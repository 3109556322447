"use strict"

// This code is very similar to that of ng-hide, except that we don't watch the
// attribute value for changes.
//
// c.f. https://github.com/angular/angular.js/blob/v1.3.19/src/ng/directive/ngShowHide.js
module.exports = function($animate, waffle) {
  "ngInject"

  return {
    multiElement: true,
    restrict: 'A',
    link: function($scope, element, attr) {
      const switchName = attr.waffleIfSwitch;
      // nosemgrep:semgrep-rules.use-launchdarkly-react
      const enabled = waffle.switch_is_active(switchName);
      $animate[enabled ? 'removeClass' : 'addClass'](element, 'ng-hide', {
        tempClasses: 'ng-hide-animate'
      })
    }
  }
}
