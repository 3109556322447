'use strict'

module.exports = function($scope, auth, $location, redirect) {
  'ngInject'

  $scope.employer = auth.currentUser.routing_organization
  $scope.goToNext = () => {
    const nextQueryParam = $location.search().next
    if (nextQueryParam === undefined) {
      if (auth.currentUser.has_health_history_completed) {
        redirect('/react/dashboard')
        return true
      } else {
        redirect('/health-history')
        return true
      }
    }
    redirect(nextQueryParam)
  }
}
