/**
 * @ngdoc filter
 * @name transcriptName
 *
 * @description
 * Returns a name of a given transcript.
 */

'use strict'

module.exports = function() {
  'ngInject'

  return function(transcript) {
    if (transcript == 'ENST00000304494') {
      return 'p16INK4a'
    }
    if (transcript == 'ENST00000579755') {
      return 'p14ARF'
    }
    return ''
  }
}
