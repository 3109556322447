module.exports = function SignInModal($modal) {
  'ngInject'

  return {
    open: function(callback) {
      return $modal.open({
        templateUrl: '/kit_orders/modals/signin.html',
        controller: function($scope, $modalInstance, auth) {
          'ngInject'
          $scope.loggingIn = false
          $scope.login = function() {
            return auth.login($scope.email, $scope.password)
              .then(function() {
                $modalInstance.dismiss()
                callback()
              })
              .catch(function(res) {
                $scope.loggingIn = false
              })
          }
        },
        size: 'sm'
      })
    }
  }
}
