'use strict'

module.exports = function($scope, $http, auth, ngToast) {
  'ngInject'

  $scope.changingPassword = false

  $scope.changePassword = function(){
    $scope.changingPassword = true
  }

  $scope.cancelPasswordChange = function() {
    $scope.changingPassword = false
  }

  $scope.changePasswordSubmit = function(form) {
    $scope.changingPassword = true
    $http.patch('/api/v1/accounts/' + auth.currentUser.id, { verification_password: form.currentPassword.$viewValue, password: form.password.$viewValue })
      .then(function(res) {
        ngToast.create('Password successfully changed.')
        $scope.changingPassword = false
      })
      .catch(function(res) {
        if (res.status == 403) {
          form.currentPassword.$setValidity('invalid', false)
          ngToast.create('Password change failed. The password you entered does not match your current password.')
        } else {
          ngToast.create('Something went wrong.')
        }
      })
  }
}
