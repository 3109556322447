/**
 * @ngdoc module
 * @name gcToolFilter
 *
 * @description
 * The `gcToolFilter` provides filters for GC tools.
 */
module.exports = angular.module('gcToolFilter', [])

  /**
   * @ngdoc filter
   * @name gcToolReportStatus
   * @memberof gcToolFilter
   *
   * @description
   * Converts a report object with state and opened_at fields to its display string.
   */
  .filter('gcToolReportStatus', function () {
    return function (report) {
      if (!report) {
        return
      }
      if (report.state == 'sent_to_ordering_physician') {
        return "Released to provider"
      } else if (report.state == 'release_ready') {
        return "Ready to release"
      } else if (report.state == 'released') {
        return report.opened_at ? "Viewed by client" : "Released to client"
      } else {
        return report.state
      }
    }
  })

  /**
   * @ngdoc filter
   * @name releasePreferences
   * @memberof gcToolFilter
   *
   * @description
   * Formats physician release preferences for display.
   */
  .filter('releasePreferences', function () {
    return function (releasePreferences) {
      let displayString
      if (!releasePreferences) {
        return
      }

      if (releasePreferences.release_to_patient) {
        if (releasePreferences.release_delay_in_days === null) {
          displayString = "Release delay not set"
        } else if (releasePreferences.release_delay_in_days === 0) {
          displayString = "Immediate release"
        } else {
          displayString = releasePreferences.release_delay_in_days + ' day release'
        }
        displayString += ', '
        if (releasePreferences.require_counseling_if_positive) {
          displayString += 'GC Block'
        } else {
          displayString += 'No GC Block'
        }
      } else {
        displayString = 'No client release'
      }
      return displayString;
    }
  })
