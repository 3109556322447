/**
 * @prettier
 */

module.exports = function ltrTimechart($filter, testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/ltr_timechart.html',
    replace: true,
    scope: {
      riskData: '=',
      testType: '=',
    },
    link: function(scope, elem) {
      // We potentially mangle data later for display purposes
      scope.timechartRiskData = angular.copy(scope.riskData);

      // Only negative reports
      if (scope.timechartRiskData.gene == '') {
        // All Female Negative, and H30 Male Negative
        scope.showNegativeMultiChart =
          scope.timechartRiskData.gender == 'F' || scope.testType == testTypes.hereditary30;
        // Only triggered for BO19 Male Negative
        scope.showNegativeSingleChart = !scope.showNegativeMultiChart;
      }

      // Add STK11-specific asterisk if age exception exists
      scope.hasAgeExceptionsAt = $filter('hasAgeExceptionsAt')(
        scope.timechartRiskData.risks.pathogenic
      );

      scope.setHeight = function(num, adj) {
        return $filter('barChartHeight')(num, adj) + '%';
      };
      scope.setLabelHeight = function(num) {
        return $filter('barChartHeight')(num, 2.5) + '%';
      };
      scope.setHeightBottom = function(num, adj) {
        return $filter('barChartHeight')(num, adj, 0) + '%';
      };
      // Used to get the baseline risk matching the pathogenic risk associated, by age
      scope.getBaselineRisk = function(risk) {
        const baseline = $filter('filter')(scope.timechartRiskData.risks.baseline.data, {
          age: risk.age,
        });
        if (baseline.length) {
          return baseline[0].risk;
        }
      };
      // Assumes a single-age pathogenic chart
      function heightOverride(phenotype) {
        const notElevatedRiskAge = scope.timechartRiskData.risks.pathogenic.data[0].age;
        if (phenotype == 'breast cancer') {
          if (notElevatedRiskAge == 70) {
            return '7.1%';
          } else if (notElevatedRiskAge == 80) {
            return '10%';
          } else if (notElevatedRiskAge == 95) {
            return '12%';
          }
        } else if (phenotype == 'ovarian cancer') {
          if (notElevatedRiskAge == 70) {
            return '1%';
          } else if (notElevatedRiskAge == 80) {
            return '1%';
          } else if (notElevatedRiskAge == 95) {
            return '1.3%';
          }
        } else if (phenotype == 'male breast cancer') {
          if (notElevatedRiskAge == 70) {
            return '<0.1%';
          } else if (notElevatedRiskAge == 80) {
            return '<0.1%';
          }
        }
      }
      if (angular.isDefined(scope.timechartRiskData.risks.pathogenic)) {
        // Reduce graph to a single column if risks don't have enough to populate an age-series, choosing the oldest age
        if (scope.timechartRiskData.risks.pathogenic.data.length <= 3) {
          scope.timechartRiskData.risks.pathogenic.data = $filter('filter')(
            scope.timechartRiskData.risks.pathogenic.data,
            {
              age:
                scope.timechartRiskData.risks.pathogenic.data[
                  scope.timechartRiskData.risks.pathogenic.data.length - 1
                ].age,
            }
          );
        }

        // Layout var to control single-age vs. age-series chart layouts
        scope.columns = scope.timechartRiskData.risks.pathogenic.data.length;

        // isNotElevated height overrides for single pathogenic charts with a not-elevated pathogenic risk, used whenever breast or ovarian risk isn't elevated on BO19 reports
        // TODO: Improve this logic
        if (
          scope.columns == 1 &&
          ($filter('isNotElevated')(scope.timechartRiskData.risks.pathogenic.data[0].risk) ||
            $filter('isStudiesOngoing')(scope.timechartRiskData.risks.pathogenic.data[0].risk))
        ) {
          scope.heightOverride = heightOverride(scope.timechartRiskData.phenotype);
        }
      }
    },
  };
};
