module.exports = angular.module('kitRegistrationDirectives', [])
  .directive('uppercaseModel', function($parse) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        const uppercase = function(inputValue) {
          if (angular.isUndefined(inputValue) || !inputValue.toUpperCase) {
            inputValue = ''
          }
          const uppercased = inputValue.toUpperCase()
          if (uppercased !== inputValue) {
            modelCtrl.$setViewValue(uppercased)
            modelCtrl.$render()
          }
          return uppercased
        }
        modelCtrl.$parsers.push(uppercase)
        modelCtrl.$formatters.push(uppercase)
        uppercase($parse(attrs.ngModel)(scope))
      }
    }
  })
