'use strict'

module.exports = function($filter, $translate) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/_questions.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.questions = []
      scope.title = 'RESULT.QUESTIONS.FAMILY_TITLE'
      const hasTrueNegative = (scope.reportContent.reported_variants_in_relatives || []).some(function(v) {
        return v.is_true_negative
      })
      if (scope.reportContent.is_negative) {
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.FAMILY.NEGATIVE.T1',
          p: 'FH_REPORT.QUESTIONS.FAMILY.NEGATIVE.P1',
          withGC: true
        })
        if (hasTrueNegative) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.FAMILY.TRUE_NEGATIVE.T1',
            p: 'FH_REPORT.QUESTIONS.FAMILY.TRUE_NEGATIVE.P1'
          })
        }
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.FAMILY.NEGATIVE.T2',
          p: 'FH_REPORT.QUESTIONS.FAMILY.NEGATIVE.P2'
        })
        if ($filter('mutationsHaveVus')(scope.reportContent.mutations)) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.FAMILY.VUS.T1',
            p: 'FH_REPORT.QUESTIONS.FAMILY.VUS.P1',
            references: [283]
          })
        }
      } else if (scope.reportContent.is_positive) {
        // TODO: Add unit tests once logic is finalized
        const mutations = $filter('pathogenicMutations')(scope.reportContent.mutations)
        const genes = scope.reportContent.genes_with_pathogenic_mutations
        const singleMut = mutations.length == 1
        const singleGene = genes.length == 1

        genes.forEach(function(gene) {
          const allelicity = scope.reportContent.allelicity_by_gene[gene]
          const geneMutations = mutations.filter(function(m) { return m.gene == gene })
          let p = 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P1.'
          if (allelicity) {
            p += allelicity.toUpperCase()
          } else {
            p += 'UNKNOWN_PHASE'
          }
          let nounType = 'RESULT.MUTATION.'
          if (geneMutations.length == 1 && allelicity != 'biallelic') {
            nounType += 'SINGULAR.'
          } else {
            nounType += 'PLURAL.'
          }
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.T1',
            p: p,
            values: { gene: gene,
              mutationNoun: $translate.instant(nounType + 'NOUN'),
              mutationNounWithArticle: $translate.instant(nounType + 'WITH_ARTICLE'),
              thisMutationNoun: $translate.instant(nounType + 'THIS'),
              mutationPredicate: $translate.instant(nounType + 'PREDICATE') }
          })
        })

        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.T2',
          p: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P2'
        })

        const q3 = {
          t: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.T3',
          values: { gene: genes[0] }
        }
        if (singleMut) {
          if (mutations[0].zygosity == 'Heterozygous') {
            q3.p = 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P3.SINGLE_HET'
          } else {
            q3.p = 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P3.SINGLE_HOM'
          }
        } else {
          if (singleGene) { // multiple muts on the same gene.
            const gene = genes[0]
            if (!scope.reportContent.allelicity_by_gene[gene]) { // unknown phase
              q3.p = 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P3.UNKNOWN_PHASE'
            }
          } else {
            q3.values = { genes: $filter('joinEnglishList')(genes) }
          }
        }
        if (q3.p) {
          scope.questions.push(q3)
        }

        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.T4',
          p: 'FH_REPORT.QUESTIONS.FAMILY.POSITIVE.P4',
          references: [289]
        })

        if ($filter('mutationsHaveVus')(scope.reportContent.mutations)) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.FAMILY.VUS.T1',
            p: 'FH_REPORT.QUESTIONS.FAMILY.VUS.P1',
            references: [283]
          })
        }
      }
    }
  }
}
