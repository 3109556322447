const NoPOBoxesModalController = function($scope, outboundShipperName, jurisdictionName, $modalInstance) {
  'ngInject'
  $scope.outboundShipperName = outboundShipperName
  $scope.jurisdictionName = jurisdictionName

  $scope.dismiss = function() {
    $modalInstance.dismiss()
  }
}

module.exports = function NoPOBoxesModal($modal) {
  'ngInject'
  return {
    open: function(outboundShipperName, jurisdictionName) {
      return $modal.open({
        templateUrl: '/kit_orders/modals/no_p_o_boxes.html',
        controller: NoPOBoxesModalController,
        size: 'sm',
        resolve: {
          outboundShipperName: function() {
            return outboundShipperName
          },
          jurisdictionName: function() {
            return jurisdictionName
          }
        }
      })
    }
  }
}
