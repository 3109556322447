'use strict'

module.exports = function healthHistoryGeneticTestResultForm(
  criteriaRelationshipTypes, sideOfFamilyTypes, CancerGene
) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/health_history_genetic_test_result_form.html',
    scope: {
      geneticTestResult: '=',
      isPatient: '=',
      removeGeneticTestResult: '=?'
    },
    link: function(scope) {
      scope.criteriaRelationshipTypes = Object.keys(criteriaRelationshipTypes.ALL).map(function(key) {
        return criteriaRelationshipTypes.ALL[key]
      })
      scope.sideOfFamilyTypes = Object.keys(sideOfFamilyTypes).map(function(key) {
        return sideOfFamilyTypes[key]
      })
      scope.cancerGenes = Object.keys(CancerGene).map(function(key) {
        return CancerGene[key]
      })

      scope.onRemove = function() {
        // TODO: Add confirmation logic here.
        scope.removeGeneticTestResult(scope.geneticTestResult)
      }
    }
  }
}
