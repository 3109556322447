const InsightsFlowNavigation = {
  blue: true,
  showLeft: false,
  showRight: false,
  showPromoBanner: false,
  active: 'buy',
  subnavCompact: true,
  layout: 'minimum'
}

module.exports = angular.module('insights', [
  require('monoweb/django_apps/users/js/insights/controllers').name,
]).config(function(colorRouteProvider) {
  colorRouteProvider
    .when('/insights/introduction', {
      templateUrl: '/insights/introduction.html',
      controller: 'InsightsIntroductionController',
      auth: {
        requireLogin: false,
        requireEmailConfirmation: false
      },
      navigation: InsightsFlowNavigation
    })
    .when('/insights/account', {
      templateUrl: '/insights/account.html',
      controller: 'InsightsAccountController',
      auth: {
        requireLogin: false,
        requireEmailConfirmation: false
      },
      navigation: InsightsFlowNavigation
    })
})
