/**
 * @prettier
 */

module.exports = function reportProvideHealthHistory() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_provide_health_history.html',
    controllerAs: 'ctrl',
    replace: true,
    bindToController: true,
    scope: {
      reportContent: '=',
    },
    controller: function() {
      const ctrl = this;
    },
  };
};
