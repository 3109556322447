module.exports = function($location, $scope, $sce) {
  'ngInject'
  const orderNumber = $location.search().order_number
  if (orderNumber) {
    $scope.iframeWidth = '100%';
    // adaptive height doesn't work, so set a fixed height and allow scrolling
    $scope.iframeHeight = '1500px';
    $scope.appointmentURL = $sce.trustAsResourceUrl(`/schedule?order_number=${orderNumber}`);
    $scope.iframeScrolling = 'yes';
  } else {
    $location.path('/404')
  }
}
