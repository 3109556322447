'use strict'

// kitPriceService provides methods for getting the current price.
// This service does not have context about checkout, i.e. SKU, quantity.
module.exports = function(standalonePriceDollars, promoCodeStore, claimStore, standaloneProviderServicePriceDollars, sampleReuseService, modifierBrcaUpgradePriceDollars, testTypes, auth) {
  'ngInject'

  // returns kit price
  // If no SKU is given, it will show standalone test price.
  this.getAdvertisedKitPriceDollars = function(sku) {
    const custom_price_dollars = this.getCustomKitPriceDollars(sku)
    if (custom_price_dollars) {
      return custom_price_dollars
    }
    return sku ? sku.priceDollars : standalonePriceDollars
  }

  this.getProviderServicePriceDollars = function(sku) {
    return sku ? sku.providerServicePriceDollars : standaloneProviderServicePriceDollars
  }

  this.getDisplayedKitPrice = function(sku) {
    let kitPrice

    const claim = claimStore.claim

    const skuDiscount = claimStore.getNonUpgradeSkuDiscount(sku, false)
    if (skuDiscount) {
      kitPrice = skuDiscount.copay_cents / 100.0
    }

    if (kitPrice === undefined) {
      const baseKitPrice = this.getAdvertisedKitPriceDollars(sku)
      const modifiedKitPrice = baseKitPrice + this.getModifierDiscountDollars(sku)
      kitPrice = Math.max(0, modifiedKitPrice - (this.isFreeShippingPromoCode(sku) ? 0 : this.getPromoCodeDiscountDollars(sku, modifiedKitPrice)))
    }
    if (kitPrice % 1 != 0) {
      return kitPrice.toFixed(2)
    }
    return kitPrice.toFixed(0)
  }

  this.isFreeShippingPromoCode = function(sku) {
    return promoCodeStore.promoAppliesToSku(sku) && promoCodeStore.promotionalCode && promoCodeStore.promotionalCode.code == 'SHIPPING18'
  }

  this.isZero = function(sku) {
    return this.getDisplayedKitPrice(sku) == "0"
  }

  this.hasPromoCode = function(sku) {
    return this.getPromoCodeDiscountDollars(sku) > 0
  }

  // Get the current promo code, but only if it is redeemable
  // and can be applied to the specified sku.
  this.getValidPromoCode = function(sku) {
    if (promoCodeStore.promoAppliesToSku(sku)) {
      return promoCodeStore.promotionalCode
    } else {
      return null
    }
  }

  // Check if the current promo code is for free shipping, used primarily
  // to not show price adjustments for the free shipping Turbo experiment
  this.isFreeShippingPromoCode = function(sku) {
    return promoCodeStore.promoAppliesToSku(sku) && promoCodeStore.promotionalCode && promoCodeStore.promotionalCode.code == 'SHIPPING18'
  }

  // Get promo code discount amount. This can exceed the kit price amount,
  // e.g. the fixed discount amount is greater than the kit price amount, or
  // the percent discount is 100% and there is also a fixed discount amount.
  this.getPromoCodeDiscountDollars = function(sku, baseKitPrice) {
    const promo_code = this.getValidPromoCode(sku)
    if (!promo_code) {
      return 0
    }

    if (!baseKitPrice) {
      baseKitPrice = this.getAdvertisedKitPriceDollars(sku)
    }
    let discountCents = 0

    // percent discount is first applied to the advertised kit price
    if (promo_code.percent_discount) {
      // Math.floor mimics python integer division that's used on the server side
      discountCents += Math.floor(baseKitPrice * promo_code.percent_discount)
    }

    // finally the fixed portion of the promo code is applied.
    if (promo_code.value_cents) {
      discountCents += promo_code.value_cents
    }
    return discountCents / 100
  }

  this.getCustomKitPriceDollars = function(sku) {
    const promo_code = this.getValidPromoCode(sku)
    if (!promo_code) {
      return false
    }
    const custom_price_cents = promo_code.custom_kit_price_cents
    if (!custom_price_cents) {
      return false
    }
    return custom_price_cents / 100
  }

  // Get non promo code discounts that should be displayed
  this.getModifierDiscountDollars = function(sku) {
    const skuName = sku.name
    if (auth.isLoggedIn && skuName in auth.currentUser.pricing_modifiers) {
      return auth.currentUser.pricing_modifiers[skuName]['price_cents'] / 100
    }
    return 0
  }
}
