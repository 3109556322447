'use strict'

module.exports = function($location, sku, pathFlow, auth, defaultSku, KitCheckout, waffle, testTypes) {
  'ngInject'

  const flow = pathFlow('kitCheckoutFlow', [
    { controller: 'OrderingPhysicianController', path: '/purchase/ordering-physician' },
  ])

  flow.ui = {}
  flow.checkout = new KitCheckout()
  flow.initialLoggedIn = true
  flow.init = function(start) {
    const params = $location.search()
    let skuName = defaultSku
    if (params.sku && sku[params.sku]) {
      skuName = params.sku
    }

    // [CONSUMER-5214] A hack to prevent checkout to get reset on purchase confirmation page.
    // This prevents brief flashes when going from /purchase/review to the confirmation page.

    if ($location.path().indexOf('/purchase') == -1 || $location.path().indexOf('/confirmation') == -1) {
      flow.checkout.init(start, skuName)
    }

    // record this value so know whether a user was created in this flow.
    auth.sync().then(function() {
      flow.initialLoggedIn = auth.isLoggedIn
    })
  }

  return flow
}
