'use strict'

module.exports = function($scope, $location, auth, sampleReuseFlow, ngToast, handlePaymentError) {
  'ngInject'

  $scope.checkout = sampleReuseFlow.checkout
  $scope.auth = auth
  $scope.testTypes = sampleReuseFlow.checkout.sku.testTypes

  $scope.placePurchase = function() {
    if (!$scope.has_consented) {
      ngToast.create("We are unable to complete this purchase unless you check the Consent box.")
      return
    }

    return $scope.checkout.placePurchase($scope.auth.currentUser)
      .then(function(res) {
        if ($scope.checkout.existingPhysicianOrder) {
          $location.path('/sample-reuse/confirmation').search({existing_physician: true})
        }
        else {
          $location.path('/sample-reuse/confirmation')
        }
      })
      .catch(function(res) {
        handlePaymentError(res, $scope.checkout, function() {
          sampleReuseFlow.goTo('/sample-reuse/pay', false)
        })
      })
  }
}
