/**
   * This file contains angular directives used across Color sites.
   */

!function() {
  'use strict'

  angular.module('coreDirectives', ['ngToast', 'clrConstant'])
    .directive('clrAccordionListItem', require('./directives/clr_accordion_list_item'))
    .directive('clrBreadcrumbs', require('./directives/clr_breadcrumbs'))
    .directive('clrBreadcrumb', require('./directives/clr_breadcrumb'))
    .directive('clrCheckbox', require('./directives/clr_checkbox'))
    .directive('clrDateInput', require('./directives/clr_date_input'))
    .directive('clrEmailSpelling', require('./directives/clr_email_spelling'))
    .directive('clrForm', require('./directives/clr_form'))
    .directive('clrFormError', require('./directives/clr_form_error'))
    .directive('clrHozChart', require('./directives/clr_hoz_chart'))
    .directive('clrHozChartLegendItem', require('./directives/clr_hoz_chart_legend_item'))
    .directive('clrHozChartRow', require('./directives/clr_hoz_chart_row'))
    .directive('clrMarked', require('./directives/clr_marked'))
    .directive('clrPdfViewer', require('./directives/clr_pdf_viewer'))
    .directive('clrRadioRow', require('./directives/clr_radio_row'))
    .directive('clrSelectOrText', require('./directives/clr_select_or_text'))
    .directive('clrSetFocus', require('./directives/clr_set_focus'))
    .directive('clrSpinnerText', require('./directives/clr_spinner_text'))
    .directive('clrTab', require('./directives/clr_tab'))
    .directive('clrTabCustom', require('./directives/clr_tab_custom'))
    .directive('clrTabSelect', require('./directives/clr_tab_select'))
    .directive('clrTabSet', require('./directives/clr_tab_set'))
    .directive('clrTabSimple', require('./directives/clr_tab_simple'))
    .directive('clrTabSimpleWaypoint', require('./directives/clr_tab_simple_waypoint'))
    .directive('clrTabUnderline', require('./directives/clr_tab_underline'))
    .directive('clrToggleOnInput', require('./directives/clr_toggle_on_input'))
    .directive('clrUploader', require('./directives/clr_uploader'))
    .directive('clrVideoBlockPlayer', require('./directives/clr_video_block_player'))
    .directive('clrVideoThumbnail', require('./directives/clr_video_thumbnail'))
    .directive('htmlCompile', require('./directives/html_compile'))
    .directive('confirmationFor', require('./directives/confirmation_for'))
    .directive('goTo', require('./directives/go_to'))
    .directive('initFocus', require('./directives/init_focus'))
    .directive('kitPriceText', require('./directives/kit_price_text'))
    .directive('popToast', require('./directives/pop_toast'))
    .directive('promoBanner', require('./directives/promo_banner'))
    .directive('impersonationBanner', require('./directives/impersonation_banner'))
    .directive('selectOnClick', require('./directives/select_on_click'))
    .directive('showErrorOnBlur', require('./directives/show_error_on_blur'))
    .directive('stopEnterSubmit', require('./directives/stop_enter_submit'))
    .directive('stopPropagationOn', require('./directives/stop_propagation_on'))
    .directive('tabBar', require('./directives/tab_bar'))
    .directive('tooltip', require('./directives/tooltip'))
    .directive('uiSelectRequired', require('./directives/ui_select_required'))
    .directive('unique', require('./directives/unique'))
    .directive('waffleIfNotSwitch', require('./directives/waffle_if_not_switch'))
    .directive('waffleIfSwitch', require('./directives/waffle_if_switch'))
    .directive('popupWindow', require('./directives/popup_window'))
    .directive('track', require('./directives/track'))
    .directive('stickyTableHeaders', require('./directives/sticky_table_headers'))
    .directive('reference', require('./directives/reference'))
    .directive('referenceSup', require('./directives/reference_sup'))
    .directive('references', require('./directives/references'))
    .directive('fullReference', require('./directives/full_reference'))
    .directive('inputField', require('./directives/input_field'))
    .directive('htmlInjectionProtection', require('./directives/html_injection_protection'))
    .directive('progressBar', require('./directives/progress_bar'))
    .directive('uploadedImage', require('./directives/uploaded_image'))
}()
