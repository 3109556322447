'use strict'

module.exports = function($translate, $filter) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/summary_chart.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope, elem) {
      let showTooltip = false
      function toggleTooltip() {
        showTooltip = !showTooltip
        if (showTooltip) {
          elem.find('.vbar__hover').show()
        } else {
          elem.find('.vbar__hover').hide()
        }
      }
      function extractNumbersFromXTimesRisk(risk) {
        const re = /\d+/g
        const match = re.exec(risk)
        if (match) {
          return parseInt(match[0])
        } else {
          return 1
        }
      }
      function getHeightFromXTimesRisk(times) {
        return times * 5
      }
      const risks = $filter('filter')(scope.reportContent.risks, {gender: scope.reportContent.gender, phenotype: 'coronary heart disease'})[0]
      scope.references = risks.references
      // Show line chart for negative
      if (scope.reportContent.is_negative) {
        showTooltip = false
        const normalRisks = $filter('filter')(risks.data, {category: 'normal'})
        const borderlineRisks = $filter('filter')(risks.data, {category: 'borderline'})
        const highRisks = $filter('filter')(risks.data, {category: 'high'})
        const ages = normalRisks.map(function(r) { return r.age; })
        const maxAge = ages.reduce(function(a, b) { return Math.max(a, b); })
        const minAge = ages.reduce(function(a, b) { return Math.min(a, b); })
        scope.options = {
          chart: {
            type: 'lineChart',
            showLegend: false,
            height: 200,
            margin: {top: 0, right: 0, bottom: 20, left: 35},
            pointRange: [50, 50],
            x: function(d) { return d.age; },
            y: function(d) { return parseInt(d.risk); },
            xDomain: [minAge - 1, maxAge + 1],
            yDomain: [0, 60],
            axisLabelDistance: 50,
            xAxis: {
              axisLabelDistance: 50,
              tickPadding: 10,
              ticks: 5,
              // We set x domain to min - 1 and max + 1
              // to get some spacing between axis and lines.
              // The following formatting shows nice rounded
              // number, e.g. 40 instead of 39.
              tickFormat: function(d) {
                return Math.round(d/10) * 10;
              }
            },
            yAxis: {
              tickPadding: 10,
              tickValues: [0, 15, 30, 45, 60],
              tickFormat: function(d) {
                return d + '%';
              }
            },
            useInteractiveGuideline: false,
            callback: function(chart) {
              chart.tooltip.contentGenerator(function(data) {
                scope.risk = data.point.risk
                scope.age = data.point.age
                scope.category = data.series[0].key.toLowerCase()
                // Call $apply since this callback is outside of angular context.
                scope.$apply()
                toggleTooltip()
              })
              return chart
            }
          }
        }
        scope.data = [
          {
            values: normalRisks,
            key: 'Normal cholesterol',
            color: '#256b88'
          },
          {
            values: borderlineRisks,
            key: 'Borderline cholesterol',
            color: '#256b88'
          },
          {
            values: highRisks,
            key: 'High cholesterol',
            color: '#256b88'
          }
        ]
        scope.legends = ['high', 'borderline', 'normal'].map(function(category) {
          return {
            text: 'FH_REPORT.CHART.NEGATIVE.' + category,
            type: 'line',
            cls: category
          }
        })
      } else if (scope.reportContent.is_positive) {
        if (scope.reportContent.genes_with_pathogenic_mutations.length != 1) {
          throw new Error("Only supports a single gene positive case.")
        }
        scope.gene = scope.reportContent.genes_with_pathogenic_mutations[0]
        scope.risks = $filter('filter')(scope.reportContent.risks, {gender: scope.reportContent.gender, phenotype: 'coronary heart disease', gene: scope.gene})[0]
        if (scope.risks.data.length != 3) {
          throw new Error("# risk data is required to be 3.")
        }

        scope.bars = scope.risks.data.reverse().map(function(data) {
          const times = extractNumbersFromXTimesRisk(data.risk)
          return {
            name: data.risk,
            category: data.category,
            times: times,
            height: getHeightFromXTimesRisk(times)
          }
        })
        scope.legends = scope.bars.map(function(bar) {
          return {
            text: 'FH_REPORT.CHART.POSITIVE.' + bar.category,
            type: 'square',
            cls: bar.category
          }
        })
      }
    }
  }
}
