"use strict"

module.exports = function checkMinor(testTypes, patientIsMinor) {
  'ngInject'

  return {
    restrict: 'A',
    require: '^form',
    link: function(scope, elem, attrs, formCtrl) {
      scope.form = formCtrl
      const errorField = angular.element("<span class='form-error-message' ng-show='minorError'></span>")
      const checkBirthday = function(day, month, year) {
        const birthdate = new Date(Date.parse(month + " " + day + ", " + year))
        return !patientIsMinor(birthdate)
      }

      const checkMinor = function() {
        let valid = false;
        if ([testTypes.fh3, testTypes.cardio30].indexOf(formCtrl.test_requested.$modelValue) === -1) {
          valid = checkBirthday(formCtrl.day.$modelValue, formCtrl.month.$modelValue, formCtrl.year.$modelValue)
          if (!valid) {
            formCtrl.test_requested.$setValidity('test_requested', false)
            errorField.empty()
            errorField.append('Cannot order this test for a minor patient')
          }
        } else {
          formCtrl.test_requested.$setValidity('test_requested', true)
          errorField.empty()
        }
        return valid
      }

      const testTypeBlurHandler = function() {
        checkMinor()
        scope.$digest()
      }

      elem.bind('blur', testTypeBlurHandler)
      scope.form.test_requested.checkMinor = checkMinor
      elem.after(errorField)

    }
  }
}
