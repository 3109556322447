/**
 * @prettier
 */

module.exports = function reportGeneDetails($filter, namedMutation, testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_gene_details.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      // Order based on classifications so ng-repeat logically organizes summary paragraphs
      const pathogenicMutations = [];
      const likelyPathogenicMutations = [];
      angular.forEach(scope.reportContent.mutations, function(mutation) {
        // Check for lower/higher risk mutations and add checks accordingly
        // Lower: CHEK2 I157T
        if (
          $filter('containsAny')([mutation.gene], ['CHEK2']) &&
          $filter('containsMutation')([mutation], 'chr22.GRCh37:g.29121087A>G')
        ) {
          mutation.appendLower = true;
          mutation.studySubject = 'Some studies have';
          mutation.additionalMutationRefs = [74];
        }
        // Lower: BRCA2 T2626C
        if (
          $filter('containsAny')([mutation.gene], ['BRCA2']) &&
          $filter('containsMutation')([mutation], 'chr13.GRCh37:g.32936732G>C')
        ) {
          mutation.appendLower = true;
          mutation.studySubject = 'A study has';
          mutation.additionalMutationRefs = [318];
        }
        // Higher: ATM 7271T>G
        if (
          $filter('containsAny')([mutation.gene], ['ATM']) &&
          $filter('containsMutation')([mutation], 'chr11.GRCh37:g.108199929T>G')
        ) {
          mutation.appendHigher = true;
          mutation.additionalMutationRefs = [169, 170, 171];
        }
        if (
          $filter('containsAny')([mutation.gene], ['APC']) &&
          $filter('containsMutation')([mutation], namedMutation.apc_i1307k)
        ) {
          // APC I1307K-only condition
          mutation.appendAnalyzedPositions =
            ' Studies have shown that the risk of cancer associated with this particular mutation is significantly lower than that of other mutations in the <em>APC</em> gene.';
          mutation.appendAnalyzedPositionsRefs = [199, 200, 201]; // these are added by the risks
        }
        if ($filter('containsAny')([mutation.gene], ['EPCAM'])) {
          // EPCAM-only condition
          mutation.appendAnalyzedPositions =
            ' For <em>EPCAM</em>, only large deletions and duplications including 3’ end of the gene are analyzed, because other positions are not known to impact cancer risk.';
        }
        if (
          $filter('containsAny')([mutation.gene], ['MITF']) &&
          $filter('containsMutation')([mutation], 'chr3.GRCh37:g.70014091G>A')
        ) {
          // MITF-only condition
          mutation.appendAnalyzedPositions =
            ' For <em>MITF</em>, only chr3:g.70014091 (including c.952G>A) is analyzed, because other positions are not known to impact cancer risk.';
        }
        if ($filter('containsAny')([mutation.gene], ['GREM1'])) {
          // GREM1-only condition
          mutation.appendAnalyzedPositions =
            ' For <em>GREM1</em>, only duplications in the upstream regulatory region are analyzed, because other positions are not known to impact cancer risk.';
        }
        if ($filter('containsAny')([mutation.gene], ['PMS2'])) {
          // PMS2-only condition
          if (scope.reportContent.render_version < 8) {
            mutation.appendAnalyzedPositions = ' For <em>PMS2</em>, exons 12-15 are not analyzed.';
          }
        }
        if (mutation.classification == 'Pathogenic') {
          pathogenicMutations.push(mutation);
        } else if (mutation.classification == 'Likely Pathogenic') {
          likelyPathogenicMutations.push(mutation);
        }
      });
      scope.mutationsSet = [];
      if (pathogenicMutations.length) {
        scope.mutationsSet.push(pathogenicMutations);
      }
      if (likelyPathogenicMutations.length) {
        scope.mutationsSet.push(likelyPathogenicMutations);
      }
    },
  };
};
