module.exports = function ProviderPlatformOrderController($rootScope, $scope, $location, $routeParams, $timeout, $translate, $window, $http, auth, DetailedPhysicianOrder, PhysicianOrderCancellation, ngToast, locationSkipReload, modalShim, providerReleaseResultsForOrderModal, showMedicationPromo) {
  'ngInject'

  $scope.showPgxBanner = false

  const checkShowPgxBanner = (order) => {
    const pgxOrder = order.kit_orders.find((ko) => ko.test_type === 'pgx v1')
    if (!!pgxOrder && order.status.has_report && showMedicationPromo()) {
      const PgxOnboardingEventLogAPI = '/api/v1/event_log?event_type=api:Tutorial Finished: PgxOnboarding'
      $http.get(PgxOnboardingEventLogAPI).then((resp) => {
        if (resp.data.count === 0) {
          $scope.showPgxBanner = true
        }
      })
    }
  }

  /*
   * Returns the test types of any reports that the provider can release to the patient.
   */
  $scope.getTestTypesWithReleasableReports = function() {
    // Order hasn't loaded yet.
    if (!$scope.order) {
      return [];
    }
    // If the order is provider-owned, we don't release results to the patient.
    if (!!$scope.order.is_provider_owned) {
      return [];
    }
    // Check if the order is in a state where results are available to the physician.
    if (!$scope.order.status.has_report) {
      return [];
    }
    // From the previous check, we know that all KitOrders have reports that are
    // available to the physician. Now we determine which ones haven't been released to
    // the patient yet.
    return $scope.order.kit_orders
      .filter((kitOrder) => !kitOrder.is_released)
      .map((kitOrder) => kitOrder.test_type);
  };

  $scope.openPhysOrderReleaseModal = function() {
    const releaseResultModal = providerReleaseResultsForOrderModal.open(
      $scope.order.requisition_number,
      $scope.getTestTypesWithReleasableReports(),
    );
    // Consumers may be using Windows + external PDF readers, so a shim is required
    releaseResultModal.result.finally(function() {
      modalShim.remove();
    });
    releaseResultModal.opened.then(function() {
      modalShim.add();
    });
  };

  $scope.loadOrder = function() {
    DetailedPhysicianOrder.get({ requisition_number: $routeParams.requisition_number }).$promise
      .then(function(res) {
        $scope.order = res
        checkShowPgxBanner($scope.order)
        $translate('PROVIDER_PORTAL.STATUSES.' + $scope.order.status.name + '.NAME')
          .then(function(translation) { $scope.order.statusName = translation })
      })
      .catch(function(res) {
        if (res.status == 404) {
          $location.path('/providers/home')
          ngToast.create({
            className: 'error',
            content: 'Order not available to this account'
          })
        }
      })
  }

  $scope.loadOrder()

  $scope.onSaveOrder = function() {
    return $scope.order.$update()
      .then(function() {
        ngToast.create('Order has been successfully updated')
        $scope.loadOrder()
      })
      .catch((response) => {
        const errorMessage = (response.data && response.data.non_field_errors) ?
          response.data.non_field_errors.join(', ') :
          'Update failed'

        ngToast.create({
          className: 'error',
          content: errorMessage
        })
      })
  }

  $scope.currentUser = auth.currentUser
  $scope.url = $location.path()
  $scope.resultsPath = '/providers/order/results/'
  $scope.logPath = '/providers/order/log/'
  $scope.detailsPath = '/providers/order/details/'

  $scope.isPath = function(url, path) {
    return url.indexOf(path) == 0
  }

  $scope.goToOrders = function() {
    $location.path('/providers/home')
  }

  $scope.goToPath = function(url, id) {
    locationSkipReload(url+ id)
    $scope.url = url
  }

  $scope.shipKit = function(order) {
    $scope.shipping = true
    $http.put('/api/v1/detailed_physician_orders/' + order.requisition_number + '/trigger_shipping')
      .then(function(){
        $rootScope.$broadcast('physicianOrderLog:updated')
      })
      .then(function() {
        ngToast.create("Queued for shipping.")
      })
      .finally(function() {
        $scope.shipping = false
      })
  }

  $scope.cancelTest = (order) => {
    PhysicianOrderCancellation.open(order.requisition_number, order.patient_profile.first_name, order.patient_profile.last_name).result
      .then(() => {
        $scope.order.is_cancellable = false
        ngToast.create("Order has been cancelled.")
        $rootScope.$broadcast('physicianOrderLog:updated')
      })
  }
}
