'use strict'

module.exports = function(promoCodeStore, canAddDependents) {
  'ngInject'

  let dependents = [{}]
  const dependentsClaimManager = {
    claimForSelf: true,
    onlySpouse: function() {
      return promoCodeStore.promotionalCode.max_redemptions == 2
    },
    canAddFamilyMember: function() {
      return canAddDependents(promoCodeStore)
    },
    getFamilyMembers: function() {
      return dependents
    },
    addFamilyMember: function() {
      dependents.push({})
    },
    removeMember: function(i) {
      dependents.splice(i, 1)
    }
  }

  dependentsClaimManager.init = function() {
    dependents = [{}]
    dependentsClaimManager.claimForSelf = true
  }

  return dependentsClaimManager
}
