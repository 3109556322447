"use strict"

module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      ngModel: '=',
      clrToggleOnInput: '='
    },
    link: function(scope, elem, attrs, ngModel) {
      scope.$watch(function() {
        return scope.ngModel
      }, function(newValue, oldValue) {
        if (!newValue) {
          scope.clrToggleOnInput = ''
        }
      })
      scope.$watch(function() {
        return scope.clrToggleOnInput
      }, function(newValue, oldValue) {
        if (newValue) {
          // When input is being typed in/has new value
          ngModel.$setViewValue(true)
        } else if (oldValue) {
          // When input has no val(), i.e. when input text is deleted
          ngModel.$setViewValue(false)
        }
        ngModel.$render()
      })
    }
  }
}
