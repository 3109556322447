"use strict"

/**
 * Creates a .tab-bar element for tabs with paths attached, also handles translations
 */
module.exports = function($location, $filter, $anchorScroll, $timeout) {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      tabBar: '='
    },
    templateUrl: '/directives/tabBar.html',
    link: function(scope, elem, attrs) {
      elem.addClass("tab-bar")
      const tab = $filter('filter')(scope.tabBar, {url: $location.path()})
      if (tab.length) {
        tab[0].active = true
      } else {
        scope.tabBar[0].active = true
      }
      $timeout(function() {$anchorScroll()}, 200)
    }
  }
}
