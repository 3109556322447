/**
 * @prettier
 */

module.exports = function reportedData(
  $window,
  testOutcome,
  classification,
  ReportedData,
  sangerDataModal
) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
      showDetails: '=',
    },
    templateUrl: '/results/reported_data.html',
    link: function(scope) {
      scope.testOutcome = testOutcome;
      scope.classification = classification;
      scope.showBenignVariants = false;

      if (scope.report.clinical_result) {
        scope.loading = true;
        ReportedData.get({ id: scope.report.clinical_result }).$promise.then(function(res) {
          scope.reportedData = res;
          scope.loading = false;
        });
      }

      scope.toggleShowBenign = function() {
        scope.showBenignVariants = !scope.showBenignVariants;
      };
      scope.openSangerPlots = function($event, confirmation) {
        // Each row in the table is itself a link to a variant classification page. When
        // openSangerPlots is called, we only want to open a modal, not follow the link to
        // the classification page
        $event.stopPropagation();
        sangerDataModal.open(confirmation);
      };
      scope.viewClassification = function(classification_id) {
        $window.open('/variant_classifications/' + classification_id + '/show');
      };
    },
  };
};
