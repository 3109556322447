/**
 * @prettier
 */

module.exports = function reportDropdown(
  reportOrderDetailsModal,
  reportRevisionsModal,
  urlBuilder,
  uploadFileTypes
) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_dropdown.html',
    replace: true,
    scope: {
      report: '=',
      reportContent: '=',
      hideDownload: '=?',
    },
    link: function(scope, elem) {
      scope.toggleDropdown = function() {
        scope.dropdownOpened = !scope.dropdownOpened;
      };
      scope.openOrderDetails = function() {
        reportOrderDetailsModal.open(scope.reportContent, scope.report);
        scope.toggleDropdown();
      };
      scope.openRevisions = function() {
        reportRevisionsModal.open(scope.report.revisions);
        scope.toggleDropdown();
      };
      scope.makeS3Url = function(key) {
        return urlBuilder.buildUrl('/api/v1/uploaded_file', {
          key: key,
          file_type: uploadFileTypes.geneticCounselingNote,
        });
      };
    },
  };
};
