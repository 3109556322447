"use strict"

// https://stackoverflow.com/questions/17417607/angular-ng-bind-html-and-directive-within-it

module.exports = function($compile) {
  "ngInject"

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const ensureCompileRunsOnce = scope.$watch(
        function(scope) {
          return scope.$eval(attrs.htmlCompile)
        },
        function(value) {
          // When 'compile' expression changes assign it to the DOM
          element.html(value)

          // compile the new DOM and link to the current scope
          $compile(element.contents())(scope)

          // Use un-watch feature so ensure complications happens only once
          ensureCompileRunsOnce()
        }
      )
    }
  }
}
