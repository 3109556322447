/**
 * @prettier
 */

module.exports = function reportSummary(
  $filter,
  $location,
  $route,
  $http,
  auth,
  testTypes,
  ngToast,
  testOutcome,
  addReleaseGroupHoldModal,
  removeReleaseGroupHoldModal,
  withdrawReleaseGroupModal,
  addReportReviewNoteModal,
  releaseToPhysicianModal,
  textSaveModal,
  approveReleaseModal,
  releaseToPathologistModal,
  changeReportStateModal,
  rereleaseModal,
  reportNewState,
  reportContentReviewedState,
  reportQueuedforBoardReviewState,
  reportPreparedForBoardReviewState,
  reportBoardReviewedState,
  reportSignedOffState,
  reportReleaseGatedState,
  reportSentToPhysicianState,
  reportReleaseApprovedState,
  reportReleasedState,
  reportReleaseReadyState,
  clinicalDomains
) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
      isAnonymized: '=',
    },
    templateUrl: '/results/report_summary.html',
    link: function(scope) {
      const report = scope.report;
      const isPreRelease =
        [
          reportNewState,
          reportQueuedforBoardReviewState,
          reportPreparedForBoardReviewState,
          reportContentReviewedState,
          reportBoardReviewedState,
          reportSignedOffState,
          reportReleaseGatedState,
          reportReleaseApprovedState,
        ].indexOf(report.state) != -1;

      const isNonTerminalState =
        [
          reportNewState,
          reportQueuedforBoardReviewState,
          reportPreparedForBoardReviewState,
          reportContentReviewedState,
          reportBoardReviewedState,
          reportSignedOffState,
          reportReleaseGatedState,
          reportReleaseApprovedState,
          reportSentToPhysicianState,
          reportReleaseReadyState,
        ].indexOf(report.state) != -1;

      const pathologistReview =
        report.state == reportContentReviewedState || report.state == reportBoardReviewedState;

      const isAnonymized = scope.isAnonymized;

      scope.showReleaseToPathologistBtn =
        !isAnonymized && report.state == reportNewState && report.result_is_ready;
      scope.showReleaseToGeneticsBoardBtn =
        !isAnonymized && report.state == reportQueuedforBoardReviewState;
      scope.showMarkAsReviewedByGeneticsBoardBtn =
        !isAnonymized && report.state == reportPreparedForBoardReviewState;
      scope.showReleaseToPhysicianBtn = !isAnonymized && pathologistReview;
      if (scope.showReleaseToPhysicianBtn) {
        // Only NY CQ holders can sign out NY reports
        // But other pathologists can still approve the sign out
        scope.canSignOut = !report.sample.for_ny || auth.currentUser.is_ny_cq_holder;
      }
      scope.showApproveReleaseBtn = !isAnonymized && report.state == reportSignedOffState;
      scope.showAddNoteBtn = !isAnonymized && isPreRelease;
      scope.showSendToOrderingPhysicianBtn =
        !isAnonymized &&
        report.state == reportReleaseApprovedState &&
        report.existing_physician_order;
      scope.showReadyForReleaseBtn =
        !isAnonymized &&
        report.state == reportReleaseApprovedState &&
        !report.existing_physician_order;
      scope.showRereleaseBtn =
        report.state == reportReleasedState ||
        (!report.release_to_patient && report.state == reportSentToPhysicianState);

      scope.sample = report.sample;
      scope.healthHistory = report.user.health_profile || {};
      scope.testTypes = testTypes;
      scope.openAddNoteModal = function(report) {
        addReportReviewNoteModal.open(report).result.then(function() {
          ngToast.create('Note added');
        });
      };
      scope.rereleaseModal = rereleaseModal;

      scope.clinicalDomain = clinicalDomains[report.kit_order.test_type];

      // Format uploaded filenames. The elements in uploaded_s3_keys look like
      // 'documents/1/2016-02-19T15:09:48.212992_file_name.jpg'
      if (report.provider_upload_s3_keys) {
        scope.report.uploaded_filenames = report.provider_upload_s3_keys.map(
          $filter('getFileNameFromS3Key')
        );
      }

      scope.dropdownOpened = false;

      const showRequestGeneticsBoardReviewBtn = !isAnonymized && pathologistReview;
      const showSendBackForContentReview =
        !isAnonymized && isPreRelease && report.state != reportNewState;
      const showReviewedHHChange = report.state == reportReleasedState;
      const showPutOnHold = !isAnonymized && isNonTerminalState && !scope.report.on_hold;
      const showTakeOffHold = !isAnonymized && isNonTerminalState && scope.report.on_hold;
      const showInstantReportTool =
        !isAnonymized &&
        report.state == reportNewState &&
        auth.currentUser.permissions['results.can_use_instant_report'];
      // TODO(tally38): We are still figuring out who will get permissions for this tool.
      // For now, I am restricting it to can_use_instant_report, but I will create a new
      // permission class for retracting reports in a separate PR once i have more details.
      // There is a chance we will not need to limit permissions on this.
      const showQueueRetractionOption =
        !isAnonymized &&
        report.state == reportReleasedState &&
        auth.currentUser.permissions['results.can_use_instant_report'];
      const showGenerateReportData =
        !isAnonymized &&
        report.state == reportNewState &&
        report.test_outcome != testOutcome.non_reportable &&
        auth.currentUser.permissions['results.can_generate_clinical_results'];

      const putOnHoldOption = { show: showPutOnHold, onClick: putOnHold, label: 'Put on hold' };
      const putOffHoldOption = {
        show: showTakeOffHold,
        onClick: takeOffHold,
        label: 'Take off hold',
      };

      function requestGeneticsBoardReview(report) {
        const state = report.state;
        scope.dropdownOpened = false;
        changeReportStateModal
          .open(
            report,
            'genetics_board_review_required',
            'Request Genetics Board Review',
            'Request',
            'Review needed because...'
          )
          .result.then(function() {
            $location.path('/reports/content_reviewed,board_reviewed/list');
          });
      }

      function sendBackForContentReview(report) {
        const state = report.state;
        scope.dropdownOpened = false;
        changeReportStateModal
          .open(
            report,
            'sent_back_for_content_review',
            'Send Back for Content Review',
            'Send',
            'Send back because...'
          )
          .result.then(function() {
            $location.path('/reports/content_reviewed,board_reviewed/list');
          });
      }

      function putOnHold(report) {
        scope.dropdownOpened = false;

        // TODO (LABX-4368): Simplify this branching logic once we're using ReleaseGroups for all reports
        if (report.release_group.release_group_id) {
          addReleaseGroupHoldModal
            .open(report.release_group.release_group_id)
            .result.then(() =>
              ngToast.create(
                `ReleaseGroup ${report.release_group.release_group_id} has been put on hold.`
              )
            )
            .then($route.reload);
        } else {
          report.put_on_hold = true;
          report.reason = '';
          textSaveModal
            .open(
              'Put report on hold',
              'Put on hold',
              'Please specify the reason...',
              report,
              'reason'
            )
            .result.then(() => {
              ngToast.create('The report has been put on hold.');
              putOnHoldOption.show = false;
              putOffHoldOption.show = true;
            })
            .finally(() => {
              delete report.put_on_hold;
            });
        }
      }

      function takeOffHold(report) {
        // TODO (LABX-4368): Simplify this branching logic once we're using ReleaseGroups for all reports
        if (report.release_group.release_group_id && report.release_group.active_holds.length > 0) {
          removeReleaseGroupHoldModal
            .open(report.release_group, report.release_group.active_holds[0])
            .result.then(() =>
              ngToast.create(
                `A hold on Release Group ${report.release_group.release_group_id} has been removed.`
              )
            )
            .then($route.reload);
        } else {
          scope.dropdownOpened = false;
          report.take_off_hold = true;
          return report
            .$save()
            .then(function() {
              ngToast.create('The report has been taken off hold.');
              putOnHoldOption.show = true;
              putOffHoldOption.show = false;
            })
            .finally(function() {
              delete report.take_off_hold;
            });
        }
      }

      const generateReportedData = report => {
        scope.dropdownOpened = false;
        $http.put(`/api/v1/reports/${report.id}/generate_clinical_result`).then(() => {
          ngToast.create('The report data has been generated.');
          $route.reload();
        });
      };

      function goToInstantReportTool(report) {
        const newWindow = window.open('/adminresults/report/' + report.id + '/change/', '_blank');
        if (newWindow) {
          /**
           * See https://getcolor.atlassian.net/browse/ENG-584
           * ExternalLink opens in a new tab, while ensuring that the link protects from:
           * https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
           * https://mathiasbynens.github.io/rel-noopener
           * See lint rule react/jsx-no-target-blank
           */
          newWindow.opener = null;
        }
      }

      function queueRetraction(report) {
        scope.rereleaseModal.open(report.id, true);
      }

      function markAsHHChangeReviewed(report) {
        scope.dropdownOpened = false;
        $http.put('/api/v1/reports/' + report.id + '/mark_as_hh_update_reviewed').then(function() {
          ngToast.create('Marked as health history reviewed no rerelease required.');
        });
      }

      function withdraw(report) {
        scope.dropdownOpened = false;

        // TODO (LABX-4368): Simplify this branching logic once we're using ReleaseGroups for all reports
        if (report.release_group.release_group_id) {
          withdrawReleaseGroupModal
            .open(report.release_group.release_group_id)
            .result.then(() =>
              ngToast.create(
                `ReleaseGroup ${report.release_group.release_group_id} has been withdrawn.`
              )
            )
            .then(function() {
              $location.path('/reports/new/list');
            });
        } else {
          $http.put('/api/v1/reports/' + report.id + '/withdraw').then(function() {
            ngToast.create('Withdrew the report.');
            $location.path('/reports/new/list');
          });
        }
      }

      scope.dropdownOptions = [
        {
          show: showRequestGeneticsBoardReviewBtn,
          onClick: requestGeneticsBoardReview,
          label: 'Request Genetics Board Review',
        },
        {
          show: showSendBackForContentReview,
          onClick: sendBackForContentReview,
          label: 'Send Back for Content Review',
        },
        putOnHoldOption,
        putOffHoldOption,
        {
          show: showReviewedHHChange,
          onClick: markAsHHChangeReviewed,
          label: 'Reviewed HH change and no rerelease required',
        },
        {
          show:
            (report.revision || auth.currentUser.permissions['results.withdraw_report']) &&
            isPreRelease,
          onClick: withdraw,
          label: 'Withdraw',
        },
        {
          show: showGenerateReportData,
          onClick: generateReportedData,
          label: 'Generate Report Data',
        },
        {
          show: showInstantReportTool,
          onClick: goToInstantReportTool,
          label: 'Instant Report Tool',
        },
        {
          show: showQueueRetractionOption,
          onClick: queueRetraction,
          label: 'Queue Retraction',
        },
      ];
      scope.showDropdown = scope.dropdownOptions.some(function(o) {
        return o.show;
      });

      scope.releaseToPathologist = function(report) {
        if (!report.generated_content) {
          ngToast.create({
            className: 'error',
            content: 'A template must be applied before releasing to pathologist.',
          });
          return;
        }
        releaseToPathologistModal.open([report]).result.then(function() {
          report.kit_order.test_type === testTypes.covid19
            ? $location.path('/covid_reports/list')
            : $location.path('/reports/new/list');
        });
      };

      scope.releaseToGeneticsBoard = function(report) {
        scope.releasing = true;
        report.genetics_board_review_prepared = true;
        report
          .$save()
          .then(function() {
            $location.path('/reports/queued_for_board_review/list');
          })
          .finally(function() {
            scope.releasing = false;
          });
      };

      scope.markAsReviewedByGeneticsBoard = function(report) {
        scope.releasing = true;
        report.genetics_board_reviewed = true;
        report
          .$save()
          .then(function() {
            $location.path('/reports/prepared_for_board_review/list');
          })
          .finally(function() {
            scope.releasing = false;
          });
      };

      scope.releaseToPhysician = function(report) {
        const reportsToSignOut = [];
        const reportsToApprove = [];
        if (scope.canSignOut) {
          reportsToSignOut.push(report);
        } else {
          reportsToApprove.push(report);
        }
        releaseToPhysicianModal.open(reportsToSignOut, reportsToApprove).result.then(function() {
          $location.path('/reports/content_reviewed,board_reviewed/list');
        });
      };

      scope.approveRelease = function(report) {
        const state = report.state;
        approveReleaseModal.open([report]).result.then(function() {
          $location.path('/reports/' + state + '/list');
        });
      };

      function updateReportState(report, state) {
        scope.releasing = true;
        report[state] = true;
        report
          .$save()
          .then(function() {
            $location.path(`/reports/${state}/list`);
          })
          .finally(function() {
            scope.releasing = false;
          });
      }

      scope.readyForRelease = function(report) {
        updateReportState(report, 'release_ready');
      };

      scope.sendToOrderingPhysician = function(report) {
        updateReportState(report, 'send_to_ordering_physician');
      };

      scope.toggleDropdown = function() {
        scope.dropdownOpened = !scope.dropdownOpened;
      };
    },
  };
};
