/**
 * @prettier
 */

/**
 * This renders the full list of references, usually at the bottom of each page
 * then sends a global event to each `<reference/>` to render each reference ID in order
 * and matched with the unique set called on this card.
 */
module.exports = function($rootScope, $timeout, Reference) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      references: '@',
    },
    transclude: true,
    template: `<div class="card card--references" ng-if="references">
    <div class="card__inner">
      <h2 class="references__title">References</h2>
      <div class="card__content">
        <div class="references">
          <ol>
            <li ng-repeat="reference in referencesResolved", id="reference_{{reference.id}}">
              <span full-reference="reference"></span>
            </li>
          </ol>
        </div>
        <ng-transclude></ng-transclude>
      </div>
    </div></div>`,
    link: function(scope, elem, attrs) {
      // TODO: Make references global service so that
      // we don't need to fetch everytime.
      if (scope.references) {
        // Remove duplicate values
        const unique = [];
        scope.references.split(',').filter(function(r) {
          if (unique.indexOf(r) == -1) {
            unique.push(r);
          }
        });
        scope.references = unique.join(',');
        $rootScope.$broadcast('references:requireReferences');
        Reference.get({ id__in: scope.references }).$promise.then(function(res) {
          // Related to above comment, temporary 1s timeout to ensure references render on client for varying network conditions.
          $timeout(function() {
            $rootScope.$broadcast('references:renderReference', res);
          }, 1500);
          scope.referencesResolved = res.results;
        });
      }
    },
  };
};
