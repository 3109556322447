module.exports = function CounselingResultsController($scope, $routeParams, $http, $window) {
  "ngInject"

  $scope.types = ['upcoming', 'search', 'call_needed', 'positive_reminder']
  $scope.typeTitles = {
    'upcoming': 'Upcoming Counseling',
    'search': 'Find a Result',
    'call_needed': 'External Order, Call Needed',
    'positive_reminder': 'Positive, Reminder Needed'
  }
  $scope.type = $routeParams.type

  const fields_to_request = [
    'id', 'sample_id', 'test_type', 'state', 'released_at', 'is_result_positive', 'appointment', 'opened_at',
    'user__last_name', 'user__first_name', 'user__birthday',
  ]

  /** Query the counseling info endpoint with the specified queryparams **/
  $scope.loadResults = function(params) {
    $scope.loadingResults = true
    $scope.results = []
    // Use the 'fields' queryparam to request a subset of the fields that this endpoint returns by default
    params.fields = fields_to_request.join(',')
    $http.get('/api/v1/counseling/info', { params: params })
      .then((res) => {$scope.results = res.data.results})
      .finally(() => {$scope.loadingResults = false})
  }

  if ($scope.type == 'search') {
    $scope.query = ''
    $scope.findResult = function(query) {
      const params = { query: query }
      $scope.loadResults(params)
    }
  } else {
    const params = { filter: $scope.type }
    $scope.loadResults(params)
  }

  /**
   * Sort results by upcoming GC appointments
   * If there is no appointment or an appointment has happened in the past, place at the bottom
   **/
  $scope.resultSorter = function(result) {
    if (result.appointment) {
      const endTime = new Date(result.appointment.end_time)
      const now = new Date()
      if (endTime > now) {
        return endTime
      }
    }
    return Number.POSITIVE_INFINITY
  }

  $scope.viewResult = function(result) {
    $window.open('/counseling/' + result.id)
  }
}
