"use strict"

module.exports = () => {
  "ngInject"

  return {
    restrict: 'E',
    template: `
<div class="banner banner--orange">
  <div class="banner__inner">
    <div class="banner__content">
      <span class="hide--web">🎭  Mirroring {{auth.currentUser.email}}.  Please stop before mirroring another user.
        <div class="btn btn-primary u--margin-left-20" ng-click="stopImpersonating()">
          Stop
        </div>
      </span>
    </div>
  </div>
</div>`,
    link: (scope) => {
      scope.stopImpersonating = () => {
        window.location.href = '/impersonate/stop/'
      }
    }
  }
}
