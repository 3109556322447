"use strict"

/**
 * Video directive for the videos that appear as thumbnails with an image background
 * and a play button in the center, open appearing in 3-up lists
 */
module.exports = function () {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      videoName: '@',
      imageName: '@',
      contentClass: '@?'
    },
    template: `
    <div class="clr-video-thumbnail">
      <div
        class="clr-video-thumbnail__frame play-button__wrapper"
        ng-class="{ 'img--{{imageName}}': true }"
        vimeo-modal-player="{{ videoName }}"
      >
        <div class="play-button play-button--center"></div>
      </div>
    </div>
    `,
    link: function (scope) {
      scope.contentClass = scope.contentClass ? scope.contentClass : ''
    }
  }
}
