module.exports = function MultipleOrdersModal($modal) {
  'ngInject'
  return {
    open: function(state) {
      return $modal.open({
        templateUrl: '/kit_registrations/multiple_orders_modal.html',
        controller: function($scope, $modalInstance, $location) {
          'ngInject'
          $scope.continue = function() {
            $location.path('/react/create-account').search({next: '/genetics/activate/barcode'})
            $modalInstance.close()
          }
        },
        size: 'sm'
      })
    }
  }
}
