/**
 * @ngdoc module
 * @name kitOrderResource
 *
 * @description
 * The `kitOrderResource` provides resources for
 * variant classification API.
 */
module.exports = angular.module('kitOrderResource', ['clrResource'])

  /**
   * @ngdoc service
   * @name KitOrder
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `kit_orders` REST API.
   */
  .factory('KitOrder', function(resource) {
    return resource(
      '/api/v1/kit_orders/:order_number'
      , { order_number: '@order_number', format: 'json' }
    )
  })

  .factory('Purchase', function(resource) {
    return resource(
      '/api/v1/purchases/:purchase_number'
      , { purchase_number: '@purchase_number', format: 'json' }
    )
  })

  /**
   * @ngdoc service
   * @name OrderPendingApproval
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `orders_pending_approval` REST API.
   */
  .factory('OrderPendingApproval', function(resource) {
    return resource('/api/v1/orders_pending_approval', {format: 'json'})
  })

  /**
   * @ngdoc service
   * @name OrderLog
   * @memberof OrderLogResource
   *
   * @description
   * A resource for `order_logs` REST API.
   */
  .factory('OrderLog', function(resource) {
    return resource('/api/v1/order_logs', { format: 'json', page_size: 1000 })
  })

  /**
   * @ngdoc service
   * @name KitStatus
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `kit_status` REST API.
   */
  .factory('KitStatus', function(resource) {
    return resource(
      '/api/v1/kit_status'
      , { format: 'json' }
    )
  })

  /**
   * @ngdoc service
   * @name HealthcareProvider
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `healthcare_providers` REST API.
   */
  .factory('HealthcareProvider', function(resource) {
    return resource(
      '/api/v1/healthcare_providers/:id'
      , { id: '@id', format: 'json' }
    )
  })

  /**
   * @ngdoc service
   * @name PromotionalCode
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `promotional_codes` REST API.
   */
  .factory('PromotionalCode', function(resource) {
    return resource(
      '/api/v1/promotional_codes/:code'
      , { code: '@code', format: 'json' }, {
        get: {
          method : 'GET',
          cache : true
        },
        sync: {
          method : 'GET'
        }
      }
    )
  })

  /**
   * @ngdoc service
   * @name Claim
   * @memberof kitOrderResource
   *
   * @description
   * A resource for Claims REST API.
   */
  .factory('Claim', function(resource) {
    return resource(
      '/api/v1/claims/:token'
      , { token: '@token', format: 'json' }
      , { get: { method : 'GET', cache : true }, sync: { method : 'GET' } }
    )
  })

  /**
   * @ngdoc service
   * @name Waitlist
   * @memberof kitOrderResource
   *
   * @description
   * A resource for `waitlists` REST API.
   */
  .factory('Waitlist', function(resource) {
    return resource(
      '/api/v1/waitlists/:id'
      , { id: '@id', format: 'json' }
    )
  })
