'use strict'

module.exports = function(stateAcronyms) {
  "ngInject"

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      country: '=stateCountry'
    },
    link: function(scope, element, attrs, ctrl) {
      const usStateValidator = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true
        }
        return stateAcronyms.indexOf(viewValue) >= 0
      }

      // Rather than trying to add/remove the specific formatters from
      // ngModelController.$parsers/$formatters, we use a single
      // `formatter` function and swap out the `currentFormatter`
      // reference when the country changes.
      const dummyFormatter = function(inputValue) {
        return inputValue
      }

      const uppercaseFormatter = function(inputValue) {
        if (inputValue === undefined) { inputValue = '' }
        const uppercased = inputValue.toUpperCase()
        if (uppercased !== inputValue) {
          ctrl.$setViewValue(uppercased)
          ctrl.$render()
        }
        return uppercased
      }

      let currentFormatter = dummyFormatter
      const formatter = function(inputValue) {
        return currentFormatter(inputValue)
      }

      ctrl.$parsers.push(formatter)
      ctrl.$formatters.push(formatter)

      scope.$watch('country', function(newValue, oldValue) {
        if (newValue == 'US') {
          ctrl.$validators.state = usStateValidator
          currentFormatter = uppercaseFormatter
          uppercaseFormatter(scope.$eval(attrs.ngModel))
        } else {
          delete ctrl.$validators['state']
          delete ctrl.$validators['shippableState']
          ctrl.$setValidity('state', true)
          ctrl.$setValidity('shippableState', true)
          currentFormatter = dummyFormatter
        }

        const oldState = ctrl.$viewValue
        if (oldState && oldState.length > 0) {
          ctrl.$setViewValue("", "default")
          ctrl.$setViewValue(oldState, "default")
        }
      })
    }
  }
}
