module.exports = function saveDraft($location, $interval, $q, $route, $window, currentDraftPhysicianOrderVersion, DraftPhysicianOrder,
  ngToast, PhysicianOrderFormData, ProviderPlatformModals, waffle) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/save_draft.html',
    link: function(scope, element, attrs, formCtrl) {

      let dirty = true
      let orderWatcher

      scope.isSaveDisabled = function() {
        const order = PhysicianOrderFormData.get()
        if (!order || !dirty || order._submitting)
          return true
        return !order.patient_profile.first_name || !order.patient_profile.last_name
      }

      scope.saveDraft = function() {
        const order = PhysicianOrderFormData.get()
        // Check if something has written over this.
        checkIfUpdatedSinceLastRead(order).then(function(wasUpdated) {
          if (wasUpdated) {
            return ProviderPlatformModals.openDraftUpdatedModal(order.draft_requisition_number)
          }
          if (scope.isSaveDisabled()) return
          updateDraftToDb(order)
        })
      }

      function checkIfUpdatedSinceLastRead(order) {
        // Check if there exists the draft has been updated since we last read it by looking at timestamp
        if (!order.draft_requisition_number) {
          return $q.when(false)
        }
        return DraftPhysicianOrder.get({requisition_number: order.draft_requisition_number}).$promise
          .then(function(existingDraft) {
            return existingDraft.updated_at != order.draft_updated_at
          })
          .catch(function() {
            // No drafts found means order must have been placed and therefore updated
            return true
          })
      }

      function updateDraftToDb(order) {
        const data = angular.copy(order)
        // Destroy watch on existing order while we save
        if (orderWatcher) orderWatcher()
        // Strip CC info
        delete data.payment['creditCard']
        const draft = {
          'json_payload': data,
          'patient_first_name': order.patient_profile.first_name,
          'patient_last_name': order.patient_profile.last_name,
          'version': currentDraftPhysicianOrderVersion,
        }
        if (order.patient_profile.birthday) {
          draft['patient_birthday'] = order.patient_profile.birthday
        }
        // If there is an existing provider in DB we want to store that in the draft
        if (order.ordering_physician_profile && order.ordering_physician_profile.id) {
          draft['ordering_physician'] = angular.copy(order.ordering_physician_profile)
        }
        if (order.primary_contact && order.primary_contact.id) {
          draft['ordering_physician'] = angular.copy(order.primary_contact)
        }
        // Update draft if already requisition number, otherwise create new one
        let savePromise = null
        if (order.draft_requisition_number) {
          //draft.requisition_number = order.draft_requisition_number
          savePromise = new DraftPhysicianOrder(draft).$update({requisition_number: order.draft_requisition_number})
        } else {
          savePromise = new DraftPhysicianOrder(draft).$save()
        }
        savePromise.then(function(res) {
          ngToast.create('Your progress has been saved')
          order.draft_requisition_number = res.requisition_number
          order.draft_updated_at = res.updated_at
          watchOrder(order)
          dirty = false
        })
      }

      scope.cancelOrder = function() {
        const order = PhysicianOrderFormData.get()
        ProviderPlatformModals.openCancelOrderModal(
          order.draft_requisition_number
        ).result.then(function() { $location.path('/providers/home') })
      }

      function watchOrder(order) {
        scope.order = order
        orderWatcher = scope.$watch('order', function(newValue, oldValue) {
          if (newValue != oldValue) { dirty = true }
        }, true)
      }

      const THIRTY_SECONDS_IN_MILLIS = 30 * 1000
      const autoSaveHeartBeat = $interval(function() {
        scope.saveDraft()
      }, THIRTY_SECONDS_IN_MILLIS)

      scope.$on('$destroy', function() {
        $interval.cancel(autoSaveHeartBeat);
      });
    }
  }
}
