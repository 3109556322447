'use strict'

module.exports = function($scope, $http, auth, ngToast, Address) {
  'ngInject'

  $scope.changingInfo = false

  const resetAddress = function() {
    const contactAddress = new Address()
    if (auth.currentUser.phone) {
      contactAddress.phone_country = auth.currentUser.phone.country
      contactAddress.phone_number = auth.currentUser.phone.national_number
    }
    if (auth.currentUser.contact_address) {
      contactAddress.$get({ id: auth.currentUser.contact_address })
    }
    $scope.contactAddress = contactAddress
  }
  resetAddress()
  $scope.changeInfoSubmit = function(user, contactAddress) {
    $scope.contactAddress = new Address()
    $scope.contactAddress.user = auth.currentUser.id
    $scope.contactAddress.line1 = contactAddress.line1
    $scope.contactAddress.line2 = contactAddress.line2
    $scope.contactAddress.city = contactAddress.city
    $scope.contactAddress.state = contactAddress.state
    $scope.contactAddress.postal_code = contactAddress.postal_code
    $scope.contactAddress.country = contactAddress.country
    $scope.contactAddress.full_name = user.firstName + " " + user.lastName
    $scope.contactAddress.phone_country = contactAddress.phone_country
    $scope.contactAddress.phone_number = contactAddress.phone_number
    $scope.contactAddress.$save()
      .then(function(res) {
        const data = {
          first_name: user.firstName,
          last_name: user.lastName,
          contact_address: res.id,
          phone_number: {
            national_number: contactAddress.phone_number,
            country: contactAddress.phone_country
          }
        }
        return $http.patch('/api/v1/accounts/' + auth.currentUser.id, data)
          .then(function(res) {
            return auth.sync(true)
          })
          .then(function(res) {
            ngToast.create('Your contact info has been successfully changed.')
          })
      })
      .then(function() {
        $scope.changingInfo = false
      })
  }

  $scope.cancelChangeInfo = function() {
    $scope.changingInfo = false
    if (!$scope.contactAddress.id) {
      resetAddress()
    }
  }

  $scope.changeInfo = function() {
    $scope.changingInfo = true
  }
}
