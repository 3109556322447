module.exports = function refulfillSample($http, $routeParams, $route, ngToast) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      order: '=',
      onEdit: '=?'
    },
    templateUrl: '/ordering_physicians/refulfill_sample.html',
    link: function(scope) {
      scope.cancel = function() {
        scope.onEdit && scope.onEdit()
      }

      scope.submitting = false

      scope.save = function() {
        const payload = {
          requisition_number: scope.order.requisition_number,
          kit_barcode: scope.order.sample.kit_barcode,
          accession_number: scope.order.sample.accession_number,
          collection_date: scope.order.sample.collection_date
        }

        scope.submitting = true
        $http.post('/api/v1/providers/refulfill_sample', payload)
          .then(function() {
            ngToast.create('New sample updated')
            $route.reload()
          }).catch(function() {
            ngToast.create({
              className: 'error',
              content: 'Update failed'
            })
          }).finally(function() {
            scope.submitting = false
            scope.cancel()
          })
      }
    }
  }
}
