/**
 * @prettier
 */

module.exports = function reportTestMethodology(
  $filter,
  $translate,
  interpretedGenes,
  testTypes,
  defaultLanguage
) {
  'ngInject';

  // This only covers breastOvarian19, hereditary30, and fh3. For wisdom9, see the wisdomReportTestMethodology directive
  // As of 9/20/2019, we are not allowing new v1 reports for BO19 and H30.
  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_test_methodology.html',
    replace: true,
    link: function(scope) {
      scope.testTypes = testTypes;
      scope.version = $filter('testMethodologyVersion')(scope.reportContent);
      if (angular.isUndefined(scope.language)) {
        scope.language = defaultLanguage;
      }

      const genesToAsterisk = ['CDK4', 'EPCAM', 'GREM1', 'MITF', 'PMS2', 'POLD1', 'POLE', 'APOB'];

      // FH M&L with version less than 5 should have PCSK9 with asterisk.
      if (scope.reportContent.template.test_type == testTypes.fh3 && scope.version < 5) {
        genesToAsterisk.push('PCSK9');
      }

      const genesToDoubleAsterisk = scope.version < 7 ? ['LDLR'] : [];
      const asteriskedGenes = [];
      if (scope.reportContent.template.test_type != testTypes.breastOvarian19) {
        angular.forEach(
          $filter('orderBy')(interpretedGenes[scope.reportContent.template.test_type]),
          function(gene) {
            if (genesToAsterisk.indexOf(gene) > -1) {
              asteriskedGenes.push(gene + '*');
            } else if (genesToDoubleAsterisk.indexOf(gene) > -1) {
              asteriskedGenes.push(gene + '**');
            } else {
              asteriskedGenes.push(gene);
            }
          }
        );
      }

      const conditionalNewYorkLabSentence = '';
      // TODO: Add conditional New York Lab sentence to New York-only methodologies, currently empty string, please rewrite logic as needed (https://getcolor.atlassian.net/browse/ENG-72)
      // if(scope.reportContent.sample.isFromNewYork) { conditionalNewYorkLabSentence = 'Sanger confirmation studies were performed at Baylor College of Medicine, Medical Genetics Laboratories, 2450 Holcombe, Grand Blvd., Houston, TX 77021. ' }

      // BO19
      if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
        if (scope.version > 4) {
          angular.forEach(
            $filter('orderBy')(interpretedGenes[scope.reportContent.template.test_type]),
            function(gene) {
              if (genesToAsterisk.indexOf(gene) > -1) {
                asteriskedGenes.push(gene + '*');
              } else {
                asteriskedGenes.push(gene);
              }
            }
          );
          scope.listOfGenes = asteriskedGenes.join(', ');
        } else {
          scope.listOfGenes = $filter('orderBy')(
            interpretedGenes[scope.reportContent.template.test_type]
          ).join(', ');
        }
        $translate(
          [
            'RESULT.METHODOLOGY.BO19.P1_V' + scope.version + '_MD',
            'RESULT.METHODOLOGY.BO19.P4_V' + scope.version + '_MD',
          ],
          {
            conditionalNewYorkLabSentence: conditionalNewYorkLabSentence,
          }
        ).then(function(translations) {
          scope.p1 = translations['RESULT.METHODOLOGY.BO19.P1_V' + scope.version + '_MD'];
          scope.p4 = translations['RESULT.METHODOLOGY.BO19.P4_V' + scope.version + '_MD'];
        });

        // H30 and FH3
      } else {
        let mType;
        if (scope.reportContent.template.test_type == testTypes.hereditary30) {
          mType = 'H30';
        } else if (scope.reportContent.template.test_type == testTypes.fh3) {
          mType = 'FH3';
        }
        scope.listOfGenes = asteriskedGenes.join(', ');
        $translate(
          [
            'RESULT.METHODOLOGY.' + mType + '.P1_V' + scope.version + '_MD',
            'RESULT.METHODOLOGY.' + mType + '.P3_V' + scope.version + '_MD',
            'RESULT.METHODOLOGY.' + mType + '.P4_V' + scope.version + '_MD',
          ],
          { conditionalNewYorkLabSentence: conditionalNewYorkLabSentence },
          undefined,
          undefined,
          scope.language // We need to force the language because this directive is used in both standard and abbreviated reports
        ).then(function(translations) {
          scope.p1 = translations['RESULT.METHODOLOGY.' + mType + '.P1_V' + scope.version + '_MD'];
          scope.p3 = translations['RESULT.METHODOLOGY.' + mType + '.P3_V' + scope.version + '_MD'];
          scope.p4 = translations['RESULT.METHODOLOGY.' + mType + '.P4_V' + scope.version + '_MD'];
        });

        if (scope.reportContent.template.test_type == testTypes.fh3 && scope.version > 5) {
          $translate(
            ['RESULT.METHODOLOGY.' + mType + '.P2_V' + scope.version + '_MD'],
            scope.language // We need to force the language because this directive is used in both standard and abbreviated reports
          ).then(function(translations) {
            scope.p2 =
              translations['RESULT.METHODOLOGY.' + mType + '.P2_V' + scope.version + '_MD'];
          });
        }
      }
    },
  };
};
