const getAbsolutePath = require("monoweb/django_apps/core/static/js/absolute_path");

const cdnify = require("monoweb/django_apps/core/static/js/cdnify");
const resolvers = require("monoweb/django_apps/core/static/js/resolvers");

!(function() {
  "use strict";

  const app = angular.module("colorApp", [
    "ngRoute",
    "ngAnimate",
    "ngAria",
    "ngSanitize",
    "ngToast",
    "nvd3",
    "angularPayments",
    "hc.marked",
    "http-auth-interceptor",
    "pascalprecht.translate",
    "fiestah.money", //Angular monetary input validation
    "duScroll",
    "ngclipboard",
    "rt.popup",
    "ui.bootstrap",
    "ui.scrollfix",
    "ui.mask",
    "ui.select",
    "accountServices",
    "angularFileUpload",
    "angucomplete-alt",
    "clrFilter",
    "clrStaticFiles",
    "clrNavigation",
    "clrPhoneNumbers",
    "clrLocation",
    "clrProvider",
    "clrRaven",
    "clrRequest",
    "clrConstant",
    "clrModal",
    "clrVideo",
    "clrStripe",
    "clrGeographical",
    "clrWaffle",
    "coreDirectives",
    /**
     * benefits module are related to routes like `https://staging.color.com/go/jefferson`
     * Google Analytics:
     * https://analytics.google.com/analytics/web/#/report/content-engagement-flow/a50707607w93103344p96946678/_r.segment-0=~2Fgo~2F&_r.segment-0-matchType=BEGINS_WITH&_r.segment-0-name=~2Fgo~2F&_r.dimension=analytics.landingPagePath&_r.steps=13
     * The routes were referenced in `$COLOR_ROOT/monoweb/django_apps/frontend/app/modules/marketing/marketing.module.js`
     */
    require("monoweb/django_apps/benefits/js").name,
    require("monoweb/django_apps/home/js").name,
    require("monoweb/django_apps/results/static/js/results").name,
    require("monoweb/django_apps/core/static/js/clr_a11y.module").name,
    require("monoweb/django_apps/users/js").name,
    require("monoweb/django_apps/users/js/accounts").name,
    require("monoweb/django_apps/users/js/insights").name,
    "healthProfileDirectives",
    "healthProfileFilter",
    "healthProfileResource",
    require("monoweb/django_apps/counseling/js").name,
    "physicianResource",
    "reportModal",
    "resultResource",
    "resultService",
    require("monoweb/django_apps/kit_orders/js").name,
    require("monoweb/django_apps/kit_registrations/js").name,
    require("monoweb/django_apps/ordering_physicians/js").name,
    require("monoweb/django_apps/results_v2/js").name,
    "familyTestingDirectives",
    "familyTestingFilters",
    "familyTestingServices",
    require("monoweb/django_apps/files/js").name,
    require("monoweb/django_apps/counseling/js/schedulizer/services").name
  ]);

  app.config([
    "colorRouteProvider",
    "$httpProvider",
    "$locationProvider",
    function(colorRouteProvider, $httpProvider, $locationProvider) {
      $locationProvider.hashPrefix("!").html5Mode({
        enabled: true
      });

      // Set defaults for meta title/description/image
      colorRouteProvider.setMetaTagDefaults({
        title: "Color | Realize the promise of population genomics",
        description:
          "Color Population Health is the industry leading, genetics-driven platform that enables personalized care delivery to improve health at scale.",
        image: cdnify("social/logo_opaque_padded.png")
      });

      /*
       * Route configs:
       * - auth:
       *   - requireLogin (defualt: true)
       *   - requireAnonymous (default: false)
       *   - requireEmailConfirmation (default: requireLogin)
       *   - requireSex (default: false)
       *   - requireHealthHistoryCompleted (default: false)
       * - navigation:
       *   - showRight (default: true)
       *   - showLeft (default: true)
       *   - affixed (default: false)
       *   - showSignInAsButton (default: false)
       *   - showStore (default: true)
       *   - setCurrentProgress (default: false)
       *   - currentStepProgress (default: false)
       *   - active (default: false)
       *   - subnavActive (default: false)
       *   - showPromoBanner (default: false)
       *   - showPromoBannerType (default: false)
       *   - layout (default: standard)
       */

      colorRouteProvider
        // 404
        .when("/404", {
          templateUrl: "/404.html",
          seo: {
            title: "Page Not Found - Color",
            description: ""
          },
          auth: {
            requireLogin: false,
            requireEmailConfirmation: false
          }
        })

        // Video Scripts
        // FIXME: Google analytics show no access to `/video` routes. These probably can be deleted.
        // Search example, shows starts with `/go`, but nothing that starts with `/video`
        // https://analytics.google.com/analytics/web/#/report/content-engagement-flow/a50707607w93103344p96946678/_r.segment-0=~2Fgo~2F&_r.segment-0-matchType=BEGINS_WITH&_r.segment-0-name=~2Fgo~2F&_r.dimension=analytics.landingPagePath&_r.steps=13&_r.segment-1=~2Fvideo&_r.segment-1-matchType=BEGINS_WITH&_r.segment-1-name=video/
        .when(
          "/video/about-genetic-testing-script/hereditary-high-cholesterol-test",
          {
            templateUrl: "/video/about_genetic_testing_script.html",
            seo: {
              title:
                "About Genetic Testing for Hereditary High Cholesterol Test - Color",
              description:
                "Useful information about genetic testing for hereditary high cholesterol test at Color."
            },
            auth: {
              requireLogin: false,
              requireEmailConfirmation: false
            },
            navigation: {
              showLeft: false,
              showRight: false,
              layout: "minimum"
            }
          }
        )
        .when("/video/about-genetic-testing-script/the-color-test", {
          templateUrl: "/video/about_genetic_testing_script.html",
          seo: {
            title: "About Genetic Testing for The Color Test - Color",
            description:
              "Useful information about genetic testing for the Color test at Color."
          },
          auth: {
            requireLogin: false,
            requireEmailConfirmation: false
          },
          navigation: {
            showLeft: false,
            showRight: false,
            layout: "minimum"
          }
        })
        .when("/video/about-genetic-testing-script/hereditary-cancer-test", {
          templateUrl: "/video/about_genetic_testing_script.html",
          seo: {
            title: "About Genetic Testing for Hereditary Cancer Test - Color",
            description:
              "Useful information about genetic testing for hereditary cancer test at Color."
          },
          auth: {
            requireLogin: false,
            requireEmailConfirmation: false
          },
          navigation: {
            showLeft: false,
            showRight: false,
            layout: "minimum"
          }
        })
        .when(
          "/video/about-genetic-testing-script/breast-ovarian-cancer-test",
          {
            templateUrl: "/video/about_genetic_testing_script.html",
            seo: {
              title:
                "About Genetic Testing for Breast Ovarian Cancer Test - Color",
              description:
                "Useful information about genetic testing for breast and ovarian cancer test at Color."
            },
            auth: {
              requireLogin: false,
              requireEmailConfirmation: false
            },
            navigation: {
              showLeft: false,
              showRight: false,
              layout: "minimum"
            }
          }
        )
        .when("/video/about-genetic-testing-script/brca-test", {
          templateUrl: "/video/about_genetic_testing_script.html",
          seo: {
            title: "About Genetic Testing for BRCA Test - Color",
            description:
              "Useful information about genetic testing for BRCA Test at Color."
          },
          auth: {
            requireLogin: false,
            requireEmailConfirmation: false
          },
          navigation: {
            showLeft: false,
            showRight: false,
            layout: "minimum"
          }
        })
        .when(
          "/video/about-genetic-testing-script/hereditary-heart-health-test",
          {
            templateUrl: "/video/about_genetic_testing_script.html",
            seo: {
              title:
                "About Genetic Testing for Hereditary Heart Health Test - Color",
              description:
                "Useful information about genetic testing for hereditary heart health test at Color."
            },
            auth: {
              requireLogin: false,
              requireEmailConfirmation: false
            },
            navigation: {
              showLeft: false,
              showRight: false,
              layout: "minimum"
            }
          }
        )
        .when(
          "/video/about-genetic-testing-script/tier-1-hereditary-conditions-test",
          {
            templateUrl: "/video/about_genetic_testing_script.html",
            seo: {
              title:
                "About Genetic Testing for Tier 1 Hereidtary Conditions Test - Color",
              description:
                "Useful information about genetic testing for tier 1 hereditary conditions test at color."
            },
            auth: {
              requireLogin: false,
              requireEmailConfirmation: false
            },
            navigation: {
              showLeft: false,
              showRight: false,
              layout: "minimum"
            }
          }
        )
        // June, 2017: We changed video script paths so we are redirecting the following legacy paths.
        .when("/video/about-genetic-testing-script", {
          redirectTo:
            "/video/about-genetic-testing-script/hereditary-cancer-test"
        })
        .when("/video/about-genetic-testing-script-breast-ovarian-19", {
          redirectTo:
            "/video/about-genetic-testing-script/breast-ovarian-cancer-test"
        })

        .when("/consent/routing-authorization", {
          templateUrl: "/users/routing_authorization.html",
          seo: {
            title: "Employer Benefits - Consent",
            description: ""
          },
          controller: "RoutingAuthorizationController",
          auth: {
            requireEmailConfirmation: false
          },
          navigation: { blue: true }
        })
        .when("/getkits", {
          redirectTo: function() {
            return "/support/question?issue=RequestKitsOffice";
          }
        })
        .when("/wisdomstudy/informed-consent", {
          templateUrl: "/home/wisdom/consent.html",
          seo: {
            title: "Informed Consent - Color",
            description: "Read Color's informed consent."
          },
          controller: "HomeStaticController",
          auth: {
            requireLogin: false,
            requireEmailConfirmation: false
          }
        })
        // This URL is used in actual kit package. Be careful if you are changing this.
        .when("/wisdomstudy", {
          redirectTo: function() {
            return "/react/genetics/activate";
          }
        })
        .when("/wisdomstudy/complete", {
          templateUrl: "/kit_registrations/complete.html",
          seo: {
            title: "Activate your Color kit - Color",
            description: ""
          },
          controller: "ActivationCompleteController",
          auth: { loginPath: "/react/create-account" },
          navigation: {
            blue: true,
            subnavActive: "activate"
          },
          resolve: resolvers.kitStatus
        })

        .when("/family-testing/create-account", {
          templateUrl: "/family_testing/account_creation.html",
          controller: "FamilyTestingAccountCreationController",
          auth: {
            requireLogin: false
          }
        })
        .when("/family-testing/enroll", {
          templateUrl: "/family_testing/enroll_templates.html",
          controller: "FamilyTestingInitializationController",
          auth: {
            loginPath: "/react/create-account",
            requireEmailConfirmation: false
          },
          navigation: {
            blue: true
          },
          resolve: resolvers.familyTestingEligibility
        })
        .when("/family-testing/enroll/results", {
          templateUrl: "/family_testing/results.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          controller: "FamilyTestingResultsController",
          auth: {
            loginPath: "/react/create-account",
            requireEmailConfirmation: false
          },
          navigation: {
            blue: true,
            subnavCompact: true,
            showLeft: false
          }
        })
        .when("/family-testing/enroll/relatives", {
          templateUrl: "/family_testing/relatives.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          controller: "FamilyTestingRelativesController",
          auth: {
            loginPath: "/react/create-account",
            requireEmailConfirmation: false
          },
          navigation: {
            blue: true,
            subnavCompact: true,
            showLeft: false
          }
        })
        .when("/family-testing/enroll/review", {
          templateUrl: "/family_testing/review.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          controller: "FamilyTestingReviewController",
          auth: {
            loginPath: "/react/create-account",
            requireEmailConfirmation: false
          },
          navigation: {
            blue: true,
            subnavCompact: true,
            showLeft: false
          }
        })
        .when("/family-testing/enroll/confirmation", {
          templateUrl: "/family_testing/confirmation.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          controller: "FamilyTestingConfirmationController",
          navigation: {
            blue: true
          },
          auth: {
            loginPath: "/react/create-account",
            requireEmailConfirmation: false
          }
        })
        .when("/family-testing/enroll/reviewing", {
          templateUrl: "/family_testing/reviewing.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          auth: { requireLogin: false }
        })
        .when("/family-testing/enroll/complete", {
          templateUrl: "/family_testing/complete.html",
          seo: {
            title: "Enroll in Color's Family Testing Program - Color",
            description: ""
          },
          navigation: {
            blue: true
          },
          auth: { requireLogin: false }
        })
        .otherwise({ redirectTo: "/react" }); // Redirect to react page to route if not angular page
      // IE caches the XHR requests. In order to avoid this, we set an HTTP response header to mimic default behaviors of moderns browsers.
      $httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";
      // Configure CSRF.
      $httpProvider.defaults.xsrfHeaderName = "X-CSRFToken";
      $httpProvider.defaults.xsrfCookieName = angular
        .element("#csrf-cookie-name")
        .val();
    }
  ]);

  app.config([
    "$translateProvider",
    "$translatePartialLoaderProvider",
    function($translateProvider, $translatePartialLoaderProvider) {
      $translateProvider.useLoader("$translatePartialLoader", {
        urlTemplate: function(part, lang) {
          const elem = document.getElementById(
            "i18n-" + part + "-" + lang + "-url"
          );
          return elem.value;
        }
      });
      $translatePartialLoaderProvider.addPart("core");
      $translatePartialLoaderProvider.addPart("static");
      $translatePartialLoaderProvider.addPart("ordering_physicians");
      $translateProvider.use("en");
      $translateProvider.useSanitizeValueStrategy("sanitizeParameters");
      $translateProvider.useMissingTranslationHandler(
        "sentryTranslateExceptionHandler"
      );
    }
  ]);

  // angular ui-mask settings
  app.config([
    "uiMask.ConfigProvider",
    "device",
    function(uiMaskConfigProvider, device) {
      uiMaskConfigProvider.clearOnBlur(false);
      uiMaskConfigProvider.clearOnBlurPlaceholder(true);
      // Fixes Android-only keyboard issues with cursor position while typing, happens on accession/barcode/telephone input (https://github.com/angular-ui/ui-mask/issues/141)
      // Backspace behind a masking character does not work, no idea how to fix (https://github.com/angular-ui/ui-mask/issues/123)
      if (device.isAndroid) {
        uiMaskConfigProvider.eventsToHandle(["keyup", "click", "focus"]);
      }
    }
  ]);

  // angular-scroll settings
  app.value("duScrollDuration", 900).value("duScrollOffset", 68);

  app.run([
    "$rootScope",
    "$anchorScroll",
    "$document",
    "$filter",
    "$translate",
    "auth",
    "navigation",
    "KitCheckoutFlow",
    "sampleReuseFlow",
    "PhysicianOrderFormFlow",
    "ProviderAccountFlow",
    "FamilyTestingFlow",
    "routeHistory",
    "waffle",
    "ProviderFamilyTestingFlow",
    "a11y",
    function(
      $rootScope,
      $anchorScroll,
      $document,
      $filter,
      $translate,
      auth,
      navigation,
      KitCheckoutFlow,
      sampleReuseFlow,
      PhysicianOrderFormFlow,
      ProviderAccountFlow,
      FamilyTestingFlow,
      routeHistory,
      waffle,
      ProviderFamilyTestingFlow,
      a11y
    ) {
      // Experimental stripped Turbo landing page needs to have an auth
      // set for the payment flow, so check referrer to match the navigation service
      // setter in our routes
      const referrerUrl = $document[0].referrer.split("/");
      // All the Turbo experimental page routes begin with /t/
      if (referrerUrl[3] == "t") {
        // This property enables various flags inside the purchase flow
        auth.isAds = true;
      }

      // Comfortable offset that clears clr-tab navigation as well as card top padding/margin
      $anchorScroll.yOffset = 80;

      $rootScope.waffle = waffle;

      $rootScope["static"] = $filter("static");

      // Required event bind for async translation bundle loading
      // https://angular-translate.github.io/docs/#/guide/12_asynchronous-loading
      $rootScope.$on("$translatePartialLoaderStructureChanged", function() {
        $translate.refresh();
      });

      $rootScope.$on("$routeChangeStart", function(event, next) {
        /* eslint-disable angular/no-private-call */
        if (!next || !next.$$route) {
          return;
        }

        ProviderAccountFlow.route(next.$$route.originalPath);
        KitCheckoutFlow.route(next.$$route.originalPath);
        sampleReuseFlow.route(next.$$route.originalPath);
        PhysicianOrderFormFlow.route(next.$$route.originalPath);
        ProviderFamilyTestingFlow.route(next.$$route.originalPath);
        FamilyTestingFlow.route(next.$$route.originalPath);
        navigation.reset(next.$$route.navigation);
        routeHistory.push(next.$$route.originalPath);
        /* eslint-enable angular/no-private-call */
      });

      $rootScope.$on("$routeChangeSuccess", function(event, next) {
        // add aria label and role to images on page load
        a11y.labelImages();
      });
    }
  ]);

  // TODO: refactor common services into a generic module to be included by
  // both www and clinical JS apps.

  app.factory("colorAuthRouteFilter", [
    "authRouteFilter",
    "associatedSample",
    function(authRouteFilter, associatedSample) {
      return authRouteFilter(
        "/sign-in",
        "/react/dashboard",
        "/react/email-confirmation-required",
        "/",
        "/select-sex",
        "/providers/home",
        "/consent/routing-authorization",
        associatedSample
      );
    }
  ]);

  app.config([
    "ngToastProvider",
    function(ngToastProvider) {
      ngToastProvider.configure({
        animation: "fade",
        maxNumber: 1,
        timeout: 15000
      });
    }
  ]);

  // Angular prepends "unsafe" tag in ng-href to prevent XSS
  // Add blob to allow for blob downloads
  app.config(function($compileProvider) {
    "ngInject";
    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?|ftp|mailto|blob):/
    );
  });

  // Due to Angularjs 1.6 upgrade, bindings are no longer available in controller contructors
  // when running $compile.  Turn pre-assigned bindings back on.
  app.config(function($compileProvider) {
    "ngInject";
    $compileProvider.preAssignBindingsEnabled(true);
  });

  app.factory("routeHistory", function() {
    return [];
  });
})();
