module.exports = angular.module('homeResource', ['clrResource'])

  .factory('MarketingEmailContact', function(resource) {
    'ngInject'
    return resource(
      'api/v1/marketing_email_contacts'
      , { format: 'json' }
    )
  })

  .factory('RawDataFile', function(resource) {
    'ngInject'
    return resource(
      'api/v1/raw_data_file'
      , { format: 'json' }, {
        get: {
          method: 'GET',
          isArray: true
        }
      }
    )
  })

  .factory('PromoBanner', function(resource) {
    'ngInject'
    return resource(
      'api/v1/promo_banner'
      , { format: 'json' }, {
        get: {
          method: 'GET',
          cache: true
        }
      }
    )
  })
