module.exports = function clrPatientProfile($location, $modal, auth, waffle) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/order_testing_button.html',
    link: function(scope) {

      // As of Nov 1 2024, we are blocking all CPP orders
      scope.orderTestingButtonIsVisible = false;

      scope.goToPlaceOrder = function() {
        if (auth.currentUser.is_email_confirmed) {
          $location.url('/providers/place-order/')
        } else {
          auth.sync(true)
            .then(function(account) {
              if (account.is_email_confirmed) {
                $location.url('/providers/place-order')
              } else {
                openEmailConfirmationModal()
              }
            })
        }
      }

      function openEmailConfirmationModal() {
        return $modal.open({
          templateUrl: "/ordering_physicians/_confirm_email_modal.html",
          size: "md"
        })
      }
    }
  }
}
