module.exports = angular.module('userDirectives', [])
  .directive('clrAddress', require('./directives/clr_address'))
  .directive('isStateValid', require('./directives/is_state_valid'))
  .directive('stateField', require('./directives/state_field'))
  .directive('clrCountrySelect', require('./directives/clr_country_select'))
  .directive('clrUsStateSelect', require('./directives/clr_us_state_select'))
  .directive('clrAddressForm', require('./directives/clr_address_form'))
  .directive('clrPhoneInput', require('./directives/clr_phone_input'))
  .directive('clrBirthdayInput', require('./directives/clr_birthday_input'))
  .directive('clrSexInput', require('./directives/clr_sex_input'))
  .directive('clrGenderInput', require('./directives/clr_gender_input'))
  /**
   * BE AWARE: References are hard to find:
   * https://github.com/color/color/blob/7de49e804f61409e92655b4c5ff780b74d02d278/src/django_apps/kit_orders/templates/angular/kit_orders/order_account.jade#L8
   * create-account-form-v2
   */
  .directive('createAccountFormV2', require('./directives/create_account_form_v2'))
