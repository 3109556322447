"use strict"

/**
 * Enables the jQuery plugin for sticky table headers
 */

module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    link: function(scope, element) {
      element.stickyTableHeaders()
    }
  }
}
