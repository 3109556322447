module.exports = angular.module('counselingDirective', ['clrConstant'])
  .directive('gcSchedulizer', function(upcomingAppointment, chooseAppointment) {
    'ngInject'

    return {
      restrict: 'A',
      scope: {
        staticText: '=',
        onComplete: '&?',
        orderNumber: '=',
      },
      controller: function() {
        const ctrl = this
      },
      replace: true,
      link: function(scope, elem, attrs, ctrl) {

        // Sets text
        loadUpcomingAppointment()

        elem.bind('click', function() {
          chooseAppointment(scope.orderNumber)
        })

        function loadUpcomingAppointment() {
          return upcomingAppointment.get(scope.orderNumber)
            .then(function(appointment) {
              if (appointment) {
                if (!scope.staticText) {
                  elem.text("Reschedule Appointment")
                }
              } else {
                if (!scope.staticText) {
                  elem.text("Schedule Appointment")
                }
              }
            })
        }
      }
    }
  })

  .directive('uploadedCounselingNotes', () => {
    return {
      restrict: 'E',
      templateUrl: '/angular_js_templates/gc_tools/uploaded_counseling_notes.html',
      scope: {
        uploadedNotes: '=',
      }
    }
  })

  .directive('counselingNoteUploader', (fileUploader) => {
    return {
      restrict: 'E',
      template: '<clr-uploader uploader="uploader" min-files="1" label="Upload a counseling note"></clr-uploader>',
      scope: {
        uploadedNoteKey: '=',
        reportId: '='
      },
      link: (scope, elem) => {
        scope.uploader = fileUploader(
          {
            url: '/api/v1/counseling/counseling_notes/upload',
            formData: [{
              report: scope.reportId
            }],
            autoUpload: true,
            queueLimit: 1,
          }
        )
        // We only allow users to upload one file at a time, so we only need to watch the 0th element of the uploader's queue
        scope.$watch('uploader.queue[0].s3_key', () => {
          if (scope.uploader.queue[0]) {
            scope.uploadedNoteKey = scope.uploader.queue[0].s3_key
          } else {
            scope.uploadedNoteKey = null
          }
        })
      }
    }
  })

  .directive('checkboxGroup', () => {
    return {
      restrict: 'E',
      templateUrl: '/angular_js_templates/gc_tools/checkbox_group.html',
      scope: {
        questions: '=',
        answers: '=',
      }
    }
  })

  .directive('counselingNoteQuestionnaire', ($filter) => {
    return {
      restrict: 'E',
      templateUrl: '/angular_js_templates/gc_tools/counseling_note_questionnaire.html',
      scope: {
        noteOptions: '=',
        internalNote: '=',
        report: '=',
      },
      link: (scope, elem) => {
        scope.noteOptions = {
          results: {},
          discussion: {},
          nextSteps: {},
          otherTestsDiscussed: {}
        }

        scope.checkboxQuestions = {
          results: [
            {
              question: "Show VUS Details",
              propertyName: "showVusDetails"
            }
          ],
          discussion: [
            {
              question: "Discussed seeing a local GC",
              propertyName: "showLocalGCInfo"
            },
            {
              question: "Discussed lifestyle recommendations",
              propertyName: "showLifestyleRecommendations"
            },
            {
              question: "Discussed the Family Testing Program",
              propertyName: "showFamilyTestingProgramInfo"
            },
          ],
          nextSteps: [
            {
              question: "Share your results with providers and family.",
              propertyName: "showShareResultsSection"
            },
            {
              question: "Schedule an appointment with your provider.",
              propertyName: "showScheduleProviderAppointment"
            },
            {
              question: "Schedule an appointment with a genetic counselor in your area.",
              propertyName: "showScheduleGCAppointment"
            },
            {
              question: "Consider the Family Testing Program.",
              propertyName: "showFamilyTestingProgram"
            },
          ]
        }

        // Add an "Also discussed this patient's ___ results" checkbox for each of the user's other test types
        scope.checkboxQuestions.otherTestsDiscussed = []
        for (const testType in scope.report.reports_for_other_test_types) {
          scope.checkboxQuestions.otherTestsDiscussed.push({
            question: `Also discussed this patient's ${$filter('testTypeName')(testType)} results`,
            propertyName: testType,
          })
        }
      }
    }
  })

  .directive('aouCounselingNoteQuestionnaire', (testTypes, testOutcome) => {
    return {
      restrict: 'E',
      templateUrl: '/angular_js_templates/gc_tools/aou_counseling_note_questionnaire.html',
      scope: {
        noteOptions: '=',
        report: '=',
      },
      link: (scope, elem) => {
        scope.isSpanish = (scope.report.latest_completed_appointment && scope.report.latest_completed_appointment.language == 'Spanish')
        scope.isAouAcmg59Positive = scope.report.kit_order.test_type == testTypes.aouAcmg59 && scope.report.test_outcome == testOutcome.positive
        scope.isAouAcmg59Uninformative = scope.report.kit_order.test_type == testTypes.aouAcmg59 && scope.report.test_outcome != testOutcome.positive
        scope.isAouPgxV1 = scope.report.kit_order.test_type == testTypes.aouPgxV1
      }
    }
  })

  .directive('addCounselingNote', ($http, ngToast, auth, testTypes, testOutcome, publishCounselingNoteModal, waffle, environment) => {
    return {
      restrict: 'E',
      templateUrl: '/angular_js_templates/gc_tools/add_counseling_note.html',
      scope: {
        report: '=',
        publishedNoteKey: '=',
      },
      link: (scope, elem) => {
        scope.view = 'options'
        scope.noteOptions = {}
        scope.internalNote = ""
        scope.supportedLanguages = {
          // should match the SupportedLanguages Enum in template tags
          English: 'English',
          Spanish: 'Spanish'
        }
        scope.isSpanish = (scope.report.latest_completed_appointment && scope.report.latest_completed_appointment.language == scope.supportedLanguages.Spanish)
        // Lists the supported languages for test_types that can generate notes
        const noteLanguages = {
          [testTypes.hereditary30]: [scope.supportedLanguages.English],
          [testTypes.cardio30]: [scope.supportedLanguages.English],
          [testTypes.aouAcmg59]: scope.isSpanish ? [scope.supportedLanguages.Spanish, scope.supportedLanguages.English] : [scope.supportedLanguages.English],
          [testTypes.aouPgxV1]: scope.isSpanish ? [scope.supportedLanguages.Spanish, scope.supportedLanguages.English] : [scope.supportedLanguages.English]
        }
        const test_type = scope.report.kit_order.test_type
        scope.noteInfoByLanguage = {}

        // Determine whether we can generate a note for this report
        const supportedTestTypes = [testTypes.hereditary30, testTypes.cardio30, testTypes.aouAcmg59, testTypes.aouPgxV1]
        scope.isAouTestType = [testTypes.aouAcmg59, testTypes.aouPgxV1].includes(test_type)
        scope.canGenerateNote = false
        if (auth.currentUser.permissions['files.can_use_counseling_note_generator']) {
          scope.canGenerateNote = (supportedTestTypes.includes(test_type) &&
                                   (scope.report.test_outcome == testOutcome.positive || test_type == testTypes.aouPgxV1))
          // HDR- reports are still gated by a waffle
          if (test_type == testTypes.aouAcmg59 && scope.report.test_outcome != testOutcome.positive) {
            // nosemgrep:semgrep-rules.use-launchdarkly-react
            scope.canGenerateNote = waffle.switch_is_active('enable-aou-note-generation-11-2022')
          }
        }

        // Determines whether the user can be linked to the ReTool note generator
        scope.canUseRetoolGenerator = false;
        if (
          auth.currentUser.permissions["files.can_use_counseling_note_generator"] &&
          // nosemgrep:semgrep-rules.use-launchdarkly-react
          waffle.switch_is_active("enable-retool-note-generation-02-2024")
        ) {
          const testName = test_type.replace(/\s/g, "").toLowerCase()

          // nosemgrep:semgrep-rules.use-launchdarkly-react
          if (waffle.switch_is_active(`enable-${testName}-note-generation`)) {
            scope.canUseRetoolGenerator = true;
            if (environment == "prod") {
              scope.retoolBaseURL = "https://retool.color.com/apps/002d2ffe-eb7d-11ee-a8da-6778f5f0fdc5/Precision%20Care%20Tools/GC%20Note%20Generator";
            } else {
              scope.retoolBaseURL = "https://retool.staging.color.com/apps/002d2ffe-eb7d-11ee-a8da-6778f5f0fdc5/Precision%20Care%20Tools/GC%20Note%20Generator";
            }
          }
        }

        // Whether the user is using the note generation flow or the note upload flow
        scope.usingNoteGenerator = false

        // Updates the content and draftKey to the language specific information
        scope.updateContent = (language) => {
          scope.activeLanguage = language
          scope.noteContent = scope.noteInfoByLanguage[language].noteContent
          scope.draftNoteKey = scope.noteInfoByLanguage[language].draftNoteKey
        }

        const trix = angular.element('#counseling-note-editor')[0]

        const getEditorContents = () => {
          return trix.value
        }

        const setEditorContents = (html) => {
          // Delete editor contents
          const text = trix.editor.getDocument().toString()
          trix.editor.setSelectedRange([0, text.length])
          trix.editor.deleteInDirection("forward")

          // Insert new html
          trix.editor.setSelectedRange([0, 0])
          trix.editor.insertHTML(html)
        }

        /* Functions for transitioning between the different views in this component
         * State transitions:
         * options <---> preview <---> editor
         *                  |
         *                  |
         *                  v
         *            published note
         */

        scope.showOptions = () => {
          scope.view = 'options'
          scope.noteIsGenerated = false
        }

        scope.showPreview = () => {
          scope.view = 'preview'
        }

        scope.generateAndPreviewNote = () => {
          scope.loading = true

          // Add test_type languages to noteInfoByLanguage
          noteLanguages[test_type].forEach((language) => {
            scope.noteInfoByLanguage[language] = {}
          })

          Promise.all(
            noteLanguages[test_type].map((language) => generateNoteContent(language))
          )
            .then(() =>
              Promise.all(
                noteLanguages[test_type].map((language) => generatePdf(language))
              )
            )
            .then(() => scope.updateContent(noteLanguages[test_type][0]))
            .then(scope.showPreview)
            .finally(() => {
              scope.loading = false
              scope.noteIsGenerated = true
              // Run one $digest cycle after generating notes in various languages
              scope.$apply()
            })
        }

        scope.showEditor = () => {
          setEditorContents(scope.noteContent)
          scope.view = 'editor'
        }

        /* Use the content in the text editor to generate a pdf, then display it */
        scope.saveEdits = () => {
          scope.loading = true
          scope.noteInfoByLanguage[scope.activeLanguage].noteContent = getEditorContents()

          generatePdf(scope.activeLanguage)
            .then(() => scope.updateContent(scope.activeLanguage))
            .then(scope.showPreview)
            .finally(() => {
              scope.loading = false
            })
        }

        scope.publish = () => {
          publishCounselingNoteModal.open(publishNote).result.then((response) => {
            scope.publishedNoteKey = response.data
            ngToast.create('Success! Your note has been published.')
            addInternalNote()
          })
        }

        /* Functions for making API calls */

        const generateNoteContent = (language) => {
          // makes a copy of the noteOptions and sets the language to be used by the templates
          const noteOptions = Object.assign({}, scope.noteOptions)
          noteOptions.language = language
          const data = {
            note_options: noteOptions,
            report: scope.report.id
          }
          return $http.post('api/v1/counseling/counseling_notes/generate_content', data)
            .then((res) => {
              // saves content information by language. Used by updateContent() to swap content in the UI
              scope.noteInfoByLanguage[language].noteContent = res.data
            })
        }

        const generatePdf = (language) => {
          const data = {
            note_content: scope.noteInfoByLanguage[language].noteContent,
            report: scope.report.id,
            language: language
          }
          return $http.post('api/v1/counseling/counseling_notes/generate_pdf', data)
            .then((res) => {
              // saves content information by language. Used by updateContent() to swap content in the UI
              scope.noteInfoByLanguage[language].draftNoteKey = res.data
            })
        }

        /* Make an api request to publish the counseling note draft. Returns a promise. */
        const publishNote = () => {
          let keys;
          // If note is generated get data from noteInfoByLanguage otherwise its a single note that has been uploaded
          if (scope.noteIsGenerated) {
            const languages = noteLanguages[test_type]
            keys = languages.map(language => scope.noteInfoByLanguage[language].draftNoteKey)
          } else {
            keys = [scope.draftNoteKey]
          }
          const data = {
            keys,
            report: scope.report.id,
          }
          return $http.post('api/v1/counseling/counseling_notes/publish', data)
        }

        /* Save the counselor's internal notes */
        const addInternalNote = () => {
          if (scope.internalNote) {
            const data = {
              report: scope.report.id,
              text: scope.internalNote
            }
            $http.post('/api/v1/counseling/notes', data).then(() => {
              scope.internalNote = ""
              scope.$emit('internalNote:created')
            })
          }
        }
      }
    }
  })
