/**
 * @prettier
 */
module.exports = function selectEnrollmentType(CPPTutorials) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/family_testing/select_enrollment_type.html',
    scope: {
      enrollment: '=',
    },
    link: function(scope) {
      scope.tutorials = CPPTutorials;

      scope.directName = 'family_testing_direct';
      scope.cascadeName = 'family_testing_cascade';

      scope.selectEnrollmentType = function(enrollmentType) {
        if (enrollmentType == scope.directName) {
          scope.enrollment.for_physician_order = true;
          CPPTutorials.openTutorialIfNotSeen(scope.directName);
          // 'self' is not an option for direct flow. We must reset if changed during order
          if (scope.enrollment.proband && scope.enrollment.proband.relationship == 'self') {
            scope.enrollment.proband.relationship = undefined;
          }
        } else if (enrollmentType == scope.cascadeName) {
          scope.enrollment.for_physician_order = false;
          // Cascade flow only supports enrollment type of self
          scope.enrollment.proband.relationship = 'self';
          CPPTutorials.openTutorialIfNotSeen(scope.cascadeName);
        } else if (enrollmentType == undefined) {
          scope.enrollment.for_physician_order = undefined;
        }
      };
    },
  };
};
