!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrResource
   *
   * @description
   * The `clrResource` provides interaction support with Color's RESTful
   * services via the AngularJS's `$resource` service.
   */
  const module = angular.module('clrResource', ['ngResource', 'clrRequest'])

  /**
   * @ngdoc service
   * @name resource
   * @memberof clrResource
   *
   * @description
   * `resource` is a service that is similar to AngularJS's $resource
   * except `$save()` method sends either POST/PUT request depending on
   * the existence of `id` of the resource.
   */
  module.factory('resource', function($resource) {
    return function(url, params, methods) {
      const defaults = {
        update: { method: 'put', isArray: false },
        create: { method: 'post', isArray: false },
        update_with_ignore_auth_module: {
          method: 'put',
          isArray: false,
          ignoreAuthModule: true
        },
        create_with_ignore_auth_module: {
          method: 'post',
          isArray: false,
          ignoreAuthModule: true
        },
        save_with_skip_error_toast: {
          method: 'post',
          isArray: false,
          skipErrorToast: true
        },
        get_with_ignore_auth_module: {
          method: 'GET',
          isArray: false,
          ignoreAuthModule: true
        },
        get_as_cached: {
          method: 'GET',
          isArray: false,
          cache: true
        },
        get_as_array: {
          method: 'GET',
          isArray: true
        }
      }

      methods = angular.extend(defaults, methods)

      const resource = $resource(url, params, methods)

      resource.prototype.$save = function(param) {
        if (!this.id) {
          return this.$create(param)
        } else {
          return this.$update(param)
        }
      }

      resource.prototype.$save_with_ignore_auth_module = function(param) {
        if (!this.id) {
          return this.$create_with_ignore_auth_module(param)
        } else {
          return this.$update_with_ignore_auth_module(param)
        }
      }

      return resource
    }
  })

  /**
   * @class ResourcePaginator
   *
   * @description
   * A wrapper class for AngularJS resource object that allows
   * to fetch resource items in paginated fashion.
   *
   * @param {Object} resource - resource object.
   * @param {Object} defaultParams - query params to use by default.
   * @param {function} transform - function to apply to each result.
   */
  function ResourcePaginator(resource, defaultParams, transform, resolvedPromise) {
    this._transform = transform

    this._resolvedPromise = resolvedPromise
    /**
     * @member {Array} items - Fetched items.
     * @memberof ResourcePaginator#
     */
    this.items = []

    /**
     * @member {integer} total - A total number of items paginator can fetch from server.
     * @memberof ResourcePaginator#
     */
    this.total = 0

    /**
     * @member {boolean} hasNext - Indicates if there exist a next page.
     * @memberof ResourcePaginator#
     */
    this.hasNext = true

    /**
     * @member {boolean} hasPrevious - Indicates if there exist a previous page.
     * @memberof ResourcePaginator#
     */
    this.hasPrevious = false

    /**
     * @member {boolean} loadingNext - Indicates if the paginator is currently loading next page.
     * @memberof ResourcePaginator#
     */
    this.loadingNext = false

    /**
     * @member {boolean} loadingPrevious - Indicates if the paginator is currently loading previous page.
     * @memberof ResourcePaginator#
     */
    this.loadingPrevious = false

    /**
     * @member {boolean} loadingFailed - Indicates if the paginator failed to load a page.
     * @memberof ResourcePaginator#
     */
    this.loadingFailed = false

    this._nextParams = {}
    this._prevParams = {}
    if (defaultParams != void 0) {
      this._defaultParams = angular.extend({}, defaultParams)
      this._nextParams = angular.extend({}, defaultParams)
      this._prevParams = angular.extend({}, defaultParams)
    }
    this._resource = resource
  }

  /**
   * @method getNextPage
   * @memberof ResourcePaginator#
   *
   * @description
   * Starts loading next page and returns a promise.
   *
   * @param {Boolean} ignoreAuth - Indicates if whether auth module should be ignored.
   * @returns {promise} - The newly created promise.
   */
  ResourcePaginator.prototype.getNextPage = function(ignoreAuth) {
    this.loadingNext = true
    let deferred
    if (ignoreAuth) {
      deferred = this._resource.$get_with_ignore_auth_module(this._nextParams)
    } else {
      deferred = this._resource.$get(this._nextParams)
    }
    return deferred.then(angular.bind(this, function(res) {
      this._updateResource()
      const results = this._resource.results
      for (let i = 0; i < results.length; i++) {
        this.items.push(this._transform ? this._transform(results[i]) : results[i])
      }
      return res
    }))
      .catch(() => this.loadingFailed = true)
      .finally(angular.bind(this, function(res) {
        this.loadingNext = false
        return res
      }))
  }

  /**
   * @method getPreviousPage
   * @memberof ResourcePaginator#
   *
   * @description
   * Starts loading next page and returns a promise.
   *
   * @param {Boolean} ignoreAuth - Indicates if whether auth module should be ignored.
   * @returns {promise} - The newly created promise.
   */
  ResourcePaginator.prototype.getPreviousPage = function(ignoreAuth) {
    this.loadingPrevious = true
    let deferred
    const currentResource = angular.extend({}, this._resource)
    if (ignoreAuth) {
      deferred = this._resource.$get_with_ignore_auth_module(this._prevParams)
    } else {
      deferred = this._resource.$get(this._prevParams)
    }
    return deferred.then(angular.bind(this, function(res) {
      this._updateResource()
      this.items.splice(-1 * currentResource.results.length)
      return res
    }))
      .catch(() => this.loadingFailed = true)
      .finally(angular.bind(this, function(res) {
        this.loadingPrevious = false
        return res
      }))
  }

  /**
   * @method getCurrentPage
   * @memberof ResourcePaginator#
   *
   * @description
   * Returns current page resource was on.
   *
   * @returns {promise} - The newly created promise.
   */
  ResourcePaginator.prototype.getCurrentPage = function() {
    return this._resolvedPromise(this._resource)
  }

  ResourcePaginator.prototype.hasItems = function() {
    return this.items.length != 0
  }

  ResourcePaginator.prototype.getFirstPage = function(ignoreAuth) {
    this.loadingNext = true
    let deferred
    if (ignoreAuth) {
      deferred = this._resource.$get_with_ignore_auth_module(this._defaultParams)
    } else {
      deferred = this._resource.$get(this._defaultParams)
    }
    return deferred.then(angular.bind(this, function(res) {
      this._updateResource()
      const results = this._resource.results

      this.items = results
      return res
    }))
      .catch(() => this.loadingFailed = true)
      .finally(angular.bind(this, function(res) {
        this.loadingNext = false
        return res
      }))
  }

  /**
   * @private
   * @method _updateResource
   * @memberof ResourcePaginator#
   *
   * @description
   * Updates properties based on new resource fetches.
   */
  ResourcePaginator.prototype._updateResource = function() {
    this.total = this._resource.count
    const next = this._resource.next
    const previous = this._resource.previous
    this.hasNext = next != null
    this.hasPrevious = previous != null
    if (this.hasNext) {
      this._updateQueryParamWithMeta(next, this._nextParams)
    }
    if (this.hasPrevious) {
      this._updateQueryParamWithMeta(previous, this._prevParams)
    }
  }

  /**
   * @method getAllPages
   * @memberof ResourcePaginator#
   *
   * @description
   * Loads all pages.
   *
   * @param {Boolean} ignoreAuth - Indicates if whether auth module should be ignored.
   * @returns {promise} - The promise that resolves all items.
   */
  ResourcePaginator.prototype.getAllPages = function(ignoreAuth) {
    return this.getNextPage(ignoreAuth)
      .then(angular.bind(this, function(res) {
        if (this.hasNext) {
          return this.getAllPages()
        } else {
          return this.items
        }
      }))
  }

  ResourcePaginator.prototype.removeItem = function(index) {
    this.items.splice(index, 1)
    this.total -= 1
  }

  /**
   * @private
   * @method _updateNextParamWithMeta
   * @memberof ResourcePaginator#
   *
   * @description
   * Updates query params.
   */
  ResourcePaginator.prototype._updateQueryParamWithMeta = function(url, params) {
    let queryStr = parseUrl(url).search;

    // clear existing parameters out
    for (const param in params) { delete params[param] }

    queryStr = queryStr.substring(1)
    const fields = queryStr.split('&')
    for (let i = 0; i < fields.length; i++) {
      const pair = fields[i].split('=')
      params[pair[0]] = pair[1] ? pair[1].replace('+', ' ') : pair[1]
    }
  }

  function parseUrl(url) {
    const a = document.createElement('a')
    a.href = decodeURIComponent(url)
    return a
  }

  /**
   * @ngdoc service
   * @name resourcePaginator
   * @memberof clrResource
   *
   * @description
   * A factory which creates a resource paginator object that lets you
   * paginate data provided by server.
   *
   * @param {Object} resource - resource object.
   * @param {Object} defaultParams - Query params to use by default.
   * @returns {ResourcePaginator} An instance of ResourcePaginator.
   *
   * @example
   * var itemPaginator = resourcePaginator(new Item(), { format: 'json' })
   * itemPaginator.getNextPage()
   */
  module.factory('resourcePaginator', function(resolvedPromise) {
    return function(resource, defaultParams, transform) {
      return new ResourcePaginator(resource, defaultParams, transform, resolvedPromise)
    }
  })

  module.factory('indexedResource', function(resolvedPromise, resourcePaginator) {
    function IndexedResource(resource) {
      this.resource = resource
      this.items = []
      this.itemsById = {}
      this.loaded = false
    }

    IndexedResource.prototype.load = function() {
      if (this.loaded) {
        return resolvedPromise(this.items)
      } else {
        return resourcePaginator(this.resource).getAllPages(true)
          .then(angular.bind(this, function(items) {
            this.items = items
            angular.forEach(items, angular.bind(this, function(item) {
              this.itemsById[item.id] = item
            }))
            this.loaded = true
            return this.items
          }))
      }
    }

    IndexedResource.prototype.getById = function(id) {
      return this.itemsById[id]
    }

    IndexedResource.prototype.setItem = function(item) {
      this.items.push(item)
      this.itemsById[item.id] = item
    }

    return function(resource) {
      return new IndexedResource(resource)
    }
  })

  /**
   * Service to persist an object across pages
   */
  function PersistedObject(klass) {
    if (klass) {
      this._obj = new klass()
    }
    this.set = function(obj) { this._obj = obj }
    this.get = function() { return this._obj }
    this.refresh = function() {
      if (klass) {
        this._obj = new klass()
      } else {
        this._obj = null
      }
    }
    this.getOrSetIfEmpty = function(obj) {
      if (!this._obj){
        this._obj = obj
      }
      return this._obj
    }
  }

  module.factory('persistedObject', function() {
    return function(klass) { return new PersistedObject(klass) }
  })

  function CookiePersistedObject($cookies, $location, cookieName) {
    this.set = function(obj) {
      $cookies.put(cookieName, obj)
      this._obj = obj
    }

    this.get = function() {
      if (this._obj) {
        return this._obj
      }
      return $cookies.get(cookieName)
    }

    this.clear = function() {
      this._obj = null
      $cookies.remove(cookieName)
    }

    this.initFromQueryParams = function() {
      const paramValue = $location.search()[cookieName]
      if (paramValue) {
        this.set(paramValue)
        $location.search(cookieName, null)
      }
    }
  }

  /**
   * Service for setting saving query params in cookies
   */
  module.factory('cookiePersistedObject', function($cookies, $location) {
    return function(paramName) {
      return new CookiePersistedObject($cookies, $location, paramName)
    }
  })

  module.service('project', function() {
    return new function() {
      this.application = "www"
    }()
  })

  /**
   * @ngdoc service
   * @name urlBuilder
   * @memberof clrResource
   *
   * @description
   * Appends a set of query params onto the given URL. In the active version of Angular,
   * $httpParamSerializer and other built-ins that are used are not exposed, so we have to
   * adapt it from their source code.
   */

  module.service('urlBuilder', function() {
    /**
     * From https://github.com/angular/angular.js/blob/33f7f26558a52124c2eaf40d91bedb58686bde0a/src/Angular.js#L1301
     */
    function encodeUriQuery(val, pctEncodeSpaces) {
      return encodeURIComponent(val).
        replace(/%40/gi, '@').
        replace(/%3A/gi, ':').
        replace(/%24/g, '$').
        replace(/%2C/gi, ',').
        replace(/%3B/gi, ';').
        replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'))
    }

    /**
     * From https://github.com/angular/angular.js/blob/ad4336f9359a073e272930f8f9bcd36587a8648f/src/ng/http.js#L942
     */
    function buildUrl(url, params) {
      if (!params) return url
      const parts = []
      angular.forEach(params, function(value, key) {
        if (value === null || angular.isUndefined(value)) return
        if (!angular.isArray(value)) value = [value]

        angular.forEach(value, function(v) {
          if (angular.isObject(v)) {
            v = angular.toJson(v)
          }

          parts.push(
            encodeUriQuery(key)
              + '='
              + encodeUriQuery(v)
          )
        })
      })

      if (parts.length > 0) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + parts.join('&')
      }

      return url
    }

    return {
      buildUrl: buildUrl
    }
  })

  module.service('fileUploader', function($cookies, ngToast, requestErrorToast, FileUploader) {
    return function(options) {
      options = options || {}

      const csrfCookieName = angular.element("#csrf-cookie-name").val();
      const defaultOptions = {
        url: '/api/v1/uploaded_file',
        formData: [],
        alias: 'document',
        headers: {
          'X-CSRFToken': $cookies.get(csrfCookieName),
          'Accept': 'application/json'
        },
        queueLimit: 999,
        autoUpload: true
      }

      const defaultOptionKeys = Object.keys(defaultOptions)
      for (let i = 0; i < defaultOptionKeys.length; i++) {
        const option = defaultOptionKeys[i]

        if (!(option in options)) {
          options[option] = defaultOptions[option]
        }
      }

      const uploader = new FileUploader(options)

      uploader.onSuccessItem = function(item, response, status, headers) {
        item.s3_key = response
      }

      uploader.doneUploading = function(toastOnBlock) {
        if (uploader.isUploading) {
          uploader.uploadAll()
          if (toastOnBlock) {
            ngToast.create({ content: 'Please try again after uploads have finished.' })
          }
          return false
        }
        return true
      }

      uploader.getUploadedKeys = function() {
        const keys = []
        for (let i = 0; i < uploader.queue.length; i++) {
          const s3_key = uploader.queue[i].s3_key
          if (s3_key) {
            keys.push(s3_key)
          }
        }
        return keys
      }

      uploader.onErrorItem = function(item, response, status, headers) {
        requestErrorToast({data: response})
      }

      return uploader
    }
  })

  // https://stackoverflow.com/a/35171163
  module.service('setViewportScroll', function($window) {

    let cachedScrollY = 0
    const preventMotion = function(event) {
      $window.scrollTo(0,0)
      event.preventDefault()
      event.stopPropagation()
    }

    const disable = function() {
      cachedScrollY = $window.scrollY
      $window.addEventListener("scroll", preventMotion, false)
      $window.addEventListener("touchmove", preventMotion, false)
      angular.element("html").css("{ overflow: 'hidden' }")
      angular.element("body").css("{ overflow: 'hidden', position: 'relative' }")
    }

    const enable = function() {
      $window.scrollTo(0, cachedScrollY)
      $window.removeEventListener("scroll", preventMotion, false)
      $window.removeEventListener("touchmove", preventMotion, false)
      angular.element("html").css("{ overflow: 'visible' }")
      angular.element("body").css("{ overflow: 'visible', position: 'static' }")
    }

    return {
      disable: disable,
      enable: enable
    }
  })

}();
