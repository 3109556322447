'use strict'

module.exports = function overrideOopBlockModal(
  $modal,
  $q,
  InsuranceEligibilitySubmission
) {
  'ngInject'

  return {
    open: (eligibilitySubmissionId) => {
      const result = $modal.open({
        templateUrl: '/ordering_physicians/modals/override_oop_block.html',
        size: 'lg',
      }).result

      return result.then(() => {
        return InsuranceEligibilitySubmission.update({
          id: eligibilitySubmissionId,
          overriden: true
        })
      })
    }
  }
}
