/**
 * @prettier
 */

module.exports = function qcMetrics(SampleSequenceRun) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
    },
    templateUrl: '/results/qc_metrics.html',
    link: function(scope) {
      scope.sampleSequences = [];
      SampleSequenceRun.get({
        sample: scope.report.sample.id,
        test_type__icontains: scope.report.kit_order.test_type,
      }).$promise.then(function(res) {
        scope.sampleSequences = res.results;
      });
    },
  };
};
