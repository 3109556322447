/**
 * @ngdoc module
 * @name counselingResource
 *
 * @description
 * The `counselingResource` provides resources for
 * Genetic Counseling Appointments
 */
module.exports = angular.module('counselingResource', ['clrResource'])

  /**
   * @ngdoc service
   * @name Appointment
   * @memberof counselingResource
   *
   * @description
   * A resource for `counseling/appointment` REST API.
   */
  .factory('Appointment', function(resource) {
    return resource(
      '/api/v1/counseling/appointment/:id'
      , { format: 'json', id: '@id' },
      { update: { method: 'PATCH' } }
    )
  })

  /**
   * @ngdoc service
   * @name upcomingAppointment
   * @memberof counselingResource
   *
   * @description
   * A upcoming appointment.
   */
  .factory('upcomingAppointment', function($filter, Appointment) {
    return {
      get: function(orderNumber) {
        const params = {}
        if (orderNumber) {
          params['kit_order__order_number'] = orderNumber
        }
        return Appointment.get(params).$promise.then(function(res) {
          const upcomingAppointments = $filter('filter')(res.results, { 'is_upcoming': true })
          if (upcomingAppointments.length) {
            return $filter('orderBy')(upcomingAppointments, 'end_time')[0]
          }
          return null
        })
      }
    }
  })
