'use strict'

module.exports = function(months) {
  "ngInject"

  return {
    restrict: 'E',
    require: ['^^form', 'ngModel'],
    scope: {
      label: '@',
      flexStyle: '@',
      validate: '=',
      onChange: '=',
      ngModel: '=',
      contextPrefix: '@?', // this gets prepended to "Month" input placeholder.
      displayTooltip: '@?',
      popupContent: '@?',
    },
    templateUrl: '/directives/clr_date_input.html',
    link: function(scope, elem, attr, controllers) {
      scope.showError = true
      scope.isoformat = angular.isDefined(attr.isoformat) && attr.isoformat !== 'false'
      scope.form = controllers[0]
      scope.months = months
      scope.label = scope.label || undefined

      // Toggle for new flex form styling, make this default once we switch over all form design
      scope.flexStyle = angular.isDefined(scope.flexStyle) ? (scope.flexStyle == "true" ? true : false) : false

      const watcher = scope.$watch('ngModel', function(ngModel) {
        scope.dateModel = (scope.ngModel && angular.isDefined(scope.ngModel.$render)) ? scope.ngModel : controllers[1]

        scope.dateModel.$render = function() {
          const value = scope.dateModel.$viewValue
          if (value && scope.isoformat) {
            const dateArray = value.split('-')
            if (dateArray.length == 3) {
              scope.year = dateArray[0]
              scope.month = scope.months[parseInt(dateArray[1]) -1]
              scope.day = '' + parseInt(dateArray[2])
            }
          } else if (value) {
            const dateArray = value.split('/')
            if (dateArray.length == 3) {
              scope.month = scope.months[parseInt(dateArray[0]) - 1]
              scope.day = '' + parseInt(dateArray[1])
              scope.year = '' + parseInt(dateArray[2])
            }
          }
        }

        watcher()

        scope.dateModel.$render()
      })

      function validate(date) {
        const cutoff = new Date(1850, 1, 1)
        const numDays = new Date(date.year, date.month, 0).getDate()
        const day = parseInt(date.day)
        if (day > numDays || day < 1) {
          return false
        }
        const b = new Date(date.year, date.month-1, day)
        return b > cutoff
      }

      function formatDate(date) {
        if (scope.isoformat) {
          return date.year + '-' + ('0' + date.month).slice(-2) + '-' + ('0' + date.day).slice(-2)
        }
        return date.month + '/' + parseInt(date.day) + '/' + date.year
      }

      function onChange(date) {
        if (date.month) {
          date.month = months.indexOf(date.month) + 1
        }

        let valid = validate(date)
        scope.dateModel.$setValidity('date', valid)

        if (angular.isDefined(scope.validate)) {
          valid = valid && scope.validate(date)
        }

        if (angular.isDefined(scope.onChange)) {
          scope.onChange(scope.dateModel, date)
        }

        scope.dateModel.$setViewValue(valid ? formatDate(date) : '')
        if (scope.form['month'].$pristine ||
            scope.form['day'].$pristine ||
            scope.form['year'].$pristine) {
          scope.dateModel.$setPristine()
        } else {
          scope.dateModel.$setDirty()
        }
      }

      scope.$watch(function() {
        return {
          year: scope.year,
          month: scope.month,
          day: scope.day,

          // Also track these because the scope variables are not updated when
          // form validators failed. If the form's showError value is false,
          // not tracking these values subtly causes the clr error message to
          // not get displayed with certain inputs
          _edit: [
            scope.form['month'].$pristine,
            scope.form['day'].$pristine,
            scope.form['year'].$pristine
          ]
        }
      }, onChange, true)
    }
  }
}
