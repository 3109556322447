module.exports = angular.module('kitOrderDirectives', ['clrModal', 'clrRequest', 'kitOrderResource'])
  .directive('clrCreditCardForm', require('monoweb/django_apps/kit_orders/js/directives/clr_credit_card_form'))
  .directive('clrKitOrderPreview', require('monoweb/django_apps/kit_orders/js/directives/clr_kit_order_preview'))
  .directive('clrHealthcareProviderInput', require('monoweb/django_apps/kit_orders/js/directives/clr_healthcare_provider_input'))
  .directive('clrHealthcareProviderShareResultsInput', require('monoweb/django_apps/kit_orders/js/directives/clr_healthcare_provider_share_results_input'))
  .directive('clrHealthcareProvider', require('monoweb/django_apps/kit_orders/js/directives/clr_healthcare_provider'))
  .directive('clrHealthcareProviderShareResults', require('monoweb/django_apps/kit_orders/js/directives/clr_healthcare_provider_share_results'))
  .directive('clrPromocodeForm', require('monoweb/django_apps/kit_orders/js/directives/clr_promocode_form'))
  .directive('clrPromoCodeInput', require('monoweb/django_apps/kit_orders/js/directives/clr_promo_code_input'))
  .directive('clrOrderingPhysicianForm', require('monoweb/django_apps/kit_orders/js/directives/clr_ordering_physician_form'))
  .directive('clrCheckoutBreadcrumb', require('monoweb/django_apps/kit_orders/js/directives/clr_checkout_breadcrumb'))
  .directive('clrReferralCard', require('monoweb/django_apps/kit_orders/js/directives/clr_referral_card'))
  .directive('clrCampaignTokenForm', require('monoweb/django_apps/kit_orders/js/directives/clr_campaign_token_form'))
  .directive('clrSampleReuseBreadcrumb', require('monoweb/django_apps/kit_orders/js/directives/clr_sample_reuse_breadcrumb'))
  .directive('stripeCheckoutButton', require('monoweb/django_apps/kit_orders/js/directives/stripe_checkout_button'))
