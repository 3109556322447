/**
 * @prettier
 */

module.exports = function ClientStoriesController($scope, $http, geneCount, kitPriceService) {
  'ngInject';

  $scope.geneCount = geneCount;
  $scope.kitPriceService = kitPriceService;

  // This code is also in core/static/js/directives/clrQuiz.js, which has the
  // same newsletter signup form.
  $scope.submitted = false;
  $scope.subscribe = {
    email: null,
    purpose: 'health_notes',
  };

  $scope.submit = function(data) {
    return $http
      .post('/api/v1/marketing_email_contacts', data)
      .then(function() {
        $scope.submitted = true;
      })
      .catch(function() {
        $scope.submitting = false;
      });
  };
};
