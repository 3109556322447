module.exports = function() {
  'ngInject'
  return {
    scope: {
      step: '@'
    },
    templateUrl: '/kit_orders/_checkout_breadcrumb.html',
    controller: function($scope, KitCheckoutFlow) {
      'ngInject'
      $scope.initialLoggedIn = KitCheckoutFlow.initialLoggedIn
    }
  }
}
