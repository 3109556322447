module.exports = function placePhysicianOrderKit(ProviderPlatformModals, promoCodeStore, waffle) {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      checkout: '=',
      userProvider: '=',
      order: '='
    },
    templateUrl: '/ordering_physicians/directives/place_physician_order_kit.html',
    link: function(scope) {
      scope.settings = scope.userProvider ? scope.userProvider.settings : {}
    }
  }
}
