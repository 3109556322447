'use strict'

module.exports = function() {
  'ngInject'

  return function(promoCodeStore) {
    const code = promoCodeStore.promotionalCode
    if (!code.campaign || code.max_redemptions == 1) {
      return false
    }
    return code.remaining_redemptions_count > 0
  }
}
