"use strict"

module.exports = function() {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      src: '@',
      height: '@?'
    },
    template: '<img ng-if="height" src="{{src}}" height="{{height}}" />',
    link: function (scope) {
      // If the height is not defined, then we display the image at half its actual size for retina display
      if (!scope.height) {
        const img = new Image()
        img.onload = function(){
          scope.height = img.height / 2
        }
        img.src = scope.src
      }
    }
  }
}
