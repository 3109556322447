// note this is used in a different module:
//   monoweb/django_apps/users/templates/angular/accounts/settings.jade
module.exports = function OrderPreferencesController($scope, auth, ngToast, ProviderSettings) {
  'ngInject'

  $scope.settings = new ProviderSettings(auth.currentUser.provider_profile.settings)
  $scope.provider = auth.currentUser.provider_profile
  $scope.editingField = ""
  $scope.editedValue = {}

  $scope.edit = function(fieldName) {
    $scope.editingField = fieldName
  }

  $scope.save = function(fieldName) {
    $scope.settings[fieldName] = $scope.editedValue[fieldName]
    $scope.cancel()
  }

  $scope.cancel = function () {
    $scope.editedValue = {}
    $scope.editingField = ""
  }

  $scope.$watch("settings",
    function(newValue, oldValue) {
      if (oldValue && newValue != oldValue) {
        $scope.settings.$update()
          .then(function() { return auth.sync(true) })
          .then(function(res) {
            ngToast.create('Your preferences have been updated')
          })
      }
    },
    true
  )
}
