"use strict"

module.exports = function () {
  "ngInject"

  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.on('click', function () {
        this.select()
      })
    }
  }
}
