module.exports = function clrProviderInstitution() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      institution: '='
    },
    templateUrl: '/ordering_physicians/directives/clr_provider_institution.html',
    link: function(scope, _, attrs) {
      if (attrs.showPhone) {
        scope.showPhone = true
      }

      if (attrs.showFax) {
        scope.showFax = true
      }
    }
  }
}
