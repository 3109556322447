'use strict'

module.exports = function($scope, $location, Account, InsightsFlow) {
  'ngInject'

  $scope.flow = InsightsFlow

  $scope.routes = InsightsFlow.getRoutes()

  $scope.goToNext = function() {
    InsightsFlow.goToNext()
  }

  $scope.account = new Account()
}
