module.exports = function placePrimaryContact(nonPrimaryContactRoles) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/place_primary_contact.html',
    scope: {
      userProvider: '=',
      order: '=',
      searchable: '@'
    },
    link: function(scope) {

      if (scope.userProvider) {
        scope.canBePrimaryContact = nonPrimaryContactRoles.indexOf(scope.userProvider.primary_role) == -1
      }

      if (!scope.order.primary_contact_reference) {
        if (scope.canBePrimaryContact) {
          scope.order.primary_contact_reference = "placed_by"
        } else {
          scope.order.primary_contact_reference = "ordering_physician"
        }
      }

      if (scope.userProvider) {
        scope.defaultCountry = scope.userProvider.country
      } else {
        scope.defaultCountry = "US"
      }

      scope.$watch('order.primary_contact_reference', function(value) {
        if (value != 'other') {
          delete scope.order['primary_contact']
        }
      })
    }
  }
}
