const controllers = angular.module('benefitsControllers', [])
  .controller('BenefitsController', require('./controllers/benefits_controller'))

const modals = angular.module('benefitsModal', ['ui.bootstrap', 'ngToast'])
  .factory('AuthenticationFactorsModal', require('./modals/authentication_factors'))
  .factory('EmailAuthenticationModal', require('./modals/email_authentication'))
  .factory('UpgradePromptModal', require('./modals/upgrade_prompt'))
  .factory('RoutingAuthorizationModal', require('./modals/routing_authorization'))

const services = angular.module('benefitsServices', [])
  .service('receivedPromoCode', require('./services/received_promo_code'))
  .service('benefitsEmployeePageOptions', require('./services/benefits_employee_page_options'))

const directives = angular.module('benefitsDirectives', [])
  .directive('routingAuthorization', require('./directives/routing_authorization'))

module.exports = angular.module('benefits', [
  controllers.name,
  modals.name,
  services.name,
  directives.name
])
