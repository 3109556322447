'use strict'

module.exports = function() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      healthcareProvider: "="
    },
    require: '^form',
    templateUrl: '/kit_orders/directives/healthcare_provider_share_results_input.html',
    link: function(scope, elem, attr, ctrl) {
      scope.form = ctrl
      scope.hideRestrictions = attr.hasOwnProperty('hideRestrictions')
    }
  }
}
