/**
 * @prettier
 */

module.exports = function ltrLegend() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/ltr_legend.html',
    controllerAs: 'ctrl',
    replace: true,
    bindToController: true,
    scope: {
      riskLabel: '@',
      riskReferences: '=',
      baselineLabel: '@?',
      baselineReferences: '=?',
    },
    controller: function() {
      const ctrl = this;
    },
  };
};
