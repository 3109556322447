/**
 * @prettier
 */
module.exports = function providerSidebar(
  $window,
  auth,
  cppHomeUrlPath,
  showMedicationPromo,
  DraftPhysicianOrder
) {
  'ngInject';

  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/ordering_physicians/_sidebar.html',
    link: function(scope) {
      scope.activeNav = cppHomeUrlPath.getRouteName();

      function setUp() {
        // TODO(jw) think about heart beat for drafts
        DraftPhysicianOrder.get().$promise.then(function(response) {
          scope.draftsCount = response.count;
        });
      }

      if (auth.currentUser.provider_profile) {
        scope.institutionName = auth.currentUser.provider_profile.institution
          ? auth.currentUser.provider_profile.institution.name
          : auth.currentUser.provider_profile.institution_name;
      }

      setUp();

      scope.goToTutorials = function() {
        $window.location.assign('/providers/tutorials');
      };
    },
  };
};
