'use strict'

module.exports = () => {
  'ngInject'

  const isCovidBarcode = (kitBarcode) => {
    // Covid barcodes look like this D-1234567890
    return /D-\d{10}/.test(kitBarcode)
  }

  let isCovidSample = false;
  return {
    update: (kitBarcode) => {
      isCovidSample = isCovidBarcode(kitBarcode);
    },

    get: () => isCovidSample
  }
}
