/**
 * @prettier
 */

module.exports = function reportShareYourResults(addProviderModal) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_share_your_results.html',
    scope: {
      report: '=',
      reportContent: '=',
    },
    link: function(scope, elem) {
      scope.healthcareProviders = scope.report.sharing_providers;
      scope.disclaimer = false;
      scope.addProvider = function(provider) {
        addProviderModal
          .open(provider, scope.reportContent.kit_order.order_number)
          .result.then(function(res) {
            scope.healthcareProviders.push(res);
          });
      };
    },
  };
};
