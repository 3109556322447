/**
 * @ngdoc module
 * @name orderingPhysicianResource
 */
module.exports = angular.module('orderingPhysicianResource', ['ngCookies', 'clrLocation', 'clrResource', 'clrStripe'])

  /**
   * @ngdoc service
   * @name OrderingPhysicianProfile
   * @memberof orderingPhysicianResource
   *
   * @description
   * A resource for `ordering_physicians` REST API.
   */
  .factory('OrderingPhysicianProfile', function(resource) {
    return resource(
      '/api/v1/ordering_physicians'
      , { format: 'json' }
    )
  })

  /**
   * @ngdoc service
   * @name PhysicianOrder
   * @memberof orderingPhysicianResource
   *
   * @description
   * A resource for `physician_orders` REST API to access basic information.
   */
  .factory('PhysicianOrder', function(resource) {
    return resource(
      '/api/v1/physician_orders/:requisition_number'
      , { format: 'json', requisition_number: '@requisition_number' }
    )
  })

  /**
   * @ngdoc service
   * @name ColorProviderPlatformInvitation
   * @memberof orderingPhysicianResource
   *
   * @description
   * A resource for `physician_orders` REST API to access basic information.
   */
  .factory('ColorProviderPlatformInvitation', function(resource) {
    return resource(
      '/api/v1/color_provider_platform_invitations/:token',
      { format: 'json', token: '@token' }
    )
  })

  /**
   * @ngdoc service
   * @name PhysicianOrderPayment
   * @memberof orderingPhysicianResource
   *
   * @description
   */
  .factory('PhysicianOrderPayment', function() {
    function PhysicianOrderPayment() {
      this.meetsOopCheck = function () {
        return (
          this.eligibility_submission && (
            this.eligibility_submission.met_oop_check_for_insurance ||
            this.eligibility_submission.overriden
          )
        )
      }

      this.meetsCriteriaCheck = function() {
        return this.criteria_submission && this.criteria_submission.met_criteria
      }

      this.meetsInsuranceCheck = function() {
        return this.meetsOopCheck() && this.meetsCriteriaCheck()
      }

      this.oopEstimate = function() {
        return this.eligibility_submission && this.eligibility_submission.estimated_oop_cost_cents
      }
    }

    return PhysicianOrderPayment
  })

  /**
   * @ngdoc service
   * @name DetailedPhysicianOrder
   * @memberof orderingPhysicianResource
   *
   * @description
   * A resource for `physician_orders` REST API to access detailed sensitive information.
   */
  .factory('DetailedPhysicianOrder', function($filter, resource, PhysicianOrderPayment) {
    const DetailedPhysicianOrderResource = resource(
      '/api/v1/detailed_physician_orders/:requisition_number'
      , { format: 'json', requisition_number: '@requisition_number'}
    )

    const BLANK_ORDER = {
      not_require_counseling_if_positive: false,
      release_delay_in_days: null,
      additional_recipients: [],
      type: null,
      patient_profile: {},
      upload_files: [],
      payment: new PhysicianOrderPayment(),
      attempted_insurance_submission: {},

      // This attribute is used solely for ui purposes, and is not intended
      // to be serialized.
      ui: {}
    }

    DetailedPhysicianOrderResource.initializeWithSettings = function(providerSettings) {
      const blankOrder = angular.copy(BLANK_ORDER)
      if (providerSettings && !angular.equals(providerSettings, {})) {
        blankOrder.not_require_counseling_if_positive = !providerSettings.default_require_counseling_if_positive
        if (providerSettings.default_release_delay_in_days != null) {
          blankOrder.release_delay_in_days = providerSettings.default_release_delay_in_days
        }
        blankOrder.test_requested = providerSettings.default_test_requested
        if (providerSettings.require_payment || providerSettings.payment_code) {
          blankOrder.skip_payment = false
        }
        blankOrder.type = providerSettings.limit_type
        blankOrder.is_provider_owned = providerSettings.provider_owned_only
        blankOrder.no_vus_details = !providerSettings.default_vus_option
        if (providerSettings.show_collection_date) {
          blankOrder.collection_date = $filter('date')(new Date(), 'M/d/yyyy')
        }
      }
      // Helper methods on the physician order object
      return new DetailedPhysicianOrderResource(blankOrder)
    }

    DetailedPhysicianOrderResource.loadFromDraft = function(currentOrder, draft) {
      const draftOrder = draft.json_payload
      draftOrder.draft_requisition_number = draft.requisition_number
      draftOrder.draft_updated_at = draft.updated_at
      // Methods on existing payment need to be copied over to draft payment
      draftOrder.payment = angular.extend(currentOrder.payment, draftOrder.payment)
      return draftOrder
    }

    DetailedPhysicianOrderResource.loadFromEnrollment= function(currentOrder, enrollment) {
      currentOrder.patient_profile.first_name = enrollment.applicant_first_name
      currentOrder.patient_profile.last_name = enrollment.applicant_last_name
      currentOrder.patient_profile.email = enrollment.applicant_email
      if (enrollment.applicant_promocode) {
        currentOrder.promocode_for_payment = enrollment.applicant_promocode.code
        currentOrder.test_requested = enrollment.applicant_promocode.sku_list[0]
      }
      return currentOrder
    }

    DetailedPhysicianOrderResource.getFromRequisitionNumber = (requisitionNumber) => {
      return DetailedPhysicianOrderResource.get({requisition_number: requisitionNumber}).$promise
        .then(r => r.toJSON())
        .then(order => {
          order.ui = {}
          order.payment = new PhysicianOrderPayment()

          return new DetailedPhysicianOrderResource(order)
        })
    }

    return DetailedPhysicianOrderResource
  })

  .factory('DetailedProviderProfiles', function(resource) {
    return resource(
      '/api/v1/detailed_provider_profiles'
      , { format: 'json', page_size: 100, exclude_unverified: true}
    )
  })

  .factory('ProviderSettings', function(resource) {
    return resource(
      '/api/v1/provider_settings/:id'
      , { format: 'json', id: '@id' }
    )
  })

  .factory('InsurancePayers', function(resource) {
    return resource(
      '/api/v1/payments/insurance_payers'
    )
  })

  .factory('InsuranceEligibilitySubmission', function(resource) {
    return resource('/api/v1/eligibility_submission/:id', {
      id: '@id',
      format: 'json'
    })
  })

  .factory('InsuranceCriteriaSubmission', function(resource) {
    return resource('/api/v1/insurance_criteria_submissions', { format: 'json' })
  })

  .factory('ProviderColorUser', function($http) {
    return {
      invite: function(verifiedPhysicianId) {
        return $http({
          method: 'POST',
          url: '/api/v1/invite_to_cpp',
          data: {
            verified_physician: verifiedPhysicianId
          }
        })
      }
    }
  })

  .factory('ProviderAccountFlow', function(pathFlow) {
    return pathFlow('providerAccountFlow', [
      { controller: 'ProviderAccountCreationController', path: '/providers/create-account' },
      { controller: 'ProviderExistingAccountController', path: '/providers/existing-account' }
    ])
  })

  .factory('DraftPhysicianOrder', function(resource) {
    return resource(
      '/api/v1/draft_physician_orders/:requisition_number'
      , { format: 'json', id: '@requisition_number' }
    )
  })

  .factory('SharedOrder', function(resource) {
    return resource(
      '/api/v1/shared_orders/:order_number'
      , { id: '@order_number', format: 'json' }
    )
  })

  .factory('Tutorials', function(resource) {
    return resource(
      '/api/v1/tutorials', { format: 'json' }
    )
  })

  .factory('Enrollment', function(resource) {
    'ngInject';
    return resource('/api/v1/enrollments/:id', {
      format: 'json',
      page_size: 15,
      id: '@id'
    });
  })

  .service('ProviderAccount', function(Account, persistedObject) {
    return persistedObject(Account)
  })

  .service('PhysicianOrderFormData', function(persistedObject) {
    return persistedObject()
  })

  .service('PortalPaginator', function(persistedObject) {
    return persistedObject(function(){})
  })

  .service('physicianOrderCheckout', function(
    $q, Address, Checkout, CreditCard, claimStore, promoCodeStore, kioCountries, ngToast, sku
  ) {
    function PhysicianOrderCheckout(order, userProvider, testRequested) {
      Checkout.call(this)

      this.order = order
      this.payment = order.payment
      this.sku = sku[testRequested]

      if (!this.payment.creditCard) {
        this.payment.creditCard = new CreditCard()
      }

      if (userProvider && kioCountries.indexOf(userProvider.country) != -1) {
        this.shippingAddress = new Address({country: userProvider.country})
      }
    }

    PhysicianOrderCheckout.prototype = Object.create(Checkout.prototype)

    PhysicianOrderCheckout.prototype.skipPayment = function() {
      return Boolean(!this.isPaymentRequired() || this.order.skip_payment)
    }

    PhysicianOrderCheckout.prototype.reviewPayment = function() {
      this.payment.promotional_code = promoCodeStore.promotionalCode.code
      this.payment.totalPriceDollars = this.getTotalPriceDollars().toFixed(2)

      if (claimStore.claim) {
        this.payment.claim_token = claimStore.claim.token
      }
      if (this.skipPayment()) {
        return $q.when();
      }

      return this.payment.creditCard.$save()
        .then((stripeResp) => {
          this.payment.stripe = stripeResp
        })
        .catch((res) => {
          if (res.error && res.error.message) {
            ngToast.create({ className: 'error', content: res.error.message })
          }
          return $q.reject()
        })
    }
    return PhysicianOrderCheckout
  })

  .factory('PhysicianOrderFormFlow', function(pathFlow) {
    return pathFlow('physicianOrderFormFlow', [
      { controller: 'ProviderPlatformPlaceOrderController', path: '/providers/place-order' },
      { controller: 'ProviderPlatformSubmitPaymentController', path: '/providers/submit-payment' },
      { controller: 'ProviderPlatformReviewOrderController', path: '/providers/review-order' }
    ], { skipToEnd: false })
  })

  .factory('ProviderFamilyTestingFlow', function(pathFlow) {
    return pathFlow('providerFamilyTestingFlow', [
      { controller: 'ProviderPlatformFamilyTestingOrderController', path: '/providers/family-testing-enrolling' },
      { path: '/providers/family-testing-reviewing' }
    ], { skipToEnd: false })
  })

/**
   * @ngdoc service
   * @name cppHomeUrlPath
   * @memberof orderingPhysicianResource
   *
   * @description
   * cppHomeUrlPath looks at the current URL and returns api parameters associated
   * and name of the 'route' the page is on.
   */
  .service('cppHomeUrlPath', function($location) {
    const defaultApiParams = {
      page_size: 15,
      ordering: '-created_at'
    }

    const routeNames = ['all', 'delayed', 'in_progress', 'released', 'institution']

    function cppHomeUrlPather() {
      this.getApiParamsFromLocation = function() {
        const pathParams = $location.search()
        const queryParams = angular.copy(defaultApiParams)

        if (pathParams['view']) {
          queryParams[pathParams['view']] = true
        }
        if (pathParams['test_outcome']) {
          queryParams['test_outcome'] = pathParams['test_outcome']
        }
        if (pathParams['covid_test_significance']) {
          queryParams['covid_test_significance'] = pathParams['covid_test_significance']
        }
        if (pathParams['page']) {
          queryParams['page'] = pathParams['page']
        }
        if (this.getSearchQuery()) {
          queryParams['search'] = this.getSearchQuery()
        }
        return queryParams
      }

      this.getRouteName = function() {
        const pathParams = $location.search()
        const currentUrl = $location.path()
        if (this.getSearchQuery()) {
          return 'search'
        } else if (currentUrl.indexOf('/providers/forms') >= 0) {
          return 'forms'
        } else if (currentUrl.indexOf('/providers/drafts') >= 0) {
          return 'drafts'
        } else if (currentUrl.indexOf('/providers/family-testing') >= 0) {
          return 'family_testing'
        } else if (pathParams['test_outcome'] && pathParams['test_outcome'] == 'positive') {
          return 'positive'
        } else if (pathParams['covid_test_significance'] && pathParams['covid_test_significance'] == 'inconclusive') {
          return 'covid_inconclusive'
        } else if (pathParams['view'] && routeNames.indexOf(pathParams['view']) > 0) {
          return pathParams['view']
        } else if (currentUrl.indexOf('/providers/shared') >= 0) {
          return 'shared'
        } else if (currentUrl.indexOf('/providers/home') >= 0) {
          return 'all'
        }
        return ''
      }

      this.getSearchQuery = function() {
        const params = $location.search()
        if (params['search']) {
          // Queries in URL are base 64 encoded to obfuscate PHI
          return atob(decodeURIComponent(params['search']))
        }
        return ""
      }
    }

    return new cppHomeUrlPather()
  })

/**
   * @ngdoc service
   * @name PhysicianOrderNavigationService
   * @memberof orderingPhysicianResource
   *
   * @description
   * Manages the current states of the physician order flow.
   */
  .service('PhysicianOrderNavigationService', function() {
    const STATES = {
      DETAILS: 'DETAILS',
      PAYMENT: 'PAYMENT',
      REVIEW: 'REVIEW'
    }

    const URLS = {
      DETAILS: '/providers/place-order',
      PAYMENT: '/providers/submit-payment',
      REVIEW: '/providers/review-order'
    }

    let state = null

    function setState(newState) {
      if (!(newState in STATES)) {
        throw TypeError(newState + ' is not a valid state.')
      }

      state = newState
    }

    function getState() {
      return state
    }

    return {
      STATES: STATES,
      URLS: URLS,
      setState: setState,
      getState: getState
    }
  })

  .run(function($rootScope, PhysicianOrderFormData, PhysicianOrderFormFlow,
    ProviderAccount, ProviderAccountFlow) {
    // For refreshing data on entering and existing the flow
    const refreshDataWithFlow = function(flow, persistedData, $rootScope) {
      $rootScope.$on(flow.startEventName, function() { persistedData.refresh() })
      $rootScope.$on(flow.resetEventName, function() { persistedData.refresh() })
    }
    refreshDataWithFlow(PhysicianOrderFormFlow, PhysicianOrderFormData, $rootScope)
    refreshDataWithFlow(ProviderAccountFlow, ProviderAccount, $rootScope)
  })
