/**
 * @prettier
 */

module.exports = function reportSummaryTitle($filter) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_summary_title.html',
    replace: true,
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.msgReportTitle = $filter('reportTitle')(scope.reportContent);
    },
  };
};
