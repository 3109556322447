function RoutingConsentModalController($scope, setMembershipAndGoToNext, $modalInstance, employer, ngToast, $http) {
  $scope.translateValues = { employer }

  const submitConsent = (isConsenting) => {
    $http.post('/api/v1/user_consent', {
      consented: isConsenting, type: 'routing_authorization', scope: employer
    }).then(res => {
      createSuccessfulConsentToast(isConsenting)
      setMembershipAndGoToNext()
    }).catch(err => {
      ngToast.create({ className: 'error', content: "There was an error. Please contact Support." })
    }).finally(() => {
      $modalInstance.close()
    })
  }

  $scope.authorize = () => {
    submitConsent(true)
  }

  $scope.notAuthorize = () => {
    submitConsent(false)
  }

  const createSuccessfulConsentToast = (isConsenting) => {
    if (isConsenting) {
      ngToast.create({ className: 'success', content: `You will receive recommended ${employer} benefits and services on your dashboard.` })
    } else {
      ngToast.create({ className: 'success', content: `You will not receive recommended ${employer} benefits and services on your dashboard.` })
    }
  }

}

module.exports = ($modal) => {
  'ngInject'
  return {
    open: function(employer, setMembershipAndGoToNext) {
      return $modal.open({
        templateUrl: '/benefits/modals/routing_authorization.html',
        controller: RoutingConsentModalController,
        size: 'lg',
        resolve: {
          employer: () => {
            return employer
          },
          setMembershipAndGoToNext: () => {
            return setMembershipAndGoToNext
          }
        }
      })
    }
  }
}
