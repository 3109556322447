"use strict"

module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    transclude: true,
    scope: {
      active: '=active'
    },
    templateUrl: '/spinner_text.html'
  }
}
