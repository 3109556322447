module.exports = {
  reactRedirect: {
    '_reactRedirect_': function($location, $window, auth) {
      'ngInject'
      // Reconstructs current path with parameters and removes the react url
      const currentPath = $location.path() + window.location.search
      const reactPath = currentPath.replace('/react', '')
      // .replace does not add to browser history for back button behavior
      $window.location.replace(reactPath)
    }
  },

  healthHistory: {
    'healthHistory': function(HealthHistory) {
      'ngInject'
      return HealthHistory.get().$promise
        .catch(function(res) {
          if (res.status == 404) {
            return new HealthHistory()
          }
        })
    }
  },

  results: {
    'report': function($route, LatestReport) {
      'ngInject'
      return LatestReport.get({testType: $route.current.params.testType}).$promise
    },
    'isPDF': ['resolvedPromise', function(resolvedPromise) {
      return resolvedPromise(false)
    }]
  },

  kitStatus: {
    'kitStatuses': function(KitStatus) {
      'ngInject'
      return KitStatus.get().$promise
    }
  },

  userStatus: {
    'userStatus': function($http) {
      'ngInject'
      return $http.get('/api/v1/user_status')
        .then(function(res) {
          return res.data
        })
    }
  },

  familyTestingEligibility: {
    'eligibility': function($http) {
      'ngInject'
      return $http.get(
        '/api/v1/family_testing/eligibility',
        { ignoreAuthModule: true }
      )
        .then(function(response) {
          return response.data
        })
    }
  },

  // Used to set default heardAboutColorThrough for special URLs we distribute via ads media.
  applyAttributionRedirect: function(attribution, redirectPath) {
    return {
      '_AttributionRedirect_': function($window, $location, $cookies) {
        'ngInject'
        $cookies.put('attribution', attribution)

        // Routes starting with /t/ are served from cache directly off the
        // Django server, and aren't mounted in the Angular app.
        if (redirectPath.startsWith('/t/')) {
          $window.location.href = redirectPath
        } else {
          $location.path(redirectPath);
        }
        return
      }
    }
  },
}
