module.exports = function(promoCodeStore, $q, PromoCodeMismatchModal) {
  "ngInject"

  return {
    restrict: 'E',
    require: '^form',
    templateUrl: '/kit_orders/directives/promo_code_input.html',
    scope: {
      sku: '=',
    },
    link: function(scope, element, attrs, formCtrl) {
      // promo code input is designed to register an async validator
      // so when the parent form is submitted, the promo code is validated
      // asynchronously. The addAsyncValidator method from clr-form is used to allow
      // the parent form to wait for all validator promises to resolve
      // before checking the validity of the form.

      scope.promoCodeStore = promoCodeStore
      scope.showPromotionalCodeInput = promoCodeStore.promotionalCode && promoCodeStore.promotionalCode.can_redeem
      scope.promoCode = { code: promoCodeStore.promotionalCode.code }
      // Adjusts promocode input setting if promocodeStore is dynamically updated
      scope.$watch('promoCodeStore.promotionalCode.can_redeem', function(canRedeem) {
        if (canRedeem) {
          scope.showPromotionalCodeInput = true
          // this variable helps avoid editing promoCodeStore.promotionalCode directly
          scope.promoCode.code = promoCodeStore.promotionalCode.code
        }
      }, true)

      formCtrl.addAsyncValidator('promo-code', 'isValidPromo', function(modelVal, viewVal) {
        const value = modelVal || viewVal

        if (promoCodeStore.promotionalCode && promoCodeStore.promotionalCode.can_redeem) {
          // valid if code has already been applied and is in the field
          if (promoCodeStore.promotionalCode.code == value) {
            return $q.when(true)
          }
          // if there is a code and it isn't the one in the field, clear the
          // promoCodeStore
          promoCodeStore.clear()
        }

        // valid if no input
        if (!scope.showPromotionalCodeInput) {
          return $q.when(true)
        }
        // valid if input is blank
        if (!value) {
          return $q.when(true)
        }

        // apply the code
        promoCodeStore.clear()
        return promoCodeStore.updateCode(value, false).then(function(code) {
          if (code.can_redeem && scope.sku && !promoCodeStore.promoAppliesToSku(scope.sku)) {
            const promoCodeMismatchPromise = PromoCodeMismatchModal.open(scope.sku.name, code.external_sku_list_names).result
            promoCodeMismatchPromise.then(function(resetPromoCode) {
              scope.removePromotionalCode()
            })
          }
        })
      })

      scope.removePromotionalCode = function() {
        scope.promoCode.code = ''
        scope.showPromotionalCodeInput = false
        promoCodeStore.clear()
      }

      scope.togglePromotionalCodeInput = function() {
        scope.showPromotionalCodeInput = !scope.showPromotionalCodeInput
      }

      scope.updateCode = function() {
        // triggers validation by committing the form values
        formCtrl.$commitViewValue()
      }
    }
  }
}
