/**
 * @prettier
 */

module.exports = function reportRiskTooltip() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_risk_tooltip.html',
    scope: {
      risk: '=',
    },
  };
};
