/**
 * @prettier
 */

module.exports = function ProviderPlatformFamilyTestingController(
  $scope,
  cppHomeUrlPath,
  Enrollment
) {
  'ngInject';

  $scope.loading = true;
  const params = cppHomeUrlPath.getApiParamsFromLocation();
  Enrollment.get(params)
    .$promise.then(function(enrollments) {
      $scope.enrollments = enrollments;
    })
    .finally(function() {
      $scope.loading = false;
    });
};
