!function() {

  'use strict'

  const module = angular.module('familyTestingServices', ['clrLocation'])

  module.factory('FamilyTestingFlow', function(pathFlow, auth) {
    'ngInject'
    const flow = pathFlow(
      'familyTestingFlow',
      [
        {
          controller: 'FamilyTestingInitializationController',
          path: '/family-testing/enroll'
        },
        {
          controller: 'FamilyTestingResultsController',
          path: '/family-testing/enroll/results',
          optional: true
        },
        {
          controller: 'FamilyTestingRelativesController',
          path: '/family-testing/enroll/relatives'
        },
        {
          controller: 'FamilyTestingReviewController',
          path: '/family-testing/enroll/review'
        },
        {
          controller: 'FamilyTestingConfirmationController',
          path: '/family-testing/enroll/confirmation'
        }
      ]
    )

    flow.initializeRelatives = function() {
      const flowState = flow.state

      flowState.mother = null
      flowState.father = null
      flowState.sisters = []
      flowState.brothers = []
      flowState.daughters = []
      flowState.sons = []

      if (flowState.resultsOwner == 'self') {
        // Pre-fill relative information from available health history
        angular.forEach(
          flowState.eligibility.family_info,
          function(relative) {
            const relationship = relative.relationship
            const age = relative.age
            if (age < 18) {
              return
            }
            const relativeStub = { age: age, prefilled: true }
            if (relationship == 'mother') {
              flowState.mother = relativeStub
            } else if (relationship == 'father') {
              flowState.father = relativeStub
            } else if (relationship == 'full sister') {
              flowState.sisters.push(relativeStub)
            } else if (relationship == 'full brother') {
              flowState.brothers.push(relativeStub)
            } else if (relationship == 'daughter') {
              flowState.daughters.push(relativeStub)
            } else if (relationship == 'son') {
              flowState.sons.push(relativeStub)
            }
          }
        )
      }

      flowState.relativesNeeded = true
    }

    flow.initializeEnrollmentData = function(enrollment) {
      const flowState = flow.state
      // Initializes flow with data from existing enrollment
      flowState.genes = enrollment.positive_genes
      flowState.proband = {relationship: enrollment.proband_relationship}
      if (enrollment.proband_relationship == 'self') {
        flowState.resultsOwner = 'self'
      } else {
        flowState.resultsOwner = 'relative'
      }
    }

    flow.getRelatives = function() {
      const flowState = flow.state
      let relatives = [flowState.mother, flowState.father]
      relatives = relatives.concat(
        flowState.sisters,
        flowState.brothers,
        flowState.daughters,
        flowState.sons
      )

      if (
        flowState.resultsOwner == 'relative' &&
        flowState.applicant &&
        flowState.applicant.enroll
      ) {
        relatives.unshift({
          firstName: auth.currentUser.first_name,
          lastName: auth.currentUser.last_name,
          email: auth.currentUser.email,
          enroll: true
        })
      }

      return relatives.filter(function(relative) { return relative && relative.enroll })
    }

    flow.initialize = function() {
      const flowState = flow.state
      flowState.isPreApproved = function() {
        // Eligibility is pre-approved if it's a color positive test or
        // it has gone through approval process already.
        const eligibility = flow.state.eligibility
        if (eligibility) {
          if (flow.state.eligibility.status == 'approved') {
            return true
          } else if (flow.state.eligibility.color_positive) {
            return true
          }
        }
        return false
      }
    }

    return flow
  })

    .factory('Enrollment', function(resource) {
      'ngInject'
      return resource(
        '/api/v1/enrollments/:id'
        , { format: 'json', page_size: 15, id: '@id'}
      )
    })

}();
