const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="publish")
    modal-title Are you ready to publish?
    modal-body
      p Once you hit “Publish” this note will be emailed to the patient.
      p If this is a Spanish GC note, the note will include the Spanish version first, followed by the English version on subsequent pages in the same file.
      p If this is a physician order, the note will also go out to the ordering physician, the primary contact, and any additional recipients.
    submit-button Publish
`

const controller = ($scope, $modalInstance, publishNote) => {
  $scope.modalInstance = $modalInstance
  $scope.publish = publishNote
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (publishNote) => {
      return $modal.open({
        template: template,
        controller: controller,
        size: 'md',
        resolve: {
          publishNote: () => publishNote,
        }
      })
    }
  }
}
