/**
 * @prettier
 */

module.exports = function reportAboutFamily($filter, testTypes, namedMutation) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_about_family.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      if (scope.reportContent.is_positive) {
        const allChildImpactGenes = [
          'BRCA2',
          'PALB2',
          'RAD51C',
          'BRIP1',
          'MLH1',
          'MSH2',
          'MSH6',
          'MUTYH',
          'PMS2',
          'ATM',
          'CHEK2',
        ];
        (scope.childImpactGenes = []), (scope.childNonImpactGenes = []);
        angular.forEach(scope.reportContent.genes_with_pathogenic_mutations, function(gene) {
          if ($filter('containsAny')([gene], allChildImpactGenes)) {
            scope.childImpactGenes.push(gene);
          } else {
            scope.childNonImpactGenes.push(gene);
          }
        });
        scope.reportIsSingleHomozygous = $filter('isSingleHomozygousReport')(
          scope.reportContent.mutations
        );
        scope.reportIsPotentiallyCompoundHeterozygous = $filter(
          'isPotentiallyCompoundHeterozygousReport'
        )(scope.reportContent);
        scope.showApcI1307k = $filter('containsMutation')(
          scope.reportContent.mutations,
          namedMutation.apc_i1307k
        );
        scope.showHighDeNovo =
          !scope.showApcI1307k &&
          scope.reportContent.template.test_type == testTypes.hereditary30 &&
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'TP53',
            'APC',
            'BMPR1A',
            'SMAD4',
          ]);
        // A case when there are one het and one hom mutations.
        scope.genes = $filter('genesWithMutationName')(scope.reportContent.mutations);
        scope.homGenes = $filter('filter')(scope.genes, { isHomozygous: true });
        scope.hetGenes = $filter('filter')(scope.genes, { isHomozygous: false });
        scope.hasBothHomHet = scope.homGenes.length && scope.hetGenes.length;
        if (scope.hasBothHomHet) {
          if (scope.homGenes.length != 1 || scope.hetGenes.length != 1) {
            throw new Error('We currently only support one hom and one het cases.');
          }
        }
      }
    },
  };
};
