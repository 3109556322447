/**
 * @ngdoc filter
 * @name additionalFindingMutations
 *
 * @description
 * Returns mutations considered as additional finding.
 */

'use strict'

module.exports = function($filter) {
  'ngInject'

  return function(mutations) {
    // We used to report somatic variants with classification so we only pick variants with empty classification
    // here for backward compatibility.
    return mutations.filter(function(m) {
      return m.variant_type == 'somatic' && !m.classification
    })
  }
}
