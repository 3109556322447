require('monoweb/django_apps/core/static/js/app.js')
require('monoweb/django_apps/core/static/js/constants.js')
require('monoweb/django_apps/core/static/js/geo_constants.js')
require('monoweb/django_apps/core/static/js/controllers.js')
require('monoweb/django_apps/core/static/js/directives.js')
require('monoweb/django_apps/core/static/js/event.js')
require('monoweb/django_apps/core/static/js/filter.js')
require('monoweb/django_apps/core/static/js/location.js')
require('monoweb/django_apps/core/static/js/modal.js')
require('monoweb/django_apps/core/static/js/navigation.js')
require('monoweb/django_apps/core/static/js/phone_numbers.js')
require('monoweb/django_apps/core/static/js/providers.js')
require('monoweb/django_apps/core/static/js/raven.js')
require('monoweb/django_apps/core/static/js/resource.js')
require('monoweb/django_apps/core/static/js/request.js')
require('monoweb/django_apps/core/static/js/static_files.js')
require('monoweb/django_apps/core/static/js/stripe.js')
require('monoweb/django_apps/core/static/js/video.js')
require('monoweb/django_apps/core/static/js/waffle.js')

require('monoweb/django_apps/family_testing/static/js/family_testing/controllers.js')
require('monoweb/django_apps/family_testing/static/js/family_testing/directives.js')
require('monoweb/django_apps/family_testing/static/js/family_testing/filters.js')
require('monoweb/django_apps/family_testing/static/js/family_testing/services.js')

require('monoweb/django_apps/health_profiles/static/js/health_profiles/controllers.js')
require('monoweb/django_apps/health_profiles/static/js/health_profiles/directives.js')
require('monoweb/django_apps/health_profiles/static/js/health_profiles/filter.js')
require('monoweb/django_apps/health_profiles/static/js/health_profiles/resource.js')

require('monoweb/django_apps/physicians/static/js/physicians/resource.js')

require('monoweb/django_apps/results/static/js/results/report_modal.js')
require('monoweb/django_apps/results/static/js/results/resource.js')
require('monoweb/django_apps/results/static/js/results/service.js')
