!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrEvent
   *
   * @description
   * The `clrEvent` provides DOM event related services.
   */
  const module = angular.module('clrEvent', [])

  /**
   * @ngdoc service
   * @name manuallyTriggerScroll
   * @memberof clrEvent
   *
   * @description
   * `manuallyTriggerScroll` manually triggers scroll event.
   *
   * @returns {KeyEvent}
   */
  module.factory('manuallyTriggerScroll', function($window) {
    let delayedScrollId
    return function() {
      if (delayedScrollId != void 0) {
        $window.clearTimeout(delayedScrollId)
      }
      delayedScrollId = $window.setTimeout(function() {
        angular.element(window).trigger('scroll')
        delayedScrollId = void 0
      }, 500)
    }
  })

}();
