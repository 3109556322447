/**
 * @prettier
 */

module.exports = function() {
  return {
    restrict: 'E',
    replace: true,
    template: `<sup><span ng-repeat="ref in refs"><span reference="{{ ref }}"></span><span ng-if="!$last">,</span></span></sup>`,
    scope: {
      refs: '=',
    },
  };
};
