module.exports = angular.module('results_v2', [
  require('monoweb/django_apps/results_v2/js/modals').name,
  require('monoweb/django_apps/results_v2/js/directives').name,
  require('monoweb/django_apps/results_v2/js/services').name
]).config(function(colorRouteProvider) {
  colorRouteProvider
    .when('/results-v2', {
      templateUrl: '/results_v2/report_picker.html',
      controller: require('monoweb/django_apps/results_v2/js/controllers/results_v2_report_picker_controller'),
      seo: {
        title: "Results V2 - Report Picker"
      },
      navigation: {showNav: false},
      auth: {
        requireColorEmployee: true
      }
    })

    .when('/results-v2/:reportId', {
      templateUrl: '/results/web_report_v2.html',
      controller: require('monoweb/django_apps/results_v2/js/controllers/report_v2_controller'),
      seo: {
        title: 'Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' }
    })

    .when('/results-v2/v/:reportId/:step', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: {
        'report': function($route, Report) {
          'ngInject'
          return Report.released({ id: $route.current.params.reportId }).$promise
        },
        'isPDF': ['resolvedPromise', function(resolvedPromise) {
          return resolvedPromise(false)
        }]
      },
    })
})
