/**
 * @prettier
 */

module.exports = function reportSummaryChart($filter, testTypes, namedMutation) {
  'ngInject';

  // render_version changes for compound heterozygous logic
  // <= 2: Only show monoallelic risks as a chart
  // >= 3: Show both mono and biallelic risks
  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_summary_chart.html',
    replace: true,
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      let genderNoun = scope.reportContent.gender == 'F' ? 'women' : 'men';
      scope.testTypes = testTypes;

      //- Subtitle append if NOT a breast survivor, female, and (risk model exists || breast biopsy + atypical hyperplasia || breast biopsy + LCIS)
      scope.showRiskModelLanguageAppend =
        !scope.reportContent.breast_cancer_survivor &&
        scope.reportContent.gender == 'F' &&
        (scope.reportContent.template.risk_model ||
          scope.reportContent.had_breast_biopsy_with_atypical_hyperplasia ||
          scope.reportContent.diagnosed_with_lobular_carcinoma_in_situ);

      function createGeneReport(gene, allelicity) {
        const geneReport = {
          data: [],
          gene: gene.name,
          mutationName: gene.mutationName ? ' ' + gene.mutationName : '', // add a space for display purposes
          gender: scope.reportContent.gender,
          allelicity: angular.isNumber(allelicity) ? allelicity : 1, // default allelicity to 1/monoallelic if not set
        };

        // Only a one type of allelicity risks (either 1/monoallelic or 2/biallelic) are ever shown in any chart grouping. When more than one are shown, we depend on creating multiple geneReports, handled in the later logic that calls createGeneReport()
        const primaryRisks = $filter('sortedRisks')(
          scope.reportContent,
          scope.reportContent.gender,
          false,
          null,
          gene.name,
          geneReport.allelicity
        );

        // Create list of risk citations that get appended to the labels in the legend
        let primaryPathogenicRisks = $filter('sortRiskByPhenotype')(
          primaryRisks.pathogenic,
          'primary',
          [gene.name],
          scope.reportContent.template.test_type
        );
        let primaryBaselineRisks = $filter('sortRiskByPhenotype')(
          primaryRisks.baseline,
          'primary',
          [gene.name],
          scope.reportContent.template.test_type
        );
        const customPrimaryPhenotypes = $filter('customPhenotypes')(
          scope.reportContent,
          gene.name,
          'primary'
        );
        if (angular.isDefined(customPrimaryPhenotypes)) {
          primaryPathogenicRisks = primaryPathogenicRisks.filter(function(r) {
            return customPrimaryPhenotypes.indexOf(r.phenotype) > -1;
          });
          primaryBaselineRisks = primaryBaselineRisks.filter(function(r) {
            return customPrimaryPhenotypes.indexOf(r.phenotype) > -1;
          });
        }
        geneReport.pathogenicReferences = $filter('uniqueReferences')(primaryPathogenicRisks);
        geneReport.baselineReferences = $filter('uniqueReferences')(primaryBaselineRisks);

        // Set "age by x" with commonRiskAge $filter
        geneReport.commonRiskAge = $filter('commonRiskAge')(
          scope.reportContent.genes_with_pathogenic_mutations,
          primaryPathogenicRisks
        );

        // Add STK11-specific age exceptions
        primaryPathogenicRisks = $filter('insertDisplayAge')(
          primaryPathogenicRisks,
          geneReport.commonRiskAge,
          scope.reportContent.render_version
        );

        // Determine whether or not various parts of the chart should have 'single' appearing before the gene name, as well as extra subtitle content. Only exists currently on CHEK2 Homozygous, CHEK2 Compound Het, and MUTYH Compound Het when geneReport.allelicity == 1
        if (geneReport.allelicity == 1) {
          geneReport.useSingleRiskLogic = $filter('useSingleRiskLogic')(
            scope.reportContent,
            geneReport.gene
          );
        } else {
          geneReport.useSingleRiskLogic = false;
        }

        // Decide whether to show single age charts vs decade-series charts
        if (
          $filter('containsNone')([geneReport.gene], ['BRCA1', 'BRCA2']) ||
          geneReport.gender == 'M' ||
          geneReport.mutationName ||
          (geneReport.gene == 'BRCA2' && allelicity == '2')
        ) {
          geneReport.showSingleAgeChartTitle = true;
        } else if (
          geneReport.gender == 'F' &&
          $filter('containsAny')([geneReport.gene], ['BRCA1', 'BRCA2']) &&
          !geneReport.mutationName
        ) {
          geneReport.showDecadeAgeChartTitle = true;
        }

        // BRCA1 R1699Q, M has unknown specific risks.
        if (
          gene.name == 'BRCA1' &&
          gene.mutationName == 'R1699Q' &&
          scope.reportContent.gender == 'M'
        ) {
          geneReport.mutationName = '';
        }

        // Legend label copy
        let msgRiskLabel = '';
        if (geneReport.allelicity == 2) {
          msgRiskLabel = ' with a mutation in both copies of ' + gene.name;
        } else {
          msgRiskLabel = ' with ' + (geneReport.useSingleRiskLogic ? 'a single ' : '') + gene.name;
        }
        if (geneReport.mutationName) {
          msgRiskLabel = msgRiskLabel + geneReport.mutationName;
        } else if (geneReport.allelicity != 2) {
          msgRiskLabel = msgRiskLabel + ' mutation';
        }
        const msgBaselineLabel = 'Average among US ';
        if (scope.reportContent.gender == 'F') {
          geneReport.msgRiskLabel = 'Women' + msgRiskLabel;
          geneReport.msgBaselineLabel = msgBaselineLabel + 'women';
        } else if (scope.reportContent.gender == 'M') {
          geneReport.msgRiskLabel = 'Men' + msgRiskLabel;
          geneReport.msgBaselineLabel = msgBaselineLabel + 'men';
        }

        // PTEN-specific Cowden syndrome append
        if (gene.name == 'PTEN' && scope.reportContent.gender == 'F') {
          geneReport.msgRiskLabel += ' and Cowden syndrome';
          geneReport.subtitleReplace =
            'Risk among US women with a <em>PTEN</em> mutation and Cowden syndrome to develop specific cancers by the age of 70.';
        }
        // APC non-I1307K append
        if (gene.name == 'APC' && !geneReport.mutationName) {
          geneReport.subtitleReplace =
            'Risk among US ' +
            genderNoun +
            ' with an <em>APC</em> mutation to develop specific cancers by the age of 80 without surgical intervention. In general, risk is higher for those with FAP compared to AFAP.';
        }

        // Reconstruct data that gets shown in main chart based on primaryPathogenicRisks
        for (let i = 0; i < primaryPathogenicRisks.length; i++) {
          geneReport.data.push({
            gene: gene.name,
            gender: scope.reportContent.gender,
            phenotype: primaryPathogenicRisks[i].phenotype,
            risks: {
              baseline: primaryBaselineRisks[i],
              pathogenic: primaryPathogenicRisks[i],
            },
          });
        }
        return geneReport;
      } // End of createGeneReport

      function createNegativeRisk(report, phenotype) {
        const negativeRisk = {
          gene: '',
          gender: report.gender,
          phenotype: phenotype,
          risks: {
            baseline: $filter('sortedRisks')(report, report.gender, false, phenotype).baseline[0],
          },
        };
        return negativeRisk;
      }

      function matchReportRiskAges(geneReport, ageMax) {
        // Double mutations need to have the same max age
        // TODO: Current logic doesn't look at the other mutation's max age
        const matchedAgeReport = angular.copy(geneReport);
        angular.forEach(matchedAgeReport.data, function(data) {
          data.risks.pathogenic.data = data.risks.pathogenic.data.filter(function(riskData) {
            return riskData.age <= ageMax;
          });
        });
        return matchedAgeReport;
      }

      function singleReportRiskAge(geneReport, ageOnly) {
        angular.forEach(geneReport.data, function(data) {
          data.risks.pathogenic.data = data.risks.pathogenic.data.filter(function(riskData) {
            return riskData.age == ageOnly;
          });
          data.risks.baseline.data = data.risks.baseline.data.filter(function(riskData) {
            return riskData.age == ageOnly;
          });
        });
        return geneReport;
      }

      // Ensure age range charts only display specific age groups, especially age 65 baseline ovarian F risks (used for STK11 reports)
      function filterlifetimeAgeRange(riskData) {
        const lifetimeAgeRange = [30, 40, 50, 60, 70, 80, 95];
        return riskData.filter(function(data) {
          return lifetimeAgeRange.indexOf(parseInt(data.age)) > -1;
        });
      }

      const isPotentiallyCompoundHeterozygousReport = $filter(
        'isPotentiallyCompoundHeterozygousReport'
      )(scope.reportContent);
      const isSingleHomozygousReport = $filter('isSingleHomozygousReport')(
        scope.reportContent.mutations
      );

      let hasI1307K;
      if (scope.reportContent.is_positive) {
        scope.geneReports = [];
        angular.forEach($filter('genesWithMutationName')(scope.reportContent.mutations), function(
          gene
        ) {
          let geneReport = createGeneReport(gene);
          if (geneReport.data.length) {
            // Removes duplicate primaryPathogenicRisks when double mutations and controls what graphs show

            // CHEK2 F Compound Het subtitle
            if (
              isPotentiallyCompoundHeterozygousReport &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['CHEK2'])
            ) {
              if (scope.reportContent.gender == 'F') {
                geneReport.subtitleAppend =
                  ' Please note that these numbers are for women with a single <em>CHEK2</em> gene mutation. Breast cancer risk in women with a single mutation in each copy of the <em>CHEK2</em> gene may be twice as high.';
              } else {
                geneReport.subtitleAppend =
                  ' Please note that these numbers are for men with a single <em>CHEK2</em> gene mutation. Prostate and colorectal cancer risk in men with a mutation in each copy of the <em>CHEK2</em> gene is not yet known.';
              }
              geneReport.showChek2SingleRiskCitation = true;
            } else if (gene.isHomozygous) {
              if (gene.name == 'CHEK2' && scope.reportContent.gender == 'F') {
                geneReport.subtitleAppend =
                  ' Please note that these numbers are for women with a single <em>CHEK2</em> gene mutation. Breast cancer risk in women with two <em>CHEK2</em> mutations may be twice as high.';
                geneReport.showChek2SingleRiskCitation = true;
              } else if (gene.name == 'CHEK2' && scope.reportContent.gender == 'M') {
                geneReport.subtitleAppend =
                  ' Please note that these numbers are for men with a single <em>CHEK2</em> gene mutation. Cancer risk in men with two <em>CHEK2</em> mutations is currently unknown.';
                geneReport.showChek2SingleRiskCitation = false;
              } else if (gene.name == 'APC' && gene.gHGVS == namedMutation.apc_i1307k) {
                // H30 APC I1307K Homozygous subtitle
                geneReport.subtitleAppend =
                  ' Please note that these numbers are for ' +
                  genderNoun +
                  ' with a single <em>APC</em> I1307K gene mutation. Research on the risk of colorectal cancer in ' +
                  genderNoun +
                  ' with this mutation in both copies of the <em>APC</em> gene is ongoing.';
              } else if (gene.name == 'MITF' && scope.reportContent.gender == 'F') {
                // H30 MITF F Homozygous subtitle
                geneReport.subtitleAppend =
                  ' Research on the risk of melanoma in women with this mutation in both copies of the <em>MITF</em> gene is ongoing.';
              }
            }

            // TODO: Refactor this when good logic exists upon more reports
            // If there are two mutations in two different genes, the risk displayed should be for the lower of the two ages (if different).
            if (
              isPotentiallyCompoundHeterozygousReport &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'MUTYH',
              ]) &&
              geneReport.gene != 'CHEK2'
            ) {
              // Check for compound heterozygous MUTYH reports, where we have split logic between primary risks existing and those that don't. When render_version 2 was in place, a MUTYH F Compound Het report was released without biallelic risks for colorectal, so excluding it for those versions, otherwise including it.
              scope.geneReports.push(matchReportRiskAges(createGeneReport(gene, 1), 80));
              if (scope.reportContent.render_version >= 3) {
                scope.geneReports.push(matchReportRiskAges(createGeneReport(gene, 2), 80));
              }
            } else if (
              // BRCA2 Homozygous / Compound Het
              (isPotentiallyCompoundHeterozygousReport || isSingleHomozygousReport) &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRCA2'])
            ) {
              scope.geneReports.push(singleReportRiskAge(createGeneReport(gene, 2), 50));
              scope.geneReports.push(geneReport);
            } else if (
              // MSH6 Homozygous
              isSingleHomozygousReport &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['MSH6'])
            ) {
              // Do nothing, no charts should appear for MSH6 Homozygous
            } else if (gene.name == 'CDKN2A') {
              geneReport.subtitleReplace =
                'Risk among US ' +
                (geneReport.gender == 'F' ? 'women' : 'men') +
                ' with a <em>CDKN2A</em> mutation to develop specific cancers by the age of ' +
                geneReport.commonRiskAge +
                '. Melanoma has a wide risk range because of factors such as age, sun exposure, and ethnicity, among others.';
              scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'ATM',
                'PALB2',
              ])
            ) {
              if (geneReport.gene == 'PALB2') {
                geneReport.subtitleReplace =
                  'Risk among US women with a <em>PALB2</em> mutation to develop breast cancer by the age of 70 is highlighted because its impact is higher.';
                scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'CHEK2',
              ])
            ) {
              const hasR1699Q = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.brca1_r1699q
              );
              if (hasR1699Q) {
                if (geneReport.gene == 'BRCA1') {
                  geneReport.subtitleReplace =
                    'Risk among US women with a <em>BRCA1</em> R1699Q mutation to develop ovarian cancer by age 70.';
                  geneReport.data = geneReport.data.filter(function(risk) {
                    return !(risk.gene == 'BRCA1' && risk.phenotype == 'breast cancer');
                  });
                } else {
                  // CHEK2
                  geneReport.subtitleReplace =
                    'Risk among US women with a <em>CHEK2</em> mutation to develop breast cancer by age 70. With genes in shared cancer pathways like <em>BRCA1</em> and <em>CHEK2</em>, it is believed that risk corresponds to the gene with the greater impact. Because the <em>BRCA1</em> R1699Q mutation is associated with lower risks of breast cancer than other <em>BRCA1</em> mutations, <em>CHEK2</em> risk is highlighted.';
                }
                scope.geneReports.push(geneReport);
              } else {
                if (geneReport.gene == 'BRCA1') {
                  if (scope.reportContent.gender == 'F') {
                    geneReport.subtitleReplace =
                      'Risk among US women with a <em>BRCA1</em> mutation to develop specific cancers by different ages in their lives is highlighted because its impact is higher.';
                    scope.geneReports.push(matchReportRiskAges(geneReport, 70));
                  } else {
                    if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
                      // BO19 BRCA1 + CHEK2 M only shows BRCA1 charts
                      // H30 uses standard subtitle because it shows both genes
                      geneReport.subtitleReplace =
                        'Risk among US men with a <em>BRCA1</em> mutation to develop specific cancers by the age of 70. <em>CHEK2</em> mutations are not currently known to affect risk of male breast cancer.';
                    }
                    scope.geneReports.push(singleReportRiskAge(geneReport, 70));
                  }
                } else if (
                  geneReport.gene == 'CHEK2' &&
                  geneReport.gender == 'M' &&
                  scope.reportContent.template.test_type == testTypes.hereditary30
                ) {
                  // H30 BRCA1 + CHEK2 M
                  geneReport.subtitleReplace =
                    'Risk among US men with a <em>CHEK2</em> mutation to develop specific cancers by the age of 70. Prostate cancer risk associated with a <em>CHEK2</em> mutation is highlighted because its impact is higher.';
                  scope.geneReports.push(singleReportRiskAge(geneReport, 70));
                }
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'BARD1',
              ])
            ) {
              if (geneReport.gene == 'BRCA1') {
                geneReport.subtitleReplace =
                  'Risk among US women with a <em>BRCA1</em> mutation to develop specific cancers by different ages in their lives is highlighted because its impact is higher.';
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'PMS2',
              ])
            ) {
              if (geneReport.gene == 'PMS2') {
                geneReport.subtitleAppend =
                  ' Ovarian cancer risk is increased, but <em>BRCA1</em> is highlighted because its impact is higher.';
              }
              if (geneReport.gene == 'BRCA1') {
                geneReport = matchReportRiskAges(geneReport, 70);
              }
              geneReport.data = geneReport.data.filter(function(risk) {
                return !(
                  risk.gene == 'PMS2' &&
                  (risk.phenotype == 'breast cancer' || risk.phenotype == 'ovarian cancer')
                );
              });
              // Remove reference 92 from the PMS chart references cause it's referring to breast cancer, no need to remove it from references because it's used in the family tab risk tables
              if (geneReport.gene == 'PMS2') {
                geneReport.pathogenicReferences.splice(geneReport.pathogenicReferences.indexOf(92));
              }
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'APC',
                'BARD1',
                'CHEK2',
                'MUTYH',
              ])
            ) {
              if (geneReport.gene == 'CHEK2') {
                geneReport = singleReportRiskAge(geneReport, 70);
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'RAD51C',
              ])
            ) {
              // RAD51C isn't shown because BRCA1's breast and ovarian cancer risk is higher
              if (geneReport.gene == 'BRCA1') {
                geneReport.subtitleReplace =
                  'Risk among US women with a <em>BRCA1</em> mutation to develop specific cancers by different ages in their lives is highlighted because its impact is higher.';
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'MUTYH',
              ])
            ) {
              if (geneReport.gene == 'BRCA1') {
                scope.geneReports.push(geneReport);
                // Special logic for BRCA1 + Homozygous/in-trans MUTYH
              } else if (
                geneReport.gene == 'MUTYH' &&
                scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic'
              ) {
                geneReport = createGeneReport(gene, 2);
                geneReport.subtitleReplace =
                  'Risk among US ' +
                  genderNoun +
                  ' with a mutation in each copy of the <em>MUTYH</em> gene to develop specific cancers by the age of 80 without surgical intervention.';
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'APC',
                'ATM',
                'MSH6',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              // For APC I1307K + ATM + MSH6, only show ATM breast cancer risk
              if (hasI1307K && geneReport.gene != 'APC') {
                scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'APC',
                'ATM',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              // For APC I1307K + ATM, only show ATM breast cancer risk
              if (hasI1307K && geneReport.gene == 'ATM') {
                scope.geneReports.push(geneReport);
              } else if (!hasI1307K) {
                scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BARD1',
                'APC',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              if (hasI1307K && geneReport.gene == 'BARD1' && geneReport.gender == 'F') {
                // For F, only show BARD1 breast cancer risk at age 80
                scope.geneReports.push(singleReportRiskAge(geneReport, 80));
              } else if (hasI1307K && geneReport.gene == 'APC' && geneReport.gender == 'M') {
                // For F, only show APC I1307K colorectal risk at age 80
                scope.geneReports.push(singleReportRiskAge(geneReport, 80));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BARD1',
                'PMS2',
              ])
            ) {
              if (geneReport.gene == 'BARD1') {
                geneReport = singleReportRiskAge(geneReport, 70);
                geneReport.commonRiskAge = 70;
              }
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BARD1',
                'CHEK2',
              ])
            ) {
              // BARD1 + CHEK2 only shows CHEK2 risks
              if (geneReport.gene == 'CHEK2') {
                geneReport = singleReportRiskAge(geneReport, 70);
                if (geneReport.gender == 'F') {
                  geneReport.subtitleReplace =
                    'Risk among US women with a <em>CHEK2</em> mutation to develop breast cancer by the age of 70 is highlighted because its impact is higher.';
                }
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'ATM',
                'MITF',
              ])
            ) {
              scope.geneReports.push(singleReportRiskAge(geneReport, 70));
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'MITF',
              ])
            ) {
              scope.geneReports.push(singleReportRiskAge(geneReport, 70));
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'RAD51D',
              ])
            ) {
              // CHEK2 and RAD51D do not share the common age.
              if (geneReport.gene == 'RAD51D') {
                geneReport = singleReportRiskAge(geneReport, 80);
                geneReport.commonRiskAge = 80;
              } else if (geneReport.gene == 'CHEK2') {
                geneReport = singleReportRiskAge(geneReport, 70);
                geneReport.commonRiskAge = 70;
              }
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'MLH1',
                'MITF',
              ])
            ) {
              scope.geneReports.push(singleReportRiskAge(geneReport, 70));
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'BMPR1A',
              ])
            ) {
              scope.geneReports.push(matchReportRiskAges(geneReport, 80)); // Only CHEK2 double mut that uses age 80, most use age 70
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRIP1',
                'MSH6',
              ])
            ) {
              scope.geneReports.push(matchReportRiskAges(geneReport, 70));
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'MUTYH',
              ]) &&
              scope.reportContent.genes_with_pathogenic_mutations.length == 2
            ) {
              if (geneReport.gene == 'CHEK2') {
                scope.geneReports.push(singleReportRiskAge(geneReport, 70));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'ATM',
                'BRCA2',
              ])
            ) {
              if (geneReport.gene == 'BRCA2') {
                if (scope.reportContent.gender == 'F') {
                  if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
                    geneReport.subtitleReplace =
                      'Risk among US women with a <em>BRCA2</em> mutation to develop specific cancers by the age of 70. With genes in shared cancer pathways like <em>BRCA2</em> and <em>ATM</em>, it is believed that risk corresponds to the gene with the greater impact (<em>BRCA2</em>).';
                  } else {
                    geneReport.subtitleReplace =
                      'Risk among US women with a <em>BRCA2</em> mutation to develop specific cancers by different ages in their lives is highlighted because its impact is higher.';
                  }
                }
                scope.geneReports.push(geneReport);
              } else if (scope.reportContent.gender == 'M') {
                scope.geneReports.push(geneReport);
              }
            } else if (
              geneReport.gene == 'BRCA1' &&
              scope.reportContent.gender == 'M' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'ATM',
                'BRCA1',
              ])
            ) {
              geneReport.subtitleReplace =
                'Risk among US men with a <em>BRCA1</em> mutation to develop specific cancers by the age of 70. <em>ATM</em> mutations are not currently known to affect risk of male breast cancer.';
              geneReport = matchReportRiskAges(geneReport, 70);
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'ATM',
                'CHEK2',
              ])
            ) {
              if (geneReport.gender == 'F') {
                if (geneReport.gene == 'CHEK2') {
                  if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
                    geneReport.subtitleReplace =
                      'Risk among US women with a <em>CHEK2</em> mutation to develop specific cancers by the age of 70. With genes in shared cancer pathways like <em>CHEK2</em> and <em>ATM</em>, it is believed that risk corresponds to the gene with the greater impact (<em>CHEK2</em>).';
                  }
                  scope.geneReports.push(singleReportRiskAge(geneReport, 70));
                }
              } else {
                scope.geneReports.push(singleReportRiskAge(geneReport, 70));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA2',
                'CHEK2',
              ])
            ) {
              // also handles the inclusion of APC I1307K
              // Only show BRCA2 charts. BRCA2 shows up as the only primary chart, so we need to alter that subtitle.
              if (geneReport.gene == 'BRCA2') {
                if (geneReport.gender == 'M') {
                  geneReport.subtitleReplace =
                    'Risk among US men with a <em>BRCA2</em> mutation to develop specific cancers by age 70 is highlighted because its impact is higher.';
                } else {
                  geneReport.subtitleReplace =
                    'Risk among US women with a <em>BRCA2 mutation</em> to develop specific cancers by different ages in their lives is highlighted because its impact is higher.';
                }
                scope.geneReports.push(geneReport);
              }
            } else if (
              geneReport.gender == 'M' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'MSH6',
              ])
            ) {
              if (geneReport.gene == 'MSH6') {
                geneReport.commonRiskAge = 70;
              }
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'MSH2',
              ])
            ) {
              if (geneReport.gene == 'MSH2') {
                geneReport.commonRiskAge = 70;
              }
              scope.geneReports.push(geneReport);
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'APC',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              if (hasI1307K && geneReport.gene != 'APC') {
                // Colorectal cancer risk is shown as a primary for APC I1307K but
                // since CHEK2 has much higher risk for breast cancer and has lower
                // risk for colorectal cancer, we want to show both colorectal cancer
                // as secondary.
                scope.geneReports.push(matchReportRiskAges(geneReport, 70));
              } else if (geneReport.gender == 'M' && hasI1307K && geneReport.gene == 'APC') {
                scope.geneReports.push(matchReportRiskAges(geneReport, 70));
              } else if (!hasI1307K && geneReport.gene == 'APC' && geneReport.gender == 'F') {
                geneReport.subtitleReplace =
                  'Risk among US women with an <em>APC</em> mutation to develop specific cancers by the age of 80 without surgical intervention. In general, risk is higher for those with FAP compared to AFAP.';
                geneReport.commonRiskAge = 80;
                scope.geneReports.push(matchReportRiskAges(geneReport, 80));
              } else if (!hasI1307K && geneReport.gene == 'CHEK2' && geneReport.gender == 'F') {
                geneReport.commonRiskAge = 80;
                scope.geneReports.push(matchReportRiskAges(geneReport, 80));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA2',
                'MUTYH',
              ]) &&
              scope.reportContent.genes_with_pathogenic_mutations.length == 2
            ) {
              // Omit MUTYH
              if (geneReport.gene == 'BRCA2') {
                if (scope.reportContent.gender == 'M') {
                  geneReport = matchReportRiskAges(geneReport, 70);
                }
                scope.geneReports.push(geneReport);
              }
            } else if (
              scope.reportContent.gender == 'F' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA2',
                'TP53',
              ])
            ) {
              if (geneReport.gene == 'BRCA2') {
                geneReport.commonRiskAge = 70;
                scope.geneReports.push(matchReportRiskAges(geneReport, 70));
              } else if (geneReport.gene == 'TP53') {
                geneReport.commonRiskAge = 95;
                scope.geneReports.push(matchReportRiskAges(geneReport, 95));
              }
            } else if (
              scope.reportContent.gender == 'F' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'TP53',
              ])
            ) {
              if (geneReport.gene == 'CHEK2') {
                geneReport.commonRiskAge = 70;
                scope.geneReports.push(matchReportRiskAges(geneReport, 70));
              } else if (geneReport.gene == 'TP53') {
                geneReport.commonRiskAge = 95;
                scope.geneReports.push(matchReportRiskAges(geneReport, 95));
              }
            } else if (
              scope.reportContent.gender == 'F' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'MSH2',
                'APC',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              if (hasI1307K && geneReport.gene == 'MSH2') {
                // omit colorectal from APC I1307K
                scope.geneReports.push(geneReport);
              }
            } else if (
              scope.reportContent.gender == 'F' &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'MSH6',
                'APC',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              if (hasI1307K) {
                // omit colorectal from APC I1307K
                if (geneReport.gene == 'MSH6') {
                  geneReport.subtitleReplace =
                    'Risk among US women with an <em>MSH6</em> mutation to develop specific cancers by the age of 70 is highlighted because its impact is higher.';
                  scope.geneReports.push(geneReport);
                }
              } else {
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA2',
                'APC',
              ])
            ) {
              hasI1307K = $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.apc_i1307k
              );
              if (hasI1307K) {
                // omit colorectal from APC I1307K
                if (geneReport.gene == 'BRCA2') {
                  scope.geneReports.push(geneReport);
                }
              } else {
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA2',
                'PALB2',
              ])
            ) {
              // omit PALB2 because BRCA2 prostate risk is higher
              if (geneReport.gene == 'BRCA2') {
                genderNoun = geneReport.gender == 'F' ? 'women' : 'men';
                geneReport.subtitleReplace =
                  'Risk among US ' +
                  genderNoun +
                  ' with a <em>BRCA2</em> mutation to develop specific cancers by age 70 is highlighted because its impact is higher.';
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'MLH1',
                'MUTYH',
              ])
            ) {
              if (geneReport.gene == 'MLH1') {
                // MLH1 has higher colorectal, so only show that
                scope.geneReports.push(geneReport);
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['MUTYH'])
            ) {
              if (
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['ATM'])
              ) {
                // MUTYH + ATM
                // Select age 70 risk for both genes.
                scope.geneReports.push(matchReportRiskAges(geneReport, 70));
              } else if (
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                  'TP53',
                ])
              ) {
                // MUTYH + TP53
                // TP53 should overwrite all MUTYH risks since it has higher risks for all cancers.
                if (geneReport.gene == 'TP53') {
                  geneReport.subtitleReplace =
                    'Risk among US men with a <em>TP53</em> mutation to develop specific cancers by the age of 95 is highlighted because its impact is higher.';
                  scope.geneReports.push(matchReportRiskAges(geneReport, 95));
                }
              } else if (
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                  'BAP1',
                ]) ||
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                  'PTEN',
                ]) ||
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                  'PALB2',
                ])
              ) {
                scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
              } else if (
                $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                  'MSH2',
                ])
              ) {
                // MUTYH Homozygous + MSH2 have split age (80 and 70 respectively) logic per:
                // https://docs.google.com/document/d/1yQ58Nrb90Ht0XWVxO8lf5hM_op_sWqTN9eGFsWTMUkQ/edit
                if (
                  geneReport.gene == 'MUTYH' &&
                  scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic'
                ) {
                  geneReport = createGeneReport(gene, 2);
                  geneReport.commonRiskAge = 80;
                  geneReport.subtitleReplace =
                    'Risk among US ' +
                    genderNoun +
                    ' with a mutation in each copy of the <em>MUTYH</em> gene to develop specific cancers by the age of 80 without surgical intervention.';
                  scope.geneReports.push(singleReportRiskAge(geneReport, 80));
                } else {
                  scope.geneReports.push(singleReportRiskAge(geneReport, 70));
                }
              } else {
                // All other MUTYH
                if (isSingleHomozygousReport) {
                  geneReport = createGeneReport(gene, 2);
                  geneReport.subtitleReplace =
                    'Risk among US ' +
                    genderNoun +
                    ' with a mutation in each copy of the <em>MUTYH</em> gene to develop specific cancers by the age of 80 without surgical intervention.';
                }
                scope.geneReports.push(matchReportRiskAges(geneReport, 80));
              }
            } else if (
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'CHEK2',
                'MSH2',
              ])
            ) {
              if (geneReport.gene == 'MSH2') {
                if (geneReport.gender == 'F') {
                  geneReport.subtitleReplace =
                    'Risk among US women with an <em>MSH2</em> mutation to develop specific cancers by the age of 70. Colorectal cancer risk associated with <em>MSH2</em> is highlighted because its impact is higher.';
                } else {
                  geneReport.subtitleReplace =
                    'Risk among US men with an <em>MSH2</em> mutation to develop specific cancers by the age of 70. Colorectal cancer risk associated with <em>MSH2</em> is highlighted because its impact is higher.';
                }
              }
              scope.geneReports.push(matchReportRiskAges(geneReport, 70));
            } else if (
              $filter('containsMutation')(
                scope.reportContent.mutations,
                namedMutation.brca1_r1699q
              ) &&
              $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRCA1'])
            ) {
              // BRCA1 R1699Q single mutation subtitle override
              if (geneReport.gene == 'BRCA1') {
                if (geneReport.gender == 'F') {
                  geneReport.subtitleReplace =
                    'Risk among US women with a <em>BRCA1</em> R1699Q mutation to develop specific cancers by age 80.';
                } else if (geneReport.gender == 'M') {
                  geneReport.subtitleReplace =
                    'Risk among US men with a <em>BRCA1</em> mutation to develop specific cancers by the age of 80. Please note that these risk estimates are generalized for a man with a <em>BRCA1</em> gene mutation and are not specific to a <em>BRCA1</em> R1699Q mutation. Research on this specific mutation is ongoing.';
                }
              }
              scope.geneReports.push(geneReport);
            } else if (
              geneReport.gender == 'M' &&
              $filter('containsAny')([geneReport.gene], ['BRCA1', 'BRCA2']) &&
              !$filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'BRCA2',
              ]) &&
              !$filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
                'BRCA1',
                'PALB2',
              ])
            ) {
              // BRCA1/BRCA2 M-override to prevent chart logic from showing age 70 and 80
              // TODO: May need to apply this to more than BRCA1/BRCA2
              if (geneReport.gene == 'BRCA1') {
                scope.geneReports.push(singleReportRiskAge(geneReport, 80));
              } else if (geneReport.gene == 'BRCA2') {
                scope.geneReports.push(singleReportRiskAge(geneReport, 70));
              }
            } else {
              scope.geneReports.push(matchReportRiskAges(geneReport, geneReport.commonRiskAge));
            }
          }
        });
      } else if (scope.reportContent.is_negative) {
        let riskLabel = 'Average risk among US ';
        if (scope.reportContent.gender == 'F') {
          riskLabel += 'women';
        } else {
          riskLabel += 'men';
        }
        scope.negativeReport = {
          gender: scope.reportContent.gender,
          riskLabel: riskLabel,
          baselineReferences: $filter('uniqueReferences')(
            $filter('sortedRisks')(scope.reportContent, scope.reportContent.gender, false).baseline
          ),
          risks: [],
        };
        if (scope.reportContent.gender == 'F') {
          // Only add charts for non-survivor cases
          if (!scope.reportContent.breast_cancer_survivor) {
            scope.negativeReport.risks.push(
              createNegativeRisk(scope.reportContent, 'breast cancer')
            );
          }
          // Show ovarian if breast ovarian 19, otherwise show colorectal
          if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
            // Fallopian tube cancer is linked to ovarian cancer, so don't show the chart in that case either
            if (
              !scope.reportContent.ovarian_cancer_survivor &&
              !scope.reportContent.fallopian_tube_cancer_survivor
            ) {
              // Due to STK11's addition of age 65 ovarian baseline risks, we need to omit them here because they'll mess the expected age range (30, 40, 50, 60, 70, 80, 95)
              angular.forEach(scope.reportContent.risks, function(risk) {
                if (risk.phenotype == 'ovarian cancer' && risk.gender == 'F') {
                  risk.data = filterlifetimeAgeRange(risk.data);
                }
              });
              scope.negativeReport.risks.push(
                createNegativeRisk(scope.reportContent, 'ovarian cancer')
              );
            }
          } else if (
            scope.reportContent.template.test_type == testTypes.hereditary30 &&
            !scope.reportContent.colorectal_cancer_survivor
          ) {
            scope.negativeReport.risks.push(
              createNegativeRisk(scope.reportContent, 'colorectal cancer')
            );
          }
        } else if (scope.reportContent.gender == 'M') {
          if (
            !scope.reportContent.breast_cancer_survivor &&
            scope.reportContent.template.test_type == testTypes.breastOvarian19
          ) {
            scope.negativeReport.risks.push(
              createNegativeRisk(scope.reportContent, 'male breast cancer')
            );
          } else if (scope.reportContent.template.test_type == testTypes.hereditary30) {
            if (!scope.reportContent.prostate_cancer_survivor) {
              scope.negativeReport.risks.push(
                createNegativeRisk(scope.reportContent, 'prostate cancer')
              );
            }
            if (!scope.reportContent.colorectal_cancer_survivor) {
              scope.negativeReport.risks.push(
                createNegativeRisk(scope.reportContent, 'colorectal cancer')
              );
            }
          }
        }
      }
    },
  };
};
