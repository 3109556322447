const props = require('./benefits_props')

module.exports = function($rootScope, $scope, $http, $location, $routeParams,
  requestErrorToast, benefitsEmployeePageOptions,
  AuthenticationFactorsModal, EmailAuthenticationModal, sku, testTypes) {
  'ngInject'
  $scope.props = props

  $scope.values = {
    identifiers: []
  }

  const getIdentifier = () => {
    // Identifiers can be split into two different fields like first_name, last_name.  Join them together before submitting
    return $scope.values.identifiers.join('').replace(/'/g, '')
  }

  const claimBenefitsErrorHandler = (response) => {
    const identifier = getIdentifier()

    // Employer does not cover Color Test
    if (response.status === 404) {
      $scope.values.identifierNotFound = true
    // Request authentication
    } else if (response.status === 400 && response.data.auth_factors) {
      delete $scope.values.identifierNotFound
      AuthenticationFactorsModal.open(
        identifier,
        response.data.auth_factors,
        $scope.options.concatAuthFactors,
        $scope.options.organization_name
      )
    // Show error
    } else {
      requestErrorToast(response)
    }
  }

  $scope.submitIdentifier = () => {
    $scope.submitting = true
    const identifier = getIdentifier()
    let organization_name = null
    if ('organization_name' in $scope.options){
      organization_name = $scope.options.organization_name
    }

    // PC-1449 Note: $scope.options.authFactors will always be null since no population
    // has this configured any longer. Leaving this code alone since it will be dead code
    // once populations are all migrated over to the new React-based genetics kit flow.
    if ($scope.options.authFactors) {
      AuthenticationFactorsModal.open(
        identifier,
        $scope.options.authFactors,
        $scope.options.concatAuthFactors,
        $scope.options.organization_name
      )
      $scope.submitting = false
      return
    }

    const nonCovidSkus = Object.keys(sku).filter((s) => s != testTypes.covid19)

    const data = { identifier, organization_name, skus: nonCovidSkus }

    return $http.post('api/v1/claim_benefits', data, { skipErrorToast: true })
      .then(response => {
        const discountType = response.data.discount_type
        EmailAuthenticationModal.open(identifier, discountType)
      }).catch(response => {
        claimBenefitsErrorHandler(response)
      }).finally(() => {
        $scope.submitting = false
      })
  }

  // Load email authentication w/ promo code input.
  const submitted = $location.path() == '/benefits/employees/code'
  if (submitted) {
    EmailAuthenticationModal.open()
  }

  // Variable page content based on the company
  const path = $location.path()
  const pathToCompanyMap = (path) => {
    const paths = {
      '/go/aetna': 'aetna',
      '/go/cadence': 'cadence',
      '/go/cdw': 'cdw',
      '/go/epicgames': 'epicgames',
      '/go/hbc': 'hbc',
      '/homedepot': 'homedepot',
      '/go/levistrauss': 'levistrauss',
      '/go/sap': 'sap',
      '/go/qualtrics': 'sap',
      '/go/sapcanada': 'sapcanada',
      '/go/mubadala': 'mubadala',
      '/go/jefferson': 'jefferson',
      '/go/alaskarailroad': 'alaskarailroad',
      '/go/sequoiaconsultinggroup': 'sequoiaconsultinggroup',
      '/ohana': 'salesforce',
      '/go/ohana': 'salesforce',
      '/go/totalrewards': 'ejgallo',
      '/go/shamrockfoods': 'shamrockfoods',
      '/go/biltmore': 'biltmore',
      '/go/biltmore-claim': 'biltmore_claim',
      '/go/ocra': 'ocra',
    }
    return paths.hasOwnProperty(path) ? paths[path] : $routeParams.company
  }

  $scope.options = benefitsEmployeePageOptions(pathToCompanyMap(path))
  $scope.showMedications = !$scope.options.sku || $scope.options.sku.testTypes.indexOf('pgx v1') != -1
  Object.assign($scope.props, $scope.options.propsOverlay)

  $rootScope.metatags['title'] = $scope.options.routeTitle
  $rootScope.metatags['description'] = $scope.options.routeDescription
  $rootScope.metatags['url'] = $location.url()
  // TODO: Do we do anything with layouts? There are 3, with cadence being unique
  // TODO: How do we handle Salesforce and Aetna?
}
