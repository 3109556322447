"use strict"

module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    require: '^form',
    scope: {
      ngModel: '=',
      name: '@',
      attrName: '@',
      options: '=',
      ngRequired: '=',
      selectDefaultText: '@',
      textDefaultText: '@'
    },
    templateUrl: '/select_or_text.html',
    compile: function() {
      return {
        pre: function(scope) {
          const initValue = scope.ngModel[scope.attrName]
          scope.other = false

          // Initialize select input value
          for (let i = 0; i < scope.options.length; i++) {
            if (initValue == scope.options[i].value) {
              scope.selectedValue = initValue
              break
            }
          }

          // Initialize text input value
          if (!scope.selectedValue && !!initValue) {
            scope.other = true
            scope.selectedValue = '__other'
            scope.enteredValue = initValue
          }

          // Add other option to options
          scope.optionsWithOther = copyOptionsWithOther(scope.options)
        },
        post: function(scope, elem, attrs, formCtrl) {
          scope.form = formCtrl
          scope.$watch('options', function(newValue) {
            scope.optionsWithOther = copyOptionsWithOther(scope.options)
          }, true)

          scope.triggerOnSelect = function(value) {
            if (value == '__other') {
              scope.other = true
              scope.ngModel[scope.attrName] = ''
            } else {
              scope.other = false
              scope.ngModel[scope.attrName] = value
            }
          }

          scope.triggerOnEnter = function(value) {
            scope.ngModel[scope.attrName] = value
          }
        }
      }
      function copyOptionsWithOther(options) {
        const optionsWithOther = angular.copy(options)
        optionsWithOther.push({ name: 'Other', value: '__other' })
        return optionsWithOther
      }
    }
  }
}
