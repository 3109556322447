/**
 * @ngdoc filter
 * @name numericAllelicity
 *
 * @description
 * Changes alphabetic allelicity to numeric allelicity.
 */

'use strict'

module.exports = function() {
  return function(allelicity) {
    if (allelicity == 'monoallelic') {
      return '1'
    } else if (allelicity == 'biallelic') {
      return '2'
    } else {
      throw new Error('Only monoallelic and biallelic allelicity are supported.')
    }
  }
}
