/**
 * @prettier
 */

module.exports = function ltrTable($filter, testTypes, testOutcome) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/ltrTable.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      // Card is only shown for H30 negative reports currently
      scope.showCard =
        scope.reportContent.template.test_type == testTypes.hereditary30 &&
        scope.reportContent.test_outcome == testOutcome.negative;
      if (scope.showCard) {
        scope.lowerAge = 50; // TODO: pick lower age based on subjects birthdate and report date, rounded up to nearest available risk stat
        scope.upperAge = 95;

        let phenotypes = [];
        if (scope.reportContent.gender == 'F') {
          phenotypes = [
            'uterine cancer',
            'melanoma',
            'pancreatic cancer',
            'ovarian cancer',
            'stomach cancer',
          ];
        } else {
          phenotypes = ['male breast cancer', 'melanoma', 'pancreatic cancer', 'stomach cancer'];
        }

        // Adjust phenotypes for cancer survivors
        if (scope.reportContent.breast_cancer_survivor && scope.reportContent.gender == 'M') {
          phenotypes.splice(phenotypes.indexOf('male breast cancer'), 1);
        }
        if (
          scope.reportContent.ovarian_cancer_survivor ||
          scope.reportContent.fallopian_tube_cancer_survivor ||
          scope.reportContent.primary_peritoneal_cancer_survivor
        ) {
          // Ovarian, Fallopian Tube, and Primary Peritoneal affect ovarian cancer risks
          phenotypes.splice(phenotypes.indexOf('ovarian cancer'), 1);
        }
        if (scope.reportContent.endometrial_cancer_survivor) {
          phenotypes.splice(phenotypes.indexOf('uterine cancer'), 1);
        }
        if (scope.reportContent.gastric_cancer_survivor) {
          phenotypes.splice(phenotypes.indexOf('stomach cancer'), 1);
        }
        if (scope.reportContent.melanoma_survivor) {
          phenotypes.splice(phenotypes.indexOf('melanoma'), 1);
        }
        if (scope.reportContent.pancreatic_cancer_survivor) {
          phenotypes.splice(phenotypes.indexOf('pancreatic cancer'), 1);
        }

        // Forces how SEER citation if double survivor depending on each gender
        if (
          scope.reportContent.colorectal_cancer_survivor &&
          ((scope.reportContent.gender == 'F' && scope.reportContent.breast_cancer_survivor) ||
            (scope.reportContent.gender == 'M' && scope.reportContent.prostate_cancer_survivor))
        ) {
          scope.showSeerCitation = true;
        }

        scope.tableData = [];
        angular.forEach(phenotypes, function(phenotype) {
          scope.tableData.push({
            phenotype: phenotype,
            lowerAgeRisk: $filter('getSingleRisk')(
              scope.reportContent.risks,
              scope.reportContent.gender,
              phenotype,
              scope.lowerAge
            ),
            upperAgeRisk: $filter('getSingleRisk')(
              scope.reportContent.risks,
              scope.reportContent.gender,
              phenotype,
              scope.upperAge
            ),
          });
        });
      }
    },
  };
};
