function PurchaseForWhoController($scope, $http, $modalInstance, $location, claimStore, requestErrorToast, sku, pricingModifier, sampleReuseService) {
  'ngInject'

  $scope.nonUpgradePrice = sku.priceDollars
  const claim = claimStore.claim
  if (claim && claim.remaining_redemptions_count > 0) {
    const discount = claimStore.getNonUpgradeSkuDiscount(sku)
    $scope.nonUpgradePrice = discount.copay_cents / 100.0
    $scope.organizationName = discount.organization_name
  } else if (pricingModifier) {
    $scope.isPricingModifier = true
    $scope.upgradePrice = sku.priceDollars + (pricingModifier.price_cents / 100)
  } else if (!claim && !pricingModifier) {
    $scope.noClaim = true
  }

  $scope.continue = function() {
    if ($scope.upgrade.recipient == 'self') {
      // If sample reuse is possible, go through that flow instead.
      if (sampleReuseService.eligibleForReuse(sku.name)) {
        $location.path('/sample-reuse/pay').search({sku: sku.name})
      } else {
        $location.path('/purchase/ordering-physician').search({sku: sku.name, purchase_for_myself: true})
      }
    } else {
      $location.path('/purchase/ordering-physician').search({sku: sku.name, purchase_for_someone_else: true})
    }
    $modalInstance.dismiss()
  }
}

function PossibleUpgradeController($scope, $http, $modalInstance, $location, $window, claimStore, requestErrorToast, sku) {
  'ngInject'

  const claim = claimStore.claim

  if (!angular.isUndefined(claim.remaining_redemptions_count)) {
    $scope.remainingRedemptions = claim.remaining_redemptions_count
  }

  $scope.loginPath = '/react/sign-in' + '?next=' + encodeURIComponent($location.url())

  $scope.continue = function() {
    if ($scope.upgrade.choice == 'upgrade') {
      $location.url($scope.loginPath)
      $modalInstance.dismiss()
    } else {
      $modalInstance.dismiss()
    }
  }
}

module.exports = ($modal, $location, auth, claimStore) => {
  'ngInject'

  const UpgradePromptFunction = function() {
    this.openPurchaseForWhoModal = (sku, pricingModifier) => {
      return $modal.open({
        templateUrl: '/benefits/modals/purchase_for_who.html',
        controller: PurchaseForWhoController,
        size: 'md',
        resolve: {
          sku: () => sku,
          pricingModifier: () => pricingModifier
        }
      })
    },

    this.openPossibleUpgradeModal = (sku) => {
      // Modal to show user they might have upgrade available if they sign-in
      return $modal.open({
        templateUrl: '/benefits/modals/upgrade_possible.html',
        controller: PossibleUpgradeController,
        size: 'md',
        resolve: {
          sku: () => sku,
        }
      })
    },

    this.checkAndOpen = (sku) => {
      const params = $location.search()
      // Check if data-upgrade is possible. If so: first do that
      if (!params.purchase_for_someone_else && !params.purchase_for_myself) {
        // For clients, see if they can upgrade to OC
        if (auth.isLoggedIn) {
          const modifier = auth.currentUser.pricing_modifiers[sku.name]
          if (modifier && (modifier.name == 'combo_upgrade_modifier' || modifier.name == 'entry_upgrade_modifier')) {
            this.openPurchaseForWhoModal(sku, modifier)
            return
          }
        }
      }

      // For logged out users with Benefits Claim. Offer modal suggesting there may be upgrade available
      if (!auth.isLoggedIn) {
        claimStore.getClaim().then( (claim) => {
          if (claim && claim.upgrades_available > 0) {
            this.openPossibleUpgradeModal(sku)
            return
          }
        })
      }
    }
  }
  return new UpgradePromptFunction()
}
