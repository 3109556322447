'use strict'

module.exports = function OrderingPhysicianController(
  $scope, $location, $modal, $cookies, auth, textModal, waffle, ngToast,
  KitCheckoutFlow, kitPriceService, associatedSample, claimStore,
  promoCodeStore, requisitionStore
) {
  'ngInject'

  let promoCode;
  if (promoCodeStore.promotionalCode) {
    promoCode = promoCodeStore.promotionalCode.code
  }

  // If the promoCodeStore doesn't have the code, try grabbing it from the cookies
  if (!promoCode) {
    promoCode = $cookies.get('code')
  }

  // Add the code to the query params
  if (promoCode){
    $location.search('code', promoCode)
  }

  // Clear the code from cookies once it's been added to the query params
  $cookies.remove('code')

  // Get requisition from local storage or cookies
  let requisition;
  const storedRequisition = requisitionStore.get();
  if (storedRequisition) {
    requisition = storedRequisition;
  } else {
    requisition = $cookies.get("requisition");
  }

  // Add the requisition to the query params if it exists
  if (requisition) {
    $location.search("requisition", requisition);
  }

  // Clear the requisition from cookies once it's been added to the query params
  $cookies.remove("requisition");

  $location.path(`/react/orders`);
}
