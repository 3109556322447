const tutorials = {
  family_testing_direct: {
    cards: [
      {
        image: "ftp_overview_provider",
        title: "Here’s what to expect",
        description: "Let's quickly walk you through the process for getting $199 testing for your patient through Color's Family Testing Program."
      },
      {
        image: "submit_positive",
        title: "Submit the report",
        description: "Upload the positive report from your patient’s first-degree relative. We accept results from Color and also other genetic testing labs.",
      },
      {
        image: "color_computer",
        title: "Color will review the application",
        description: "When you submit the application, it will be sent to Color for review. This process takes 1-2 business days on average. "
      },
      {
        image: "provider_patient",
        title: "Place your patient’s order",
        description: "If the application is approved, we will send you an email with instructions on how you can place an order for your patient to receive $199 testing."
      },
    ]
  },
  family_testing_cascade: {
    cards: [
      {
        image: "ftp_overview_patient",
        title: "Here’s what to expect",
        description: "Let's quickly walk you through the process for getting $199 testing for your patient’s relatives through Color's Family Testing Program.",
      },
      {
        image: "submit_positive",
        title: "Submit the report",
        description: "Upload the positive report from your patient. We accept results from Color and also other genetic testing labs."
      },
      {
        image: "color_computer",
        title: "Color will review the application",
        description: "When you submit the application, it will be sent to Color for review. This process takes 1-2 business days on average."
      },
      {
        image: "patients_talking",
        title: "Your patient will receive instructions",
        description: "If the application is approved, we will send your patient an email with instructions on how they can invite their relatives to get $199 testing."
      }
    ]
  }
}

function BasicTutorialCardController($scope, $modalInstance, nextFn, prevFn, card) {
  $scope.next = function() { nextFn($modalInstance) }
  $scope.previous = function() { prevFn($modalInstance) }
  $scope.title = card.title
  $scope.description = card.description
  $scope.image = card.image
  $scope.isFirstCard = card.isFirstCard
  $scope.isLastCard = card.isLastCard
}

module.exports = angular.module('CPPTutorials', [])

  .factory('CPPTutorials', function($modal, auth, Tutorials) {
    class TutorialManager {
      constructor(cards, name) {
        this.cards = cards
        this.tutorialName = name
        this.currentIndex = 0;

        this.nextCard = ($modalInstance) => {
          $modalInstance.dismiss()
          this.currentIndex += 1
          if (this.currentIndex >= this.cards.length) {
            return
          } else {
            this.openModalForCard(this.currentIndex)
          }
        }

        this.previousCard = ($modalInstance) => {
          $modalInstance.dismiss()
          this.currentIndex -= 1
          if (this.currentIndex < 0) {
            return
          } else {
            this.openModalForCard(this.currentIndex)
          }
        }
      }

      openModalForCard(index) {
        if (index == 0 ) {
          const apiParams = {tutorial_name: this.tutorialName, event_type: 'start'}
          Tutorials.create(apiParams).$promise.catch(() => undefined)
        } else if (index >= this.cards.length - 1) {
          const apiParams = {tutorial_name: this.tutorialName, event_type: 'end'}
          Tutorials.create(apiParams).$promise.catch(() => undefined)
        }

        const card = this.cards[index]
        const self = this
        return $modal.open({
          templateUrl: "/ordering_physicians/modals/tutorial_basic_card.html",
          controller: BasicTutorialCardController,
          size: "skinny",
          resolve: {
            nextFn: function() { return self.nextCard },
            prevFn: function() { return self.previousCard },
            card: function() {
              return {
                title: card.title,
                description: card.description,
                image: card.image,
                isFirstCard: index == 0,
                isLastCard: index >= self.cards.length - 1
              }
            }
          }
        })
      }
    }

    class CPPTutorials {
      constructor(tutorials) {
        this.tutorials = tutorials
        const provider = auth.currentUser.provider_profile
        if (provider && provider.settings) {
          this.seenTutorials = provider.settings.tutorials_started
        } else {
          this.seenTutorials = []
        }
      }

      openTutorial(name) {
        this.cards = this.tutorials[name].cards
        this.seenTutorials.push(name)
        const manager = new TutorialManager(this.cards, name)
        manager.openModalForCard(0)
      }

      openTutorialIfNotSeen(name) {
        if (this.seenTutorials.indexOf(name) >= 0) {
          return
        } else {
          this.openTutorial(name)
        }
      }
    }

    return new CPPTutorials(tutorials)
  })
