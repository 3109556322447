module.exports = function ProviderSignInController($scope, auth, redirect) {
  'ngInject'

  $scope.loggingIn = false

  $scope.login = function(email, password) {
    $scope.loggingIn = true
    auth.login(email, password)
      .then(function() {
        redirect('/')
      })
      .catch(function(res) {
        $scope.loggingIn = false
      })
  }
}
