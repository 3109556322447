"use strict"

// Toast related directives
/////////////////////////////

/**
 * Pops toast for django messages
 */
module.exports = function(ngToast) {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      popToast: '@',
      popToastTags: '@'
    },
    link: function(scope, elem, attrs) {
      ngToast.create({ className: scope.popToastTags, content: scope.popToast })
    }
  }
}
