/**
 * @prettier
 */

module.exports = function() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_question.html',
    replace: true,
    scope: {
      reportContent: '=',
      question: '=',
    },
  };
};
