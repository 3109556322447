module.exports = function($scope, $translate, auth, $timeout) {
  "ngInject"

  $scope.shareLink = 'https://www.color.com/l/referral?code=' + auth.currentUser.referral_code
  $translate([
    'ORDER_CONFIRMATION.SHARE_MODAL.SUBJECT',
    'ORDER_CONFIRMATION.SHARE_MODAL.MAIL',
    'ORDER_CONFIRMATION.SHARE_MODAL.TWEET'
  ], {
    shareLink: $scope.shareLink,
    senderFirstName: auth.currentUser.first_name
  }).then(function(translations) {
    $scope.twitterStatus = translations['ORDER_CONFIRMATION.SHARE_MODAL.TWEET']
    $scope.mailto = 'mailto:?subject='
    $scope.mailto += encodeURIComponent(translations['ORDER_CONFIRMATION.SHARE_MODAL.SUBJECT'])
    $scope.mailto += '&body=' + encodeURIComponent(translations['ORDER_CONFIRMATION.SHARE_MODAL.MAIL'])
  })

  $scope.copied = ''
  $scope.onCopySuccess = function(e) {
    $scope.$apply(function() {
      $scope.copied = 'Copied to clipboard'
    })
    $timeout(function() {
      $scope.copied = ''
    }, 2000)
  };
}
