!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name healthProfileFilter
   *
   * @description
   * The `healthProfileFilter` provides filters for health profiles.
   */
  const module = angular.module('healthProfileFilter', ['clrFilter'])

  /**
   * @ngdoc filter
   * @name anyRelativeHadCancer
   * @memberof healthProfileFilter
   *
   * @description
   * Checks if any of given relative had cancer.
   * Returns true or false.
   */
  module.filter('anyRelativeHadCancer', function() {
    return function(relatives) {
      if (angular.isUndefined(relatives)) {
        return false
      }
      for (let i = 0; i < relatives.length; i++) {
        const relative = relatives[i]
        if (relative.female_relative_health_profile) {
          if (relative.female_relative_health_profile.had_cancer) {
            return true
          }
        } else if (relative.male_relative_health_profile) {
          if (relative.male_relative_health_profile.had_cancer) {
            return true
          }
        }
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name anyRelativeHadCardioHistory
   * @memberof healthProfileFilter
   *
   * @description
   * Checks if any of given relative had cardio history.
   * Returns true or false.
   */
  module.filter('anyRelativeHadCardioHistory', function() {
    return function(relatives) {
      if (angular.isUndefined(relatives)) {
        return false
      }
      for (let i = 0; i < relatives.length; i++) {
        const relative = relatives[i]
        if (relative.cardio_history.length) {
          return true
        }
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name anyRelativeHadMutation
   * @memberof healthProfileFilter
   *
   * @description
   * Checks if any of given relative had mutation.
   * Returns true or false.
   */
  module.filter('anyRelativeHadMutation', function() {
    return function(relatives, clinicalDomain) {
      if (angular.isUndefined(relatives)) {
        return false
      }
      let fieldName
      if (clinicalDomain == 'cancer') {
        fieldName = 'had_mutation'
      } else if (clinicalDomain == 'cardio') {
        fieldName = 'had_cardio_mutation'
      } else {
        throw clinicalDomain + ' not supported.'
      }
      for (let i = 0; i < relatives.length; i++) {
        if (relatives[i][fieldName] == 'Y') {
          return true
        }
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name yesNoFullText
   * @memberof healthProfileFilter
   *
   * @description
   * Converts shortened yes/no/I'm not sure option into full text.
   */
  module.filter('yesNoFullText', function() {
    return function(shortenForm, unknownText) {
      if (shortenForm == 'Y') {
        return 'Yes'
      } else if (shortenForm == 'N') {
        return 'No'
      } else {
        return unknownText ? unknownText : 'I\'m not sure'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name booleanToYesNo
   * @memberof healthProfileFilter
   *
   * @description
   * Converts boolean to yes or no.
   */
  module.filter('booleanToYesNo', function() {
    return function(value) {
      if (value) {
        return 'Yes'
      } else {
        return 'No'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name birthYearToAge
   * @memberof healthProfileFilter
   *
   * @description
   * Converts birth year to age.
   */
  module.filter('birthYearToAge', function() {
    return function(birthYear) {
      const date = new Date();
      const currentYear = date.getFullYear();
      const anonymizedIndex = birthYear.indexOf ? birthYear.indexOf(' or earlier') : -1
      if (anonymizedIndex == -1) {
        date.setYear(birthYear)
        return currentYear - date.getFullYear()
      } else {
        date.setYear(birthYear.slice(0, anonymizedIndex))
        return currentYear - date.getFullYear() + ' or earlier'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name ageToBirthYear
   * @memberof healthProfileFilter
   *
   * @description
   * Converts age to birth year.
   */
  module.filter('ageToBirthYear', function() {
    return function(age) {
      if (age == null) {
        return null
      }
      const date = new Date()
      date.setYear(date.getFullYear() - age)
      return date.getFullYear()
    }
  })

  /**
   * @ngdoc filter
   * @name familyRelativeIdentifier
   * @memberof healthProfileFilter
   *
   * @description
   * Converts health profile object into it's identifier string.
   */
  module.filter('familyRelativeIdentifier', function($filter) {
    const relationshipNames = {
      'full sister': 'sister',
      'full brother': 'brother',
      'maternal half sister': 'half-sister, mother',
      'maternal half brother': 'half-brother, mother',
      'paternal half sister': 'half-sister, father',
      'paternal half brother': 'half-brother, father'
    }
    return function(relative, index, total, relationshipOnly) {
      if (!relative) {
        return
      }

      let name;
      let prefix = '';
      let suffix = ''

      if (!relationshipOnly && relative.full_name) {
        name = relative.full_name
      } else if (!relationshipOnly && relative.first_name && relative.last_name) {
        name = relative.first_name + ' ' + relative.last_name
      } else {
        name = $filter('capitalize')(relationshipNames[relative.relationship] || relative.relationship, 'first')
        if (!angular.isUndefined(index) && total > 1) {
          prefix = $filter('ordinalNumber')(index + 1) + ' '
        }
      }

      if (relative.age_at_death) {
        suffix = ' (' + relative.age_at_death + ', dec.' + ')'
      } else if (relative.birth_year) {
        suffix = ' (' + $filter('birthYearToAge')(relative.birth_year) + ')'
      } else {
        suffix = ' (unknown)'
      }

      return prefix + name + suffix
    }
  })

  /**
   * @ngdoc filter
   * @name familyRelativeAge
   * @memberof healthProfileFilter
   *
   * @description
   * Converts health profile object into it's age string.
   */
  module.filter('familyRelativeAge', function($filter) {
    return function(relative, fullSentence) {
      if (fullSentence) {
        if (relative.age_at_death) {
          return ' was ' + relative.age_at_death + ' years old at death'
        } else if (relative.birth_year) {
          return ' is ' + $filter('birthYearToAge')(relative.birth_year) + ' years old'
        }
        return "'s age is unknown"
      } else {
        if (relative.age_at_death) {
          return relative.age_at_death + ' years old at death'
        } else if (relative.birth_year) {
          return $filter('birthYearToAge')(relative.birth_year) + ' years old'
        }
        return 'unknown'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name genesHadMutation
   * @memberof healthProfileFilter
   *
   * @description
   * Returns a list of genes that had mutation given a health profile.
   */
  module.filter('genesHadMutation', function() {
    return function(mutations) {
      return mutations.map(function(m) {
        let res = m.gene
        if (m.name) {
          res += ' (' + m.name + ')'
        }
        return res
      })
    }
  })

  /**
   * @ngdoc filter
   * @name cancersDiagnosed
   * @memberof healthProfileFilter
   *
   * @description
   * Returns a list of cancers that have been diagnosed
   * given a gender specific health profile.
   */
  module.filter('cancersDiagnosed', function() {
    return function(profile) {
      const cancers = []
      if (profile.had_breast_cancer) {
        cancers.push('Breast cancer')
      }
      if (profile.had_ovarian_cancer) {
        cancers.push('Ovarian cancer')
      }
      if (profile.had_fallopian_tube_cancer) {
        cancers.push('Fallopian tube cancer')
      }
      if (profile.had_primary_peritoneal_cancer) {
        cancers.push('Primary peritoneal cancer')
      }
      if (profile.had_other_cancer1) {
        cancers.push(profile.other_cancer1)
      }
      if (profile.had_other_cancer2) {
        cancers.push(profile.other_cancer2)
      }
      return cancers
    }
  })

  /**
   * @ngdoc filter
   * @name genderFullText
   * @memberof healthProfileFilter
   *
   * @description
   * Expand abbreviated gender string.
   */
  module.filter('genderFullText', function() {
    return function(gender) {
      if (gender == 'F') {
        return 'Female'
      }
      if (gender == 'M') {
        return 'Male'
      }
      return ''
    }
  })

  /**
   * @ngdoc filter
   * @name familyRelativesSort
   * @memberof healthProfileFilter
   *
   * @description
   * Returns the list of relatives in order of importance.
   * Priority is children, parents, grandparents, siblings, mother side, father side with female over male priority.
   */
  module.filter('familyRelativesSort', function() {
    const relationshipPriorityList = [
      'daughter',
      'son',
      'mother',
      'father',
      'maternal grandmother',
      'maternal grandfather',
      'paternal grandmother',
      'paternal grandfather',
      'full sister',
      'full brother',
      'maternal half sister',
      'maternal half brother',
      'paternal half sister',
      'paternal half brother',
      'maternal aunt',
      'maternal uncle',
      'paternal aunt',
      'paternal uncle'
    ]
    return function(relatives) {
      return relatives.sort(function(a,b) {
        const indexA = relationshipPriorityList.indexOf(a.relationship)
        const indexB = relationshipPriorityList.indexOf(b.relationship)
        if (indexA == indexB) {
          return a.id - b.id
        }
        return indexA - indexB
      })
    }
  })

}();
