// *** DO NOT EDIT THIS OBJECT MANUALLY ***
// It is generated in Python using geographical_area.generate().
//
// To re-generate, edit monoweb.django_apps.users.geographical_area, then run:
//
//     $ python -m monoweb.django_apps.users.geographical_area
//
// `shared_js` has no mechanism for testing. Until it is moved to a managed workspace, we test via
// the applications that use this code.
// See:
// `$COLOR_ROOT/projects/home/frontend/src/lib/util/geoConstants/utils.test.ts`
// `$COLOR_ROOT/monoweb/django_apps/core/tests/js/geo_constants_spec.js
// FIXME: When this moves into a yarn workspace, move the unit tests there.
//

!function() {
  'use strict'

  const module = angular.module('clrGeographical', [])

  function getField(list, index, def) {
    if (list == undefined) return def
    if (list[index] == undefined) return def
    return list[index]
  }

  function sort_countries($filter, country_list) {
    country_list.sort(function (a, b) {
      return $filter('name')(a).localeCompare($filter('name')(b))
    })
    return country_list
  }

  const defaultAddressFieldOrder = [["city"],["state","postalCode"]]

  const defaultAddressOptionalFields = ["state","postalCode"]

  module
    .filter("name", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 0, null)
      }
    })

    .filter("phonePrefix", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 1, null)
      }
    })

    .filter("phoneMask", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 2, null)
      }
    })

    .filter("daysToDeliver", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 3, null)
      }
    })

    .filter("shippingChargeCents", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 4, null)
      }
    })

    .filter("ourGovtOk", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 5, true)
      }
    })

    .filter("theirGovtOk", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 6, true)
      }
    })

    .filter("carrierOk", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 7, true)
      }
    })

    .filter("cityMaxlen", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 8, 80)
      }
    })

    .filter("cityLabel", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 9, "City, Town, or Settlement")
      }
    })

    .filter("cityWidth", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 10, 100)
      }
    })

    .filter("stateMaxlen", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 11, 50)
      }
    })

    .filter("stateLabel", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 12, "State, Province, or Region")
      }
    })

    .filter("stateWidth", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 13, 60)
      }
    })

    .filter("postalCodeMaxlen", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 14, 10)
      }
    })

    .filter("postalCodeLabel", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 15, "Postal Code")
      }
    })

    .filter("postalCodeWidth", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 16, 40)
      }
    })

    .filter("postalCodeMask", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 17, null)
      }
    })

    .filter("addressFieldOrder", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 18, defaultAddressFieldOrder)
      }
    })

    .filter("addressOptionalFields", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 19, defaultAddressOptionalFields)
      }
    })

    .filter("flagFilename", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 20, countryCode + ".png")
      }
    })

    .filter("outboundShipperName", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 21, "DHL")
      }
    })

    .filter("outboundServiceName", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 22, "Express Worldwide")
      }
    })

    .filter("deliveredDutyPaid", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 23, false)
      }
    })

    .filter("taxIdMaxlen", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 24, null)
      }
    })

    .filter("taxIdLabel", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 25, null)
      }
    })

    .filter("taxIdWidth", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 26, null)
      }
    })

    .filter("taxIdMask", function(geographicalArea) {
      return function(countryCode) {
        return getField(geographicalArea[countryCode], 27, null)
      }
    })

    .filter('fieldWidth', function($filter) {
      return function(countryCode, field) {
        if (field == 'city') return $filter('cityWidth')(countryCode)
        if (field == 'state') return $filter('stateWidth')(countryCode)
        if (field == 'postalCode') return $filter('postalCodeWidth')(countryCode)
        if (field == 'taxId') return $filter('taxIdWidth')(countryCode)
      }
    })

    .filter('shippable', function ($filter) {
      return function (countryCode) {
        return $filter('ourGovtOk')(countryCode)
        && $filter('theirGovtOk')(countryCode)
        && $filter('carrierOk')(countryCode)
      }
    })

    .filter('hasAddressField', function ($filter) {
      return function (countryCode, fieldName) {
        const fieldOrder = $filter('addressFieldOrder')(countryCode)
        // fieldOrder is a nested array of the address fields for this country, so
        // we need to flatten them.
        const flattenedFields = [].concat.apply([], fieldOrder)
        return flattenedFields.indexOf(fieldName) != -1
      }
    })

    .filter('isRequiredField', function ($filter) {
      return function (countryCode, fieldName) {
        const optionalFields = $filter('addressOptionalFields')(countryCode)
        return optionalFields.indexOf(fieldName) == -1
      }
    })

  // returns list of countries that are mailable to
    .factory('allCountries', function ($filter, geographicalArea) {
      const allCountries = []
      angular.forEach(Object.keys(geographicalArea), function (countryCode) {
        if ($filter('ourGovtOk')(countryCode) && $filter('carrierOk')(countryCode)) {
          allCountries.push(countryCode)
        }
      })
      return sort_countries($filter, allCountries)
    })

  // returns every country in the world
    .factory('entireCountryList', function ($filter, geographicalArea) {
      const entireCountryList = []
      angular.forEach(Object.keys(geographicalArea), function (countryCode) {
        entireCountryList.push(countryCode)
      })
      return sort_countries($filter, entireCountryList)
    })

    .factory('phoneCountries', function ($filter, geographicalArea) {
      const phoneCountries = []
      angular.forEach(Object.keys(geographicalArea), function (countryCode) {
        if ($filter('phonePrefix')(countryCode) != null) {
          phoneCountries.push(countryCode)
        }
      })
      return sort_countries($filter, phoneCountries)
    })

  /**
 * @ngdoc constant
 * @const geographicalArea
 * @static
 * @memberof clrGeographical
 *
 * @description
 * Returns a list of geographical area.
 *
 * @returns {list}
 */
    .constant('geographicalArea', {
      AF: ["Afghanistan","+93",,"6",10900],
      AL: ["Albania","+355",,"9",10900],
      DZ: ["Algeria","+213",,"11",12200],
      AS: ["American Samoa","+1",,"6",11600,,,,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png"],
      AD: ["Andorra","+376",,"5",6900],
      AO: ["Angola","+244",,"6",12200],
      AI: ["Anguilla","+1",,"5",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      AQ: ["Antarctica",,,,,,,false],
      AG: ["Antigua and Barbuda","+1","(999) 999-9999","5",6600],
      AR: ["Argentina","+54",,"4",8100],
      AM: ["Armenia","+374",,"6",9000],
      AW: ["Aruba","+297",,"4",6600,,,,,,,,,,,,,,,,"NL.png"],
      AU: ["Australia","+61",,"12",7900,,,,,"Suburb",45,3,"State Code",30,6,"Postcode",25,,[["city","state","postalCode"]],[]],
      AT: ["Austria","+43",,"4",9300],
      AZ: ["Azerbaijan","+994",,"6",10700],
      BS: ["Bahamas","+1","(999) 999-9999","4",6600],
      BH: ["Bahrain","+973",,"5",9800],
      BD: ["Bangladesh","+880",,"6",7900],
      BB: ["Barbados","+1","(999) 999-9999","4",6600],
      BY: ["Belarus","+375",,"7",10700,false],
      BE: ["Belgium","+32",,"4",8200],
      BZ: ["Belize","+501",,"4",8100],
      BJ: ["Benin","+229",,"5",12200],
      BM: ["Bermuda","+1",,"4",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      BT: ["Bhutan","+975",,"10",9500],
      BO: ["Bolivia","+591",,"5",8100],
      BQ: ["Bonaire, Sint Eustatius, and Saba","+599",,"4",6600,,,false,,,,,,,,,,,,,"NL.png"],
      BA: ["Bosnia and Herzegovina","+387",,"5",10900],
      BW: ["Botswana","+267",,"5",12200],
      BV: ["Bouvet Island",,,"4",9300,,,false,,,,,,,,,,,,,"NO.png"],
      BR: ["Brazil","+55",,"5",8100,,,,,"City",80,2,"State",20,9,,100,"99999-999",[["city","state"],["postalCode"],["taxId"]],[],,,,true,18,"Tax ID number (CPF)",100,"999.999.999-99"],
      IO: ["British Indian Ocean Territory","+246",,"4",9900,,,false,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      BN: ["Brunei Darussalam","+673",,"5",10200],
      BG: ["Bulgaria","+359",,"4",10900],
      BF: ["Burkina Faso","+226",,"5",12200],
      BI: ["Burundi","+257",,"6",11400,false],
      CV: ["Cabo Verde","+238",,"7",11400],
      KH: ["Cambodia","+855",,"7",10200],
      CM: ["Cameroon","+237",,"5",12200],
      CA: ["Canada","+1","(999) 999-9999","3",6800,,,,,"City",45,2,"Province",25,,,30,"A9A 9A9",[["city","state","postalCode"]],[],,,,true],
      KY: ["Cayman Islands","+1",,"3",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      CF: ["Central African Republic","+236",,"7",11400,false],
      TD: ["Chad","+235",,"7",11400],
      CL: ["Chile","+56",,"4",8100],
      CN: ["China","+86",,"5",5500,,false],
      CX: ["Christmas Island","+61",,,,,,false],
      CC: ["Cocos (Keeling) Islands","+61",,,,,,false],
      CO: ["Colombia","+57",,"4",8100],
      KM: ["Comoros","+269",,"6",11400],
      CG: ["Congo","+242",,"6",12200],
      CD: ["Congo (DRC)","+243",,"6",11400,false],
      CK: ["Cook Islands","+682",,"6",9500,,,,,"Island",70,,,0,4,"Postcode",30,,[["city","postalCode"]],[],"NZ.png"],
      CR: ["Costa Rica","+506",,"4",8100],
      HR: ["Croatia","+385",,"4",10900],
      CU: ["Cuba","+53",,,,false,,false],
      CW: ["Curaçao","+599",,"4",8200,,,false,,,,,,,,,,,,,"NL.png"],
      CY: ["Cyprus","+357",,"6",9300],
      CZ: ["Czechia","+420",,"4",10900],
      CI: ["Côte d'Ivoire","+225",,"6",11400,false],
      DK: ["Denmark","+45",,"4",9300],
      DJ: ["Djibouti","+253",,"6",11400],
      DM: ["Dominica","+1","(999) 999-9999","6",6600],
      DO: ["Dominican Republic","+1","(999) 999-9999","4",6600],
      EC: ["Ecuador","+593",,"4",8100],
      EG: ["Egypt","+20",,"5",8100],
      SV: ["El Salvador","+503",,"4",8100],
      GQ: ["Equatorial Guinea","+240",,"7",11400],
      ER: ["Eritrea","+291",,"6",11400],
      EE: ["Estonia","+372",,"4",10900],
      SZ: ["Eswatini","+268",,"6",9300],
      ET: ["Ethiopia","+251",,"5",11400],
      FK: ["Falkland Islands (Malvinas)","+500",,"7",7200,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      FO: ["Faroe Islands","+298",,"7",6900,,,,,,,,,,,,,,,,"DK.png"],
      FJ: ["Fiji","+679",,"7",10200],
      FI: ["Finland","+358",,"4",9300,,,,,"Settlement",70,,,0,5,,30,,[["postalCode","city"]],[]],
      FR: ["France","+33",,"4",6100,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[]],
      GF: ["French Guiana","+594",,"6",7200,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      PF: ["French Polynesia","+689",,"4",10200,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      TF: ["French Southern Territories",,,"4",6100,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      GA: ["Gabon","+241",,"5",12200],
      GM: ["Gambia","+220",,"6",12200],
      GE: ["Georgia","+995",,"8",10700],
      DE: ["Germany","+49",,"4",8200,,false,,,"Settlement",80,,,0,5,,20,,[["postalCode","city"]],[]],
      GH: ["Ghana","+233",,"6",12200],
      GI: ["Gibraltar","+350",,"5",6900,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      GR: ["Greece","+30",,"4",9300],
      GL: ["Greenland","+299",,"8",6900,,,,,,,,,,,,,,,,"DK.png"],
      GD: ["Grenada","+1","(999) 999-9999","5",6600],
      GP: ["Guadeloupe","+590",,"4",6300,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      GU: ["Guam","+1",,"4",9200,,,,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png"],
      GT: ["Guatemala","+502",,"4",8100],
      GG: ["Guernsey","+44",,"5",6900,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      GN: ["Guinea","+224",,"6",12200],
      GW: ["Guinea Bissau","+245",,"6",11400],
      GY: ["Guyana","+592",,"6",8100],
      HT: ["Haiti","+509",,"4",6600],
      HM: ["Heard Island and McDonald Islands",,,"12",7900,,,false,,"Suburb",45,3,"State Code",30,6,"Postcode",25,,[["city","state","postalCode"]],[],"AU.png"],
      VA: ["Holy See (Vatican City State)","+379",,"4",6100,,,false],
      HN: ["Honduras","+504",,"4",8100],
      HK: ["Hong Kong","+852",,"4",7100,,,,,"Village, Town, or District",,15,"Part of Hong Kong",40,,,0,,[["city"],["state"]],[]],
      HU: ["Hungary","+36",,"4",10900],
      IS: ["Iceland","+354",,"4",9300],
      IN: ["India","+91",,"5",5800,,false],
      ID: ["Indonesia","+62",,"5",7900],
      IR: ["Iran","+98",,,,false,,false],
      IQ: ["Iraq","+964",,"5",9000,false],
      IE: ["Ireland","+353",,"4",8200,,,,,"Postal Town",,,,0,8,,30,,[["city"],["postalCode"]],[]],
      IM: ["Isle of Man","+44",,"4",9900,,,false,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      IL: ["Israel","+972",,"4",9800,,,,,"City",70,,,0,7,,30,,[["postalCode","city"]],[]],
      IT: ["Italy","+39",,"4",8200,,,,,"Town",45,2,"Province",25,5,,30,,[["postalCode","city","state"]],[]],
      JM: ["Jamaica","+1","(999) 999-9999","4",6600],
      JP: ["Japan","+81",,"4",8300],
      JE: ["Jersey","+44",,"5",6900,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      JO: ["Jordan","+962",,"6",9800],
      KZ: ["Kazakhstan","+7",,"6",10900],
      KE: ["Kenya","+254",,"5",11400],
      KI: ["Kiribati","+686",,"8",10200],
      XK: ["Kosovo","+383",,,,,,false],
      KW: ["Kuwait","+965",,"6",9800],
      KG: ["Kyrgyzstan","+996",,"7",10700],
      LA: ["Laos","+856",,"7",10200],
      LV: ["Latvia","+371",,"4",10900],
      LB: ["Lebanon","+961",,"5",9000,false],
      LS: ["Lesotho","+266",,"5",11400],
      LR: ["Liberia","+231",,"6",12200],
      LY: ["Libya","+218",,"7",11400,false],
      LI: ["Liechtenstein","+423",,"5",6900],
      LT: ["Lithuania","+370",,"5",10900],
      LU: ["Luxembourg","+352",,"4",8200],
      MO: ["Macau","+853",,"5",5800,,false,,,,,,,,,,,,,,"CN.png"],
      MG: ["Madagascar","+261",,"6",12200],
      MW: ["Malawi","+265",,"6",12200],
      MY: ["Malaysia","+60",,"5",7900],
      MV: ["Maldives","+960",,"7",10200],
      ML: ["Mali","+223",,"5",12200],
      MT: ["Malta","+356",,"5",9300],
      MH: ["Marshall Islands","+692",,,,,,false],
      MQ: ["Martinique","+596",,"4",6300,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      MR: ["Mauritania","+222",,"6",11400],
      MU: ["Mauritius","+230",,"5",12200],
      YT: ["Mayotte","+262",,"4",11400,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      MX: ["Mexico","+52",,"4",4500,,,,,"Settlement",50,5,"State",20,5,,30,"99999",[["postalCode","city","state"]],[]],
      FM: ["Micronesia","+691",,,,,,false],
      MD: ["Moldova","+373",,"6",10700],
      MC: ["Monaco","+377",,"5",6100],
      MN: ["Mongolia","+976",,"9",10200],
      ME: ["Montenegro","+382",,"6",10900],
      MS: ["Montserrat","+1",,"5",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      MA: ["Morocco","+212",,"4",12200],
      MZ: ["Mozambique","+258",,"6",12200],
      MM: ["Myanmar","+95",,,,false,,false],
      NA: ["Namibia","+264",,"5",12200],
      NR: ["Nauru","+674",,"7",10200],
      NP: ["Nepal","+977",,"7",10200],
      NL: ["Netherlands","+31",,"4",8200],
      NC: ["New Caledonia","+687",,"7",9500,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      NZ: ["New Zealand","+64",,"5",7900,,,,,"Settlement",70,,,0,4,"Postcode",30,,[["city","postalCode"]],[]],
      NI: ["Nicaragua","+505",,"4",8100],
      NE: ["Niger","+227",,"7",11400],
      NG: ["Nigeria","+234",,"5",12200],
      NU: ["Niue","+683",,"7",10200,,,,,"Settlement",70,,,0,4,"Postcode",30,,[["city","postalCode"]],[],"NZ.png"],
      NF: ["Norfolk Island","+672",,"12",7900,,,false,,"Suburb",45,3,"State Code",30,6,"Postcode",25,,[["city","state","postalCode"]],[],"AU.png"],
      KP: ["North Korea","+850",,,,false,,false],
      MK: ["North Macedonia","+389",,"5",7900],
      MP: ["Northern Mariana Islands","+1",,"2-6",995,,,false,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png","USPS","First-class Mail"],
      NO: ["Norway","+47",,"4",9300],
      OM: ["Oman","+968",,"5",9800],
      PK: ["Pakistan","+92",,"6",9800],
      PW: ["Palau","+680",,,,,,false],
      PS: ["Palestine","+970",,,,,,false],
      PA: ["Panama","+507",,"4",8100],
      PG: ["Papua New Guinea","+675",,"7",10200],
      PY: ["Paraguay","+595",,"5",8100],
      PE: ["Peru","+51",,"5",8100],
      PH: ["Philippines","+63",,"5",7900],
      PN: ["Pitcairn","+64",,"4",9900,,,false,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      PL: ["Poland","+48",,"4",10900],
      PT: ["Portugal","+351",,"4",8200],
      PR: ["Puerto Rico","+1",,"2-6",995,,,,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png","USPS","First-class Mail"],
      QA: ["Qatar","+974",,"5",9800],
      RO: ["Romania","+40",,"5",10900],
      RU: ["Russian Federation","+7",,"6",10700,,false],
      RW: ["Rwanda","+250",,"5",11400],
      RE: ["Réunion","+262",,"4",11400,,false,,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      BL: ["Saint Barthélemy","+590",,"4",6600,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      SH: ["Saint Helena, Ascension and Tristan da Cunha","+290",,"4",9900,,,false,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      KN: ["Saint Kitts and Nevis","+1","(999) 999-9999","5",6600],
      LC: ["Saint Lucia","+1","(999) 999-9999","5",6600],
      MF: ["Saint Martin (French part)","+590",,"4",6100,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      PM: ["Saint Pierre and Miquelon","+508",,"4",6100,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      VC: ["Saint Vincent and the Grenadines","+1","(999) 999-9999","5",6600],
      WS: ["Samoa","+685",,"6",10200],
      SM: ["San Marino","+378",,"5",6100],
      ST: ["Sao Tome and Principe","+239",,"8",11400],
      SA: ["Saudi Arabia","+966",,"4",9000],
      SN: ["Senegal","+221",,"5",12200],
      RS: ["Serbia","+381",,"4",10900],
      SC: ["Seychelles","+248",,"4",12200],
      SL: ["Sierra Leone","+232",,"6",11400],
      SG: ["Singapore","+65",,"5",7100,,,,,"City",70,,,0,6,,30,,[["city","postalCode"]],[]],
      SX: ["Sint Maarten (Dutch part)","+1","(999) 999-9999","4",6600,,,false,,,,,,,,,,,,,"NL.png"],
      SK: ["Slovakia","+421",,"4",10900],
      SI: ["Slovenia","+386",,"4",10900],
      SB: ["Solomon Islands","+677",,"6",10200],
      SO: ["Somalia","+252",,,,false,,false],
      ZA: ["South Africa","+27",,"5",12200],
      GS: ["South Georgia and the South Sandwich Islands","+500",,"4",9900,,,false,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      KR: ["South Korea","+82",,"8",7800,,false],
      SS: ["South Sudan","+211",,,,false,,false],
      ES: ["Spain","+34",,"4",8200,,,,,"Town",40,30,"Province",30,5,,30,,[["postalCode","city","state"]],["state"]],
      LK: ["Sri Lanka","+94",,"5",7900],
      SD: ["Sudan","+249",,,,false,,false],
      SR: ["Suriname","+597",,"7",8100],
      SJ: ["Svalbard and Jan Mayen","+47",,"4",9300,,,false,,,,,,,,,,,,,"NO.png"],
      SE: ["Sweden","+46",,"4",9300],
      CH: ["Switzerland","+41",,"4",9300],
      SY: ["Syria","+963",,,,false,,false],
      TW: ["Taiwan","+886",,"4",7100],
      TJ: ["Tajikistan","+992",,"7",10700],
      TZ: ["Tanzania","+255",,"5",12200],
      TH: ["Thailand","+66",,"5",7900],
      TL: ["Timor-Leste","+670",,"10",9500],
      TG: ["Togo","+228",,"5",12200],
      TK: ["Tokelau","+690",,"5",7900,,,false,,"Settlement",70,,,0,4,"Postcode",30,,[["city","postalCode"]],[],"NZ.png"],
      TO: ["Tonga","+676",,"7",10200],
      TT: ["Trinidad and Tobago","+1","(999) 999-9999","4",6600],
      TN: ["Tunisia","+216",,"6",11400],
      TM: ["Turkmenistan","+993",,,,,,false],
      TC: ["Turks and Caicos Islands","+1",,"4",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      TV: ["Tuvalu","+688",,"7",10200],
      TR: ["Türkiye","+90",,"4",9800],
      UG: ["Uganda","+256",,"5",12200],
      UA: ["Ukraine","+380",,"6",10900],
      AE: ["United Arab Emirates","+971",,"4",9800],
      GB: ["United Kingdom","+44",,"4",9900,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[]],
      US: ["United States","+1","(999) 999-9999","2-6",995,,,,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],,"USPS","First-class Mail"],
      UM: ["United States Minor Outlying Islands",,,"2-6",995,,,false,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png","USPS","First-class Mail"],
      UY: ["Uruguay","+598",,"5",8100],
      UZ: ["Uzbekistan","+998",,"7",10700],
      VU: ["Vanuatu","+678",,"6",9500],
      VE: ["Venezuela","+58",,"4",7200,false],
      VN: ["Viet Nam","+84",,"5",7900],
      VG: ["Virgin Islands, British","+1",,"4",6600,,,,,"Town",,,,0,8,"Postcode",100,,[["city"],["postalCode"]],[],"GB.png"],
      VI: ["Virgin Islands, U.S.","+1",,"2-6",995,,,,,"City",55,2,"State",20,,"Zip Code",25,"99999",[["city","state","postalCode"]],[],"US.png","USPS","First-class Mail"],
      WF: ["Wallis and Futuna","+681",,"4",6100,,false,false,,"Town Name and Sorting Code",70,,,0,5,,30,,[["postalCode","city"]],[],"FR.png"],
      EH: ["Western Sahara",,,,,,,false],
      YE: ["Yemen","+967",,"6",9000,false],
      ZM: ["Zambia","+260",,"6",12200],
      ZW: ["Zimbabwe","+263",,"5",11400,false],
      AX: ["Åland Islands","+358",,"4",9300,,,false,,"Settlement",70,,,0,5,,30,,[["postalCode","city"]],[],"FI.png"]
    })
}()
