"use strict"

module.exports = function ($rootScope, $anchorScroll, $document, $location, $timeout, $compile, setViewportScroll) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {},
    replace: true,
    transclude: true,
    template: '<div class="tab-bar tab-bar--directive" ui-scrollfix="+0"><div class="tab-bar__mask a--mobile-nav-mask" ng-if="mobileMenuOpen" ng-click="mobileToggleMenu(false)"></div><div class="tab-bar__mobile"><a href="#" class="tab-bar__more" ng-click="mobileToggleMenu()" aria-haspopup="true" aria-expanded="true">MORE</a></div><div class="tab-bar__inner" ng-class="{ \'js--active\': mobileMenuOpen }"><div class="tab-bar__underline"></div><ul><ng-transclude></ng-transclude></ul></div></div>',
    link: function (scope, elem, attrs) {

      let activeTab;
      let anchorAtLoad
      const tabUnderlineElement = elem.find('.tab-bar__underline')

      // Initialize tabs
      function initTabs() {
        anchorAtLoad = $location.hash()
        if (anchorAtLoad) {
          // Scroll to anchor in url if it exists
          setActiveTab(anchorAtLoad)
          $anchorScroll()
        } else {
          // Otherwise set the active tab as the first anchor
          const firstAnchor = elem.find('a[clr-tab-anchor-name]').attr('clr-tab-anchor-name')
          setActiveTab(firstAnchor)
        }
      }

      // Mobile versions of clrTab have an alternate layout that requires minor modifications
      function initMobileTabs() {
        let duplicateButton
        const tabBarButton = elem.find(".tab-bar__button")
        if (tabBarButton) {
          duplicateButton = tabBarButton.clone()
          // recompile for any attribute directives, e.g. sample reuse modal
          // needed since button is cloned above
          duplicateButton = $compile(duplicateButton)($rootScope)
          elem.find(".tab-bar__mobile").append(duplicateButton)
        }
      }

      function setActiveTab(anchorName) {
        // Remove active class from previous tabs
        elem.find('a[clr-tab-anchor-name]').removeClass('js--active')
        elem.find('a[clr-tab-anchor-name]').attr("aria-selected", false)
        // Add active class to new active tab
        activeTab = elem.find('a[clr-tab-anchor-name="' + anchorName + '"]')
        activeTab.addClass('js--active')
        activeTab.attr("aria-selected", true)
        // Move the underline
        setUnderline(tabUnderlineElement, activeTab)
      }

      // How to move the clr-tab__underline element
      function setUnderline(tabUnderlineElement, activeTab) {
        const activeTabParent = activeTab.parent()
        if (activeTabParent.length) {
          tabUnderlineElement.width(activeTabParent.width()).css('left', activeTabParent.position().left)
        }
      }

      // template ng-click triggers to scroll to anchors
      // expects the following HTML structure:
      // clr-tab-simple()
      //   li.tab-bar__list
      //     a.tab-bar__link(ng-click="$parent.goToAnchor($event)", clr-tab-anchor-name="foo") How it works
      scope.goToAnchor = function($event) {
        const anchorName = angular.element($event.currentTarget).attr('clr-tab-anchor-name')
        const anchorElement = angular.element('#' + anchorName)
        if (anchorElement.length) {
          $document.scrollToElementAnimated(anchorElement, 68)
        }
        scope.mobileMenuOpen = false
        setViewportScroll.enable()
      }

      scope.mobileToggleMenu = function(bool) {
        // setViewportScroll(!scope.mobileMenuOpen)
        scope.mobileMenuOpen = bool ? bool : !scope.mobileMenuOpen
        if (scope.mobileMenuOpen) {
          setViewportScroll.disable()
        } else {
          setViewportScroll.enable()
        }
      }

      $rootScope.$on('clrTabWaypoint:setActiveTab', function (event, anchorName) {
        setActiveTab(anchorName)
      })

      $timeout(function () {
        initTabs()
        initMobileTabs()
      })

    }
  }
}
