'use strict'

module.exports = function($filter, promoCodeStore, claimStore, kitPriceService, testTypes, sku) {
  'ngInject'

  function Checkout() {
    this.shippingAddress = null
    this._quantity = 1
    this.sku = sku[testTypes.hereditary30]
  }

  Checkout.subclass = function() {
    function klass() {
      Checkout.call(this)
    }
    klass.prototype = Object.create(Checkout.prototype)
    klass.prototype.super = Checkout
    return klass
  }

  Checkout.prototype.getMaxKitCount = function() {
    if (this.physicianOrder && this.physicianOrder.test_requested == testTypes.breastOvarian19) {
      return 1
    }
    return 6
  }

  Checkout.prototype.getQuantity = function() {
    if (this._quantity > this.getMaxKitCount()) {
      this._quantity = this.getMaxKitCount()
    }
    return this._quantity
  }

  Checkout.prototype.incrementKit = function() {
    if (this._quantity < this.getMaxKitCount()) {
      this._quantity += 1
    }
  }

  Checkout.prototype.decrementKit = function() {
    if (this._quantity > 1) {
      this._quantity -= 1
    }
  }

  Checkout.prototype.getShippingCountry = function() {
    if (this.shippingAddress && this.shippingAddress.country) {
      return this.shippingAddress.country
    }
    return "US" // default country
  }

  // price of shipping for one kit
  Checkout.prototype.getUnitShippingPriceCents = function() {
    if (this.isSampleReuse) {
      return 0;
    }
    return $filter('shippingChargeCents')(this.getShippingCountry())
  }

  // total shipping cost for this purchase
  Checkout.prototype.getShippingPriceDollars = function() {
    return this.getUnitShippingPriceCents() * this.getQuantity() / 100
  }

  Checkout.prototype.getKitPriceDollarsAfterModifiers = function() {
    // No modifiers get applied with Checkout. KitCheckout implements this.
    return kitPriceService.getAdvertisedKitPriceDollars(this.sku)
  }

  // total kit cost for this purchase
  Checkout.prototype.getKitSubtotalDollars = function() {
    return this.getKitPriceDollarsAfterModifiers() * this.getQuantity()
  }

  // total test cost for this purchase (a part of breakdown of total kit cost)
  Checkout.prototype.getTestSubtotalDollars = function() {
    return this.getKitSubtotalDollars() - this.getProviderServiceSubtotalDollars()
  }

  // total provider service cost for this purchase (a part of breakdown of total kit cost)
  Checkout.prototype.getProviderServiceSubtotalDollars = function() {
    if (this.existingPhysicianOrder) {
      return 0;
    }
    return kitPriceService.getProviderServicePriceDollars(this.sku) * this.getQuantity()
  }

  // kit subtotal
  Checkout.prototype.getItemizedKitPriceDollars = function() {
    if (this.getQuantity() == 0) {
      return 0
    }
    const total = 0
    const kitPrice = this.getKitPriceDollarsAfterModifiers()
    const unitShippingPriceDollars = this.getUnitShippingPriceCents() / 100
    const itemizedKitPrices = []
    for (let i = 0; i < this.getQuantity(); i++) {
      const item = {
        kitPriceSubtotalDollars: kitPrice,
        appliedDiscountDollars: 0,
        unitShippingPriceDollars: unitShippingPriceDollars,
        totalDollars: 0
      }
      item.totalDollars = item.kitPriceSubtotalDollars + unitShippingPriceDollars
      itemizedKitPrices.push(item)
    }

    // 2. discounts
    const sku = this.sku
    const claim = claimStore.claim
    if ((claim && claim.is_redeemable)) {
      // 2a. claim
      let numRedemptions = this.getQuantity();
      if (claim && claim.remaining_redemptions_count !== null) {
        numRedemptions = Math.min(numRedemptions, claim.remaining_redemptions_count);
      }
      itemizedKitPrices.reverse().forEach((item, index) => {
        let skuDiscount
        const isForSelf = this.purchase && this.purchase.kit_for_self
        if (numRedemptions != 0) {
          skuDiscount = claimStore.getNonUpgradeSkuDiscount(sku)
          numRedemptions -= 1;
        }

        if (!skuDiscount) {
          return;
        }

        const shipping = Math.max(
          0,
          item.unitShippingPriceDollars - skuDiscount.shipping_subsidy_cents / 100.0
        );

        const previousTotal = item.totalDollars;

        item.kitPriceSubtotalDollars = skuDiscount.copay_cents / 100.0;
        item.totalDollars = item.kitPriceSubtotalDollars + shipping;
        item.appliedDiscountDollars = previousTotal - item.totalDollars;

      });
    } else {
      // 2b. promo code
      const promo_code = promoCodeStore.promotionalCode
      const remaining_redemptions = promo_code.remaining_redemptions_count
      let numRedemptions = this.getQuantity()
      if (!promo_code.can_redeem) {
        numRedemptions = 0
      } else if (remaining_redemptions !== null) {
        numRedemptions = Math.min(numRedemptions, remaining_redemptions)
      }

      itemizedKitPrices.reverse()
        .map(function(item) {
          if (numRedemptions == 0) {
            return
          }
          // family testing code does not stack
          if (promo_code.is_family_testing_code) {
            item.kitPriceSubtotalDollars = kitPrice
            item.totalDollars = kitPrice + item.unitShippingPriceDollars
          }
          const maxPromoCodeDiscountDollars = kitPriceService.getPromoCodeDiscountDollars(sku, item.kitPriceSubtotalDollars)
          const appliedPromoCodeDollars = Math.min(item.totalDollars, maxPromoCodeDiscountDollars)
          numRedemptions -= 1
          item.appliedDiscountDollars = appliedPromoCodeDollars
          item.totalDollars -= appliedPromoCodeDollars
        })
    }

    return itemizedKitPrices
  }

  // kit subtotal, less promo price discount if any (does not include promo code discount)
  Checkout.prototype.getKitPriceDollars = function() {
    return this.getItemizedKitPriceDollars().reduce(function(sum, item) {
      return sum + item.kitPriceSubtotalDollars
    }, 0)
  }

  // shipping + kit subtotal - promo price discount (does not include promo code discount)
  Checkout.prototype.getSubtotalPriceDollars = function() {
    return this.getKitPriceDollars() + this.getShippingPriceDollars()
  },

  // applied promo code amount
  Checkout.prototype.getAppliedDiscountDollars = function() {
    return this.getItemizedKitPriceDollars().reduce(function(sum, item) {
      return sum + item.appliedDiscountDollars
    }, 0)
  },

  // shipping + kit subtotal - promo price discount - promo code
  Checkout.prototype.getTotalPriceDollars = function() {
    const totalPriceDollars = this.getItemizedKitPriceDollars().reduce(function(sum, item) {
      return sum + item.totalDollars
    }, 0)
    return totalPriceDollars
  }

  Checkout.prototype.isPaymentRequired = function() {
    return this.getTotalPriceDollars() > 0.01 // small delta for float rounding errors
  }

  return Checkout
}
