'use strict'

module.exports = function($filter, geographicalArea, NotYetAvailableModal, NoPOBoxesModal, topCountries, allCountries, $timeout, $translate, $window) {
  "ngInject"

  return {
    restrict: 'AE',
    require: '^form',
    scope: {
      address: '=',
      required: '=',
      compact: '=',
      focus: '=',
      checkout: '=?',
      autocomplete: '=?',
      fixedCountry: '=?',
      flexStyle: '=?',
      showPhoneNumber: '=?',
      displayTooltip: '=?',
      checkBirthday: '=?',
    },
    templateUrl: '/users/address_form.html',
    link: function(scope, element, attrs, formCtrl) {
      // Toggle for new flex form styling, make this default once we switch over all form design
      if (angular.isUndefined(scope.flexStyle)) {
        scope.flexStyle = false
      }

      if (angular.isUndefined(scope.showPhoneNumber)) {
        scope.showPhoneNumber = true
      }

      if (angular.isUndefined(scope.displayTooltip)) {
        scope.displayTooltip = true
      }

      if (!scope.address.country) {
        scope.address.country = 'US'
      }

      scope.deliveryHint = function() {
        // Regenerate the translated hint when the page is re-rendered
        if (!angular.isUndefined(scope.checkout)) {
          return $translate.instant(
            'SHIPPING.DELIVERY_HINT_INTL',
            {
              pluralizedKit : scope.checkout.getQuantity() == 1 ? 'kit' : 'kits',
              carrier : $filter('outboundShipperName')(scope.checkout.shippingAddress.country),
              service : $filter('outboundServiceName')(scope.checkout.shippingAddress.country),
              days : $filter('daysToDeliver')(scope.checkout.shippingAddress.country),
              shippingCost : scope.checkout.getShippingPriceDollars().toFixed(2)
            }
          )
        }
      }

      scope.form = formCtrl

      scope.geographicalArea = geographicalArea
      scope.NotYetAvailableModal = NotYetAvailableModal

      const getAreaInfo = function(country) {
        return {
          shippable: $filter('shippable')(country),
          outboundShipperName: $filter('outboundShipperName')(country),
          jurisdictionName: $filter('name')(country)
        }
      }

      // ensure that we clear out unused fields correctly
      const fields = {city: 'city', state: 'state', postalCode: 'postal_code'}
      let autocomplete;
      function initAutocomplete() {
        if (!$window.google || !$window.google.maps || !$window.google.maps.places || !$window.google.maps.places.Autocomplete) {
          return
        }
        const elem = element.find('input[name=line1]')[0];
        const options = { types: ['address'] }

        if (scope.fixedCountry) {
          options.componentRestrictions = { country: 'US' }
        }

        autocomplete = new google.maps.places.Autocomplete(elem, options)
        autocomplete.addListener('place_changed', fillInAddress);
      }

      function getAddressParts(place) {
        const addressParts = {};
        let i;
        let component
        for (i = 0; i < place.address_components.length; i++) {
          component = place.address_components[i]
          addressParts[component.types[0]] = { long: component.long_name, short: component.short_name }
        }
        return addressParts
      }

      function fillInAddress() {
        const place = autocomplete.getPlace();
        const parts = getAddressParts(place);
        const address = {};
        address.line1 = parts.street_number ? parts.street_number.short + ' ' + parts.route.long : parts.route.long
        address.country = parts.country.short
        $filter('addressFieldOrder')(parts.country.short).map(function (fieldRow) {
          fieldRow.map(function (field) {
            if (field === 'city') {
              address.city = parts.locality.long
            } else if (field === 'state') {
              address.state = parts.administrative_area_level_1.short
            } else if (field === 'postalCode') {
              address.postal_code = parts.postal_code ? parts.postal_code.short : ''
            }
          })
        })

        scope.$apply(function() {
          const fields = ['country', 'line1', 'line2', 'city', 'state', 'postal_code'];
          for (let i = 0; i < fields.length; i++) {
            if (address[fields[i]]) {
              scope.address[fields[i]] = address[fields[i]]
            } else {
              delete scope.address[fields[i]]
            }
          }
        })
      }

      // We want to initialize autocomplete on the next digest cycle, because
      // the `ng-if` in the template needs to process first.
      $timeout(function() {
        if (scope.autocomplete) {
          initAutocomplete();
        }
      })

      scope.$watch('address.country', function(newCountry, oldCountry) {
        // SUPPORT-2072: angular-ui-mask doesn't reset their validators/bindings when postalCode
        // changes due to the country value, so reset the mask validity manually.
        if (scope.form.postalCode) {
          $timeout(() => {
            scope.form.postalCode.$setValidity('mask', null)
          }, 250)
        }

        let countryFields = []

        // flatten countryFields into a 1-d array of fields
        $filter('addressFieldOrder')(newCountry).map(function(fieldRow) {
          countryFields = countryFields.concat(fieldRow)
        })

        Object.keys(fields).map(function(k) {
          if (countryFields.indexOf(k) == -1) {
            scope.address[fields[k]] = ""
          }
        })

        const info = getAreaInfo(newCountry)
        if (info.shippable &&
            info.outboundShipperName != 'USPS' &&
            ($filter('poBoxPresent')(scope.address.line1) ||
             $filter('poBoxPresent')(scope.address.line2))) {
          NoPOBoxesModal.open(info.outboundShipperName, info.jurisdictionName)
        }
      })

      // Ensure no PO Boxes for non-USPS addresses
      scope.$watch('address.line1', function(newValue) {
        const info = getAreaInfo(scope.address.country)
        if (info.shippable &&
            info.outboundShipperName != 'USPS' &&
            $filter('poBoxPresent')(scope.address.line1)) {
          NoPOBoxesModal.open(info.outboundShipperName, info.jurisdictionName)
        }
      })

      // Ensure no PO Boxes for non-USPS addresses
      scope.$watch('address.line2', function(newValue) {
        const info = getAreaInfo(scope.address.country)
        if (info.shippable &&
            info.outboundShipperName != 'USPS' &&
            $filter('poBoxPresent')(scope.address.line2)) {
          NoPOBoxesModal.open(info.outboundShipperName, info.jurisdictionName)
        }
      })
    }
  }
}
