/**
 * @prettier
 */

module.exports = function(testTypes, waffle) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/reportValidity.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      const whitePaperMapping = {};
      whitePaperMapping[testTypes.hereditary30] = '/hereditarycancer';
      whitePaperMapping[testTypes.breastOvarian19] = '/hereditarycancer';
      whitePaperMapping[testTypes.wisdom9] = '/hereditarycancer';
      // TODO: [CONSUMER-5277] Uncomment this once FH white paper is available.
      whitePaperMapping[testTypes.fh3] = ''; // '/hereditaryhighcholesterol'
      scope.whitePaper = whitePaperMapping[scope.reportContent.template.test_type];
    },
  };
};
