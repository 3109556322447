'use strict'

module.exports = function($http, $translate, auth, ngToast, claimStore, promoCodeStore, sku) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/kit_orders/directives/campaign_token_form.html',
    scope: {
      requisitionNumber: '=?'
    },
    link: function(scope, elem, attr, ctlr) {
      scope.auth = auth

      claimStore.getClaim().then(claim => {
        scope.claim = claim
      })

      // The page_size here allows for more than the default 100. We have >= 109 results,
      // and this will continue to grow.
      // We can keep pulling more content, but eventually the UI dropdown will start failing.
      // In addition, the API request is becoming slower. (Currently takes 280 msec)
      // FIXME: Refactor to use typeahead in UI, along with async lookup.
      $http.get('/api/v1/campaign_tokens?page_size=1000').then(res => {
        scope.campaignTokens = res.data.results
      })

      scope.toggle = () => {
        scope.showInput = !scope.showInput
      }

      scope.removeToken = () => {
        scope.showInput = false
        scope.claim = null
        claimStore.clearClaim()
      }

      scope.applyCampaignToken = () => {
        $http.patch('/api/v1/benefits/get_or_create_claim_from_campaign', {
          identifier: scope.requisitionNumber,
          campaign_token: scope.data.campaign_token
        }).then(res => {
          scope.showInput = false
          scope.claim = res.data
          claimStore.setClaim(res.data)
        })
      }
    }
  }
}
