'use strict'

module.exports = angular.module('SchedulizerService', [])
  /**
   * `chooseAppointment` appears to be used in Clinical support tools.
   */
  .factory('chooseAppointment', ($location, upcomingAppointment) => {
    return (orderNumber) => {
      return upcomingAppointment.get(orderNumber).then(appointment => {
        $location.path(`/react/schedule`).search({order_number: orderNumber})
      })
    }
  })

  /**
   * `schedulizerTextOptions` appears to be used in pending_gc_session. See also `appointmentTextOptions`
   */
  .factory('schedulizerTextOptions', (appointmentTypes, PgxTutorial) => {
    return (appointmentType) => {

      const defaultOptions = {
        title: 'Schedule Genetic Counseling Appointment - Color',
        appointmentName: 'genetic counseling appointment',
        specialistName: 'genetic counselor',
        specialistNameLong: 'board-certified genetic counselor',
        whatHappensDescription: 'Your genetic counselor may start by covering some of the the basics about genetic testing, as well as reviewing your personal results and answering any questions you may have. They may also ask clarifying questions about the personal and family health history that you provided.',
        howItWorksLink: 'https://support.color.com/genetic-counseling'
      }

      const pgxOptions = {
        title: 'Schedule Genetic Counselor Appointment - Color',
        appointmentName: 'genetic counseling appointment',
        specialistName: 'genetic counselor',
        specialistNameLong: 'genetic counselor',
        whatHappensDescription: 'Your pharmacist may start by covering some of the basics about how your body breaks down (metabolizes) certain medications, as well as reviewing your personal results and answering any questions you may have. They may also ask clarifying questions about your medication list and the personal and family history history that you provided.',
        howItWorksCta: PgxTutorial.showTutorial
      }

      switch (appointmentType) {
        case appointmentTypes.pgx:
          return pgxOptions
        default:
          return defaultOptions
      }
    }
  })
