'use strict'

module.exports = angular.module('filesFilters', ['clrResource', 'clrConstant', 'filesServices'])
  /**
   * @ngdoc filter
   * @name makeS3Url
   * @memberof filesFilters
   *
   * @description
   * Generates a url to an UploadFile represented by the given S3 key and file
   * type.
   */
  .filter('makeS3Url', function(urlBuilder) {
    return function(key, fileType) {
      return urlBuilder.buildUrl('/api/v1/uploaded_file', {
        key: key,
        file_type: fileType
      })
    }
  })

  /**
   * @ngdoc filter
   * @name makeGcNotesUrl
   * @memberof filesFilters
   *
   * @description
   * Generates a url to an UploadFile containing counseling notes represented
   * by the given S3 key.
   */
  .filter('makeGcNotesUrl', function(makeS3UrlFilter, uploadFileTypes) {
    return function(key) {
      return makeS3UrlFilter(key, uploadFileTypes.geneticCounselingNote)
    }
  })

  /**
   * @ngdoc filter
   * @name makePhysicianOrderFilesUrl
   * @memberof filesFilters
   *
   * @description
   * Generates a url to an UploadFile containing physician order files
   * represented by the given S3 key.
   */
  .filter('makePhysicianOrderFilesUrl', function(makeS3UrlFilter) {
    return function(file) {
      return makeS3UrlFilter(file.s3_key, file.file_type)
    }
  })

  .filter('uploadFileType', function(uploadFileTypeLabels) {
    return function(fileType) {
      const label = uploadFileTypeLabels[fileType]
      if (label) {
        return label + ': '
      }
      return label
    }
  })
