module.exports = function placeInsuranceDetails(InsurancePayers, InsuranceEligibilitySubmission) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      onSubmit: '=',
      order: '='
    },
    require: '^form',
    templateUrl: '/ordering_physicians/place_insurance_details.html',
    link: function(scope, element, attrs) {
      // UI

      scope.submitting = false
      scope.isUsingCashPay = undefined

      // Helpers

      scope.payment = scope.order.payment

      // Data models

      scope.order.ui.eligibility = scope.order.ui.eligibility || {
        payer: undefined,
        insurance_id: undefined,
        relationship_to_policy_holder: 'self',
        submitted: false,
        submitting: false
      }

      scope.eligibility = scope.order.ui.eligibility

      scope.$watchGroup(['eligibility.payer', 'eligibility.insurance_id'], function() {
        scope.isUsingCashPay = undefined
      })

      scope.$watch('eligibility.relationship_to_policy_holder', function(newValue) {
        if (newValue == 'self') {
          delete scope.eligibility.policy_holder
        }
      })

      scope.checkEligibility = function() {
        if (!scope.placeInsuranceDetailsForm.validate()) {
          return
        }
        scope.eligibility.submitting = true
        scope.isUsingCashPay = undefined

        const payload = angular.copy(scope.eligibility)
        payload.patient_info = {
          first_name: scope.order.patient_profile.first_name,
          last_name: scope.order.patient_profile.last_name,
          birthday: scope.order.patient_profile.birthday
        }

        delete scope.order.payment.eligibility_submission

        new InsuranceEligibilitySubmission(payload).$save()
          .then(function(eligibilitySubmission) {
            scope.payment.eligibility_submission = eligibilitySubmission
            const attempted_submission = scope.order.attempted_insurance_submission
            attempted_submission.eligibility_submission = { id: eligibilitySubmission.id }
            attempted_submission.patient_info = {
              first_name: scope.order.patient_profile.first_name,
              last_name: scope.order.patient_profile.last_name,
              birthday: scope.order.patient_profile.birthday,
              gender: scope.order.patient_profile.gender,
            }
            attempted_submission.payer = scope.order.payment.eligibility_submission.payer
            delete attempted_submission.id // Wipe ID if exists since the submission has now changed

            scope.onSubmit()
          })
          .finally(function() {
            scope.eligibility.submitting = false
            scope.eligibility.submitted = true
            scope.eligibility_state = scope.payment.eligibility_submission.eligibility_state
          })
      }

      InsurancePayers.get_as_array().$promise.then(function(res) {
        scope.insurance_companies = res
      })
    }
  }
}
