'use strict'

function AddPatientPaymentInfoModalController(
  $scope,
  $modalInstance,
  order,
  auth,
  project,
  physicianOrderCheckout,
  promoCodeStore,
  ApplicationType,
  PhysicianOrderPayment
) {
  'ngInject'

  $scope.order = order
  $scope.order.payment = $scope.order.payment || new PhysicianOrderPayment()

  $scope.userProvider = project.application === ApplicationType.CLINICAL
    ? undefined
    : auth.currentUser.provider_profile
  if ($scope.userProvider && $scope.userProvider.settings.payment_code) {
    promoCodeStore.updateCode($scope.userProvider.settings.payment_code, true)
  } else if ($scope.order.promocode_for_payment) {
    promoCodeStore.updateCode($scope.order.promocode_for_payment, true)
  }

  $scope.checkout = new physicianOrderCheckout($scope.order, $scope.userProvider, $scope.order.test_requested)

  $scope.onSubmit = function() {
    return $scope.checkout.reviewPayment()
      .then(function() {
        $modalInstance.close()
      })
  }
}

module.exports = function addPatientPaymentInfoModal(
  $modal
) {
  'ngInject'

  return {
    open: function(order) {
      return $modal.open({
        templateUrl: '/ordering_physicians/modals/add_patient_payment_info.html',
        controller: AddPatientPaymentInfoModalController,
        size: 'lg',
        resolve: {
          order: function() {
            return order
          }
        }
      })
    }
  }
}
