/**
 * @prettier
 */

module.exports = function reportLogs() {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      logs: '=',
    },
    templateUrl: '/results/report_event_logs.html',
  };
};
