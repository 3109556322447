/**
 * @prettier
 */

module.exports = function reportRevision() {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      revision: '=',
    },
    templateUrl: '/results/rerelease.html',
  };
};
