'use strict'

module.exports = function($filter) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/_questions.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.questions = []
      scope.title = 'RESULT.QUESTIONS.GENERAL_TITLE'
      if (scope.reportContent.is_negative) {
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.GENERAL.NEGATIVE.T1',
          p: 'FH_REPORT.QUESTIONS.GENERAL.NEGATIVE.P1'
        })
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.GENERAL.NEGATIVE.T2',
          p: 'FH_REPORT.QUESTIONS.GENERAL.NEGATIVE.P2',
          withGC: true
        })
        if ($filter('mutationsHaveVus')(scope.reportContent.mutations)) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.GENERAL.VUS.T1',
            p: 'FH_REPORT.QUESTIONS.GENERAL.VUS.P1',
            references: [283]
          })
        }
      } else if (scope.reportContent.is_positive) {
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.GENERAL.POSITIVE.T1',
          p: 'FH_REPORT.QUESTIONS.GENERAL.POSITIVE.P1'
        })
        if (scope.reportContent.mutations.some(function(m) { return m.classification.toLowerCase() == 'pathogenic' })) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.GENERAL.P.T1',
            p: 'FH_REPORT.QUESTIONS.GENERAL.P.P1'
          })
        }
        if (scope.reportContent.mutations.some(function(m) { return m.classification.toLowerCase() == 'likely pathogenic' })) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.GENERAL.LP.T1',
            p: 'FH_REPORT.QUESTIONS.GENERAL.LP.P1'
          })
        }
        if ($filter('mutationsHaveVus')(scope.reportContent.mutations)) {
          scope.questions.push({
            t: 'FH_REPORT.QUESTIONS.GENERAL.VUS.T1',
            p: 'FH_REPORT.QUESTIONS.GENERAL.VUS.P1',
            references: [283]
          })
        }
      }
      scope.questions.push({
        t: 'FH_REPORT.QUESTIONS.GENERAL.ALL.T1',
        p: 'FH_REPORT.QUESTIONS.GENERAL.ALL.P1'
      })
      scope.questions.push({
        t: 'FH_REPORT.QUESTIONS.GENERAL.ALL.T2',
        p: 'FH_REPORT.QUESTIONS.GENERAL.ALL.P2'
      })
      scope.questions.push({
        t: 'FH_REPORT.QUESTIONS.GENERAL.ALL.T3',
        p: 'FH_REPORT.QUESTIONS.GENERAL.ALL.P3'
      })
    }
  }
}
