module.exports = ($modal) => {
  'ngInject'

  return {
    open: () => {
      return $modal.open({
        templateUrl: 'kit_orders/modals/not_acwellness_member_modal.html',
        size: 'md',
      })
    }
  }
}
