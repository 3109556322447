"use strict"

/**
 * marked compiler that can include directives and scope compilation
 */
module.exports = function($compile, marked) {
  "ngInject"

  return {
    restrict: 'A',
    scope: true,
    link: function(scope, elem, attrs) {
      const template = marked(attrs.clrMarked)
      const content = $compile(template)(scope)
      if (attrs.linksInNewWindow) {
        content.find('a').attr("target", "_blank");
      }
      elem.append(content)
    }
  }
}
