/**
 * @class AuthRouteFilter
 *
 * @description
 * Routes users depending on their authentication/authorization status.
 *
 * @param {Object} $location - A location service.
 * @param {Object} auth - An authentication service.
 * @param {string} defaultLoginPath - A path to a login page.
 * @param {string} loggedInHomePath - A path to a home page for logged in user.
 * @param {string} emailConfirmationRequiredPath
 * A path to a page for notifying user that they need to confirm their email.
 * @param {string/function} permissionDeniedPath
 * If string, it is a path to a page for notifying user that they are not authorized to access.
 * If function, it returns a path given auth object.
 * @param {string} sexSelectorPath
 * A path to a page for selecting user sex.
 * @param {string} benefitsRoutingAuthorizationPath
 * A path to a page for consenting to Employer benefits routing.
 * @param {string} healthHistoryIncompletePath
 * A path to a page that user gets redirected to
 * if health history is not complete and is required.
 * @param {string} providerLoggedInHomePath - A path to a home page for logged in providers.
 *
 * @returns {boolean} true if redirected; otherwise false.
 */
function AuthRouteFilter($window, $location, auth, redirect, associatedSample, textModal,
  defaultLoginPath, loggedInHomePath,
  emailConfirmationRequiredPath, permissionDeniedPath,
  sexSelectorPath, healthHistoryIncompletePath,
  providerLoggedInHomePath, benefitsRoutingAuthorizationPath, permissionTypes, hasCompletedRoutingAuthorization) {
  function _redirect(path) {
    let next = path
    if (angular.isFunction(path)) {
      next = path(auth)
    }
    $location.path(next)
    $location.replace()
  }

  /**
   * @method apply
   * @memberof AuthRouteFilter#
   *
   * @description
   * Applies an auth route filter. The default configuration is as follow:
   * ```js
   * {
   *    // Redirects to loginPath if user is not logged in.
   *    // Otherwise, redirects to loggedInHomePath.
   *    requireLogin: true
   *    // Redirects to loggedInHomePath if user is logged in.
   *  , requireAnonymous: false
   *    // Redirects to emailConfirmationRequiredPath if user has confirmed email.
   *  , requireEmailConfirmation: requireLogin
   *    // Redirects to loggedInHomePath if user has confirmed email.
   *  , redirectEmailConfirmed: requireLogin
   *    // Redirects to sexSelectorPath if user has no sex selected.
   *  , requireSex: false
   *    // Redirects to healthHistoryIncompletePath if user has not completed
   *    // health history.
   *  , requireBenefitsRoutingAuthorization: false
        // Redirects to routingConsentPath if user has not provided consent
        // for routing.
   *  , requireHealthHistoryCompleted: false
   *    // Redirects to permissionDeniedPath if user is not sample reviewer.
   *  , requireSampleReviewer: false
   *    // Redirects to permissionDeniedPath if user is not variant reviewer.
   *  , requireVariantReviewer: false
   *    // Redirects to permissionDeniedPath if user is not variant reviewer manager.
   *  , requireVariantReviewerManager: false
   *    // Redirects to permissionDeniedPath if user is not ordering physician.
   *  , requireOrderingPhysician: false
   *    // Redirects to permissionDeniedPath if user is not genetic counselor.
   *  , requireGeneticCounselor: false
   *    // Redirects to permissionDeniedPath if user is not pathologist.
   *  , requirePathologist: false
   *    // Redirects to permissionDeniedPath if user is not genetics board member.
   *  , requireLabStaff: false
   *    // Redirects to permissionDeniedPath if user is not part of the lab staff.
   *  , requireLabStaff: false
   *    // Redirects to permissionDeniedPath if user is not color employee
   *  , requireColorEmployee: false
   *    // Redirects to permissionDeniedPath if user is not support staff.
   *  , requireSupportStaff: false
   *    // Redirects to permissionDeniedPath if user is not a provider.
   *  , requireProvider: false
   *    // Redirects to permissionDeniedPath if user is not support admin.
   *  , requireSupportAdmin: false
   *    // Redirects to loggedInHomePath if there is no associated sample.
   *  , requireExternalClassificationReviewer: false
   *    // Redirects to permissionDeniedPath if user is not external classification reviewer.
   *  , requireAssociatedSample: false
   * }
   * ```
   *
   * @param {Object} config
   * @param {string} currentPath
   * Apparently unused, intended as current path used to set `next` query param in case of redirect.
   */
  this.apply = function(config, currentPath) {
    const defaults = {
      requireLogin: true,
      loginPath: defaultLoginPath,
      requireAnonymous: false,
      requireEmailConfirmation: void 0,
      redirectEmailConfirmed: false,
      requireSex: false,
      requireBenefitsRoutingAuthorization: false,
      requireHealthHistoryCompleted: false,
      requireSampleReviewer: false,
      requireVariantReviewer: false,
      requireVariantReviewerManager: false,
      requireOrderingPhysician: false,
      requireGeneticCounselor: false,
      requirePathologist: false,
      requireGeneticsBoardMember: false,
      requireLabStaff: false,
      requireColorEmployee: false,
      requireSupportStaff: false,
      requireProvider: false,
      requireSupportAdmin: false,
      requireExternalClassificationReviewer: false,
      requireAssociatedSample: false,
      requiredPermissions: []
    }

    config = config == void 0 ? {} : config

    config = angular.extend({}, defaults, config)

    if (angular.isUndefined(config.requireEmailConfirmation)) {
      config.requireEmailConfirmation = config.requireLogin
    }

    // Marks this session as ads traffic.
    if (config.isAds) {
      auth.isAds = true
    }

    // User is authorized if any of the following is satisfied.
    const authorizations = {
      requireSampleReviewer: 'is_sample_reviewer',
      requireVariantReviewer: 'is_variant_reviewer',
      requireVariantReviewerManager: 'is_variant_reviewer_manager',
      requireOrderingPhysician: 'is_ordering_physician',
      requireGeneticCounselor: 'is_genetic_counselor',
      requirePathologist: 'is_pathologist',
      requireGeneticsBoardMember: 'is_genetics_board_member',
      requireLabStaff: 'is_lab_staff',
      requireColorEmployee: 'is_color_employee',
      requireSupportStaff: 'is_support_staff',
      requireProvider: 'is_provider',
      requireSupportAdmin: 'is_support_admin',
      requireExternalClassificationReviewer: 'is_external_classification_reviewer'
    }

    const showProviderView = auth.currentUser.is_provider;
    if (config.requireLogin && !auth.isLoggedIn) {
      $window.$('.body-wrapper, .footer').addClass('u--force-hide')
      let loginPath = config.loginPath;
      currentPath = $location.path() + $window.location.search
      if (currentPath != loginPath && $window.location.pathname != loginPath) {
        loginPath += '?next=' + encodeURIComponent(currentPath)
      }
      $window.location.href = loginPath
      return true
    } else if (config.requireAnonymous && auth.isLoggedIn) {
      if (config.showLogoutModal) {
        const title = "Do you want to sign out?";
        const text = "You are currently signed in as " + auth.currentUser.first_name + " " + auth.currentUser.last_name + ". Would you like to sign out of this account?";
        const buttonText = "Sign Out";
        const cancelButtonText = "Return to Account"
        textModal.open(title, text, buttonText, "", false, cancelButtonText).result
          .then(function() {
            auth.logout()
              .then(function() {
                $window.location.reload()
              })
          })
          .catch(function() {
            redirect(showProviderView ? providerLoggedInHomePath : loggedInHomePath)
            $location.replace()
          })
        return true
      } else {
        redirect(showProviderView ? providerLoggedInHomePath : loggedInHomePath)
        $location.replace()
        return true
      }
    } else if (config.requireLogin && auth.isLoggedIn && !showProviderView && auth.currentUser.hasOnlyCovidTests() && auth.currentUser.is_proxy) {
      // Proxy users are not full users and thus cannot activate a genetics test or use other Angular routes.
      // Just bring them back to their dashboard.
      // Full COVID users, on the other hand *can* use genetics and redirecting them back to the React portal is not desired
      redirect('/react/covid-test');
      return true;
    } else if (config.redirectEmailConfirmed && auth.isLoggedIn && auth.currentUser.is_email_confirmed) {
      redirect(showProviderView ? providerLoggedInHomePath : loggedInHomePath)
      $location.replace()
      return true
    } else if (config.requireBenefitsRoutingAuthorization && auth.isLoggedIn && auth.currentUser.routing_organization && !hasCompletedRoutingAuthorization(auth.currentUser)) {
      const oldPath = $location.path()
      _redirect(benefitsRoutingAuthorizationPath)
      $location.search('next', oldPath)
      return true
    } else if (config.requireSex && auth.isLoggedIn && !auth.currentUser.gender) {
      $window.location.href = sexSelectorPath;
      return true
    } else if (config.requireHealthHistoryCompleted && auth.isLoggedIn && !auth.currentUser.has_health_history_completed) {
      _redirect(healthHistoryIncompletePath)
      return true
    } else if (config.requireAssociatedSample && associatedSample && !associatedSample.get()) {
      _redirect(loggedInHomePath)
      return true
    } else {
      if (config.requireEmailConfirmation && !auth.currentUser.is_email_confirmed) {
        _redirect(emailConfirmationRequiredPath)
        return true
      } else {
        let authorized = undefined
        for (const key in authorizations) {
          const value = authorizations[key]
          if (config[key]) {
            if (angular.isUndefined(authorized)) {
              authorized = false
            }
            authorized = authorized || auth.currentUser[value]
          }
        }
        if (config.requiredPermissions.length > 0) {
          authorized = authorized || auth.currentUser.hasAllPermissions(config.requiredPermissions)
        }
        if (!angular.isUndefined(authorized) && !authorized) {
          _redirect(permissionDeniedPath)
          return true
        }
      }
    }
    return false
  }
}

/**
  * @class Auth
  *
  * @description
  * Provides user authentication service.
  *
  * @param {Object} resolvedPromise
  * @param {Object} $http - A http service.
  * @param {fn} $timeout - Angular's wrapper for window.setTimeout
  * @param {authService} authService - authService from http-auth-interceptor
  * @param {Account} Account - A Account resource class.
  * @param {string} sessionAPIEndPoint - An end point for session.
  */
function Auth(resolvedPromise, $rootScope, $http, $window, authService, Account, sessionAPIEndPoint, skipAuthSync) {
  /**
   * @member {boolean} isLoggedIn - Indicates if user is logged in.
   * @memberof Auth#
   */
  this.isLoggedIn = void 0
  /**
   * @member {boolean} isSynced
   * Indicates if session and account are synced with server.
   * @default false
   * @memberof Auth#
   */
  this.isSynced = false
  /**
   * @member {Object} currentUser - An instance of `Account` resource.
   * @memberof Auth#
   */
  this.currentUser = new Account()
  /**
   * @member {boolean} hasSkippedSync
   * Indicates if we skipped sync at bootstrap.
   * @default false
   * @memberof Auth#
   */
  this.hasSkippedSync = false
  /**
   * @member {boolean} isAds
   * Indicates if this session visited ads landing page.
   * @default false
   * @memberof Auth#
   */
  this.isAds = false

  /**
   * @method sync
   * @memberof Auth#
   *
   * @description
   * Syncs session and account with server if it has not synced yet.
   *
   * @param {boolean} [force] - Forces sync if this value is true.
   */
  this.sync = function(force) {
    if (this.isSynced) {
      if (!force) {
        return resolvedPromise(this)
      }
    } else if (this.currentSync) {
      return this.currentSync
    } else if (skipAuthSync && !this.hasSkippedSync) {
      // If user is known to not authenticated at the load time,
      // we want to skip trying to authenticate the user to
      // avoid redundant round trip with our server.
      this.isLoggedIn = false
      this.currentUser = new Account()
      this.isSynced = true
      this.hasSkippedSync = true
      $rootScope.$broadcast('auth:synced', this.currentUser.id)
      return resolvedPromise(this)
    }

    this.currentSync = $http.get(sessionAPIEndPoint, { ignoreAuthModule: true })
      .then(angular.bind(this, function() {
        this.isLoggedIn = true
        return this.currentUser.$get()
      }))
      .catch(angular.bind(this, function(res) {
        this.isLoggedIn = false
        this.currentUser = new Account()
        return res
      }))
      .finally(angular.bind(this, function() {
        $rootScope.$broadcast('auth:synced', this.currentUser.id)
        this.isSynced = true
        this.currentSync = null
        return this
      }))

    return this.currentSync
  }

  /**
   * @method login
   * @memberof Auth#
   *
   * @description
   * Logs user in.
   *
   * @param {string} email
   * @param {string} password
   */
  this.login = function(email, password) {
    const params = {
      email: email,
      password: password,
      format: 'json'
    };
    return $http.post(sessionAPIEndPoint, params, { ignoreAuthModule: true })
      .then(angular.bind(this, function(res) {
        if (res.data && res.data.redirectUri) {
          // In order to log in, redirect the user to use MFA
          $window.location.assign(res.data.redirectUri);
          throw new Error('Redirected user to log in with MFA');
        }
        this.isLoggedIn = true;
        authService.loginConfirmed();
        return this.currentUser.$get();
      }))
      .then(angular.bind(this, function(res) {
        $rootScope.$broadcast('auth:loggedIn', this.currentUser.id);
        return res;
      }));
  }

  /**
   * @method logout
   * @memberof Auth#
   *
   * @description
   * Logs user out.
   */
  this.logout = function() {
    return $http["delete"](sessionAPIEndPoint)
      .then(angular.bind(this, function(res) {
        this.isLoggedIn = false;
        this.currentUser = new Account();
        $rootScope.$broadcast('auth:loggedOut');
      }))
  }
}

/**
 * @ngdoc module
 * @name clrAuth
 *
 * @description
 * The `clrAuth` module provides tools for checking user and authentication status.
 */
module.exports = angular.module('clrAuth', ['ngToast', 'userResource', 'orderingPhysicianResource', 'http-auth-interceptor', 'clrConstant'])

  /**
   * Configure angular app to prompt redirect to login page
   * if 'event:auth-loginRequired' event is triggered.
   */
  .run(function($rootScope, $window, $location) {
    $rootScope.$on('event:auth-loginRequired', function() {
      $window.$('.body-wrapper, .footer').addClass('u--force-hide')
      let loginPath = '/sign-in';
      const currentPath = $location.path() + $window.location.search
      if (currentPath != loginPath && $window.location.pathname != loginPath) {
        loginPath += '?&next=' + encodeURIComponent(currentPath)
      }
      $window.location.href = loginPath
    })
  })

  /**
   * Run a heartbeat check every minute to ensure that the user session is still active if the user is logged in
   */
  .run(function($rootScope, $timeout, $http, $window, $location, auth, sessionAPIEndPoint) {
    function loginCheck() {
      $timeout(
        function() {
          if (auth.isLoggedIn) {
            $http.get(sessionAPIEndPoint, { ignoreAuthModule: true })
              .then(function(res) {
                const clrVersion = res.headers('x-clr-version')
                $rootScope.$emit('clrVersionUpdate', clrVersion)
              })
              .catch(function(res) {
                if (res) {
                  if (res.status === 401 || res.status === 403){
                    $window.location.href = '/sign-in?forced=true&next=' + $location.path()
                  }
                }
              })
          }
          loginCheck()
        },
        60 * 1000
      )
    }
    loginCheck()
  })

  /**
   * @ngdoc service
   * @name auth
   * @memberof clrAuth
   *
   * @description
   * `auth` allows to you to interact with user authentication service.
   *
   * @returns {Auth} An instance of `Auth`.
   *
   * @example
   * auth.login("hi@color.com", "mypassword")
   *
   * if (auth.isLoggedIn) {
   *  var userEmail = auth.currentUser.email
   * }
   */
  .service('auth', [
    'resolvedPromise', '$rootScope', '$http', '$window', 'authService', 'Account', 'sessionAPIEndPoint', 'skipAuthSync', Auth])

  /**
   * @ngdoc service
   * @name auth
   * @memberof clrAuth
   *
   * @description
   * `clrCodeVersionBanner` directive checks to make sure the browser's code version is the same
   * as what the server is currently serving.
   * Note: This will never show on dev environments because the `X-CLR-Version` header is always an empty string
   */
  .directive('clrCodeVersionBanner', function($rootScope, $window, $location, device) {
    'ngInject'

    return {
      restrict: 'E',
      scope: {},
      template: '<div class="clr-code-version-banner" ng-if="show">This browser tab is using an outdated version of Clinical. Some functionality may break unless you refresh.<br><a href="#" ng-click="reloadPage()">Force refresh</a> to load the new code ({{ keyboardShortcut }}) or <a href="#" ng-click="dismissBanner()">ignore this warning</a>.</div>',
      link: (scope, elem) => {
        // Don't show the banner initially
        scope.show = false;

        // Show different force refresh keyboard shortcuts if Windows or Mac
        if (device.isWindows) {
          scope.keyboardShortcut = 'Ctrl+Shift+R';
        } else {
          scope.keyboardShortcut = 'Cmd+Shift+R';
        }

        // Set initial browser version as undefined, which gets set upon the first heartbeat
        let browserVersion;

        // Leanest way to communicate between loginCheck() and this directive, as this module is used in www and clinical
        // On www, because this directive doesn't appear there, the $rootScope event from loginCheck() does nothing
        const checkVersion = (event, serverVersion) => {
          if (angular.isUndefined(browserVersion)) {
            // If this is the first heartbeat of this browser window's session, set it
            browserVersion = serverVersion
          } else if (browserVersion != serverVersion) { // Otherwise if the versions don't match
            // Show banner
            scope.show = true;
            // Now that the banner is shown, unregister the listener
            listener();
          } else {
            // Else continue not showing it
            scope.show = false;
          }
        }

        // Register listener
        const listener = $rootScope.$on('clrVersionUpdate', checkVersion)

        // Manual override for the banner showing and unregister the listener
        scope.dismissBanner = () => {
          elem.addClass('u--hide'); // `scope.show` takes awhile to propagate so manually hide so click feels fast
          scope.show = false;
          listener();
          return false;
        }

        // Manually bust the cache by reloading the page with a random query param at the end
        scope.reloadPage = () => {
          const cacheBustedUrl = $location.search('cachebust', new Date().getTime()).absUrl();
          $window.location = cacheBustedUrl;
          return false;
        }

      }
    }
  })

  /**
   * @ngdoc service
   * @name authRouteFilter
   * @memberof clrAuth
   *
   * @description
   * A factory which creates an authentication/authorization routing filter.
   *
   * @param {string} defaultLoginPath - A path to a login page.
   * @param {string} loggedInHomePath - A path to a home page for logged in user.
   * @param {string} emailConfirmationRequiredPath
   * A path to a page for notifying user that they need to confirm their email.
   * @param {string} permissionDeniedPath
   * A path to a page for notifying user that they are not authorized to access.
   * @param {string} sexSelectorPath
   * A path to a page for selecting user sex.
   * @param {string} benefitsRoutingAuthorizationPath
   * A path to a page for consenting to Employer benefits routing.
   * @param {string} healthHistoryIncompletePath
   * A path to a page that user gets redirected to
   * if health history is not complete and is required.
   * @param {string} providerLoggedInHomePath - A path to a home page for logged in providers.
   *
   * @returns {AuthRouteFilter} An instance of `AuthRouteFilter`.
   *
   * @example
   * var colorAuthRouteFilter = authRouteFilter(
   *   '/sign-in', '/', 'email-confirmation-required')
   *
   * // When `$routeChangeSuccess` event is triggered:
   * colorAuthRouteFilter.apply(config, next.$$route.originalPath)
   */
  .factory('authRouteFilter', function($window, $location, auth, redirect, textModal, permissionTypes, hasCompletedRoutingAuthorization) {
    return function(defaultLoginPath, loggedInHomePath,
      emailConfirmationRequiredPath, permissionDeniedPath,
      sexSelectorPath, healthHistoryIncompletePath,
      providerLoggedInHomePath, benefitsRoutingAuthorizationPath, associatedSample) {
      return new AuthRouteFilter(
        $window, $location, auth, redirect, associatedSample, textModal
        , defaultLoginPath, loggedInHomePath
        , emailConfirmationRequiredPath, permissionDeniedPath
        , sexSelectorPath, healthHistoryIncompletePath, providerLoggedInHomePath, benefitsRoutingAuthorizationPath, permissionTypes, hasCompletedRoutingAuthorization)
    }
  })
