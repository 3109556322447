!function() {
  'use strict'

  /**
   * @ngdoc module
   * @name clrStaticFiles
   *
   * @description
   * A `static` filter that points a provided resource path at the appropriate
   * static URL for the current deployment environment. This relieves us of a
   * dependency on Django's staticfiles template tags in front-end templates.
   */
  const module = angular.module('clrStaticFiles', [])

  // The https://prod-static.color.com/ placeholder will be replaced with the actual static URL
  // prefix during the `collectstatic` management command; see
  // `django_apps.core.storages.StaticURLRewritingMixin.post_process()` for
  // details.
  const injectedStaticUrl = 'https://prod-static.color.com/';
  let actualStaticUrl
  // We do this to test for the placeholder value without it also being
  // replaced by the post-processor.
  if (injectedStaticUrl.slice(0, 2) == '__' && injectedStaticUrl.slice(2) == 'STATIC_URL__') {
    actualStaticUrl = '/static/'
  } else {
    actualStaticUrl = injectedStaticUrl
  }

  module.constant('staticUrl', actualStaticUrl)

  module.filter('static', function (staticUrl) {
    return function (url) {
      // Strip leading / and append to the static URL.
      return staticUrl + url.replace(/^\//, '')
    }
  })
}()
