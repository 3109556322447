function TransitionReportController($scope, $modalInstance, $q, $http, ngToast, auth, Report, reports, action) {
  'ngInject'

  $scope.releasing = false
  $scope.auth = auth
  $scope.reports = reports
  $scope.action = action

  $scope.release = function(reports) {
    $scope.releasing = true

    const deferredReports = reports.map(function(report) {
      report[action] = true
      return new Report(report).$save()
    })

    $q.all(deferredReports)
      .then(function() {
        return $modalInstance.close()
      })
      .finally(function(res) {
        $scope.releasing = false
      })
  }
}

function ReleaseReportToPhysicianController($scope, $modalInstance, $q, $http, auth, Report, reportsToSignOut, reportsToApprove) {
  'ngInject'

  const actions = []
  if (reportsToSignOut.length > 0) {
    actions.push("Sign Out")
  }
  if (reportsToApprove.length > 0) {
    actions.push("Approve for Sign-Out")
  }
  $scope.actionText = actions.join("/")

  $scope.releasing = false
  $scope.auth = auth
  $scope.reportsToSignOut = reportsToSignOut
  $scope.reportsToApprove = reportsToApprove

  $scope.release = (reportsToSignOut, reportsToApprove) => {
    $scope.releasing = true

    const deferredReports = reportsToSignOut.map((report) => {
      return $http.put(`api/v1/reports/${report.id}/sign_out`)
    })
    deferredReports.concat(reportsToApprove.map((report) => {
      report['approved_for_sign_out'] = true
      return new Report(report).$save()
    }))

    $q.all(deferredReports)
      .then(() => {
        return $modalInstance.close()
      })
      .finally((res) => {
        $scope.releasing = false
      })
  }
}

/**
 * @ngdoc module
 * @name resultModal
 *
 * @description
 * The `resultModal` provides result modals.
 */
module.exports = angular.module('resultModal', ['ngToast', 'resultResource'])
  /**
   * @ngdoc service
   * @name changeReportStateModal
   * @memberof resultModal
   *
   * @description
   * A modal for changing report state.
   */
  .factory('changeReportStateModal', function($modal) {
    function ChangeReportStateModal($scope, $modalInstance, report, state, title, buttonText, placeholder) {
      'ngInject'
      $scope.requesting = false
      $scope.report = report
      $scope.title = title
      $scope.buttonText = buttonText
      $scope.placeholder = placeholder

      $scope.request = function(report) {
        $scope.requesting = true

        report[state] = true

        report.$save()
          .then(function() {
            return $modalInstance.close()
          })
          .finally(function(res) {
            $scope.requesting = false
          })
      }
    }
    return {
      open: function(report, state, title, buttonText, placeholder) {
        return $modal.open({
          templateUrl: '/results/request_genetics_board_review_modal.html',
          controller: ChangeReportStateModal,
          size: 'lg',
          resolve: {
            report: function() {
              return report
            },
            state: function() {
              return state
            },
            title: function() {
              return title
            },
            buttonText: function() {
              return buttonText
            },
            placeholder: function() {
              return placeholder
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name releaseToPathologistModal
   * @memberof resultModal
   *
   * @description
   * A modal for releasing reports to pathologist.
   */
  .factory('releaseToPathologistModal', function($modal) {

    return {
      open: function(reports) {
        return $modal.open({
          templateUrl: '/results/release_to_pathologist_modal.html',
          controller: TransitionReportController,
          size: 'lg',
          resolve: {
            reports: function() {
              return reports
            },
            action: function() {
              return 'content_reviewed'
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name releaseToPhysicianModal
   * @memberof resultModal
   *
   * @description
   * A modal for releasing reports to signing physician.
   */
  .factory('releaseToPhysicianModal', function($modal) {
    return {
      open: function(reportsToSignOut, reportsToApprove) {
        return $modal.open({
          templateUrl: '/results/release_to_physician_modal.html',
          controller: ReleaseReportToPhysicianController,
          size: 'lg',
          resolve: {
            reportsToSignOut: function() {
              return reportsToSignOut
            },
            // Pathologists that can't sign out a report can still approve it for someone else to sign out
            reportsToApprove: function() {
              return reportsToApprove
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name approveReleaseModal
   * @memberof resultModal
   *
   * @description
   * A modal for releasing reports to customer.
   */
  .factory('approveReleaseModal', function($modal) {
    return {
      open: function(reports) {
        return $modal.open({
          templateUrl: '/results/approve_release_modal.html',
          controller: TransitionReportController,
          size: 'lg',
          resolve: {
            reports: function() {
              return reports
            },
            action: function() {
              return 'release_approved'
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name setReportContentTemplateModal
   * @memberof resultModal
   *
   * @description
   * A modal for creating new report templates.
   */
  .factory('setReportContentTemplateModal', function($modal) {
    return {
      open: function(templates) {
        return $modal.open({
          templateUrl: '/results/create_report_template_modal.html',
          controller: function($scope, $modalInstance, templates) {
            $scope.templates = templates

            $scope.continue = function(templateToCopy) {
              return $modalInstance.close(templateToCopy)
            }
          },
          size: 'md',
          resolve: {
            templates: function() {
              return templates
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name addReportReviewNoteModal
   * @memberof resultModal
   *
   * @description
   * A modal for adding report review note.
   */
  .factory('addReportReviewNoteModal', function($modal) {
    return {
      open: function(report) {
        return $modal.open({
          templateUrl: '/results/add_note_modal.html',
          controller: function($scope, $modalInstance, report) {

            $scope.report = report

            $scope.addNote = function(report) {
              $scope.adding = true
              report.$save()
                .then(function() {
                  return $modalInstance.close()
                })
                ['finally'](function() {
                  $scope.adding = false
                })
            }
          },
          size: 'lg',
          resolve: {
            report: function() {
              return report
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name saveReferenceModal
   * @memberof resultModal
   *
   * @description
   * A modal for saving a reference.
   */
  .factory('saveReferenceModal', function($modal) {
    return {
      open: function(reference) {
        return $modal.open({
          templateUrl: '/results/save_reference_modal.html',
          controller: function($scope, $modalInstance, reference, Reference) {

            $scope.reference = new Reference(reference)

            $scope.saveReference = function(reference) {
              $scope.saving = true
              reference.$save()
                .then(function() {
                  return $modalInstance.close(reference)
                })
                ['finally'](function() {
                  $scope.saving = false
                })
            }
          },
          size: 'md',
          resolve: {
            reference: function() {
              return reference
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name retryFaxModal
   * @memberof resultModal
   *
   * @description
   * A modal for retrying fax.
   */
  .factory('retryFaxModal', function($modal) {
    return {
      open: function(fax) {
        return $modal.open({
          templateUrl: '/results/_modal-retry_fax.html',
          controller: function($scope, $modalInstance, $http, fax) {

            $scope.fax = fax

            $scope.request = function(fax) {
              $scope.requesting = true
              $http.post('/api/v1/retry_fax', { fax_log: fax.id })
                .then(function() {
                  return $modalInstance.close()
                })
                .finally(function() {
                  $scope.requesting = false
                })
            }
          },
          size: 'sm',
          resolve: {
            fax: function() {
              return fax
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name rereleaseModal
   * @memberof resultModal
   */
  .factory('rereleaseModal', function($modal) {
    return {
      open: function(reportId, isRetraction = false) {
        return $modal.open({
          templateUrl: '/results/_modal-rerelease.html',
          controller: function RereleaseModal($scope, $modalInstance, $location, $http, ngToast, reportId) {
            $scope.isRetraction = isRetraction
            $scope.revisionTypes = []
            $http.get('/api/v1/revision_types?ordering=-updated_at&is_active=True&page_size=500')
              .then(function(res) {
                $scope.revisionTypes = res.data.results.map(function(rt) {
                  return { label: '[ID ' + rt.id + '] ' + rt.reason, value: rt }
                })
              })
            $scope.rerelease = function(revisionType) {
              $scope.requesting = true
              $http.put('/api/v1/reports/' + reportId + '/queue_for_rerelease', { revision_type: revisionType.id, is_retraction: isRetraction })
                .then(function(res) {
                  ngToast.create("Queued for rerelease.")
                  $location.path('/reports/' + res.data.revised_report)
                  return $modalInstance.close()
                })
                .finally(function() {
                  $scope.requesting = false
                })
            }
          },
          size: 'lg',
          resolve: {
            reportId: function() {
              return reportId
            },
            isRetraction: function() {
              return isRetraction
            }
          }
        })
      }
    }
  })

  .factory('reviewKnownVariantModal', function($modal) {
    return {
      open: (reportedVariantId) => {
        return $modal.open({
          templateUrl: '/results/_modal-review-known-variant.html',
          controller: function ReviewKnownVariantModal($scope, $modalInstance, $http, ngToast, reportedVariantId) {
            $scope.resolved = true

            $scope.submit = () => {
              $scope.submitting = true
              let url;
              let data
              if ($scope.resolved) {
                url = `/api/v1/reported_variants/${reportedVariantId}/resolve`
                data = {
                  variant_classification_id: $scope.variantClassificationId,
                  notes: $scope.resolveNotes
                }
              } else {
                url = `/api/v1/reported_variants/${reportedVariantId}/unresolvable`
                data = { notes: $scope.unresolvableNotes }
              }
              $http.put(url, data)
                .then((response) => {
                  ngToast.create("Known variant reviewed.")
                  return $modalInstance.close()
                })
                .finally(() => $scope.submitting = false)
            }
          },
          resolve: {
            reportedVariantId: () => reportedVariantId
          }
        })
      }
    }
  })

  .factory('confirmTrueNegativeModal', function($modal) {
    return {
      open: (reportedVariantId) => {
        return $modal.open({
          templateUrl: '/results/_modal-confirm-true-negative.html',
          controller: function ConfirmTrueNegativeModal($scope, $modalInstance, $http, ngToast, reportedVariantId) {
            $scope.submit = () => {
              $scope.submitting = true
              $http.put(`/api/v1/reported_variants/${reportedVariantId}/confirm_true_negative`)
                .then((response) => {
                  ngToast.create("True negative confirmed.")
                  return $modalInstance.close()
                })
                .finally(() => $scope.submitting = false)
            }
          },
          resolve: {
            reportedVariantId: () => reportedVariantId
          }
        })
      }
    }
  })

  .factory('PgxTutorial', function(multiPageModal) {
    'ngInject'
    return {
      showTutorial: function() {
        multiPageModal.open(
          [
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TITLE1",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TITLE2",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TITLE3",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TITLE4",
          ],
          [
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TEXT1",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TEXT2",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TEXT3",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.TEXT4",
          ],
          [
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.CONTINUE",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.CONTINUE",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.CONTINUE",
            "ACTIVATE.TEST_INFO.MEDICATION_MODAL.FINISH",
          ],
          [
            "img--benefits_and_limitations_results",
            "img--benefits_and_limitations_genes",
            "img--benefits_and_limitations_other_factors_pgx",
            "img--benefits_and_limitations_provider",
          ]
        )
      }
    }
  })

  /**
   * @ngdoc service
   * @name confirmPgxModal
   * @memberof resultModal
   *
   * @description
   * A modal for ensuring customers know to not change medications based on pgx report results.
   */
  .factory('confirmPgxModal', function($modal) {
    return {
      open: function(goToReport) {
        return $modal.open({
          templateUrl: '/results/_modal_confirm_pgx.html',
          controller: function confirmPgxModalController($scope) {
            $scope.submit = () => {
              goToReport()
            }
          },
          size: 'sm',
          resolve: {}
        })
      }
    }
  })
