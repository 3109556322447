"use strict"

module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      clrSetFocus: '@'
    },
    link: function(scope, elem) {
      elem.bind('click', function() {
        angular.element('[name=' + scope.clrSetFocus + ']').focus()
      })
      scope.$on('destroy', function() {
        elem.unbind('click')
      })
    }
  }
}
