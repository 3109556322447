/**
 * @prettier
 */

module.exports = function sampleLetterMailto($compile, marked) {
  'ngInject';

  return {
    restrict: 'A',
    scope: true,
    link: function(scope, elem, attrs) {
      const template = marked(attrs.sampleLetterMailto);
      const content = $compile(template)(scope);
      const stubElement = angular.element('<div>');
      stubElement.append(content);
      const compiledContent = stubElement.html();
      // Convert </p> to new lines, strip all html after
      const url = encodeURIComponent(
        compiledContent.replace(/<\/p>/gi, '\n').replace(/<(?:.|\n)*?>/gm, '')
      );
      // Add mailto: prefix
      const mailto = 'mailto:?Body=' + url;
      elem.attr('href', mailto);
    },
  };
};
