'use strict'

module.exports = function($location, sku, pathFlow, auth, KitCheckout, waffle, physicianOrder, testTypes) {
  'ngInject'

  const flow = pathFlow('sampleReuseFlow', [
    { controller: 'SampleReusePayController', path: '/sample-reuse/pay' },
    { controller: 'SampleReuseConsentController', path: '/sample-reuse/consent' },
  ])

  flow.checkout = new KitCheckout()
  flow.init = function(start) {

    const params = $location.search()
    let skuName = 'combo 3'
    if (params.sku && sku[params.sku]) {
      skuName = params.sku
    }

    // Sample reuse only qualifies for H30
    flow.checkout.init(start, skuName)

    auth.sync().then(function() {
      // alreadyHavePhysicianOrder relies on information from currentUser
      if (physicianOrder.alreadyHavePhysicianOrder(flow.checkout.sku.name)) {
        flow.checkout.existingPhysicianOrder = true
      }
    })

    flow.checkout.isSampleReuse = true
    flow.checkout.requireShipping = false
  }

  return flow
}
