"use strict"

module.exports = function($animate, waffle) {
  "ngInject"

  return {
    multiElement: true,
    restrict: 'A',
    link: function($scope, element, attr) {
      const switchName = attr.waffleIfNotSwitch;
      // nosemgrep:semgrep-rules.use-launchdarkly-react
      const enabled = !waffle.switch_is_active(switchName);
      $animate[enabled ? 'removeClass' : 'addClass'](element, 'ng-hide', {
        tempClasses: 'ng-hide-animate'
      })
    }
  }
}
