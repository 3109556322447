'use strict'

module.exports = function(cookiePersistedObject, lowFrictionHealthcareProviders) {
  'ngInject'

  const store = cookiePersistedObject("healthcareProvider")
  // Search for provider with key equal to passed in param
  store.getHealthcareProvider = function() {
    const storedValue = store.get()
    if (storedValue) {
      return lowFrictionHealthcareProviders[storedValue.toLowerCase()]
    }
    return null
  }
  return store
}
