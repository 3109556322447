'use strict'

module.exports = function physicianOrderInsuranceAttemptDetails() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      order: '=',
    },
    templateUrl: '/ordering_physicians/directives/physician_order_insurance_attempt_details.html',
    link: function(scope) {
      // Set a few convenience variables.
      scope.$watch('order', function(order) {
        if (order) {
          const lastSubmission = scope.order.attempted_insurance_submission
          if (lastSubmission && lastSubmission.criteria_submission) {
            const diagnoses = lastSubmission.criteria_submission.hboc_criteria.diagnoses
            const geneticTestResults = lastSubmission.criteria_submission.hboc_criteria.genetic_test_results
            scope.hasPersonalHistory = !!diagnoses.filter(function(d) {
              return !d.relationship
            }).length
            scope.hasFamilyHistory = !!diagnoses.filter(function(d) {
              return d.relationship
            }).length
            scope.hasPersonalPastGeneticTesting = !!geneticTestResults.filter(function(t) {
              return !t.relationship
            }).length
            scope.hasFamilyPastGeneticTesting = !!geneticTestResults.filter(function(t) {
              return t.relationship
            }).length
          }
        }
      });
    }
  }
}
