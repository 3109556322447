/**
 * @prettier
 */

module.exports = function wisdomReportTestMethodology(
  $filter,
  $translate,
  interpretedGenes,
  testTypes
) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/wisdom_report_test_methodology.html',
    replace: true,
    link: function(scope) {
      scope.listOfGenes = $filter('orderBy')(interpretedGenes[testTypes.wisdom9]).join(', ');
      scope.version = $filter('testMethodologyVersion')(scope.reportContent);
      if (scope.version > 1) {
        $translate([
          'RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P1_MD',
          'RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P3_MD',
          'RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P4_MD',
        ]).then(function(translations) {
          scope.p1 = translations['RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P1_MD'];
          scope.p3 = translations['RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P3_MD'];
          scope.p4 = translations['RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P4_MD'];
        });
      }
      if (scope.version >= 9) {
        $translate(['RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P2_MD']).then(function(
          translations
        ) {
          scope.p2 = translations['RESULT.METHODOLOGY.WISDOM.V' + scope.version + '.P2_MD'];
        });
      }
    },
  };
};
