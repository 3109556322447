module.exports = function ReportNavbar($timeout) {
  'ngInject'

  return {
    scope: {
      selectedTab: '@',
      tabs: '=',
      scrollPos: '&',
      report: '=',
      reportContent: '=',
    },
    templateUrl: '/results_v2/directives/report_navbar.html',
    controller: ($scope, $document, $window) => {
      'ngInject'
      $scope.withCallback = (fn) => {
        fn()
        $document.scrollTo(0, $scope.scrollPos() + 2, 100)
      }

      // listen to scroll event to decide whether to show the selector
      let ticking = false;
      $scope.hideOnWeb = true
      $window.addEventListener('scroll', function() {
        if (ticking) {
          return
        }
        $window.requestAnimationFrame(function() {
          // $scope.report is set in report_v2_controller, passed in web_report_v2.jade
          // We do not want to show the affixed navbar for hdr 59 reports
          if ($scope.report && $scope.report.kit_order && $scope.report.kit_order.test_type === 'hdr 59') {
            return
          }
          $scope.hideOnWeb = $scope.scrollPos() >= $window.scrollY
          $scope.$digest()
          ticking = false;
        });
        ticking = true;
      });
    },
    link: (scope, element, attrs) => {
      const setTab = function(index) {
        const tab = element.find(`.tab-bar__list-${index}`)[0]
        if (tab) {
          element.find('.tab-bar__underline')
            .width(tab.clientWidth)
            .css('left', tab.offsetLeft)
        }
      }

      scope.mobileToggleMenu = function(bool) {
        scope.mobileMenuOpen = bool ? bool : !scope.mobileMenuOpen
      }

      scope.$watch('tabs', (newValue, oldValue) => {
        if (newValue) {
          $timeout(() => setTab(0))
        }
      })
      scope.$watch('selectedTab', (newValue, oldValue) => {
        if (!isNaN(newValue)) {
          setTab(newValue)
          scope.mobileMenuOpen = false
        }
      })
    }
  }
}
