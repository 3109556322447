'use strict'

// Research Redirect Controller is responsible for redirects for the /research/activate path
// For Research Sample Activation, we have to verify we have a claim, generate a physician order, kit orders,
// and the purchase. This request does that and takes them to activation.
module.exports = function($location, $http, ngToast, accessionNumber, kitBarcode) {
  'ngInject'
  $http.post('/api/v1/purchase_research_sample', {'kit_barcode': kitBarcode.get(), 'accession_number': accessionNumber.get()})
    .then(() => {
      $location.path('/genetics/activate');
    }).catch(err => {
      ngToast.create({ className: 'error', content: err.data })
      // We shouldn't surface activation to them (since patient may place a physician order, think they need to purchase the kit).
      $location.path('/');
    })
}
