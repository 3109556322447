'use strict'

module.exports = function PreResultsController($rootScope, $scope, $translate
  , $translatePartialLoader, $filter, $location, $routeParams
  , $sce, $window, appointmentTypes, auth, promoCodeStore, kitStatuses, sampleReuseService
  , schedulizerTextOptions, testTypes, sku, PgxTutorial, confirmPgxModal) {
  'ngInject'

  // Results Ready pages uses strings from this bundle
  $translatePartialLoader.addPart('report')
  $scope.promo_code = auth.currentUser.promo_code
  if ($scope.promo_code) {
    promoCodeStore.persistCode($scope.promo_code)
    promoCodeStore.updateCode($scope.promo_code, true)
  }

  let kitStatus = null
  if (kitStatuses.results.length) {
    if ($routeParams.testType) {
      const kitStatusFromParams = $routeParams.testType.replace(/-/g, ' ')
      const matchingKits = kitStatuses.results.filter(function(kit) {
        return kit.test_type === kitStatusFromParams
      })
      kitStatus = matchingKits.length ? matchingKits[0] : null
    }
    if (!$routeParams.testType || !kitStatus) {
      kitStatus = kitStatuses.results[0]
      $location.path('/react/dashboard')
      return
    }
  }
  if (!kitStatus) {
    kitStatus = {"order_status": "no_order"}
  }
  $scope.testTypes = testTypes
  $scope.testType = kitStatus.test_type
  $scope.video = sku[$scope.testType].video
  $scope.videoScriptUrl = sku[$scope.testType].videoScriptUrl
  $scope.orderNumber = kitStatus.order_number
  if (kitStatus.health_conditions && kitStatus.health_conditions.length > 0){
    $scope.healthCondition = kitStatus.health_conditions[0] // Take the first condition
  }
  $scope.kitStatus = kitStatus
  $scope.showTestType = false
  $scope.titleError = false
  $scope.showReorder = false
  $scope.showActivate = false
  $scope.showShipping = false
  $scope.showTestTypeNav = kitStatuses.results.length > 1 && kitStatus.order_status != 'no_order'
  $scope.showShippingIsIncorrect = false
  $scope.showBarcode = false
  $scope.showTracking = false
  $scope.showBanner = false
  $scope.showGetReady = false
  $scope.showWhatToDoNext = false
  $scope.showWhatToDoNextActivate = false
  $scope.showWhatHappensNext = false
  $scope.showAboutGeneticTesting = false
  $scope.showPurchaseNumber = false

  $scope.goToReport = function() {
    const testType = $routeParams.testType
    if (testType == 'pgx-v1') {
      // PGx Report uses react whereas other reports use iframe in Angular
      $window.location.replace('/results/pgx/dashboard')
    } else {
      $location.path(`/results/${testType}/report`).replace()
    }
  }

  $scope.goToPreResultsView = function() {
    const testType = $routeParams.testType
    $location.path(`/react/results-v3/${testType}`).replace()
  }

  $scope.confirmPgx = function() {
    confirmPgxModal.open($scope.goToReport).result
  }

  switch (kitStatus.order_status) {
    case "no_order":
    case "wisdom_order":
      break

    case "not_shipped":
      $scope.timeline = "warehouse"
      $scope.showShippingIsIncorrect = true
      $scope.showShipping = true
      $scope.showGetReady = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.NOT_SHIPPED',
        'PRE-RESULTS.STATUS.DETAILS.NOT_SHIPPED',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.NOT_SHIPPED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.NOT_SHIPPED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    // @todo endpoint doesn't return this state at the moment
    case "payment_declined":
      $scope.timeline = "warehouse"
      $scope.titleError = true
      $scope.showReorder = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.PAYMENT_DECLINED',
        'PRE-RESULTS.STATUS.DETAILS.PAYMENT_DECLINED'
      ], {
        cardLast4: 'cardLast4'
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.PAYMENT_DECLINED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.PAYMENT_DECLINED']
      })
      break

    case "usps_lost_package":
      $scope.timeline = "warehouse"
      $scope.showShippingIsIncorrect = true
      $scope.showShipping = true
      $scope.showGetReady = true
      $scope.showBanner = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE',
        'PRE-RESULTS.STATUS.TITLE.NOT_SHIPPED',
        'PRE-RESULTS.STATUS.DETAILS.NOT_SHIPPED',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ]).then(function (translations) {
        $scope.msgBanner = translations['PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE']
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.NOT_SHIPPED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.NOT_SHIPPED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    case "shipped_to_home":
      $scope.timeline = "to_home"
      $scope.showShippingIsIncorrect = true
      $scope.showShipping = true
      $scope.showTracking = true
      $scope.showGetReady = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME',
        'PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ], {
        shipDate: $filter('date')(kitStatus.fulfillment.scanned_at, 'MMM ') + $filter('ordinalSuffix')($filter('date')(kitStatus.fulfillment.scanned_at, 'd'))
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    case "shipped_to_home_failed":
      $scope.timeline = null
      $scope.titleError = true
      $scope.showShipping = true
      $scope.showTracking = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME_FAILED',
        'PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME_FAILED',
        'PRE-RESULTS.QUESTIONS.DOES_COLOR_SHIP_PO',
        'PRE-RESULTS.QUESTIONS.DOES_COLOR_SHIP_INTERNATIONAL'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME_FAILED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME_FAILED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.DOES_COLOR_SHIP_PO']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.DOES_COLOR_SHIP_INTERNATIONAL']
      })
      break

    case "usps_lost_package_new_kit_shipped":
      $scope.timeline = "to_home"
      $scope.showShippingIsIncorrect = true
      $scope.showShipping = true
      $scope.showTracking = true
      $scope.showGetReady = true
      $scope.showBanner = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE_NEW_KIT_SENT',
        'PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME',
        'PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ], {
        shipDate: $filter('date')(kitStatus.fulfillment.scanned_at, 'MMM ') + $filter('ordinalSuffix')($filter('date')(kitStatus.fulfillment.scanned_at, 'd'))
      }).then(function (translations) {
        $scope.msgBanner = translations['PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE_NEW_KIT_SENT'],
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_HOME']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_HOME']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    case "new_kit_shipped":
      $scope.timeline = "to_home"
      $scope.showShipping = true
      $scope.showTracking = true
      $scope.showGetReady = true
      $scope.showBanner = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.NEW_KIT_SHIPPED',
        'PRE-RESULTS.STATUS.DETAILS.NEW_KIT_SHIPPED',
        'PRE-RESULTS.BANNERS.ORIGINAL_SAMPLE_NOT_ENOUGH_SALIVA',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.NEW_KIT_SHIPPED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.NEW_KIT_SHIPPED']
        $scope.msgBanner = translations['PRE-RESULTS.BANNERS.ORIGINAL_SAMPLE_NOT_ENOUGH_SALIVA']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    // @todo endpoint doesn't return this state at the moment
    // https://app.asana.com/0/11977858404233/29788045473163
    case "new_kit_shipped_because_usps_lost_package":
      $scope.timeline = "to_home"
      $scope.showShipping = true
      $scope.showTracking = true
      $scope.showGetReady = true
      $scope.showBanner = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.NEW_KIT_SHIPPED',
        'PRE-RESULTS.STATUS.DETAILS.NEW_KIT_SHIPPED',
        'PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE',
        'PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING',
        'PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE',
        'PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.NEW_KIT_SHIPPED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.NEW_KIT_SHIPPED']
        $scope.msgBanner = translations['PRE-RESULTS.BANNERS.USPS_LOST_PACKAGE']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.I_TYPED_INCORRECT_SHIPPING']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHEN_WILL_MY_KIT_ARRIVE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.HOW_CAN_TRACK_STATUS']
      })
      break

    case "delivered_to_home":
      $scope.timeline = "home"
      if (kitStatus.shipping_address) {
        $scope.showShipping = true
        $scope.showTracking = true
      }
      $scope.showActivate = true
      $scope.showWhatToDoNextActivate = true
      $scope.showPurchaseNumber = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.DELIVERED_TO_HOME',
        'PRE-RESULTS.STATUS.DETAILS.DELIVERED_TO_HOME',
        'PRE-RESULTS.STATUS.DETAILS.DELIVERED_ONSITE',
        'PRE-RESULTS.QUESTIONS.HAVE_KIT_WHAT_SHOULD_I_DO',
        'PRE-RESULTS.QUESTIONS.HOW_QUICKLY_RETURN_SAMPLE',
        'PRE-RESULTS.QUESTIONS.WHAT_DO_NEVER_RECEIVED'
      ], {
        deliveredDate: $filter('date')(kitStatus.shipping.delivered_at, 'MMM ') + $filter('ordinalSuffix')($filter('date')(kitStatus.shipping.delivered_at, 'd')),
        deliveredTime: $filter('date')(kitStatus.shipping.delivered_at, 'h:mma')
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.DELIVERED_TO_HOME']
        $scope.msgStatusDetails = kitStatus.shipping_address ?
          translations['PRE-RESULTS.STATUS.DETAILS.DELIVERED_TO_HOME'] :
          translations['PRE-RESULTS.STATUS.DETAILS.DELIVERED_ONSITE']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.HAVE_KIT_WHAT_SHOULD_I_DO']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_QUICKLY_RETURN_SAMPLE']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.WHAT_DO_NEVER_RECEIVED']
      })
      break

    case "delivered_to_home_activated":
      $scope.timeline = "home"
      $scope.showBarcode = true
      $scope.showWhatToDoNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.DELIVERED_TO_HOME_ACTIVATED',
        'PRE-RESULTS.STATUS.DETAILS.DELIVERED_TO_HOME_ACTIVATED',
        'PRE-RESULTS.QUESTIONS.HOW_PROVIDE_SAMPLE',
        'PRE-RESULTS.QUESTIONS.WHAT_DO_MISTAKE_PROVIDING_SAMPLE'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.DELIVERED_TO_HOME_ACTIVATED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.DELIVERED_TO_HOME_ACTIVATED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.HOW_PROVIDE_SAMPLE']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.WHAT_DO_MISTAKE_PROVIDING_SAMPLE']
      })
      break

    case "collected_onsite_activated":
      $scope.timeline = "home"
      $scope.showBarcode = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.COLLECTED_ONSITE_ACTIVATED',
        'PRE-RESULTS.STATUS.DETAILS.COLLECTED_ONSITE_ACTIVATED',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.COLLECTED_ONSITE_ACTIVATED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.COLLECTED_ONSITE_ACTIVATED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
      })
      break

    case "shipped_to_lab":
      $scope.timeline = "to_lab"
      $scope.showWhatHappensNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_LAB',
        'PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_LAB',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS',
        'PRE-RESULTS.QUESTIONS.FORGOT_TO_ACTIVATE_BEFORE_MAILING'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.SHIPPED_TO_LAB']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.SHIPPED_TO_LAB']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
        $scope.msgQ3 = translations['PRE-RESULTS.QUESTIONS.FORGOT_TO_ACTIVATE_BEFORE_MAILING']
      })
      break

    case "at_lab_not_activated":
      $scope.timeline = "lab"
      $scope.titleError = true
      $scope.showActivate = true
      $scope.showAboutGeneticTesting = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.AT_LAB_NOT_ACTIVATED',
        'PRE-RESULTS.STATUS.DETAILS.AT_LAB_NOT_ACTIVATED',
        'PRE-RESULTS.QUESTIONS.HOW_TO_ACTIVATE_KIT',
        'PRE-RESULTS.QUESTIONS.FORGOT_TO_ACTIVATE_BEFORE_MAILING'
      ]).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.AT_LAB_NOT_ACTIVATED']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.AT_LAB_NOT_ACTIVATED']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.HOW_TO_ACTIVATE_KIT']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.FORGOT_TO_ACTIVATE_BEFORE_MAILING']
      })
      break

    case "at_lab_sequencing":
      $scope.timeline = "lab"
      $scope.showBarcode = true
      $scope.showWhatHappensNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING',
        'PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS'
      ], {
        tatEstimate: $filter('clrDate')(kitStatus.tat_estimate)
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
      })
      break

    case "at_lab_data_reuse":
      $scope.timeline = "lab"
      $scope.showBarcode = true
      $scope.showWhatHappensNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING',
        'PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_DATA_REUSE',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS'
      ], {
        tatEstimateDataReuse: $filter('clrDate')(sampleReuseService.getResultsExpectedDate(kitStatus.purchased_at))
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_DATA_REUSE']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
      })
      break

    case "at_lab_sequencing_known_delay":
      $scope.timeline = "lab"
      $scope.showBarcode = true
      $scope.showWhatHappensNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING',
        'PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_KNOWN_DELAY',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS'
      ], {
        accessionedDate : $filter('clrDate')(kitStatus.fulfillment && kitStatus.fulfillment.completed_at),
        tatEstimate: $filter('clrDate')(kitStatus.tat_estimate)
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_KNOWN_DELAY']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
      })
      break

    case "at_lab_sequencing_unknown_delay":
      $scope.timeline = "lab"
      $scope.showBarcode = true
      $scope.showWhatHappensNext = true
      $scope.showTestType = true
      $translate([
        'PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING',
        'PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_UNKNOWN_DELAY',
        'PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY',
        'PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS'
      ], {
        naiveTatEstimate : $filter('clrDate')(kitStatus.naive_tat_estimate)
      }).then(function (translations) {
        $scope.msgStatusTitle = translations['PRE-RESULTS.STATUS.TITLE.AT_LAB_SEQUENCING']
        $scope.msgStatusDetails = translations['PRE-RESULTS.STATUS.DETAILS.AT_LAB_SEQUENCING_UNKNOWN_DELAY']
        $scope.msgQ1 = translations['PRE-RESULTS.QUESTIONS.WHEN_RESULTS_READY']
        $scope.msgQ2 = translations['PRE-RESULTS.QUESTIONS.HOW_PREPARE_RESULTS']
      })
      break

    case "pending_gc_session":
    case "non_reportable":
      $scope.showTestType = true
      $scope.phoneNumber = auth.currentUser.phone_number
      $scope.showScheduling = !kitStatus.counseling.upcoming_appointment
      $scope.appointmentTypes = appointmentTypes
      $scope.appointmentType = kitStatus.counseling.appointment_type
      $scope.appointmentTextOptions = schedulizerTextOptions(kitStatus.counseling.appointment_type)
      break
    case "released_opened":
    case "rereleased_not_opened":
    case "rereleased_opened":
      $scope.goToReport()
      break

    case "released_not_opened":
      $scope.goToPreResultsView()
      break

    default:
      break
  }

  // "results_ready" state
  $scope.videoPlaying = false
  if ($scope.testType == testTypes.breastOvarian19) {
    $scope.videoToPlay = 'pretestBreastOvarian19'
  } else {
    $scope.videoToPlay = 'preCounselCancer'
  }
  $scope.playVideo = function() {
    $scope.videoPlaying = true
  }
  $scope.closeVideo = function() {
    $scope.videoPlaying = false
  }

  $scope.showTutorial = PgxTutorial.showTutorial
}
