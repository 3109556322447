'use strict'

module.exports = function physicianOrderMainDetails(
  auth,
  detailedCovidSampleTypes,
  project,
  testTypes,
  ngToast,
  ApplicationType,
  PhysicianOrderType
) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      editable: '=?',
      onSave: '=',
      order: '=',
      ordering: '=?'
    },
    templateUrl: '/ordering_physicians/directives/physician_order_main_details.html',
    link: function(scope) {
      // Constants to be used in the templates
      const isClinical = project.application === ApplicationType.CLINICAL
      scope.isClinical = isClinical

      scope.PhysicianOrderType = PhysicianOrderType

      scope.testTypes = testTypes

      // The current provider.
      scope.userProvider = isClinical ? undefined: auth.currentUser.provider_profile

      // The section which is currently being edited; if none, undefined.
      scope.editing = undefined

      // The set of sections which are editable.
      scope.editableSections = {
        PATIENT_PROFILE: 'PATIENT_PROFILE',
        TEST_TYPE: 'TEST_TYPE',
        REFULFILLED_SAMPLE: 'REFULFILLED_SAMPLE'
      }

      scope.detailedCovidSampleTypes = detailedCovidSampleTypes

      // Set a few convenience variables.
      scope.$watch('order', function(order) {
        if (order) {
          const editableStates = ['new', 'claimable']
          scope.canEditTestRequested = editableStates.indexOf(scope.order.state) > 0

          // Display profile of placing user if they are not also the ordering physician
          if (scope.userProvider && !scope.userProvider.default_as_ordering_physician) {
            scope.displayedPlacedByProfile = scope.userProvider
          } else if (
            scope.order.placed_by_profile &&
            !scope.order.placed_by_profile.default_as_ordering_physician
          ) {
            scope.displayedPlacedByProfile = scope.order.placed_by_profile
          }
        }
      });

      /**
       * Toggles on editing for a particular section.
       *
       * @param {string} section
       * The section to edit. Value should be in ``scope.editableSections``.
       */
      scope.toggleEditing = function(section) {
        scope.editing = section
      }

      /**
       * Toggles off editing for all sections.
       */
      scope.resetEditing = function() {
        scope.editing = undefined
      }

      /**
       * Saves an edited order.
       */
      scope.save = function() {
        scope.onSave()
          .then(scope.resetEditing)
      }

      scope.onCopySuccess = function() {
        ngToast.create('Copied!')
      }
    }
  }
}
