module.exports = function MarketingCarousel() {
  'ngInject'
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    templateUrl: '/marketing/_carousel.html',
    link: function(scope) {
      scope.selectedClass = 'img--carousel_01'
    }
  }
}
