'use strict'

module.exports = function submitPaymentForm(
  $cookies,
  $timeout,
  ApplicationType,
  PhysicianOrderType,
  ProviderPlatformModals,
  auth,
  project,
  overrideOopBlockModal,
  promoCodeStore,
  testTypes,
  waffle
) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      checkout: '=',
      onSubmit: '=',
      order: '=',

      // Whether this form information is being submitted.
      submitting: '=',

      // Whether this form is being used to re-submit payment details.
      resubmitting: '=',

      // The initial payment type to show. Possible values are "insurance".
      paymentType: '@',

      // The text for the submit button. Possible values are
      // {"review-order", "update-order"}
      submitButtonText: '@'
    },
    templateUrl: '/ordering_physicians/directives/submit_payment_form.html',
    link: function(scope) {
      const STATES = {
        INSURANCE: 'INSURANCE',
        CASH_PAY: 'CASH_PAY',
        SWITCHED_TO_CASH_PAY: 'SWITCHED_TO_CASH_PAY',
        OVERRIDE_OOP_BLOCK: 'OVERRIDE_OOP_BLOCK'
      }

      scope.STATES = STATES

      scope.settings = { allow_ship_to_home_payment: true };

      if (project.application !== ApplicationType.CLINICAL) {
        scope.settings = auth.currentUser.provider_profile.settings
      } else {
        // Clear all promocodes when placing orders in clinical
        promoCodeStore.clear()
      }

      scope.isSupportStaff = auth.currentUser.is_support_staff

      // NOTE: We turned off the ability to bill to insurance in Aug 2024 by
      // setting following variable to false (we used to consider location,
      // provider settings, and test type), unless the user is support staff
      scope.isInsuranceOn = !!scope.isSupportStaff

      scope.PhysicianOrderType = PhysicianOrderType

      scope.isShipToHomePaymentEnabled = scope.settings.allow_ship_to_home_payment

      scope.order.ui.payment = scope.order.ui.payment || {}

      scope.ui = scope.order.ui.payment

      if (scope.paymentType === 'insurance') {
        scope.ui.state = STATES.INSURANCE
      }

      if (!scope.isInsuranceOn || scope.order.type === PhysicianOrderType.SAMPLE_ORDERED || scope.order.promocode_for_payment) {
        scope.ui.state = STATES.CASH_PAY
      }

      if (
        !scope.order.shipping_address &&
        scope.order.type === PhysicianOrderType.SAMPLE_ORDERED &&
        scope.isShipToHomePaymentEnabled
      ) {
        const patient = scope.order.patient_profile
        scope.order.shipping_address = {
          full_name: patient.first_name + ' ' + patient.last_name,
          line1: patient.address_line1,
          city: patient.city,
          state: patient.state,
          country: patient.country,
          postal_code: patient.postal_code,
          phone_country: patient.phone_number.country,
          phone_number: patient.phone_number.national_number,
        }
      }

      scope.setState = (state) => {
        scope.ui.state = state
      }

      scope.openOverrideOopBlockModal = () => {
        overrideOopBlockModal.open(scope.order.payment.eligibility_submission.id)
          .then(() => (scope.order.payment.eligibility_submission.overriden = true))
          .then(() => (scope.order.skip_payment = true))
          .then(() => scope.setState(STATES.OVERRIDE_OOP_BLOCK))
      }

      /**
       * The criteria section is visible if any of the following:
       *   - we've selected insurance
       *   - we've been switched to cash pay and passed the out of pocket check
       *     - this lets you see the failed criteria submission card
       *   - we've overriden the out of pocket block
       */
      scope.isCriteriaSectionVisible = () => {
        const insurancePredicate = scope.ui.state === STATES.INSURANCE
        const switchedToCashPredicate = (
          scope.ui.state === STATES.SWITCHED_TO_CASH_PAY &&
          scope.order.payment.meetsOopCheck()
        )
        const overrideOutOfPocketPredicate = (
          scope.ui.state === STATES.OVERRIDE_OOP_BLOCK
        )

        return insurancePredicate || switchedToCashPredicate || overrideOutOfPocketPredicate
      }

      /**
       * The criteria section is active if any of the following:
       *   - we've selected insurance and passed the out of pocket check
       *   - we've been switched to cash and passed the out of pocket check
       *     - this lets you retry, if you failed the criteria check
       *   - we've overriden the out of pocket block
       */
      scope.isCriteriaSectionActive = () => {
        const insurancePredicate = (
          scope.ui.state === STATES.INSURANCE &&
          scope.order.payment.meetsOopCheck()
        )
        const switchedToCashPredicate = (
          scope.ui.state === STATES.SWITCHED_TO_CASH_PAY &&
          scope.order.payment.meetsOopCheck()
        )
        const overrideOutOfPocketPredicate = (
          scope.ui.state === STATES.OVERRIDE_OOP_BLOCK
        )

        return insurancePredicate || switchedToCashPredicate || overrideOutOfPocketPredicate
      }

      /**
       * The criteria card is visible if the order has an attached criteria
       * submission.
       */
      scope.isCriteriaCardVisible = () => {
        return !!scope.order.payment.criteria_submission
      }

      scope.isShippingAddressSectionValid = () => {
        if (scope.order.type === PhysicianOrderType.SAMPLE_ORDERED && scope.isShipToHomePaymentEnabled) {
          const addressForm = angular.element('form[name=shippingAddressForm]').controller('form')
          return addressForm && addressForm.$valid
        }

        return true
      }

      // Checks if additional information section should active and editable after payment information given
      // For ship to home flow, it also makes shipping address field editable
      scope.isAdditionalInformationSectionActive = () => {
        if (!scope.ui.state) {
          return false
        }

        if (scope.ui.state === STATES.INSURANCE) {
          return scope.order.payment.meetsInsuranceCheck()
        }

        if (scope.ui.state === STATES.CASH_PAY || scope.ui.state === STATES.SWITCHED_TO_CASH_PAY) {
          if (scope.checkout.skipPayment()) {
            return true
          }

          const paymentForm = angular.element('form[name=submitPaymentForm]').controller('form')
          return paymentForm && paymentForm.$valid
        }

        if (scope.ui.state === STATES.OVERRIDE_OOP_BLOCK) {
          return scope.order.payment.meetsCriteriaCheck()
        }

        return false
      }

      scope.onEligibilityCheck = () => {
        if (scope.order.payment.meetsOopCheck()) {
          scope.setState(STATES.INSURANCE)
        } else {
          scope.setState(STATES.SWITCHED_TO_CASH_PAY)
        }

        scope.ui.oopAmount = scope.order.payment.oopEstimate()
        scope.ui.hasValidInsurance = !!scope.ui.oopAmount
      }

      /**
       * Performs any post-criteria state changes.
       */
      scope.onCriteriaCheck = () => {
        if (
          scope.ui.state === STATES.SWITCHED_TO_CASH_PAY &&
          scope.order.payment.meetsCriteriaCheck()
        ) {
          scope.setState(STATES.INSURANCE)
        } else if (!scope.order.payment.meetsCriteriaCheck()) {
          scope.setState(STATES.SWITCHED_TO_CASH_PAY)
        }
      }

      // The watcher below supports a specific workflow for support: they should
      // be able to submit criteria without having ICD codes  The use case for this
      // is that a lot of fax insurance orders they receive are missing some
      // information, and ICD codes are a big part of that.

      // However, if the criteria isn't even passing, support would prefer not to
      // follow up and get the ICD codes just to get switched to cash.

      // Therefore, if we haven't met criteria and are support, then we don't need
      // to require ICD codes; but otherwise, we do. Of course, they should not
      // be able to submit a valid insurance order without having ICD codes, so
      // we need to validate that as well on the larger form.

      scope.$watch('order.payment.criteria_submission.met_criteria', (metCriteria) => {
        // Only support should be able to skip ICD codes.
        const internal = auth.currentUser.is_support_staff
        let required = true
        const prior = scope.icdCodesRequired

        if (internal && !metCriteria) {
          required = false
        }

        // Unfortunately, nested `NgModelController`s do not re-validate
        // themselves after this is changed; therefore, we must manually
        // re-validate them.

        scope.icdCodesRequired = required

        if (required !== prior) {
          $timeout(() => {
            // Grab all ICD 10 `NgModelController`s that are a part of a
            // `HealthHistoryDiagnosisForm` and revalidate them.
            // This has to be run after the digest is done updating.
            const healthHistoryDiagnosisForms = angular.element('form[name*=healthHistoryDiagnosisForm]')
              .toArray()
              .map((formElement) => angular.element(formElement).controller('form'))
              .forEach((form) => {
                const icd10CmCodesNgModels = Object.keys(form)
                  .filter((key) => key.indexOf('icd10CmCodes') >= 0)
                  .map((key) => form[key])

                icd10CmCodesNgModels.forEach(model => model.$validate())
              })
          }, 0)
        }
      })

      const validateOrder = (order) => {
        // Validate current form as well as insurance and hboc form if they are present
        const paymentForm = angular.element('form[name=submitPaymentForm]').controller('form')
        const addressForm = angular.element('form[name=shippingAddressForm]').controller('form')
        const insuranceDetailsForm = angular.element('form[name=placeInsuranceDetailsForm]').controller('form')
        const hbocCriteriaForm = angular.element('form[name=hbocInsuranceCriteriaForm]').controller('form')
        const additionalInformationForm = angular.element('form[name=additionalInformationForm]').controller('form')

        if (paymentForm && !paymentForm.validate()) {
          return false
        }
        if (addressForm && !addressForm.validate()) {
          return false
        }
        if (insuranceDetailsForm && !insuranceDetailsForm.validate()) {
          return false
        }

        if (hbocCriteriaForm && !hbocCriteriaForm.validate()) {
          return false
        }
        if (additionalInformationForm && !additionalInformationForm.validate()) {
          return false
        }

        if (
          order.type !== PhysicianOrderType.SAMPLE_ORDERED &&
            !order.patient_profile.consent_attestation &&
            !$cookies.get('seen_no_attestation')
        ) {
          ProviderPlatformModals.openNoAttestationModal().result
            .then(function() {
              scope.onSubmit(order)
            })
          return false
        }

        return true
      }

      scope.handleSubmit = () => {
        if (!validateOrder(scope.order)) {
          return
        }

        scope.order.payment.meets_insurance = (
          (scope.ui.state === STATES.INSURANCE && scope.order.payment.meetsInsuranceCheck()) ||
          (scope.ui.state === STATES.OVERRIDE_OOP_BLOCK && scope.order.payment.meetsCriteriaCheck())
        )

        if (scope.order.payment.meets_insurance) {
          scope.order.skip_payment = false;
        }

        return scope.onSubmit(scope.order)
      }
    }
  }
}
