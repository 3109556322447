const baseReportURL = '/report/sample_web_report'
const pgxReportURL = '/results/pgx/dashboard'
const prodAssetHost = 'https://prod-static.color.com/r/'

const dummyNomenclature = [{
  'name': 'chr17.GRCh37:g.123123_123123',
  'is_primary': true,
  'type': 'gHGVS'
}, {
  'name': 'chr17.GRCh37:c.1234_1234dupTGAGA',
  'is_primary': true,
  'type': 'cHGVS'
}, {
  'name': 'chr17.GRCh37:p.Asn1234Metfs*8',
  'is_primary': true,
  'type': 'pHGVS'
}]

const dummyWarfarinInputs = {
  VKORC1: {
    31107689: ['A', 'A'],
  },
  CYP4F2: {
    15990431: ['C', 'C']
  },
  CYP2C9: {
    96702047: ['A', 'A'],
    96741053: ['A', 'A'],
    96741058: ['A', 'A'],
    96709038: ['A', 'A']
  },
}

module.exports = function ResultsV2PickerController(auth, $q, $scope, $http, $timeout, $location, interpretedGenes, handleReportClick, marked) {
  'ngInject'
  // this is a hack for the test picker because interpretedGenes contains
  //'CDKN2A (p14ARF)' etc instead of just CDKN2A
  const testGenesCovered = {
    'breast ovarian 19': interpretedGenes['breast ovarian 19'],
    'hereditary 7': interpretedGenes['hereditary 7'],
    'hereditary 25': interpretedGenes['hereditary 25'],
    'hereditary 30': interpretedGenes['hereditary 30']
      .filter((v) => !v.match('CDKN2A'))
      .concat('CDKN2A'),
    'hereditary 41': interpretedGenes['hereditary 41']
      .filter((v) => !v.match('CDKN2A'))
      .concat('CDKN2A'),
    'cardio 30': interpretedGenes['cardio 30'],
    'fh 3': interpretedGenes['fh 3'],
    'brca 1 2': interpretedGenes['brca 1 2'],
    'metabolism 4': interpretedGenes['metabolism 4'],
    'covid-19': [],
    'covid-19 antigen': [],
    'hdr 59': interpretedGenes['hdr 59'],
    'wisdom 9': interpretedGenes['wisdom 9'],
    // pgx v1 and pgx v3 interpreted genes is automatically populated by pgxGeneInterpretationsPromise
    'pgx v1': [],
    'pgx v3': []
  }

  const clinicalDomain = {
    'breast ovarian 19': 'cancer',
    'hereditary 7': 'cancer',
    'hereditary 25': 'cancer',
    'hereditary 30': 'cancer',
    'hereditary 41': 'cancer',
    'brca 1 2': 'cancer',
    'cardio 30': 'cardio',
    'fh 3': 'cardio',
    'metabolism 4': 'other',
    'covid-19': 'other',
    'covid-19 antigen': 'other',
    'hdr 59': 'other',
    'pgx v1': 'medication',
    'pgx v3': 'medication'
  }

  function setDefaultValues() {
    $scope.testType = 'pgx v3'
    $scope.sex = 'F'
    $scope.testMethodology = '082124'
    $scope.reports = []
    $scope.showVusDetails = false;
    updateGenes($scope.testType)
  }

  setDefaultValues();

  let initialData;
  let editor = null;

  const schemaPromise = $http.get('api/v1/report_info_schema')
    .then((res) => {
      $scope.schema = res.data
      $scope.schema['format'] = 'grid'
      initializeEditor()
    })

  function initializeEditor() {
    const el = document.getElementById('schema-editor')
    editor = new JSONEditor(el, {
      collapsed: true,
      schema: $scope.schema,
      theme: 'barebones',
      disable_edit_json: true,
      required_by_default: true,
      disable_properties: true
    })
  }

  // Get PGX genes and phenotypes covered
  $scope.pgxGenePhenotypes = []

  // PGx genes covered - used for pgx options selector
  $scope.pgxGenesCovered = []
  const geneDiplotypes = []
  const pgxGeneInterpretationsPromise = $http.get('api/v1/pgx_gene_interpretations')
    .then((res) => {
      // pgx v1 covers a subset of the genes returned from this endpoint
      // TODO: Maybe filter out the genes that are not covered by pgx v1
      const genesCovered = res.data.map(gene => gene.name)
      testGenesCovered['pgx v1'] = genesCovered
      testGenesCovered['pgx v3'] = genesCovered
      $scope.pgxGenesCovered = genesCovered
      res.data.forEach(genePhenotype => {
        // Flatten for ng-options
        genePhenotype.gene_diplotypes.forEach(gene_diplotype => {
          $scope.pgxGenePhenotypes.push({gene: gene_diplotype.gene, phenotype: gene_diplotype.phenotype})
          geneDiplotypes.push(gene_diplotype)
        })
      })
      updateGenes($scope.testType)
    })

  $scope.setReportURL = function() {
    const version = $scope.reportVersion;
    const query = []
    if (version) {
      const host = encodeURIComponent(`${prodAssetHost}${version}`)
      query.push(`host=${host}`)
    }
    if ($scope.pdf) {
      query.push(`pdf=True`)
    }
    if (query.length) {
      $scope.reportURL = `${baseReportURL}?${query.join('&')}`
    } else {
      $scope.reportURL = baseReportURL
    }
  }

  const params = $location.search()

  /**
   * Reset the editor back to "default" settings by
   * destroying it and re-initializing it with the
   * original "initial" data
   */
  $scope.resetSelections = function() {
    setDefaultValues();
    editor.destroy()
    initializeEditor()
    editor.setValue(initialData)
    setSexSpecificProfile()
    $scope.generateReport()
  }

  function evalValue(val, convertIntIfPossible = false) {
    // Convert boolean
    if (val.toLowerCase() == 'true') {
      return true
    } else if (val.toLowerCase() == 'false') {
      return false
    } else if (!isNaN(parseInt(val, 10)) && convertIntIfPossible) {
      return parseInt(val, 10)
    }
    return val
  }

  // Initialize default values that can be done pre-editor initialization.
  function initFromQueryParamPreEditor() {
    Object.keys(params).forEach(function(key) {
      const val = params[key]
      if (key == 'q') { // Fetch payload from existing report
        $scope.sourceReport = parseInt(val)
      } else if (key == 'r') { // Fetch payload from existing reports
        $scope.reports = val.split(',').map((x) => parseInt(x))
      } else { // Set default values from query params
        $scope[key] = evalValue(val)
      }
    })
  }

  // Initialize default values that needs to be done post-editor initialization.
  function initFromQueryParamPostEditor() {
    Object.keys(params).forEach(function(key) {
      const val = params[key]
      if (key == 'pathogenic') { // Set mutations. Expects comma delimited list of genes.
        val.split(',').forEach(function(gene) { addMutation(gene) })
      } else if (key == 'likelyPathogenic') { // Set likely pathogenic mutations.
        val.split(',').forEach(function(gene) { addMutation(gene, 'Likely Pathogenic') })
      } else if (key == 'vus') { // Set VUS.
        val.split(',').forEach(function(gene) { addVUS(gene) })
      } else if (key == 'vusp') { // Set VUS-P.
        val.split(',').forEach(function(gene) { addVUS(gene, true) })
      } else if (key == 'trueNegative') { // Set true negatives.
        val.split(',').forEach(function(gene) { addTrueNegative(gene) })
      } else if (key == 'incidentalFinding') { // Set incidental findings.
        val.split(',').forEach(function(gene) { addIncidentalFinding(gene) })
      } else if (key == 'phenotypes') {
        val.split(',').forEach(function(phenotype, index) {
          updatePhenotype(testGenesCovered['pgx v1'][index], phenotype)
          updatePhenotype(testGenesCovered['pgx v3'][index], phenotype)
        })
      } else if (key == 'instantReportContentGenes') { // Set incidental findings.
        val.split(',').forEach(function(gene) { addInstantReportContentForGene(gene) })
      } else if (key.indexOf('root.') == 0) {
        // This allows us to directly modify the JSON editor.
        // For example, root.health_profile.risk_model.elevated_dutch_criteria=true
        const parts = key.split('.')
        if (parts.length < 3) {
          throw "At least three parts are expected for JSON editor param."
        }
        const thisEditor = editor.getEditor(parts.slice(0, 2).join('.'))
        const thisValue = thisEditor.getValue()
        let curValue = thisValue
        // Initialize values if not initialized yet.
        const isArrayRegex = /(\w+)\[(\d+)\]/ // e.g. root.health_profile.cancers[0]
        let curPath = parts.slice(0, 2).join('.')
        let hadArray = false
        const sliceBase = 2
        parts.slice(sliceBase, parts.length - 1).forEach(function(part, index) {
          let propName = part
          let arrayIndex = null
          if (isArrayRegex.test(part)) {
            const match = isArrayRegex.exec(part)
            propName = match[1]
            arrayIndex = parseInt(match[2])
          }
          curPath = [curPath, propName].join('.')
          if (arrayIndex == null && !curValue[propName]) {
            if (hadArray) {
              throw "We don't support complex structure that requires schema in arrays."
            }
            const schema = editor.getEditor(curPath).validators[0].schema
            schema.definitions = angular.copy($scope.schema.definitions)
            curValue[propName] = new JSONEditor(document.createElement('div'), {schema: schema}).getValue()
          } else if (arrayIndex != null && !curValue[propName][arrayIndex]) {
            hadArray = true
            let schema
            // For array cases, we cannot derive schema from editor.
            if (propName == 'family_relative_health_profiles') {
              // We use female reative health profile by default
              // We change it to male if we set the sex of the relative health profile to male
              if (parts[sliceBase + index + 1] == 'sex' && val == 'M') {
                schema = $scope.schema.definitions.MaleRelativeHealthProfile
              } else {
                schema = $scope.schema.definitions.FemaleRelativeHealthProfile
              }
            } else if (propName == 'cancers') {
              schema = $scope.schema.definitions.CommonCancerHistory
            } else if (propName == 'lab_sendouts') {
              schema = $scope.schema.definitions.LabSendout
            } else { // Only works for the first array.
              schema = editor.getEditor(curPath).schema.items.anyOf[0]
            }
            schema.definitions = angular.copy($scope.schema.definitions)
            curValue[propName][arrayIndex] = new JSONEditor(document.createElement('div'), {schema: schema}).getValue()
          }
          curValue = curValue[propName]
          if (arrayIndex != null) {
            curValue = curValue[arrayIndex]
          }
        })
        curValue[parts[parts.length - 1]] = evalValue(val, true)
        thisEditor.setValue(thisValue)
      }
    })
  }

  initFromQueryParamPreEditor()

  $scope.setReportURL()

  // allow page to preload report info from an existing report
  let initialDataPromise = $q.when({
    data: {
      user: {
        sex: $scope.sex,
        email: auth.currentUser.email,
        phone_number: '(844) 362-6567',
        patient_id: 1,
      },
      test_information: {
        test_type: $scope.testType,
        clinical_domain: clinicalDomain[$scope.testType],
        genes_covered: Array.from(testGenesCovered[$scope.testType]),
        test_outcome: 'negative',
        test_methodology_version: $scope.testMethodology,
        show_vus_details: $scope.showVusDetails
      }
    }
  })

  if ($scope.sourceReport) {
    $scope.reports.push($scope.sourceReport)
    initialDataPromise = $http.get('api/v1/report_info/' + $scope.sourceReport + '?preview=1')
  }

  $scope.generateReport = function() {
    editor.root.refreshValue();
    const data = editor.getValue();

    $http.post('api/v1/generate_v2_report_from_report_info', data)
      .then((res) => {
        $scope.setReportURL()
        const iframe = angular.element('#reportFrame')[0]

        $scope.iframeErrors = [];
        const store = iframe.contentWindow.ReportStore
        try {
          store.update(res.data)
          reportShareFamilyData.msgMarkdownSampleLetter = ''
          if (res.data.components && 'share_family_letter' in res.data.components) {
            res.data.components.share_family_letter.content.map((c) => {
              reportShareFamilyData.msgMarkdownSampleLetter += marked(c)
            })
          }
        } catch (error) {
          $scope.iframeErrors.push(error + '\n' + error.stack)
        }
      })
      .catch((error) => {
        let msg = angular.toJson(error.data)
        if (error.data['non_field_errors']) {
          msg = error.data['non_field_errors'].join(', ')
        }
        $scope.iframeErrors.push("error generating report:\n" + msg)
      })
  }

  // Capture errors from iframe by 1. overriding console.error
  // and 2. adding an error event handler
  $scope.iframeErrors = []
  const framePromise = $q.defer()
  const reportShareFamilyData = {
    msgMarkdownSampleLetter: 'Sample letter.'
  }
  let initialLoad = true
  $scope.reportFrameOnload = function() {
    const iframe = angular.element('#reportFrame')[0]
    const originalError = iframe.contentWindow.console.error
    iframe.contentWindow.console.error = (e) => {
      $scope.iframeErrors.push(arguments[0])
      originalError.apply(arguments)
    }
    iframe.contentWindow.addEventListener('error', (e) => {
      $scope.iframeErrors.push(e)
    })
    iframe.contentWindow.addEventListener('click', (e) => {
      const testType = editor.getEditor('root.test_information.test_type').getValue()
      handleReportClick(
        e, reportShareFamilyData, testType, "1234", "")
    })
    if (initialLoad) {
      framePromise.resolve()
      initialLoad = false
    } else {
      $scope.generateReport()
    }

    if ($scope.fullScreen) {
      window.iFrameResize({
        checkOrigin: false,
        heightCalculationMethod: 'documentElementOffset',
      }, '#reportFrame')
    }
  }

  // Generate report after everything is loaded
  $q.all([initialDataPromise, schemaPromise, framePromise.promise, pgxGeneInterpretationsPromise])
    .then((result) => {
      // in order to correctly set anyOf fields, we temporarily disable
      // required by default.
      if ($scope.sourceReport) {
        editor.options.required_by_default = false
      }

      initialData = result[0].data // Make this available on controller scope so we can reset the editor
      editor.setValue(initialData);
      editor.options.required_by_default = true

      if ($scope.testType === 'pgx v1' || $scope.testType === 'pgx v3') {
        editor.root.refreshValue()
        const geneDiplotypesEditor = editor.getEditor('root.gene_diplotypes')
        const baselineGeneDiplotypes = geneDiplotypes.filter(geneDiplotype => geneDiplotype.is_baseline)
        geneDiplotypesEditor.setValue(baselineGeneDiplotypes)
      }

      setSexSpecificProfile()
      initFromQueryParamPostEditor()
    })
    .then(() => { $scope.generateReport() })

  function updateGenes(testType) {
    $scope.genes = angular.copy(testGenesCovered[testType])
    $scope.quickFinding = $scope.genes[0]
    $scope.quickGene = $scope.genes[0]
    $scope.relativeVariant = $scope.genes[0]
    $scope.instantReportGene = $scope.genes[0]
  }

  // QUICK SET
  $scope.testTypes = Object.keys(testGenesCovered)
  $scope.sexOptions = ['F', 'M']
  $scope.showVusDetailsOptions = [true, false]
  updateGenes($scope.testType)

  function addMutation(gene, classification = 'Pathogenic') {
    const testTypeEditor = editor.getEditor('root.test_information')
    const testTypeValue = testTypeEditor.getValue()
    testTypeValue['test_outcome'] = 'positive'
    testTypeEditor.setValue(testTypeValue)
    const mutationEditor = editor.getEditor('root.gene_mutations')
    const mutations = mutationEditor.getValue()
    const mutation = {
      'classification': classification,
      'nomenclatures': dummyNomenclature,
      'variant_id': 0,
      'zygosity': 'Heterozygous',
      'criteria': [{
        'description': 'This is a test supporting evidence. You will see real one on production.',
      }]
    }
    let allelicity = '1';

    // Allow specific mutation name to be specified. e.g. BRCA1(R1699Q)
    let r = /(.+)\((.*)\)/g
    let match = r.exec(gene)
    if (match) {
      gene = match[1]
      mutation.mutation_name = match[2]
    }

    // Allow zygosity to be specified. e.g. BRCA1[Homozygous]
    r = /(.+)\[(.*)\]/g
    match = r.exec(gene)
    if (match) {
      gene = match[1]
      mutation.zygosity = match[2]
      if (mutation.zygosity == 'Homozygous') {
        allelicity = '2'
      }
    }

    // Allow allelicity to be specified. e.g. BRCA1{1}
    r = /(.+)\{(.*)\}/g
    match = r.exec(gene)
    if (match) {
      gene = match[1]
      allelicity = match[2]
    }

    const is_carrier = allelicity == '1' && gene == 'GLA' && $scope.sex == 'F';
    let updatedExisting = false
    for (let i = 0; i < mutations.length; i++) {
      if (mutations[i].gene == gene) {
        mutation.variant_id = mutations[i].mutations.length //make unique variant_ids
        mutations[i].mutations.push(mutation)
        mutations[i].allelicity = allelicity
        mutations[i].is_carrier = is_carrier
        updatedExisting = true
      }
    }

    if (!updatedExisting) {
      mutations.push({'gene': gene, 'allelicity': allelicity, 'mutations': [mutation], 'is_carrier': is_carrier})
    }

    mutationEditor.setValue(mutations)
  }

  function addInstantReportContentForGene(gene) {
    const instantReportContentEditor = editor.getEditor('root.instant_report_content')
    const instantReportContent = instantReportContentEditor.getValue()
    const irc = {
      'gene': gene,
      'summary': 'This is the instant report content that is overriding the summary section.',
      'notes': 'This is the instant report content that is overriding the notes section.',
      'title': 'This is the instant report content that is overriding the title section.',
      'additional_findings': 'This is the instant report content that is overriding the additional findings section.',
    }
    instantReportContent.push(irc)
    instantReportContentEditor.setValue(instantReportContent)
  }

  $scope.quickAddInstantReportContent = function(gene) {
    addInstantReportContentForGene(gene)
    $scope.generateReport()
  }

  $scope.quickAddMutation = function(gene, classification = 'Pathogenic') {
    addMutation(gene, classification)
    $scope.generateReport()
  }

  function addVUS(gene, vuspNote) {
    const mutationEditor = editor.getEditor('root.gene_mutations')
    const mutations = mutationEditor.getValue()
    const mutation = {
      'classification': 'Variant of Uncertain Significance',
      'nomenclatures': dummyNomenclature
    }
    if (vuspNote) {
      mutation['vusp_notes'] = 'This is an example VUS-P note.'
    }
    let updatedExisting = false
    for (let i = 0; i < mutations.length; i++) {
      if (mutations[i].gene == gene) {
        mutations[i].mutations.push(mutation)
        updatedExisting = true
      }
    }
    if (!updatedExisting) {
      mutations.push({'gene': gene, 'mutations': [mutation]})
    }

    mutationEditor.setValue(mutations)
  }

  $scope.quickAddVUS = function(gene, vuspNote) {
    addVUS(gene, vuspNote)
    $scope.generateReport()
  }

  $scope.quickSetTestType = function() {
    const testTypeEditor = editor.getEditor('root.test_information')
    const testInfo = testTypeEditor.getValue()
    testInfo.test_type = $scope.testType
    testInfo.clinical_domain = clinicalDomain[$scope.testType]
    testInfo.genes_covered = Array.from(testGenesCovered[$scope.testType])
    testTypeEditor.setValue(testInfo)
    updateGenes($scope.testType)

    $scope.generateReport()
  }

  $scope.quickSetShowVusDetails = function() {
    const testTypeEditor = editor.getEditor('root.test_information')
    const testInfo = testTypeEditor.getValue()
    testInfo.show_vus_details = $scope.showVusDetails;
    testTypeEditor.setValue(testInfo)
    $scope.generateReport()
  }

  function addIncidentalFinding(gene) {
    const mutationEditor = editor.getEditor('root.incidental_findings')
    const mutations = mutationEditor.getValue()
    const mutation = {
      'classification': '',
      'nomenclatures': dummyNomenclature,
      'variant_type': 'somatic'
    }
    let updatedExisting = false
    for (let i = 0; i < mutations.length; i++) {
      if (mutations[i].gene == gene) {
        mutations[i].mutations.push(mutation)
        updatedExisting = true
      }
    }
    if (!updatedExisting) {
      mutations.push({'gene': gene, 'mutations': [mutation]})
    }

    mutationEditor.setValue(mutations)
  }

  $scope.quickAddIncidentalFinding = function(gene) {
    addIncidentalFinding(gene)
    $scope.generateReport()
  }

  function addTrueNegative(gene) {
    const variantsEditor = editor.getEditor('root.reported_variants_in_relatives')
    const variants = variantsEditor.getValue()
    variants.push({is_true_negative: true, gene: gene, nomenclatures: dummyNomenclature})
    variantsEditor.setValue(variants)
  }

  $scope.quickAddTrueNegative = function(gene) {
    addTrueNegative(gene)
    $scope.generateReport()
  }

  function setSexSpecificProfile() {
    // Change sex specific health profile.
    // We use json editor to initialize the profile based on the schema.
    const profileSchema = $scope.sex == 'F' ? $scope.schema.definitions.FemaleHealthProfile : $scope.schema.definitions.MaleHealthProfile
    // The schema depends on the root definitions for the schema.
    profileSchema.definitions = angular.copy($scope.schema.definitions)
    const sexSpecificProfileEditor = new JSONEditor(document.createElement('div'), {schema: profileSchema})
    editor.getEditor('root.health_profile').setValue(sexSpecificProfileEditor.getValue())
  }

  $scope.quickSetSex = function() {
    editor.getEditor('root.user.sex').setValue($scope.sex)
    setSexSpecificProfile()
    $scope.generateReport()
  }

  $scope.quickSetTestMethodology = function() {
    editor.getEditor('root.test_information.test_methodology_version').setValue($scope.testMethodology)
    $scope.generateReport()
  }

  $scope.loadReport = function(reportID) {
    if (!$scope.sourceReport && !reportID) {
      return
    }
    if (!reportID) {
      reportID = $scope.sourceReport
    } else {
      $scope.sourceReport = reportID
    }
    $http.get('api/v1/report_info/' + reportID + '?preview=1')
      .then((result) => {
        if ($scope.reports.indexOf(reportID) < 0) {
          $scope.reports.push(reportID)
        }
        // in order to correctly set anyOf fields, we temporarily disable
        // required by default.
        editor.options.required_by_default = false
        const note = result.data.note
        if (note) {
          delete result.data.note
          $scope.reportNote = note
        } else {
          $scope.reportNote = ''
        }

        editor.setValue(result.data);
        editor.options.required_by_default = true
        $scope.generateReport()
      })
  }

  $scope.viewPdf = function() {
    editor.root.refreshValue();
    const data = editor.getValue();
    $http.post('api/v1/create_report_from_report_info', data)
      .then(function(res) {
        const params = new URLSearchParams(window.location.search);
        window.open(`/report/sample_pdf_report/${res.data.id}?${params}`)
      })
  }

  function updatePhenotype(gene, phenotype) {
    phenotype = phenotype.phenotype || phenotype
    editor.root.refreshValue();
    const geneDiplotypesEditor = editor.getEditor('root.gene_diplotypes')
    const newGeneDiplotype = geneDiplotypes.filter(geneDiplotype => geneDiplotype.gene === gene && geneDiplotype.phenotype === phenotype)[0]
    const currentGeneDiplotypes = geneDiplotypesEditor.getValue()

    currentGeneDiplotypes.push(newGeneDiplotype)
    geneDiplotypesEditor.setValue(currentGeneDiplotypes)
  }

  $scope.quickUpdatePhenotype = function(gene, phenotype) {
    updatePhenotype(gene, phenotype)
    $scope.generateReport()
  }
}
