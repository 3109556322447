const cdnify = require('monoweb/django_apps/core/static/js/cdnify')

!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrProvider
   *
   * @description
   * The `clrProvider` provides providers for color app.
   */
  const module = angular.module('clrProvider', ['metatags'])

  /**
   * @ngdoc provider
   * @name colorRouteProvider
   * @memberof clrProvider
   *
   * @description
   *
   * `colorRouteProvider` extends `$routeProvider`'s `when` method by
   * adding default `resolve` properties with `auth` so that `auth`
   * is always synced after route change is succeeded. Also incorporates
   * `MetaTagsProvider` to handle meta data when changing routes.
   */
  module.provider('colorRoute', function($routeProvider, MetaTagsProvider) {

    let metaTagDefaults = {}
    const colorRouteProvider = angular.extend(this, $routeProvider)

    colorRouteProvider.when = function(path, route) {
      route.resolve = angular.extend({}, route.resolve)
      route.resolve['_auth_'] = [
        '$location', '$q', 'auth', 'colorAuthRouteFilter',
        function($location, $q, auth, colorAuthRouteFilter) {
          return auth.sync(auth.isLoggedIn && !auth.currentUser.is_email_confirmed)
            .then(function(res) {
              if (!route.redirectTo) {
                if (colorAuthRouteFilter.apply(route.auth, $location.path())) {
                  return $q.reject()
                }
              }
              return res
            })
        }]

      $routeProvider.when(path, route)

      /* Ignore $routeProvider redirects and expect seo object to exist,
         * otherwise use some defaults */
      const metaTagsWithPath = {
        url: path,
        image: cdnify('social/logo_opaque_padded.png'),
      }
      if (angular.isDefined(route.seo)) {
        MetaTagsProvider.when(path, angular.extend(metaTagsWithPath, metaTagDefaults, route.seo))
      } else {
        MetaTagsProvider.when(path, angular.extend(metaTagsWithPath, metaTagDefaults))
      }
      return this
    }

    colorRouteProvider.setMetaTagDefaults = function(defaults) {
      metaTagDefaults = defaults
    }

    return colorRouteProvider

  })

  module.run(function($rootScope, $route, MetaTags) {

    // Init `<meta>` changes and fire update() when route changes
    MetaTags.initialize()
    $rootScope.$on('$routeChangeSuccess', function(newVal, oldVal) {
      if (oldVal !== newVal) {
        let path = $route.current.originalPath
        if (path && path.length > 1 && path[path.length - 1] == '/') {
          path = path.substring(0, path.length - 1)
        }
        if (path) {
          MetaTags.update(path)
        }
      }
    })
    $rootScope.$on('$locationSkipReloadSuccess', function(event, lastRoute, path) {
      const nextRoute = $route.routes[path]
      if (angular.isDefined(nextRoute) && nextRoute.seo) {
        MetaTags.update(path)
      }
    })
  })

}();
