module.exports = angular.module('resultsControllers', [])
  .controller('FaxListController', require('./controllers/fax_list_controller'))
  .controller('PreResultsController', require('./controllers/pre-results_controller'))
  .controller('ReportReviewController', require('./controllers/report_review_controller'))
  .controller('ReportReviewListController', require('./controllers/report_review_list_controller'))
  .controller('CovidReportListController', require('./controllers/covid_report_list_controller'))
  .controller('ReportController', require('./controllers/report_controller'))
  .controller('ReportWrapperController', require('./controllers/report_wrapper_controller'))
  .controller('ReportWrapperV1Controller', require('./controllers/report_wrapper_v1_controller'))
  .controller('AppointmentController', require('./controllers/appointment_controller'))
