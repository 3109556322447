"use strict"

/**
 * Validates input value is unique in given array.
 */
module.exports = function() {
  "ngInject"

  return {
    require: 'ngModel',
    link: function(scope, elem, attrs, ctrl) {
      ctrl.$parsers.unshift(function(viewValue) {
        if (scope[attrs.unique].indexOf(viewValue) > -1) {
          ctrl.$setValidity('unique', false)
          return undefined
        } else {
          ctrl.$setValidity('unique', true)
          return viewValue
        }
      })
    }
  }
}
