!function() {

  'use strict'

  const module = angular.module('familyTestingFilters', [])

  module.filter('geneMutations', function() {
    return function(genes) {
      let text = ''
      if (genes.length == 1) {
        text += genes[0]
      } else if (genes.length == 2) {
        text += genes[0] + ' and ' + genes[1]
      } else {
        text += genes.slice(0, -1).join(', ')
        text += ', and ' + genes.slice(-1)
      }
      text += ' mutation'
      if (genes.length > 1) {
        text += 's'
      }
      return text
    }
  })

  module.filter('relativeAdjective', function() {
    return function(relationship) {
      if (relationship == 'mother') {
        return 'maternal'
      }
      if (relationship == 'father') {
        return 'paternal'
      }
    }
  })
}();
