'use strict'

module.exports = function($compile) {
  'ngInject'

  return {
    restrict: 'E',
    require: '^clrBreadcrumbs',
    scope: {
      first: '=?',
      whenStep: '=',
      linkWhenInactive: '=?'
    },
    transclude: true,
    templateUrl: '/directives/clr_breadcrumb.html',
    link: function(scope, elem, attrs, breadcrumbsCtrl) {
      let latestStepValue
      const update = function(watchedValue, actualValue) {
        const active = watchedValue == actualValue
        scope.active = active
        elem.find('.displayed-text').toggleClass('js--active', active)
      }

      const registration = breadcrumbsCtrl.register(function(stepValue) {
        latestStepValue = stepValue
        update(scope.whenStep, stepValue)
      })

      scope.$watch('whenStep', function(watchedStepValue) {
        update(watchedStepValue, latestStepValue)
      })

      scope.$on('$destroy', function() {
        breadcrumbsCtrl.unregister(registration)
      })
    }
  }
}
