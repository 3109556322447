/**
 * @prettier
 */

module.exports = function sharedOrderResults() {
  return {
    restrict: 'E',
    scope: {
      order: '=',
    },
    templateUrl: '/ordering_physicians/directives/shared_order_results.html',
    controller: function($scope, $http, ProviderPlatformModals, showMedicationPromo, $window) {
      'ngInject';

      $scope.$watch('$ctrl.order', order => {
        if (order) {
          this.resultsUrl = `/color_results/${order.order_number}`;
          $scope.openFTPModal = function() {
            ProviderPlatformModals.openFTPOneClickModal(order.order_number);
          };
        }
      });
    },
    controllerAs: '$ctrl',
    bindToController: true,
  };
};
