'use strict'

/**
 * @prettier
 */

module.exports = function($scope, RawDataFile) {
  'ngInject'
  const barcode_to_properties = {}
  $scope.returnedData = RawDataFile.get().$promise.then(function(res) {
    for (const returned_data_info of res) {
      const barcode = returned_data_info.kit_barcode
      if (barcode in barcode_to_properties) {
        barcode_to_properties[barcode].push(returned_data_info)
      } else {
        barcode_to_properties[barcode] = [returned_data_info]
      }
    }

    const to_return = []
    for (const barcode in barcode_to_properties) {
      if (barcode_to_properties.hasOwnProperty(barcode)) {
        to_return.push({'kit_barcode': barcode, 'items': barcode_to_properties[barcode]})
      }
    }
    $scope.returned_data = to_return
  })
}
