module.exports = function placeTestType(ProviderPlatformModals, kioCountries) {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      userProvider: '=',
      order: '='
    },
    templateUrl: '/ordering_physicians/directives/place_test_type.html',
    link: function(scope, element, attrs, formCtrl) {
      const kioNotAllowed = scope.userProvider && kioCountries.indexOf(scope.userProvider.country) == -1
      const TEST_TYPES = {
        SAMPLE_ORDERED: 'sample_ordered',
        SAMPLE_AT_OFFICE: 'sample_at_office'
      }

      scope.types = [
        TEST_TYPES.SAMPLE_AT_OFFICE,
        TEST_TYPES.SAMPLE_ORDERED
      ]

      scope.selectType = function(selectedType) {
        if (kioNotAllowed && selectedType === TEST_TYPES.SAMPLE_AT_OFFICE) {
          ProviderPlatformModals.openKioOptionNotAvailableModal()
        } else {
          scope.order.type = selectedType
        }
      }
    }
  }
}
