/**
 * @prettier
 */

module.exports = function reportOpenOrderDetailsModal(reportOrderDetailsModal) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      report: '=',
      reportContent: '=',
    },
    link: function(scope, elem) {
      elem.bind('click', function() {
        reportOrderDetailsModal.open(scope.reportContent, scope.report);
      });
    },
  };
};
