module.exports = function getEmailProvider(auth) {
  'ngInject'

  const domainProviderMap = {
    'gmail.com': { name: 'Gmail', url: 'https://www.gmail.com' },
    'yahoo.com': { name: 'Yahoo', url: 'https://mg.mail.yahoo.com' },
    'hotmail.com': { name: 'Hotmail', url: 'https://www.hotmail.com' },
    'aol.com': { name: 'AOL', url: 'https://mail.aol.com' },
    'comcast.net': { name: 'Comcast', url: 'https://login.comcast.net' }
  }

  return function() {
    const domain = auth.currentUser.email.split('@')[1]
    return domainProviderMap[domain]
  }
}
