/**
 * @prettier
 */

module.exports = function(
  $q,
  $translate,
  $http,
  $filter,
  resolvedPromise,
  ngToast,
  claimStore,
  HealthcareProvider,
  PhysicianOrder,
  promoCodeStore,
  requisitionStore,
  sku,
  textModal
) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      physicianOrder: '=',
      withoutHealthcareProviders: '=',
      healthcareProviders: '=',
      withColorOrderingPhysician: '=',
      sendCopyToHealthcareProviders: '=',
      onPurchase: '=?',
      onSubmit: '=',
      skuTestTypes: '=',
      onChangeSku: '=?',
      existingPhysicianOrder: '=?',
    },
    templateUrl: '/kit_orders/directives/clr_ordering_physician_form.html',
    link: function(scope, element, attrs, formCtrl) {
      // default to populated requisition number if cookie is present
      const storedRequisition = requisitionStore.get();
      if (storedRequisition) {
        scope.withColorOrderingPhysician = false;
        scope.physicianOrder.requisition_number = storedRequisition;
        scope.physicianOrder.$get();
      }

      // we allow up to two additional healthcare providers
      scope.canAddHealthcareProvider = function() {
        return (
          scope.healthcareProviders.filter(function(provider) {
            return !provider.id && !provider.provider_id;
          }).length < 2
        );
      };

      scope.addHealthcareProvider = function() {
        if (!scope.canAddHealthcareProvider()) {
          throw new Error('Cannot add another healthcare provider.');
        }
        scope.healthcareProviders.push(new HealthcareProvider());
      };

      scope.removeHealthcareProvider = function(index) {
        scope.healthcareProviders.splice(index, 1);
        if (scope.healthcareProviders.length == 0) {
          scope.addHealthcareProvider();
        }
      };

      // ensure there is at least one healthcare provider to edit when
      // sendCopyToHealthcareProviders is toggled.
      scope.$watch('sendCopyToHealthcareProviders', function(sendCopy) {
        if (sendCopy && scope.healthcareProviders.length == 0) {
          scope.addHealthcareProvider();
        }
      });

      scope.$watch('physicianOrder.promocode_for_payment', function(promocode) {
        if (promocode) {
          promoCodeStore.updateCode(promocode);
        }
      });

      scope.$watch('physicianOrder.claim_for_payment', function(claim) {
        if (claim) {
          claimStore.setClaim(claim);
        }
      });

      scope.applyRequisitionCode = function(callback) {
        if (!scope.physicianOrder.requisition_number || scope.withColorOrderingPhysician) {
          if (callback) {
            return $q.when(callback());
          }
          return resolvedPromise();
        }

        function setPhysicianOrder(data) {
          for (const key in data) {
            scope.physicianOrder[key] = data[key];
          }
        }

        function openTestTypeMismatchModal(testRequested) {
          const skuTests = $filter('joinEnglishList')(
            scope.skuTestTypes.map(function(testType) {
              return 'the ' + $filter('translate')('SKU.' + testType);
            })
          );
          return $translate(
            [
              'ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.T',
              'ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.P',
              'ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.BUTTON',
            ],
            {
              currentTest: sku[scope.skuTestTypes[0]].productName,
              orderedTest: sku[testRequested].productName,
              testDesc:
                /* eslint-disable indent */
                scope.skuTestTypes.length > 1
                  ? $filter('translate')(
                      'ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.P_TEST_DESC',
                      { tests: skuTests }
                    )
                  : '',
              /* eslint-enable indent */
            }
          ).then(function(translations) {
            let buttonText = '';
            if (scope.onChangeSku) {
              buttonText = translations['ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.BUTTON'];
            }
            return textModal.open(
              translations['ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.T'],
              translations['ORDERING_PHYSICIAN.HEALTH_CONDITION_MISMATCH.P'],
              buttonText
            ).result;
          });
        }

        return $http
          .get('/api/v1/physician_orders/' + scope.physicianOrder.requisition_number)
          .then(function(res) {
            const data = res.data;
            if (data.state != 'claimable' || data.type != 'sample_ordered') {
              ngToast.create({ className: 'error', content: msgRedeemedRequisition });
              scope.clearRequisitionCode(true);
            } else if (
              !$filter('eqSet')(
                new Set(sku[data.test_requested].testTypes),
                new Set(scope.skuTestTypes)
              )
            ) {
              return openTestTypeMismatchModal(data.test_requested)
                .then(function() {
                  if (scope.onChangeSku) {
                    scope.onChangeSku(data);
                    setPhysicianOrder(data);
                  }
                })
                .catch(function() {
                  scope.clearRequisitionCode(true);
                });
            } else {
              setPhysicianOrder(data);
              if (callback) {
                return callback();
              }
            }
          })
          .catch(() =>
            ngToast.create({
              className: 'error',
              content: msgUnknownRequisition,
            })
          );
      };

      scope.clearRequisitionCode = function(keepInput) {
        requisitionStore.clear();
        const oldRequisitionNumber = scope.physicianOrder.requisition_number;
        scope.physicianOrder = new PhysicianOrder();
        if (keepInput) {
          scope.physicianOrder.requisition_number = oldRequisitionNumber;
        }
      };

      // loops over profiles in physicianOrder and returns all formal names
      scope.requisitionProviders = function() {
        const profiles = ['placed_by_profile', 'ordering_physician_profile'];
        const providers = [];
        for (let i = 0; i < profiles.length; i++) {
          const profile = scope.physicianOrder[profiles[i]];
          if (profile && providers.indexOf(profile.formal_name) < 0) {
            providers.push(profile.formal_name);
          }
        }
        return providers;
      };

      scope.submitting = false;
      scope.goToNext = function() {
        scope.submitting = true;
        return scope.applyRequisitionCode(scope.onSubmit).finally(function() {
          scope.submitting = false;
        });
      };

      let msgUnknownRequisition;
      let msgRedeemedRequisition;
      $translate([
        'ORDERING_PHYSICIAN.TOASTS.REDEEMED_REQUISITION',
        'ORDERING_PHYSICIAN.TOASTS.UNKNOWN_REQUISITION',
      ]).then(function(translations) {
        msgRedeemedRequisition = translations['ORDERING_PHYSICIAN.TOASTS.REDEEMED_REQUISITION'];
        msgUnknownRequisition = translations['ORDERING_PHYSICIAN.TOASTS.UNKNOWN_REQUISITION'];
      });
    },
  };
};
