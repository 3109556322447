/**
 * @ngdoc filter
 * @name commonRiskAge
 *
 * @description
 * Returns common risk age for a given report.
 */

'use strict'

module.exports = function($filter) {
  'ngInject'

  const ageGenes = [
    { age: 95, geneSets: [['APC', 'TP53'], ['MUTYH', 'TP53'], ['BRIP1', 'MITF']] },
    { age: 80, geneSets: [['APC'], ['MUTYH'], ['APC', 'ATM'], ['APC', 'MITF'], ['BARD1', 'CDKN2A'], ['BARD1', 'MITF'], ['BARD1', 'MUTYH'], ['BRCA1', 'BRIP1'], ['BRCA1', 'GREM1'], ['BRCA1', 'MITF'], ['BRCA1', 'MUTYH'], ['BRCA1', 'APC'], ['BARD1', 'APC'], ['BARD1', 'BRCA1'], ['BRIP1', 'MUTYH'], ['BRCA1', 'RAD51C'], ['CDKN2A', 'CHEK2'], ['CDKN2A', 'PALB2'], ['MITF', 'MUTYH'], ['MUTYH', 'RAD51D'], ['BRCA1', 'MSH6'], ['BRCA1', 'MSH2'], ['APC', 'RAD51D'], ['APC', 'ATM'], ['APC', 'MUTYH'], ['APC', 'BRIP1'], ['BMPR1A', 'CHEK2']] },
    { age: 70, geneSets: [['STK11'], ['ATM'], ['CHEK2'], ['BARD1', 'CHEK2'], ['BRCA2', 'CDKN2A'], ['APC', 'EPCAM', 'MUTYH'], ['APC', 'ATM', 'MSH6'], ['PALB2']] } // STK11 common age is 70, but it also needs special work to bring in age 65 risks, see "hasAgeExceptionsAt"
  ]

  return function(genes, risks) {
    for (let i = 0; i < ageGenes.length; i++) {
      const config = ageGenes[i];
      const geneMatches = config.geneSets.some(function(genesToMatch) {
        return $filter('containsAll')(genes, genesToMatch) && $filter('containsAll')(genesToMatch, genes)
      })
      if (geneMatches) {
        return config.age
      }
    }
    if (genes.length > 1) {
      return 70
    } else if (risks && risks.length) {
      return $filter('orderBy')(risks[0].data, '-age')[0].age
    }
    return null
  }
}
