"use strict"

module.exports = function ($rootScope, $timeout, $location) {
  "ngInject"

  return {
    restrict: 'A',
    scope: false,
    link: function (scope, elem, attrs) {

      const waypoint = new Waypoint({
        // Unwrap the jQuery object
        element: elem[0],
        // Fire when the top of the element hits the bottom of the viewport
        offset: 68,
        // Disable waypoint until the element is ready.
        enabled: false,
        // trigger setActiveTab on clr-tab-simple directive when these waypoints are hit
        handler: function (direction) {
          $rootScope.$broadcast('clrTabWaypoint:setActiveTab', attrs.clrTabSimpleWaypoint)
        }
      })

      elem.ready(function () {
        $timeout(function () {
          waypoint.enable()
        }, 250)
      })

      scope.$on('$destroy', function () {
        waypoint.destroy()
      })

    }
  }
}
