'use strict'

module.exports = function($filter, interpretedGenes) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/summary_text.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.notes = []
      // Negative
      if (scope.reportContent.is_negative) {
        // Paragraphs
        scope.paragraphs = ['FH_REPORT.SUMMARY_TEXT.NEGATIVE.P1']
        // List of genes
        scope.listOfGenes = interpretedGenes[scope.reportContent.template.test_type].map(function(gene) {
          return '<em>' + gene + '</em>'
        }).join(', ')
        // Notes: All
        scope.notes.push('FH_REPORT.SUMMARY_TEXT.NOTES.NEGATIVE')
        // Notes: Dutch criteria score of 6 or greater
        const profile = scope.reportContent.health_profile
        if (profile && profile.has_elevated_dutch_criteria_score) {
          scope.notes.push('FH_REPORT.SUMMARY_TEXT.NOTES.DUTCH_CRITERIA')
        }
      // Positive
      } else if (scope.reportContent.is_positive) {
        const mutations = $filter('pathogenicMutations')(scope.reportContent.mutations)
        const genes = scope.reportContent.genes_with_pathogenic_mutations
        if (genes.length > 1) {
          throw new Error("Only single gene is currently supported.")
        }

        const allelicity = scope.reportContent.allelicity_by_gene[genes[0]]
        scope.values = {
          gene: genes[0],
          classification: mutations[0].classification.toLowerCase(),
          capitalizedClassification: mutations[0].classification }

        // Paragraphs
        scope.paragraphs = []

        // Single het or hom
        if (mutations.length == 1) {
          if (mutations[0].zygosity == "Heterozygous") {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P1_HET')
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P2_HET')
          } else {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P1_HOM')
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P2_HOM')
          }
        // Compound het unknown phase
        } else if (mutations.length == 2 && genes.length == 1 && !allelicity) {
          if (mutations[0].classification == mutations[1].classification) {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P1_UNKNOWN_PHASE_P_OR_LP')
          } else {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P1_UNKNOWN_PHASE_P_AND_LP')
          }
          scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P2_HET')
          scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P3_UNKNOWN_PHASE')
        } else {
          throw new Error("Only single mutation or compound het unknown phase is currently supported.")
        }

        if (['APOB', 'PCSK9'].indexOf(genes[0]) > -1) {
          // Known phase
          if (allelicity) {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P4')
          // Unknown phase
          } else {
            scope.paragraphs.push('FH_REPORT.SUMMARY_TEXT.POSITIVE.P4_UNKNOWN_PHASE')
          }
        }

        // Notes: All
        const user = scope.reportContent.user
        if (user && user.is_minor) {
          scope.notes.push('FH_REPORT.SUMMARY_TEXT.NOTES.MINOR_POSITIVE')
        } else {
          scope.notes.push('FH_REPORT.SUMMARY_TEXT.NOTES.POSITIVE')
        }
      }
    }
  }
}
