/**
 * @prettier
 */

const E164Regex = require('monoweb/django_apps/core/static/js/e164_regex').E164Regex;

!(function() {
  'use strict';

  /**
   * @ngdoc module
   * @name clrPhoneNumbers
   *
   * @description
   * Some helpers for parsing and formatting phone numbers in JavaScript.
   */
  const module = angular.module('clrPhoneNumbers', []);

  module.constant('E164Regex', E164Regex);
})();
