module.exports = function ProviderAccountCreationController(
  $rootScope, $scope, $location, $routeParams, auth, Account, ProviderAccountFlow,
  ProviderAccount, requestErrorToast, ColorProviderPlatformInvitation, waffle
) {
  'ngInject'

  $scope.account = ProviderAccount.get()

  if (!$scope.account.provider_profile) {
    $scope.account.provider_profile = {}
  }

  if (!$scope.account.provider_profile.country) {
    $scope.account.provider_profile.country = 'US'
  }

  // If there is a query param with key ``token``, then this is an invited
  // provider. The token is a unique key pointing to an invitation, and
  // the invitation is linked to an already-verified provider.

  const token = $location.search().token || $routeParams.token
  const emailConfirmationToken = $location.search().t

  if (token) {
    // Fetch account
    ColorProviderPlatformInvitation.get({ token: token.toLowerCase() }).$promise
      .then(function(invitation) {
        $scope.invitation = invitation
        $scope.institution = invitation.institution

        const providerProfile = invitation.provider_profile
        providerProfile // Accessing the variable somehow forces a repaint of the primaryRole select
        $scope.account.first_name = providerProfile.first_name
        $scope.account.last_name = providerProfile.last_name
        $scope.account.email = providerProfile.email
        $scope.account.provider_profile = providerProfile

        $scope.account.invitation = {
          token: invitation.token
        }
      })
      .catch(function(response) {
        // TODO: Alert the user in a better way
        console.log(response)
      })
  }

  if (emailConfirmationToken) {
    $scope.account.t = emailConfirmationToken
  }

  // The below behavior (prefilled provider form) is deprecated.

  if ($location.search().prefill) {
    const user_fields = ['first_name', 'last_name', 'email']
    const provider_profile_fields = ['primary_role', 'npi', 'institution_name', 'address_line1',
      'city', 'state', 'country']

    const phone_fields = ['phone_number', 'fax_number']

    $scope.account.provider_profile = {}
    $location.search('prefill', null)

    user_fields.forEach(function(field) {
      $scope.account[field] = $location.search()[field]
      $location.search(field, null)
    })

    provider_profile_fields.forEach(function(field) {
      $scope.account.provider_profile[field] = $location.search()[field]
      $location.search(field, null)
    })

    phone_fields.forEach(function (field) {
      $scope.account.provider_profile[field] = {
        country: "US",
        national_number: $location.search()[field]
      }
      $location.search(field, null)
    })
  }

  $scope.createAccount = function() {
    // TODO: Copy original data so it is not modified.
    const payload = angular.copy($scope.account)

    if (payload.provider_profile) {
      payload.provider_profile['first_name'] = payload.first_name
      payload.provider_profile['last_name'] = payload.last_name
    }

    // Creating new resource so that password won't get wiped
    // on request fulfillment.

    return new Account(payload).$save()
      .then(function(account) {
        auth.isSynced = false
        $rootScope.$broadcast('account:created', account)
        $location.path('/providers/home').search({ welcome: 'true' })
      })
      .catch(function(res) {
        if (res.data.email) {
          ProviderAccount.set(payload)
          ProviderAccountFlow.goTo('/providers/existing-account', true)
        }
        // If the error is due to the password, show the error in a toast.
        if (res.data.password) {
          requestErrorToast({data: res.data.password[0]})
        }
      })
  }
}
