module.exports = function insuranceChecklistAffix() {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/insurance_checklist_affix.html',
    scope: {
    },
    link: function(scope, elem, attr) {
    }
  }
}
