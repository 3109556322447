/**
 * @prettier
 */

module.exports = function reportConnectWithOthers($filter, namedMutation) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_connect_with_others.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      if (scope.reportContent.is_positive && !scope.reportContent.is_somatic) {
        scope.showCard = true;
      }
      let allelicity;
      if (scope.reportContent.genes_with_pathogenic_mutations.length == 1) {
        // Handle single mutations
        //- TP53
        if ($filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['TP53'])) {
          scope.showLfs = true;
          if (scope.reportContent.gender == 'M') {
            scope.showKintalk = true;
          }
        }
        //- All genes, except CHEK2, Lynch Syndrome, MUTYH
        if (
          $filter('containsNone')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'CDKN2A',
            'CDK4',
            'PMS2',
            'MSH2',
            'MSH6',
            'APC',
            'CDH1',
            'MITF',
            'MLH1',
            'BAP1',
            'MUTYH',
            'BMPR1A',
            'EPCAM',
            'GREM1',
            'SMAD4',
            'STK11',
          ])
        ) {
          scope.showForce = true;
        }
        //- Default F
        if (
          scope.reportContent.gender == 'F' &&
          $filter('containsNone')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'ATM',
            'RAD51C',
            'RAD51D',
            'CDH1',
            'CDK4',
            'PMS2',
            'MSH2',
            'MSH6',
            'PTEN',
            'APC',
            'MITF',
            'MLH1',
            'BAP1',
            'MUTYH',
            'CDKN2A',
            'BMPR1A',
            'SMAD4',
            'EPCAM',
            'GREM1',
          ])
        ) {
          scope.showBrightPink = true;
        }
        //- PMS2, MSH2, MSH6
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'PMS2',
            'MSH2',
            'MSH6',
            'MLH1',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
        }
        //- CDKN2A, CHEK2, ATM, PMS2, MITF, MSH2, MSH6, RAD51C
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CDK4',
            'CDKN2A',
            'CHEK2',
            'ATM',
            'PMS2',
            'MITF',
            'MSH2',
            'MSH6',
            'RAD51C',
            'RAD51D',
            'MLH1',
            'BAP1',
          ])
        ) {
          scope.showKintalk = true;
        }
        // CDH1
        if ($filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['CDH1'])) {
          scope.showNoStomachForCancer = true;
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKomen = true;
          } else {
            scope.showKintalk = true;
          }
        }
        //- BRCA1 and default M, except Lynch Syndrome
        if (
          scope.reportContent.gender == 'M' &&
          $filter('containsNone')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CDKN2A',
            'APC',
            'BAP1',
            'CHEK2',
            'BMPR1A',
            'SMAD4',
            'PMS2',
            'MSH2',
            'MSH6',
            'CDH1',
            'CDK4',
            'MLH1',
            'MITF',
            'EPCAM',
            'STK11',
            'GREM1',
            'TP53',
          ])
        ) {
          scope.showHisBreastCancer = true;
        }
        // PTEN
        if ($filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['PTEN'])) {
          scope.showForce = true;
          scope.showPtenFoundation = true;
          scope.showHcc = true;
          if (scope.reportContent.gender == 'M') {
            scope.showHisBreastCancer = false;
          }
        }
        // RAD51C, RAD51D
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'RAD51C',
            'RAD51D',
          ])
        ) {
          scope.showOvarian = true;
        }
        // STK11
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['STK11'])
        ) {
          scope.showHcc = true;
          scope.showKintalk = true;
        }
        //- All genes, except CHEK2, Lynch Syndrome, TP53, RAD51C, MUTYH
        if (
          $filter('containsNone')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'CDKN2A',
            'CDK4',
            'PMS2',
            'MSH2',
            'MSH6',
            'TP53',
            'RAD51C',
            'RAD51D',
            'PTEN',
            'APC',
            'MITF',
            'CDH1',
            'MLH1',
            'BAP1',
            'MUTYH',
            'BMPR1A',
            'SMAD4',
            'EPCAM',
            'GREM1',
            'STK11',
          ])
        ) {
          scope.showKomen = true;
        }
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CDK4',
            'CDKN2A',
            'MITF',
            'BAP1',
          ])
        ) {
          scope.showAmericanMelanoma = true;
        }
        //- All genes, except Lynch Syndrome, TP53, RAD51C
        if ($filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['APC'])) {
          scope.showHcc = true;
          scope.showKintalk = true;
          scope.showColonCancerAlliance = true;
        }
        // Male + ATM, BRIP1, RAD51C, PALB2, BARD1 don't show the card
        if (
          scope.reportContent.gender == 'M' &&
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'BRIP1',
            'RAD51C',
            'PALB2',
            'BARD1',
            'RAD51D',
          ])
        ) {
          scope.showCard = false;
        }
        // CHEK2
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['CHEK2'])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKomen = true;
          } else {
            allelicity = scope.reportContent.allelicity_by_gene['CHEK2'];
            if (!allelicity) {
              // Unknown phase
              scope.showColonCancerAlliance = true;
              scope.showHcc = true;
            } else if (allelicity == 'biallelic') {
              // homozygous
              scope.showKintalk = true;
            } else {
              scope.showCard = false;
            }
          }
        }
        // MUTYH
        if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['MUTYH'])
        ) {
          allelicity = scope.reportContent.allelicity_by_gene['MUTYH'];
          if (
            !allelicity ||
            allelicity == 'biallelic' ||
            $filter('isInCis')(scope.reportContent, 'MUTYH')
          ) {
            // Unknown phase or biallelic or in cis
            scope.showColonCancerAlliance = true;
            scope.showHcc = true;
            scope.showKintalk = true;
            scope.showHisBreastCancer = false; // negate above rule that's default for M
          } else {
            scope.showCard = false;
          }
        }
        // BMPR1A
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['BMPR1A'])
        ) {
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        }
        // SMAD4
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['SMAD4'])
        ) {
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        }
        // EPCAM
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['EPCAM'])
        ) {
          scope.showLynch = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        }
        // GREM1
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['GREM1'])
        ) {
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        }
        // APC II1307K show nothing, for heterozygous and homozygous
        if ($filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)) {
          scope.showCard = false;
        }
        if (
          $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, ['CDKN2A'])
        ) {
          scope.showNpf = true;
        }
        // BRCA2 Homozygous
        if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRCA2']) &&
          ($filter('isPotentiallyCompoundHeterozygousReport')(scope.reportContent) ||
            $filter('isSingleHomozygousReport')(scope.reportContent.mutations))
        ) {
          scope.showFarf = true;
          scope.showIfar = true;
          scope.showFanconiHope = true;
        }
        // MSH6 Homozygous
        if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['MSH6']) &&
          $filter('isSingleHomozygousReport')(scope.reportContent.mutations)
        ) {
          scope.showLynch = false;
          scope.showKomen = false;
          scope.showForce = false;
          scope.showHisBreastCancer = false;
          scope.showBmmrdConsortium = true;
          scope.showKintalk = true;
          scope.showHcc = true;
        }
      } else {
        // multiple mutations
        // Handle double mutations, treated as one offs for now until a pattern is discovered
        if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'BARD1',
            'CHEK2',
            'MUTYH',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'MITF',
            'MLH1',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKomen = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'CHEK2',
            'MUTYH',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'EPCAM',
            'MUTYH',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BMPR1A',
            'CHEK2',
          ])
        ) {
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'BRCA2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'BRIP1',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showKintalk = true;
          if (
            !$filter('containsMutation')(scope.reportContent.mutations, namedMutation.brca1_r1699q)
          ) {
            if (scope.reportContent.gender == 'F') {
              scope.showBrightPink = true;
            } else {
              scope.showHisBreastCancer = true;
            }
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'CHEK2',
          ])
        ) {
          scope.showForce = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'PMS2',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showLynch = true;
          scope.showKomen = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'PALB2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'MITF',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
            scope.showKintalk = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'CDKN2A',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
          scope.showNpf = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'MSH6',
          ]) ||
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'PMS2',
          ]) ||
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'MLH1',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'RAD51D',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'RAD51C',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showOvarian = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'MLH1',
          ])
        ) {
          scope.showForce = true;
          scope.showHcc = true;
          scope.showKintalk = true;
          scope.showLynch = true;
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'TP53',
          ]) ||
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'TP53',
          ])
        ) {
          scope.showLfs = true;
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'BRCA1',
          ])
        ) {
          // APC I1307K + BRCA1
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'BRCA1',
          ])
        ) {
          scope.showForce = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
            scope.showKintalk = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'BRIP1',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'BRCA2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'MITF',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'MSH2',
          ])
        ) {
          scope.showLynch = true;
          scope.showHcc = true;
          scope.showKintalk = true;
          scope.showForce = true;
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'PMS2',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'MSH2',
          ])
        ) {
          scope.showLynch = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'MSH6',
          ])
        ) {
          scope.showLynch = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'TP53',
          ])
        ) {
          scope.showLfs = true;
          scope.showForce = true;
          scope.showBrightPink = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BAP1',
            'MUTYH',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'CHEK2',
          ])
        ) {
          // Also handles the APC I1307K inclusion
          scope.showForce = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'BRCA2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'F') {
            scope.showKintalk = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'MITF',
          ])
        ) {
          scope.showKintalk = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'BRIP1',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKintalk = true;
            scope.showKomen = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'CHEK2',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKintalk = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'CHEK2',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            if (
              $filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
            ) {
              scope.showForce = true;
              scope.showKintalk = true;
              scope.showKomen = true;
            } else {
              scope.showColonCancerAlliance = true;
              scope.showHcc = true;
              scope.showForce = true;
              scope.showKomen = true;
              scope.showKintalk = true;
            }
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MITF',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'RAD51D',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showKomen = true;
          scope.showOvarian = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'PALB2',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          }
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'CDKN2A',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
          scope.showNpf = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CDKN2A',
            'PALB2',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showNpf = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'MUTYH',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKintalk = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'RAD51C',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKintalk = true;
            scope.showKomen = true;
            scope.showOvarian = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MSH2',
            'MUTYH',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
          if (scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic') {
            scope.showColonCancerAlliance = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'PMS2',
            'MUTYH',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'PMS2',
            'MSH6',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'CDKN2A',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showNpf = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'CHEK2',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKintalk = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'BRCA1',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'MITF',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showKomen = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'MUTYH',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MLH1',
            'MUTYH',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MLH1',
            'RAD51D',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showOvarian = true;
            scope.showForce = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MUTYH',
          ])
        ) {
          scope.showKomen = true;
          scope.showForce = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          if (scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic') {
            scope.showColonCancerAlliance = true;
            scope.showHcc = true;
            scope.showKintalk = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRIP1',
            'CHEK2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRIP1',
            'MUTYH',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'PALB2',
            'MUTYH',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'MUTYH',
          ])
        ) {
          if (
            $filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
          ) {
            scope.showCard = false;
          } else {
            scope.showColonCancerAlliance = true;
            scope.showHcc = true;
            scope.showKintalk = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRIP1',
            'MITF',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRIP1',
            'MSH6',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          }
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'PALB2',
          ]) ||
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRIP1',
            'PALB2',
          ])
        ) {
          scope.showForce = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'MUTYH',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          if (scope.reportContent.gender == 'M') {
            scope.showHisBreastCancer = true;
          } else {
            scope.showBrightPink = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'GREM1',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'MSH2',
          ])
        ) {
          scope.showForce = true;
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'MUTYH',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKomen = true;
            scope.showKintalk = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MSH6',
            'PALB2',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'TP53',
            'MUTYH',
          ])
        ) {
          scope.showLfs = true;
          scope.showForce = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'RAD51C',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showOvarian = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'BARD1',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'PMS2',
          ])
        ) {
          scope.showKintalk = true;
          scope.showHcc = true;
          scope.showLynch = true;
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BARD1',
            'PALB2',
          ])
        ) {
          scope.showCard = false;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'RAD51D',
          ])
        ) {
          if (
            scope.reportContent.gender == 'F' &&
            $filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
          ) {
            scope.showForce = true;
            scope.showOvarian = true;
            scope.showKintalk = true;
          } else {
            scope.showCard = false;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'MITF',
          ])
        ) {
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
          }
          scope.showKintalk = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'MSH6',
          ])
        ) {
          scope.showHcc = true;
          scope.showKintalk = true;
          scope.showLynch = true;
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showKomen = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'PMS2',
          ])
        ) {
          scope.showForce = true;
          scope.showKintalk = true;
          scope.showHcc = true;
          scope.showLynch = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'ATM',
            'PALB2',
          ])
        ) {
          scope.showForce = true;
          scope.showBrightPink = true;
          scope.showKomen = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'ATM',
          ])
        ) {
          scope.showKintalk = true;
          if (
            $filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
          ) {
            scope.showForce = true;
            scope.showKomen = true;
          } else {
            scope.showColonCancerAlliance = true;
            scope.showHcc = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'PMS2',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MSH6',
            'MUTYH',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'MLH1',
          ])
        ) {
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'MUTYH',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'PALB2',
          ])
        ) {
          scope.showAmericanMelanoma = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showForce = true;
            scope.showBrightPink = true;
            scope.showKomen = true;
          }
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'RAD51C',
          ])
        ) {
          scope.showForce = true;
          scope.showOvarian = true;
          scope.showKintalk = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'RAD51D',
          ])
        ) {
          scope.showForce = true;
          scope.showOvarian = true;
          scope.showKintalk = true;
          scope.showAmericanMelanoma = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MUTYH',
            'RAD51D',
          ])
        ) {
          scope.showForce = true;
          scope.showOvarian = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MUTYH',
            'PTEN',
          ])
        ) {
          scope.showForce = true;
          scope.showPtenFoundation = true;
          scope.showHcc = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'GREM1',
            'PMS2',
          ])
        ) {
          scope.showColonCancerAlliance = true;
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MSH6',
          ]) ||
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MSH2',
          ])
        ) {
          scope.showForce = true;
          scope.showKomen = true;
          scope.showHcc = true;
          scope.showLynch = true;
          scope.showKintalk = true;
          if (scope.reportContent.gender == 'F') {
            scope.showBrightPink = true;
          } else {
            scope.showHisBreastCancer = true;
          }
        }
      }
    },
  };
};
