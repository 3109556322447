'use strict'

module.exports = function() {
  "ngInject"

  return {
    restrict: 'AE',
    scope: {
      address: '=',
      compact: '=',
      showPhoneNumber: '=?'
    },
    templateUrl: '/users/address.html',
    link: function(scope) {
      if (angular.isUndefined(scope.showPhoneNumber)) {
        scope.showPhoneNumber = true
      }
    }
  }
}
