!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrRaven
   *
   * @description
   * The `clrRaven` provides some custom logic for ngRaven.
   */
  const module = angular.module('clrRaven', ['ngRaven'])

  /**
   * Sentry angular-translate exception handler
   */
  module.factory('sentryTranslateExceptionHandler', function($raven) {
    return function(translationID, uses) {
      // Fire Sentry exception:
      // https://github.com/gdi2290/angular-raven#how-do-i-add-this-to-my-project
      $raven.captureException('Translation key not found: "' + translationID + '" in the ' + uses + ' bundle.', {
        fingerprint: [translationID, uses],
      })
      // Return empty string to broken translation key doesn't show
      // http://angular-translate.github.io/docs/#/guide/17_custom-error-handler
      return ' '
    }
  })

  /**
   * Initialization:
   * * Set user context when auth event happens.
   * * Register global tags for Sentry.
   */
  module.run(function($rootScope, $raven, environment) {
    function setUserContext(e, userId) {
      if (userId) {
        $raven.setUserContext({id: userId})
      } else {
        $raven.setUserContext()
      }
    }

    $rootScope.$on('auth:synced', setUserContext)
    $rootScope.$on('auth:loggedIn', setUserContext)
    $rootScope.$on('auth:loggedOut', setUserContext)

    $raven.setTagsContext({
      environment: environment
    })
  })

  /**
   * Report every non 2xx requests to sentry.
   */
  module.config(function($httpProvider) {
    const urlCleaners = [
      [/api\/v1\/promotional_codes\/[^\/]+/, "api/v1/promotional_codes/_"],
      [/\d\d+/g, "_"]
    ]
    function applyPattern(patterns, input) {
      return patterns.reduce(function(str, pattern) {
        return str.replace(pattern[0], pattern[1])
      }, input)
    }
    function setLevel(rejection) {
      // Set different log levels based on type of exception
      // https://docs.sentry.io/clients/javascript/usage/#passing-additional-data
      if (rejection.status == -1 || rejection.status >= 400 && rejection.status <= 499 && rejection.config.method == 'GET') {
        // Covers most client-originated network scenarios (404s from API requests, -1 from Angular template fetch failures, etc)
        return 'info'
      } else if (rejection.status >= 400 && rejection.status <= 499 && rejection.config.method == 'POST') {
        // Slightly more severe warnings on attempts to POST data but it's invalid for some reason
        return 'warning'
      } else {
        // Everything else
        return 'warning'
      }
    }

    $httpProvider.interceptors.push(function($q, $raven, $location) {
      return {
        responseError: function(rejection) {
          // We don't report unauthenticated error.
          if (rejection.status != 401 && rejection.status != 404 && !Raven.debug) {
            // clean the URL to make sentry more usable
            const cleanedURL = applyPattern(urlCleaners, rejection.config.url)

            // request data is not sent as it could potentially contain PHI.
            const msg = rejection.status + " " + rejection.config.method + " " + cleanedURL
            $raven.captureMessage(msg, {
              culprit: $location.path(),
              fingerprint: [$location.path(), rejection.status, setLevel(rejection)],
              trimHeadFrames: 2,
              stacktrace: true,
              tags: {
                status: rejection.status
              },
              level: setLevel(rejection),
              extra: {
                originalURL: rejection.config.url
              }
            })
          }
          return $q.reject(rejection)
        }
      }
    })
  })

  /**
   * Monkey-patch Raven._send() to send errors on some URLs to specific
   * projects, e.g. provider and RED.
   *
   * Background: https://github.com/color/color/wiki/Error-reporting-via-Sentry
   */
  Raven._originalSend = Raven._send
  Raven._send = function(data) {
    Raven.setDSN(Raven._globalOptions.defaultDSN)
    for (let i = 0; i < Raven._globalOptions.overrideUrls.length; i++) {
      const override = Raven._globalOptions.overrideUrls[i]
      if (window.location.pathname.search(override[0]) > -1) {
        // Have to set level to 'warning' here for it to work. Setting level:
        // 'warning' in Raven.config() (in templates/_raven.html) or in
        // Raven.setTagsContext() (above in this file) have no effect.
        // https://docs.sentry.io/clients/javascript/usage/#raven-js-additional-context
        data.level = 'warning'
        Raven.setDSN(override[1])
        break
      }
    }
    Raven._originalSend(data)
  }
}();
