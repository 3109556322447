'use strict'

module.exports = function($filter) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/summary_title.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.values = {}
      // Negative
      if (scope.reportContent.is_negative) {
        scope.title = 'FH_REPORT.SUMMARY_TITLE.NEGATIVE'
      // Positive
      } else {
        const mutations = $filter('pathogenicMutations')(scope.reportContent.mutations)
        const genes = scope.reportContent.genes_with_pathogenic_mutations
        if (genes.length > 1) {
          throw new Error("Only single gene is currently supported.")
        }
        scope.values = {
          gene: genes[0],
          classification: mutations[0].classification.toLowerCase() }
        // Single het or hom
        if (mutations.length == 1) {
          if (mutations[0].zygosity == "Heterozygous") {
            scope.title = 'FH_REPORT.SUMMARY_TITLE.POSITIVE_HET'
          } else {
            scope.title = 'FH_REPORT.SUMMARY_TITLE.POSITIVE_HOM'
          }
        // Compound het unknown phase
        } else if (mutations.length == 2 && genes.length == 1 && !scope.reportContent.allelicity_by_gene[genes[0]]) {
          if (mutations[0].classification == mutations[1].classification) {
            scope.title = 'FH_REPORT.SUMMARY_TITLE.POSITIVE_UNKNOWN_PHASE_P_OR_LP'
          } else {
            scope.title = 'FH_REPORT.SUMMARY_TITLE.POSITIVE_UNKNOWN_PHASE_P_AND_LP'
          }
        } else {
          throw new Error("Only single mutation or compund het unknown phase is currently supported.")
        }
      }
    }
  }
}
