'use strict'

function ReattemptInsuranceModalController(
  $scope,
  $modalInstance,
  $location,
  order
) {
  'ngInject'

  $scope.order = order
  $scope.continue = () => {
    $location.path('/providers/resubmit-payment/' + $scope.order.requisition_number)
    $modalInstance.close()
  }
}

module.exports = function reattemptInsuranceModal(
  $modal
) {
  'ngInject'

  return {
    open: (order) => (
      $modal.open({
        templateUrl: '/ordering_physicians/modals/reattempt_insurance.html',
        controller: ReattemptInsuranceModalController,
        size: 'lg',
        resolve: {
          order: function() {
            return order
          }
        }
      })
    )
  }
}
