module.exports = angular.module('kitOrderServices', [
  'ngCookies', 'ngToast', 'pascalprecht.translate',
  'clrAuth', 'clrConstant', 'clrLocation', 'clrModal',
  'clrRequest', 'clrResource', 'clrStripe', 'clrGeographical',
  'kitOrderResource', 'orderingPhysicianResource', 'userResource'])

  // Sets 'code' cookie if given in query params.
  .run(function($location, promoCodeStore, associatedSample, accessionNumber, kitBarcode, requisitionStore) {
    const search = $location.search()
    const promoCode = search.code
    const hideFailure = search.hide_failure
    if (promoCode) {
      $location.search('code', null)
      if (hideFailure) {
        $location.search('hide_failure', null)
      }
      promoCodeStore.persistCode(promoCode)
    }
    promoCodeStore.init(hideFailure)
    requisitionStore.initFromQueryParams()
    associatedSample.initFromQueryParams()
    accessionNumber.initFromQueryParams();
    kitBarcode.initFromQueryParams();
  })

  // Sets 'claimToken' cookie if given in query params.
  .run(function($location, claimStore, ngToast) {
    const search = $location.search();
    const claimToken = search.claim_token || claimStore.get();
    const hideFailure = search.hide_failure;

    if (claimToken) {
      $location.search('claim_token', null);
      if (hideFailure) {
        $location.search('hide_failure', null);
      }
      return claimStore.getClaim(claimToken).then(claim => {
        if (!hideFailure && (!claim || !claim.is_redeemable)) {
          ngToast.create('You are no longer eligible for this Color benefit. Please contact Color Support for assistance.');
        }
      })
    }
  })

  .run(function($rootScope, KitCheckoutFlow) {
    $rootScope.$on(KitCheckoutFlow.startEventName, function() {
      KitCheckoutFlow.init(true)
    })
    $rootScope.$on(KitCheckoutFlow.resetEventName, function() {
      KitCheckoutFlow.init(false)
    })
  })

  .run(function($rootScope, sampleReuseFlow) {
    $rootScope.$on(sampleReuseFlow.startEventName, function() {
      sampleReuseFlow.init(true)
    })
    $rootScope.$on(sampleReuseFlow.resetEventName, function() {
      sampleReuseFlow.init(false)
    })
  })

  .service('promoCodeStore', require('./services/promo_code_store'))
  .service('claimStore', require('./services/claim_store'))
// kitPriceService provides methods for getting the current price, including
// price promotions.
  .service('kitPriceService', require('./services/kit_price_service'))
// Checkout provides a base Checkout implementation
  .service('Checkout', require('./services/checkout'))
  .factory('KitCheckout', require('./services/kit_checkout'))
  .factory('jsLazyLoader', require('./services/js_lazy_loader'))
  .service('canAddDependents', require('./services/can_add_dependents'))
  .service('requisitionStore', require('./services/requisition_store'))
  .service('associatedSample', require('./services/associated_sample'))
  .service('accessionNumber', require('./services/accession_number'))
  .service('kitBarcode', require('./services/kit_barcode'))
  .service('splitName', require('./services/split_name'))
  .service('healthcareProviderStore', require('./services/healthcare_provider_store'))
  .service('handlePaymentError', require('./services/handle_payment_error'))
  .service('KitCheckoutFlow', require('./services/kit_checkout_flow'))
  .service('sampleReuseFlow', require('./services/sample_reuse_flow'))
  .service('DependentsClaimManager', require('./services/dependents_claim_manager'))
  .service('sampleReuseService', require('./services/sample_reuse_service'))
  .service('physicianOrder', require('./services/physician_order'))
  .service('handleIntlShipping', require('./services/handle_intl_shipping'))
