function AuthenticationFactorsController($scope, $http, $location, $modalInstance, requestErrorToast, claimStore, identifier, organization_name, authFactorsNeeded, concatAuthFactors, receivedPromoCode) {
  'ngInject'
  $scope.authFactorsNeeded = authFactorsNeeded
  $scope.authFactors = {}

  $scope.submitAuthFactors = () => {
    // Teamsters doesn't give their employees emails so we have to de-dupe on first_name,
    // last_initial, zip, and dob by combining them into the identifier
    const request = {
      identifier: concatAuthFactors ? identifier + authFactorsNeeded.map(key => $scope.authFactors[key]).join('') : identifier,
      factors: $scope.authFactors,
      organization_name: organization_name,
    }

    $http.post('api/v1/claim_benefits', request, { skipErrorToast: true })
      .then(response => {
        if (response.data.code) {
          receivedPromoCode(response.data.code)
        }
        if (response.data.token) {
          receivedClaimToken(response.data.token)
        }
      }).catch(response => {
        if (response.status == 400) {
          $scope.authFactorsInvalid = true
        } else {
          requestErrorToast(response)
        }
      })
  }

  const receivedClaimToken = (token) => {
    claimStore.clearClaim()
    return claimStore.getClaim(token).then(claim => {
      if (!claim) {
        return
      }

      const skuDiscounts = claim.sku_discounts
      if (skuDiscounts && skuDiscounts.length === 1) {
        $location.path('/purchase').search({sku: skuDiscounts[0].sku})
      } else {
        $location.hash('pricing')
        $location.path('/product/compare-genetic-tests')
      }
      $modalInstance.close()
    })
  }
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (identifier, authFactorsNeeded, concatAuthFactors, organization_name) => {
      return $modal.open({
        templateUrl: '/benefits/modals/authentication_factors.html',
        controller: AuthenticationFactorsController,
        size: 'md',
        resolve: {
          identifier: () => identifier,
          authFactorsNeeded: () => authFactorsNeeded,
          concatAuthFactors: () => concatAuthFactors,
          organization_name: () => organization_name,
        }
      })
    }
  }
}
