!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name resultResource
   *
   * @description
   * The `resultResource` provides resources for
   * variant results API.
   */
  const module = angular.module('resultResource', ['clrResource'])

  // Report state values.
  module.constant('reportNotReadyState', 'not_ready')
  module.constant('reportNewState', 'new')
  module.constant('reportContentReviewedState', 'content_reviewed')
  module.constant('reportQueuedforBoardReviewState', 'queued_for_board_review')
  module.constant('reportPreparedForBoardReviewState', 'prepared_for_board_review')
  module.constant('reportBoardReviewedState', 'board_reviewed')
  module.constant('reportSignOutApprovedState', 'sign_out_approved')
  module.constant('reportSignedOffState', 'signed_off')
  module.constant('reportReleaseGatedState', 'release_gated')
  module.constant('reportReleaseApprovedState', 'release_approved')
  module.constant('reportSentToPhysicianState', 'sent_to_ordering_physician')
  module.constant('reportReleaseReadyState', 'release_ready')
  module.constant('reportReleasedState', 'released')

  /**
   * @ngdoc service
   * @name Report
   * @memberof resultResource
   *
   * @description
   * A resource for `reports` REST API.
   */
  module.factory('Report', function(resource) {
    return resource(
      '/api/v1/reports/:id'
      , { id: '@id', format: 'json' }, {
        'released': {
          url: '/api/v1/reports/:id/released'
        }
      }
    )
  })

  /**
   * @ngdoc service
   * @name ReportedData
   * @memberof resultResource
   *
   * @description
   * A resource for `reported_data` REST API.
   */
  module.factory('ReportedData', function(resource) {
    return resource(
      '/api/v1/reported_data/:id'
      , { id: '@id', format: 'json' }
    )
  })
  /**
   * @ngdoc service
   * @name LatestReport
   * @memberof resultResource
   *
   * @description
   * A resource for `latest_reports` REST API.
   */
  module.factory('LatestReport', function(resource) {
    return resource(
      '/api/v1/latest_reports/:testType'
      , { testType: '@testType', format: 'json' }
    )
  })

  /**
   * @ngdoc service
   * @name AnonymizedReport
   * @memberof resultResource
   *
   * @description
   * A resource for `anonymized_reports` REST API.
   */
  module.factory('AnonymizedReport', function(resource) {
    return resource('/api/v1/anonymized_reports/:id', { id: '@id', format: 'json' })
  })

  /**
   * @ngdoc service
   * @name FaxLog
   * @memberof resultResource
   *
   * @description
   * A resource for `fax_logs` REST API.
   */
  module.factory('FaxLog', function(resource) {
    return resource(
      '/api/v1/fax_logs/:id'
      , { id: '@id', format: 'json' }
    )
  })

  /**
   *
   * V1 reports are no longer supported. Delete this.
   */
  module.factory('Template', function(resource) {
    return resource('/api/v1/templates/:id', { id: '@id', format: 'json' })
  })

  /**
   * @ngdoc service
   * @name Reference
   * @memberof resultResource
   *
   * @description
   * A resource for `references` REST API.
   */
  module.factory('Reference', function(resource) {
    return resource('/api/v1/references/:id', { id: '@id', format: 'json' })
  })

  /**
   * @ngdoc service
   * @name ScreeningGuideline
   * @memberof resultResource
   *
   * @description
   * A resource for `screening_guidelines` REST API.
   */
  module.factory('ScreeningGuideline', function(resource) {
    return resource('/api/v1/screening_guidelines', { format: 'json' })
  })

  /**
   * @ngdoc service
   * @name sampleRunVariants
   * @memberof resultService
   *
   * @description
   * `sampleRunVariants` loads sample run variants with its classification
   * for a given report.
   */
  module.factory('sampleRunVariants', function($q, resourcePaginator, SampleRunVariant
    , VariantClassification, ClassificationReview, VariantConfirmation, resolvedPromise) {

    function SampleRunVariants(report, hideBenignUnreported) {
      this.report = report
      this.hideBenignUnreported = hideBenignUnreported
      this.items = []
      this.loading = false
    }

    SampleRunVariants.prototype.load = function() {
      let sampleRunVariants;
      const deferredConfirmations = VariantConfirmation.get({ sample__user: this.report.user.id })
      this.loading = true
      return resourcePaginator(
        new SampleRunVariant()
        , {
          hide_benign: this.hideBenignUnreported,
          report_id: this.report.id,
          sample_sequence_run: this.report.sample_sequence_run.id,
          page_size: 500
        }
      ).getAllPages()
        .then(angular.bind(this, function(res) {
          let variantIds = []
          sampleRunVariants = res
          angular.forEach(sampleRunVariants, function(srv) {
            variantIds.push(srv.variant_id)
          })
          variantIds = variantIds.join()
          let variantClassifications;
          let classificationReviews;
          if (variantIds) {
            variantClassifications = VariantClassification.get({
              for_report: true,
              classification_unit__variant__in: variantIds,
              test_type: this.report.kit_order.test_type,
              hide_unreported: this.hideBenignUnreported,
              page_size: 1000
            }).$promise
            classificationReviews = ClassificationReview.get({
              is_internal: 'True',
              variant_classification__classification_unit__variant__in: variantIds,
              page_size: 1000
            }).$promise
          } else {
            variantClassifications = resolvedPromise({ results: [] })
            classificationReviews = resolvedPromise({ results: [] })
          }
          return $q.all([variantClassifications, classificationReviews])
        }))
        .then(function(res) {
          const variantIdToCuClassifications = {};
          const cuToReviews = {};
          const variantClassifications = res[0].results;
          const classificationReviews = res[1].results
          angular.forEach(variantClassifications, function(vc) {
            // a map of classification_unit_id to possible unreviewed or active variant classifications
            const cur = variantIdToCuClassifications[vc.classification_unit.variant.id] || {}
            // Always show unreviewed classification. o/w active classification.
            if (vc.state != 'reviewed') {
              cur[vc.classification_unit.id] = vc
            } else if (vc.active && (!cur[vc.classification_unit.id] || cur[vc.classification_unit.id].state == 'reviewed')) {
              cur[vc.classification_unit.id] = vc
            }
            variantIdToCuClassifications[vc.classification_unit.variant.id] = cur
          })
          angular.forEach(classificationReviews, function(review) {
            // Ignore cancelled reviews.
            if (review.state == 'cancelled') {
              return
            }
            const curReview = cuToReviews[review.variant_classification.classification_unit.id]
            if (curReview) {
              if (curReview.created_at < review.created_at) {
                cuToReviews[review.variant_classification.classification_unit.id] = review
              }
            } else {
              cuToReviews[review.variant_classification.classification_unit.id] = review
            }
          })
          angular.forEach(sampleRunVariants, function(sampleRunVariant) {
            const cuToClassifications = variantIdToCuClassifications[sampleRunVariant.variant_id]
            const cus = cuToClassifications ? Object.keys(cuToClassifications) : [] // all classification_unit ids for this variant
            sampleRunVariant.variant_classifications = cus.map(function(cu) { return cuToClassifications[cu]; })
            sampleRunVariant.variant_classifications.forEach(function(cls) {
              cls.review = cuToReviews[cls.classification_unit.id]
            })
          })
          return deferredConfirmations.$promise
        })
        .then(angular.bind(this, function(res) {
          const variantIdToConfirmations = {}
          angular.forEach(res.results, function(vc) {
            if (!variantIdToConfirmations[vc.variant_id]) {
              variantIdToConfirmations[vc.variant_id] = []
            }
            variantIdToConfirmations[vc.variant_id].push(vc)
          })
          angular.forEach(sampleRunVariants, function(sampleRunVariant) {
            sampleRunVariant.variant_confirmations = variantIdToConfirmations[sampleRunVariant.variant_id]
          })
          this.items = sampleRunVariants
          return this
        }))
        .finally(angular.bind(this, function() {
          this.loading = false
          return this
        }))
    }

    return function(report, hideBenignUnreported) {
      return new SampleRunVariants(report, hideBenignUnreported)
    }
  })
}();
