"use strict"

/**
 * Video directive for the video boxes with a full image background,
 * a play button in the center, and a bottom blue overlay bar w/ text
 */
module.exports = function () {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      videoName: '@',
      imageName: '@',
      buttonSize: '@?',
      contentPadding: '@?',
      contentClass: '@?'
    },
    transclude: true,
    templateUrl: '/directives/clr_video_block_player.html',
    link: function (scope) {
      scope.buttonSize = scope.buttonSize ? scope.buttonSize : 'large' // valid values are 'small' and 'large'
      scope.contentPadding = scope.contentPadding ? scope.contentPadding : 'large' // valid values are 'small' and 'large'
      scope.contentClass = scope.contentClass ? scope.contentClass : ''
    }
  }
}
