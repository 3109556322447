
module.exports = function designWhiteBodyBackground(){
  'ngInject'

  // Workaround to modify CSS styling of the <body id='ng-app'> tag per page
  // Usage in Jade: `design-white-body-background()`
  return {
    restrict: 'E',
    scope: false,
    link: function(scope) {
      angular.element('#ng-app').addClass('clear-layout');
      scope.$on('$destroy', () => {
        angular.element('#ng-app').removeClass('clear-layout');
      })
    }
  }
}
