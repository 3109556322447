'use strict'

// KitRedirectController is responsible for redirects for the /kit path
// Old benefits emails contain a link to /kit, and if the sampleId
// parameter is set then they should now be taken to the activation flow
// instead of the purchase flow.
module.exports = function($location, associatedSample) {
  'ngInject'

  if (associatedSample.get()) {
    $location.path('/genetics/activate').replace()
  } else {
    $location.path('/product/hereditary-cancer-genetic-test').replace()
  }
}
