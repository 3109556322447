/**
 * @prettier
 */

module.exports = function reportedMutations() {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      mutations: '=',
    },
    templateUrl: '/results/reported_mutations.html',
  };
};
