module.exports = function providerPaginator($window, $location) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      pageSize: '@',
      results: '='
    },
    templateUrl: '/ordering_physicians/_paginator.html',
    link: function(scope) {

      scope.pageSize = parseInt(scope.pageSize)
      let currentPage = 1
      const pageNum = $location.search()['page']
      if (pageNum) {
        currentPage = parseInt(pageNum)
      }

      scope.currentItem = 1 + (currentPage - 1) * scope.pageSize
      scope.endItem = Math.min(scope.currentItem + 15, scope.results.count)

      scope.getNextPage = function() {
        const currentParams = angular.copy($location.search())
        currentParams['page'] = currentPage + 1
        $location.search(currentParams)
      }

      scope.getPreviousPage = function() {
        const currentParams = angular.copy($location.search())
        currentParams['page'] = currentPage - 1
        $location.search(currentParams)
      }
    }
  }
}
