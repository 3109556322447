/**
 * @ngdoc module
 * @name resultFilter
 *
 * @description
 * The `resultFilter` provides filters for results.
 */
module.exports = angular.module('resultFilter', ['clrConstant', 'clrFilter'])

  /**
   * @ngdoc filter
   * @name pdfReportUrl
   * @memberof resultFilter
   *
   * @description
   * Generates a PDF report URL given a report id.
   */
  .filter('pdfReportUrl', function() {
    return function(report_id) {
      return '/pdf_reports/' + report_id
    }
  })

  /**
   * @ngdoc filter
   * @name webReportUrl
   * @memberof resultFilter
   *
   * @description
   * Generates a web report URL given a report id.
   */
  .filter('webReportUrl', function() {
    return function(report_id) {
      return '/web-report/' + report_id + '/next-steps'
    }
  })

/**
   * @ngdoc filter
   * @name webReportV2Url
   * @memberof resultFilter
   *
   * @description
   * Generates a web report v2 URL given a report id.
   * should only be used for internal tools
   */
  .filter('webReportV2Url', function() {
    return function(reportId) {
      // Render the report using the new report page when the 'show_react_web=true'
      // query parameter is detected. This supports the ongoing integration of
      // monoweb/reports from a separate project into the main codebase.
      // During the transition, it's necessary to maintain both the old and new
      // report views in clinical to ensure a smooth migration to the updated system.
      if (window.location.search.includes('show_react_web=true')) {
        return `/results-v3/report/view?reportId=${reportId}`
      }

      return `/results-v2/v/${reportId}/next-steps`
    }
  })

  /**
   * @ngdoc filter
   * @name hasSampleTag
   * @memberof resultFilter
   *
   * @description
   * Filters objects with a sample with a given tag.
   */
  .filter('hasSampleTag', function() {
    return function(objs, searchStr) {
      if (!objs) {
        return false
      }
      return objs.filter(function(obj) {
        if (searchStr == void 0 || searchStr == '') {
          return true
        }
        if (!obj.sample) {
          return false
        }
        if (obj.sample.for_client) {
          if ('client'.indexOf(searchStr.toLowerCase()) != -1) {
            return true
          }
        }
        const tags = obj.sample.tags
        if (!tags) {
          return false
        }
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].name.toLowerCase().indexOf(searchStr.toLowerCase()) != -1) {
            return true
          }
        }
        return false
      })
    }
  })

  /**
   * @ngdoc filter
   * @name abbreviateClassification
   * @memberof resultFilter
   *
   * @description
   * Abbreviates variant classification name.
   */
  .filter('abbreviateClassification', function(classification) {
    return function(name) {
      if (name == classification.p) {
        return 'P'
      } else if (name == classification.lp) {
        return 'LP'
      } else if (name == classification.ras) {
        return 'RAS'
      } else if (name == classification.vus) {
        return 'VUS'
      } else if (name == classification.lb) {
        return 'LB'
      } else if (name == classification.b) {
        return 'B'
      } else if (name == classification.rai) {
        return 'RAI'
      } else if (classification) {
        throw 'Unexpected classification name: ' + name
      } else { // somatic variants don't have classification
        return ''
      }
    }
  })

  /**
   * @ngdoc filter
   * @name resultSignificance
   * @memberof resultFilter
   *
   * @description
   * Returns clinical significance of a given report.
   * e.g.
   * - P:BRCA1 + LP:MUTYH:Homozygous + VUS:APC + Additional Findings
   * - CYP2D6:*3/*9:intermediate metabolizer + CYP2C19:*1/*3:poor metabolizer
   */
  .filter('resultSignificance', function($filter, reportNewState, testOutcome, classification, zygosity) {
    function interpretMutations(geneMutations) {
      function formatMutation(mut) {
        let geneName = mut.gene
        if (mut.mutation_name) {
          geneName += ' (' + mut.mutation_name + ')'
        }
        let classificationName = $filter('abbreviateClassification')(mut.classification)
        if (mut.vusp) {
          classificationName += '-P'
        }
        const parts = [geneName, classificationName]
        // Note homozygous mutation
        if (mut.zygosity == zygosity.homozygous) {
          parts.push(mut.zygosity)
        }
        return parts.join(':')
      }

      function formatMutations(mutations, classificationName) {
        return mutations.filter(function(m) { return m.classification == classificationName }).map(function(m) { return formatMutation(m) })
      }

      let mutations = []
      mutations = mutations.concat(formatMutations(geneMutations, classification.p))
      mutations = mutations.concat(formatMutations(geneMutations, classification.lp))
      mutations = mutations.concat(formatMutations(geneMutations, classification.ras))
      mutations = mutations.concat(formatMutations(geneMutations, classification.vus))
      return mutations
    }

    function interpretDiplotypes(geneDiplotypes) {
      return geneDiplotypes.filter(function(gene) {
        return !gene.is_baseline
      }).map(function(gene) {
        let diplotypes
        if (gene.diplotypes.length) {
          diplotypes = gene.diplotypes.map(function(d) { return d.diplotype }).join(', ')
        } else {
          diplotypes = 'indeterminate'
        }
        return [gene.gene, diplotypes, gene.phenotype].join(':')
      })
    }

    function interpretValues(interpretedValues) {
      return interpretedValues.map(value => `${value.value_type}: ${value.significance}`).join(', ')
    }

    return function(report) {
      if (report.test_outcome == testOutcome.non_reportable) {
        return testOutcome.non_reportable
      } else if (report.state == reportNewState && !report.result_is_ready) {
        return 'Pending'
      } else {
        let parts = []
        // Interpret variants
        if (report.gene_mutations.length) {
          parts = parts.concat(interpretMutations(report.gene_mutations))
        }
        // Interpret diplotypes
        if (report.gene_diplotypes != null && report.gene_diplotypes.length) {
          // Check for null/undefined to handle a number of errors:
          // (TypeError: Cannot read property 'length' of null)
          parts = parts.concat(interpretDiplotypes(report.gene_diplotypes))
        }
        // Interpret values
        if (report.interpreted_values.length) {
          parts = parts.concat(interpretValues(report.interpreted_values))
        }
        if (!parts.length) {
          parts.push('Baseline')
        }
        if (report.has_incidental_findings) {
          parts.push('Additional Findings')
        }
        return parts.join(' + ')
      }
    }
  })

  /**
   * @ngdoc filter
   * @name variantType
   * @memberof resultFilter
   */
  .filter('variantType', function() {
    return function(srv) {
      if (srv.variant_type == 'germline') {
        return 'Germline'
      } else if (srv.variant_type == 'somatic') {
        return 'Low Fraction / Somatic'
      } else {
        throw 'variantType filter expects either germline or somatic type.'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name reportStatusText
   * @memberof resultFilter
   *
   * @description
   * Converts report to its status string.
   */
  .filter('reportStatusText', function() {
    return function(report) {
      if (report.state == 'new' && !report.result_is_ready) {
        return 'Variants pending'
      } else if (report.state == 'new' && report.result_is_ready) {
        return 'Under GC review'
      } else if (report.state == 'queued_for_board_review') {
        return 'Preparing for GBR'
      } else if (report.state == 'prepared_for_board_review') {
        return 'Under GBR'
      } else if (report.state == 'content_reviewed' || report.state == 'board_reviewed') {
        return 'Under pathologist review'
      } else if (report.state == 'signed_off') {
        return 'Under internal physician review'
      } else if (report.state == 'release_gated') {
        return 'Release gated'
      } else if (report.state == 'release_approved') {
        return 'Approved for release'
      } else if (report.state == 'sent_to_ordering_physician') {
        return 'Under external physician review'
      } else if (report.state == 'release_ready') {
        return 'Ready for release'
      } else if (report.state == 'released') {
        return 'Released to client'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name confirmationStatus
   * @memberof resultFilter
   *
   * @description
   * Returns a status of a given variant confirmation.
   */
  .filter('confirmationStatus', function($filter) {
    return function(confirmation) {
      if (angular.isUndefined(confirmation)) {
        return 'Not queued'
      }
      const predictedToConfirm = confirmation.sample_run_variant && confirmation.sample_run_variant.quality_prediction && confirmation.sample_run_variant.quality_prediction.predicted_to_confirm
      if (confirmation.state == 'new') {
        if (predictedToConfirm) {
          return 'In queue, predicted to confirm'
        }
        return 'In queue'
      }
      if (confirmation.state == 'pending') {
        let status
        if (predictedToConfirm) {
          status = 'Pending, predicted to confirm'
        } else {
          status = 'Pending'
        }
        return status + ' (Sent ' + $filter('date')(confirmation.requested_at, 'MM/dd/yy') + ')'
      }
      if (confirmation.state == 'completed') {
        if (angular.isUndefined(confirmation.is_present)) {
          return 'Completed'
        } else if (confirmation.is_present) {
          return 'Present'
        } else {
          return 'Not present'
        }
      }
      return $filter('capitalize')(confirmation.state).replace('_', ' ')
    }
  })

  /**
   * @ngdoc filter
   * @name reviewStatus
   * @memberof resultFilter
   *
   * @description
   * Determines the status of the review for a variant classification
   */
  .filter('reviewStatus', function() {
    // Note that this logic is the same as our backend's monoweb/django_apps/results/is_classification_approved function.
    // Changes in logic here should reflect in changes there as well
    return function(vc) {
      const review = vc.review
      if (!review) {
        return 'Not queued'
      }
      if (review.state == 'reviewed' && review.classification == vc.classification.id) {
        return 'Approved'
      } else {
        return 'Pending'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name variantValidationStatus
   *
   * @description
   * Whether a variant was validated, through confirmation or via other methods. See BIOINF-2350.
   */
  .filter('variantValidationStatus', function() {
    return function(validation) {
      if (angular.isUndefined(validation)) {
        return 'Unknown'
      }
      if (validation.is_valid === true) {
        status = 'Present'
      } else if (validation.is_valid === false) {
        status = 'Absent'
      } else {
        if (validation.requires_confirmation) {
          status = 'Pending'
        } else {
          status = 'N/A'
        }
      }
      return status + ': ' + validation.details
    }
  })

  /**
   * @ngdoc filter
   * @name areConfirmationsInExceptionState
   * @memberof resultFilter
   *
   * @description
   * Returns true if any given variant confirmation in the list is in an exception state.
   */
  .filter('areConfirmationsInExceptionState', function() {
    return function(confirmations) {
      if (!confirmations || !confirmations.length) {
        return false
      }

      return confirmations.some(function(confirmation) {
        // Diplotype confirmations do not have is_present, so the result is considered valid
        // purely based on the confirmation state
        const isValid = angular.isUndefined(confirmation.is_present) || confirmation.is_present

        return confirmation.state == 'completed' && !isValid
          || ['cancelled', 'failed'].indexOf(confirmation.state) != -1
          || confirmation.for_ny && confirmation.state == 'pending'
      })
    }
  })

  .filter('displayDiplotype', () => {
    return (solution) => [solution.haplotype_1, solution.haplotype_2].join('/')
  })

  /**
   * @ngdoc filter
   * @name trustUrl
   * @memberof resultFilter
   *
   * @description
   * Allows a URL displayed in an iframe to be from another host
   */
  .filter('trustUrl', ($sce) => {
    return function(url) {
      return $sce.trustAsResourceUrl(url);
    }
  })

  .filter('diplotypeSummary', function($filter) {
    return (call) => {
      if (call.solutions.length) {
        return call.solutions.map(s => $filter('displayDiplotype')(s)).join(', ');
      } else {
        return 'Indeterminate';
      }
    }
  })

  .filter('joinBy', function() {
    return function(input, delimiter) {
      return (input || []).join(delimiter || ',')
    }
  })

  /**
   * @ngdoc filter
   * @name barChartHeight
   * @memberof resultFilter
   *
   * @description
   * Parses and manipulates the risk numbers to render more pleasantly on the report chart
   */
  .filter('barChartHeight', function($filter) {
    return function(risk, modifier, bound) {
      const matchBracketedPercentage = /\[[0-9\-\.]*%\]$/
      const matchMiscSymbols = /[%<>()\[\]\s]/g
      const matchHasNumber = /[0-9]/

      if (angular.isDefined(risk)) {
        let cleanRisk = risk.replace(matchMiscSymbols, '')
        if (angular.isUndefined(modifier)) {
          modifier = 0
        }
        if ($filter('isNotElevated')(risk) || $filter('isStudiesOngoing')(risk)) {
          return 0
        } else if (cleanRisk.indexOf('elevated') > -1 && !matchHasNumber.test(cleanRisk) && cleanRisk.indexOf('-') == -1) {
          // Is elevated w/o range and doesn't contain a number
          // Return elevated height
          return 15 + modifier
        } else if (cleanRisk.indexOf('-') > -1) {
          // Is a range
          cleanRisk = cleanRisk.replace('elevated', '')
          // return upper bound unless bound is defined
          if (angular.isUndefined(bound)) {
            bound = 1
          }
          return parseFloat(cleanRisk.split('-')[bound]) + modifier
        } else {
          // Is a single value, needs to cover cases where elevated also exists, e.g. "elevated (<1%)" or "elevated (9%)"
          cleanRisk = parseFloat(cleanRisk.replace('elevated', ''))
          if (cleanRisk <= 0.5) {
            return 0.5 + modifier
          }
          if (cleanRisk <= 1) {
            return 1 + modifier
          }
          if (cleanRisk <= 1.5) {
            return 1.5 + modifier
          }
        }
        return cleanRisk + modifier
      }
    }
  })

  /**
   * @ngdoc filter
   * @name mutationsHaveVus
   * @memberof resultFilter
   *
   * @description
   * Looks at mutations to see if it contain a vus
   */
  .filter('mutationsHaveVus', function(classification) {
    return function(mutations) {
      let hasVus = false
      if (angular.isDefined(mutations)) {
        for (let i = 0; i < mutations.length; i++) {
          if (mutations[i].classification == classification.vus) {
            hasVus = true
            break
          }
        }
      }
      return hasVus
    }
  })

  /**
   * @ngdoc filter
   * @name pathogenicMutations
   * @memberof resultFilter
   *
   * @description
   * Filters only pathogenic or likely pathogenic mutations
   */
  .filter('pathogenicMutations', function(classification) {
    return function(mutations) {
      if (angular.isDefined(mutations)) {
        const pathogenicMutations = mutations.filter(function(mutation) {
          // Somatic variants will not have classification since all our classifications are for germline.
          // We used to apply germline classification to somatic variants. For backward compatibility,
          // we still want to include those variants in pathogenic mutations.
          return mutation.classification == classification.p || mutation.classification == classification.lp || mutation.classification == classification.ras
        })
        return pathogenicMutations
      }
    }
  })

  /**
   * @ngdoc filter
   * @name uniqueReferences
   * @memberof resultFilter
   *
   * @description
   * Create array of unique risk reference IDs
   */
  .filter('uniqueReferences', function() {
    return function(risks) {
      const uniqueReferences = []
      if (angular.isDefined(risks)) {
        angular.forEach(risks, function(risk) {
          angular.forEach(risk.references, function(reference) {
            if (uniqueReferences.indexOf(reference) == -1) {
              uniqueReferences.push(reference)
            }
          })
        })
      }
      return uniqueReferences
    }
  })

  /**
   * @ngdoc filter
   * @name isCancerSurvivor
   * @memberof resultFilter
   *
   * @description
   * Returns object fashioned with `_survivor` properties if applicable. Survivor logic is only shown for 8 cancers we cover in H30, and breast/ovarian/fallopian in BO19.
   */
  .filter('isCancerSurvivor', function(testTypes) {
    return function(healthHistory, testType) {

      function apiPropertyToReportContentProperty(prop) {
        // Converts 'had_foo_cancer' to 'foo_cancer_survivor'
        return prop.replace('had_', '') + '_survivor'
      }

      const survivorProperties = {};
      let validApiProperties = {}
      // Valid CancerProfile properties per monoweb/django_apps/health_profiles/models.py
      if (testType == testTypes.hereditary30) {
        validApiProperties = {
          unisex: ['had_breast_cancer', 'had_colorectal_cancer', 'had_gastric_cancer', 'had_melanoma', 'had_pancreatic_cancer'],
          female: ['had_ovarian_cancer', 'had_fallopian_tube_cancer', 'had_primary_peritoneal_cancer', 'had_endometrial_cancer'],
          male: ['had_prostate_cancer']
        }
      } else if (testType == testTypes.breastOvarian19) {
        validApiProperties = {
          unisex: ['had_breast_cancer'],
          female: ['had_ovarian_cancer', 'had_fallopian_tube_cancer'],
          male: []
        }
      } else {
        validApiProperties = {
          unisex: [],
          female: [],
          male: []
        }
      }

      if (healthHistory && healthHistory.female_health_profile) {
        angular.forEach(validApiProperties.unisex.concat(validApiProperties.female), function(prop) {
          if (healthHistory.female_health_profile[prop]) {
            survivorProperties[apiPropertyToReportContentProperty(prop)] = true
          }
        })
      } else if (healthHistory && healthHistory.male_health_profile) {
        angular.forEach(validApiProperties.unisex.concat(validApiProperties.male), function(prop) {
          if (healthHistory.male_health_profile[prop]) {
            survivorProperties[apiPropertyToReportContentProperty(prop)] = true
          }
        })
      }

      // If any survivorProperties exist, add generic survivor property
      if (!angular.equals({}, survivorProperties)) {
        survivorProperties.cancer_survivor = true
      }

      return survivorProperties
    }
  })

  /**
   * @ngdoc filter
   * @name hadBiopsyWithAtypicalHyperplasia
   * @memberof resultFilter
   *
   * @description
   * Returns true if healthHistory had breast biopsy with atypical hyperplasia. o/w false.
   */
  .filter('hadBiopsyWithAtypicalHyperplasia', function() {
    return function(healthHistory) {
      if (healthHistory && healthHistory.female_health_profile && healthHistory.female_health_profile.had_breast_biopsy) {
        return healthHistory.female_health_profile.had_breast_biopsy_with_atypical_hyperplasia == 'Y'
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name diagnosedWithLcis
   * @memberof resultFilter
   *
   * @description
   * Returns true if healthHistory had breast biopsy and diagnosed with LCIS. o/w false.
   */
  .filter('diagnosedWithLcis', function() {
    return function(healthHistory) {
      if (healthHistory && healthHistory.female_health_profile && healthHistory.female_health_profile.had_breast_biopsy) {
        return healthHistory.female_health_profile.diagnosed_with_lobular_carcinoma_in_situ == 'Y'
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name isElevated
   * @memberof resultFilter
   *
   * @description
   * Returns true if risk is "elevated", with or without a range (and not "not elevated")
   */
  .filter('isElevated', function() {
    return function(risk) {
      if (angular.isDefined(risk)) {
        return risk.indexOf('elevated') > -1 && risk.indexOf('not') == -1
      }
    }
  })

  /**
   * @ngdoc filter
   * @name isNotElevated
   * @memberof resultFilter
   *
   * @description
   * Returns true if risk is "not elevated" or "not currently elevated"
   */
  .filter('isNotElevated', function() {
    return function(risk) {
      if (angular.isDefined(risk)) {
        return risk == 'not elevated' || risk == 'not currently elevated'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name isStudiesOngoing
   * @memberof resultFilter
   *
   * @description
   * Returns true if risk is "studies ongoing"
   */
  .filter('isStudiesOngoing', function() {
    return function(risk) {
      if (angular.isDefined(risk)) {
        return risk == 'studies ongoing'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name isLikelyPathogenic
   * @memberof resultFilter
   *
   * @description
   * Returns true if mutations only contain likely pathogenic mutation and no pathogenic mutations, or if a simple mutation is likely pathogenic
   */
  .filter('isLikelyPathogenic', function() {
    return function(mutations) {
      if (angular.isDefined(mutations)) {
        if (!angular.isArray(mutations)) {
          return mutations.classification.toLowerCase() == 'likely pathogenic'
        }
        const classifications = mutations.map(function(mutation) {
          return mutation.classification.toLowerCase()
        })
        return classifications.indexOf('likely pathogenic') > -1 && classifications.indexOf('pathogenic') == -1
      } else {
        return false
      }
    }
  })
  /**
   * @ngdoc filter
   * @name hasLikelyPathogenic
   * @memberof resultFilter
   *
   * @description
   * Returns true if mutations contain likely pathogenic mutations
   */
  .filter('hasLikelyPathogenic', function() {
    return function(mutations) {
      if (angular.isDefined(mutations)) {
        if (!angular.isArray(mutations)) {
          return mutations.classification.toLowerCase() == 'likely pathogenic'
        }
        const classifications = mutations.map(function(mutation) {
          return mutation.classification.toLowerCase()
        })
        return classifications.indexOf('likely pathogenic') > -1
      } else {
        return false
      }
    }
  })

  /**
   * @ngdoc filter
   * @name hasBothPathogenic
   * @memberof resultFilter
   *
   * @description
   * Returns true if mutations contain both pathogenic and likely pathogenic mutations
   */
  .filter('hasBothPathogenic', function() {
    return function(mutations) {
      if (angular.isDefined(mutations)) {
        if (!angular.isArray(mutations)) {
          return false
        }
        const classifications = mutations.map(function(mutation) {
          return mutation.classification.toLowerCase()
        })
        return classifications.indexOf('likely pathogenic') > -1 && classifications.indexOf('pathogenic') > -1
      } else {
        return false
      }
    }
  })

  /**
   * @ngdoc filter
   * @name isPotentiallyCompoundHeterozygousReport
   * @memberof resultFilter
   *
   * @description
   * Returns true if reportContent has mutations that make it a potentially compound heterozygous report
   */
  .filter('isPotentiallyCompoundHeterozygousReport', function(classification, zygosity) {
    return function(reportContent, gene) {
      let isPotentiallyCompoundHeterozygousReport = false
      angular.forEach(reportContent.allelicity_by_gene, function(allelicity, gene) {
        if (allelicity == 'biallelic') {
          const homozygousMutations = reportContent.mutations.filter(function(mutation) {
            return mutation.zygosity == zygosity.homozygous && mutation.gene == gene && (mutation.classification == classification.p || mutation.classification == classification.lp || mutation.classification == classification.ras)
          })
          if (homozygousMutations.length == 0) {
            isPotentiallyCompoundHeterozygousReport = true
          }
        } else if (allelicity == null) {
          isPotentiallyCompoundHeterozygousReport = true
        }
      })
      return isPotentiallyCompoundHeterozygousReport
    }
  })

  /**
   * @ngdoc filter
   * @name isInCis
   * @memberof resultFilter
   *
   * @description
   * Returns true if reportContent has mutations that make it in cis report
   */
  .filter('isInCis', function($filter) {
    return function(reportContent, targetGene) {
      let isInCis = false
      angular.forEach(reportContent.allelicity_by_gene, function(allelicity, gene) {
        if (allelicity == 'monoallelic' && (!targetGene || targetGene == gene)) {
          const pathogenicMutations = $filter('pathogenicMutations')(reportContent.mutations)
          if ($filter('filter')(pathogenicMutations, {gene: gene}).length > 1) {
            isInCis = true
          }
        }
      })
      return isInCis
    }
  })

  /**
   * @ngdoc filter
   * @name isHomozygous
   * @memberof resultFilter
   *
   * @description
   * Returns true if a given variant is homozygous
   */
  .filter('isHomozygous', function(zygosity) {
    return function(variant) {
      return variant.zygosity == zygosity.homozygous
    }
  })

  /**
   * @ngdoc filter
   * @name isSingleHomozygousReport
   * @memberof resultFilter
   *
   * @description
   * Returns true if only 1 L/LP mutation in this report and it's homozygous
   */
  .filter('isSingleHomozygousReport', function($filter, classification) {
    return function(mutations) {
      if (angular.isDefined(mutations)) {
        const pathogenicMutations = mutations.filter(function(mutation) {
          return mutation.classification == classification.p || mutation.classification == classification.lp || mutation.classification == classification.ras
        })
        if (!angular.isArray(pathogenicMutations) || pathogenicMutations.length != 1) {
          return false
        }
        return $filter('isHomozygous')(pathogenicMutations[0])
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name isBiallelic
   * @memberof resultFilter
   *
   * @description
   * Checks if any gene has biallelic mutations.
   */
  .filter('isBiallelic', function() {
    return function(reportContent) {
      for (const gene in reportContent.allelicity_by_gene) {
        if (reportContent.allelicity_by_gene[gene] == 'biallelic') {
          return true
        }
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name useSingleRiskLogic
   * @memberof resultFilter
   *
   * @description
   * Returns true if report should use singleRisk logic
   */
  .filter('useSingleRiskLogic', function($filter) {
    return function(report, gene) {
      if (angular.isDefined(report.mutations)) {
        if ($filter('isPotentiallyCompoundHeterozygousReport')(report, gene)) {
          return true
        }
        return report.mutations.some(function(m) { return $filter('isHomozygous')(m) && (!gene || gene == m.gene) })
      }
      return false
    }
  })

  /**
   * @ngdoc filter
   * @name isSomaticReport
   * @memberof resultFilter
   *
   * @description
   * Returns true report should be considered under 'somatic/low allele' flag and changes reportContent.is_somatic to true.
   * Currently returns true for reports with any number of somatic P/LP mutations AND no germline P/LP. Germline VUS don't factor into this determination.
   *
   * DEPRECATION NOTICE: Only used on render_version 1, returns false on render_version >= 2
   *
   */
  .filter('isSomaticReport', function(classification) {
    return function(report) {
      if (report.render_version != 1) {
        return false
      }
      if (angular.isDefined(report.mutations)) {
        const pathogenics = report.mutations.filter(function(mutation) {
          return mutation.classification == classification.p || mutation.classification == classification.lp || mutation.classification == classification.ras
        })
        const variantTypes = pathogenics.map(function(mutation) {
          if (angular.isDefined(mutation.variant_type)) {
            return mutation.variant_type.toLowerCase()
          }
        })
        let allSomatic = !!variantTypes.length
        angular.forEach(variantTypes, function(c) {
          allSomatic = allSomatic && c == 'somatic'
        })
        return allSomatic
      } else {
        return false
      }
    }
  })

  /**
   * @ngdoc filter
   * @name reportTitle
   * @memberof resultFilter
   *
   * @description
   * Returns proper report title based contents of reportContent
   */
  .filter('reportTitle', function($filter, namedMutation) {
    return function(report) {
      let title = ""
      const genes = $filter('genesWithMutationName')(report.mutations)

      if (report.is_negative) { // Negative
        title = 'No mutations were identified.'
      } else if (report.is_non_reportable) { // Non reportable
        title = 'Unable to return results for this sample.'
      } else if (($filter('isSingleHomozygousReport')(report.mutations) || $filter('isPotentiallyCompoundHeterozygousReport')(report) || $filter('isInCis')(report)) && genes.length == 1) { // If single homozygous or compound hetero
        const isLikelyPathogenic = $filter('isLikelyPathogenic')(report.mutations)
        const hasBothPathogenic = $filter('hasBothPathogenic')(report.mutations)
        // APC I1307K is currently the only 'common' mutation
        const isCommon = genes[0].name == 'APC' && genes[0].mutationName == 'I1307K'

        // RV < 5 homozygous errantly says two genes, later revisions say "each copy of"
        if (report.render_version >= 5 && $filter('isSingleHomozygousReport')(report.mutations)) {
          title = "A "
          if (isCommon) {
            // APC I1307K is currently the only 'common' mutation
            title += 'common '
          }
          title += isLikelyPathogenic ? 'likely pathogenic' : 'pathogenic'
          title += " mutation was identified in each copy of the "
        } else {
          if (hasBothPathogenic) {
            title = "A pathogenic mutation and a likely pathogenic mutation"
          } else {
            title = "Two " // Applies to Compound Het and Homozyg with RV < 5
            // If common and homozygous
            if (isCommon) {
              // APC I1307K is currently the only 'common' mutation
              title += 'common '
            }
            title += isLikelyPathogenic ? 'likely pathogenic' : 'pathogenic'
            title += " mutations"
          }
          title += ' were identified in the '
        }
        title += $filter('genesString')([genes[0].name], 'gene')
        title += '.'
      } else if (genes.length) { // Multiple P/LP gene mutations
        const pathogenicMutations = [];
        const likelyPathogenicMutations = [];
        const commonLikelyPathogenicMutations = [];
        const commonLikelyPathogenicHomoyzgousMutations = [];
        let currentPositionCount = 0
        const generateMutationString = function(genes, likelyPathogenic, isHomozygous, isCommon, uniqueGeneGroupsCount) {
          if (genes.length) {
            const uniqueMutations = $filter('unique')(genes)
            currentPositionCount++
            if (genes.length == 1) {
              title += "a "
            }
            title += isCommon ? "common " : ""
            title += likelyPathogenic ? "likely " : ""
            title += "pathogenic mutation"
            if (genes.length == 1) {
              title += " was "
            } else if (genes.length > 1) {
              title += "s were "
            }
            title += "identified in "
            if (isHomozygous) {
              title += "each copy of "
            }
            title += "the "
            title += $filter('genesString')(uniqueMutations)
            if (uniqueMutations.length > 1) {
              title += " genes"
            } else {
              title += " gene"
            }
            if (currentPositionCount < uniqueGeneGroupsCount) {
              // This comma is intentional as it adds a pause between each mutation designation and is not supposed to follow list comma grammar
              title += ", "
              if (currentPositionCount == uniqueGeneGroupsCount - 1) {
                title += "and "
              }
            }
          }
        }

        const groupedGenes = {}
        // Group mutations by pathogenicity, zygosity, and frequency.
        angular.forEach($filter('pathogenicMutations')(report.mutations), function(mutation) {
          const key = [
            !$filter('isLikelyPathogenic')(mutation),
            $filter('isHomozygous')(mutation),
            // APC I1307K is the only named mutation currently that requires the "common" prefix
            !$filter('containsMutation')([mutation], namedMutation.apc_i1307k)
          ]
          if (!groupedGenes[key]) {
            groupedGenes[key] = [key, []]
          }
          groupedGenes[key][1].push(mutation.gene)
        })

        const uniqueGeneGroupsCount = Object.keys(groupedGenes).length
        // Converts grouped genes into a list with a sort key.
        const groupedList = Object.keys(groupedGenes).map(function(key) {
          const args = groupedGenes[key][0]
          // Sort group by pathogenicity, zygosity and then frequency.
          let sortKey = 0
          args.forEach(function(arg, index) {
            sortKey += arg ? Math.pow(10, (args.length - index)) : 0
          })
          return [groupedGenes[key], sortKey]
        })
        groupedList.sort(function(a, b) { return b[1] - a[1] })

        // Constructs report summary title.
        groupedList.forEach(function(group) {
          const args = group[0][0]
          const isLikelyPathogenic = !args[0]
          const isHomozygous = args[1]
          const isCommon = !args[2]
          const gene = group[0][1]
          generateMutationString(gene, isLikelyPathogenic, isHomozygous, isCommon, uniqueGeneGroupsCount)
        })

        // is_somatic is a rarely used flag, only exists in <5 reports total
        title += report.is_somatic ? ' in a small fraction of your DNA.' : '.'
        title = $filter('capitalize')(title, 'firstOnly')
      }

      return title
    }
  })

  /**
   * @ngdoc filter
   * @name tablePhenotypeString
   * @memberof resultFilter
   *
   * @description
   * Returns shortened phenotype strings for use in risk tables
   */
  .filter('tablePhenotypeString', function($filter) {
    return function(s) {
      if (angular.isString(s)) {
        if (/cancer$/.test(s) && s != 'any cancer') {
          s = s.replace(/\s*[cC]ancer$/, '')
        }
      }
      return $filter('capitalize')(s, 'firstOnly')
    }
  })

  /**
   * @ngdoc filter
   * @name nomenclature
   * @memberof resultFilter
   *
   * @description
   * Returns the appropriate nomenclature string based on mutation and type of string desired either 'primary', 'secondary', or 'transcript'
   *
   * @example
   * $filter('nomenclature')(mutation, 'primary') => '{{ cHGVS }} ({{ pHGVS }})'
   * $filter('nomenclature')(mutation, 'secondary') => '{{ gHGVS }}, {{ BIC }}'
   * $filter('nomenclature')(mutation, 'transcript') => 'ENST00000265849'
   */
  .filter('nomenclature', function($filter) {
    function removeTranscriptReference(nomenclature) {
      // Note: we need to handle cases with multiple colons in the nomenclature, e.g.:
      // "ENST00000342988:deletion of exons 1-10 (chr18.GRCh37:g.48400713_48599018delinsCAATTGT)" ->
      // "deletion of exons 1-10 (chr18.GRCh37:g.48400713_48599018delinsCAATTGT)"
      const pos = nomenclature.indexOf(':')
      if (pos >= 0) {
        return nomenclature.substring(pos + 1)
      } else {
        return nomenclature
      }
    }

    function getTranscriptReference(nomenclature) {
      const split = nomenclature.split(':')
      if (split.length > 1) {
        return split[0]
      }
    }

    return function(mutation, type) {
      if (angular.isDefined(mutation)) {
        if (type == 'primary') {
          if (mutation.effect == 'CNV') {
            let exon = $filter('filter')(mutation.nomenclatures, { type: 'Exon', is_primary: true })[0].name
            exon = removeTranscriptReference(exon)
            return exon
          } else {
            const cHGVS = $filter('filter')(mutation.nomenclatures, { type: 'cHGVS', is_primary: true })[0].name;
            let pHGVS = $filter('filter')(mutation.nomenclatures, { type: 'pHGVS', is_primary: true });

            let primaryNomenclature

            primaryNomenclature = removeTranscriptReference(cHGVS)

            if (pHGVS.length) {
              pHGVS = removeTranscriptReference(pHGVS[0].name)
              primaryNomenclature += ' (' + pHGVS + ')'
            }
            return primaryNomenclature
          }
        } else if (type == 'secondary') {
          let secondary
          const isCNV = mutation.effect == 'CNV' ? true : false
          let bic = $filter('filter')(mutation.nomenclatures, { type: 'BIC' })
          bic = $filter('orderBy')(bic, '-is_primary')
          const bicNames = bic.map(function(b) {
            return b.name
          })
          const secondaryNomenclature = []
          const gHGVS = $filter('filter')(mutation.nomenclatures, { type: 'gHGVS', is_primary: true })
          if (gHGVS.length) {
            secondaryNomenclature.push(gHGVS[0].name)
          }
          if (secondaryNomenclature.length) {
            secondary = secondaryNomenclature.join(', ')
          }
          if (bicNames.length) {
            if (secondary) {
              secondary += isCNV ? ', BIC: ' : ', '
              secondary += bicNames.join(', ')
            } else {
              secondary = isCNV ? 'BIC: ' : ''
              secondary += bicNames.join(', ')
            }
          }
          return secondary
        } else if (type == 'transcript') {
          if (mutation.effect != 'CNV') {
            const cHGVS = $filter('filter')(mutation.nomenclatures, { type: 'cHGVS', is_primary: true })[0].name
            return getTranscriptReference(cHGVS)
          } else if (mutation.effect == 'CNV') {
            const exon = $filter('filter')(mutation.nomenclatures, { type: 'Exon', is_primary: true })[0].name
            return getTranscriptReference(exon)
          }
        }
      }
    }
  })

  /**
   * @ngdoc filter
   * @name riskString
   * @memberof resultFilter
   *
   * @description
   * Returns transformed risk value strings for frontend display purposes.
   */
  .filter('riskString', function($filter) {

    // Match for "elevated [N%]" and "elevated [M-N%]" risks
    const matchBracketedPercentage = /\[[0-9\-\.]*%\]$/
    const matchArrowNotation = /[\d\.]+->/

    return function(s) {
      if (angular.isString(s)) {
        // Add space around dash of ranges if not also "Elevated", "10-24%" -> "10 - 24%"
        if (s.indexOf('-') > -1 && s.indexOf('elevated') == -1) {
          s = s.replace('-', ' - ')
        }
        // Is "elevated [N%]", removes, the bracketed value
        if (matchBracketedPercentage.test(s)) {
          s = s.replace(matchBracketedPercentage, '').trim()
        }
        // If range has arrow notation, e.g. "2->4%", only show upper bound
        if (matchArrowNotation.test(s)) {
          s = s.replace(matchArrowNotation, '')
        }
        if (s == 'not elevated') {
          s = 'Not Known To Be Elevated'
        }
        if (s == 'not currently elevated') {
          s = 'Not Currently Known To Be Elevated'
        }
        if (s == 'studies ongoing') {
          s = 'Studies Ongoing'
        }
        s = $filter('capitalize')(s)
      }
      return s
    }
  })

/**
   * @ngdoc filter
   * @name cancerString
   * @memberof resultFilter
   *
   * @description
   * Returns a grammatically correct string for phenotypes that exist in reportContent risks object.
   */

/**
   * Sample expected outputs:
   * breast cancer
   * breast and ovarian cancer
   * breast, ovarian and prostate cancer
   * colorectal, urinary tract, gastric, central nervous system, small bowel, pancreatic, hepatobiliary tract cancer and sebaceous neoplasm
   */

  .filter('cancerString', function($filter) {
    const generateCancerString = function(cancers, others) {
      cancers[cancers.length - 1] = cancers[cancers.length - 1] + ' cancer'
      const combined = cancers.concat(others)
      if (combined.length == 2) {
        return combined.join(" and ")
      } else if (combined.length > 2) {
        const last = combined.pop()
        return combined.join(", ") + " and " + last
      } else {
        return combined[0]
      }
    }

    return function(risks, allelicity) {
      let cancers = []
      let others = []

      angular.forEach(risks, function(risk) {
        if (allelicity && risk.allelicity != allelicity) {
          return
        }
        if (risk.phenotype.indexOf('cancer') > -1) {
          cancers.push(risk.phenotype.replace(/\s*cancer(s)?/, '').toLowerCase()) // remove ' cancer' from phenotype names because they're typically combined or displayed without their ' cancer' suffix
        } else {
          others.push(risk.phenotype.toLowerCase())
        }
      })

      // Some risks are duplicated from allelicity (like MUTYH), so when constructing a string, remove any duplicates
      cancers = $filter('unique')(cancers)
      others = $filter('unique')(others)

      return generateCancerString(cancers, others)
    }
  })

/**
     * @ngdoc filter
     * @name getPrimaryPhenotypeOrder
     * @memberof resultFilter
     *
     * @description
     * Returns a array of phenotypes considered primary, basically what shows on the main parts of reports.
     */

  .filter('getPrimaryPhenotypeOrder', function($filter, testTypes) {
    return function(genes, gender, testType, allelicity) {
      if (!angular.isArray(genes)) {
        genes = [genes]
      }

      const phenotypes = []

      function addPhenotypes(phenotypesToAdd) {
        phenotypesToAdd.forEach(function(p) {
          if (phenotypes.indexOf(p) == -1) {
            phenotypes.push(p)
          }
        })
      }

      if (testType == testTypes.hereditary30) {
        if ($filter('containsAny')(genes, ['APC', 'GREM1', 'MUTYH'])) {
          addPhenotypes(['colorectal cancer'])
        }
        if ($filter('containsAny')(genes, ['ATM'])) {
          if (gender == 'F') {
            addPhenotypes(['breast cancer'])
          } else {
            addPhenotypes(['pancreatic cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['BRCA1'])) {
          if (gender == 'F') {
            addPhenotypes(['breast cancer', 'ovarian cancer'])
          } else {
            addPhenotypes(['male breast cancer', 'pancreatic cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['BRCA2'])) {
          if (!allelicity || allelicity == 1) {
            addPhenotypes(['breast cancer', 'ovarian cancer', 'male breast cancer', 'prostate cancer'])
          }
          if (!allelicity || allelicity == 2) {
            addPhenotypes(['acute myeloid leukemia'])
          }
        }
        if ($filter('containsAny')(genes, ['BAP1'])) {
          addPhenotypes(['melanoma'])
        }
        if ($filter('containsAny')(genes, ['BARD1'])) {
          addPhenotypes(['breast cancer'])
        }
        if ($filter('containsAny')(genes, ['BMPR1A'])) {
          addPhenotypes(['colorectal cancer', 'stomach cancer'])
        }
        if ($filter('containsAny')(genes, ['BRIP1'])) {
          addPhenotypes(['breast cancer', 'ovarian cancer'])
        }
        if ($filter('containsAny')(genes, ['CDH1'])) {
          addPhenotypes(['gastric cancer', 'breast cancer'])
        }
        if ($filter('containsAny')(genes, ['CDK4'])) {
          addPhenotypes(['melanoma'])
        }
        if ($filter('containsAny')(genes, ['CDKN2A'])) {
          addPhenotypes(['melanoma', 'pancreatic cancer'])
        }
        if ($filter('containsAny')(genes, ['EPCAM'])) {
          if (gender == 'F') {
            addPhenotypes(['ovarian cancer', 'colorectal cancer', 'uterine cancer', 'breast cancer'])
          } else {
            addPhenotypes(['colorectal cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['MLH1'])) {
          if (gender == 'F') {
            addPhenotypes(['colorectal cancer', 'uterine cancer', 'ovarian cancer'])
          } else {
            addPhenotypes(['colorectal cancer', 'stomach cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['MSH2'])) {
          if (gender == 'F') {
            addPhenotypes(['colorectal cancer', 'uterine cancer', 'ovarian cancer'])
          } else {
            addPhenotypes(['colorectal cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['MSH6'])) {
          addPhenotypes(['colorectal cancer', 'uterine cancer', 'ovarian cancer'])
        }
        if ($filter('containsAny')(genes, ['CHEK2'])) {
          if (gender == 'F') {
            addPhenotypes(['breast cancer'])
          } else {
            addPhenotypes(['colorectal cancer', 'prostate cancer'])
          }
        }
        if ($filter('containsAny')(genes, ['RAD51C', 'RAD51D'])) {
          addPhenotypes(['ovarian cancer'])
        }
        if ($filter('containsAny')(genes, ['MITF'])) {
          addPhenotypes(['melanoma'])
        }
        if ($filter('containsAny')(genes, ['PALB2'])) {
          addPhenotypes(['breast cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['PMS2'])) {
          addPhenotypes(['colorectal cancer', 'uterine cancer', 'ovarian cancer'])
        }
        if ($filter('containsAny')(genes, ['PTEN'])) {
          addPhenotypes(['breast cancer', 'thyroid cancer', 'kidney cancer', 'uterine cancer'])
        }
        if ($filter('containsAny')(genes, ['STK11'])) {
          addPhenotypes(['breast cancer', 'colorectal cancer', 'stomach cancer'])
        }
        if ($filter('containsAny')(genes, ['SMAD4'])) {
          addPhenotypes(['colorectal cancer', 'stomach cancer'])
        }
        if ($filter('containsAny')(genes, ['TP53'])) {
          addPhenotypes(['breast cancer', 'brain cancer', 'sarcoma (bone) cancer', 'sarcoma (soft tissue) cancer', 'any cancer'])
        }
        if (!phenotypes.length) {
          addPhenotypes(['breast cancer', 'prostate cancer', 'colorectal cancer'])
        }
      } else if (testType == testTypes.breastOvarian19) {
        if ($filter('containsAny')(genes, ['PMS2', 'MSH2', 'MSH6', 'MLH1', 'EPCAM'])) {
          addPhenotypes(['ovarian cancer', 'colorectal cancer', 'uterine cancer', 'breast cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['CDH1'])) {
          addPhenotypes(['breast cancer', 'gastric cancer', 'ovarian cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['PTEN'])) {
          addPhenotypes(['breast cancer', 'kidney cancer', 'thyroid cancer', 'uterine cancer', 'ovarian cancer'])
        }
        if ($filter('containsAny')(genes, ['RAD51C', 'RAD51D'])) {
          addPhenotypes(['ovarian cancer', 'breast cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['TP53'])) {
          addPhenotypes(['breast cancer', 'ovarian cancer', 'brain cancer', 'sarcoma (bone) cancer', 'sarcoma (soft tissue) cancer', 'any cancer'])
        }
        if ($filter('containsAny')(genes, ['ATM']) && gender == 'M') {
          addPhenotypes(['pancreatic cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['CHEK2']) && gender == 'M') {
          addPhenotypes(['colorectal cancer', 'prostate cancer', 'male breast cancer'])
        }
        if ($filter('containsAny')(genes, ['STK11']) && gender == 'F') {
          addPhenotypes(['breast cancer', 'colorectal cancer', 'stomach cancer'])
        }
        if (!phenotypes.length) {
          addPhenotypes(['breast cancer', 'ovarian cancer', 'male breast cancer'])
        }
      } else {
        throw 'sortRiskByPhenotype testType not defined'
      }
      return phenotypes
    }
  })

/**
   * @ngdoc filter
   * @name sortRiskByPhenotype
   * @memberof resultFilter
   *
   * @description
   * Returns set of sorted risks based on risks and tier of cancers desired, useful on sample letter and result charts
   *
   * @param {object} risks               - This is typically used in concert with `$filter('sortedRisks')` on the resulting `risks.pathogenic` or `risks.baseline`
   * @param {string or array} cancerTier - 'primary', 'secondary', 'all', or an array that constitutes as primary
   * @param {array} genes                - A list of genes to sort against
   * @param {string} testType            - 'breastOvarian19', 'hereditary30', 'wisdom9'
   */

  .filter('sortRiskByPhenotype', function($filter, testTypes) {
    return function(risks, cancerTier, genes, testType) {
      const primaryPhenotypeOrder = $filter('getPrimaryPhenotypeOrder')(genes, risks.length ? risks[0].gender : null, testType)
      function comparator(risk) {
        let val = primaryPhenotypeOrder.indexOf(risk.phenotype)
        if (val < 0) {
          val = primaryPhenotypeOrder.length
          val += risks.indexOf(risk)
        }
        return val
      }
      // Make copy of risks (avoid issues with mutability) then order with primaries first
      // Ideally we also order by alphabet before primaries first, but because of not wanting to re-issue non-impactful changes on released reports, this is not done.
      risks = $filter('orderBy')(angular.copy(risks), comparator)
      // On Hereditary 30, force alphabetical ordering first, then prefer primaryPhenotypes as needed, because this is the preferred order on everything but primary
      if (testType == testTypes.hereditary30 && cancerTier != 'primary') {
        risks = $filter('orderBy')(risks, 'phenotype')
      }
      return risks.filter(function(risk) {
        if (angular.isArray(cancerTier)) {
          return $filter('containsAny')([risk.phenotype], cancerTier)
        } else if (cancerTier == 'primary') {
          return $filter('containsAny')([risk.phenotype], primaryPhenotypeOrder)
        } else if (cancerTier == 'all') {
          return true
        } else {
          return $filter('containsNone')([risk.phenotype], primaryPhenotypeOrder)
        }
      })
    }
  })

/**
   * @ngdoc filter
   * @name genesWithPathogenicMutations
   * @memberof resultFilter
   *
   * @description
   * Returns list of genes using sortMutations filter, which handles ordering based on classification and special use cases
   */

  .filter('genesWithPathogenicMutations', function($filter, classification) {
    return function(mutations) {
      mutations = mutations.filter(function(mutation) {
        return mutation.classification == classification.lp || mutation.classification == classification.p || mutation.classification == classification.ras
      })
      if (mutations.length == 0) {
        return []
      }
      const sortedMutations = $filter('sortMutations')(mutations)
      return $filter('unique')(sortedMutations.map(function(mutation) {
        return mutation.gene
      }))
    }
  })

/**
   * @ngdoc filter
   * @name sortMutations
   * @memberof resultFilter
   *
   * @description
   * Returns reordered list of reportContent.mutations, sorted by classification and alphabetical (with respect to special gene combo use cases)
   */

  .filter('sortMutations', function($filter) {
    function setMutationsGenesOrder(genes) {
      if ($filter('containsAll')(genes, ['ATM', 'BRCA2'])) {
        return ['BRCA2', 'ATM']
      } else {
        return genes
      }
    }

    return function(mutations) {
      // Return mutations if there is 0 or 1, no need to sort
      if (mutations.length <= 1) {
        return mutations
      }
      // Initially order mutations by genes, alphabetically
      mutations = $filter('orderBy')(angular.copy(mutations), 'gene')
      const genes = []
      angular.forEach(mutations, function(mutation) {
        genes.push(mutation.gene)
      })
      // Then set order mutations by genes, alphabetically
      const mutationsGenesOrder = setMutationsGenesOrder(genes)
      function comparator(mutation) {
        let val = mutationsGenesOrder.indexOf(mutation.gene)
        if (val < 0) {
          val = mutationsGenesOrder.length
          val += mutations.indexOf(mutation.gene)
        }
        return val
      }
      // Order by genes first
      mutations = $filter('orderBy')(mutations, comparator)
      // Then order by classification (because only "Pathogenic" and "Likely Pathogenic" exist, order "Pathogenic" first)
      mutations = $filter('orderBy')(mutations, '-classification')
      return mutations
    }
  })

/**
   * @ngdoc filter
   * @name containsMutation
   * @memberof resultFilter
   *
   * @description
   * Returns true if requested gHGVS mutation name exists inside mutations
   */

  .filter('containsMutation', function($filter) {
    return function(mutations, mutationName) {
      const count = 0
      for (let i = 0; i < mutations.length; i++) {
        if ($filter('filter')(mutations[i].nomenclatures, { type: 'gHGVS', name: mutationName }).length) {
          return true
        }
      }
      return false
    }
  })

/**
   * @ngdoc filter
   * @name getRiskByAge
   * @memberof resultFilter
   *
   * @description
   * Get the risk number by age, defaults to 80
   */

  .filter('getRiskByAge', function($filter) {
    return function(risks, age) {
      if (angular.isDefined(risks)) {
        age = age ? age : 80
        const result = $filter('filter')(risks, { 'age': age })
        if (result.length) {
          return result[0].risk
        }
      }
    }
  })

/**
   * @ngdoc filter
   * @name orderType
   * @memberof resultFilter
   */

  .filter('orderType', function() {
    return function(kit_order) {
      // TODO: Change return type once we integrate with other ordering networks
      if (kit_order.independent_physician_order) {
        return 'PWN'
      } else if (kit_order.physician_order) {
        return 'External'
      } else {
        return 'Internal'
      }
    }
  })

/**
   * @ngdoc filter
   * @name geneArticle
   * @memberof resultFilter
   *
   * @description
   * Writes the proper "a {{gene}}" or "an {{gene}}", with default <em> wrapper around gene
   */

  .filter('geneArticle', function($filter) {
    function presentGene(gene, noWrap, isSinglePrefix) {
      let article;
      const anArticleGenes = ['APC', 'APOB', 'ATM', 'EPCAM', 'LDLR', 'LDLRAP1', 'MLH1', 'MSH2', 'MSH6', 'STK11']
      if (isSinglePrefix) {
        article = 'a single '
      } else if (anArticleGenes.indexOf(gene) > -1) {
        article = 'an '
      } else {
        article = 'a '
      }
      return noWrap ? article + gene : article + '<em>' + gene + '</em>'
    }
    return function(genes, noWrap, isSinglePrefix) {
      if (angular.isString(genes)) {
        // Infers this is a single gene if it's a string, most common use case
        return presentGene(genes, noWrap, isSinglePrefix)
      } else if (angular.isArray(genes)) {
        const wrappedGenes = []
        angular.forEach(genes, function(gene) {
          wrappedGenes.push(presentGene(gene, noWrap, isSinglePrefix))
        })
        return $filter('genesString')(wrappedGenes, null, true)
      }
    }
  })

/**
   * @ngdoc filter
   * @name genesString
   * @memberof resultFilter
   *
   * @description
   * Writes a collection of genes in string form, with optional <em> wrappers
   */

  .filter('genesString', function() {
    return function(genes, nounAppend, noWrap) {
      let result = ''
      angular.forEach(genes, function(gene, index) {
        if (index == genes.length - 1 && genes.length != 1) {
          result += ' and '
        } else if (index != 0) {
          result += ', '
        }
        result += noWrap ? gene : '<em>' + gene + '</em>'
      })
      if (nounAppend) {
        result += ' ' + nounAppend
        if (genes.length > 1) {
          result += 's'
        }
      }
      return result
    }
  })

  /**
   * @ngdoc filter
   * @name getReportReferences
   * @memberof resultFilter
   *
   * @description
   * Returns array of references based on reportContent and gender, deduped and sorted.
   */
  .filter('getReportReferences', function($filter) {
    function addReference(references, reference) {
      if (references.indexOf(reference) == -1) {
        references.push(reference)
      }
    }
    return function(report, gender) {
      const reportReferences = []
      const sortedRisks = $filter('sortedRisks')(report, gender)

      if (report.is_positive) {
        angular.forEach(sortedRisks.pathogenic, function(risk) {
          if (risk.allelicity != '2' || report.allelicity_by_gene[risk.gene] != 'monoallelic') {
            angular.forEach(risk.references, function(r) {
              addReference(reportReferences, r)
            })
          }
        })
      }
      angular.forEach(sortedRisks.baseline, function(risk) {
        risk.references.map(function(r) {
          addReference(reportReferences, r)
        })
      })
      // New addition with Wisdom reports, not all reports have "summary_references"
      angular.forEach(report.template.summary_references, function(r) {
        addReference(reportReferences, r)
      })
      angular.forEach(report.template.screening_guideline_intro_references, function(r) {
        addReference(reportReferences, r)
      })
      angular.forEach(report.template.screening_guidelines, function(guideline) {
        guideline.references.map(function(r) {
          addReference(reportReferences, r)
        })
      })
      if (report.template.incidental_finding_references) {
        angular.forEach(report.template.incidental_finding_references, function(r) {
          addReference(reportReferences, r)
        })
      }
      if (report.template.additional_family_impact_references) {
        angular.forEach(report.template.additional_family_impact_references, function(r) {
          addReference(reportReferences, r)
        })
      }
      return reportReferences.sort()
    }
  })

  /**
   * @ngdoc filter
   * @name sortedRisks
   * @memberof resultFilter
   *
   * @description
   * Returns sorted set of risks, both pathogenic and baseline,
   * based on gender and the risk ages as defined in the pathogenic risks.
   * Can optionally, only show a specified phenotype, gene or allelicity.
   */
  .filter('sortedRisks', function($filter, testTypes) {
    return function(reportContent, gender, removeNotIncreasedPathogenicRisks, phenotype, gene, allelicity) {

      if (angular.isUndefined(reportContent)) {
        return
      }

      // Set gender to same as reportContent if none defined
      if (angular.isUndefined(gender)) {
        gender = reportContent.gender
      }
      // Allow both genders if 'A', else match against 'F' or 'M'
      function ifGender(riskGender, gender) {
        return gender == 'A' || riskGender == gender
      }

      // Returns true if undefined, which means it's processing baseline where gene is undefined
      function ifGene(riskGene, gene) {
        return angular.isUndefined(gene) || riskGene == gene
      }

      let negativePhenotypes = []
      if (reportContent.template.test_type == testTypes.breastOvarian19) {
        negativePhenotypes = ['breast cancer', 'ovarian cancer', 'pancreatic cancer', 'male breast cancer', 'prostate cancer']
      } else if (reportContent.template.test_type == testTypes.hereditary30) {
        negativePhenotypes = ['breast cancer', 'prostate cancer', 'colorectal cancer']
      }

      const risks = {}
      // DO NOT REMOVE: Funky mutability issue when using subsequent `.filter()`s, even though we don't touch the original `reportContent` object
      // TODO, Clean this up: https://getcolor.atlassian.net/browse/ENG-27
      const reportContentClone = angular.copy(reportContent)

      // List of risks based on gender (and optionally phenotype and/or gene and/or allelicity)
      risks.pathogenic = reportContentClone.risks.filter(function(risk) {
        if (risk.gene && ifGender(risk.gender, gender) && (!phenotype || risk.phenotype == phenotype) && ifGene(risk.gene, gene) && (!allelicity || risk.allelicity == allelicity)) {
          return risk
        }
      })

      // List of risks based on gender (and optionally phenotype and/or gene)
      // If pathogenic risks exist, match baseline risk ages against pathogenic risk ages
      risks.baseline = reportContentClone.risks.filter(function(risk) {
        // If risk is baseline risk and gender matches and phenotype matches
        if (!risk.gene && ifGender(risk.gender, gender) && (!phenotype || risk.phenotype == phenotype)) {
          // Basic check for undefined pathogenic risks existing for each baseline risk
          if (risks.pathogenic.length) {
            // Filter pathogenic risks for matching phenotype and gender
            const matchedPathogenicRisks = risks.pathogenic.filter(function(r) {
              return r.phenotype == risk.phenotype && r.gender == risk.gender
            })
            // Check if matched pathogenic risk exists
            if (matchedPathogenicRisks.length) {
              // Collect ages represented in pathogenic risks
              const matchedPathogenicAges = []
              angular.forEach(matchedPathogenicRisks, function(matchedPathogenicRisk) {
                angular.forEach(matchedPathogenicRisk.data, function(r) {
                  if (matchedPathogenicAges.indexOf(r.age) == -1) {
                    matchedPathogenicAges.push(r.age)
                  }
                })
              })
              // Filter baseline risks to only ages that exist in the matched pathogenic risks
              risk.data = risk.data.filter(function(data) {
                return matchedPathogenicAges.indexOf(data.age) != -1
              })
              return risk
            }
          } else if (reportContentClone.is_negative && $filter('containsAny')([risk.phenotype], phenotype ? [phenotype] : negativePhenotypes)) {
            return risk
          }
        }
      })

      // Optionally remove pathogenic risks that have a single risk guideline that is 'not elevated' or have the same risks as baseline
      if (removeNotIncreasedPathogenicRisks) {
        risks.pathogenic = risks.pathogenic.filter(function(pRisk) {
          const bRisk = $filter('filter')(risks.baseline, { phenotype: pRisk.phenotype, age: pRisk.age })[0]
          // No pathogenic risks, cause none exist (like RAD51C M)
          if (!pRisk.data.length) {
            return false
          }
          // Check each risk number to see if it's `not elevated` or `studies ongoing`
          const notIncreasedPRisks = pRisk.data.filter(function(p) {
            return !($filter('isNotElevated')(p.risk) || $filter('isStudiesOngoing')(p.risk))
          })
          // Single pathogenic risk that is 'not elevated'
          if (!notIncreasedPRisks.length) {
            return false
          }

          // If same risk numbers are the same as baseline
          return !angular.equals(pRisk.data, bRisk.data)
        })
      }

      return {
        baseline: risks.baseline,
        pathogenic: risks.pathogenic
      }
    }
  })

  /**
   * @ngdoc filter
   * @name allRisksNotElevated
   * @memberof resultFilter
   *
   * @description
   * Return true if all risks in a risks object only contain `not elevated` or `not currentl elevated`
   */
  .filter('allRisksNotElevated', function($filter) {
    return function(risks) {
      if (risks.length) {
        for (let i = 0; i < risks.length; i++) {
          for (let j = 0; j < risks[i].data.length; j++) {
            if (!$filter('isNotElevated')(risks[i].data[j].risk)) {
              return false
            }
          }
        }
      }
      return true
    }
  })

  /**
   * @ngdoc filter
   * @name getSingleRisk
   * @memberof resultFilter
   *
   * @description
   * Get a single risk number from reportContent.risks
   */
  .filter('getSingleRisk', function() {
    return function(risks, gender, phenotype, age, gene) {
      let singleRisk
      angular.forEach(risks, function(risk) {
        if (risk.gender == gender && risk.phenotype == phenotype && (gene ? risk.gene == gene : risk.gene == "")) {
          angular.forEach(risk.data, function(r) {
            if (r.age == age) {
              singleRisk = r.risk
            }
          })
        }
      })
      return singleRisk
    }
  })

  /**
   * @ngdoc filter
   * @name clinicalCancerNames
   * @memberof resultFilter
   *
   * @description
   * Convert cancer constants used on the frontend to the clinical names that are used in the backend and are returned
   * by health profile serializers such as CancerProfileSerializerMixin
   */
  .filter('clinicalCancerNames', function(cancerToClinicalName) {
    return function(cancers) {
      return cancers.map((c) => cancerToClinicalName[c])
    }
  })

  .filter('cancerHistory', function($filter, cancersCovered) {
    return function(profile) {
      const history = profile.cancers.filter(
        (c) => $filter('clinicalCancerNames')(cancersCovered).indexOf(c.name) != -1
      ).map(
        (c) => c.name.replace(' cancer', '')
      )
      if (profile.had_breast_biopsy_with_atypical_hyperplasia == 'Y') {
        history.push('AH')
      }
      if (profile.diagnosed_with_lobular_carcinoma_in_situ == 'Y') {
        history.push('LCIS')
      }
      if (profile.had_mastectomy) {
        history.push('Mastectomy')
      }
      if (profile.had_oophorectomy) {
        history.push('Oophorectomy')
      }
      if (profile.significant_polyps) {
        history.push('Polyps')
      }
      return history.length == 0 ? 'None' : history.join(', ')
    }
  })

  .filter('relativesCancerHistory', function($filter, cancersCovered, clinicalCancerName) {
    return function(relatives) {
      const phenotypes = {}
      for (const cancerName of $filter('clinicalCancerNames')(cancersCovered)) {
        phenotypes[cancerName] = {fdr_count: 0, count: 0}
      }
      for (const relative of relatives) {
        let relativePhenotypes = []
        if (relative.female_relative_health_profile) {
          relativePhenotypes = relative.female_relative_health_profile.cancers
        } else if (relative.male_relative_health_profile) {
          relativePhenotypes = relative.male_relative_health_profile.cancers
        }
        for (const relativePhenotype of relativePhenotypes) {
          if (relativePhenotype.name in phenotypes) {
            phenotypes[relativePhenotype.name].count += 1
            if (relative.relationship_is_first_degree) {
              phenotypes[relativePhenotype.name].fdr_count += 1
            }
          }
        }
      }

      const phenotypesList = Object.keys(phenotypes).filter(
        (cancerName) => phenotypes[cancerName].count > 0
      ).map((cancerName) => {
        const fdrCount = phenotypes[cancerName].fdr_count
        const significant = (
          ([clinicalCancerName.OVARIAN, clinicalCancerName.PROSTATE, clinicalCancerName.MELANOMA, clinicalCancerName.COLORECTAL].indexOf(cancerName) != -1 && fdrCount >= 1)
          || (cancerName == clinicalCancerName.PANCREATIC && fdrCount >= 2)
        )
        // Remove the word 'cancer' from the cancer name in order to save space
        const shortName = cancerName.replace(' cancer', '')
        return {name: shortName, significant: significant}
      })

      return phenotypesList.length ? phenotypesList : [{name: 'None', significant: false}]
    }
  })

  .filter('relativesCardioHistory', function($filter) {
    return function(relatives) {
      let phenotypes = relatives.map(function(relative) {
        return relative.cardio_history.map(function(c) { return c.name })
      }).reduce(function(a, b) { return a.concat(b) })

      phenotypes = $filter('unique')(phenotypes)

      return phenotypes.length ? phenotypes.map(function(p) {
        return {name: p, significant: false}
      }) : [{name: 'None', significant: false}]
    }
  })

  /**
   * @ngdoc filter
   * @name orderReportedVariants
   * @memberof resultFilter
   *
   * @description
   * Order variants by classification and gene.
   */
  .filter('orderReportedVariants', function($filter, classification) {
    const pathogenicClassifications = [classification.p, classification.lp, classification.ras, classification.vus]
    const benignClassifications = [classification.lb, classification.b, classification.rai]

    const byClassification = function(variant) {
      // order pathogenic/vus variants by pathogenicity, and put all benign/likely benign variants at the end
      let sortKey = pathogenicClassifications.indexOf(variant.classification)
      if (sortKey == -1) {
        sortKey = pathogenicClassifications.length
      }
      return sortKey
    }

    return function(variants, showBenign) {
      if (!showBenign) {
        variants = $filter('filter')(variants, function(variantCall) {
          return benignClassifications.indexOf(variantCall.classification) == -1
        })
      }
      variants = $filter('orderBy')(variants, [byClassification, (variant) => variant.gene])
      return variants
    }
  })

  /**
   * @ngdoc filter
   * @name orderVariants
   * @memberof resultFilter
   *
   * @description
   * Order variants by classification and gene.
   */
  .filter('orderVariants', function($filter, classification) {
    const variantClassificationsToShow = [
      classification.p, classification.lp, classification.ras, 'VUS'];

    const benignClassifications = [classification.lb, classification.b, classification.rai]
    const byClassification = function(srv) {
      let minkey = -1
      // select the most severe classification for this variant
      for (let i = 0; i < srv.variant_classifications.length; i++) {
        if (srv.variant_classifications[i].state == 'reviewed') {
          const key = variantClassificationsToShow.indexOf(
            srv.variant_classifications[i].classification.short_name)
          if (minkey < 0 || key > -1 && key < minkey) {
            minkey = key
          }
        }
      }
      if (minkey == -1) {
        minkey = variantClassificationsToShow.length
      }
      return minkey
    }

    const byGene = function(srv) {
      return srv.variant_classifications[0].classification_unit.variant.gene
    }

    return function(variants, showBenign) {
      if (angular.isUndefined(variants)) {
        return []
      }

      variants = variants.filter(function(srv) {
        return angular.isDefined(srv.variant_classifications) && srv.variant_classifications.length > 0
      })

      variants = $filter('orderBy')(variants, [byClassification, byGene])
      if (!showBenign) {
        variants = $filter('filter')(variants, function(srv) {
          for (let i = 0; i < srv.variant_classifications.length; i++) {
            const one_vc = srv.variant_classifications[i]
            if (benignClassifications.indexOf(one_vc.classification.short_name) == -1 || one_vc.state != 'reviewed') {
              return true
            }
          }
          return false
        })
      }
      return variants
    }
  })

  /**
   * @ngdoc filter
   * @name testMethodologyVersion
   * @memberof resultFilter
   *
   * @description
   * Returns version of test methodologies based on sample.recieved_at date.
   */
  .filter('testMethodologyVersion', function(testTypes) {
    return function(reportContent) {
      /* Date comparison required to handle updated test methodologies section to reflect:
       * BO19:
       *   Version 1: Initial release.
       *   Version 2: Add CAP #, remove GATK version. Effective for kits received after Nov 7th, 2015 UTC.
       *   Version 3: Changes required for NYC application.
       *   Version 4: Add CE mark, match content structure of H30 reports.
       *   Version 5: VUS content update, changes regarding structural variants, added asterisk below gene list.
       *   Version 6: Should always be backend-set, has blood language along with NY Lab statement.
       *   Version 7: Should always be backend-set, secondary confirmation languate updated for NY.
       * H30:
       *   Version 1: Initial release.
       *   Version 2: VUS content update, changes regarding structural variants.
       *   Version 3: Should always be backend-set, has blood language along with NY Lab statement.
       *   Version 4: Should always be backend-set, secondary confirmation languate updated for NY.
       * W9:
       *   Version 1: Initial release.
       *   Version 2: General language now conforms with equivalent BO19 and H30 versions.
       */

      // Use backend-set test methodology version if value exists in report object
      if (angular.isString(reportContent.test_methodology_version)) {
        const backendTestVersionString = reportContent.test_methodology_version.split("_")
        // Check for existence of `_`
        if (backendTestVersionString.length == 2) {
          const backendTestType = backendTestVersionString[0]
          const backendVersion = backendTestVersionString[1]
          if (reportContent.template.test_type == testTypes.breastOvarian19 && backendTestType == "bo19" || reportContent.template.test_type == testTypes.hereditary30 && backendTestType == "h30" || reportContent.template.test_type == testTypes.wisdom9 && backendTestType == "w9" || reportContent.template.test_type == testTypes.fh3 && backendTestType == "fh3") {
            return parseInt(backendVersion)
          } else {
            throw 'Backend-set test methodology version does not match with reportContent.test_type'
          }
        }
      }

      // Otherwise use JS-derived test methodology version
      const dates = {
        sampleReceivedAt: new Date(reportContent.sample.received_at),
        bo19: {
          version1Cutoff: new Date("2015-11-07T00:00:00.000Z"),
          version2Cutoff: new Date("2016-02-05T00:00:00.000Z"),
          version3Cutoff: new Date("2016-06-03T00:00:00.000Z"),
          version4Cutoff: new Date("2016-08-29T17:00:00.000Z")
          // version5Cutoff is anything after V4
        },
        h30: {
          version1Cutoff: new Date("2016-08-29T17:00:00.000Z")
          // version2Cutoff is anything after V1
        }
      }
      if (reportContent.template.test_type == testTypes.breastOvarian19) {
        if (dates.sampleReceivedAt <= dates.bo19.version1Cutoff) {
          return 1
        } else if (dates.sampleReceivedAt <= dates.bo19.version2Cutoff) {
          return 2
        } else if (dates.sampleReceivedAt <= dates.bo19.version3Cutoff) {
          return 3
        } else if (dates.sampleReceivedAt <= dates.bo19.version4Cutoff) {
          return 4
        } else {
          return 5
        }
      } else if (reportContent.template.test_type == testTypes.hereditary30) {
        if (dates.sampleReceivedAt <= dates.h30.version1Cutoff) {
          return 1
        } else {
          return 2
        }
      } else if (reportContent.template.test_type == testTypes.wisdom9) {
        // Only 1 version of the Wisdom Test Methodology exists
        return 1
      }
    }
  })

  /**
   * @ngdoc filter
   * @name screeningGuidelinesContainPhenotype
   * @memberof resultFilter
   *
   * @description
   * Returns bool if reportContent.template.screening_guidelines contains a particular phenotype,
   * with strict matching on phenotype value
   */
  .filter('screeningGuidelinesContainPhenotype', function() {
    return function(guidelines, phenotype) {

      function containsPhenotype(phenotypeToCheck) {
        return guidelines.some(function(guideline) {
          return guideline.phenotype.indexOf(phenotypeToCheck) == 0
        })
      }

      if (guidelines && phenotype) {
        switch (phenotype) {
          case 'breast cancer':
            return containsPhenotype('breast cancer') ||
            containsPhenotype('breast and ovarian cancer')
          case 'ovarian cancer':
            return containsPhenotype('ovarian cancer') ||
            containsPhenotype('breast and ovarian cancer') ||
            // MLH1 guideline
            containsPhenotype('uterine and ovarian cancer') ||
            // STK11 guideline
            containsPhenotype('cervical, ovarian, and uterine cancer') ||
            // MSH6 Homozygous (CMMR-D)
            containsPhenotype('other CMMR-D associated cancers')
          case 'colorectal cancer':
            return containsPhenotype('colorectal cancer')
          case 'pancreatic cancer':
            return containsPhenotype('pancreatic cancer')
          case 'uterine cancer':
            return containsPhenotype('uterine cancer') ||
            // MLH1 guideline
            containsPhenotype('uterine and ovarian cancer') ||
            // STK11 guideline
            containsPhenotype('cervical, ovarian, and uterine cancer') ||
            // MSH6 Homozygous (CMMR-D)
            containsPhenotype('other CMMR-D associated cancers')
          case 'stomach cancer':
            return containsPhenotype('stomach cancer') ||
            // MLH1 guideline
            containsPhenotype('stomach and small bowel cancer') ||
            // CDH1 guideline: https://getcolor.atlassian.net/browse/CONSUMER-4392
            containsPhenotype('gastric cancer')
          case phenotype:
            return containsPhenotype(phenotype)
          default:
            return false
        }
        return guidelines.filter(function(g) {
          return g.phenotype.indexOf(phenotype) == 0
        }).length > 0
      } else {
        return false
      }
    }
  })

  .filter('risksShaper', require('./filters/risks_shaper'))
  .filter('genesWithMutationName', require('./filters/genes_with_mutation_name'))
  .filter('numericAllelicity', require('./filters/numeric_allelicity'))
  .filter('additionalFindingMutations', require('./filters/additional_finding_mutations'))
  .filter('transcriptName', require('./filters/transcript_name'))
  .filter('commonRiskAge', require('./filters/common_risk_age'))
  .filter('insertDisplayAge', require('./filters/insert_display_age'))
  .filter('hasAgeExceptionsAt', require('./filters/has_age_exceptions_at'))
  .filter('customPhenotypes', require('./filters/custom_phenotypes'))

  // For FH Report
  .filter('fhGuidelines', require('./filters/fh/guidelines'))
