!function() {

  'use strict'

  const module = angular.module('healthProfileResource', ['clrRequest', 'clrResource'])

  module.constant('femaleCancerAttrs', [
    'had_breast_cancer',
    'had_hematological_malignancy',
    'had_ovarian_cancer',
    'had_fallopian_tube_cancer',
    'had_primary_peritoneal_cancer',
    'had_colorectal_cancer',
    'had_endometrial_cancer',
    'had_gastric_cancer',
    'had_melanoma',
    'had_pancreatic_cancer',
    'had_thyroid_cancer',
    'had_kidney_cancer',
    'had_other_cancer1',
    'had_other_cancer2'
  ])

  module.constant('maleCancerAttrs', [
    'had_breast_cancer',
    'had_colorectal_cancer',
    'had_gastric_cancer',
    'had_melanoma',
    'had_pancreatic_cancer',
    'had_prostate_cancer',
    'had_thyroid_cancer',
    'had_kidney_cancer',
    'had_hematological_malignancy',
    'had_other_cancer1',
    'had_other_cancer2'
  ])

  module.factory('HealthHistory', function($filter, resource, femaleCancerAttrs, maleCancerAttrs) {
    const HealthHistory = resource(
      '/api/v1/health_history/:id'
      , { format: 'json', id: '@id' }
    )

    HealthHistory.R_PARENTS = ['mother', 'father']
    HealthHistory.R_MATERNAL_GRANDPARENTS = ['maternal grandmother', 'maternal grandfather']
    HealthHistory.R_PATERNAL_GRANDPARENTS = ['paternal grandmother', 'paternal grandfather']
    HealthHistory.R_GRANDPARENTS = HealthHistory.R_MATERNAL_GRANDPARENTS + HealthHistory.R_PATERNAL_GRANDPARENTS
    HealthHistory.R_ANCESTORS = HealthHistory.R_PARENTS + HealthHistory.R_GRANDPARENTS
    HealthHistory.R_SIBLINGS = [
      'full sister', 'maternal half sister', 'paternal half sister',
      'full brother', 'maternal half brother', 'paternal half brother'
    ]
    HealthHistory.R_CHILDREN = ['daughter', 'son']
    // PARSIBS = Parent's Siblings
    HealthHistory.R_MATERNAL_PARSIBS = ['maternal aunt', 'maternal uncle']
    HealthHistory.R_PATERNAL_PARSIBS = ['paternal aunt', 'paternal uncle']
    HealthHistory.R_PARSIBS = HealthHistory.R_MATERNAL_PARSIBS + HealthHistory.R_PATERNAL_PARSIBS

    HealthHistory.prototype.initializeRelatives = function() {
      if (!this.family_relative_health_profiles || (this.family_relative_health_profiles.length == 0)) {
        this.family_relative_health_profiles = [
          { relationship: 'mother', can_edit: true, can_delete: false },
          { relationship: 'father', can_edit: true, can_delete: false },
          { relationship: 'maternal grandmother', can_edit: true, can_delete: false },
          { relationship: 'maternal grandfather', can_edit: true, can_delete: false },
          { relationship: 'paternal grandmother', can_edit: true, can_delete: false },
          { relationship: 'paternal grandfather', can_edit: true, can_delete: false }
        ]
      }

      this.family_relative_health_profiles = $filter('familyRelativesSort')(this.family_relative_health_profiles)
    }

    HealthHistory.prototype.getRelatives = function(relationships) {
      if (!this.family_relative_health_profiles) {
        return []
      }

      const relatives = []
      for (let i = 0; i < this.family_relative_health_profiles.length; i++) {
        const relative = this.family_relative_health_profiles[i]
        if (relationships.indexOf(relative.relationship) != -1) {
          relatives.push(relative)
        }
      }
      return relatives
    }

    HealthHistory.prototype.getRelative = function(relationship) {
      return this.getRelatives([relationship])[0]
    }

    HealthHistory.prototype.hasChildren = function() {
      return this.getRelatives(HealthHistory.R_CHILDREN).length > 0
    }

    HealthHistory.prototype.removeAllRelatives = function(relationships) {
      const profiles = this.family_relative_health_profiles
      if (!profiles) {
        return
      }
      for (let i = profiles.length - 1; i >= 0; i--) {
        const relationship = profiles[i].relationship
        if (relationships.indexOf(relationship) != -1) {
          profiles.splice(i, 1)
        }
      }
    }

    HealthHistory.prototype.cleanUpBeforeSave = function() {
      // Reset unused family relative health profiles
      if (!this.has_children) {
        this.removeAllRelatives(HealthHistory.R_CHILDREN)
      }

      if (this.has_siblings != 'Y') {
        this.removeAllRelatives(HealthHistory.R_SIBLINGS)
      }

      if (this.mother_has_siblings != 'Y') {
        this.removeAllRelatives(HealthHistory.R_MATERNAL_PARSIBS)
      }

      if (this.father_has_siblings != 'Y') {
        this.removeAllRelatives(HealthHistory.R_PATERNAL_PARSIBS)
      }

      // Clean up cancer fields: if had_cancer is set, `null` answers for
      // specific cancers should be changed to `false`.
      const cleanUpCancerFields = function(profile, attrs) {
        if (profile && !angular.isUndefined(profile.had_cancer) && profile.had_cancer !== null) {
          for (let i = 0; i < attrs.length; i++) {
            if (!profile[attrs[i]]) {
              profile[attrs[i]] = false
            }
          }
        }
      }

      cleanUpCancerFields(this.female_health_profile, femaleCancerAttrs)
      cleanUpCancerFields(this.male_health_profile, maleCancerAttrs)
      if (this.family_relative_health_profiles) {
        this.family_relative_health_profiles.forEach(function (relative) {
          cleanUpCancerFields(relative.female_health_profile, femaleCancerAttrs)
          cleanUpCancerFields(relative.male_health_profile, maleCancerAttrs)
        })
      }
    }

    const superSave = HealthHistory.prototype.$save
    HealthHistory.prototype.$save = function() {
      this.cleanUpBeforeSave()
      return superSave.apply(this, arguments)
    }

    return HealthHistory
  })

  module.factory('RiskProfile', function(resource) {
    return resource(
      '/api/v1/risk_profiles/:id'
      , { format: 'json', id: '@id' }
    )
  })

  module.factory('AnonymizedRiskProfile', function(resource) {
    return resource(
      '/api/v1/anonymized_risk_profiles/:id'
      , { format: 'json', id: '@id' }
    )
  })

}();
