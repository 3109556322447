/**
 * @ngdoc filter
 * @name genesWithMutationName
 *
 * @description
 * Returns array of P/LP mutations with display names when appropriate
 */

'use strict'

module.exports = function($filter, namedMutation) {
  'ngInject'

  return function(mutations) {
    mutations = $filter('pathogenicMutations')(mutations)
    if (mutations.length == 0) {
      return []
    }
    mutations = $filter('sortMutations')(mutations)
    const namedMutations = []
    angular.forEach(mutations, function(mutation) {
      let gHGVS = '';
      let mutationName = ''
      // APC I1307K
      if (mutation.gene == 'APC' && $filter('containsMutation')([mutation], namedMutation.apc_i1307k)) {
        mutationName = 'I1307K'
        gHGVS = namedMutation.apc_i1307k
      // BRCA1 R1699Q
      } else if (mutation.gene == 'BRCA1' && $filter('containsMutation')([mutation], namedMutation.brca1_r1699q)) {
        mutationName = 'R1699Q'
        gHGVS = namedMutation.brca1_r1699q
      }
      // TODO: Handle TP53 R181H when those reports are needed/content is written
      // Check for dupes prior to adding to response, necessary for compound hets
      let thisMutation = $filter('filter')(namedMutations, { name: mutation.gene, mutationName: mutationName })
      if (thisMutation.length) {
        thisMutation = thisMutation[0]
      } else {
        thisMutation = { name: mutation.gene, mutationName: mutationName, gHGVS: gHGVS }
        namedMutations.push(thisMutation)
      }
      thisMutation.isHomozygous = thisMutation.isHomozygous || $filter('isHomozygous')(mutation)
    })

    return namedMutations
  }
}
