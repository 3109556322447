/**
 * @ngdoc filter
 * @name risksShaper
 *
 * @description
 * Reshapes content of reportContent.risks to best fit how the template engine wants to work.
 */

"use strict";

module.exports = function($filter, testTypes) {
  "ngInject";

  return function(risks, testType, mutations) {
    let modifiedRisks = angular.copy(risks);

    if (testType == testTypes.hereditary30) {
      // Filter out 'ovarian cancer' for H30 TP53 reports
      modifiedRisks = modifiedRisks.filter(function(risk) {
        return !(risk.gene == "TP53" && risk.phenotype == "ovarian cancer");
      });
    }

    // Filter mutation specific risks.
    // If mutation specific risks exists for mutations found,
    // it includes the risks. o/w exclude them.
    const geneWithNonSpecRisks = {};
    const mutationClassificationUnits = {};
    mutations.forEach(function(mutation) {
      if (
        mutation.classification == "Pathogenic" ||
        mutation.classification == "Likely Pathogenic"
      ) {
        const matchedRisks = modifiedRisks.filter(function(risk) {
          return risk.classification_unit_id == mutation.classification_unit_id;
        });
        if (mutation.classification_unit_id && matchedRisks.length) {
          mutationClassificationUnits[mutation.classification_unit_id] = true;
        } else {
          geneWithNonSpecRisks[mutation.gene] = true;
        }
      }
    });

    // Mutation specific risks with unknown impact.
    // We replace these risks with non-mutation specific risks.
    const unknownMutationSpecificRisks = modifiedRisks.filter(function(risk) {
      return (
        risk.data &&
        risk.data.length == 1 &&
        risk.data[0].risk == "unknown" &&
        risk.classification_unit_id
      );
    });

    modifiedRisks = modifiedRisks.filter(function(risk) {
      if (risk.classification_unit_id) {
        if (!risk.data || risk.data[0].risk != "unknown") {
          return mutationClassificationUnits[risk.classification_unit_id];
        }
      } else {
        // if the risk is not mutation specific, we include them if they are:
        // * baseline risk,
        // * for gene with non mutation specific risks, or
        // * for gene with mutation specific risks but known impact.
        return (
          !risk.gene ||
          geneWithNonSpecRisks[risk.gene] ||
          $filter("filter")(unknownMutationSpecificRisks, {
            gene: risk.gene,
            phenotype: risk.phenotype,
            gender: risk.gender
          }).length
        );
      }
    });

    return modifiedRisks;
  };
};
