/**
 * @prettier
 */

module.exports = function reportShareFamily() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_share_family.html',
    controllerAs: 'ctrl',
    replace: true,
    bindToController: true,
    scope: {
      cardStyle: '@',
      reportContent: '=',
      reportId: '=',
    },
    controller: function reportShareFamilyController(
      emailFamilyModal,
      sampleLetterModal,
      reportShareFamilyData
    ) {
      'ngInject';

      const ctrl = this;
      reportShareFamilyData(ctrl.reportContent, ctrl);

      ctrl.openEmailFamilyModal = function() {
        emailFamilyModal.open(
          ctrl.msgMarkdownSampleLetter,
          ctrl.reportId,
          ctrl.reportContent.kit_order.test_type
        );
      };
      ctrl.openSampleLetterModal = function() {
        sampleLetterModal.open(ctrl.msgMarkdownSampleLetter);
      };
    },
  };
};
