'use strict'

module.exports = function() {
  'ngInject'

  // Beware of any unicode inside patterns and test thoroughly; e.g. "(i|î)" works but not "[iî]".
  const subPatterns = [
    "G? *(P[.]?|post) *(O[.]?|office) *(B[.]?|box)", // US, CA, UK, AU, NZ
    "(Apartado|Apto)([. ]( *Postal)?)?", // US, ES, MX
    "(Bo(i|î)te|Case|Casella) +Postale|BP|CP", // FR, CA, IT
    "Postilokero|Box", // FI
    "Postfach", // DE
    "Bosca +Poist", // IE
    "Ta +Do'ar", // IL
    "Window +Delivery|Locked +Bag +Service" // SG
  ]
  const poBoxPattern = new RegExp("\\b((" + subPatterns.join(")|(") + ")) +\\d", "i")
  return function(line) {
    return poBoxPattern.test(line)
  }
}
