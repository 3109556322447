module.exports = function CovidReportListController(
  $scope,
  $window,
  $timeout,
  $filter,
  waffle,
  resourcePaginator,
  Report
) {
  "ngInject";

  $scope.search = { is_covid: true };

  // NOTE(color-thenriod): If you change this code, you probably need to change the same code in
  // monoweb/django_apps/core/static/js/controllers.js
  // ifchange
  $scope.covidReportRetractionPageEnabled = !!(
    waffle.SWITCHES &&
    waffle.SWITCHES["clinical-covid-report-retraction-2022-08"]
  );
  // Why not just use the `href` attribute of an `<a>` you ask? In short, because it won't work.
  // I don't have the full explanation, but if you are in Angular-land, and you use an <a> in the
  // normal way to link to a page in React-land, you will end up on a blank page in Angular land
  // and you will have to manually refresh the page (which will have the correct url in the url
  // bar - so that's nice I guess). Forcing the redirection with a click handler that forcibly
  // updates the window location will cause a page reload that will actually put you in
  // React-land.
  $scope.goToCovidReportRetractionPage = function() {
    $window.location.assign("/covid_reports/retract");
  };
  // endifchange

  // Set up a fake ResourcePaginator with the attributes expected by the
  // paginated-table component. This will display a message instructing
  // the user that no search results are displayed until they enter some
  // search terms in the boxes.
  $scope.reports = {
    items: [],
    total: 0,
    getNextPage: () => {},
    noSearchExecuted: true
  };

  $scope.viewReport = report => {
    return () => $window.open("/reports/" + report.id);
  };

  $scope.columns = [
    {
      key: report => report.kit_order.order_number,
      label: "Order #"
    },
    {
      key: report => report.sample.kit_barcode,
      label: "Sample"
    },
    {
      key: report => $filter("resultSignificance")(report),
      label: "Significance"
    },
    {
      key: report => $filter("capitalize")(report.user.gender),
      label: "Gender"
    },
    {
      key: report => report.user.age,
      label: "Age"
    },
    {
      key: report =>
        $filter("date")(report.kit_order.ready_at_lab, "MMMM dd, yyyy"),
      label: "At Lab"
    },
    {
      key: report => $filter("date")(report.released_at, "MMMM dd, yyyy"),
      label: "Released"
    },
    {
      key: report => $filter("reportStatusText")(report),
      label: "Status"
    }
  ];

  let searchPromise = null;
  $scope.$watch(
    "search",
    (newValue, oldValue) => {
      // Skip initial watch trigger.
      if (angular.isUndefined(oldValue) || newValue == oldValue) {
        return;
      }
      if (searchPromise) {
        $timeout.cancel(searchPromise);
      }
      searchPromise = $timeout(() => {
        $scope.reports = resourcePaginator(new Report(), $scope.search);
        $scope.reports.getNextPage();
        searchPromise = undefined;
      }, 1000);
    },
    true
  );
};
