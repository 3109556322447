'use strict'

module.exports = function($filter) {
  'ngInject'

  return function(reportContent) {
    const guidelines = []
    if (reportContent.is_negative) {
      guidelines.push({
        phenotype: 'FH_REPORT.GUIDELINES.NEGATIVE.CORONARY.PHENOTYPE',
        content: 'FH_REPORT.GUIDELINES.NEGATIVE.CORONARY.' + reportContent.gender + '_CONTENT',
        references: reportContent.gender == 'F' ? [281, 285] : [281]
      })
      guidelines.push({
        phenotype: 'FH_REPORT.GUIDELINES.NEGATIVE.GENERAL.PHENOTYPE',
        content: 'FH_REPORT.GUIDELINES.NEGATIVE.GENERAL.CONTENT',
        references: [282]
      })
    } else if (reportContent.is_positive) {
      const genes = reportContent.genes_with_pathogenic_mutations
      const allelicities = reportContent.allelicity_by_gene
      // At least one gene with biallelic
      if (genes.some(function(gene) { return allelicities[gene] == '2' })) {
        guidelines.push({
          phenotype: 'FH_REPORT.GUIDELINES.POSITIVE_BIALLELIC.CORONARY.PHENOTYPE',
          content: 'FH_REPORT.GUIDELINES.POSITIVE_BIALLELIC.CORONARY.CONTENT',
          references: [291],
        })
      // At least one gene with unkonwn phase
      } else if (genes.some(function(gene) { return !allelicities[gene] })) {
        guidelines.push({
          phenotype: 'FH_REPORT.GUIDELINES.POSITIVE_MONOALLELIC.CORONARY.PHENOTYPE',
          phenotype_sub: 'FH_REPORT.GUIDELINES.POSITIVE_MONOALLELIC.CORONARY.PHENOTYPE_SUB',
          content: 'FH_REPORT.GUIDELINES.POSITIVE_MONOALLELIC.CORONARY.CONTENT',
          references: [287, 288, 285]
        })
        guidelines.push({
          phenotype: 'FH_REPORT.GUIDELINES.POSITIVE_BIALLELIC.CORONARY.PHENOTYPE',
          phenotype_sub: 'FH_REPORT.GUIDELINES.POSITIVE_BIALLELIC.CORONARY.PHENOTYPE_SUB',
          content: 'FH_REPORT.GUIDELINES.POSITIVE_BIALLELIC.CORONARY.CONTENT',
          references: [291],
        })
      // Monoallelic only
      } else {
        guidelines.push({
          phenotype: 'FH_REPORT.GUIDELINES.POSITIVE_MONOALLELIC.CORONARY.PHENOTYPE',
          content: 'FH_REPORT.GUIDELINES.POSITIVE_MONOALLELIC.CORONARY.CONTENT',
          references: [287, 288, 285]
        })
      }

      guidelines.push({
        phenotype: 'FH_REPORT.GUIDELINES.POSITIVE.GENERAL.PHENOTYPE',
        content: 'FH_REPORT.GUIDELINES.POSITIVE.GENERAL.CONTENT',
        references: [282]
      })
    }
    return guidelines
  }
}
