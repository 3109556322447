module.exports = function searchProvider(DetailedProviderProfiles) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/search_provider.html',
    scope: {
      role: '@',
      order: '=',
      defaultCountry: '=',
      isOrderingPhysician: '='
    },
    link: function(scope, elem) {
      function searchAndUpdateChoices(query) {
        return DetailedProviderProfiles.get({'search': query}).$promise
          .then(function(res) { scope.physicianChoices = res.results })
      }

      scope.submitting = false

      scope.showInput = function() {
        return !(scope.order[scope.role] && scope.order[scope.role]['id'])
      }

      scope.submitSearch = function(query) {
        scope.submitting = true
        searchAndUpdateChoices(query)
          .then(function() {
          // if search limits to few choices then autoselect the first one
            if (scope.physicianChoices.length < 10) {
              scope.order[scope.role] = scope.physicianChoices[0]
            } else {
              scope.order[scope.role] = ""
            }
          })
          .finally(function() {
            scope.submitting = false
          })
      }

      searchAndUpdateChoices("")
    }
  }

}
