"use strict"

/**
 *
 *
 */
module.exports = function () {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      itemTitle: '@',
      itemSubtitle: '@?',
      itemSubtitleStrikeText: '@?',
      itemSubtitleText: '@?',
      itemIcon: '@?',
      itemNumber: '@?',
      startsOpen: '=?'
    },
    transclude: true,
    templateUrl: '/directives/clr_accordion_list_item.html',
    link: function (scope) {
      scope.startsOpen = scope.startsOpen ? scope.startsOpen : false
      scope.openState = scope.startsOpen
      scope.toggle = function() {
        scope.openState = !scope.openState
      }
    }
  }
}
