'use strict'

module.exports = function(auth, createAccount, Account, SignInModal) {
  "ngInject"

  return {
    restrict: 'AE',
    scope: {
      goToNext: '&',
      title: '@',
      subtitle: '@',
      account: '='
    },
    templateUrl: '/users/create_account_form_v2.html',
    link: function(scope) {
      scope.creatingAccount = false

      scope.createAccount = () => {
        scope.creatingAccount = true
        createAccount(scope.account)
          .then((res) => {
            scope.goToNext()
          })
          .catch(() => {
            scope.creatingAccount = false
          })
      }

      scope.auth = auth

      // TODO: Update style on login modal
      scope.showLoginModal = () => {
        SignInModal.open(() => {
          scope.goToNext()
        })
      }
    }
  }
}
