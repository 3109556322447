const getAbsolutePath = require('monoweb/django_apps/core/static/js/absolute_path');
const resolvers = require('monoweb/django_apps/core/static/js/resolvers')

module.exports = angular.module('kitOrders', [
  require('monoweb/django_apps/kit_orders/js/controllers').name,
  require('monoweb/django_apps/kit_orders/js/directives').name,
  require('monoweb/django_apps/kit_orders/js/modals').name,
  require('monoweb/django_apps/kit_orders/js/resources').name,
  require('monoweb/django_apps/kit_orders/js/services').name
]).config(function(colorRouteProvider) {
  const noLoginOrConfirmation = {
    requireLogin: false,
    requireEmailConfirmation: false
  }

  colorRouteProvider
    .when('/kit', {
      controller: 'KitRedirectController',
      template: ' ', // http://stackoverflow.com/questions/14411740/how-to-route-without-a-templateurl
      auth: noLoginOrConfirmation
    })
    .when('/research/activate', {
      controller: 'ResearchRedirectController',
      template: ' ',
      auth: {
        requireLogin: true,
        requireEmailConfirmation: true
      }
    })
  // Purchase Flow
  // Legacy redirects
    .when('/kit_orders/ordering_physician', { redirectTo: '/purchase/ordering-physician' })
    .when('/kit_orders/:order_number/confirmation', { redirectTo: '/purchase/:order_number/confirmation' })
    // TODO (UXTASK-1533): This was the angular order flow path that is still
    // referenced in emails sent to AC Wellness participants. Delete in two
    // weeks on 2023-03-09.
    .when('/order', { redirectTo: '/react/orders' })
    .when('/order/shipping', { redirectTo: '/react/orders/shipping' })

    .when('/purchase', { redirectTo: '/purchase/ordering-physician' })
    .when('/purchase/ordering-physician', {
      templateUrl: '/kit_orders/ordering_physician.html',
      seo: {
        title: 'Purchase your Color Test - Color',
        description: ''
      },
      controller: 'OrderingPhysicianController',
      auth: noLoginOrConfirmation,
      navigation: {
        showLeft: false,
        showRight: false,
        showPromoBanner: true,
        showPromoBannerWhitelist: [
          'enterprise',
          'familyTestingCode',
        ],
        active: 'buy',
        subnavCompact: true,
        layout: 'minimum'
      }
    })
    .when('/purchase/shipping', { redirectTo: '/react/orders' })
    .when('/purchase/billing', { redirectTo: '/react/orders' })
    .when('/purchase/account', { redirectTo: '/react/orders' })
    .when('/purchase/review', { redirectTo: '/react/orders' })
    .when('/purchase/:purchase_number/confirmation', {
      templateUrl: '/kit_orders/confirmation.html',
      seo: {
        title: 'Purchase your Color Test - Color',
        description: ''
      },
      controller: 'KitOrderConfirmationController',
      auth: {
        requireLogin: false
      },
      navigation: {
        blue: true,
      }
    })
    .when('/purchase/:purchase_number/activate', {
      templateUrl: '/kit_orders/confirmation_activate.html',
      seo: {
        title: 'Purchase your Color Test - Color',
        description: ''
      },
      controller: 'KitOrderConfirmationController',
      auth: {
        requireLogin: true,
        loginPath: '/react/create-account',
        requireEmailConfirmation: false
      },
      navigation: {
        active: 'buy'
      }
    })

  // Sample reuse flow
  const sampleReuseNavigation = {
    blue: true,
    showLeft: false,
    showPromoBanner: true,
    showPromoBannerWhitelist: [
      'enterprise',
      'familyTestingCode'
    ],
    active: 'buy',
    subnavCompact: true,
    layout: 'minimum'
  }
  colorRouteProvider
    .when('/sample-reuse', { redirectTo: '/sample-reuse/pay' })
    .when('/sample-reuse/pay', {
      templateUrl: '/kit_orders/sample_reuse/pay.html',
      controller: 'SampleReusePayController',
      auth: {
        requireLogin: true
      },
      navigation: sampleReuseNavigation
    })
    .when('/sample-reuse/consent', {
      templateUrl: '/kit_orders/sample_reuse/consent.html',
      controller: 'SampleReuseConsentController',
      auth: {
        requireLogin: true
      },
      navigation: sampleReuseNavigation
    })
    .when('/sample-reuse/confirmation', {
      templateUrl: '/kit_orders/sample_reuse/confirmation.html',
      controller: 'SampleReuseConfirmationController',
      auth: {
        requireLogin: true
      },
      navigation: sampleReuseNavigation
    })
})
