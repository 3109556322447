'use strict'

module.exports = ($timeout, auth, RoutingAuthorizationModal, ngToast, $http, organizationRelationship) => {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      employer: '=',
      goToNext: '=',
    },
    templateUrl: '/benefits/directives/routing_authorization.html',
    link: function(scope) {
      scope.showSection = {
        routingConsent: false,
        defaultRouting: false,
      }

      scope.translateValues = {
        employer: scope.employer
      }
      scope.relationship = { type: null }
      scope.organizationRelationship = organizationRelationship

      const scrollTo = (element) => {
        $timeout(() => {
          if (element) {
            $('html,body').animate({scrollTop: $(element).offset().top - 275} , "slow")
          }
        }, 0, false);
      }

      scope.selectEmployee = () => {
        scope.showSection.routingConsent = true
        scope.showSection.defaultRouting = false
        scope.relationship = { type: organizationRelationship.EMPLOYEE }
        scrollTo("#routingConsent")
      }

      scope.selectNone = () => {
        scope.showSection.routingConsent = false
        scope.showSection.defaultRouting = true
        scope.relationship = { type: organizationRelationship.NON_HEALTH_INSURANCE_BENEFICIARY }
        scrollTo("#defaultRouting")
      }

      scope.selectNonEmployee = () => {
        scope.showSection.routingConsent = false
        scope.showSection.defaultRouting = true
        scope.relationship = { type: organizationRelationship.HEALTH_INSURANCE_BENEFICIARY }
        scrollTo("#defaultRouting")
      }

      scope.setMembershipAndGoToNext = () => {
        if (scope.relationship.type) {
          return $http.post('/api/v1/set_organization_membership', {
            organization_name: scope.employer, relationship: scope.relationship.type
          }).then(res => {
            return auth.sync(true)
          }).then(res => {
            scope.goToNext()
          })
            .catch(err => {
              ngToast.create({ className: 'error', content: "There was an error. Please contact Support." })
            })
        } else {
          ngToast.create({ className: 'error', content: "You must select a membership before continuing." })
        }
      }

      scope.hasCompletedHealthHistory = () => {
        return auth.currentUser.has_health_history_completed
      }

      scope.selectNo = () => {
        $http.post('/api/v1/user_consent', {
          consented: false, type: 'routing_authorization', scope: scope.employer
        }).then(res => {
          ngToast.create({ className: 'success', content: `You will not receive recommended ${scope.employer} benefits and services on your dashboard.`})
          scope.setMembershipAndGoToNext()
        }).catch(err => {
          ngToast.create({ className: 'error', content: "There was an error. Please contact Support." })
        })
      }

      scope.selectYes = () => {
        RoutingAuthorizationModal.open(scope.employer, scope.setMembershipAndGoToNext)
      }
    }
  }
}
