module.exports = function ProviderPlatformHomeController($scope, $location, $modal, auth,
  ngToast, cppHomeUrlPath, DraftPhysicianOrder, ProviderPlatformModals) {
  'ngInject'

  function setOrders(promise) {
    $scope.loading = true
    promise.then(function(res) {
      $scope.drafts = res
    }).finally(function(res) {
      $scope.loading = false
    })
  }
  const params = cppHomeUrlPath.getApiParamsFromLocation()
  setOrders(DraftPhysicianOrder.get(params).$promise)
  $scope.routeName = cppHomeUrlPath.getRouteName()

  $scope.continueOrder = function(draft) {
    $location.path('/providers/place-order').search({draft: draft.requisition_number})
  }

  $scope.discard = function(draft, $event) {
    // Stops propogation of click into continuing the order
    $event.stopPropagation()
    ProviderPlatformModals.openCancelOrderModal(
      draft.requisition_number
    ).result.then(function() {
      setOrders(DraftPhysicianOrder.get({'ordering': '-created_at'}).$promise)
    })
  }
}
