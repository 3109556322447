"use strict"

/**
 * clrTabSet parent directive for other clrTab_____ directives (but not clrTabSimple)
 * Used on reports V1
 * Not used on product or learn pages or reports V2
 */
module.exports = function($timeout, setViewportScroll) {
  "ngInject"

  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    replace: true,
    templateUrl: '/directives/clr_tab_set.html',
    link: function(scope, elem, attrs) {
      scope.heroNotSwappable = attrs.heroNotSwappable
      scope.inCard = attrs.inCard
      scope.disableScrollfix = attrs.disableScrollfix
      scope.hideTabs = attrs.hideTabs
      scope.tallHero = attrs.tallHero

      // Mobile versions of clrTab have an alternate layout that requires minor modifications
      function initMobileTabs() {
        let duplicateButton
        const tabBarButton = elem.find(".tab-bar__button")
        if (tabBarButton) {
          duplicateButton = tabBarButton.clone()
          elem.find(".tab-bar__mobile").append(duplicateButton)
        }
        angular.element('.tab-bar__link').on('click', function() {
          scope.mobileToggleMenu(false)
          setViewportScroll.enable()
        })
      }

      scope.mobileToggleMenu = function(bool) {
        scope.mobileMenuOpen = bool ? bool : !scope.mobileMenuOpen
        if (scope.mobileMenuOpen) {
          setViewportScroll.disable()
        } else {
          setViewportScroll.enable()
        }
      }

      $timeout(function () {
        initMobileTabs()
      }, 100) // Needs a slight timeout while waiting for ng-transclude to finish

    },
    controller: function($rootScope, $scope, $document, $window, $q, $http, $templateCache, locationSkipReload) {
      "ngInject"

      const self = this
      const tabs = []

      self.select = function (tab) {
        angular.forEach(tabs, function (tab) {
          tab.selected = false
        })
        tab.selected = true
        self.preloadTemplates(tab.heroTemplateUrl, tab.contentTemplateUrl)
          .then(function() {
            self.setUrls(tab.heroTemplateUrl, tab.contentTemplateUrl)
            self.setHeroHeight(tab.heroHeight)
          })
      }

      self.setUnderline = function(width, left) {
        $rootScope.$broadcast('clrTab:setUnderline', {
          width: width,
          left: left
        })
      }

      self.setScroll = function(forceToTabs) {
        const windowScrollTop = angular.element(window).scrollTop()
        const tabSetOffsetTop = angular.element('.tab-bar').offset().top
        const heroElement = angular.element('.clr-tab-hero')
        const heroBottom = heroElement.outerHeight() + heroElement.offset().top
        // Scroll to top if tabs aren't docked and hero isn't static
        if (windowScrollTop < tabSetOffsetTop && !$scope.heroNotSwappable) {
          $document.scrollTopAnimated(0)
        } else if (windowScrollTop >= tabSetOffsetTop || forceToTabs) {
          $document.scrollTopAnimated(heroBottom)
        }
      }

      self.setUrls = function(heroTemplateUrl, contentTemplateUrl) {
        $scope.heroTemplateUrl = heroTemplateUrl
        $scope.contentTemplateUrl = contentTemplateUrl
      }

      self.setHeroHeight = function(heroHeight) {
        if ($scope.heroTemplateUrl) {
          $scope.heroHeight = heroHeight || ''
        } else {
          $scope.heroHeight = 0
        }
      }

      self.preloadTemplates = function(heroTemplateUrl, contentTemplateUrl) {
        const heroPromise = heroTemplateUrl
          ? $http.get(heroTemplateUrl, {cache: $templateCache})
          : $q.when()
        const contentPromise = $http.get(contentTemplateUrl, {cache: $templateCache})
        return $q.all([heroPromise, contentPromise])
      }

      self.addTab = function(tab) {
        tabs.push(tab)
      }
    }
  }
}
