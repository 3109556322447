/**
 * @prettier
 */

const processAriaLabel = label => {
  return label
    .trim()
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, ' $1') // replace camelcase with spaces
    .replace(/\s\s+/g, ' '); // replace all whitespace with a single space
};

module.exports = angular
  .module('clrA11y', [])

  .factory('a11y', $timeout => {
    'ngInject';
    const labelImages = () => {
      $timeout(() => {
        angular.element("*[class^='img']").each(index => {
          const element = angular.element("*[class^='img']")[index];
          const style = getComputedStyle(element);
          const label = style.getPropertyValue('--aria-label');
          if (label) {
            // process the label so it's more human readable
            const altText = processAriaLabel(label);
            angular.element(element).attr('aria-label', altText);
            angular.element(element).attr('role', 'img');
            angular.element(element).attr('alt', altText);
          }
        });
      });
    };
    return {
      labelImages: labelImages,
    };
  });
