"use strict"

/**
 * Checkbox for array model.
 * Appends other text field at the end of the array.
 */
module.exports = function($window) {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      href: '@',
      popupName: '@'
    },
    link: function(scope, elem) {
      elem.on('click', function(e) {
        e.preventDefault()
        const newWindow = $window.open(scope.href, scope.popupName, 'height=450,width=800')
        if (newWindow) {
          newWindow.opener = null;
        }
      })
    }
  }
}
