'use strict'

module.exports = function(claimStore, promoCodeStore, kitPriceService) {
  'ngInject'

  // TODO: remove orderFlowStyle when we merger order preview
  return {
    restrict: 'A',
    scope: {
      checkout: '=',
      orderFlowStyle: '=?',
    },
    templateUrl: '/kit_orders/order_preview.html',
    link: function(scope) {
      scope.claimStore = claimStore
      scope.promoCodeStore = promoCodeStore
      scope.kitPriceService = kitPriceService
      scope.partnerRequestsPayment = claimStore.claim && claimStore.claim.organization_name === 'NorthShore'
    }
  }
}
