'use strict'

module.exports = function physicianOrderPaymentDetails(
  addPatientPaymentInfoModal,
  auth,
  fileUploader,
  uploadFileTypes,
  PhysicianOrderType
) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      editable: '=?',
      order: '=',
      ordering: '=',
      onSave: '='
    },
    templateUrl: '/ordering_physicians/directives/physician_order_payment_details.html',
    link: function(scope) {
      scope.isAddPatientPaymentInfoModalOpen = false

      /**
       * Opens a modal to add credit card payment information.
       */
      scope.openAddPatientPaymentInfoModal = (order) => {
        scope.isAddPatientPaymentInfoModalOpen = true
        scope.order.skip_payment = false
        addPatientPaymentInfoModal.open(order).result
          .then(() => scope.onSave()) // save order
          .catch(() => scope.order.skip_payment = true) // reset
          .finally(() => scope.isAddPatientPaymentInfoModalOpen = false)
      }

      const SWITCHED_TO_CASH_REASON = {
        DEFAULT: 'DEFAULT',
        OOP_OVER: 'OOP_OVER',
        BLACKLIST: 'BLACKLIST',
        CRITERIA: 'CRITERIA'
      }

      scope.SWITCHED_TO_CASH_REASON = SWITCHED_TO_CASH_REASON

      scope.getSwitchedToCashReason = () => {
        const s = scope.order.attempted_insurance_submission
        if (s.eligibility_submission.eligibility_state === 'oop_over') {
          return SWITCHED_TO_CASH_REASON.OOP_OVER
        } else if (s.eligibility_submission.eligibility_state === 'payer_not_accepted') {
          return SWITCHED_TO_CASH_REASON.BLACKLIST
        } else if (s.criteria_submission) {
          if (!s.criteria_submission.met_criteria) {
            return SWITCHED_TO_CASH_REASON.CRITERIA
          }
        }

        return SWITCHED_TO_CASH_REASON.DEFAULT
      }

      // Set a few convenience variables.
      scope.$watch('order', (order) => {
        if (order) {
          scope.payment = scope.order.payment

          scope.canEditPayment = (
            scope.order.skip_payment &&
            !scope.order.payment &&
            scope.order.type == PhysicianOrderType.SAMPLE_AT_OFFICE &&
            scope.order.type !== 'new'
          )

          // All uploaded files on this page will be ``physicianOrderFiles``.
          scope.uploader = fileUploader({
            formData: [{
              physician_order_requisition_number: scope.order.requisition_number,
              file_type: uploadFileTypes.physicianOrderFiles
            }]
          })

          scope.uploader.onSuccessItem = scope.onSave
        }
      });
    }
  }
}
