!function() {
  'use strict'

  const colorApp = angular.module('colorApp')
  const openRegistryModal = function(textModal) {
    return function() {
      textModal.open(
        "Information transfer to the BRCA Foundation",
        "You consent to our transfer of your  name, email, birth date, mutation details, ethnicity, personal cancer history, and surgery history (“Information”) to the BRCA Foundation as part of enrolling. Immediately upon such transfer, the BRCA Foundation assumes sole responsibility and liability for using, disclosing and safeguarding your Information under applicable federal and state data privacy and security laws, regulations and guidelines.",
        'Done'
      )
    }
  }

  colorApp.controller(
    'FamilyTestingInitializationController',
    function($location) {
      'ngInject'

      return $location.path('/react/hereditary-disease-risk/family-testing/enroll')
    }
  )

  colorApp.controller(
    'FamilyTestingResultsController',
    function($scope, $modal, ngToast, familyTestingGenes, months, FamilyTestingFlow) {
      'ngInject'
      $scope.flowState = FamilyTestingFlow.state

      $scope.genes = familyTestingGenes
      $scope.relationships = [
        'mother',
        'father',
        'sister',
        'brother',
        'daughter',
        'son'
      ]

      // Initialize birthdate based on the flow state
      const birthdate = $scope.flowState.applicant.birthdate
      if (birthdate) {
        $scope.day = birthdate.getDate()
        $scope.month = months[birthdate.getMonth()]
        $scope.year = birthdate.getFullYear()
      }

      function getProbandRelationship() {
        if ($scope.flowState.resultsOwner == 'self') {
          return 'self'
        } else {
          return $scope.flowState.proband.relationship
        }
      }

      const originalProbandRelationship = getProbandRelationship()

      function getAge(birthdate) {
        const today = new Date()
        let age = today.getFullYear() - birthdate.getFullYear()
        const m = today.getMonth() - birthdate.getMonth()
        if (m < 0 || m == 0 && today.getDate() < birthdate.getDate()) {
          age--
        }
        return age
      }

      $scope.onLearnMore = function() {
        return $modal.open({
          templateUrl: '/family_testing/_results_modal.html',
          size: 'lg'
        })
      }

      $scope.goToNext = function() {
        const birthdate = new Date($scope.birthdate)
        if (getAge(birthdate) < 18) {
          ngToast.create({
            className: 'error',
            content: "You must be 18 or older to apply for the Color Family Testing program."
          })
          return
        }

        // Set up flowState for next steps
        const flowState = $scope.flowState
        flowState.applicant.birthdate = new Date(birthdate)

        // Clear out relatives if proband has changed
        if (originalProbandRelationship != getProbandRelationship()) {
          FamilyTestingFlow.initializeRelatives()
        }

        if ($scope.flowState.edit && !flowState.relativesNeeded) {
          FamilyTestingFlow.goTo('/family-testing/enroll/review')
        } else {
          FamilyTestingFlow.goTo('/family-testing/enroll/relatives', true)
        }
      }
    }
  )

  colorApp.controller(
    'FamilyTestingRelativesController',
    function($scope, textModal, FamilyTestingFlow) {
      'ngInject'
      $scope.flowState = FamilyTestingFlow.state

      $scope.enrolledRelativeCount = FamilyTestingFlow.getRelatives().length

      function enrollRelative() {
        if ($scope.enrolledRelativeCount >= 10) {
          textModal.open(
            "You have selected 10 relatives",
            "At this time, Color’s Family Testing Program covers up to 10 relatives for $50 testing. If you have more than 10 relatives who would be eligible for testing, please contact <a href='mailto:familyprogram@color.com'>familyprogram@color.com</a>.",
            'Okay',
            "",
            true
          )
          return false
        }
        $scope.enrolledRelativeCount += 1
        $scope.form.$setValidity('selection', true)
        return true
      }

      function unenrollRelative() {
        $scope.enrolledRelativeCount -= 1
      }

      $scope.changeEnrollment = function(relative) {
        // function is called after a change is made to the relative
        if (relative && relative.enroll) {
          if (!enrollRelative()) {
            relative.enroll = false
          }
        } else {
          unenrollRelative()
        }
      }

      $scope.addRelative = function(relationship) {
        if (enrollRelative()) {
          $scope.flowState[relationship + 's'].push({ enroll: true })
        }
      }

      $scope.goToNext = function() {
        if ($scope.enrolledRelativeCount == 0) {
          $scope.form.$setValidity('selection', false)
          return
        }
        $scope.flowState.relativesNeeded = false
        if ($scope.flowState.edit) {
          FamilyTestingFlow.goTo('/family-testing/enroll/review')
        } else {
          FamilyTestingFlow.goTo('/family-testing/enroll/review', true)
        }
      }
    }
  )

  colorApp.controller(
    'FamilyTestingReviewController',
    function($scope, $http, auth, resolvedPromise, FamilyTestingFlow) {
      'ngInject'
      $scope.flowState = FamilyTestingFlow.state
      $scope.currentUser = auth.currentUser

      $scope.relatives = FamilyTestingFlow.getRelatives()

      $scope.goToPage = function(pageUrl) {
        $scope.flowState.edit = true
        FamilyTestingFlow.goTo('/family-testing/enroll/' + pageUrl)
      }

      function getInviteeInfo(relative, relationship) {
        if (relative && relative.enroll) {
          return {
            first_name: relative.firstName,
            last_name: relative.lastName,
            age: relative.age,
            email: relative.email,
            relationship: relationship
          }
        } else {
          return null
        }
      }

      $scope.submit = function() {
        $scope.submitting = true
        const flowState = $scope.flowState
        let invitees = []
        invitees.push(getInviteeInfo(flowState.mother, 'mother'))
        invitees.push(getInviteeInfo(flowState.father, 'father'))
        angular.forEach(
          ['sister', 'brother', 'daughter', 'son'],
          function(relationship) {
            angular.forEach(
              flowState[relationship + 's'],
              function(invitee) {
                invitees.push(getInviteeInfo(invitee, relationship))
              }
            )
          }
        )

        invitees = invitees.filter(function(invitee) { return invitee })

        const data = {
          invitees: invitees,
        }

        if (flowState.eligibility.status == 'approved') {
          if (flowState.applicant && flowState.applicant.enroll) {
            data.requestor = {'requesting_test': true}
          }
          return $http.post('/api/v1/family_testing/add_additional_invitees', data)
            .then(function() {
              auth.sync(true)
              FamilyTestingFlow.goTo('/family-testing/enroll/confirmation', true)
            })
        }
        else if (!flowState.eligibility.color_positive) {
          data.proband = {
            genes: flowState.genes,
            document_key: flowState.uploader.getUploadedKeys()[0]
          }
          data.requestor = {
            date_of_birth: flowState.applicant.birthdate.toISOString().slice(0, 10),
            sex: flowState.applicant.sex,
            how_you_heard: flowState.applicant.heardAboutColor
          }

          if (flowState.resultsOwner == 'self') {
            data.proband.relationship = 'self'
          } else if (flowState.resultsOwner == 'relative') {
            data.proband.relationship = flowState.proband.relationship
            data.proband.first_name = flowState.proband.firstName
            data.proband.last_name = flowState.proband.lastName
            data.proband.age = flowState.proband.age
            data.requestor.requesting_test = flowState.applicant.enroll
          }
        }

        resolvedPromise()
          .then(function() {
            return $http.post('/api/v1/family_testing/request_discount', data)
              .then(function() {
                auth.sync(true)
                FamilyTestingFlow.goTo('/family-testing/enroll/confirmation', true)
              })
          })
          .catch(function() {
            // Silently ignoring our API exceptions. FIXME do better in React.
          })
          .finally(function() {
            $scope.submitting = false
          })
      }
    }
  )

  colorApp.controller(
    'FamilyTestingConfirmationController',
    function($scope, FamilyTestingFlow) {
      'ngInject'
      const flowState = FamilyTestingFlow.state
      $scope.isPreApproved = flowState.isPreApproved()
    }
  )

  colorApp.controller(
    'FamilyTestingAccountCreationController',
    function($scope, $location, auth, ngToast, createAccount) {
      'ngInject'
      if (auth.isLoggedIn) {
        $location.path('/family-testing/enroll')
      }
      $scope.createAccount = function(account) {
        $scope.creatingAccount = true
        createAccount(account).then(function() {
          $location.path('/family-testing/enroll')
        }).catch(function() {
          ngToast.create({className: 'error', content: "Account creation failed; please contact support"})
        }).finally(function() {
          $scope.creatingAccount = false
        })
      }
    }
  )
}()
