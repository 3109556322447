'use strict'

module.exports = function($scope, $location, InsightsFlow) {
  'ngInject'

  $scope.flow = InsightsFlow

  $scope.routes = InsightsFlow.getRoutes()

  $scope.goToNext = function() {
    InsightsFlow.goToNext()
  }

}
