module.exports = function clrPatientProfile() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      order: '=',
      editing: '=',
      onCancelEdit: '=?',
      onEdit: '=?'
    },
    templateUrl: '/ordering_physicians/directives/clr_patient_profile.html',
    link: function(scope) {
      let oldPatientProfile

      scope.$watch('order', function(order) {
        if (order) {
          oldPatientProfile = angular.copy(scope.order.patient_profile)
        }
      })

      scope.cancel = function() {
        scope.order.patient_profile = oldPatientProfile
        scope.onCancelEdit()
      }

      scope.edit = function() {
        scope.onEdit()
      }
    }
  }
}
