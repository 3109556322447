module.exports = function createAccount(Account, $rootScope, auth, promoCodeStore, ngToast) {
  'ngInject'

  return function(account) {
    if (promoCodeStore.promotionalCode) {
      // Submit promo code to backend to be sent in confirmation email.
      account.promoCode = promoCodeStore.promotionalCode.code
    }
    return new Account(account).$save()
      .then(function(res) {
        $rootScope.$broadcast('account:created', res)
        auth.isSynced = false
      })
      .catch(error => {
        if (error.data) {
          for (const field in error.data) {
            error.data[field].forEach(message => {
              ngToast.create({ className: 'error', content: `${message.message}` })
            })
          }
        } else {
          ngToast.create({ className: 'error', content: error })
        }
      })
  }
}
