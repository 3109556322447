'use strict'

module.exports = function() {

  return function(jobs, department) {

    function sortDepartmentAlphabetical(a, b) {
      if (a.department < b.department) {
        return -1
      } else if (a.department > b.department) {
        return 1
      }
      return 0
    }

    const preferredDepartmentJobs = [];
    const otherJobs = []

    // Break up jobs by preferred department first
    angular.forEach(jobs, function(job) {
      if (job.department == department) {
        preferredDepartmentJobs.push(job)
      } else {
        otherJobs.push(job)
      }
    })

    // Sort by other jobs to match department alphabetical order
    otherJobs.sort(sortDepartmentAlphabetical)

    return preferredDepartmentJobs.concat(otherJobs)
  }
}
