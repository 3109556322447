module.exports = function knownVariants($http, reviewKnownVariantModal, confirmTrueNegativeModal) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      reportId: '=',
      reportedVariants: '=',
    },
    templateUrl: '/results/known_variants.html',
    link: function(scope) {
      const refreshReportedVariants = () => {
        $http.get(`/api/v1/reports/${scope.reportId}/reported_variants`)
          .then(response => scope.reportedVariants = response.data)
      }
      refreshReportedVariants()

      scope.reviewVariant = (reportedVariant) => {
        const modal = reviewKnownVariantModal.open(reportedVariant.id)
        modal.result.then(refreshReportedVariants)
      }

      scope.confirmTrueNegative = (reportedVariant) => {
        const modal = confirmTrueNegativeModal.open(reportedVariant.id)
        modal.result.then(refreshReportedVariants)
      }

    }
  }
}
