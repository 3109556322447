module.exports = function ProviderPlatformHomeController($scope, $location, auth,
  EmailConfirmationResend, ngToast, ProviderHomeInitialMessages, cppHomeUrlPath,
  DetailedPhysicianOrder, waffle) {
  'ngInject'

  function setOrders(promise) {
    $scope.loading = true
    promise.then(function(res) {
      $scope.orders = res
    }).finally(function(res) {
      $scope.loading = false
    })
  }

  $scope.directPurchasingFlowBannerIsVisible = true

  const params = cppHomeUrlPath.getApiParamsFromLocation()
  setOrders(DetailedPhysicianOrder.get(params).$promise)
  $scope.routeName = cppHomeUrlPath.getRouteName()

  const user = auth.currentUser
  $scope.isEmailConfirmed = user.is_email_confirmed
  $scope.userEmail = user.email
  $scope.showGroup = user.provider_profile && user.provider_profile.settings.show_group

  $scope.viewOrder = function(order) {
    const showResultStatuses = ['released_to_provider', 'released_to_patient', 'viewed_by_patient']
    if (showResultStatuses.indexOf(order.status.name) != -1) {
      $location.path('/providers/order/results/' + order.requisition_number)
    } else {
      $location.path('/providers/order/log/' + order.requisition_number)
    }
  }

  $scope.resending = false

  $scope.resendConfirmationEmail = function() {
    if (!$scope.resending) {
      $scope.resending = true
      EmailConfirmationResend.save().$promise
        .then(function() {
          ngToast.create('We have resent the email.')
        })
        .finally(function() {
          $scope.resending = false
        })
    }
  }

  ProviderHomeInitialMessages.run()
}
