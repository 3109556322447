"use strict"

module.exports = function($rootScope) {
  "ngInject"

  return {
    restrict: 'A',
    require: '^clrTabSet',
    scope: true,
    link: function(scope, elem) {
      $rootScope.$on('clrTab:setUnderline', function(event, data) {
        elem.width(data.width).css('left', data.left)
      })
    }
  }
}
