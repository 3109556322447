"use strict"

module.exports = function($rootScope) {
  "ngInject"

  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    require: '^clrTabSet',
    scope: {
      right: '@?',
      showOnDock: '@?'
    },
    templateUrl: '/directives/clr_tab_custom.html'
  }
}
