/**
 * @prettier
 */

module.exports = function reportVus(auth, testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_vus.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      scope.currentUser = auth.currentUser;
      const mutations = [];
      angular.forEach(scope.reportContent.mutations, function(mutation) {
        if (mutation.classification == 'Variant of Uncertain Significance') {
          mutations.push(mutation);
        }
      });
      scope.mutations = mutations;
    },
  };
};
