/**
 * @prettier
 */

/**
 * This component renders each individual reference in full text following the AMA citation style
 * https://owl.english.purdue.edu/owl/resource/1017/01/
 */
module.exports = function(marked) {
  'ngInject';

  return {
    scope: {
      reference: '=fullReference',
    },
    template:
      '<span ng-if="reference.authors">{{reference.authors}}. </span><span ng-bind-html="markdownTitle"></span>.<i ng-if="reference.journal"> {{reference.journal}}. </i><span ng-if="reference.date"> {{reference.date}}</span><span ng-if="reference.date && reference.volume">;</span><span ng-if="reference.volume">{{reference.volume}}</span><span ng-if="reference.issue_number">({{reference.issue_number}})</span><span ng-if="(reference.volume || reference.issue_number) && reference.pages">:</span><span ng-if="reference.pages">{{reference.pages}}</span><span ng-if="reference.date || reference.volume || reference.issue_number || reference.pages">.</span><span ng-if="reference.url"> <a href="{{reference.url}}" target="_blank" rel="noopener noreferrer">{{reference.url_title}}</a>.</span>',
    link: function(scope) {
      // Markdown wraps the title in a <p> and new line, so we need to remove it
      function updateTitle() {
        if (scope.reference) {
          scope.markdownTitle = marked(scope.reference.title).replace(/(<p>|<\/p>|\s+$)/g, '');
        }
      }
      scope.$watch('reference.title', function() {
        updateTitle();
      });
    },
  };
};
