'use strict'

module.exports = angular.module('resultsV2Services', [])
  .factory('handleReportClick', function(ShareProviderModal, ScheduleAppointmentModal, emailFamilyModal, sampleLetterModal) {

    return function(e, reportShareFamilyData, test_type, kitOrderNumber) {
      if (e.target.tagName.toLowerCase() != 'a') {
        return
      }

      if (e.target.href.indexOf('appointments') > -1) {
        e.preventDefault()
        ScheduleAppointmentModal.open(kitOrderNumber)
        return
      }

      const cls = e.target.classList;
      for (let i = 0; i < cls.length; i++) {
        switch (cls[i]) {
          case 'action--sample_letter':
            e.preventDefault()
            sampleLetterModal.open(reportShareFamilyData.msgMarkdownSampleLetter)
            break;
          case 'action--share_provider':
            e.preventDefault()
            ShareProviderModal.open(kitOrderNumber)
            break;
          case 'action--email_relatives':
            e.preventDefault()
            emailFamilyModal.open(
              reportShareFamilyData.msgMarkdownSampleLetter,
              reportShareFamilyData.reportId,
              test_type
            )
            break;
          case 'action--appointment':
            e.preventDefault()
            ScheduleAppointmentModal.open(kitOrderNumber)
            break;
          default:
            break;
        }
      }
    }
  })
