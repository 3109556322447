'use strict'

module.exports = function reportAffectOtherGender($filter, testTypes) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_affect_other_gender.html',
    scope: {
      reportContent: '='
    },
    link: function(scope) {

      function createGeneReport(report, gene, gender, allelicity, biallelicKnown) {
        const geneReport = {}
        geneReport.allelicity = $filter('numericAllelicity')(allelicity)
        geneReport.gene = gene.name
        geneReport.mutationName = gene.mutationName ? ' ' + gene.mutationName : ''
        geneReport.mutations = $filter('filter')(report.mutations, { gene: gene.name })
        geneReport.biallelicKnown = biallelicKnown
        geneReport.mutationNoun = 'mutation'
        geneReport.p1Exception = false
        // Table risks are different than paragraph risks, and when > 1 mutation, tables show both genders, necessitating a separate tableRisks. Also, if > 1 mutation and BO19, we need to show `not elevated` risks because breast or ovarian are marketed as such, therefore these tables need to show those `not elevated` risks.
        if (scope.reportContent.genes_with_pathogenic_mutations.length > 1 && scope.reportContent.template.test_type == testTypes.breastOvarian19) {
          geneReport.tableRisks = $filter('sortedRisks')(report, gender, false, null, gene.name, geneReport.allelicity)
          if ($filter('allRisksNotElevated')(geneReport.tableRisks.pathogenic)) {
            // Workaround to get the `RESULT.AFFECTS_OTHERS.P1_NO_EFFECT` content to fire, ideally uses a `showCard...` value
            // Isolating right now to > 1 until more test coverage is added to cover the single pathogenic reports
            geneReport.tableRisks.pathogenic = []
          }
        } else {
          geneReport.tableRisks = $filter('sortedRisks')(report, gender, true, null, gene.name, geneReport.allelicity)
        }
        geneReport.tableRisks.pathogenic = $filter('sortRiskByPhenotype')(geneReport.tableRisks.pathogenic, 'all', [gene.name], scope.reportContent.template.test_type)
        geneReport.paragraphRisks = $filter('sortedRisks')(report, gender, true, null, gene.name, geneReport.allelicity)
        geneReport.paragraphRisks.pathogenic = $filter('sortRiskByPhenotype')(geneReport.paragraphRisks.pathogenic, 'all', [gene.name], scope.reportContent.template.test_type)

        geneReport.tableRiskReferences = $filter('uniqueReferences')(geneReport.tableRisks.pathogenic)
        geneReport.tableBaselineReferences = $filter('uniqueReferences')(geneReport.tableRisks.baseline)

        // Setting common risk age.
        geneReport.commonRiskAge = $filter('commonRiskAge')(scope.reportContent.genes_with_pathogenic_mutations, geneReport.tableRisks.pathogenic)
        // Following double muts requires different age buckets since they don't share common age.
        if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRCA1', 'MSH6']) && geneReport.gene == 'MSH6') {
          geneReport.commonRiskAge = 70
        } else if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRCA1', 'MSH2']) && geneReport.gene == 'MSH2') {
          geneReport.commonRiskAge = 70
        } else if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['MUTYH', 'MSH2']) && geneReport.gene == 'MUTYH' && scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic') {
          geneReport.commonRiskAge = 80
        } else if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['CHEK2', 'RAD51D']) && geneReport.gene == 'RAD51D') {
          geneReport.commonRiskAge = 80
        } else if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['APC', 'CHEK2']) && !geneReport.mutationName) {
          geneReport.commonRiskAge = 80
        } else if ($filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, ['BRIP1', 'MITF']) && geneReport.gene == 'BRIP1') {
          geneReport.commonRiskAge = 80
        }

        geneReport.isElevated = geneReport.tableRisks.pathogenic.filter(function(risk) {
          return $filter('isElevated')(risk.data[0].risk)
        })

        // Add STK11-specific age exceptions
        geneReport.tableRisks.pathogenic = $filter('insertDisplayAge')(geneReport.tableRisks.pathogenic, geneReport.commonRiskAge, scope.reportContent.render_version)
        geneReport.hasAgeExceptionsAt = $filter('hasAgeExceptionsAt')(geneReport.tableRisks.pathogenic)

        if (geneReport.tableRisks.pathogenic.length) {
          angular.forEach(geneReport.tableRisks.pathogenic, function(risk) {
            risk.baseline = geneReport.tableRisks.baseline.filter(function(baseline) {
              return risk.phenotype == baseline.phenotype
            })[0]
          })
        }

        // Start paragraph string generation here:
        if (gender == 'M') {
          geneReport.gender = 'M'
          geneReport.genderNoun = 'man'
          geneReport.genderNounPlural = 'men'
          geneReport.genderThirdPersonPronoun = 'he'
          geneReport.genderPossessiveAdjective = 'his'
        } else if (gender == 'F') {
          geneReport.gender = 'F'
          geneReport.genderNoun = 'woman'
          geneReport.genderNounPlural = 'women'
          geneReport.genderThirdPersonPronoun = 'she'
          geneReport.genderPossessiveAdjective = 'her'
        }
        if ($filter('isLikelyPathogenic')(geneReport.mutations)) {
          geneReport.msgIsLikelyPathogenic = 'likely pathogenic'
        }
        geneReport.cancers = $filter('cancerString')(geneReport.paragraphRisks.pathogenic)

        // CDH1-specific string replacement for this report, the phenotype to have this direct callout
        if (gene.name == 'CDH1' && geneReport.gender == 'M') {
          if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
            geneReport.cancers = geneReport.cancers.replace('gastric cancer', 'stomach cancer, particularly a rare type of stomach cancer called diffuse gastric cancer,')
          } else if (scope.reportContent.template.test_type == testTypes.hereditary30) {
            geneReport.cancers = geneReport.cancers.replace('gastric cancer', 'gastric (stomach) cancer, particularly a rare type of cancer called diffuse gastric cancer,')
          }
        }
        if (gene.name == 'CDH1' && geneReport.gender == 'F') {
          geneReport.cancers = geneReport.cancers.replace('breast and gastric cancer', 'developing breast cancer (particularly a type called lobular breast cancer) and gastric cancer (particularly a rare type called hereditary diffuse gastric cancer or HDGC)')
        }
        if (gene.name == 'BAP1') {
          geneReport.cancers = geneReport.cancers.replace('kidney, lung (mesothelioma) cancer and melanoma', 'melanoma of the eye (uveal) and skin (cutaneous), kidney, and lung (specifically a type called mesothelioma) cancer')
        }
        if (angular.isDefined(geneReport.paragraphRisks.pathogenic)) {
          geneReport.cancersVerb = geneReport.paragraphRisks.pathogenic.length > 1 ? 'are' : 'is'
          geneReport.chanceNoun = geneReport.paragraphRisks.pathogenic.length > 1 ? 'chances' : 'chance'
        }
        // MSH6-specific overrides
        if (gene.name == 'MSH6') {
          // MSH6 Homozygous (CMMR-D) reports have very unique scenarios, therefore none of the existing paragraph logic is applicable
          if (geneReport.allelicity == 2) {
            if (geneReport.gender == 'M') {
              geneReport.p1Exception = 'If a man has a mutation in each copy of the <em>MSH6</em> gene, his chances of developing brain, blood, colorectal, small bowel, and other cancers are greater than that of the average US man. Most individuals with a mutation in each copy of the <em>MSH6</em> gene will develop cancer, but the specific lifetime risks of developing each type of cancer is unknown. This does not mean that he has a diagnosis of cancer or that he will definitely develop cancer in his lifetime.'
            } else {
              // TODO: No MSH6 F Homozygous copy is currently available, fill when we have need for that report
            }
          } else {
            if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
              geneReport.cancers = 'colorectal and other cancers'
            } else if (scope.reportContent.template.test_type == testTypes.hereditary30) {
              if (gender == 'F') {
                geneReport.cancers = 'colorectal, ovarian, uterine, brain, hepatobiliary tract, pancreatic, sebaceous neoplasms, small bowel, stomach, and urinary tract cancer'
              } else if (gender == 'M') {
                geneReport.cancers = 'colorectal and other cancers'
              }
            }
            geneReport.cancersVerb = 'are'
            geneReport.chanceNoun = 'chances'
          }
        }
        // TP53-specific overrides
        if (gene.name == 'TP53') {
          if (gender == 'F') {
            geneReport.cancers = 'certain cancers, especially breast, and brain cancer and sarcoma,'
            geneReport.cancersVerb = 'are'
            geneReport.chanceNoun = 'chances'
            geneReport.anyCancerRisk = $filter('filter')(geneReport.tableRisks.pathogenic, { 'phenotype': 'any cancer' })[0]
          } else {
            geneReport.cancers = 'certain cancers, especially brain cancer and sarcoma,'
            geneReport.cancersVerb = 'are'
            geneReport.chanceNoun = 'chances'
            geneReport.anyCancerRisk = $filter('filter')(geneReport.tableRisks.pathogenic, { 'phenotype': 'any cancer' })[0]
          }
        }
        // APC-specific (non-I1307K) overrides
        if (gene.name == 'APC' && !gene.mutationName) {
          geneReport.cancers = 'colorectal and other cancers, including brain (medulloblastoma), pancreatic, small bowel (duodenal), stomach, and thyroid, as well as non-cancerous growths called desmoid tumors,'
          geneReport.cancersVerb = 'are'
          geneReport.chanceNoun = 'chances'
        }
        // APC-specific (I1307K) overrides
        if (gene.name == 'APC' && gene.mutationName == 'I1307K') {
          geneReport.isSlightly = 'slightly '
        }
        // MUTYH two variants with unknown phase overrides
        if (gene.name == 'MUTYH' && geneReport.allelicity == '2') {
          geneReport.cancers = 'colorectal and small bowel cancer (especially in the duodenum)'
        }
        // BMPR1A
        if (gene.name == 'BMPR1A') {
          geneReport.cancers = 'colorectal, stomach, pancreatic, and small bowel (especially in the duodenum) cancer'
        }
        // CHEK2 Homozygous F
        if (gene.name == 'CHEK2' && gene.isHomozygous && geneReport.gender == 'F') {
          geneReport.cancers = 'cancer'
        }
        // SMAD4
        if (gene.name == 'SMAD4') {
          geneReport.cancers = 'colorectal, stomach, pancreatic, and small bowel (especially in the duodenum) cancer'
        }
        // RV < 5: This is errant logic only applicable to RV 1-4
        // Specifically to handle RV1-4 BARD1 + CHEK2: BARD1 only had age 80 risks at the time
        if (gene.name == 'BARD1' && scope.reportContent.render_version < 5) {
          geneReport.commonRiskAge = 80
        }

        // If compound het or homozygous, show extra content
        if ($filter('useSingleRiskLogic')(scope.reportContent, geneReport.gene)) {
          // For P1
          if (geneReport.allelicity == '1') {
            geneReport.singleP1Text = 'single '
            // For single homozygous with known biallelic risks, we show
            // monoallelic and biallelic risks in a separate table.
            if (biallelicKnown && !$filter('isSingleHomozygousReport')(scope.reportContent.mutations)) {
              geneReport.biallelicP1Text2 = ', or two mutations in the same copy of the <em>' + gene.name + '</em> gene'
            }
          } else {
            geneReport.biallelicP1Text1 = 'each copy of '
          }
          // For risk table column header
          if (biallelicKnown) {
            if (geneReport.allelicity == '1') {
              geneReport.withGeneNumMutation = 'single '
            } else {
              geneReport.withGeneNumMutation = 'two '
              geneReport.mutationNoun = 'mutations'
            }
          }
          if (gene.isHomozygous) {
            geneReport.isHomozygous = true
          } else if (scope.reportContent.allelicity_by_gene[geneReport.gene] == 'biallelic' || scope.reportContent.allelicity_by_gene[geneReport.gene] == null) {
            geneReport.isPotentiallyCompundHet = true
          }
        }

        const nonMutationSpecificRisks = geneReport.tableRisks.pathogenic.filter(function(risk) {
          return !risk.classification_unit_id
        })
        geneReport.allMutationSpecificRisks = !nonMutationSpecificRisks.length

        if (geneReport.mutationName && !geneReport.allMutationSpecificRisks) {
          if (nonMutationSpecificRisks.length == geneReport.tableRisks.pathogenic.length) {
            geneReport.nonMutationSpecificCancers = 'these'
          } else {
            geneReport.nonMutationSpecificCancers = 'the ' + $filter('cancerString')(nonMutationSpecificRisks)
            const mutationSpecificRisks = geneReport.tableRisks.pathogenic.filter(function(risk) {
              return risk.classification_unit_id
            })
            geneReport.mutationSpecificCancers = 'the ' + $filter('cancerString')(mutationSpecificRisks)
          }
        }

        return geneReport
      } // End of createGeneReport

      if (scope.reportContent.is_positive && !scope.reportContent.is_somatic) {
        scope.geneReports = []
        // If single P/LP, only show opposite gender. If >1 P/LP of different
        // genes, show opposite gender first, then same gender
        let genders
        if (scope.reportContent.genes_with_pathogenic_mutations.length == 1) {
          genders = scope.reportContent.gender == 'F' ? ['M'] : ['F']
        } else if (scope.reportContent.genes_with_pathogenic_mutations.length > 1) {
          genders = scope.reportContent.gender == 'F' ? ['M', 'F'] : ['F', 'M']
        }
        angular.forEach($filter('genesWithMutationName')(scope.reportContent.mutations), function(mutation) {
          const allelicities = [];
          const allelicity = scope.reportContent.allelicity_by_gene[mutation.name];
          let biallelicKnown = false
          // For single homozygous reports (which are biallelic), for our current set of reports, there are none that have biallelic risks so we're only using the monoallelic risks
          if (allelicity && allelicity != 'biallelic') {
            allelicities.push(allelicity)
          } else { // Show both monoallelic and biallelic for unknown phase if exists.
            if ($filter('filter')(scope.reportContent.risks, { gene: mutation.name, allelicity: '2' }).length) {
              allelicities.push('biallelic')
              biallelicKnown = true
            }
            // MSH6 Homozygous (CMMR-D) has special condition where the biallelic mutation
            // has no risks associated but still needs a card to appear
            if (allelicity == 'biallelic' && mutation.name == 'MSH6' && mutation.isHomozygous) {
              allelicities.push('biallelic')
            }
            allelicities.push('monoallelic')
          }
          angular.forEach(genders, function(gender) {
            angular.forEach(allelicities, function(allelicity) {
              scope.geneReports.push(createGeneReport(scope.reportContent, mutation, gender, allelicity, biallelicKnown))
            })
          })

          // For single homozygous reports that have known biallelic risks,
          // we show biallelic risks only for client's gender in summary.
          // So we show monoallelic risks in the affect other gender section.
          // This only applies to MUTYH Homozygous currently.
          // Similarly for MSH6 Homozygous, no known risks exists for biallelic but because
          // of the known CMMR-D, we need to show copy here without a table
          if ((biallelicKnown || mutation.name == 'MSH6') && mutation.isHomozygous && genders.length == 1) {
            scope.geneReports.push(createGeneReport(scope.reportContent, mutation, scope.reportContent.gender, 'monoallelic', biallelicKnown))
          }
        })
      }
    }
  }
}
