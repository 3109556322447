"use strict"

/**
 * The equivalent of setting:
 *
 *   <input name="foo"
 *          ng-focus="form.foo.showError = false"
 *          ng-blur="form.foo.showError = true">
 *
 * On an input element. With this you can just do:
 *
 *   <input name="foo" show-error-on-blur>
 *
 */
module.exports = function () {
  "ngInject"

  return {
    restrict: 'A',
    require: '^form',
    link: function (scope, element, attrs, formCtrl) {
      const getName = function () {
        return attrs.showErrorOnBlur && attrs.showErrorOnBlur != "show-error-on-blur" ? attrs.showErrorOnBlur : attrs.name
      }

      element.on('focus', function (event) {
        scope.$applyAsync(function () {
          // Check if form exists as it may be removed
          const form = formCtrl[getName()]
          if (form) form.showError = true
        })
      })

      element.on('blur', function (event) {
        scope.$applyAsync(function () {
          // Check if form exists as it may be removed
          const form = formCtrl[getName()]
          if (form) form.showError = true
        })
      })
    }
  }
}
