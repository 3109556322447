module.exports = function ShareProvider($modal) {
  'ngInject'

  return {
    open: function(kitOrderNumber) {
      return $modal.open({
        templateUrl: '/results_v2/modals/ShareProviderModal.html',
        controller: function($scope, $modalInstance, HealthcareProvider, ngToast) {
          'ngInject'
          $scope.healthcareProvider = new HealthcareProvider()
          $scope.confirming = false
          $scope.disclaimer = false
          $scope.healthcareProvider.order_number = kitOrderNumber
          $scope.confirmProvider = function() {
            $scope.confirming = true
          }
          $scope.addProvider = function() {
            $scope.submitting = true
            $scope.healthcareProvider.$save()
              .then(function(res) {
                $scope.submitting = false
                ngToast.create('Your results will be sent soon.')

                // hack to refresh the report frame
                const reportFrame = angular.element('#reportFrame')[0]
                if (reportFrame && reportFrame.contentWindow.ReportStore) {
                  reportFrame.contentWindow.ReportStore.fetch()
                }
                $modalInstance.close(res)
              })
              .finally(function(res) {
                $scope.submitting = false
              })
          }
        },
        size: 'sm'
      })
    }
  }
}
