/**
 * @prettier
 */

module.exports = function reportRevisions() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_revisions.html',
    replace: true,
    scope: {
      revisions: '=',
    },
  };
};
