'use strict'

module.exports = function hbocInsuranceCriteriaForm(
  auth,
  cancerTypes,
  criteriaRelationshipTypes,
  fileUploader,
  ngToast,
  sideOfFamilyTypes,
  uploadFileTypes,
  InsuranceCriteriaSubmission
) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/hboc_insurance_criteria_form.html',
    scope: {
      order: '=',
      onSubmit: '=?',
      userProvider: '=',
      checkout: '=',
      icdCodesRequired: '=',
    },
    require: '^form',
    link: function(scope, elem, attr, ctrl) {
      // Data models

      scope.order.ui.criteria = scope.order.ui.criteria || {
        criteria: {
          is_ashkenazi_jewish_descent: undefined,
          upload_files: []
        },
        patientDiagnoses: [],
        familyDiagnoses: [],
        familyGeneticTestResults: [],
        hasHadCancer: undefined,
        hasFamilyHistoryOfCancer: undefined,
        hasFamilyWithPositiveMutation: undefined,
      }

      scope.criteria = scope.order.ui.criteria.criteria
      scope.patientDiagnoses = scope.order.ui.criteria.patientDiagnoses
      scope.familyDiagnoses = scope.order.ui.criteria.familyDiagnoses
      scope.familyGeneticTestResults = scope.order.ui.criteria.familyGeneticTestResults

      // UI

      scope.isSupportUser = auth.currentUser.is_support_staff
      scope.ui = scope.order.ui.criteria
      scope.submitting = false
      scope.requiresCompleteForm = true

      // Add watchers to clear data if selections change.

      scope.$watch('ui.hasHadCancer', function(newValue) {
        if (newValue === false || newValue === 'unknown') {
          scope.patientDiagnoses = []
        } else if (newValue === true && scope.patientDiagnoses.length === 0) {
          scope.patientDiagnoses.push({})
        }
      })

      scope.$watch('ui.hasFamilyHistoryOfCancer', function(newValue) {
        if (newValue === false || newValue === 'unknown') {
          scope.familyDiagnoses = []
        } else if (newValue === true && scope.familyDiagnoses.length === 0) {
          scope.familyDiagnoses.push({})
        }
      })

      scope.$watch('ui.hasFamilyWithPositiveMutation', function(newValue) {
        if (newValue === false || newValue === 'unknown') {
          scope.familyGeneticTestResults = []
        } else if (newValue === true && scope.familyGeneticTestResults.length === 0) {
          scope.familyGeneticTestResults.push({})
        }
      })

      function removeElement(propertyName) {
        return function(element) {
          const index = scope[propertyName].indexOf(element)
          scope[propertyName].splice(index, 1)
        }
      }

      scope.removePatientDiagnosis = removeElement('patientDiagnoses')
      scope.removeFamilyDiagnosis = removeElement('familyDiagnoses')
      scope.removeFamilyGeneticTestResult = removeElement('familyGeneticTestResults')

      function isValid() {
        const hbocInsuranceCriteriaForm = elem.find('form[name=hbocInsuranceCriteriaForm]').controller('form')
        return hbocInsuranceCriteriaForm.validate()
      }

      scope.checkCriteria = function() {
        if (!isValid()) {
          return
        }

        // We set the value to the string "Unknown" because ``ng-model`` cannot
        // distinguish between ``null`` and ``undefined``, so we have to use
        // another value, otherwise some form inputs will be initially filled
        // out.
        const criteria = angular.copy(scope.criteria)
        criteria.diagnoses = []
          .concat(angular.copy(scope.patientDiagnoses))
          .concat(angular.copy(scope.familyDiagnoses))
        criteria.genetic_test_results = []
          .concat(angular.copy(scope.familyGeneticTestResults))

        if (criteria.is_ashkenazi_jewish_descent === 'unknown') {
          criteria.is_ashkenazi_jewish_descent = null
        }

        if (scope.hasFamilyHistoryOfCancer === 'unknown') {
          criteria.has_limited_or_unknown_family_history = true
        }

        // The ICD-10 code selector unfortunately offers no interface for
        // post-processing due to the UI, so we marshal the codes into the
        // right format here.
        for (let i = 0; i < criteria.diagnoses.length; i++) {
          const diagnosis = criteria.diagnoses[i]
          diagnosis.icd_10_cm_codes = diagnosis.icd_10_cm_codes.map(function(code) {
            return code.name
          })
        }

        const submission = new InsuranceCriteriaSubmission({
          patient_info: {
            first_name: scope.order.patient_profile.first_name,
            last_name: scope.order.patient_profile.last_name,
            birthday: scope.order.patient_profile.birthday,
            gender: scope.order.patient_profile.gender,
          },
          hboc_criteria: criteria,
          payer: scope.order.payment.eligibility_submission.payer
        })

        scope.submitting = true

        submission.$save()
          .then(function(response) {
            scope.order.payment.criteria_submission = response
            const attempted_submission = scope.order.attempted_insurance_submission
            attempted_submission.criteria_submission = {id:  response.id}
            delete attempted_submission.id // Wipe ID if exists since the submission has now changed
            scope.onSubmit()
          })
          .finally(function() {
            scope.submitting = false
          })
      }
    }
  }
}
