'use strict'

// extract a healthcare provider for the backend API
function healthcareProviderFromProfile(profile) {
  return {
    provider_id: profile.id,
    formal_name: profile.formal_name,
    first_name: profile.first_name,
    last_name: profile.last_name,
    institution_name: profile.institution_name,
    city: profile.city,
    state: profile.state
  }
}

module.exports = function($window, $cookies, $q, $rootScope, $http, resolvedPromise, auth, Stripe,
  Checkout, CreditCard, Purchase, PhysicianOrder, KitStatus, HealthcareProvider,
  promoCodeStore, clinicalDomains, testTypes, associatedSample, requisitionStore,
  healthcareProviderStore, sku, kitPriceService, claimStore) {
  'ngInject'

  const KitCheckout = Checkout.subclass()

  KitCheckout.prototype.init = function(start, skuName) {
    if (this.skipReset) {
      this.skipReset = false
      return
    }

    this._quantity = 1
    if (!skuName) {
      skuName = testTypes.hereditary30
    }
    this.sku = sku[skuName]
    this.purchase = new Purchase({
      stripe_card_token: null,
      shipping_address: null,
      shared_providers: [],
      physician_order: null,
      authorized_to_order: true, // set this to false if we are not asking ordering option.
      kit_barcode: false, // only set for payment-only controller
      promotional_code: null,
      claim_token: null,
      kit_for_self: true
    })
    this.shippingAddress = {}
    this.requireShipping = true

    this.creditCard = new CreditCard()
    this.withColorOrderingPhysician = true
    this.sendCopyToHealthcareProviders = false
    this.physicianOrder = new PhysicianOrder() // this is passed to and used in the clrOrderingPhysician directive
    this.skipReset = false
    this.edit = false
    this.paymentErrorsCount = 0
    this.currentUser = auth.currentUser
    this.isSampleReuse = false
    this.existingPhysicianOrder = false
    this._modifierPrice = 0
    this._modifierName = ''
    // default heardAboutColorThrough cookie might be set via applyAttributionRedirect resolver, usually via ads traffic
    this.heardAboutColorThrough = $cookies.get('attribution') || null

    // load all existing providers to prepopulate the ordering physician info
    const healthcareProvider = healthcareProviderStore.getHealthcareProvider()
    if (healthcareProvider) {
      this.purchase.shared_providers.push(new HealthcareProvider(healthcareProvider))
      this.sendCopyToHealthcareProviders = true
    }
    auth.sync().then(angular.bind(this, function() {
      if (!start || !auth.isLoggedIn || !auth.isSynced || this.sku.testTypes.length > 1) {
        return null
      }
      return KitStatus.get().$promise
    }))
      .then(angular.bind(this, function(res) {
        if (res) {
          const clinicalDomain = clinicalDomains[this.sku.testTypes[0]]
          res.results.filter(function(result) {
            return clinicalDomains[result.test_type] == clinicalDomain
          }).map(angular.bind(this, this.syncOrderingInformation))
        }
      }))
    if (start) {
      this.updateModifierPrice()
    }
  }

  KitCheckout.prototype.removeModifier = function() {
    this._modifierPrice = 0
    this._modifierName = ''
  }

  KitCheckout.prototype.updateModifierPrice = function() {
    const params = {
      sku: this.sku.name,
      quantity: this.getQuantity(),
      kit_for_self: this.purchase.kit_for_self
    }
    $http.get('api/v1/get_modifier_status', {params: params, cache: true})
      .then(angular.bind(this, function(res) {
        this.removeModifier()
        if (res.data) {
          this._modifierPrice = (parseInt(res.data.modifier_price_cents) / 100)
          this._modifierName = res.data.modifier_name
        }
      }))
  }

  KitCheckout.prototype.incrementKit = function() {
    Checkout.prototype.incrementKit.call(this)
    this.updateModifierPrice()
  }

  KitCheckout.prototype.decrementKit = function() {
    Checkout.prototype.decrementKit.call(this)
    this.updateModifierPrice()
  }

  KitCheckout.prototype.setKitForSelf = function(kitForSelf) {
    this.purchase.kit_for_self = kitForSelf
    this.updateModifierPrice()
  }

  KitCheckout.prototype.setSku = function(sku) {
    this.sku = sku
    this.updateModifierPrice()
  }

  KitCheckout.prototype.syncOrderingInformation = function(kitStatus) {
    if (kitStatus.shared_providers) {
      kitStatus.shared_providers.map(angular.bind(this, function(healthcareProvider) {
        if (healthcareProvider.is_verified) {
          this.purchase.shared_providers.push(
            new HealthcareProvider(healthcareProvider))
        }
      }))
    }
    if (kitStatus.physician_order &&
        kitStatus.physician_order.ordering_physician_profile &&
        kitStatus.physician_order.ordering_physician_profile.is_verified) {
      this.purchase.shared_providers.push(
        healthcareProviderFromProfile(kitStatus.physician_order.ordering_physician_profile))
    }
    if (kitStatus.physician_order &&
        kitStatus.physician_order.placed_by_profile &&
        kitStatus.physician_order.placed_by_profile.is_verified) {
      this.purchase.shared_providers.push(
        healthcareProviderFromProfile(kitStatus.physician_order.placed_by_profile))
    }
    if (this.purchase.shared_providers.length > 0) {
      this.sendCopyToHealthcareProviders = true
    }
  }

  KitCheckout.prototype.getKitPriceDollarsAfterModifiers = function() {
    const priceDollars = kitPriceService.getAdvertisedKitPriceDollars(this.sku)
    // exclude FTP codes and benefits channel from modifiers
    if (!promoCodeStore.isEligibleForPricingModifiers() || (claimStore.claim && claimStore.claim.is_redeemable)) {
      return priceDollars
    }
    return priceDollars + this._modifierPrice
  }

  KitCheckout.prototype.getTotalPriceDollars = function() {
    return Checkout.prototype.getTotalPriceDollars.call(this)
  }

  KitCheckout.prototype.isPaymentRequired = function() {
    return Checkout.prototype.isPaymentRequired.call(this)
  }

  KitCheckout.prototype.testTypes = function() {
    // It's an anti-pattern to modify states in getter methods.
    // We do similar thing in getQuantity().
    // physicianOrder gets set in different form directive
    // so it's a bit hard to udpate sku based on physician order
    // update.
    // TODO: Clean this logic and move this out from this method.
    // https://getcolor.atlassian.net/browse/ENG-169
    const testRequested = this.physicianOrder.test_requested
    if (testRequested && testRequested != this.sku.name) {
      this.sku = sku[testRequested]
    }
    return this.sku.testTypes
  }

  KitCheckout.prototype.prepareOrderInfo = function() {
    // Clear healthcare providers if clients decided:
    // * not to send copy to healthcare providers, or
    // * not to use color ordering physician.
    if (!this.sendCopyToHealthcareProviders || !this.withColorOrderingPhysician || !this.purchase.kit_for_self) {
      this.purchase.shared_providers = []
    }
    if (!this.withColorOrderingPhysician && this.purchase.kit_for_self) {
      this.purchase.physician_order = this.physicianOrder.id
    }
  }

  // prepare kitOrder for saving. This should be idempotent because it can
  // get called multiple times if the payment card is declined.
  KitCheckout.prototype.preparePurchase = function() {
    this.prepareOrderInfo()

    if (claimStore.claim && claimStore.claim.is_redeemable) {
      this.purchase.claim_token = claimStore.claim.token;
    } else if (promoCodeStore.promotionalCode.can_redeem) {
      this.purchase.promotional_code = promoCodeStore.promotionalCode.code
    }

    if (this.heardAboutColorThrough) {
      this.purchase.heard_about_color_through = this.heardAboutColorThrough
    }

    if (this.isSampleReuse) {
      this.purchase.is_sample_reuse = true
      this.purchase.phone_number = {
        'country': this.currentUser.phone.country,
        'national_number': this.currentUser.phone.national_number
      }
      // Sample reuse currently only supports ColorConsent
      this.purchase.consent_type = 'color'
    }

    this.purchase.items = [{sku: this.sku.name, quantity: this.getQuantity()}]
  }

  // NY address requires NY physician.
  KitCheckout.prototype.hasNewYorkOrderMismatch = function() {
    const country = this.shippingAddress.country
    const state = (this.shippingAddress.state || '').toUpperCase()
    let physicianOrderState = ''
    let hasPhysicianProfile = false
    if (
      // check that the physician order would be applied
      !this.withColorOrderingPhysician &&
      this.purchase.kit_for_self &&
      // check there is a physician order
      this.physicianOrder &&
      this.physicianOrder.ordering_physician_profile
    ) {
      hasPhysicianProfile = true
      physicianOrderState = this.physicianOrder.ordering_physician_profile.state
    }
    return country == 'US' && state == 'NY' && hasPhysicianProfile && physicianOrderState != 'NY'
  }

  KitCheckout.prototype.setCreditCardToken = function() {
    if (this.isPaymentRequired()) {
      return this.creditCard.$save()
        .then(angular.bind(this, function(res) {
          this.purchase.stripe_card_token = res.id
        }))
    } else {
      return resolvedPromise()
    }
  }

  KitCheckout.prototype.setShippingAddress = function() {
    if (this.requireShipping) {
      this.purchase.shipping_address = this.shippingAddress
    }
  }

  KitCheckout.prototype.placePurchase = function(currentUser) {
    // Prevent double submission of purchase. Not concurrency safe but should
    // be good enough.
    if (this.placingPurchase) {
      return $q.reject({
        message: 'Please wait, your purchase is being processed.'
      })
    }

    this.placingPurchase = true
    this.setShippingAddress()

    return this.setCreditCardToken()
      .then(angular.bind(this, function() {
        this.preparePurchase()
        return this._placePurchase()
      }))
      .finally(angular.bind(this, function() {
        this.placingPurchase = false
      }))
  }

  KitCheckout.prototype._placePurchase = function() {
    return this.purchase.$save()
      .then(angular.bind(this, function(res) {
      // re-sync the promo code instead of clearing if we can still add dependents.
      // this is executed after a route change so as to not modify the UI.
        const cleanUp = $rootScope.$on('$routeChangeSuccess', angular.bind(this, function() {
          if (promoCodeStore.canAddDependents()) {
            promoCodeStore.sync()
          } else {
            promoCodeStore.clear()
          }
          if (claimStore.claim) {
            claimStore.clearClaim();
            claimStore.getClaim();
          }
          cleanUp()
        }))
        requisitionStore.clear()
        res.hasAssociatedSample = !!this.purchase.kit_barcode
        associatedSample.clear()
        healthcareProviderStore.clear()
        return res
      }))
      .catch(function(res) {
      // If there was a problem finding the associated sample, clear the sample info
        if (res.data && res.data.kit_barcode) {
          associatedSample.clear()
        }
        // Propagate the error
        return $q.reject(res)
      })
  }

  // Instructions on how to test ApplePay locally:
  // https://gist.github.com/kn/b33a57b8f5b86617df0195e832fb0b70
  KitCheckout.prototype.placePurchaseWithApplePay = function() {
    const deferredResult = $q.defer()
    this.preparePurchase()
    let colorTestLabel = 'Color Test'
    if (this.getQuantity() > 1) {
      colorTestLabel += ' (' + this.getQuantity() + ')'
    }
    const lineItems = [
      { label: colorTestLabel, amount: this.getKitSubtotalDollars().toFixed(2) },
      { label: 'Shipping + handling', amount: this.getShippingPriceDollars().toFixed(2) }
    ]

    if (this.getAppliedDiscountDollars() > 0) {
      lineItems.push({ label: 'Discount', amount: '-' + this.getAppliedDiscountDollars().toFixed(2) })
    }

    const request = {
      requiredShippingContactFields: ['postalAddress', 'phone', 'email', 'name'],
      countryCode: this.getShippingCountry(),
      currencyCode: 'USD',
      total: {
        label: 'Color',
        amount: this.getTotalPriceDollars().toFixed(2)
      },
      lineItems: lineItems
    }
    const applePaySession = Stripe.applePay.buildSession(request, angular.bind(this, function(result, completion) {
      const shipping = result.shippingContact
      this.shippingAddress.line1 = shipping.addressLines[0]
      this.shippingAddress.line2 = shipping.addressLines[1]
      this.shippingAddress.city = shipping.locality
      this.shippingAddress.state = shipping.administrativeArea
      this.shippingAddress.postal_code = shipping.postalCode
      this.shippingAddress.country = shipping.countryCode.toUpperCase()
      this.shippingAddress.full_name = shipping.givenName + ' ' + shipping.familyName
      this.shippingAddress.phone_number = shipping.phoneNumber

      if (this.shippingAddress.country != 'US') {
        completion($window.ApplePaySession.STATUS_FAILURE)
        deferredResult.reject({ error: 'ApplePay is only available for US shipping addresses.' })
        return deferredResult.promise
      }

      this.purchase.email = shipping.emailAddress
      this.purchase.stripe_card_token = result.token.id
      this.setShippingAddress()
      this._placePurchase()
        .then(angular.bind(this, function(res) {
          completion($window.ApplePaySession.STATUS_SUCCESS)
          deferredResult.resolve(res)
        }))
        .catch(angular.bind(this, function(res) {
          completion($window.ApplePaySession.STATUS_FAILURE)
          deferredResult.reject(res)
        }))
    }))
    applePaySession.begin()
    return deferredResult.promise
  }

  KitCheckout.prototype.placePurchaseWithStripeCheckout = function(token_response, address) {
    const deferredResult = $q.defer()
    this.preparePurchase()

    this.shippingAddress.line1 = address.shipping_address_line1
    this.shippingAddress.line2 = ''
    this.shippingAddress.city = address.shipping_address_city
    this.shippingAddress.state = address.shipping_address_state
    this.shippingAddress.postal_code = address.shipping_address_zip
    this.shippingAddress.full_name = address.shipping_name

    if (address.shipping_address_country != 'United States') {
      deferredResult.reject({ error: 'Stripe checkout is only available for US shipping address.' })
      return deferredResult.promise
    }
    this.shippingAddress.country = 'US'

    this.purchase.email = token_response.email
    this.purchase.stripe_card_token = token_response.id
    this.setShippingAddress()
    this._placePurchase()
      .then(function(res) {
        deferredResult.resolve(res)
      })
      .catch(function(res) {
        deferredResult.reject(res)
      })

    return deferredResult.promise
  }

  function createIfNotPersisted(resource, resolved) {
    if (resolved || !!resource.id) {
      return resolvedPromise(resource)
    } else {
      return resource.$save()
    }
  }

  return KitCheckout
}
