/**
 * @prettier
 */

module.exports = function($http) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
    },
    templateUrl: '/results/called_diplotypes.html',
    link: function(scope) {
      scope.diplotypeCalls = [];
      if (scope.report.sample_sequence_run) {
        scope.loading = true;
        $http
          .get(
            `/api/v1/diplotype_calls?sample_sequence_run=${scope.report.sample_sequence_run.id}&active_interpretations_only=false`
          )
          .then(res => {
            scope.diplotypeCalls = res.data.results;
          })
          .finally(() => {
            scope.loading = false;
          });
      }
    },
  };
};
