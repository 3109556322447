const PromoCodeMismatchModalController = function($scope, skuName, externalSkuListNames, $location, $modalInstance, promoCodeStore) {
  'ngInject'
  $scope.skuName = skuName
  $scope.externalSkuListNames = externalSkuListNames
  $scope.clearPromoCode = function () {
    promoCodeStore.clear()
    $modalInstance.close(true)
  }
  $scope.changeSelection = function() {
    $location.path('/product/compare-genetic-tests')
    $modalInstance.dismiss('change selection')
  }
}

module.exports = function PromoCodeMismatchModal($modal) {
  'ngInject'
  return {
    open: function(skuName, externalSkuListNames) {
      return $modal.open({
        templateUrl: '/kit_orders/modals/promo_code_mismatch.html',
        controller: PromoCodeMismatchModalController,
        size: 'sm',
        resolve: {
          skuName: function() {
            return skuName
          },
          externalSkuListNames: function() {
            return externalSkuListNames
          }
        }
      })
    }
  }
}
