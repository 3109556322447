/**
 * @prettier
 */

module.exports = function reportSummaryText($filter, interpretedGenes, testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_summary_text.html',
    replace: true,
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      scope.listOfGenes = $filter('orderBy')(
        interpretedGenes[scope.reportContent.template.test_type]
      ).join(', ');
      if (scope.reportContent.template.test_type == testTypes.wisdom9) {
        // We don't show low fraction variants for wisdom reports.
        scope.additionalFindingMutations = [];
      } else {
        // Curently, the only qualifications to show the Additional Findings blurb are low fraction/somatic mutations
        scope.additionalFindingMutations = $filter('additionalFindingMutations')(
          scope.reportContent.mutations
        );
      }
      if (scope.additionalFindingMutations.length) {
        scope.additionalFindingGenes = scope.additionalFindingMutations.map(function(mutation) {
          return mutation.gene;
        });
        scope.additionalFindingGenes = $filter('unique')(scope.additionalFindingGenes);
      }
      scope.hasTrueNegatives = $filter('filter')(
        scope.reportContent.reported_variants_in_relatives || [],
        { is_true_negative: true }
      ).length;
    },
  };
};
