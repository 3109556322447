module.exports = angular.module('orderingPhysicianDirectives', ['ngclipboard'])
  .directive('clrPatientProfile', require('./directives/clr_patient_profile'))
  .directive('clrProviderInput', require('./directives/clr_provider_input'))
  .directive('clrProviderInstitution', require('./directives/clr_provider_institution'))
  .directive('clrProviderProfile', require('./directives/clr_provider_profile'))
  .directive('cppBanner', require('./directives/cpp_banner'))
  .directive('insuranceChecklistAffix', require('./directives/insurance_checklist_affix'))
  .directive('orderTestingButton', require('./directives/order_testing_button'))
  .directive('physicianOrderSearchInput', require('./directives/physician_order_search_input'))
  .directive('providerHiw', require('./directives/provider_hiw'))
  .directive('providerOrderTabs', require('./directives/provider_order_tabs'))
  .directive('providerSharedOrderTabs', require('./directives/provider_shared_order_tabs'))
  .directive('providerPaginator', require('./directives/provider_paginator'))
  .directive('providerSidebar', require('./directives/provider_sidebar'))
  .directive('providerUploader', require('./directives/provider_uploader'))
  .directive('submitPaymentForm', require('./directives/submit_payment_form'))
  .directive('sharedOrderDetails', require('./directives/shared_order_details'))
  .directive('sharedOrderResults', require('./directives/shared_order_results'))
  .directive('selectEnrollmentType', require('./directives/select_enrollment_type'))
