'use strict'

module.exports = function($translate, ngToast, promoCodeStore, sku) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/kit_orders/directives/promocode_form.html',
    scope: {
      flexStyle: '@',
      testRequested: '=?'
    },
    link: function(scope, elem, attr, ctlr) {
      scope.promoCodeStore = promoCodeStore
      scope.promoCode = {}

      scope.togglePromotionalCodeInput = function() {
        scope.showPromotionalCodeInput = !scope.showPromotionalCodeInput
      }

      scope.removePromotionalCode = function() {
        scope.promoCode.code = ''
        scope.showPromotionalCodeInput = false
        promoCodeStore.clear()
      }

      scope.updateCode = function() {
        promoCodeStore.clear()
        promoCodeStore.updateCode(scope.promoCode.code, true).then(function(code) {
          const selectedSku = sku[scope.testRequested]
          if (scope.testRequested && !promoCodeStore.promoAppliesToSku(selectedSku)) {
            $translate('SKU.' + selectedSku.name)
              .then(function(translatedTestName) {
                ngToast.create({ className: 'error', content: "The promotion code you entered does not cover the " + translatedTestName})
              })
            scope.removePromotionalCode()
          } else if (code.can_redeem) {
            $translate('BILLING.TOASTS.PROMO_CODE_APPLIED')
              .then(function(translation) {
                ngToast.create(translation)
              })
          }
        })
      }

      // Adjusts promocode input setting if promocodeStore is dynamically updated
      scope.$watch('promoCodeStore.promotionalCode.can_redeem', function(canRedeem) {
        if (canRedeem) {
          scope.showPromotionalCodeInput = true
          // this variable helps avoid editing promoCodeStore.promotionalCode directly
          scope.promoCode = { code: promoCodeStore.promotionalCode.code }
        }
      }, true)
    }
  }
}
