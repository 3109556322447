/**
 * @prettier
 */

module.exports = function providerOrderTabs() {
  'ngInject';

  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/ordering_physicians/provider_order_tabs.html',
  };
};
