module.exports = function MarketingQuestions() {
  'ngInject'
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    templateUrl: '/marketing/_questions.html',
    link: function(scope) { }
  }
}
