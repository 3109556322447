module.exports = function placeCreditCard() {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      checkout: '=',
      order: '=',
      skippable: '='
    },
    templateUrl: '/ordering_physicians/place_credit_card.html',
    link: function(scope, element, attrs) {
      const item = scope.checkout.getItemizedKitPriceDollars()[0]
      scope.payment = scope.order.payment
      scope.$watch('checkout.isPaymentRequired()', function(val) {
        if (!scope.checkout.isPaymentRequired()) {
          scope.order.skip_payment = false
        }
      }, true)
    }
  }
}
