module.exports = function NewYorkModal($modal) {
  'ngInject'
  return {
    open: function(canChangeOrderingPhysician, modalType, back) {
      return $modal.open({
        templateUrl: '/kit_registrations/new_york_modal.html',
        size: 'sm',
        controller: function($scope, $modalInstance, stateFullNames, back, canChangeOrderingPhysician, modalType) {
          'ngInject'
          $scope.modalType = modalType
          $scope.canChangeOrderingPhysician = canChangeOrderingPhysician

          $scope.back = function() {
            $modalInstance.dismiss()
            if (back) {
              back()
            }
          };
        },
        resolve: {
          modalType: function() {
            return modalType
          },
          canChangeOrderingPhysician: function() {
            return canChangeOrderingPhysician
          },
          back: function() {
            return back
          }
        }
      })
    }
  }
}
