"use strict"

/**
 * Stops propagating event.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      elem.bind(attrs.stopPropagationOn, function(e) {
        e.stopPropagation()
      })
    }
  }
}
