'use strict'

module.exports = function($scope, $location, $http, auth, waffle) {
  'ngInject'

  $scope.auth = auth
  if (auth.currentUser.is_provider) {
    $scope.provider = auth.currentUser.provider_profile
  }

  $scope.retrieveOrganizationName = () => {
    $http.get('api/v1/get_routing_organization').then((response) => {
      $scope.organization_name = response.data
    })
  }
}
