/**
 * @prettier
 */

module.exports = function providerOrderTabs() {
  return {
    restrict: 'E',
    scope: {
      isPath: '=',
      goToPath: '=',
      paths: '=',
    },
    templateUrl: '/ordering_physicians/directives/provider_shared_order_tabs.html',
    controller: function() {
      'ngInject';
    },
    controllerAs: '$ctrl',
    bindToController: true,
  };
};
