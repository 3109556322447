/**
 * @prettier
 */

module.exports = function sharedOrderDetails() {
  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/shared_order_details.html',
    scope: {
      order: '=',
    },
    controller: function(testTypes) {
      'ngInject';

      this.testTypes = testTypes;
    },
    controllerAs: '$ctrl',
    bindToController: true,
  };
};
