/**
 * @prettier
 */

module.exports = function reportTemplate(
  $rootScope,
  $filter,
  resourcePaginator,
  indexedResource,
  Reference,
  ngToast,
  setReportContentTemplateModal,
  reportNewState,
  reportSignedOffState,
  classification
) {
  'ngInject';

  // TODO: V1 reports are no longer supported. Delete this.
  return {
    restrict: 'AE',
    scope: {
      report: '=',
    },
    templateUrl: '/results/report_template.html',
    link: function(scope) {
      const report = scope.report;
      const isNonReportable =
        !report.sample_sequence_run || report.sample_sequence_run.state == 'rejected';

      scope.showReportTemplateHeader = report.state != reportSignedOffState;
      // We automatically apply template for non-reportable cases.
      scope.showReportEditButton = report.state == reportNewState && !isNonReportable;

      let genes = [];
      for (const m of report.gene_mutations) {
        if (
          m.classification == classification.p ||
          m.classification == classification.lp ||
          m.classification == classification.ras
        ) {
          if (!genes.includes(m.gene)) {
            genes.push(m.gene);
          }
        }
      }
      genes = genes.sort().join(',');
      const params = {
        gene: genes,
        is_negative: genes ? 'False' : 'True',
        is_non_reportable: isNonReportable ? 'True' : 'False',
        gender: report.user.gender,
        test_type: report.kit_order.test_type,
      };

      if (isNonReportable) {
        delete params.gene;
        delete params.gender;
        delete params.is_negative;
      }

      // Applying V1 templates is no longer supported. All of this code will be delted in the future.
      scope.disableApplyTemplateButton = true;

      scope.templates = [];
      scope.selectedTemplate = null;

      scope.changeActiveReportTemplateSection = function(section) {
        scope.activeReportTemplateSection = section;
        if (section == 'select') {
          scope.showPDFTab = false;
        }
      };

      scope.changeActiveReportTemplateSection(report.template ? 'show' : 'select');

      scope.references = indexedResource(new Reference());
      scope.references.load();

      scope.changeTemplate = function(selectedTemplate) {
        if (selectedTemplate.id == 'new') {
          scope.changeActiveReportTemplateSection('create');
          const options = scope.templates.slice(0, scope.templates.length - 1);
          setReportContentTemplateModal
            .open(options)
            .result.then(function(templateToCopy) {
              // We are in the process of deleting references to the Template model.
              scope.newTemplate = null;
            })
            .catch(function() {
              scope.changeActiveReportTemplateSection('select');
              scope.selectedTemplate = scope.templates[0];
            });
        }
      };

      scope.onNewTemplateCreated = function(template) {
        // Insert a new item one before the last item.
        scope.templates.splice(scope.templates.length - 1, 0, template);
        scope.changeTemplate(template);
        scope.selectedTemplate = template;
        scope.changeActiveReportTemplateSection('select');
      };

      scope.onNewTemplateCancelled = function() {
        scope.changeActiveReportTemplateSection('select');
      };

      scope.applyTemplate = function(report, selectedTemplate) {
        scope.applying = true;
        report.template = selectedTemplate.id;
        report.apply_template = true;
        return report
          .$save()
          .then(function() {
            ngToast.create('Template applied.');
            scope.changeActiveReportTemplateSection('show');
            scope.showPDFTab = true;
            $rootScope.$broadcast('pdf:reload');
          })
          .finally(function() {
            scope.applying = false;
          });
      };
    },
  };
};
