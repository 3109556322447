/**
 * @prettier
 */

/**
 * This component matches the reference ID defined in the attribute directive
 * to the card that shows the full list of references.
 */
module.exports = function($document, $rootScope) {
  'ngInject';

  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      // Due to implementation optimizations, we're using a global event pattern
      // match up assorted `<reference/>` in the document to the bottom references card
      // Therefore here we listen for that global event before matching a number to
      // the respective `<ol>` list of references
      const renderReferenceWatch = $rootScope.$on('references:renderReference', function(
        event,
        referencesResolved
      ) {
        if (referencesResolved.count) {
          const references = referencesResolved.results;
          // Match up reference ID referenced here with ID matched in the resolved global event
          const index = references
            .map(function(ref) {
              return ref.id;
            })
            .indexOf(parseInt(attrs.reference));
          if (index > -1) {
            // Insert an anchor link with the ordered number to the lower references card
            elem
              .html(
                `<a href="#reference_${attrs.reference}" title="${
                  references[index].title
                }">${index + 1}</a>`
              )
              .bind('click', function(e) {
                e.preventDefault();
                $document.scrollToElementAnimated(angular.element('#reference_' + attrs.reference));
              });
          } else {
            console.info('Reference not loaded: #' + attrs.reference);
          }
        }
      });
      scope.$on('$destroy', renderReferenceWatch);
    },
  };
};
