const getAbsolutePath = require("monoweb/django_apps/core/static/js/absolute_path");

!(function() {
  "use strict";

  /**
   * @ngdoc module
   * @name clrNavigation
   *
   * @description
   * The `clrNavigation` provides interaction support with
   * Color's global navigation bar.
   */
  // TODO: Rename navigation to layout.
  const module = angular.module("clrNavigation", []);

  /**
   * @class Navigation
   *
   * @description
   * A class for configuring Color's global navigation bar.
   */
  function Navigation() {
    /**
     * @member {boolean} showNav - Shows the navigation if true.
     * @default true
     * @memberof Navigation#
     */
    this.showNav = true;
    /**
     * @member {boolean} showRight - Shows right part of the navigation if true.
     * @default true
     * @memberof Navigation#
     */
    this.showRight = true;
    /**
     * @member {boolean} showLeft - Shows left part of the navigation if true.
     * @default true
     * @memberof Navigation#
     */
    this.showLeft = true;
    /**
     * @member {boolean} affixed - affix logged out navigation if true.
     * @default false
     * @memberof Navigation#
     */
    this.affixed = false;
    /**
     * @member {boolean} showSignInAsButton - Shows signin button of the logged out navigation as button, instead of an anchor, if true.
     * @default false
     * @memberof Navigation#
     */
    this.showSignInAsButton = false;
    /**
     * @member {boolean} linkToProviderSignIn - Signin button links to providers signin instead
     * @default false
     * @memberof Navigation#
     */
    this.linkToProviderSignIn = false;
    /**
    /**
     * @member {boolean} showStore - Shows a link to store on the logged out navigation if true.
     * @default false
     * @memberof Navigation#
     */
    this.showStore = false;
    /**
     * @member {boolean} active - Sets active navigation link highlight
     * @default false
     * @memberof Navigation#
     */
    this.active = false;
    /**
     * @member {boolean} subnavActive - Sets subnav active navigation link highlight
     * @default false
     * @memberof Navigation#
     */
    this.subnavActive = false;
    /**
     * @member {boolean} subnavCompact - Show compacted sub nav with only Sign Out link
     * @default false
     * @memberof Navigation#
     */
    this.subnavCompact = false;
    /**
     * @member {boolean} showPhysicianOrderBreadcrumbs - Show physician ordering breadcrumbs
     * @default false
     * @memberof Navigation#
     */
    this.showPhysicianOrderBreadcrumbs = false;
    /**
     * @member {boolean} showPromoBanner - Show promo banner
     * @default false
     * @memberof Navigation#
     */
    this.showPromoBanner = false;
    /**
     * @member {boolean} showPromoBannerType - Show a specific promo banner
     * @default false
     * @memberof Navigation#
     */
    this.showPromoBannerType = false;
    /**
     * @member {boolean} showPromoBannerWhitelist - Whitelist of banner types we can show
     * @default false or array of banner types
     * @memberof Navigation#
     */
    this.showPromoBannerWhitelist = false;
    /**
     * @member {boolean} showSearchBar - Display search bar in navigation
     * @default false
     * @memberof Navigation#
     */
    this.showSearchBar = false;
    /**
     * @member {string} scrollButtonText - Text of any action button scrolling user to action
     * @default null
     * @memberof Navigation#
     */
    this.scrollButtonText = null;

    /**
     * @member {string} layout - Sets the layout style
     * @default 'standard'
     * @memberof Navigation#
     */
    this.layout = "standard";
    /**
     * @member {string} mobileFooter - Show mobile footer on this page
     * (valid values: false, 'standard', 'buy', 'providers')
     * @default false
     * @memberof Navigation#
     */
    this.mobileFooter = false;
    /**
     * @member {string} blue - Turn the navbar blue (for OC/PGx launch)
     * (valid values: false, true)
     * @default false
     * @memberof Navigation#
     */
    this.blue = false;
    /**
     * @member {string} showRedcrossCta - Custom Red Cross CTA implementation (https://getcolor.atlassian.net/browse/ACQ-564)
     * (valid values: false, true)
     * @default false
     * @memberof Navigation#
     */
    this.showRedcrossCta = false;

    this.logoLink = "/react/dashboard";
  }

  /**
   * @method reset
   * @memberof Navigation#
   *
   * @description
   * Resets navigation config with a given config.
   *
   * @param {Object} - Object with configuration key values.
   */
  Navigation.prototype.reset = function(config) {
    this.showNav = true;
    this.showRight = true;
    this.showLeft = true;
    this.affixed = false;
    this.affixedButtonText = null;
    this.affixedButtonLink = null;
    this.affixedButtonSku = null;
    this.showSignInAsButton = false;
    this.linkToProviderSignIn = false;
    this.showStore = true;
    this.active = false;
    this.subnavActive = false;
    this.subnavCompact = false;
    this.showPhysicianOrderBreadcrumbs = false;
    this.showPromoBanner = false;
    this.showPromoBannerType = false;
    this.showPromoBannerWhitelist = false;
    this.showSearchBar = false;
    this.scrollButtonText = null;
    this.layout = "standard";
    this.mobileFooter = false;
    this.blue = false;
    this.showRedcrossCta = false;
    this.logoLink = "/react/dashboard";
    config = config == void 0 ? {} : config;

    angular.extend(this, config);
  };

  /**
   * @ngdoc service
   * @name navigation
   * @memberof clrNavigation
   *
   * @description
   * A service that allows to configure visual of global navigation.
   *
   * @returns {Navigation} An instance of Navigation.
   *
   * @example
   * navigation.reset({ showLeft: false })
   */
  module.service("navigation", Navigation);

  module.directive("clrTopNavigation", function(
    $window,
    $timeout,
    $anchorScroll,
    $location,
    auth,
    navigation,
    redirect,
    setViewportScroll
  ) {
    "ngInject";
    return {
      restrict: "E",
      scope: {},
      priority: 500,
      templateUrl: "/clr_top_navigation.html",
      link: function(scope, elem, attrs) {
        scope.navigation = navigation;
        scope.auth = auth;
        scope.location = $location;
        scope.locationHost = escape(
          $window.location.protocol + "//" + $window.location.host
        );
        scope.getAbsolutePath = getAbsolutePath;

        scope.$watchCollection("auth.currentUser", function() {
          if (scope.auth.isLoggedIn) {
            scope.name = scope.auth.currentUser.first_name
              ? scope.auth.currentUser.first_name +
                " " +
                scope.auth.currentUser.last_name
              : scope.auth.currentUser.email;
          } else {
            scope.name = "";
          }
        });

        scope.showSubNav = false;
        scope.toggleSubNav = function(subNavName) {
          const isSubNavChanged = scope.subNavName != subNavName;
          scope.subNavName = subNavName;
          if (!scope.showSubNav || !isSubNavChanged) {
            scope.showSubNav = !scope.showSubNav;
          }
        };

        scope.showCollapsedNav = false;
        scope.toggleCollapsedNav = function() {
          if (scope.showCollapsedNav) {
            scope.showSubnav = false;
          }
          scope.showCollapsedNav = !scope.showCollapsedNav;
        };

        scope.collapseAllNav = function() {
          scope.showSubNav = false;
          scope.showCollapsedNav = false;
        };

        // Window click handler to close navigation unless you're clicking inside the top navigation elmeent or any of it's children
        let hasParentTopNavigationElement = false;
        angular.element($window).on("click", function(e) {
          hasParentTopNavigationElement = angular
            .element(e.target)
            .closest("#top_navigation").length;
          if (scope.showSubNav && !hasParentTopNavigationElement) {
            scope.collapseAllNav();
            scope.$apply();
          } else {
            e.stopPropagation();
          }
        });

        scope.logout = function() {
          $window.$(".body-wrapper, .footer").addClass("u--force-hide");
          auth
            .logout()
            .then(function() {
              $window.location.href = "/react/sign-in";
            })
            .catch(function() {
              $window.$(".body-wrapper, .footer").removeClass("u--force-hide");
            });
        };

        scope.openProviderPortal = function() {
          scope.collapseAllNav();
          redirect("/");
        };

        scope.changePath = function(path) {
          $location.path(path);
          scope.collapseAllNav();
        };

        // Mobile Navigation toggles/settings
        scope.mobileMenuOpen = false;
        scope.mobileSublinks = false;

        scope.mobileToggleMenu = function() {
          angular.element("#hamburger").toggleClass("js--active");
          if (angular.element("#hamburger").hasClass("js--active")) {
            scope.mobileMenuOpen = true;
            setViewportScroll.disable();
          } else {
            scope.mobileMenuOpen = false;
            setViewportScroll.enable();
            $timeout(function() {
              scope.mobileSublinks = false;
            }, 300);
          }
        };

        scope.mobileToggleSublinks = function(selected) {
          if (scope.mobileSublinks == selected) {
            scope.mobileSublinks = false;
          } else {
            scope.mobileSublinks = selected;
          }
        };

        scope.mobileCloseAll = function(keepMobileSublinks) {
          angular.element("#hamburger").removeClass("js--active");
          scope.mobileMenuOpen = false;
          setViewportScroll.enable();
          if (keepMobileSublinks) {
            $anchorScroll();
          } else {
            scope.mobileSublinks = false;
          }
        };
        // https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/
        document.addEventListener("touchstart", function() {}, true);
      }
    };
  });

  module.directive("clrProviderTopNavigation", function(
    $window,
    auth,
    navigation,
    redirect
  ) {
    "ngInject";
    return {
      restrict: "E",
      scope: {},
      templateUrl: "/ordering_physicians/provider_navigation.html",
      link: function(scope) {
        scope.navigation = navigation;
        scope.auth = auth;

        scope.$watchCollection("auth.currentUser", function() {
          if (scope.auth.isLoggedIn) {
            scope.name = scope.auth.currentUser.first_name
              ? scope.auth.currentUser.first_name +
                " " +
                scope.auth.currentUser.last_name
              : scope.auth.currentUser.email;
          } else {
            scope.name = "";
          }
        });

        scope.logout = function() {
          $window.$(".body-wrapper, .footer").addClass("u--force-hide");
          auth
            .logout()
            .then(function() {
              $window.location.href = "/react/sign-in";
            })
            .catch(function() {
              $window.$(".body-wrapper, .footer").removeClass("u--force-hide");
            });
        };

        scope.openHomeDashboard = function() {
          redirect("/react/dashboard");
        };

        scope.goToInstitution = function() {
          $window.location.assign("/providers/institution");
        };
      }
    };
  });
})();
