'use strict'

module.exports = function($scope, ngToast, auth, getPrevPath, EmailConfirmationResend, getEmailProvider) {
  'ngInject'

  function getUserPurpose() {
    const lastPath = getPrevPath()
    if (lastPath) {
      if (lastPath == '/health-history' || lastPath == '/select-sex' || lastPath == '/health-history/health-check') {
        return 'health-history'
      }
      else if (lastPath.indexOf('/results') == 0) {
        return 'results'
      }
      else if (lastPath == '/account-settings') {
        return 'account'
      }
      else if (lastPath == '/account/settings') {
        return 'account'
      }
      else if (lastPath == '/react/account/settings') {
        return 'account'
      }
      else if (lastPath == '/genetics/activate/barcode') {
        return 'activate'
      }
    }
    return 'other'
  }

  $scope.email = auth.currentUser.email
  $scope.emailProvider = getEmailProvider()
  $scope.purpose = getUserPurpose()
  $scope.emailConfirmationResend = new EmailConfirmationResend()
  $scope.resending = false

  $scope.resendConfirmationEmail = function(emailConfirmationResend) {
    if (!$scope.resending) {
      $scope.resending = true
      emailConfirmationResend.next = getPrevPath()
      emailConfirmationResend.$save()
        .then(function() {
          ngToast.create('We have resent the email.')
        })
        .catch(function(res) {
          ngToast.create({ className: 'error', content: 'Something went wrong.' })
        })
        .finally(function() {
          $scope.resending = false
        })
    }
  }
}
