"use strict"

/**
 * Stops input from submitting form on enter
 */
module.exports = function () {
  "ngInject"

  return {
    restrict: 'A',
    link: function (scope, elem) {
      elem.bind("keydown keyup", function (event) {
        if (event.which == 13) {
          event.preventDefault()
        }
      })
    }
  }
}
