'use strict'

module.exports = function() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      creditCard: '=',
      form: '=',
      initFocus: '='
    },
    templateUrl: '/kit_orders/directives/credit_card_form.html'
  }
}
