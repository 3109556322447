const resolvers = require('monoweb/django_apps/core/static/js/resolvers')
const getAbsolutePath = require('monoweb/django_apps/core/static/js/absolute_path');

module.exports = angular.module('home', [
  require('monoweb/django_apps/home/js/directives').name,
  require('monoweb/django_apps/home/js/controllers').name,
  require('monoweb/django_apps/home/js/filters').name,
  require('monoweb/django_apps/home/js/resources').name,
  require('monoweb/django_apps/home/js/animation').name,
]).config(function(colorRouteProvider) {

  'ngInject'

  const brcacodeRedirect = {
    redirectTo: getAbsolutePath(true,'/providers/place-order'),
    caseInsensitiveMatch: true,
    mobileFooter: 'standard'
  }

  // TODO: Migrate this into the frontend/app/marketing folder
  colorRouteProvider
  // IMPORTANT: /go is taken by TV promo
    // RGB-212
    .when('/go', {
      auth: { requireLogin: false },
      resolve: resolvers.applyAttributionRedirect('tv', '/t/start?utm_source=tv')
    })

    /*
     * "/react/path" will directly set location.href to '/path'
     *  This is used such that you can link directly to react pages through
     *  /react prefix /react by itself or direct to react '/' page
     */
    .when('/react', {
      resolve: {
        '_reactRedirect2_': function($window) {
          'ngInject'
          $window.location.replace('/')
        }
      },
      auth: {
        requireLogin: false,
        requireEmailConfirmation: false
      },
    })
    .when('/react/:reactPath*', {
      resolve: resolvers.reactRedirect,
      auth: {
        requireLogin: false,
        requireEmailConfirmation: false
      },
    })
})
