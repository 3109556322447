'use strict'

module.exports = ($location) => {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      routes: '=',
      routeToText: '=',
    },
    templateUrl: '/directives/progress_bar.html',
    link: (scope) => {
      scope.currentRoute = $location.path()
      // rounding up to a multiple of 5
      scope.percentComplete = Math.ceil((100 * ((scope.routes.indexOf(scope.currentRoute) + 1) / scope.routes.length)) / 5) * 5
    }
  }
}
