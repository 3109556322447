'use strict'

module.exports = function(
  $scope, $modal, $routeParams, $translate, $filter, $location, Purchase, $anchorScroll, auth) {
  'ngInject'

  $translate('ORDER_CONFIRMATION.ABOUT_A_WEEK').then(function(translation) {
    $scope.deliveryTime = translation
  })
  $scope.purchase = new Purchase({ purchase_number: $routeParams.purchase_number })
  $scope.noReferral = $location.search().no_referral
  $scope.purchase.$get()
    .then(function(res) {
      if (auth.isLoggedIn && !$scope.noReferral) {
        $modal.open({
          templateUrl: '/kit_orders/modals/share_modal.html',
          size: 'md',
          controller: 'ShareController'
        })
      }

      if (res.shipping_address.country != 'US') {
        $scope.deliveryTime = 'about ' + $filter('daysToDeliver')(res.shipping_address.country) + ' days'
      }
    })

  // Users may purchase for emails other than whomever if logged in.  If so, do not show email text
  if (!$location.search().no_email && auth.isLoggedIn) {
    $scope.user = auth.currentUser
    $scope.emailConfirmed = auth.currentUser.is_email_confirmed
  }

  $anchorScroll()

  $scope.subscribed = $location.search().subscribed
}
