module.exports = function($location, ngToast, KitCheckoutFlow) {
  'ngInject'

  return function(res, method) {
    // non-US Shipping address.
    if (res.error) {
      ngToast.create({className: 'error', content: res.error})
      const url = $location.path()
      if ($location.path().indexOf('/purchase/ordering-physician') == -1) {
        // Hack around pathflow routing. We are not able to skip ordering-physician page
        // if coming from another path. This "fakes" the visit using pathFlow's internal variable
        KitCheckoutFlow.completed['/purchase/ordering-physician'] = true
      }
      KitCheckoutFlow.goTo('/purchase/shipping', true)
    }
  }
}
