/**
 * @prettier
 */

module.exports = function reportSummaryExtra($filter, testTypes, namedMutation) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_summary_extra.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      // render_version changes for scope.colorTestName
      // H30 >= v1: "the Color hereditary cancer risk test"
      // BO19 v5: "the Color breast and ovarian cancer risk test"
      // BO19 v2-4: "Color\'s breast and ovarian cancer risk test"
      // BO19 v1: "the Color test"
      if (scope.reportContent.template.test_type == testTypes.hereditary30) {
        scope.colorTestName = 'the Color hereditary cancer risk test';
      } else if (
        scope.reportContent.template.test_type == testTypes.breastOvarian19 &&
        (scope.reportContent.render_version == 2 ||
          scope.reportContent.render_version == 3 ||
          scope.reportContent.render_version == 4)
      ) {
        scope.colorTestName = "Color's breast and ovarian cancer risk test";
      } else if (
        scope.reportContent.template.test_type == testTypes.breastOvarian19 &&
        scope.reportContent.render_version >= 5
      ) {
        scope.colorTestName = 'the Color breast and ovarian cancer risk test';
      } else {
        scope.colorTestName = 'the Color test';
      }

      function createGeneReport(report, gene, gender, allelicity) {
        const geneReport = {};
        geneReport.gene = gene;
        geneReport.mutationNoun = 'mutation';
        let risks;
        if (allelicity) {
          risks = $filter('sortedRisks')(report, gender, true, null, gene, allelicity);
        } else {
          if (report.allelicity_by_gene[gene] == 'monoallelic') {
            risks = $filter('sortedRisks')(report, gender, true, null, gene, '1');
          } else {
            risks = $filter('sortedRisks')(report, gender, true, null, gene);
          }
        }
        geneReport.biallelicRisksKnown = $filter('sortedRisks')(
          report,
          gender,
          true,
          null,
          gene,
          '2'
        ).pathogenic.length;

        const customPrimaryPhenotypes = $filter('customPhenotypes')(
          scope.reportContent,
          gene,
          'primary'
        );
        const customSecondaryPhenotypes = $filter('customPhenotypes')(
          scope.reportContent,
          gene,
          'secondary'
        );
        const secondaryPhenotypes = customSecondaryPhenotypes || 'secondary';

        if (angular.isUndefined(customPrimaryPhenotypes)) {
          geneReport.primaryCancers = $filter('cancerString')(
            $filter('sortRiskByPhenotype')(
              risks.pathogenic,
              'primary',
              [gene],
              scope.reportContent.template.test_type
            ),
            allelicity
          ); // Remove duplicate primary cancers here
        } else if (customPrimaryPhenotypes.length) {
          geneReport.primaryCancers = $filter('joinEnglishList')(customPrimaryPhenotypes);
        } else {
          geneReport.primaryCancers = null;
        }

        if (
          gene == 'APC' &&
          $filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
        ) {
          geneReport.mutationName = ' I1307K';
        }

        if (
          gene == 'MITF' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MITF',
            'MUTYH',
          ])
        ) {
          // Special use-case for MITF + MUTYH, we want to show different age than primary
          geneReport.commonRiskAge = 95;
        } else if (
          gene == 'MSH6' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MSH6',
          ])
        ) {
          geneReport.commonRiskAge = 70;
        } else if (
          gene == 'MSH2' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA1',
            'MSH2',
          ])
        ) {
          geneReport.commonRiskAge = 70;
        } else if (
          gene == 'TP53' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'BRCA2',
            'TP53',
          ])
        ) {
          geneReport.commonRiskAge = 95;
        } else if (
          gene == 'TP53' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'CHEK2',
            'TP53',
          ])
        ) {
          geneReport.commonRiskAge = 95;
        } else if (
          gene == 'MUTYH' &&
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'MUTYH',
            'MSH2',
          ]) &&
          scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic'
        ) {
          geneReport.commonRiskAge = 80;
        } else if (
          $filter('containsAll')(scope.reportContent.genes_with_pathogenic_mutations, [
            'APC',
            'CHEK2',
          ]) & !$filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
        ) {
          // APC (non-i1307K) + CHEK2 Has a different common risk age than APC i1307K + CHEK2, so each
          // implementation requires a bit more unconventional logic
          geneReport.commonRiskAge = 80;
        }

        geneReport.secondaryRisks = $filter('sortRiskByPhenotype')(
          risks.pathogenic,
          secondaryPhenotypes,
          [gene],
          scope.reportContent.template.test_type
        );

        // notDesignedForCancers is only used on H30 reports and >= RV5 BO19 reports, removes the hereditary cancers marketed as being tested
        let notDesignedForCancers = [];
        if (scope.reportContent.template.test_type == testTypes.hereditary30) {
          notDesignedForCancers = geneReport.secondaryRisks.filter(function(risk) {
            return (
              risk.phenotype != 'pancreatic cancer' &&
              risk.phenotype != 'ovarian cancer' &&
              risk.phenotype != 'uterine cancer' &&
              risk.phenotype != 'stomach cancer' &&
              risk.phenotype != 'breast cancer' &&
              risk.phenotype != 'colorectal cancer' &&
              risk.phenotype != 'melanoma' &&
              risk.phenotype != 'prostate cancer'
            );
          });
        } else if (
          scope.reportContent.template.test_type == testTypes.breastOvarian19 &&
          scope.reportContent.render_version >= 5
        ) {
          // >= RV5 BO19 reports need to correctly remove the 3 cancers covered there
          notDesignedForCancers = risks.pathogenic.filter(function(risk) {
            return (
              risk.phenotype != 'ovarian cancer' &&
              risk.phenotype != 'breast cancer' &&
              risk.phenotype != 'male breast cancer'
            );
          });
        }

        // Set allelicity
        geneReport.secondaryRisks.forEach(function(risk) {
          if (!geneReport.allelicity) {
            geneReport.allelicity = risk.allelicity;
          } else if (risk.allelicity && geneReport.allelicity != risk.allelicity) {
            throw new Error('All secondary risks need to have the same allelicity.');
          }
        });

        // Set defaults for these values first
        geneReport.secondaryCancers1 = $filter('cancerString')(
          geneReport.secondaryRisks,
          allelicity
        );
        geneReport.secondaryCancers1Append = '';
        // BO19 reports use "secondaryCancers2" in their 2nd paragraph string generation, and RV < 5 errantly doesn't include primary cancers that aren't breast or ovarian
        if (
          scope.reportContent.render_version >= 5 &&
          scope.reportContent.template.test_type == testTypes.breastOvarian19
        ) {
          geneReport.secondaryCancers2 = $filter('cancerString')(notDesignedForCancers);
        } else {
          geneReport.secondaryCancers2 = geneReport.secondaryCancers1;
        }

        // Override geneReport.secondaryCancers if necessary depending on the lower conditions
        if (gene == 'TP53') {
          if (
            scope.reportContent.template.test_type == testTypes.breastOvarian19 &&
            scope.reportContent.gender == 'F'
          ) {
            geneReport.primaryCancers =
              'breast, ovarian, brain, sarcoma (bone) and sarcoma (soft tissue) cancer';
            geneReport.secondaryCancers1 =
              'other Li-Fraumeni related cancers, including adrenocortical carcinoma, colorectal, leukemia, liver, lung, lymphoma, melanoma, pancreas and stomach';
          } else if (scope.reportContent.template.test_type == testTypes.hereditary30) {
            if (scope.reportContent.gender == 'F') {
              geneReport.primaryCancers =
                'breast, brain, and sarcoma (cancer of the bone and soft tissue)';
              geneReport.secondaryCancers1 =
                'other Li-Fraumeni related cancers, including adrenocortical carcinoma, colorectal, leukemia, liver, lung, lymphoma, melanoma, ovarian, pancreatic, and stomach';
            } else {
              geneReport.primaryCancers =
                'brain cancer and sarcoma (cancer of the bone and soft tissue)';
              geneReport.secondaryCancers1 =
                'other Li-Fraumeni related cancers, including adrenocortical carcinoma, colorectal, leukemia, liver, lung, lymphoma, melanoma, pancreatic, and stomach';
            }
            geneReport.panelNotDesignedForCancers =
              'adrenocortical carcinoma, leukemia, liver and lung cancer, and lymphoma';
            geneReport.panelNotDesignedForRiskNoun = 'risks';
            geneReport.panelNotDesignedForCancerNoun = 'these cancers';
          }
          geneReport.secondaryCancers2 = 'these cancers';
        } else if (gene == 'MSH6') {
          // The first wording is only applicable to BO19 MSH6 F reports
          if (
            gender == 'F' &&
            scope.reportContent.template.test_type == testTypes.breastOvarian19
          ) {
            geneReport.primaryCancers = 'ovarian, colorectal and uterine cancers';
            geneReport.secondaryCancers1 =
              'other cancers related to Lynch syndrome, including brain/central nervous system, hepatobiliary tract, pancreas, sebaceous neoplasms, small bowel, stomach and urinary tract';
          } else {
            geneReport.secondaryCancers1 =
              'other cancers related to Lynch syndrome, including brain, hepatobiliary tract, pancreatic, sebaceous neoplasms, small bowel, stomach, and urinary tract cancer';
          }
          geneReport.secondaryCancers2 = 'these cancers';
          geneReport.panelNotDesignedForCancers =
            'brain, hepatobiliary tract, sebaceous neoplasms, small bowel, and urinary tract cancer';
          geneReport.panelNotDesignedForRiskNoun = 'risks';
          geneReport.panelNotDesignedForCancerNoun = 'these cancers';
        } else if (gene == 'MSH2') {
          geneReport.secondaryCancers2 = 'these cancers';
          geneReport.panelNotDesignedForCancers =
            'brain, hepatobiliary tract, sebaceous neoplasms, small bowel, and urinary tract cancer';
        } else if (gene == 'APC') {
          if (
            !$filter('containsMutation')(scope.reportContent.mutations, namedMutation.apc_i1307k)
          ) {
            geneReport.primaryCancers = 'colorectal cancer';
            geneReport.secondaryCancers1 =
              'brain (especially medulloblastoma), pancreatic, small bowel (especially in the duodenum), stomach, thyroid cancer and non-cancerous growths called desmoid tumors';
            geneReport.panelNotDesignedForCancers =
              'brain, small bowel, and thyroid cancers and desmoid tumors';
            geneReport.panelNotDesignedForRiskNoun = 'risks';
            geneReport.panelNotDesignedForCancerNoun = 'these cancers or tumors';
          }
        } else if (gene == 'BAP1') {
          geneReport.secondaryCancers1 =
            'kidney and lung (specifically a type called mesothelioma) cancer';
          geneReport.panelNotDesignedForCancers = $filter('cancerString')(notDesignedForCancers);
        } else if (gene == 'BMPR1A') {
          geneReport.secondaryCancers1 =
            'pancreatic and small bowel (especially in the duodenum) cancer';
          geneReport.panelNotDesignedForCancers = 'small bowel cancer';
        } else if (gene == 'EPCAM') {
          geneReport.panelNotDesignedForCancers =
            'brain, hepatobiliary tract, sebaceous neoplasms, small bowel, and urinary tract cancer';
        } else if (gene == 'MITF') {
          geneReport.secondaryCancers1 = $filter('cancerString')(geneReport.secondaryRisks);
          geneReport.panelNotDesignedForCancers = $filter('cancerString')(notDesignedForCancers);
        } else if (gene == 'MLH1') {
          geneReport.secondaryCancers1 = $filter('cancerString')(geneReport.secondaryRisks);
          geneReport.panelNotDesignedForCancers = $filter('cancerString')(notDesignedForCancers);
        } else if (gene == 'PMS2') {
          geneReport.secondaryCancers1 = $filter('cancerString')(geneReport.secondaryRisks);
          geneReport.panelNotDesignedForCancers = $filter('cancerString')(notDesignedForCancers);
        } else if (gene == 'PTEN') {
          geneReport.geneAppend = ' that cause Cowden syndrome (CS)';
          geneReport.secondaryCancers2 = 'these cancers';
        } else if (gene == 'SMAD4') {
          geneReport.secondaryCancers1 =
            'pancreatic and small bowel (especially in the duodenum) cancer';
          geneReport.panelNotDesignedForCancers = 'small bowel cancer';
        } else if (gene == 'STK11') {
          geneReport.panelNotDesignedForCancers = $filter('cancerString')(notDesignedForCancers);
          // BO19 requires special handling due to STK specific logic that works around age 65 risks.
          if (
            scope.reportContent.template.test_type == testTypes.breastOvarian19 &&
            scope.reportContent.gender == 'F'
          ) {
            geneReport.secondaryCancers2 =
              'colorectal, stomach, cervical, lung, pancreatic, small bowel, and uterine cancer';
          }
        } else if (gene == 'MUTYH' && geneReport.allelicity == '2') {
          geneReport.secondaryCancers1 = 'small bowel cancer';
          geneReport.secondaryCancers1Append = ' (especially in the duodenum)';
        } else if (gene == 'BRCA2' && geneReport.allelicity == '2') {
          geneReport.panelNotDesignedForCancerNoun = 'these cancers';
        } else if (gene == 'BRCA1') {
          geneReport.withR1699Q = $filter('containsMutation')(
            scope.reportContent.mutations,
            namedMutation.brca1_r1699q
          );
        }

        // Set panelNotDesignedForCancerNoun if not assigned by special cases above.
        if (!geneReport.panelNotDesignedForCancerNoun) {
          if (geneReport.panelNotDesignedForCancers) {
            if (notDesignedForCancers.length > 1) {
              geneReport.panelNotDesignedForRiskNoun = 'risks';
              geneReport.panelNotDesignedForCancerNoun = 'these cancers';
            } else {
              geneReport.panelNotDesignedForRiskNoun = 'risk';
              geneReport.panelNotDesignedForCancerNoun = 'this cancer';
            }
          } else {
            geneReport.panelNotDesignedForRiskNoun = 'risk';
            geneReport.panelNotDesignedForCancerNoun = 'this cancer';
          }
        }

        if (gender == 'F') {
          geneReport.genderNoun = 'woman';
          geneReport.genderNounPlural = 'women';
        } else {
          geneReport.genderNoun = 'man';
          geneReport.genderNounPlural = 'men';
        }
        geneReport.uniqueRiskReferences = $filter('uniqueReferences')(geneReport.secondaryRisks);
        geneReport.uniqueBaselineReferences = $filter('uniqueReferences')(
          $filter('sortRiskByPhenotype')(
            risks.baseline,
            secondaryPhenotypes,
            [gene],
            scope.reportContent.template.test_type
          )
        );
        if (!geneReport.commonRiskAge) {
          geneReport.commonRiskAge = $filter('commonRiskAge')(
            scope.reportContent.genes_with_pathogenic_mutations,
            geneReport.secondaryRisks
          );
        }
        geneReport.isElevated = geneReport.secondaryRisks.filter(function(risk) {
          return $filter('isElevated')(risk.data[0].risk);
        });
        if (geneReport.secondaryRisks.length) {
          angular.forEach(geneReport.secondaryRisks, function(risk) {
            risk.baselineData = risks.baseline.filter(function(baseline) {
              return risk.phenotype == baseline.phenotype;
            })[0].data;
          });
        }

        // If report should use singleRiskLogic (i.e. compound het or homozygous), show extra content
        if ($filter('useSingleRiskLogic')(scope.reportContent, geneReport.gene)) {
          // For P1
          if (geneReport.allelicity == '1') {
            geneReport.singleP1Text = 'single ';
          }
          // For risk table column header
          if (geneReport.allelicity == '2') {
            geneReport.withGeneNumMutation = 'two ';
            geneReport.mutationNoun = 'mutations';
          }
          geneReport.showCompoundSentence = true;
          if ($filter('isSingleHomozygousReport')(scope.reportContent.mutations)) {
            geneReport.isSingleHomozygous = true;
          }
        }

        // Add STK11-specific age exceptions
        geneReport.secondaryRisks = $filter('insertDisplayAge')(
          geneReport.secondaryRisks,
          geneReport.commonRiskAge,
          scope.reportContent.render_version
        );
        geneReport.hasAgeExceptionsAt = $filter('hasAgeExceptionsAt')(geneReport.secondaryRisks);

        return geneReport;
      } // End of createGeneReport

      if (scope.reportContent.is_positive) {
        scope.geneReports = [];
        angular.forEach(scope.reportContent.genes_with_pathogenic_mutations, function(gene) {
          // Special handling for BRCA2 where there are both monoallelic and biallelic risk tables.
          if (
            gene == 'BRCA2' &&
            ($filter('isPotentiallyCompoundHeterozygousReport')(scope.reportContent) ||
              $filter('isSingleHomozygousReport')(scope.reportContent.mutations))
          ) {
            scope.geneReports.push(
              createGeneReport(scope.reportContent, gene, scope.reportContent.gender, '2')
            );
            scope.geneReports.push(
              createGeneReport(scope.reportContent, gene, scope.reportContent.gender, '1')
            );
          } else if (
            gene == 'MUTYH' &&
            scope.reportContent.allelicity_by_gene['MUTYH'] == 'biallelic'
          ) {
            // Special handling for MUTYH where there are only biallelic risk tables.
            scope.geneReports.push(
              createGeneReport(scope.reportContent, gene, scope.reportContent.gender, '2')
            );
          } else {
            const geneReport = createGeneReport(
              scope.reportContent,
              gene,
              scope.reportContent.gender
            );
            if (geneReport.secondaryRisks.length) {
              scope.geneReports.push(geneReport);
            }
          }
        });
      }

      let allSecondaryPhenotypeCount = 0;
      angular.forEach(scope.geneReports, function(geneReport) {
        allSecondaryPhenotypeCount += geneReport.secondaryRisks.length;
      });
      if (
        $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
          'TP53',
          'MSH2',
          'MSH6',
        ]) ||
        allSecondaryPhenotypeCount > 1
      ) {
        scope.allSecondaryCancersPronoun = 'these cancers';
      } else {
        scope.allSecondaryCancersPronoun = 'this cancer';
      }

      // H30 Paragraph explaining what this panel doesn't fully cover
      scope.showNotDesignedForText =
        scope.reportContent.template.test_type == testTypes.hereditary30 &&
        $filter('containsAny')(scope.reportContent.genes_with_pathogenic_mutations, [
          'APC',
          'BAP1',
          'MITF',
          'MLH1',
          'MSH2',
          'MSH6',
          'TP53',
          'PMS2',
          'BMPR1A',
          'EPCAM',
          'SMAD4',
          'STK11',
        ]);

      scope.testTypes = testTypes;

      // Whether or not to show the card
      scope.showSummaryExtraCard =
        scope.reportContent.is_positive &&
        !scope.reportContent.is_somatic &&
        allSecondaryPhenotypeCount;
    },
  };
};
