!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrLocation
   *
   * @description
   * The `clrLocation` provides utilities for handling URL operations.
   */
  const module = angular.module('clrLocation', [])

  /**
   * @ngdoc service
   * @name locationSkipReload
   * @memberof clrLocation
   *
   * @description
   * `locationSkipReload` will change the url without reloading $route, will resolve the
   * route/templates set in app.js, won't re-initialize $scope or the controller.
   *
   * @param {string} path - the intended path you want to change the url to.
   *
   * @example
   * locationSkipReload('/')
   */
  module.factory('locationSkipReload', function($rootScope, $location, $route) {
    // Add bool param to $location.path to prevent page reloading when changing routes
    // http://joelsaupe.com/programming/angularjs-change-path-without-reloading/
    const originalPath = $location.path
    return function (path) {
      const lastRoute = $route.current
      const un = $rootScope.$on('$locationChangeSuccess', function () {
        $route.current = lastRoute
        // Broadcast new event to pageTrack new route with GA, listener in app.js
        $rootScope.$broadcast('$locationSkipReloadSuccess', lastRoute, path)
        un()
      })
      return originalPath.apply($location, [path])
    }
  })

  /**
   * @ngdoc service
   * @name redirect
   * @memberof clrLocation
   *
   * @description
   * `redirect` allows to redirect user to a path specified in `next` query
   * param if present, otherwise it redirect user to a given path.
   *
   * @param {string} defaultPath - path used if `next` is not present in query param.
   *
   * @example
   * redirect('/')
   */
  module.factory('redirect', function($window, $location) {
    return function(defaultPath) {
      let next = $location.search().next
      if (next != void 0) {
        // Decode url
        next = decodeURIComponent(next)
        // Use this technique to derive the ?next URL because the route is relative,
        // this technique makes it absolute
        const parser = document.createElement('a')
        parser.href = next
        // IE11 doesn't set other anchor tag properties like .host or .hostname unless
        // you set it to itself (see https://grack.com/blog/2009/11/17/absolutizing-url-in-javascript/)
        // and use .hostname, not .host otherwise IE inserts the port (e.g. ':443') when
        // calling parser.host
        if (parser.hostname == '') {
          parser.href = parser.href
        }
        // Redirects to defaultPath if next url's host name
        // does not match with current URL to prevent unvalidated
        // redirects and forwards that can be used for phishing attacks.
        if ($window.location.hostname == parser.hostname) {
          // Reload the route and don't let angular pushState it, solves email
          // confirmation loop issues in html5mode since the /confirm_email
          // route is Django-based, not angular
          console.log(`Angular redirecting to '${next}'.`);
          $window.location.href = next
        } else {
          console.log(`Angular redirecting to '${defaultPath}', wiping next query param.`);
          $location.search('next', null)
          $location.path(defaultPath)
        }
      } else {
        console.log(`Angular redirecting to '${defaultPath}'.`);
        $location.path(defaultPath)
      }
    }
  })

  /**
   * @class PathFlow
   *
   * @description
   * A class that allows to restrict an order of paths that user can visit.
   *
   * @param {Array} flow - ordered object with properties like controller and path.
   * @param {Object} $window - A window service.
   * @param {Object} $location - A location service.
   */
  function PathFlow(name, flow, options, $rootScope, $window, $location) {
    this._flow = flow
    this._pathInFlow = flow.map(function(config) {
      return config.path
    })
    this.startEventName = name + ':start'
    this.resetEventName = name + ':reset'

    /**
     * @member {boolean} skipToEnd - when all flows are completed, skips to the end.
     * @default true
     * @memberof PathFlow#
     */
    this.skipToEnd = angular.isDefined(options.skipToEnd) ? options.skipToEnd : true

    /**
     * @member {boolean} skipReset - skips reset once if this is set to true.
     * @default false
     * @memberof PathFlow#
     */
    this.skipReset = false
    /**
     * @member {Object} completed - completion stat of each step in the flow.
     * Key is step's path and value is boolean.
     * @memberof PathFlow#
     */
    this.completed = {}
    /**
     * @member {string} current - current path in the flow.
     * @memberof PathFlow#
     */
    this.current = null
    /**
     * @member {Object} state - an object for storing arbitrary information specific to the flow.
     * @memberof PathFlow#
     */
    this.state = {}

    /**
     * @method reset
     * @memberof PathFlow#
     *
     * @description
     * Resets flow to the initial state.
     */
    this.reset = function() {
      if (this.skipReset) {
        this.skipReset = false
        return
      }
      this.completed = {}
      this.current = null
      this.state = {}
      $rootScope.$broadcast(this.resetEventName)
    }

    /**
     * @method route
     * @memberof PathFlow#
     *
     * @description
     * Routes user.
     *
     * @param {string} path - name of the next path.
     */
    this.route = function(path) {
      // do not route if path is irrelevant to this flow.
      // also reset flow.
      if (this._pathInFlow.indexOf(path) == -1) {
        this.reset()
        return
      }

      // If this is start of flow, it initialize current path and
      // broadcast start event.
      if (this._pathInFlow.indexOf(path) == 0 && !this.current) {
        this.current = this._flow[0].path
        $rootScope.$broadcast(this.startEventName)
      }

      if (this.skipToEnd) {
        const lastFlow = this._flow[this._flow.length - 1]

        if (this.completed[lastFlow.path]) {
          if (path != lastFlow.path) {
            $location.path(lastFlow.path).replace()
          }
          return
        }
      }

      for (let i = 0; i < this._flow.length - 1; i++) {
        const curFlow = this._flow[i]

        if (path == curFlow.path) {
          return
        }

        if (!this.completed[curFlow.path] && !curFlow.optional) {
          $location.path(curFlow.path).replace()
          return
        }
      }
    }

    /**
     * @method goTo
     * @memberof PathFlow#
     *
     * @description
     * Changes path and mark a given path as completed.
     *
     * @param {string} path   - A next path.
     * @param {string} done   - A path to mark as completed.
     * @param {object} params - Name:value pairs to append as query params, i.e. ?name=value
     */
    this.goTo = function(path, done, params) {
      if (done) {
        this.completed[this.current] = true
      }
      this.current = path
      if (params) {
        $location.path(path).search(params)
      } else {
        $location.path(path)
      }
      $window.scrollTo(0, 0)
    }
  }

  /**
   * @ngdoc service
   * @name pathFlow
   * @memberof clrLocation
   *
   * @description
   * A factory which creates a path flow object that lets restrict path access order.
   *
   * @param {Array} flow - A list of objects with properties: controller and path.
   * User can only visit these path in a given order.
   * @returns {PathFlow} An instance of `PathFlow`.
   *
   * @example
   * var flow = pathFlow([
   *    { controller: 'QuantityController', path: '/purchase/quantity' }
   *  , { controller: 'ShippingController', path: '/purchase/shipping' }
   *  , { controller: 'BillingController', path: '/purchase/billing' }
   *  , { controller: 'ReviewController', path: '/purchase/review' }
   * ])
   *
   * // To change path and mark current path as completed:
   * flow.goTo('/purchase/shipping', '/purchase/quantity')
   *
   * // when routeChangeStart event is triggered:
   * flow.route(next.$$route.originalPath)
   */
  module.factory('pathFlow', function($rootScope, $window, $location) {
    return function(name, flow, options) {
      return new PathFlow(name, flow, options || {}, $rootScope, $window, $location)
    }
  })

  module.factory('getPrevPath', function(routeHistory) {
    return function() {
      if (routeHistory.length > 1) {
        return routeHistory[routeHistory.length - 2]
      }
      return null
    }
  })
}();
