module.exports = angular.module('userServices', [])
  .factory('createAccount', require('./services/create_account'))
  .factory('getEmailProvider', require('./services/get_email_provider'))
  .factory('hasCompletedRoutingAuthorization', require('./services/has_completed_routing_authorization.js'))
  .service('InsightsFlow', require('./insights/insights_flow.js'))
  .run(function($rootScope, InsightsFlow) {
    $rootScope.$on(InsightsFlow.startEventName, function() {
      InsightsFlow.init(true)
    })
  })
