/**
  * Scope Parameters
  *  field: object containing field data to construct input
  *    e.g. {
  *      label: 'Phone number',
  *      name: 'Phone number',
  *      type: 'text',
  *      required: true
  *    }
  *  ngModel: object on which form values are set
  *  selectOnChange: on-change callback for select inputs
  **/

/**
  *
  * Input Types:
  * text: single-line input[type=text]
  * textarea: multi-line textarea
  * select: a select element, expects an "options" block
  * checkbox: displays a single clr-checkbox with a label appended to the right
  * helpText: displays help text, is not a form element
  *
  **/

module.exports = () => {
  "ngInject"

  return {
    restrict: 'E',
    replace: true,
    scope: {
      field: '=',
      ngModel: '=',
      selectOnChange: '&?',
    },
    templateUrl: '/directives/input_field.html',
    link: (scope) => {
      // Set option.selected to populate select drop down
      if (scope.field.type == 'select'){
        scope.field.options.forEach(option => {
          option.selected = option.value == scope.ngModel[scope.field.name]
        })
      }
    }
  }
}
