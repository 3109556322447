'use strict'

module.exports = function($location, pathFlow) {
  'ngInject'

  const PATHS = {
    introduction: '/insights/introduction',
    account: '/insights/account',
  }
  const flow = pathFlow('insightsFlow', [
    {controller: 'InsightsIntroductionController', path: PATHS.introduction},
    {controller: 'InsightsAccountController', path: PATHS.account},
  ])

  flow.ROUTE_TO_TEXT = {}
  flow.ROUTE_TO_TEXT[PATHS.introduction] = 'Introduction'
  flow.ROUTE_TO_TEXT[PATHS.account] = 'Account'

  flow.getRoutes = () => {
    return [
      PATHS.introduction,
      PATHS.account,
    ]
  }

  const getNextRoute = (currentRoute) => {
    const routes = flow.getRoutes()
    const currentIndex = routes.indexOf(currentRoute)
    const nextRoute = routes[currentIndex + 1]
    if (nextRoute) {
      return nextRoute
    }
    throw 'No next route'
  }

  flow.goToNext = () => {
    const currentRoute = $location.path()
    const nextRoute = getNextRoute(currentRoute)
    flow.goTo(nextRoute, true)
  }

  return flow
}
