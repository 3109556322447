"use strict"

/**
 * Fetches and displays the kit price via an element directive.
 *
 * Usage example:
 * <kit-price-text sku='hereditary 30'></kit-price-text>
 */
module.exports = function($q, kitPriceService, sku, promoCodeStore, claimStore) {
  "ngInject"

  return {
    restrict: 'E',
    templateUrl: '/directives/kit_price_text.html',
    scope: {
      sku: '@',
      allClass: '@?',
      strikeClass: '@?',
      hideStrike: '=?',
      basePriceOnly: '=?'
    },
    link: function(scope, elem) {
      const skuObj = sku[scope.sku];

      const updateScope = () => {
        scope.basePrice = kitPriceService.getAdvertisedKitPriceDollars(skuObj);
        scope.activePrice = kitPriceService.getDisplayedKitPrice(skuObj);
        scope.showStrike = scope.basePrice != scope.activePrice && !scope.hideStrike;
      };

      $q.all([promoCodeStore.init(), claimStore.getClaim()]).finally(updateScope()).catch((e) => { throw e } );

    }
  };
}
