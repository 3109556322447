'use strict'

module.exports = function physicianOrderDetails(
  auth,
  project,
  reattemptInsuranceModal,
  ApplicationType
) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      editable: '=?',
      onSaveOrder: '=?',
      order: '=',
      ordering: '=?'
    },
    templateUrl: '/ordering_physicians/directives/physician_order_details.html',
    link: function(scope) {
      scope.isClinical = project.application === ApplicationType.CLINICAL

      // Whether or not this set of details is editable.
      scope.editable = scope.editable || false

      // Whether or not this set of details is part of an order flow.
      scope.ordering = scope.ordering || false

      const watcher = scope.$watch('order', function() {
        if (scope.order) {
          scope.canViewInsuranceAttemptDetails = (
            !scope.ordering &&
            scope.order.attempted_insurance_submission
          )

          scope.canReattemptInsurance = (
            scope.isClinical &&
            auth.currentUser.is_support_staff &&
            scope.canViewInsuranceAttemptDetails &&
            !scope.order.insurance_claim
          )

          watcher()
        }
      })

      scope.openReattemptInsuranceModal = function() {
        reattemptInsuranceModal.open(scope.order)
      }
    }
  }
}
