'use strict'

module.exports = function($filter) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/_questions.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.questions = []
      scope.title = 'FH_REPORT.QUESTIONS.CORONARY_HEART.TITLE'
      const genes = scope.reportContent.genes_with_pathogenic_mutations
      if (genes.length) {
        scope.questions.push({
          t: 'FH_REPORT.QUESTIONS.CORONARY_HEART.T1',
          p: 'FH_REPORT.QUESTIONS.CORONARY_HEART.P1',
          withGC: true
        })
      }
      scope.questions.push({
        t: 'FH_REPORT.QUESTIONS.CORONARY_HEART.T2',
        p: 'FH_REPORT.QUESTIONS.CORONARY_HEART.P2'
      })
    }
  }
}
