function PhysicianOrderCancellation($scope, $rootScope, $http, $modalInstance, ngToast, requisitionNumber, firstName, lastName, PhysicianOrderCancellationReasons) {
  'ngInject'
  $scope.firstName = firstName
  $scope.lastName = lastName
  $scope.PhysicianOrderCancellationReasons = PhysicianOrderCancellationReasons
  $scope.values = {}

  $scope.askReason = () => {
    $scope.askingReason = true
  }

  $scope.cancelTest = () => {
    $scope.cancelling = true
    $http.put(`/api/v1/detailed_physician_orders/${requisitionNumber}/cancel`, {
      reason: $scope.values.reason
    })
      .then(() => {
        $modalInstance.close()
      })
      .catch(response => {
        ngToast.create({ content: response.data.non_field_errors, className: 'error' })
        $modalInstance.dismiss()
      })
  }
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (requisitionNumber, firstName, lastName) => {
      return $modal.open({
        templateUrl: 'kit_orders/modals/physician_order_cancellation.html',
        controller: PhysicianOrderCancellation,
        size: 'md',
        resolve: {
          firstName: () => firstName,
          lastName: () => lastName,
          requisitionNumber: () => requisitionNumber
        }
      })
    }
  }
}
