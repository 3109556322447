/**
 * @prettier
 */

/**
 * All static logged-out pages are served by Marketing's CMS.
 * This function generates a URL for those hosts.
 * FIXME: the function name is confusing. As we move to React, we can kill this function off.
 * See more about the subdomain split here:
 * https://docs.google.com/document/d/1ugLUCE33LZMNhmMKYrTBRjOxnsVG0ngpvWEUu_2TFkQ/edit#
 * @param {boolean} hostIsHomeApp
 * @param {string} path  path should have a backslash in front of it.

 */
function getAbsolutePath(hostIsHomeApp, path) {
  const hostname = window.location.hostname;
  // TODO (evelyn) When we move to the CMS officially, will change all logged_out to point to www.color.com
  const in_prod = hostname.indexOf('www.color') > -1 || hostname.indexOf('home.color') > -1;
  const in_sync_staging = hostname.indexOf('sync.') > -1 && hostname.indexOf('.staging') > -1;
  const in_feature_staging = hostname.indexOf('www-') > -1 && hostname.indexOf('.staging') > -1;
  const in_staging = hostname.indexOf('staging') > -1 && !in_feature_staging && !in_sync_staging;
  const is_eks_specific_path = hostname.indexOf('use1-a.eks.') > -1;

  if (in_prod) {
    if (hostIsHomeApp) {
      if (is_eks_specific_path) {
        return 'https://home.use1-a.eks.color.com' + path;
      }
      return 'https://home.color.com' + path;
    }
    return 'https://www.color.com' + path;
  }

  if (in_staging) {
    if (hostIsHomeApp) {
      if (is_eks_specific_path) {
        return 'https://home.use1-a.eks.staging.color.com' + path;
      }
      return 'https://home.staging.color.com' + path;
    }
    return 'https://test-color-genomics.pantheonsite.io' + path;
  }
  // locally and feature staging
  if (hostIsHomeApp) {
    return path;
  }
  return 'https://test-color-genomics.pantheonsite.io' + path;
}

module.exports = getAbsolutePath;
