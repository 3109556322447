module.exports = function physicianOrderSearchInput($location, cppHomeUrlPath) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/search_bar.html',
    scope: {
    },
    link: function(scope, elem) {

      scope.expanded = false
      const input = elem.find('input')

      scope.onKeyDown = function($event) {
        // on hitting enter
        if ($event.keyCode == 13) {
          if (scope.searchQuery) {
            submitSearch(scope.searchQuery)
          } else {
            scope.dismiss()
          }
          $event.preventDefault()
        }
      }

      setPlaceholder()
      scope.$on('$locationChangeStart', setPlaceholder)

      function setPlaceholder() {
        if (cppHomeUrlPath.getRouteName() == 'drafts'){
          scope.placeholder = 'ORDERING_PHYSICIANS.SEARCH.DRAFTS'
        } else {
          scope.placeholder = 'ORDERING_PHYSICIANS.SEARCH.ALL_ORDERS'
        }
      }

      function submitSearch(searchQuery) {
        // Base 64 encode the query param to obfuscate any PHI in URL
        const encodedQuery = encodeURIComponent(btoa(searchQuery))
        if (cppHomeUrlPath.getRouteName() == 'all' || cppHomeUrlPath.getRouteName() == 'drafts') {
          $location.search({'search': encodedQuery})
        } else {
          $location.path('/providers/home').search({search: encodedQuery})
        }
      }

      scope.dismiss = function() {
        $location.search({})
      }

      if (cppHomeUrlPath.getSearchQuery()) {
        scope.searchQuery = cppHomeUrlPath.getSearchQuery()
        scope.expanded = true
      }
    }
  }
}
