/**
 * @ngdoc filter
 * @name hasAgeExceptionsAt
 *
 * @description
 * Returns the age that this set of risks has age exceptions at, assumes only a single age exists
 * Only use case is STK11 @ age 65
 */

'use strict'

module.exports = function($filter) {
  'ngInject'

  return function(risks) {
    if (!angular.isArray(risks)) {
      risks = [risks]
    }
    const ageExceptions = $filter('filter')(risks, { isAgeException: true })
    if (ageExceptions.length) {
      return ageExceptions[0].displayAge
    } else {
      return false
    }
  }
}
