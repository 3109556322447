const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="setReleasePreferences")
    modal-title Provider Release Options
    modal-body
      .form-group
        select.form-control(ng-model="releasePreferences.release_delay_in_days", ng-options="option.value as option.label for option in releaseDelayOptions")
          option(value="", disabled="") Release results to client...
      .form-group
        .checkbox-icon.checkbox-icon--square
          input#gcBlock(type="checkbox", ng-model="releasePreferences.require_counseling_if_positive")
          label(for="gcBlock") Require Color genetic counseling for positive results
    submit-button Set Release Options
`

function controller($scope, $modalInstance, $http, releasePreferences) {
  $scope.modalInstance = $modalInstance
  $scope.releasePreferences = angular.copy(releasePreferences)

  $scope.releaseDelayOptions = [
    {value: 0, label: 'Immediately'},
    {value: 5, label: 'After five days'},
    {value: 20, label: 'After twenty days'}
  ]

  $scope.setReleasePreferences = () => {
    return $http.put('/api/v1/counseling/release_preferences/' + releasePreferences.id, $scope.releasePreferences)
      .then((res) => {
      // Update the parent scope
        angular.copy(res.data, releasePreferences)
      })
  }
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (releasePreferences) => {
      return $modal.open({
        template: template,
        controller: controller,
        size: 'md',
        resolve: {
          releasePreferences: () => releasePreferences
        }
      })
    }
  }
}
