module.exports = angular.module('homeAnimation', ['ngAnimate'])
  .animation('.clr-slide-in-out', function() {
    return {
      enter: function(el, doneFn) {
        angular.element(el).slideDown(300, doneFn);
      },

      leave: function(el, doneFn) {
        angular.element(el).slideUp(300, doneFn);
      }
    }
  })
