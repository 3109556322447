'use strict'

module.exports = function($interpolate, $filter, geographicalArea, topCountries, allCountries, entireCountryList, NotYetAvailableModal) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      country: '=',
      countries: '=?',
      topCountries: '=?',
      checkAvailable: '=?',
      countryFieldName: '@?',
      withIcons: '=?',
      withLabel: '=?',
      flexStyle: '=?',
      withEntireCountryList: '=?'
    },
    templateUrl: '/users/country_select.html',
    link: function(scope, element) {
      // We use element.attr() rather than attrs.countryDisplay here because
      // we want the unrendered template. Angular will attempt to render the
      // country display template otherwise.
      let displayTemplate = element.attr('country-display')
      if (!displayTemplate) {
        displayTemplate = "{{ country | name }}"
      }
      const countryRenderer = $interpolate(displayTemplate)
      scope.display = function(country) {
        return countryRenderer({country: country})
      }

      if (angular.isUndefined(scope.countryFieldName)) {
        scope.countryFieldName = "country" + Math.floor(Math.random() * 10000)
      }

      if (angular.isUndefined(scope.countries)) {
        scope.countries = allCountries
        if (scope.withEntireCountryList) {
          scope.countries = entireCountryList
        }
      }

      if (angular.isUndefined(scope.topCountries)) {
        scope.topCountries = topCountries
      }

      if (angular.isUndefined(scope.withIcons)) {
        scope.withIcons = false
      }

      if (angular.isUndefined(scope.withLabel)) {
        scope.withLabel = true
      }

      if (angular.isUndefined(scope.checkAvailable)) {
        scope.checkAvailable = true
      }

      // Toggle for new flex form styling, make this default once we switch over all form design
      if (angular.isUndefined(scope.flexStyle)) {
        scope.flexStyle = false
      }

      scope.$watch('country', function(newValue, oldValue) {
        if (scope.checkAvailable) {
          if (newValue && !$filter('shippable')(newValue)) {
            NotYetAvailableModal.open($filter('name')(newValue))
            scope.country = oldValue
          }
        }
      })
    }
  }
}
