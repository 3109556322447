"use strict"

// Event related directives
/////////////////////////////

/**
 * Focus on the element when it is compiled.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      const initFocusExpr = attrs.initFocus
      if (!initFocusExpr || scope.$eval(initFocusExpr)) {
        scope.$applyAsync(function () {
          elem.focus()
        })
      }
    }
  }
}
