/**
 * @prettier
 */
module.exports = function ProviderPlatformSharedController($scope, $location, SharedOrder) {
  'ngInject';

  const setUp = () => {
    this.loading = true;
    SharedOrder.get()
      .$promise.then(response => {
        this.orders = response.results;
        this.orderCount = response.count;
      })
      .finally(() => (this.loading = false));
  };

  this.openOrder = order => {
    if (order.status.name == 'results_available') {
      $location.path(`/providers/shared_order/results/${order.order_number}`);
    } else {
      $location.path(`/providers/shared_order/details/${order.order_number}`);
    }
  };

  setUp();
};
