!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrRequest
   *
   * @description
   * The `clrRequest` provides HTTP request/resopnse related services.
   */
  const module = angular.module('clrRequest', ['ngToast'])

  /**
   * @ngdoc config
   *
   * @description
   * $http interceptor.
   * On 400 response, it pops toast for first error message found in returned response
   * if `ignoreRequestErrorHandling` http config is not present.
   * On 500 response, it redirects 500 page.
   */
    .config(function($httpProvider) {
      $httpProvider.interceptors.push(function($rootScope, $q, ngToast, requestErrorToast) {
        const httpErrorCodeToToast = [400, 403, 405]
        const unreportedErrorCodes = [401]
        return {
          responseError: function(rejection) {
            if ((httpErrorCodeToToast.indexOf(rejection.status) !== -1 && !rejection.config.skipErrorToast || rejection.status === 401 && rejection.config.ignoreAuthModule) &&
              !rejection.config.ignoreRequestErrorHandling) {
              requestErrorToast(rejection)
            } else if (rejection.status === 500) {
              if (rejection.config.internalServerErrorToast) {
                ngToast.create({ className: 'error', content: 'Internal server error.' })
              } else {
                $rootScope.$broadcast('error:InternalServerError')
              }
            }
            return $q.reject(rejection)
          }
        }
      })
    })

  /**
   * @ngdoc service
   * @name requestErrorToast
   * @memberof clrRequest
   *
   * @description
   * `requestErrorToast` is a service that pops a toast for the first message
   * found in a given http response object.
   *
   * @param {Object} res - response returned from server.
   *
   * @example
   * requestErrorToast(rejection)
   */
    .factory('requestErrorToast', function(ngToast) {

      function hasFieldError(field, errors) {
        return errors.hasOwnProperty(field) && errors[field].length > 0
      }

      function getErrorMsgFromObject(errors) {
      // 'var ... in ...' iterates both over arrays (of error strings)
      // and objects (where values are arrays of error strings)
        for (const field in errors) {
          if (hasFieldError(field, errors)) {
            if (angular.isArray(errors[field])) {
              return errors[field][0]
            }
            return errors[field]
          }
          if (typeof errors[field] == 'object' && angular.isUndefined(errors[field].length)) {
            return getErrorMsgFromObject(errors[field])
          }
        }
      }

      return function(res) {
      // Ignore empty or generic errors.
        if (!res.data || res.data['detail']) {
          return
        }

        const errors = res.data;
        let errorMsg

        if (hasFieldError('__all__', errors)) {
          errorMsg = errors['__all__'][0]
        } else if (hasFieldError('non_field_errors', errors)) {
          errorMsg = errors['non_field_errors']
          if (angular.isArray(errorMsg)) {
            errorMsg = errorMsg[0]
          }
        } else {
          errorMsg = getErrorMsgFromObject(errors)
        }

        // Encodes the error message as HTML
        const errorHtml = $('<div/>').text(errorMsg).html()
        ngToast.create({ className: 'error', content: errorHtml })
      }
    })

  /**
   * @ngdoc service
   * @name resolvedPromise
   * @memberof clrRequest
   *
   * @description
   * `resolvedPromise` is a service that returns a resolved promise.
   *
   * @param {any} res - resolved response.
   *
   * @example
   * resolvedPromise(response)
   */
    .factory('resolvedPromise', function($q) {
      return function(res, rejected) {
        const deferred = $q.defer()
        if (rejected) {
          deferred.reject(res)
        } else {
          deferred.resolve(res)
        }
        return deferred.promise
      }
    })

}();
