!function() {

  'use strict'

  const module = angular.module('clrWaffle', [])

  module.factory('waffle', ['$window', function($window) {
    if ('waffle' in $window) {
      return $window.waffle
    } else {
      return {}
    }
  }])
}()
