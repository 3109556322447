module.exports = function() {
  "ngInject"

  function titleCase(s) {
    return s.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
  }

  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      const attrKeys = Object.keys(attrs)

      elem.on('click', function(e) {
        const trackData = {}
        for (let i = 0; i < attrKeys.length; i++) {
          const attrKey = attrKeys[i];
          if (attrKey.match(/trackValue/)) {
            trackData[titleCase(attrKey.substr(10))] = attrs[attrKey]
          }
        }
      })
    }
  }
}
