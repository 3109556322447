!function() {

  'use strict'

  const stripe = angular.module('clrStripe', [])

  stripe.factory('Stripe', function($window) {
    if ($window.Stripe) {
      Stripe.setPublishableKey(angular.element("#stripe-public-key").val());
      return Stripe
    } else {
      console.warn('Stripe could not be found.')
      return {}
    }
  })

  stripe.factory('CreditCard', function($q, Stripe) {
    const CreditCard = function() {}

    CreditCard.prototype.$save = function() {
      const creditCardPromise = $q.defer()

      this.exp_month = this.expiry.split('/')[0]
      this.exp_year = this.expiry.split('/')[1]

      Stripe.createToken(this, function(status, res) {
        if (res.error) {
          creditCardPromise.reject(res)
        } else {
          creditCardPromise.resolve(res)
        }
      })

      return creditCardPromise.promise
    }

    return CreditCard
  })

}()
