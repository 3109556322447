/**
   * @ngdoc module
   * @name userFilter
   *
   * @description
   * The `userFilter` provides filters for users.
   */
module.exports = angular.module('userFilter', [])
  .filter('genderOrSexText', require('./filters/gender_or_sex_text'))
  .filter('poBoxPresent', require('./filters/po_box_present'))
