'use strict'

module.exports = function(stateAcronyms) {
  "ngInject"

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elem, attr, ctrl) {
      ctrl.$validators.state = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true
        }
        return stateAcronyms.indexOf(viewValue) >= 0
      }
    }
  }
}
