/**
 * @prettier
 */

module.exports = function wisdomReportSummaryExtra($translate) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/wisdom_report_summary_extra.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      function summaryExtraString(gene) {
        return 'RESULT.EXTRA.WISDOM.' + gene + '_MD';
      }
      if (scope.reportContent.genes_with_pathogenic_mutations.length) {
        scope.showSummaryExtra = true;
        const genes = scope.reportContent.genes_with_pathogenic_mutations;
        scope.summaryExtras = [];
        angular.forEach(genes, function(gene) {
          $translate([summaryExtraString(gene)]).then(function(translations) {
            scope.summaryExtras.push(translations[summaryExtraString(gene)]);
          });
        });
      }
    },
  };
};
