'use strict'

module.exports = function(waffle, auth) {
  'ngInject'
  return function() {
    const { provider_profile } = auth.currentUser;
    /**
     * From @justinwang189 -
     * I think this returns true appropriately for support team viewing CPP.
     * So if a provider i viewing and US return false
     * The old logic was:
     * if (currentProvider && currentProvider.country !== 'US') {
     *   return false
     * }
     * return true
     * This is now inverted to remove double negative logic.
     * It illuminates a little bit of an oddity:
     * If there is no `provider_profile`, then return true.
     * Based on @justinwang189 comment - this may be related to support team viewing a CPP user.
     *
     * FIXME - should we convert this to be explicitly dependent on the support team's permission?
     */
    return (!provider_profile || provider_profile.country === 'US');
  }
}
