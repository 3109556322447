"use strict"

module.exports = function() {
  "ngInject"

  return {
    require: 'ngModel',
    scope: {
      ngModel: '=',
      identifier: '@',
      name: '@',
      value: '@',
      label: '@',
      onChange: '&',
      indent: '=',
      tabindex: '@',
      ngRequired: '=',
      ngDisabled: '=',
      isChar: '=?'
    },
    templateUrl: '/checkbox.html'
  }
}
