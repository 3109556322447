const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="addEvent")
    modal-title Add Event
    modal-body
      .form-group
        select.form-control(ng-model="logData.event_type", ng-options="type for type in eventTypes", required)
          option(value="", disabled="") Select event type...
      .form-group
        input.form-control(type="date", ng-model="logData.event_time", required)
      .form-group
        input.form-control(type="text", placeholder="Add a note", ng-model="logData.note")
    submit-button Add Event
`;

function controller($scope, $modalInstance, reportId, OrderLog) {
  $scope.modalInstance = $modalInstance;
  $scope.eventTypes = [
    "No Show Counseling Session (AoU only)",
    "Counseling session",
    "Phone call to ordering physician",
    "Support call from client",
    "Support call from provider"
  ].sort();

  $scope.logData = { report: reportId, event_time: new Date() };
  $scope.addEvent = () => OrderLog.save($scope.logData).$promise;
}

module.exports = $modal => {
  "ngInject";

  return {
    open: reportId => {
      return $modal.open({
        template: template,
        controller: controller,
        size: "md",
        resolve: {
          reportId: () => reportId
        }
      });
    }
  };
};
