'use strict'

module.exports = function() {
  return {
    restrict: 'E',
    require: 'ngModel',
    scope: {
      choices: '=?',
      ngDisabled: '=?',
      ngRequired: '='
    },
    templateUrl: '/directives/clr_radio_row.html',
    link: function(scope, elem, attrs, modelCtrl) {
      scope.elemId = 'input' + scope.$id

      attrs.$observe('name', function(name) {
        scope.name = name
      })

      scope.$watch('selection', function (newValue, oldValue) {
        if (scope.ngDisabled) {
          return
        }
        modelCtrl.$setViewValue(newValue, 'change')
      })

      modelCtrl.$render = function() {
        scope.selection = modelCtrl.$viewValue
      }

      if (!scope.choices) {
        if (angular.isDefined(attrs.yesNo)) {
          scope.choices = [
            {
              value: true,
              string: 'YES'
            },
            {
              value: false,
              string: 'NO'
            }
          ]
        } else if (angular.isDefined(attrs.yesNoUnsure)) {
          scope.choices = [
            {
              value: 'Y',
              string: 'YES'
            },
            {
              value: 'N',
              string: 'NO'
            },
            {
              value: 'U',
              string: 'UNSURE'
            }
          ]
        } else {
          throw new Error("Must provide either choices, yes-no or yes-no-unsure on clr-radio-row")
        }
      }
    }
  }
}
