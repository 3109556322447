const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="removeReleaseGroupHold")
    modal-title Remove Release Group Hold?
    modal-body
      span(clr-spinner-text, active="!isFinishedLoading")

      div(ng-show="isFinishedLoading")
        p Hold type: 
          strong {{releaseGroupHold.hold_type}}
        p This action will affect all reports in release group {{releaseGroup.release_group_id}}.

        // Even if we failed to fetch the reports in the ReleaseGroup, we still want the
        // modal to be usable. So we simply hide the section where we'd normally list
        // out the reports.
        div(ng-show="reportsInReleaseGroup.length > 0")
          p
            div The following report(s) are in this release group:
            ul
              li(ng-repeat="report in reportsInReleaseGroup")
                a(href="/reports/{{report.id}}") Report {{report.id}}

        input.form-control(
          type="text",
          placeholder="Please describe why you're removing this hold.",
          ng-model="data.note",
          required
        )
    submit-button Remove Hold
`

function controller($scope, $modalInstance, $http, releaseGroup, releaseGroupHold) {
  $scope.modalInstance = $modalInstance
  $scope.releaseGroup = releaseGroup
  $scope.releaseGroupHold = releaseGroupHold
  $scope.data = {}
  $scope.removeReleaseGroupHold = () => {
    const url = `/api/v1/color_hub/release_group_holds/${releaseGroupHold.release_group_hold_id}/remove`
    return $http.post(url, $scope.data)
  }

  // Fetch the reports in the ReleaseGroup so that we can display links to them
  $scope.reportsInReleaseGroup = [];
  $scope.isFinishedLoading = false;
  $http
    .get("/api/v1/color_hub/reports", {
      params: { release_group_id: releaseGroup.release_group_id },
    })
    .then((response) => {
      $scope.reportsInReleaseGroup = response.data;
    })
    .finally(() => {
      $scope.isFinishedLoading = true;
    });
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (releaseGroup, releaseGroupHold) => {
      return $modal.open({
        template: template,
        controller: controller,
        size: 'md',
        resolve: {
          releaseGroup: () => releaseGroup,
          releaseGroupHold: () => releaseGroupHold,
        }
      })
    }
  }
}
