/**
 * @prettier
 */

module.exports = function reportAboutCancerSurvivors(testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_about_cancer_survivors.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
    },
  };
};
