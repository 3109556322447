module.exports = angular
  .module("homeDirectives", [])
  .directive(
    "mobileFooter",
    require("monoweb/django_apps/home/js/directives/mobile_footer")
  )
  .directive(
    "marketingCarousel",
    require("monoweb/django_apps/home/js/directives/marketing_carousel")
  )
  .directive(
    "marketingQuestions",
    require("monoweb/django_apps/home/js/directives/marketing_questions")
  )

  // Design-related directives
  .directive(
    "designWhiteBodyBackground",
    require("monoweb/django_apps/home/js/directives/design_white_body_background")
  );
