/**
 * @prettier
 */

module.exports = function reportSpeakWithSpecialist() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_speak_with_specialist.html',
    replace: true,
  };
};
