module.exports = function ProviderExistingAccountController($scope, $location, auth, ProviderAccount, OrderingPhysicianProfile) {
  'ngInject'

  const account = ProviderAccount.get()
  $scope.email = account.email
  $scope.submitting = false
  $scope.login = function(password) {
    $scope.submitting = true
    auth.login($scope.email, password)
      .then(function(res) {
        return OrderingPhysicianProfile.save(account.provider_profile).$promise
      })
      .then(function(res) { return auth.sync(true) })
      .then(function(res) {
        $location.path('/providers/home').search({welcome:'true'})
      })
      .catch(function(res) {
        $location.path('/providers/create-account')
      })
      .finally(function(res) {
        $scope.submitting = false
      })
  }
}
