/**
 * @ngdoc filter
 * @name insertDisplayAge
 *
 * @description
 * Returns inserts displayAge and age exceptions if necessary (STK11 is only use case right now)
 */

'use strict'

module.exports = function() {
  'ngInject'

  // STK11-specific age exceptions
  const stk11Age65Exceptions = ['cervical cancer', 'ovarian cancer', 'small bowel cancer', 'stomach cancer', 'testicular cancer', 'uterine cancer']

  return function(risks, commonRiskAge, render_version) {
    angular.forEach(risks, function(risk) {
      if (risk.gene == 'STK11' && stk11Age65Exceptions.indexOf(risk.phenotype) > -1) {
        risk.displayAge = 65
        risk.isAgeException = true
      } else if (risk.gene == 'BARD1') {
        // RV < 5: This is errant logic only applicable to RV 1-4
        // Specifically to handle RV1-4 BARD1 + CHEK2: BARD1 only had age 80 risks at the time
        if (render_version >= 5) {
          risk.displayAge = commonRiskAge
        } else {
          risk.displayAge = 80
        }
      } else {
        risk.displayAge = commonRiskAge
      }
    })
    return risks
  }
}
