/**
 * @prettier
 */
module.exports = function ProviderPlatformFamilyTestingController(
  $scope,
  $location,
  $http,
  auth,
  project,
  fileUploader,
  familyTestingGenes,
  ProviderFamilyTestingFlow,
  waffle
) {
  'ngInject';

  if (project.application != 'clinical') {
    $scope.userProvider = auth.currentUser.provider_profile;
  }

  $scope.genes = familyTestingGenes;

  $scope.enrollment = {
    for_physician_order: undefined,
    proband: {
      relationship: 'self',
      genes: [],
    },
    test_type: 'combo 3',
  };
  if ($scope.userProvider) {
    $scope.enrollment.provider_id = $scope.userProvider.id;
  }

  $scope.uploader = fileUploader({
    url: '/api/v1/family_testing/upload',
    queueLimit: 1,
  });

  $scope.uploader.onSuccessItem = function(item, response) {
    $scope.enrollment.proband.document_key = response;
  };

  $scope.relationships = [
    ["Patient's mother", 'mother'],
    ["Patient's father", 'father'],
    ["Patient's daughter", 'daughter'],
    ["Patient's son", 'son'],
    ["Patient's brother", 'brother'],
    ["Patient's sister", 'sister'],
  ];

  $scope.submitting = false;

  $scope.submit = function(enrollment, form) {
    if (!form.validate()) {
      return;
    }
    $scope.submitting = true;
    $http
      .post('/api/v1/family_testing/request_discount', enrollment)
      .then(function() {
        if (enrollment.for_physician_order) {
          ProviderFamilyTestingFlow.goTo('/providers/family-testing-reviewing', true, {
            for_physician_order: 'true',
          });
        } else {
          ProviderFamilyTestingFlow.goTo('/providers/family-testing-reviewing', true);
        }
      })
      .finally(function() {
        $scope.submitting = false;
      });
  };
};
