"use strict"

/**
 * Creates a legend item inside clrHozChart directive
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    replace: true,
    templateUrl: '/directives/clrHozChartLegendItem.html',
    scope: {
      type: '@',
      label: '@'
    }
  }
}
