module.exports = angular.module('resultDirective', ['clrAuth', 'clrConstant', 'clrFilter', 'hc.marked', 'pascalprecht.translate', 'resultFilter', 'resultModal', 'reportModal', 'resultResource', 'healthProfileFilter'])

  .directive('webReportFh', function() {
    return {
      templateUrl: '/results/fh/web_report.html',
      controller: 'ReportController',
      restrict: 'E',
      scope: false
    }
  })
  .directive('webReportH30', function() {
    return {
      templateUrl: '/results/cancer/web_report.html',
      controller: 'ReportController',
      restrict: 'E',
      scope: false
    }
  })
  .directive('webReportV2', function() {
    return {
      templateUrl: '/results/web_report_v2.html',
      controller: require('monoweb/django_apps/results_v2/js/controllers/report_v2_controller'),
      restrict: 'E',
      scope: false
    }
  })

  // Clinical-only report tooling directives
  .directive('calledDiplotypes', require('./directives/clinical/called_diplotypes'))
  .directive('diplotypeCallDetails', require('./directives/clinical/diplotype_call_details'))
  .directive('calledVariants', require('./directives/clinical/called_variants'))
  .directive('reportedData', require('./directives/clinical/reported_data'))
  .directive('confirmationInfo', require('./directives/clinical/confirmation_info'))
  .directive('familyTestingInfo', require('./directives/clinical/family_testing_info'))
  .directive('highlightedHealthHistory', require('./directives/clinical/highlighted_health_history'))
  .directive('knownVariants', require('./directives/clinical/known_variants'))
  .directive('qcMetrics', require('./directives/clinical/qc_metrics'))
  .directive('reportedMutations', require('./directives/clinical/reported_mutations'))
  .directive('reportInfo', require('./directives/clinical/report_info'))
  .directive('reportLogs', require('./directives/clinical/report_logs'))
  .directive('reportNewPreview', require('./directives/clinical/report_v2_preview')) // inconsistent directive name and filename
  .directive('reportRevision', require('./directives/clinical/report_revision')) // points to inconsistent `rerelease.html`
  .directive('reportSummary', require('./directives/clinical/report_summary')) // confusing name
  .directive('reportTags', require('./directives/clinical/report_tags'))
  .directive('reportTemplate', require('./directives/clinical/report_template'))

  // Generic V1 report directives (used on both cancer and FH reports)
  .directive('reportQuestion', require('./directives/report_question'))

  // V1 cancer report directives
  .directive('ltrLegend', require('./directives/ltr_legend'))
  .directive('ltrTable', require('./directives/ltr_table'))
  .directive('ltrTimechart', require('./directives/ltr_timechart'))
  .directive('reportAboutCancerRisks', require('./directives/report_about_cancer_risks'))
  .directive('reportAboutCancerSurvivors', require('./directives/report_about_cancer_survivors'))
  .directive('reportAboutFamily', require('./directives/report_about_family'))
  .directive('reportAboutTheGene', require('./directives/report_about_the_gene'))
  .directive('reportAdditionalFindings', require('./directives/report_additional_findings'))
  .directive('reportAdditionalGenes', require('./directives/report_additional_genes'))
  .directive('reportAffectOtherGender', require('./directives/report_affect_other_gender'))
  .directive('reportConnectWithOthers', require('./directives/report_connect_with_others'))
  .directive('reportDropdown', require('./directives/report_dropdown'))
  .directive('reportFamilyTestingProgram', require('./directives/report_family_testing_program'))
  .directive('reportGeneDetails', require('./directives/report_gene_details'))
  .directive('reportGeneralQuestions', require('./directives/report_general_questions'))
  .directive('reportGuidelines', require('./directives/report_guidelines'))
  .directive('reportLabSendouts', require('./directives/report_lab_sendouts'))
  .directive('reportMutation', require('./directives/report_mutation'))
  .directive('reportOpenOrderDetailsModal', require('./directives/report_open_order_details_modal'))
  .directive('reportOtherScreeningGuidelines', require('./directives/report_other_screening_guidelines'))
  .directive('reportProvideHealthHistory', require('./directives/report_provide_health_history'))
  .directive('reportProvideHealthHistoryBanner', require('./directives/report_provide_health_history_banner'))
  .directive('reportRevisions', require('./directives/report_revisions'))
  .directive('reportRiskFactors', require('./directives/report_risk_factors'))
  .directive('reportRiskTooltip', require('./directives/report_risk_tooltip'))
  .directive('reportShareFamily', require('./directives/report_share_family'))
  .directive('reportShareYourResults', require('./directives/report_share_your_results'))
  .directive('reportSpeakWithSpecialist', require('./directives/report_speak_with_specialist'))
  .directive('reportSummaryChart', require('./directives/report_summary_chart'))
  .directive('reportSummaryExtra', require('./directives/report_summary_extra'))
  .directive('reportSummaryText', require('./directives/report_summary_text'))
  .directive('reportSummaryTitle', require('./directives/report_summary_title'))
  .directive('reportSurvivorStringAppend', require('./directives/report_survivor_string_append'))
  .directive('reportTestMethodology', require('./directives/report_test_methodology'))
  .directive('reportTopNavigation', require('./directives/report_top_navigation'))
  .directive('reportValidity', require('./directives/report_validity'))
  .directive('reportVus', require('./directives/report_vus'))
  .directive('sampleLetterMailto', require('./directives/sample_letter_mailto'))

  // Wisdom-specific directives
  .directive('wisdomReportSummaryExtra', require('./directives/wisdom_report_summary_extra'))
  .directive('wisdomReportTestMethodology', require('./directives/wisdom_report_test_methodology'))

  // FH report directives
  .directive('fhAboutTheGene', require('./directives/fh/about_the_gene'))
  .directive('fhConnectWithOthers', require('./directives/fh/connect_with_others'))
  .directive('fhCoronaryHeartDisease', require('./directives/fh/coronary_heart_disease'))
  .directive('fhFamilyImpact', require('./directives/fh/family_impact'))
  .directive('fhGeneralQuestions', require('./directives/fh/general_questions'))
  .directive('fhGuidelines', require('./directives/fh/guidelines'))
  .directive('fhShareFamily', require('./directives/fh/share_family'))
  .directive('fhSummaryChart', require('./directives/fh/summary_chart'))
  .directive('fhSummaryText', require('./directives/fh/summary_text'))
  .directive('fhSummaryTitle', require('./directives/fh/summary_title'))
