"use strict"

/**
 * Hijacks submit action of the form with a given submit handler.
 * Also it automates error handling of the form.
 */
module.exports = function($window, $q, $raven, $location, ngToast) {
  "ngInject"

  return {
    restrict: 'A',
    require: 'form',
    scope: {
      onSubmit: '&',
      scrollToTopOnError: '=',
      toastOnError: '=',
      showPartialError: '=',
      sentryTrackValidationError: '=?',
      sentryTrackValidationErrorComponent: '@?',
      warnBeforeUnload: '=?'
    },
    link: function(scope, elem, attrs, form) {
      attrs.$set('novalidate', 'novalidate')

      form.showAllErrors = function() {
        // commitViewValue is called explicitly because we have fields which are
        // only set on blur, which is not triggered with browser autofills etc.
        form.$commitViewValue()

        // if showPartialError is set, we will only display errors on fields
        // which exist (by marking them dirty). fields which do not have errors
        // at the time this function is called (including fields dynamically added
        // afterwards) will not display errors until this function is called again.
        if (!scope.showPartialError) {
          form.showError = true
          elem.addClass('show-error')
        } else {
          Object.keys(form.$error).map(function(key) {
            form.$error[key].map(function(field) {
              field.$setDirty()
            })
          })
        }
        if (!!scope.scrollToTopOnError) {
          $window.scrollTo(0, 0)
        }
        if (!!scope.toastOnError) {
          ngToast.create({ className: 'error', content: "Please correct the errors called out in red below." })
        }
      }

      // addAsyncValidator extends $asyncValidators by storing all pending
      // validator promises in the _pending hash (keyed by field and validator name).
      // The _pending hash ensures that the validate() check only fires
      // after all pending asyncValidator promises are resolved.
      const _pending = {}
      form.addAsyncValidator = function(field, validatorName, validator) {
        if (!form[field]) {
          throw 'error registering async validator: field ' + field + ' does not exist on form'
        }
        form[field].$asyncValidators[validatorName] = function(modelVal, viewVal) {
          const deferred = $q.defer()
          _pending[field + '--' + validatorName] = deferred.promise
          return validator(modelVal, viewVal)
            .then(function(val) {
              deferred.resolve(val)
              return val
            })
            .catch(function(err) {
              deferred.reject(err)
              throw err
            })
        }
      }

      function _pendingAsync() {
        return Object.keys(_pending).map(function (k) {
          return _pending[k]
        })
      }

      // validate all the form fields. This should not be used in a form with
      // async validators

      form.validate = function() {
        if (form.$pending) {
          throw 'cannot validate form with pending requests'
        }
        if (form.$invalid) {
          form.showAllErrors()
          return false
        }
        form.showError = false
        elem.removeClass('show-error')
        return true
      }

      // Clean up structure of form.$error for readability purposes on Sentry
      const formattedFormErrorsForSentry = (formErrors) => {
        const formattedFormErrors = []
        for (const f in formErrors) {
          formErrors[f].forEach(g => {
            formattedFormErrors.push({
              // Can't have a properly key of "name" cause it's filtered out on Sentry
              fieldName: g.$name,
              fieldErrors: g.$error,
            });
          })
        }
        return formattedFormErrors;
      }

      function validateAndSubmit() {
        if (form.validate()) {
          scope.onSubmit();
          form.$dirty = false
        } else if (scope.sentryTrackValidationError) {
          $raven.captureException('Client-side form validation error', {
            culprit: $location.path(),
            // TODO: (Steven) Health history is breaking for clients on family-tree if they have validation errors
            // after the angular upgrade. Figure out root cause and comment back in.
            // extra: {
            //   'form.$error': {
            //     clean: formattedFormErrorsForSentry(form.$error),
            //   },
            // },
            tags: {
              component: scope.sentryTrackErrorComponent || null,
            },
            level: 'info',
          });
        }
      }

      elem.bind('submit', function(event) {
        event.preventDefault()

        // if there are no pending fields, validate synchronously
        // otherwise validate async
        $q.all(_pendingAsync())
          .then(validateAndSubmit)
      })

      if (scope.warnBeforeUnload) {
        window.onbeforeunload = function() {
          if (form.$dirty) {
            return 'Changes you made may not be saved.'
          }
          return
        }
      }
    }
  }
}
