/**
 * @ngdoc service
 * @name receivedPromoCode
 *
 * @description
 * The `receivedPromoCode` handles promo code routing within the benefits flow.
 */

module.exports = ($location, promoCodeStore, DependentsClaimManager) => {
  'ngInject'

  return (promoCode) => {
    promoCodeStore.clear()
    return promoCodeStore.updateCode(promoCode, true)
      .then(() => {
        if (!promoCodeStore.promotionalCode.can_redeem && !DependentsClaimManager.canAddFamilyMember(false)) {
          return
        }

        promoCodeStore.persistCode(promoCode)

        const skuList = promoCodeStore.promotionalCode.sku_list
        if (skuList && skuList.length === 1) {
          $location.path('/purchase').search({sku: skuList[0]})
        } else {
          $location.hash('pricing')
          $location.path('/product/compare-genetic-tests')
        }
      })
  }
}
