!function() {

  'use strict'

  const module = angular.module('physicianResource', ['clrResource'])

  module.factory('VerifiedPhysician', function(resource) {
    return resource(
      '/api/v1/verified_physicians/:id'
      , { id: '@id', format: 'json' }
      , { update: { method: 'PUT' } }
    )
  })

  module.factory('PhysicianVerification', function(resource) {
    return resource(
      '/api/v1/physician_verifications/:id'
      , { format: 'json', id: '@id' }
    )
  })

  module.factory('Institution', function(resource) {
    return resource(
      '/api/v1/institutions/:id'
      , { format: 'json', id: '@id', ordering: 'name' }
      , { update: { method: 'PUT' } }
    )
  })
}();
