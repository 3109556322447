'use strict'

module.exports = function(patientIsMinor) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      flexStyle: '@?',
      skipAgeCheck: '=?',
      displayTooltip: '@?'
    },
    require: ['^^form', 'ngModel'],
    templateUrl: '/users/birthday_input.html',
    link: function(scope, elem, attr, controllers) {
      scope.isoformat = angular.isDefined(attr.isoformat) ? attr.isoformat : 'false'
      scope.birthdateModel = controllers[1]
      scope.form = controllers[0]

      function shouldCheckAge() {
        if (angular.isDefined(scope.skipAgeCheck)) {
          return !scope.skipAgeCheck
        } else {
          return true
        }
      }

      function checkOverAge(birthdate) {
        const b = new Date(birthdate.year, birthdate.month - 1, birthdate.day)
        return !patientIsMinor(b)
      }

      function checkFutureDate(birthdate) {
        const now = new Date()
        const b = new Date(birthdate.year, birthdate.month - 1, birthdate.day)
        return b <= now
      }

      scope.validate = function(date) {
        if (shouldCheckAge()) {
          return checkOverAge(date) && checkFutureDate(date)
        } else {
          return checkFutureDate(date)
        }
      }

      scope.onChange = function(dateModel, date) {
        // We'd like to only show the date error OR the errors below.
        //
        // So, if there's an error caused by the date model, we won't set any
        // other errors.

        if (dateModel.$error.date) {
          return
        }

        if (shouldCheckAge()) {
          const overAge = checkOverAge(date)
          dateModel.$setValidity('underage', overAge)
        }

        const futureDate = checkFutureDate(date)
        dateModel.$setValidity('birthdate', futureDate)
      }
    }
  }
}
