/**
 * @prettier
 */

module.exports = function(
  $sce,
  $http,
  $raven,
  ngToast,
  $routeParams,
  handleReportClick,
  marked,
  testTypes,
  waffle
) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      report: '=',
      forProd: '=',
    },
    templateUrl: '/results/directives/report_v2_preview.html',
    link: function(scope) {
      // For web reports that are on `projects/home/frontend`, the web app itself fetches report payload.
      const requireFetchingPayload = true;
      scope.iframeWidth = '100%';
      scope.iframeHeight = '100%';
      // PGx web reports are implemented in www react app (projects/home/frontend).
      if (scope.report.kit_order.test_type === testTypes.pgxv1) {
        scope.reportURL = $sce.trustAsResourceUrl(`/report/${scope.report.id}/web_report`);
      } else if (scope.report.has_v2) {
        scope.reportURL = $sce.trustAsResourceUrl(`/report/${scope.report.id}/web_report`);
      } else {
        scope.reportURL = $sce.trustAsResourceUrl(`/report/sample_web_report`);
      }

      // Render the report using the new report page when the 'show_react_web=true'
      // query parameter is detected. This supports the ongoing integration of
      // monoweb/reports from a separate project into the main codebase.
      // During the transition, it's necessary to maintain both the old and new
      // report views in clinical to ensure a smooth migration to the updated system.
      if ($routeParams.show_react_web && $routeParams.show_react_web === 'true') {
        scope.reportURL = $sce.trustAsResourceUrl(
          `/results-v3/report/view?reportId=${scope.report.id}`
        );
        scope.iframeHeight = '7500px';
      }

      scope.reportErrors = [];
      const reportShareFamilyData = {
        msgMarkdownSampleLetter: 'Sample letter.',
      };
      scope.canGenerate = scope.report.state == 'new';
      scope.generatingV2 = false;
      scope.regenerateReport = () => {
        scope.generatingV2 = true;
        $http
          .post(`api/v1/generate_report_v2/${scope.report.id}`)
          .then(() => {
            window.location.reload(true);
          })
          .catch(err => {
            scope.reportErrors.push(err);
            scope.generatingV2 = false;
          });
      };

      if (requireFetchingPayload) {
        scope.reportFrameOnload = () => {
          const iframe = angular.element('#reportFrame')[0];
          window.setTimeout(() => {
            window.iFrameResize(
              {
                checkOrigin: false,
                heightCalculationMethod: 'documentElementOffset',
              },
              '#reportFrame'
            );
          }, 500);

          iframe.contentWindow.addEventListener('click', e => {
            handleReportClick(e, reportShareFamilyData, scope.report.test_type, '1234', '');
          });

          function updateShareFamilyData(report_payload) {
            reportShareFamilyData.msgMarkdownSampleLetter = '';
            report_payload.components.share_family_letter.content.map(c => {
              reportShareFamilyData.msgMarkdownSampleLetter += marked(c);
            });
          }

          // handle report info
          const handleError = function(error) {
            let msg = '';
            if (!error || !error.data) {
              console.error('Unknown internal server error');
            } else {
              msg = angular.toJson(error.data);
              if (error.data['non_field_errors']) {
                // Truncate this msg's non-field errors because v2 report exceptions can be
                // massive due to the JSON Schema-based object it's based and Sentry rejects
                // large requests.
                // Related issue: https://getcolor.atlassian.net/browse/SUPPORT-6741
                msg = error.data['non_field_errors']
                  .slice(0, 5)
                  .join(', ')
                  .substring(0, 1024);
              }
            }

            if (msg) {
              $raven.captureMessage(msg);
              scope.reportErrors.push(msg);
            }
          };
          if (!scope.report.has_v2) {
            $http
              .get(`api/v1/report_info/${scope.report.id}?preview=1`, {
                internalServerErrorToast: true,
                ignoreRequestErrorHandling: true,
              })
              .then(result => {
                delete result.data.note;
                return $http.post('api/v1/generate_v2_report_from_report_info', result.data, {
                  internalServerErrorToast: true,
                  ignoreRequestErrorHandling: true,
                });
              })
              .then(res => {
                if (res && res.data) {
                  const store = iframe.contentWindow.ReportStore;
                  store.update(res.data);
                  updateShareFamilyData(res.data);
                }
              })
              .catch(handleError);
          } else {
            $http
              .get(`report/${scope.report.id}/data.json`, {
                internalServerErrorToast: true,
                ignoreRequestErrorHandling: true,
              })
              .then(res => {
                if (res && res.data) {
                  updateShareFamilyData(res.data);
                }
              })
              .catch(handleError);
          }
        };
      }
    },
  };
};
