'use strict'

module.exports = function additionalInformationForm(
  uploadFileTypes, fileUploader, patientIsMinor, sku
) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/additional_information_form.html',
    scope: {
      order: '=',
      insurance: '=?'
    },
    link: {
      pre: function(scope) {
        scope.uploader = fileUploader(
          {
            formData: [{
              file_type: uploadFileTypes.physicianOrderFiles
            }]
          }
        )
        scope.testname = sku[scope.order.test_requested].productName

        scope.uploader.onSuccessItem = function(item, response) {
          scope.order.upload_files.push({
            s3_key: response,
            file_type: item.formData[0].file_type
          })
          scope.uploader.clearQueue()
        }

        scope.patientIsMinor = function() {
          const birthdate = new Date(scope.order.patient_profile.birthday)
          return patientIsMinor(birthdate)
        }
      },
      post: function(scope) {
        scope.removeUploadFile = function(uploadFile) {
          const i = scope.order.upload_files.indexOf(uploadFile)
          scope.order.upload_files.splice(i, 1)
        }
      }
    }
  }
}
