const getAbsolutePath = require('monoweb/django_apps/core/static/js/absolute_path');
module.exports = function mobileFooter(navigation) {
  'ngInject'
  return {
    restrict: 'E',
    scope: false,
    templateUrl: '/directives/mobileFooter.html',
    link: function(scope) {
      scope.getAbsolutePath = getAbsolutePath;
      scope.$watch(function() {
        return navigation.mobileFooter
      }, function() {
        scope.mobileFooter = navigation.mobileFooter
      })
    }
  }
}
