module.exports = angular.module('kitOrderControllers', [
  require('monoweb/django_apps/kit_orders/js/modals').name
])
  .controller('KitRedirectController', require('monoweb/django_apps/kit_orders/js/controllers/kit_redirect_controller'))
  .controller('ResearchRedirectController', require('monoweb/django_apps/kit_orders/js/controllers/research_redirect_controller'))
  .controller('OrderingPhysicianController', require('monoweb/django_apps/kit_orders/js/controllers/ordering_physician_controller'))
  .controller('KitOrderConfirmationController', require('monoweb/django_apps/kit_orders/js/controllers/kit_order_confirmation_controller'))
  .controller('OnsiteBenefitController', require('monoweb/django_apps/kit_orders/js/controllers/onsite_benefit_controller'))
  .controller('SampleReuseConsentController', require('monoweb/django_apps/kit_orders/js/controllers/sample_reuse_consent_controller'))
  .controller('SampleReusePayController', require('monoweb/django_apps/kit_orders/js/controllers/sample_reuse_pay_controller'))
  .controller('ShareController', require('monoweb/django_apps/kit_orders/js/controllers/share_controller'))
  .controller('SampleReuseConfirmationController', require('monoweb/django_apps/kit_orders/js/controllers/sample_reuse_confirmation_controller'))
