function NotYetAvailableModalController($scope, $modalInstance, $translate, ngToast, auth, Waitlist, stateFullNames, name, testName) {
  'ngInject'

  $scope.saving = false
  if (name.length == 2) {
    $scope.type = 'STATE'
    $scope.jurisdictionName = stateFullNames[name]
    $scope.testName = testName
  } else if (testName) {
    $scope.type = 'SINGLE_KIT_COUNTRY'
    $scope.jurisdictionName = name
    $scope.testName = testName
  } else {
    $scope.type = 'COUNTRY'
    $scope.jurisdictionName = name
  }

  let waitListName = name
  if (testName) {
    waitListName = waitListName + ' (' + testName + ')'
  }
  $scope.waitlist = new Waitlist({
    waitlist_type: $scope.type.toLowerCase(),
    waitlist_name: waitListName})
  if (auth.isLoggedIn) {
    $scope.waitlist.email = auth.currentUser.email
    $scope.waitlist.user = auth.currentUser.id
  }
  $scope.notify = true

  $scope.saveWaitlist = function(notify, waitlist) {
    if (!notify) {
      $modalInstance.dismiss()
      return
    }
    $scope.saving = true
    // We don't show error even if api returns 400
    // to avoid showing error if user has added to the list
    // already.
    // Only validation error that can happen is email address
    // value but we do client side validation so it is ok.
    waitlist.$save_with_skip_error_toast()
      .finally(function(res) {
        if ($scope.testName){
          ngToast.create('You will be notified when ' + $scope.testName + ' is available in '+ $scope.jurisdictionName + '.')
        } else {
          ngToast.create('You will be notified when kits are available in '+ $scope.jurisdictionName + '.')
        }
        $modalInstance.close()
        $scope.saving = false
      })
  }

  $scope.dismiss = function() {
    $modalInstance.dismiss()
  }
}

module.exports = function NotYetAvailableModal($modal) {
  'ngInject'

  return {
    open: function(name, testName) {
      return $modal.open({
        templateUrl: '/kit_orders/modals/not_yet_available.html',
        controller: NotYetAvailableModalController,
        size: 'sm',
        resolve: {
          name: function() {
            return name
          },
          testName: function() {
            return testName
          }
        }
      })
    }
  }
}
