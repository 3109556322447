module.exports = function iframeOnload() {
  return {
    scope: {
      callback: '&iframeOnload'
    },
    link: function(scope, element, attrs) {
      element.on('load', function() {
        return scope.callback();
      })
    }
  }
}
