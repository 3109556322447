'use strict'

module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      step: '='
    },
    transclude: true,
    templateUrl: '/directives/clr_breadcrumbs.html',
    controller: function($scope) {
      'ngInject'

      const listeners = []

      $scope.$watch('step', function(newValue) {
        angular.forEach(listeners, function(listener) {
          if (listener) {
            listener(newValue)
          }
        })
      })

      this.register = function(listener) {
        const i = listeners.length
        listeners.push(listener)
        listener($scope.step)
        return i
      }

      this.unregister = function(i) {
        listeners[i] = null
      }
    }
  }
}
