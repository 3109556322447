!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrConstant
   *
   * @description
   * The `clrConstant` provides constants for color app.
   */
  const module = angular.module('clrConstant', [])

  /**
   * @ngdoc constant
   * @const geneCount
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns # of genes in current panel
   *
   * @returns {integer}
   */
  module.constant('geneCount', 30)

  /**
   * @ngdoc constant
   * @const stateAcronyms
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns state abbreviations
   *
   * @returns {list}
   */
  module.constant('stateAcronyms', [
    'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC',
    'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN',
    'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN',
    'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ',
    'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI',
    'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA',
    'WI', 'WV', 'WY'])

  /**
   * @ngdoc constant
   * @const stateNames
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns state names and abbreviations
   *
   * @returns {list of dictionary}
   */
  module.constant('stateNames', [
    {'abbreviation': 'AL', 'fullName': 'Alabama'},
    {'abbreviation': 'AK', 'fullName': 'Alaska'},
    {'abbreviation': 'AS', 'fullName': 'American Samoa'},
    {'abbreviation': 'AZ', 'fullName': 'Arizona'},
    {'abbreviation': 'AR', 'fullName': 'Arkansas'},
    {'abbreviation': 'CA', 'fullName': 'California'},
    {'abbreviation': 'CO', 'fullName': 'Colorado'},
    {'abbreviation': 'CT', 'fullName': 'Connecticut'},
    {'abbreviation': 'DE', 'fullName': 'Delaware'},
    {'abbreviation': 'DC', 'fullName': 'District of Columbia'},
    {'abbreviation': 'FL', 'fullName': 'Florida'},
    {'abbreviation': 'GA', 'fullName': 'Georgia'},
    {'abbreviation': 'GU', 'fullName': 'Guam'},
    {'abbreviation': 'HI', 'fullName': 'Hawaii'},
    {'abbreviation': 'ID', 'fullName': 'Idaho'},
    {'abbreviation': 'IL', 'fullName': 'Illinois'},
    {'abbreviation': 'IN', 'fullName': 'Indiana'},
    {'abbreviation': 'IA', 'fullName': 'Iowa'},
    {'abbreviation': 'KS', 'fullName': 'Kansas'},
    {'abbreviation': 'KY', 'fullName': 'Kentucky'},
    {'abbreviation': 'LA', 'fullName': 'Louisiana'},
    {'abbreviation': 'ME', 'fullName': 'Maine'},
    {'abbreviation': 'MD', 'fullName': 'Maryland'},
    {'abbreviation': 'MA', 'fullName': 'Massachusetts'},
    {'abbreviation': 'MI', 'fullName': 'Michigan'},
    {'abbreviation': 'MN', 'fullName': 'Minnesota'},
    {'abbreviation': 'MS', 'fullName': 'Mississippi'},
    {'abbreviation': 'MO', 'fullName': 'Missouri'},
    {'abbreviation': 'MT', 'fullName': 'Montana'},
    {'abbreviation': 'NE', 'fullName': 'Nebraska'},
    {'abbreviation': 'NV', 'fullName': 'Nevada'},
    {'abbreviation': 'NH', 'fullName': 'New Hampshire'},
    {'abbreviation': 'NJ', 'fullName': 'New Jersey'},
    {'abbreviation': 'NM', 'fullName': 'New Mexico'},
    {'abbreviation': 'NY', 'fullName': 'New York'},
    {'abbreviation': 'NC', 'fullName': 'North Carolina'},
    {'abbreviation': 'ND', 'fullName': 'North Dakota'},
    {'abbreviation': 'MP', 'fullName': 'Northern Mariana Islands'},
    {'abbreviation': 'OH', 'fullName': 'Ohio'},
    {'abbreviation': 'OK', 'fullName': 'Oklahoma'},
    {'abbreviation': 'OR', 'fullName': 'Oregon'},
    {'abbreviation': 'PA', 'fullName': 'Pennsylvania'},
    {'abbreviation': 'PR', 'fullName': 'Puerto Rico'},
    {'abbreviation': 'RI', 'fullName': 'Rhode Island'},
    {'abbreviation': 'SC', 'fullName': 'South Carolina'},
    {'abbreviation': 'SD', 'fullName': 'South Dakota'},
    {'abbreviation': 'TN', 'fullName': 'Tennessee'},
    {'abbreviation': 'TX', 'fullName': 'Texas'},
    {'abbreviation': 'VI', 'fullName': 'U.S. Virgin Islands'},
    {'abbreviation': 'UT', 'fullName': 'Utah'},
    {'abbreviation': 'VT', 'fullName': 'Vermont'},
    {'abbreviation': 'VA', 'fullName': 'Virginia'},
    {'abbreviation': 'WA', 'fullName': 'Washington'},
    {'abbreviation': 'WV', 'fullName': 'West Virginia'},
    {'abbreviation': 'WI', 'fullName': 'Wisconsin'},
    {'abbreviation': 'WY', 'fullName': 'Wyoming'},
    /*
       Areas that have 2-letter codes but are deliberately omitted because they are
       outside the US: Federated States of Micronesia, Marshall Islands, and Palau.
       Areas that are not states but are deliberately included because they are
       part of the US: American Samoa, District of Columbia, Guam, Northern
       Mariana Islands, Puerto Rico, Virgin Islands (U.S.).
    */
  ])

  /**
   * @ngdoc constant
   * @const topCountries
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns the countries that should be displayed at the top of any country
   * selector, site-wide.
   *
   * @returns {list}
   */
  module.constant('topCountries', ['US', 'CA', 'GB'])

  /**
   * The masterGeneTable is a local variable which contains all the relevant
   * info about each gene.  In order to keep the code as DRY as possible, we
   * we build all other gene-related data structures from this master table.
   *
   * IMPORTANT: Do NOT alter the order of entries; that has been tweaked to
   * optimize the appearance of a user-visible table.
   */
  const masterGeneTable = [
    {
      name: 'BRCA1',
      interpreted_by: ['hereditary 30', 'brca 1 2', 'breast ovarian 19', 'wisdom 9', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Breast', 'Ovarian', 'Pancreatic', 'Prostate']
    }, {
      name: 'BRCA2',
      interpreted_by: ['hereditary 30', 'brca 1 2', 'breast ovarian 19', 'wisdom 9', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Breast', 'Ovarian', 'Melanoma', 'Pancreatic', 'Prostate']
    }, {
      name: 'MLH1',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Ovarian', 'Uterine', 'Colorectal', 'Pancreatic', 'Prostate', 'Stomach']
    }, {
      name: 'MSH2',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Ovarian', 'Uterine', 'Colorectal', 'Pancreatic', 'Prostate', 'Stomach']
    }, {
      name: 'MSH6',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Ovarian', 'Uterine', 'Colorectal', 'Prostate', 'Stomach']
    }, {
      name: 'PMS2',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Ovarian', 'Uterine', 'Colorectal', 'Prostate'],
      footnote: '*'
    }, {
      name: 'EPCAM',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 7', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Ovarian', 'Uterine', 'Colorectal', 'Pancreatic', 'Prostate', 'Stomach'],
      footnote: '*'
    }, {
      name: 'APC',
      interpreted_by: ['hereditary 30', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Colorectal', 'Pancreatic', 'Stomach']
    }, {
      name: 'MUTYH',
      interpreted_by: ['hereditary 30', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Colorectal']
    }, {
      name: 'MITF',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Melanoma'],
      footnote: '*'
    }, {
      name: 'BAP1',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Melanoma']
    }, {
      name: 'CDKN2A',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Melanoma', 'Pancreatic'],
      interpretations: ['(p14ARF)', '(p16INK4a)']
    }, {
      name: 'CDK4',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Melanoma'],
      footnote: '*'
    }, {
      name: 'TP53',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Breast', 'Ovarian', 'Uterine', 'Colorectal', 'Melanoma', 'Pancreatic', 'Stomach', 'Prostate']
    }, {
      name: 'PTEN',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Breast', 'Uterine', 'Colorectal', 'Melanoma']
    }, {
      name: 'STK11',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Breast', 'Ovarian', 'Uterine', 'Colorectal', 'Pancreatic', 'Stomach']
    }, {
      name: 'CDH1',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 41'],
      cancersCovered: ['Breast', 'Stomach']
    }, {
      name: 'BMPR1A',
      interpreted_by: ['hereditary 30', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Colorectal', 'Pancreatic', 'Stomach']
    }, {
      name: 'SMAD4',
      interpreted_by: ['hereditary 30', 'hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: ['Colorectal', 'Pancreatic', 'Stomach']
    }, {
      name: 'GREM1',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Colorectal'],
      footnote: '*'
    }, {
      name: 'POLD1',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Colorectal'],
      footnote: '*'
    }, {
      name: 'POLE',
      interpreted_by: ['hereditary 30', 'hereditary 41'],
      cancersCovered: ['Colorectal'],
      footnote: '*'
    }, {
      name: 'PALB2',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 41'],
      cancersCovered: ['Breast', 'Ovarian', 'Pancreatic']
    }, {
      name: 'CHEK2',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 41'],
      cancersCovered: ['Breast', 'Colorectal', 'Prostate']
    }, {
      name: 'ATM',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'wisdom 9', 'hereditary 41'],
      cancersCovered: ['Breast', 'Pancreatic', 'Prostate']
    }, {
      name: 'BARD1',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 41'],
      cancersCovered: ['Breast']
    }, {
      name: 'BRIP1',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 41'],
      cancersCovered: ['Breast', 'Ovarian']
    }, {
      name: 'RAD51C',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 41'],
      cancersCovered: ['Ovarian']
    }, {
      name: 'RAD51D',
      interpreted_by: ['hereditary 30', 'breast ovarian 19', 'hereditary 41'],
      cancersCovered: ['Ovarian']
    }, {
      name: 'MEN1',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'NF2',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'RB1',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'RET',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'SDHAF2',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'SDHB',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'SDHC',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'SDHD',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'TSC1',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'TSC2',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'VHL',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'WT1',
      interpreted_by: ['hereditary 25', 'hereditary 41', 'hdr 59'],
      cancersCovered: []
    }, {
      name: 'ACTA2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'ACTC1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'APOB',
      interpreted_by: ['cardio 30', 'fh 3', 'hdr 59'],
      cardioConditionsCovered: ['Familial Hypercholesterolemia'],
      footnote: '*'
    }, {
      name: 'COL3A1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'DSC2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'DSG2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'DSP',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'FBN1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'GLA',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'KCNH2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arrhythmia'],
      footnote: '*'
    }, {
      name: 'KCNQ1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arrhythmia'],
      footnote: '*'
    }, {
      name: 'LDLR',
      interpreted_by: ['cardio 30', 'fh 3', 'hdr 59'],
      cardioConditionsCovered: ['Familial Hypercholesterolemia'],
    }, {
      name: 'LMNA',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'MYBPC3',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy'],
      footnote: '*'
    }, {
      name: 'MYH7',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy'],
      footnote: '*'
    }, {
      name: 'MYH11',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'MYL2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'MYL3',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'PCSK9',
      interpreted_by: ['cardio 30', 'fh 3', 'hdr 59'],
      cardioConditionsCovered: ['Familial Hypercholesterolemia']
    }, {
      name: 'PKP2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'PRKAG2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy'],
      footnote: '*'
    }, {
      name: 'RYR2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arrhythmia']
    }, {
      name: 'SCN5A',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arrhythmia']
    }, {
      name: 'SMAD3',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'TGFBR1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy'],
      footnote: '*'
    }, {
      name: 'TGFBR2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Arteriopathy']
    }, {
      name: 'TMEM43',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'TNNI3',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'TNNT2',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'TPM1',
      interpreted_by: ['cardio 30', 'hdr 59'],
      cardioConditionsCovered: ['Cardiomyopathy']
    }, {
      name: 'ATP7B',
      interpreted_by: ['metabolism 4', 'hdr 59']
    }, {
      name: 'CACNA1S',
      interpreted_by: ['metabolism 4', 'hdr 59']
    }, {
      name: 'OTC',
      interpreted_by: ['metabolism 4', 'hdr 59']
    }, {
      name: 'RYR1',
      interpreted_by: ['metabolism 4', 'hdr 59']
    }
  ]

  /**
   * @ngdoc constant
   * @const interpretedGenes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of interpreted genes.
   *
   * @returns {integer}
   */
  module.constant('interpretedGenes', (function() {
    const result = {}
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      for (let ib = 0; ib < gene.interpreted_by.length; ib++) {
        const test = gene.interpreted_by[ib]
        result[test] = result[test] || []
        if (gene.interpretations) {
          for (let i = 0; i < gene.interpretations.length; i++) {
            result[test].push(gene.name + ' ' + gene.interpretations[i])
          }
        } else {
          result[test].push(gene.name)
        }
      }
    }
    for (const test in result) {
      result[test].sort()
    }
    return result
  })())

  /**
   * @ngdoc constant
   * @const familyTestingGenes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of genes eligible for Family Testing
   * (currently H30 + FH3)
   *
   * @returns {integer}
   */
  module.constant('familyTestingGenes', (function() {
    const result = []
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      if (gene.interpreted_by.indexOf('hereditary 30') >= 0 ||
          gene.interpreted_by.indexOf('cardio 30') >= 0) {
        result.push(gene.name)
      }
    }
    result.sort()
    return result
  })())

  /**
   * @ngdoc constant
   * @const cardioConditionsCovered
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an array of heart conditions covered by the Cardio 30 test
   *
   * @returns {array}
   */
  module.constant('cardioConditionsCovered', [
    'Cardiomyopathy', 'Arrhythmia', 'Arteriopathy', 'Familial Hypercholesterolemia'
  ])

  /**
   * @ngdoc constant
   * @const cancersCovered
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an array of cancers covered by the Hereditary 30 test
   *
   * @returns {array}
   */
  module.constant('cancersCovered', [
    'Breast', 'Ovarian', 'Uterine', 'Colorectal', 'Melanoma',
    'Pancreatic', 'Stomach', 'Prostate'
  ])

  /**
   * @ngdoc constant
   * @const cancersCoveredAltName
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of cancers with alternative names, usually asterisks
   *
   * @returns {object}
   */
  module.constant('cancersCoveredAltName', {
    'Prostate': 'Prostate*'
  })

  /**
   * @ngdoc constant
   * @const clinicalCancerName
   * @static
   * @memberof clrConstant
   *
   * @description
   * The cancer name constants we use in the backend.
   * These names should match those defined in the class Cancer in health_profiles/constants.py
   *
   * @returns {object}
   */
  const clinicalCancerName = {
    BREAST: 'Breast cancer',
    OVARIAN: 'Ovarian cancer',
    ENDOMETRIAL: 'Endometrial cancer',
    COLORECTAL: 'Colorectal cancer',
    MELANOMA: 'Melanoma',
    PANCREATIC: 'Pancreatic cancer',
    GASTRIC: 'Gastric cancer',
    PROSTATE: 'Prostate cancer',
  }
  module.constant('clinicalCancerName', clinicalCancerName)

  /**
   * @ngdoc constant
   * @const cancerToClinicalName
   * @static
   * @memberof clrConstant
   *
   * @description
   * Maps the cancer constants we use on the frontend (e.g. in cancersCovered) to the cancer names that are used in the
   * backend and returned by health profile serializers such as CancerProfileSerializerMixin
   *
   * @returns {object}
   */
  module.constant('cancerToClinicalName', {
    'Breast': clinicalCancerName.BREAST,
    'Ovarian': clinicalCancerName.OVARIAN,
    'Uterine': clinicalCancerName.ENDOMETRIAL,
    'Colorectal': clinicalCancerName.COLORECTAL,
    'Melanoma': clinicalCancerName.MELANOMA,
    'Pancreatic': clinicalCancerName.PANCREATIC,
    'Stomach': clinicalCancerName.GASTRIC,
    'Prostate': clinicalCancerName.PROSTATE,
  })

  /**
   * @ngdoc constant
   * @const cancerGenesCovered
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of genes covered with associated cancers, used in geneTable directive
   *
   * @returns {object}
   */
  module.constant('cancerGenesCovered', (function() {
    const result = []
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      if (gene.interpreted_by.indexOf('hereditary 30') >= 0) {
        result.push(gene)
      }
    }
    // do NOT sort; the order is tweaked for table aesthetics
    return result
  })())

  /**
   * @ngdoc constant
   * @const cardioGenesCovered
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of genes associated with cardio conditions, used in geneTable directive
   *
   * @returns {object}
   */
  module.constant('cardioGenesCovered', (function() {
    const result = []
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      if (gene.interpreted_by.indexOf('cardio 30') >= 0) {
        result.push(gene)
      }
    }
    // do NOT sort; the order is tweaked for table aesthetics
    return result
  })())

  /**
   * @ngdoc constant
   * @const standardCancerGenesCovered
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of genes covered by the CDC10 Color Standard test
   *
   * @returns {object}
   */
  module.constant('standardCancerGenesCovered', (function() {
    const result = []
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      if (gene.interpreted_by.indexOf('hereditary 7') >= 0) {
        result.push(gene)
      }
    }
    return result
  })())

  /**
   * @ngdoc constant
   * @const namedMutation
   * @static
   * @memberof clrConstant
   *
   * @description
   * returns cHGVS nomenclature for commonly named mutations
   *
   * @returns {string}
   */
  module.constant('namedMutation', {
    apc_i1307k: 'chr5.GRCh37:g.112175211T>A',
    brca1_r1699q: 'chr17.GRCh37:g.41215947C>T'
  })

  /**
   * @ngdoc constant
   * @const testTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of test types.
   *
   * @returns {object}
   */
  module.constant('testTypes', {
    brca12: 'brca 1 2',
    breastOvarian19: 'breast ovarian 19',
    wisdom9: 'wisdom 9',
    hereditary30: 'hereditary 30',
    cardio30: 'cardio 30',
    fh3: 'fh 3',
    hereditary7: 'hereditary 7',
    pgxv1: 'pgx v1',
    covid19: 'covid-19',
    covid19Antigen: 'covid-19 antigen',
    aouAcmg59: 'aou acmg 59',
    aouPgxV1: 'aou pgx v1',
    hdr59: 'hdr 59',
  })

  /**
   * @ngdoc constant
   * @const appointmentTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a dictionary of appointment types.
   *
   * @returns {object}
   */
  module.constant('appointmentTypes', {
    default: 'default',
    pgx: 'pgx'
  })

  /**
   * @ngdoc constant
   * @const permissionTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a dictionary of permission types.
   *
   * @returns {object}
   */
  module.constant('permissionTypes', {
    putSamplesOnHold: 'samples.put_sample_on_hold',
    viewUsers: 'users.view_users',
    viewAppointments: 'counseling.view_appointments',
    viewHealthHistory: 'health_profiles.view_healthhistory',
    viewCovidReportsQueue: 'results.can_view_covid_reports_queue',
  })

  /**
   * @ngdoc constant
   * @const clinicalDomains
   * @static
   * @memberof clrConstant
   *
   * @returns {object}
   */
  module.constant('clinicalDomains', {
    'brca 1 2': 'cancer',
    'breast ovarian 19': 'cancer',
    'wisdom 9': 'cancer',
    'hereditary 30': 'cancer',
    'fh 3': 'cardio',
    'cardio 30': 'cardio',
    'hereditary 7': 'cancer',
    'pgx v1': 'medication'
  })

  module.factory('defaultSku', function() { return 'combo 3'})

  // maps company names (in promotional campaigns) to their logo name
  module.constant('corporateLogos', {
    "BlackRock": "blackrock",
    "NetSuite": "netsuite",
    "Nvidia": "nvidia",
    "Tribune Media": "tribuneMedia",
    "Snap": "snap",
    "loanDepot": "loan_depot"
  })

  /**
   * @ngdoc constant
   * @const testOutcome
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of possible test outcomes.
   *
   * @returns {integer}
   */
  module.constant('testOutcome', {
    negative: 'negative',
    non_reportable: 'non-reportable',
    positive: 'positive'
  })

  /**
   * @ngdoc constant
   * @const classification
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of possible variant classification.
   *
   * @returns {integer}
   */
  module.constant('classification', {
    p: 'Pathogenic',
    lp: 'Likely Pathogenic',
    ras: 'Significant Risk Allele',
    vus: 'Variant of Uncertain Significance',
    lb: 'Likely Benign',
    b: 'Benign',
    rai: 'Insignificant Risk Allele',
  })

  /**
   * @ngdoc constant
   * @const zygosity
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of possible variant zygosity.
   *
   * @returns {integer}
   */
  module.constant('zygosity', {
    heterozygous: 'Heterozygous',
    homozygous: 'Homozygous'
  })

  /**
   * @ngdoc constant
   * @name device
   * @memberof clrConstant
   *
   * @description
   *
   * `device` provides device/browser checks to handle
   * cross-browser or responsive logic.
   */

  /**
   * @class Device
   */
  const Device = {}

  /**
   * @function isMsie
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the browser is IE
   *
   * @returns {boolean}
   */
  Device.isMsie = parseInt((/msie (\d+)/.exec(window.navigator.userAgent.toLowerCase()) || [])[1], 10)

  /**
   * @function isWindows
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the user's OS is Windows
   *
   * @returns {boolean}
   */
  Device.isWindows = navigator.platform.indexOf('Win') > -1

  /**
   * @function isAndroid
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the user's OS is Android
   *
   * @returns {boolean}
   */
  Device.isAndroid = /(android)/i.test(navigator.userAgent)

  /**
   * @function isSafari
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the browser is Safari
   *
   * @see https://developer.mozilla.org/en-US/docs/Browser_detection_using_the_user_agent
   *
   * @returns {boolean}
   */
  Device.isSafari = window.navigator.userAgent.match(/Safari\//) != null
                    && window.navigator.userAgent.match(/Chrom(e|ium)\//) == null

  /**
   * @function canPlayHls
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the browser can play HLS (application/x-mpegURL mimetype) files
   *
   * @returns {boolean}
   */
  Device.canPlayHls = typeof document.createElement('video').canPlayType == 'undefined' ? false : document.createElement('video').canPlayType('application/vnd.apple.mpegURL')

  /**
   * @function isHighDensity
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the device is "High Density", i.e. Apple Retina (2x),
   * or various Android resolutions above 1.3x.
   * Reference: http://stackoverflow.com/questions/19689715/what-is-the-best-way-to-detect-retina-support-on-a-device-using-javascript
   *
   * @returns {boolean}
   */
  Device.isHighDensity = window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches) || window.devicePixelRatio && window.devicePixelRatio > 1.3

  /**
   * @function isIos
   * @static
   * @memberof clrConstant.device
   *
   * @description
   * Checks if the device is running iOS
   * Reference: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
   *
   * @returns {boolean}
   */
  Device.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  module.constant('device', {
    isMsie: Device.isMsie,
    isWindows: Device.isWindows,
    isAndroid: Device.isAndroid,
    isIos: Device.isIos,
    isSafari: Device.isSafari,
    canPlayHls: Device.canPlayHls,
    isHighDensity: Device.isHighDensity,
  })

  const skipAuthSync = extractValueFromElem('skip-auth-sync', false)
  module.constant('skipAuthSync', skipAuthSync)

  function extractValueFromElem(elemId, defaultVal) {
    const elem = document.getElementById(elemId)
    return elem ? elem.value : defaultVal
  }

  function convertCentsToDollars(cents) {
    return parseInt(cents) / 100
  }

  const entryPriceDollars = convertCentsToDollars(extractValueFromElem('entry-price-cents', '0'));
  const entryProviderServicePriceDollars = convertCentsToDollars(extractValueFromElem('entry-provider-service-price-cents', '0'));
  const standalonePriceDollars = convertCentsToDollars(extractValueFromElem('standalone-price-cents', '0'));
  const standaloneProviderServicePriceDollars = convertCentsToDollars(extractValueFromElem('standalone-provider-service-price-cents', '0'));
  const comboPriceDollars = convertCentsToDollars(extractValueFromElem('combo-price-cents', '0'));
  const comboBCAMPriceDollars = convertCentsToDollars(extractValueFromElem('combo-bcam-price-cents', '0'));
  const standardPriceDollars = convertCentsToDollars(extractValueFromElem('standard-price-cents', '0'));
  const comboProviderServicePriceDollars = convertCentsToDollars(extractValueFromElem('combo-provider-service-price-cents', '0'));
  const hereditary30PriceDollars = convertCentsToDollars(extractValueFromElem('hereditary-30-price-cents', '0'));
  const hereditary30ProviderServicePriceDollars = convertCentsToDollars(extractValueFromElem('hereditary-30-provider-service-price-cents', '0'));
  const modifierAddonPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-addon-price-cents', '0'));
  const modifierBrcaUpgradePriceDollars = convertCentsToDollars(extractValueFromElem('modifier-entry-upgrade-price-cents', '0'));
  const modifierFhUpgradePriceDollars = convertCentsToDollars(extractValueFromElem('modifier-entry-upgrade-price-cents', '0'));
  const modifierEntryMultiUnitPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-entry-multi-unit-price-cents', '0'));
  const modifierStandaloneMultiUnitPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-standalone-multi-unit-price-cents', '0'));
  const modifierComboMultiUnitPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-combo-multi-unit-price-cents', '0'));
  const modifierOCComboMultiUnitPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-oc-combo-multi-unit-price-cents', '0'));
  const modifierHereditary30MultiUnitPriceDollars = convertCentsToDollars(extractValueFromElem('modifier-hereditary-30-multi-unit-price-cents', '0'))

  // productName should conform with what we use in our `en.json` file
  module.constant('sku', {
    'brca 1 2': {
      name: 'brca 1 2',
      productName: 'BRCA1/2 Genetic Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/brca-genetic-test',
      priceDollars: standardPriceDollars,
      providerServicePriceDollars: entryProviderServicePriceDollars,
      testTypes: ['brca 1 2'],
      video: 'pretestBrca12',
      videoScriptUrl: '/video/about-genetic-testing-script/brca-test',
      isEntry: true
    },
    'wisdom 9': {
      name: 'wisdom 9',
      productName: 'Wisdom Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/',
      priceDollars: 0,
      providerServicePriceDollars: standaloneProviderServicePriceDollars,
      testTypes: ['wisdom 9'],
      video: '',
      videoScriptUrl: '/',
      isEntry: false
    },
    'breast ovarian 19': {
      name: 'breast ovarian 19',
      productName: 'Breast & Ovarian Cancer Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '',
      priceDollars: standalonePriceDollars,
      providerServicePriceDollars: standaloneProviderServicePriceDollars,
      testTypes: ['breast ovarian 19'],
      video: 'pretestBreastOvarian19',
      videoScriptUrl: '/video/about-genetic-testing-script/breast-ovarian-cancer-test',
      isEntry: false
    },
    'hereditary 7': {
      name: 'hereditary 7',
      productName: 'BRCA and Lynch Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '',
      priceDollars: null, // SKU not sold externally
      providerServicePriceDollars: null,
      testTypes: ['hereditary 7'],
      video: 'preCounselCore10', // Use core 10 video
      videoScriptUrl: '/video/about-genetic-testing-script/tier-1-hereditary-conditions-test',
      isEntry: false
    },
    'hereditary 30': {
      name: 'hereditary 30',
      productName: 'Hereditary Cancer Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/hereditary-cancer-genetic-test',
      priceDollars: hereditary30PriceDollars,
      providerServicePriceDollars: hereditary30ProviderServicePriceDollars,
      testTypes: ['hereditary 30'],
      video: 'pretestHereditary30',
      videoScriptUrl: '/video/about-genetic-testing-script/hereditary-cancer-test',
      isEntry: false
    },
    'fh 3': {
      name: 'fh 3',
      productName: 'Hereditary High Cholesterol Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/hereditary-high-cholesterol-genetic-test',
      priceDollars: entryPriceDollars,
      providerServicePriceDollars: entryProviderServicePriceDollars,
      testTypes: ['fh 3'],
      video: 'preCounselFh',
      videoScriptUrl: '/video/about-genetic-testing-script/hereditary-high-cholesterol-test',
      isEntry: true
    },
    'cardio 30': {
      name: 'cardio 30',
      productName: 'Hereditary Heart Health Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/hereditary-heart-health-genetic-test',
      priceDollars: standalonePriceDollars,
      providerServicePriceDollars: standaloneProviderServicePriceDollars,
      testTypes: ['cardio 30'],
      video: 'preCounselCardio30',
      videoScriptUrl: '/video/about-genetic-testing-script/hereditary-heart-health-test',
      isEntry: false
    },
    // TODO (PGx): Update values
    'pgx v1': {
      name: 'pgx v1',
      productName: 'Medication Response Genetic Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: 'placeholder',
      priceDollars: entryPriceDollars,
      providerServicePriceDollars: entryProviderServicePriceDollars,
      testTypes: ['pgx v1'],
      video: null, // pgx has no video
      videoScriptUrl: null,
      isEntry: true
    },
    'combo 1': {
      name: 'combo 1',
      productName: 'Color tests',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/color-genetic-tests',
      priceDollars: comboPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['hereditary 30', 'fh 3'],
      video: 'preCounselCombo',
      videoScriptUrl: '/video/about-genetic-testing-script/the-color-test',
      isEntry: false
    },
    'combo 2': {
      name: 'combo 2',
      productName: 'Color tests',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/color-genetic-tests',
      priceDollars: comboPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['hereditary 30', 'cardio 30'],
      video: 'preCounselCombo2',
      videoScriptUrl: '/video/about-genetic-testing-script/the-color-test',
      isEntry: false
    },
    // TODO (CORE10): Update values
    'core 10': {
      name: 'core 10',
      productName: 'Tier 1 Hereditary Conditions Test',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/color-genetic-tests',
      priceDollars: 199,
      providerServicePriceDollars: 13,
      testTypes: ['fh 3', 'hereditary 7'],
      video: 'preCounselCore10',
      videoScriptUrl: '/video/about-genetic-testing-script/tier-1-hereditary-conditions-test',
      isEntry: true
    },
    // TODO [CONSUMER-7278]: Update values
    'combo 3': {
      name: 'combo 3',
      productName: 'Color Extended',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/overview',
      priceDollars: comboBCAMPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['hereditary 30', 'cardio 30', 'pgx v1'],
      video: '',
      videoScriptUrl: '',
      isEntry: false
    },
    'core combo': {
      name: 'core combo',
      productName: 'Color Standard',
      brand: 'Color',
      category: 'Genetic Test',
      url: '/product/color-genetic-tests',
      priceDollars: standardPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['fh 3', 'hereditary 7', 'pgx v1'],
      video: 'preCounselCore10',
      videoScriptUrl: '/video/about-genetic-testing-script/tier-1-hereditary-conditions-test',
      isEntry: false
    },
    'covid-19': {
      name: 'covid-19',
      productName: 'COVID-19 Test',
      brand: 'Color',
      category: 'Covid Test',
      url: '',
      priceDollars: standardPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['covid-19'],
      video: '',
      videoScriptUrl: '',
      isEntry: true
    },
    'covid-19 antigen': {
      name: 'covid-19 antigen',
      productName: 'COVID-19 Antigen Test',
      brand: 'Color',
      category: 'Covid Antigen Test',
      url: '',
      priceDollars: standardPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['covid-19 antigen'],
      video: '',
      videoScriptUrl: '',
      isEntry: true
    },
    'hdr 59': {
      name: 'hdr 59',
      productName: 'Hereditary Disease Risk Test 2.0',
      brand: 'Color',
      category: 'Genetic Test',
      url: '',
      priceDollars: standardPriceDollars,
      providerServicePriceDollars: comboProviderServicePriceDollars,
      testTypes: ['hdr 59'],
      video: '',
      videoScriptUrl: '',
      isEntry: true
    },
  })

  const host_url = extractValueFromElem('www-base-host-url', '')
  const logged_in_host_url = extractValueFromElem('www-logged-in-base-host-url', '')
  module.constant('wwwBaseHostUrl', host_url)
  module.constant('wwwLoggedInBaseHostUrl', logged_in_host_url)
  const prod = host_url.indexOf('www.color.com') > -1 || host_url.indexOf('clinical.color.com') > -1
  module.constant('environment', prod ? 'prod' : 'dev')

  module.constant('standalonePriceDollars', standalonePriceDollars)
  module.constant('standaloneProviderServicePriceDollars', standaloneProviderServicePriceDollars)
  module.constant('modifierAddonPriceDollars', modifierAddonPriceDollars)
  module.constant('modifierBrcaUpgradePriceDollars', modifierBrcaUpgradePriceDollars)
  module.constant('modifierFhUpgradePriceDollars', modifierFhUpgradePriceDollars)
  module.constant('multiUnitModifiersPriceDollars', {
    'brca 1 2': modifierEntryMultiUnitPriceDollars,
    'hereditary 30': modifierHereditary30MultiUnitPriceDollars,
    'fh 3': modifierEntryMultiUnitPriceDollars,
    'cardio 30': modifierStandaloneMultiUnitPriceDollars,
    'combo 1': modifierComboMultiUnitPriceDollars,
    'combo 2': modifierComboMultiUnitPriceDollars,
    'combo 3': modifierOCComboMultiUnitPriceDollars
  })

  // Any test types updated here must have its "available_in_ny" value updated in kit_orders/constants.py
  const blocked = ['fh 5', 'whole exome']
  module.constant('testTypesBlockedInNewYork', blocked)

  // used to convert black state list into full name.
  module.constant('stateFullNames', {'NY': 'New York'})

  module.constant('kioCountries', ['US', 'CA', 'AU', 'IL', 'PR', 'AE', 'NG', 'CM'])

  const euCountries = ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR',
    'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK',
    'FI', 'SE', 'UK']
  module.constant('euCountries', euCountries)

  // tests allowed in the eu
  const testTypesAllowedInEU = ['hereditary 30', 'breast ovarian 19', 'pgx v1', 'cardio 30']
  module.constant('testTypesAllowedInEU', testTypesAllowedInEU)

  // Salesforce WebToCase (for Support/Submit a Request Forms)
  module.constant('salesforceWebToCasePostUrl', extractValueFromElem('salesforce-web-to-case-post-url', ''))
  module.constant('salesforceWebToCaseOrgid', extractValueFromElem('salesforce-web-to-case-orgid', ''))

  module.constant('months', [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])

  module.constant('defaultLanguage', 'en')

  // Provider roles
  const officeManager = ['office_manager', 'Administrative assistant']
  const breastSurgeon = ['breast_surgeon', 'Breast surgeon']
  const colorectalSurgeon = ['colorectal_surgeon', 'Colorectal surgeon']
  const gc = ['gc', 'Genetic counselor']
  const gynOnc = ['gyn_onc', 'GYN oncologist']
  const internist = ['internist', 'Internist/GP']
  const geneticist = ['geneticist', 'Medical geneticist']
  const geneticNursePractitioner = ['genetic_nurse_practitioner', 'Genetic nurse practitioner']
  const medicalOncologist = ['medical_oncologist', 'Medical oncologist']
  const nurse = ['nurse', 'Nurse']
  const nursePractitioner = ['nurse_practitioner', 'Nurse practitioner']
  const ob = ['ob', 'OB/GYN']
  const otherPhysician = ['other_physician', 'Other physician']
  const otherSurgeon = ['other_surgeon', 'Other surgeon']
  const radiologist = ['radiologist', 'Radiologist']
  const surgicalOncologist = ['surgical_oncologist' , 'Surgical oncologist']
  const otherNonPhysician = ['other_non_physician', 'Other non-physician']
  const medicalAssistant = ['medical_assistant', 'Medical assistant']
  const physicianAssistant = ['physician_assistant', 'Physician assistant']
  const urologist = ['urologist', 'Urologist']
  const dermatologist = ['dermatologist', 'Dermatologist']
  const gastroenterologist = ['gastroenterologist', 'Gastroenterologist']
  const radiationOncologist = ['radiation_oncologist', 'Radiation oncologist']
  const cardiologist = ['cardiologist', 'Cardiologist']
  const endocrinologist = ['endocrinologist', 'Endocrinologist']
  const lipidologist = ['lipidologist', 'Lipidologist']
  const other = ['other', 'Unclassified']

  module.factory('externalProviderRoles', function() {
    return [
      officeManager, breastSurgeon, colorectalSurgeon, dermatologist, gastroenterologist, gc, geneticNursePractitioner, gynOnc, internist, medicalAssistant, geneticist,
      medicalOncologist, nurse, nursePractitioner, ob, otherPhysician, otherSurgeon, physicianAssistant, radiationOncologist,
      radiologist, surgicalOncologist, urologist,
      cardiologist, endocrinologist, lipidologist
    ]
  })

  module.constant('internalProviderRoles', [
    officeManager, breastSurgeon, colorectalSurgeon, dermatologist, gastroenterologist, gc, geneticNursePractitioner, gynOnc, internist, medicalAssistant, geneticist,
    medicalOncologist, nurse, nursePractitioner, ob, otherPhysician, otherSurgeon, physicianAssistant, radiationOncologist,
    radiologist, surgicalOncologist, urologist, cardiologist, endocrinologist, lipidologist, otherNonPhysician, other
  ])

  module.constant('npiOptionalRoles', [
    nurse,
    nursePractitioner,
    officeManager,
    gc,
    medicalAssistant
  ])

  module.constant('orderingPhysicianRoles', [
    breastSurgeon,
    internist,
    geneticist,
    medicalOncologist,
    ob,
    otherPhysician,
    radiologist,
    surgicalOncologist,
    colorectalSurgeon,
    gynOnc,
    otherSurgeon,
    urologist,
    dermatologist,
    gastroenterologist,
    radiationOncologist,
    cardiologist,
    endocrinologist,
    lipidologist
  ])

  const heardNews = ['news', 'News']
  const heardColleague = ['colleague', 'Colleague/Friend']
  const heardPublication = ['publication', 'Medical publication']
  const heardEmail = ['email', 'Promotional email from Color']
  const heardConference = ['conference', 'Conference/Event']
  const heardEmployee = ['employee', 'Someone from Color']
  const heardOther = ['other', 'Other']

  module.constant('providerHeardAboutColorThrough', [
    heardColleague, heardConference, heardNews, heardPublication, heardEmail, heardEmployee, heardOther
  ])

  const heardFoF = ['friend_or_family', 'Friend or family member']
  const heardProvider = ['provider', 'Healthcare provider']
  const heardEmployer = ['employer', 'My employer']
  const heardFacebook = ['facebook_ad', 'Facebook ad']
  const heardGoogle = ['google', 'Google search result or ad']
  const heardEvent = ['event', 'An event']
  const heardTV = ['tv', 'TV']
  const heardRadio = ['radio', 'Radio/SiriusXM']
  const heardPodcast = ['podcast', 'Podcast']
  const heardSpotify = ['spotify', 'Spotify']

  function shuffleArray(array) {
    let m = array.length;
    let t;
    let i
    while (m) {
      i = Math.floor(Math.random() * m--)
      t = array[m]
      array[m] = array[i]
      array[i] = t
    }
  }

  const heardAboutColorThrough = [heardFoF, heardProvider, heardEmployer, heardFacebook, heardGoogle, heardEvent, heardTV, heardRadio, heardPodcast, heardSpotify]
  shuffleArray(heardAboutColorThrough)
  heardAboutColorThrough.push(heardOther)

  module.constant('consumerHeardAboutColorThrough', heardAboutColorThrough)

  // Roles that we won't display as options to be primary contact in UI. (not strictly enforced)
  module.constant('nonPrimaryContactRoles', [officeManager[0], medicalAssistant[0]])

  // Low friction healthcare provider pilot such that URL path /care/:key
  // will prefill healthcare provider to the values. CPP-1047
  module.constant('lowFrictionHealthcareProviders', {
    'azcolorectal': {
      first_name: 'Elizabeth',
      last_name: 'McConnell',
      institution_name: 'McConnell Colorectal Center',
      phone_number: '+16022534271',
      fax_number: '+16022534273',
      state: 'AZ',
      city: 'Phoenix',
      formal_name: "Dr Elizabeth McConnell",
      image: "mcconnell",
      provider_id: 1097, // ProviderProfile id in prod to take advantage of H30 upgrade code path.
      is_low_friction: true
    },
    'etkinkramer': {
      first_name: 'Elizabeth',
      last_name: 'Etkin-Kramer',
      institution_name: 'Miami Beach Gynecologist',
      phone_number: '+13056748038',
      fax_number: '+13056748192',
      state: 'FL',
      city: 'Miami Beach',
      formal_name: "Dr Elizabeth Etkin-Kramer",
      image: "",
      provider_id: 2498, // ProviderProfile id
      is_low_friction: true
    },
    'millcreekobgyn': {
      first_name: 'Orly',
      last_name: 'Steinberg',
      institution_name: 'Mill Creek OB/GYN',
      phone_number: '+14256733420',
      fax_number: '+14256733423',
      state: 'WA',
      city: 'Mill Creek',
      formal_name: "Dr. Orly Steinberg",
      image: "millcreek",
      provider_id: 3710, // ProviderProfile id
      is_low_friction: true
    },
    'chesapeakeurology': {
      first_name: 'Benjamin',
      last_name: 'Lowentritt',
      institution_name: 'Chesapeake Urology',
      phone_number: '+14108255454',
      fax_number: '+14108255811',
      state: 'MD',
      city: 'Towson',
      formal_name: "Dr. Benjamin Lowentritt",
      image: "chesapeake",
      provider_id: 3987, // ProviderProfile id
      is_low_friction: true
    },
    'barnabasbreast': {
      first_name: 'Michele',
      last_name: 'Blackwood',
      institution_name: 'Center for Breast Health and Disease Management',
      phone_number: '+19733227020',
      fax_number: '+19733227039',
      state: 'NJ',
      city: 'Livingston',
      formal_name: "Dr. Michele Blackwood",
      image: "barnabasbreast",
      provider_id: 3955, // ProviderProfile id
      is_low_friction: true
    },
    'cwc24': {
      first_name: 'CWC',
      last_name: 'Staff',
      institution_name: "Capital Women's Care - Division 24",
      phone_number: '+13015935595',
      fax_number: '+13017540947',
      state: 'MD',
      city: 'Silver Spring',
      formal_name: "Capital Women's Care - Division 24",
      image: "cwc",
      provider_id: 4108, // ProviderProfile id
      is_low_friction: true
    }
  })

  /**
   * @ngdoc constant
   * @const uploadFileTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an object enumerating valid file types.
   *
   * @returns {object}
   */
  module.constant('uploadFileTypes', {
    geneticCounselingNote: 'genetic_counseling_note',
    geneticTestReport: 'genetic_test_report',
    insuranceCard: 'insurance_card',
    medicalRecords: 'medical_records',
    orderFormOrInformedConsent: 'order_form_or_informed_consent',
    otherPhysicianOrderFile: 'other_physician_order_file',
    physicianOrderFiles: 'physician_order_files'
  })

  /**
   * @ngdoc constant
   * @const CancerGene
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an object enumerating valid cancer genes for diagnosis.
   *
   * @returns {object}
   */
  module.constant('CancerGene', (function() {
    const result = {}
    for (let g = 0; g < masterGeneTable.length; g++) {
      const gene = masterGeneTable[g]
      if (gene.interpreted_by.indexOf('hereditary 30') >= 0) {
        result[gene.name] = [gene.name, gene.name]
      }
    }
    result['Other'] = ['Other', 'Other']
    return result
  })())

  /**
   * @ngdoc constant
   * @const cancerTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Enumerates the lists of different valid cancer types.
   *
   * Returns an object of strings to arrays of strings, where the first
   * element in the array is the user-friendly name and the second element
   * is the expected value on the backend.
   *
   * @returns {object}
   */
  module.constant('cancerTypes', {
    BILIARY_TRACT: ['Biliary Tract Cancer', 'biliary_tract_cancer'],
    BRAIN: ['Brain Cancer', 'brain_cancer'],
    BREAST: ['Breast Cancer', 'breast_cancer'],
    CERVICAL: ['Cervical Cancer', 'cervical_cancer'],
    COLORECTAL: ['Colon / Rectal Cancer', 'colorectal_cancer'],
    KERATOCANTHOMAS: ['Keratocanthomas', 'keratocanthomas'],
    KIDNEY: ['Kidney Cancer', 'kidney_cancer'],
    LEUKEMIA: ['Leukemia', 'leukemia'],
    LIVER: ['Liver Cancer', 'liver_cancer'],
    LUNG: ['Lung Cancer', 'lung_cancer'],
    MELANOMA: ['Melanoma', 'melanoma'],
    OSTEOSARCOMA: ['Osteosarcoma', 'osteosarcoma'],
    OVARIAN: ['Ovarian Cancer', 'ovarian_cancer'],
    PANCREATIC: ['Pancreatic Cancer', 'pancreatic_cancer'],
    PROSTATE: ['Prostate Cancer', 'prostate_cancer'],
    RENAL_PELVIS: ['Renal Pelvis Cancer', 'renal_pelvis_cancer'],
    SEBACEOUS_GLAND_ADENOMAS: ['Sebaceous Gland Adenomas', 'sebaceous_gland_adenomas'],
    SMALL_BOWEL: ['Small Bowel Cancer', 'small_bowel_cancer'],
    SMALL_INTESTINE: ['Small Intestine Cancer', 'small_intestine_cancer'],
    SOFT_TISSUE_SARCOMA: ['Soft Tissue Sarcoma', 'soft_tissue_sarcoma'],
    STOMACH: ['Stomach Cancer', 'stomach_cancer'],
    TESTICULAR: ['Testicular Cancer', 'testicular_cancer'],
    THYROID: ['Thyroid Cancer', 'thyroid_cancer'],
    URETER: ['Ureter Cancer', 'ureter_cancer'],
    UTERINE: ['Uterine (Endometrial) Cancer', 'uterine_cancer'],

    UNKNOWN: ['Unknown', 'unknown']
  })

  /**
   * @ngdoc constant
   * @const criteriaRelationshipTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Enumerates the lists of different valid relationships.
   *
   * Returns an object of strings to arrays of strings, where the first
   * element in the array is the user-friendly name and the second element
   * is the expected value on the backend.
   *
   * @returns {object}
   */
  module.constant('criteriaRelationshipTypes', function() {
    const FEMALE = {
      MOTHER: ['Mother', 'Mother'],
      DAUGHTER: ['Daughter', 'Daughter'],
      SISTER: ['Sister', 'Sister'],
      GRANDMOTHER: ['Grandmother', 'Grandmother'],
      AUNT: ['Aunt', 'Aunt'],
      NIECE: ['Niece', 'Niece'],
      GRANDDAUGHTER: ['Granddaughter', 'Granddaughter'],
      HALF_SISTER: ['Half-sister', 'Half-sister'],
      GREAT_GRANDMOTHER: ['Great grandmother', 'Great grandmother'],
      GREAT_AUNT: ['Great aunt', 'Great aunt'],
      FIRST_COUSIN_FEMALE: ['First cousin, female', 'First cousin, female']
    }

    const MALE = {
      FATHER: ['Father', 'Father'],
      SON: ['Son', 'Son'],
      BROTHER: ['Brother', 'Brother'],
      GRANDFATHER: ['Grandfather', 'Grandfather'],
      UNCLE: ['Uncle', 'Uncle'],
      NEPHEW: ['Nephew', 'Nephew'],
      GRANDSON: ['Grandson', 'Grandson'],
      HALF_BROTHER: ['Half-brother', 'Half-brother'],
      GREAT_GRANDFATHER: ['Great grandfather', 'Great grandfather'],
      GREAT_UNCLE: ['Great uncle', 'Great uncle'],
      FIRST_COUSIN_MALE: ['First cousin, male', 'First cousin, male']
    }

    return {
      FEMALE: FEMALE,
      MALE: MALE,
      ALL: angular.extend({}, FEMALE, MALE)
    }
  }())

  /**
   * @ngdoc constant
   * @const sideOfFamilyTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Enumerates the lists of different valid sides of a family.
   *
   * Returns an object of strings to arrays of strings, where the first
   * element in the array is the user-friendly name and the second element
   * is the expected value on the backend.
   *
   * @returns {object}
   */
  module.constant('sideOfFamilyTypes', {
    MOTHER: ['Mother', 'Mother'],
    FATHER: ['Father', 'Father']
  })

  /**
   * @ngdoc constant
   * @const PhysicianOrderType
   * @static
   * @memberof clrConstant
   *
   * @description
   * Lists the valid types of physician orders.
   *
   * @returns {object}
   */
  module.constant('PhysicianOrderType', {
    SAMPLE_ORDERED: 'sample_ordered',
    SAMPLE_AT_OFFICE: 'sample_at_office',

    // WARN: This type is deprecated; however we still need to support legacy
    //       logic.
    SAMPLE_TAKEN_HOME: 'sample_taken_home'
  })

  /**
   * @ngdoc constant
   * @const ApplicationType
   * @static
   * @memberof clrConstant
   *
   * @description
   * Lists the valid application types.
   *
   * @returns {object}
   */
  module.constant('ApplicationType', {
    CLINICAL: 'clinical',
    WWW: 'www'
  })

  /**
   * @ngdoc constant
   * @const currentDraftPhysicianOrderVersion
   * @static
   * @memberof clrConstant
   *
   * @description
   * Current version of stored draft objects
   * @returns {object}
   */
  module.constant('currentDraftPhysicianOrderVersion', 1)

  /**
   * @ngdoc constant
   * @const PhysicianOrderCancellationReasons
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an array of reasons users can select when cancelling physician order
   *
   * @returns {object}
   */
  module.constant('PhysicianOrderCancellationReasons', [
    {
      text: 'This test is not appropriate for the patient',
      value: 'not_appropriate'
    },{
      text: "I don't recognize the patient",
      value: 'do_not_recognize'
    },{
      text: 'The patient changed their mind',
      value: 'patient_changed_mind'
    },{
      text: 'Other',
      value: 'other'
    }]
  )

  /**
   * @ngdoc constant
   * @const DiabetesTypeOptions
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of options for diabetes type.
   *
   * @returns {object}
   */
  module.constant('DiabetesTypeOptions', [
    {'label': "Type 2 (adult-onset)", 'response': 'Type 2'},
    {'label': "Type 1 (juvenile diabetes)", 'response': 'Type 1'},
    {'label': "Gestational Diabetes (diagnosed during pregnancy)", 'response': 'Gestational Diabetes'},
    {'label': "Prediabetes (borderline diabetes)", 'response': 'Prediabetes'},
    {'label': "I'm not sure", 'response': "I'm not sure"}
  ])

  /**
   * @ngdoc constant
   * @const LdlLevelOptions
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of options for Ldl levels.
   *
   * @returns {object}
   */
  module.constant('LdlLevelOptions', [
    "Less than 154",
    "155-189",
    "190-249",
    "250-329",
    "330 or higher",
    "I'm not sure"
  ])

  /**
   * A list of options for company that performed genetic tests
   * along with corresponding conditions they test for.
   */
  const geneticLabOptions = [
    { name: 'Ambry Genetics', value: 'Ambry Genetics', conditions: ['Cancer', 'Cardio'] },
    { name: 'Baylor', value: 'Baylor', conditions: ['Cardio'] },
    { name: 'Color Genomics', value: 'Color Genomics', conditions: ['Cancer', 'Cardio'] },
    { name: 'Correlagen Diagnostics', value: 'Correlagen Diagnostics', conditions:['Cardio'] },
    { name: 'Counsyl', value: 'Counsyl', conditions: ['Cancer'] },
    { name: 'GeneDX', value: 'GeneDX', conditions: ['Cancer', 'Cardio'] },
    { name: 'Invitae', value: 'Invitae', conditions: ['Cancer', 'Cardio'] },
    { name: 'LabCorp', value: 'LabCorp', conditions: ['Cancer', 'Cardio'] },
    { name: 'Mayo Clinic', value: 'Mayo Clinic', conditions: ['Cardio'] },
    { name: 'Myriad', value: 'Myriad', conditions: ['Cancer'] },
    { name: 'Prevention Genetics', value: 'Prevention Genetics', conditions: ['Cardio'] },
  ]

  /**
   * @ngdoc constant
   * @const geneticLabsByCondition
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a dictionary of lists of the list of labs for each health condition.
   * The key is the condition.
   *
   * @returns {object}
   */
  module.constant('geneticLabsByCondition', (function() {
    const result = {}
    for (let k = 0; k < geneticLabOptions.length; k++) {
      const lab = geneticLabOptions[k]
      for (let j = 0; j < lab.conditions.length; j++) {
        const condition = lab.conditions[j]
        result[condition] = result[condition] || []
        result[condition].push(lab)
      }
    }
    return result
  })())

  /**
   * @ngdoc constant
   * @const kidneyOrLiverProblemOptions
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns a list of options for the liver and kidney question in health history
   *
   * @returns [{object}]
   */
  module.constant('kidneyOrLiverProblemOptions', [
    {fieldName: 'had_recent_kidney_failure', name: 'hadRecentKidneyFailure', label: 'Kidney failure in the last 6 weeks'},
    {fieldName: 'had_recent_kidney_infection', name: 'hadRecentKidneyInfection', label: 'Kidney infection in the last 6 weeks'},
    {fieldName: 'had_kidney_stones', name: 'hadKidneyStones', label: 'Kidney stones'},
    {fieldName: 'had_kidney_dialysis', name: 'hadKidneyDialysis', label: 'Kidney dialysis'},
    {fieldName: 'had_polycystic_kidneys', name: 'hadPolycysticKidneys', label: 'Polycystic kidneys'},
    {fieldName: 'had_liver_cirrhosis', name: 'hadLiverCirrhosis', label: 'Liver cirrhosis'},
    {fieldName: 'had_recent_liver_failure', name: 'hadRecentLiverFailure', label: 'Liver failure in the last 6 weeks'},
    {fieldName: 'had_eclampsia', name: 'hadEclampsia', label: 'Eclampsia/pre-eclampsia (pregnancy-related hypertension which can result in liver or kidney damage)'},
    {fieldName: 'had_lupus_nephritis', name: 'hadLupusNephritis', label: 'Lupus nephritis'},
    {fieldName: 'had_hiv_infection', name: 'hadHIVInfection', label: 'HIV infection (kidney and liver disease may be complications)'},
    {fieldName: 'had_unknown_kidney_or_liver_problem', name: 'hadUnknownKidneyOrLiverProblem', label: 'I\'m not sure'},
  ])

  /**
   * @ngdoc constant
   * @const genderIdentities
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an object containing the gender identity options.
   *
   * @returns {object}
   */
  module.constant('genderIdentities', {
    FEMALE: 'F',
    MALE: 'M',
    NON_BINARY: 'non-binary',
    SELF_DESCRIBED: 'self-described',
    PREFER_NOT_TO_RESPOND: 'prefer not to respond'
  })

  /**
   * @ngdoc constant
   * @const organizationRelationship
   * @static
   * @memberof clrConstant
   *
   * @description
   * Returns an object containing the organization relationship options.
   *
   * @returns {object}
   */
  module.constant('organizationRelationship', {
    EMPLOYEE: 'employee',
    HEALTH_INSURANCE_BENEFICIARY: 'health_insurance_beneficiary',
    NON_HEALTH_INSURANCE_BENEFICIARY: 'non_health_insurance_beneficiary',
    EMPLOYEE_RELATIONSHIPS: ['employee', 'health_insurance_beneficiary', 'non_health_insurance_beneficiary']
  })

  /**
   * @ngdoc constant
   * @const detailedCovidSampleTypes
   * @static
   * @memberof clrConstant
   *
   * @description
   * Mapping of sample type to external name.
   *
   * @returns {object}
   */
  module.constant('detailedCovidSampleTypes', {
    covid_anterior_nares_swab: 'Covid Anterior Nares Swab',
    covid_dry_anterior_nares_swab: 'Covid Dry Anterior Nares Swab',
    covid_nasopharyngeal_swab: 'Covid Nasopharyngeal Swab',
    covid_oropharyngeal_swab: 'Covid Oropharyngeal Swab',
  })
}();
