module.exports = angular
  .module("users", [
    require("monoweb/django_apps/users/js/auth").name,
    require("monoweb/django_apps/users/js/controllers").name,
    require("monoweb/django_apps/users/js/directives").name,
    require("monoweb/django_apps/users/js/filters").name,
    require("monoweb/django_apps/users/js/resource").name,
    require("monoweb/django_apps/users/js/services").name
  ])
  .config(colorRouteProvider => {
    // Accounts
    colorRouteProvider
      .when("/email_confirmation_invalid", {
        redirectTo: "/email-confirmation-invalid"
      })
      .when("/email-confirmation-invalid", {
        templateUrl: "/users/email_confirmation_invalid.html",
        seo: {
          title: "Verify Your Email Address - Color",
          description: ""
        },
        controller: "ResendEmailConfirmationEmailController",
        auth: {
          requireEmailConfirmation: false,
          redirectEmailConfirmed: true
        },
        navigation: {
          blue: true,
          showLeft: false
        }
      });
  });
