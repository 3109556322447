'use strict'

module.exports = function physicianOrderBreadcrumbs(PhysicianOrderNavigationService) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/physician_order_breadcrumbs.html',
    scope: {
      order: '='
    },
    link: function(scope) {
      scope.navService = PhysicianOrderNavigationService
    }
  }
}
