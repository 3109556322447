module.exports = function CounselingDetailController(
  $scope,
  $window,
  $http,
  $filter,
  OrderLog,
  HealthHistory,
  ngToast,
  releaseResultsModal,
  removeReleaseGroupHoldModal,
  releaseGroupResultsModal,
  setReleasePreferencesModal,
  addEventModal,
  clinicalDomains,
  report
) {
  "ngInject";
  const FTP_INELIGIBLE_TEST_TYPES = ["aou acmg 59", "aou pgx v1"];
  $scope.report = report;
  $scope.newNote = {};
  $scope.buttons = [];

  // Define health hub links
  $scope.health_guides = {
    "Breast Cancer": "/insights/breast-cancer",
    "Type 2 Diabetes": "/insights/type-2-diabetes"
  };

  const releaseGroup = report && report.release_group || null
  $scope.releaseGroup = releaseGroup;
  $scope.releaseGroupId = (releaseGroup && releaseGroup.release_group_id) || null
  $scope.activeReleaseGroupHolds = (releaseGroup && releaseGroup.active_holds) || []

  if (report.patient_id) {
    $scope.info = {
      user: {
        first_name: report.patient_id,
        last_name: "Patient ID",
        patient_id: report.patient_id
      }
    };
    getTraits($scope.info.user.patient_id);
    getAncestry($scope.info.user.patient_id);
    getPolygenicScores($scope.info.user.patient_id);
  } else {
    $http
      .get("/api/v1/counseling/info/" + report.id)
      .then(function(res) {
        $scope.info = res.data;
      })
      .then(function() {
        const physician_order_id = $scope.info.physician_order_id;
        if (physician_order_id) {
          $http
            .get("/api/v1/counseling/release_preferences/" + physician_order_id)
            .then(function(res) {
              $scope.releasePreferences = res.data;
            });
        }

        $scope.subtitles = parseHeaderSubtitles($scope.info);

        handleAddButtons()

        $scope.healthHistory = new HealthHistory(
          $scope.info.user.health_profile
        );
        if ($scope.info.user.health_profile) {
          $scope.healthHistoryUrl = "/clinical/v2/support/generic-health-history/show/" + $scope.info.user.health_profile.id + "/summary";
        }
        $scope.clinicalDomain = clinicalDomains[$scope.info.test_type];

        refreshLog();
        getTraits($scope.info.user.patient_id);
        getAncestry($scope.info.user.patient_id);
        getPolygenicScores($scope.info.user.patient_id);
      });
  }

  function handleAddButtons() {
    // Only add buttons if the report is in either of these states
    if ($scope.info.state !== "sent_to_ordering_physician" && $scope.info.state !== "release_ready") {
      return;
    }

    // Report is not in a release group, show default button to release individual report
    if (!$scope.releaseGroupId) {
      $scope.buttons = [
        { text: "Release to Client", action: $scope.releaseResults },
      ];
      return;
    }

    if ($scope.info.state === "sent_to_ordering_physician") {
      // For now, we will not permit GCs to manually bypass the sent_to_ordering_physician
      // state when manually releasing release groups. If this ends up being a blocker,
      // we will revisit
      return;
    }

    // We expect only one genetic counseling hold per release group
    const counselingHolds = $scope.activeReleaseGroupHolds.filter(hold => (hold.hold_type === 'genetic_counseling'))
    const geneticCounselingHold = counselingHolds[0]

    // The release group has a genetic counseling hold that needs to be removed in order to
    // permit releasing. If there are other, non genetic_counseling holds for this
    // release group, the backend will block the user from releasing this group
    // until the other holds have been removed.
    if (geneticCounselingHold) {
      $scope.buttons = [
        { text: "Remove GC Hold From Release Group", action: $scope.openRemoveHoldModal },
      ];
      return;
    }

    // Release all reports in the release group to the client
    $scope.buttons = [
      { text: "Release Group to Client", action: $scope.openReleaseGroupResultsModal }
    ];
  }

  function refreshNotes() {
    // ignore querying notes if no report is associated. e.g. AoU GEM results
    if (!report.id) {
      return;
    }

    $http
      .get("/api/v1/counseling/notes", { params: { report: report.id } })
      .then(function(res) {
        $scope.notes = res.data.results;
      });
  }

  $scope.$on("internalNote:created", refreshNotes);

  function parseHeaderSubtitles(info) {
    const subtitles = [
      [
        `Sex assigned at birth: ${$filter("capitalize")(
          $filter("genderOrSexText")(info.user.gender)
        )}`,
        `Gender Identity: ${$filter("capitalize")(
          $filter("genderOrSexText")(
            info.user.gender_identity,
            info.user.self_described_gender_identity
          )
        )}`,
        `${info.user.age} years old`,
        `born on ${$filter("date")(info.user.birthday)}`
      ].join(", "),
      `Preferred Language: ${$filter("capitalize")(
        info.user.preferred_language
      )}`,
      `P: ${$filter("tel")(info.user.phone_number)}`,
      info.user.email
    ];
    if (!FTP_INELIGIBLE_TEST_TYPES.includes(info.test_type)) {
      subtitles.push(`FTP: ${$filter("booleanToYesNo")(info.is_ftp)}`);
    }
    if (!info.user.city && !info.user.state) {
      subtitles.push(
        `Collected at: ${info.collection_state}, ${$filter("name")(
          info.collection_country
        )}`
      );
    } else {
      subtitles.push(
        `Contact Address: ${info.user.city}, ${info.user.state}, ${$filter(
          "name"
        )(info.user.country)}`
      );
    }

    const shipping_address = addressStr(info.shipping_address);
    if (shipping_address) {
      subtitles.push(`Shipped to: ${shipping_address}`);
    }

    return subtitles;
  }

  function addressStr(address) {
    if (!address) {
      return "";
    }
    const addressStrings = [address.line1];
    if (address.line2) {
      addressStrings.push(address.line2);
    }
    addressStrings.push(
      [address.city, address.state, address.postal_code].join(" ")
    );
    addressStrings.push(address.country);
    return addressStrings.join(", ");
  }

  function getTraits(patient_id) {
    $http
      .get("/api/v1/trait_outcomes", {
        cache: true,
        params: { patient_id: patient_id }
      })
      .then(function(res) {
        if (Object.keys(res.data).length != 0) {
          $scope.traits = res.data;
        }
      });
  }

  function getAncestry(patient_id) {
    $http
      .get("/api/v1/ancestry", {
        cache: true,
        params: { patient_id: patient_id }
      })
      .then(function(res) {
        if (Object.keys(res.data).length != 0) {
          $scope.ancestry = res.data;
        }
      });
  }

  function getPolygenicScores(patient_id) {
    $http
      .get("/api/v1/polygenic_scores", {
        cache: true,
        params: { patient_id: patient_id }
      })
      .then(function(res) {
        if (Object.keys(res.data).length != 0) {
          $scope.polygenic_scores = res.data;
        }
      });
  }

  function refreshLog() {
    if ($scope.info.order_number) {
      OrderLog.get({
        kit_order__order_number: $scope.info.order_number,
        ordering: "-event_time"
      }).$promise.then(function(res) {
        $scope.logs = res.results;
      });
    }
  }

  refreshNotes();

  $scope.releaseResults = function() {
    releaseResultsModal.open(report.id).result.then(function() {
      $scope.info.state = "released";
      ngToast.create("Results successfully released to client");
      refreshLog();
    });
  };

  $scope.openRemoveHoldModal = function() {
    const holds = $scope.activeReleaseGroupHolds.filter(hold => (hold.hold_type === 'genetic_counseling'))

    // We expect only one genetic counseling hold per release group
    const geneticCounselingHold = holds[0]

    removeReleaseGroupHoldModal.open($scope.releaseGroup, geneticCounselingHold).result.then(function() {
      // refresh page so the "Release Group to Client" button shows up
      // TODO: Refresh the report object so we don't need to refresh the page
      window.location.reload()
    });
  };

  $scope.openReleaseGroupResultsModal = function() {
    const releaseGroupId = $scope.releaseGroupId;
    releaseGroupResultsModal.open(releaseGroupId).result.then(function() {
      $scope.info.state = "released";
      ngToast.create(`Released all results to client for release group ${releaseGroupId}`);
      refreshLog();
    });
  };

  $scope.setReleasePreferences = function() {
    setReleasePreferencesModal.open($scope.releasePreferences);
  };

  $scope.addEvent = function() {
    addEventModal.open(report.id).result.then(refreshLog);
  };

  $scope.addNewNote = function() {
    $scope.addingNote = true;
    const data = { report: report.id, text: $scope.newNote.text };
    $http
      .post("/api/v1/counseling/notes", data)
      .then(function() {
        $scope.newNote = {};
        refreshNotes();
      })
      .finally(function() {
        $scope.addingNote = false;
      });
  };

  $scope.showBenignVariants = false;

  $scope.viewClassification = function(classification) {
    $window.open("/variant_classifications/" + classification.id + "/show");
  };

  $scope.isEmpty = object => angular.equals(object, {});
};
