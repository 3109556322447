/**
 * @prettier
 */

module.exports = function reportTopNavigation() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_top_navigation.html',
    replace: true,
    scope: {
      report: '=',
      reportContent: '=',
    },
  };
};
