module.exports = function WisdomActivateModal($modal) {
  'ngInject'
  return {
    open: function(state) {
      return $modal.open({
        templateUrl: '/kit_registrations/_modal-go_to_wisdom.html',
        controller: function($scope, $modalInstance, $location) {
          'ngInject'
          $scope.goToWisdom = function() {
            $location.path('/wisdomstudy')
            $modalInstance.close()
          }
        },
        size: 'sm'
      })
    }
  }
}
