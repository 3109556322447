// ReportWrapperV1Controller is used in admin to show the old v1 report,
// unless it does not even exist.
module.exports = function ReportWrapperV1Controller($scope, report, isPDF, $translatePartialLoader) {
  'ngInject'
  $scope.report = report;
  $scope.isPDF = isPDF;

  $translatePartialLoader.addPart('report')

  // in the near future, generated_content will be empty for v2 reports.
  if (!report.generated_content) {
    $scope.showV2 = true
    $scope.isFH3 = false
  } else {
    $scope.showV2 = false
    $scope.isFH3 = report.generated_content.template && report.generated_content.template.test_type == 'fh 3';
  }

  $scope.showShareWithProviderSection = true;
}
