module.exports = angular.module('userResource', ['clrResource'])
  .constant('sessionAPIEndPoint', '/api/v1/sessions')

  .factory('Account', function(resource) {
    const Account = resource(
      '/api/v1/accounts/:id',
      { id: '@id', format: 'json' }
    )
    Account.prototype.hasAnyPermission = function(permissions){
      return permissions.some(permission => {
        if (this.permissions) {
          return this.permissions[permission]
        }
      })
    }
    Account.prototype.hasAllPermissions = function(permissions){
      return permissions.every(permission => {
        if (this.permissions) {
          return this.permissions[permission]
        }
      })
    }
    Account.prototype.hasOnlyCovidTests = function() {
      if (!this.test_types || !this.test_types.length) {
        // COVID users cannot authenticate if they have no tests
        return false;
      }
      return this.test_types.every(testType => testType === 'covid-19')
    }

    return Account
  })

  .factory('Address', function(resource) {
    return resource(
      '/api/v1/addresses/:id',
      { id: '@id', format: 'json' }
    )
  })

  .factory('PasswordReset', function(resource) {
    return resource(
      '/api/v1/password_resets',
      { format: 'json' }
    )
  })

  .factory('EmailConfirmationResend', function(resource) {
    return resource(
      '/api/v1/email_confirmation_resends',
      { format: 'json' }
    )
  })

  .factory('EmailUpdateConfirmationResend', function(resource) {
    return resource(
      '/api/v1/email_update_confirmation_resends',
      { format: 'json' }
    )
  })

  .factory('PasswordResetConfirmation', function(resource) {
    return resource(
      '/api/v1/password_reset_confirmations',
      { format: 'json' }
    )
  })

  .factory('IdentityVerification', function(resource) {
    return resource(
      '/api/v1/identity_verification',
      { format: 'json' }
    )
  })
