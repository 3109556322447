/**
 * @prettier
 */

module.exports = function ProviderPlatformSharedOrderController(
  $rootScope,
  $scope,
  $location,
  $routeParams,
  $translate,
  $http,
  $window,
  SharedOrder,
  ngToast,
  locationSkipReload,
  showMedicationPromo
) {
  'ngInject';

  this.showPgxBanner = false;

  this.loadSharedOrder = function() {
    SharedOrder.get({ order_number: $routeParams.order_number })
      .$promise.then(order => {
        this.order = order;
        this.resultIsAvailable = order.status.name === 'results_available';
        if (order.test_type === 'pgx v1' && this.resultIsAvailable) {
          if (showMedicationPromo()) {
            const PgxOnboardingEventLogAPI =
              '/api/v1/event_log?event_type=api:Tutorial Finished: PgxOnboarding';
            $http.get(PgxOnboardingEventLogAPI).then(resp => {
              if (resp.data.count === 0) {
                this.showPgxBanner = true;
              }
            });
          }
        }
      })
      .catch(response => {
        if (response.status == 404) {
          $location.path('/providers/home');
          ngToast.create({
            className: 'error',
            content: 'Order not available to this account',
          });
        }
      });
  };

  this.loadSharedOrder();

  this.url = $location.path();

  this.isPath = path => {
    return this.url.indexOf(path) >= 0;
  };

  this.goToPath = url => {
    url = `${url}${this.order.order_number}`;
    locationSkipReload(url);
    this.url = url;
  };

  this.PATHS = {
    DETAILS: '/providers/shared_order/details/',
    RESULTS: '/providers/shared_order/results/',
  };
};
