'use strict'

module.exports = function($filter, $translate) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/guidelines.html',
    replace: true,
    scope: {
      reportContent: '='
    },
    link: function(scope) {
      scope.introReferences = []
      scope.screeningGuidelines = $filter('fhGuidelines')(scope.reportContent).map(function(guideline) {
        const res = { references: guideline.references }
        // Translates guideline content
        $translate([guideline.phenotype, guideline.content]).then(function(translations) {
          res.phenotype = translations[guideline.phenotype]
          res.content = translations[guideline.content]
        })
        if (guideline.phenotype_sub) {
          $translate(guideline.phenotype_sub).then(function(translation) {
            res.phenotype_sub = translation
          })
        }
        return res
      })

      if (scope.reportContent.is_negative) {
        scope.intro = 'FH_REPORT.GUIDELINES.INTRO.NEGATIVE'
      } else if (scope.reportContent.is_positive) {
        const mutations = $filter('pathogenicMutations')(scope.reportContent.mutations)
        const genes = scope.reportContent.genes_with_pathogenic_mutations
        if (genes.length > 1) {
          throw new Error("Only single gene is currently supported.")
        }
        const allelicity = scope.reportContent.allelicity_by_gene[genes[0]]
        if (mutations.length == 1) {
          const mutation = mutations[0]
          if (mutation.zygosity == "Heterozygous") {
            scope.intro = 'FH_REPORT.GUIDELINES.INTRO.POSITIVE_HET'
          } else {
            scope.intro = 'FH_REPORT.GUIDELINES.INTRO.POSITIVE_HOM'
          }
        } else if (!allelicity) {
          scope.intro = 'FH_REPORT.GUIDELINES.INTRO.POSITIVE_UNKNOWN_PHASE'
        } else {
          throw new Error("Only single mutation or compund het unknown phase is currently supported.")
        }
      }
    }
  }
}
