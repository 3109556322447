/**
 * @prettier
 */

module.exports = function($filter, testTypes, reportGenericScreeningGuidelines) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/reportOtherScreeningGuidelines.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.showCard =
        scope.reportContent.is_positive &&
        scope.reportContent.template.test_type == testTypes.hereditary30;
      scope.guidelines = [];
      // Other guidelines content in this doc: https://docs.google.com/document/d/1OudkWQkx5YabGC7PBvTGq7VF9V5M9aOxni7XEuH5tVY/
      const otherGuidelines = reportGenericScreeningGuidelines(scope.reportContent.render_version);
      const genderSingular = scope.reportContent.gender == 'F' ? 'woman' : 'man';
      const genderPlural = scope.reportContent.gender == 'F' ? 'women' : 'men';
      if (scope.showCard) {
        scope.intro =
          'In addition to the screening guidelines provided related to cancers that are increased by ' +
          $filter('geneArticle')(scope.reportContent.genes_with_pathogenic_mutations) +
          ' mutation, below are guidelines for ' +
          genderPlural +
          ' who have the same cancer risk as the average US ' +
          genderSingular +
          '. Your healthcare provider may use these American Cancer Society and NCCN Guidelines® in addition to those listed in the Next Steps section of your report to help create a customized screening plan for you.';
        angular.forEach(otherGuidelines, function(guideline) {
          if (guideline.gender ? scope.reportContent.gender == guideline.gender : true) {
            if (
              !$filter('screeningGuidelinesContainPhenotype')(
                scope.reportContent.template.screening_guidelines,
                guideline.phenotype
              )
            ) {
              scope.guidelines.push(guideline);
            }
          }
        });
        if (scope.guidelines.length == 0) {
          scope.showCard = false;
        }
      }
    },
  };
};
