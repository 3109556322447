"use strict"

/**
 * Creates a horizontal bar element of clrHozChart directive
 */
module.exports = function($filter) {
  "ngInject"

  return {
    restrict: 'A',
    replace: true,
    require: '^clrHozChart',
    templateUrl: '/directives/clrHozChartRow.html',
    link: function(scope, elem, attrs, ctrl) {

      // Handle shifting label to right of bar chart if < 20%
      const barWidth = parseInt(scope.data.barWidth.split('%')[0])
      if (barWidth < 20) {
        scope.shiftLabel = true
      }

      // 'cancer' chartStyle specific tweaks
      if (scope.chartStyle == 'cancer') {
        // Add shifting baseline/changing titles for
        elem.bind('mouseover', function() {
          ctrl.shiftBaselineGuide(scope.baselineRisks[scope.data.riskAge])
          ctrl.setTitle(scope.phenotype, scope.baselineRisks[scope.data.riskAge], scope.data.riskAge, true)
        }).bind('mouseleave', function() {
          ctrl.shiftBaselineGuide(scope.defaultBaselineGuide)
          ctrl.setTitle(scope.phenotype, scope.defaultBaselineGuide, scope.defaultBaselineRiskAge, true)
        })
      }

      // Handle elevated risks without a value, set to baseline of highest age
      if ($filter('isElevated')(scope.data.riskValue) && scope.data.riskValue.indexOf('%') == -1) {
        scope.data.barWidth = $filter('barChartHeight')(scope.baselineRisks[scope.defaultBaselineRiskAge]) + '%'
      }

    }
  }
}
