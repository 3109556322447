module.exports = function FaxListController(
  $scope, $routeParams, $timeout, ngToast,
  resourcePaginator, FaxLog, retryFaxModal) {
  'ngInject'

  $scope.states = ['all', 'requested', 'completed', 'failed']
  $scope.stateTitles = {
    'all': 'All',
    'requested': 'Pending',
    'completed': 'Completed',
    'failed': 'Failed'
  }
  const state = $routeParams.state
  $scope.state = state
  $scope.showCompleted = $scope.state == 'completed' || $scope.state == 'all'
  $scope.showResolved = $scope.state == 'failed'

  $scope.retry = function(faxLog) {
    retryFaxModal.open(faxLog).result
      .then(function() {
        ngToast.create("Fax retry requested.")
      })
  }

  $scope.faxLogs = initializeFaxLogs()

  function initializeFaxLogs(search) {
    const queryParams = { ordering: '-created_at' }
    if (state != 'all') {
      queryParams.state = state
    }
    if (search && search.sampleId) {
      queryParams.kit_barcode = search.sampleId
    }
    return resourcePaginator(new FaxLog(), queryParams)
  }

  let searchPromise
  $scope.search = {}
  $scope.$watch('search', function(newValue, oldValue) {
    // Skip initial watch trigger.
    if (angular.isUndefined(oldValue) || newValue == oldValue) {
      return
    }
    if (searchPromise) {
      $timeout.cancel(searchPromise)
    }
    searchPromise = $timeout(function() {
      $scope.faxLogs = initializeFaxLogs(newValue)
      $scope.faxLogs.getNextPage()
      searchPromise = undefined
    }, 1000)
  }, true)
};
