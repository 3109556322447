module.exports = function editTestRequested(ProviderPlatformModals) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      order: '=',
      endEditing: '='
    },
    templateUrl: '/ordering_physicians/directives/edit_test_requested.html',
    link: function(scope) {
      scope.edited_order = { test_requested: scope.order.test_requested }

      scope.save = function() {
        if (scope.edited_order.test_requested != scope.order.test_requested) {
          ProviderPlatformModals.openEditTestRequestedModal(
            scope.order,
            scope.edited_order.test_requested
          ).result.then(function() {
            scope.endEditing()
          })
        } else {
          scope.endEditing()
        }
      }
    }
  }
}
