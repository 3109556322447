"use strict"

module.exports = function($templateCache, $translate) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      name: '@?',
      message: '@?',
      string: '@?'
    },
    template: '<i role="tooltip" class="fa fa-question-circle" popup-placement="top-right" popup-overlap="-10" popup-show="{{name}}.html"></i>',
    link: function (scope) {
      if (scope.string) {
        if (!scope.name) {
          scope.name = scope.string.toLowerCase().replace(/\./g, '/')
        }
        $translate(scope.string).then(function(message) {
          $templateCache.put(scope.name + '.html', '<div class="popover-content"><p>' + message + '</p></div>')
        })
      } else {
        $templateCache.put(scope.name + '.html', '<div class="popover-content"><p>' + scope.message + '</p></div>')
      }
    }
  }
}
