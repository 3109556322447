module.exports = function ProviderPlatformReviewOrderController(
  $scope, $location, $anchorScroll, project, auth,
  DetailedPhysicianOrder, ProviderPlatformModals, PhysicianOrderFormData,
  PhysicianOrderFormFlow, PortalPaginator, ngToast,
  PhysicianOrderNavigationService, ApplicationType, waffle, claimStore,
) {
  'ngInject'

  PhysicianOrderNavigationService.setState(PhysicianOrderNavigationService.STATES.REVIEW)
  $scope.order = PhysicianOrderFormData.get()
  $scope.order.require_counseling_if_positive = !$scope.order.not_require_counseling_if_positive
  $scope.order.show_vus_details = !$scope.order.no_vus_details
  $scope.order._submitting = false

  // The current provider.
  $scope.userProvider = project.application === ApplicationType.CLINICAL
    ? undefined
    : auth.currentUser.provider_profile

  $scope.edit = function(anchor) {
    PhysicianOrderFormFlow.goTo('/providers/place-order')
    if (anchor) {
      $location.hash(anchor)
      $anchorScroll()
    }
  }

  $scope.submitOrder = function(order) {
    $scope.order._submitting = true

    if (order.skip_payment) {
      order.shipping_address = null
    }
    order.stripe = null

    new DetailedPhysicianOrder(order).$save()
      .then(function(res) {
        PortalPaginator.refresh()
        PhysicianOrderFormData.refresh()
        claimStore.clearClaim();
        $location.path('/providers/order/log/' + res.requisition_number).search()
      })
      .catch(function(res) {
        $scope.edit('#kit')
        if (res.status == 402) {
          // Error coming from our API/Django, returns what Stripe returns in `error.message`, always a single string from them. DRF returns it as a list of `non_field_errors`
          ProviderPlatformModals.openPaymentErrorModal(res.data.non_field_errors[0])
        } else if (res.error) {
          ProviderPlatformModals.openPaymentErrorModal("<strong>Your payment authorization failed.</strong>")
        } else if (res.message) {
          ngToast.create({className: 'error', content: res.message})
        }
      })
      .finally(function(res) {
        $scope.order._submitting = false
      })
  }
}
