const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="releaseResults")
    modal-title Release All Results to Client?
    modal-body
      | Client will be able to view all results in this current report's release group by signing into their Color account without scheduling further genetic counseling.
      .form-group
        input.form-control(type="text", placeholder="Add a note", ng-model="data.note", required)
    submit-button Release All Results
`

function controller($scope, $modalInstance, $http, releaseGroupId) {
  $scope.modalInstance = $modalInstance
  $scope.data = {}
  $scope.releaseResults = () => {
    const url = `/api/v1/color_hub/release_groups/${releaseGroupId}/release_to_patient`
    return $http.post(url, $scope.data)
  }
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (releaseGroupId) => {
      return $modal.open({
        template: template,
        controller: controller,
        size: 'md',
        resolve: {
          releaseGroupId: () => releaseGroupId
        }
      })
    }
  }
}
