!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name resultService
   *
   * @description
   * The `resultService` provides resources for
   * variant results API.
   */
  const module = angular.module('resultService', ['ngSanitize'])

  /**
   * @ngdoc service
   * @name reportContent
   * @memberof resultService
   */
  module.factory('reportContent', function($sce, $filter, testOutcome) {
    return function(report) {
      let content = angular.copy(report.generated_content)
      // FIXME: message: Cannot read property 'test_type' of null caused by `content.template` being null/undefined
      // Fill content with all cancer survivor properties
      content = angular.extend(content, $filter('isCancerSurvivor')(content.health_profile, content.template.test_type))

      // ADH and LCIS breast biopsy health history, female only
      content.had_breast_biopsy_with_atypical_hyperplasia = $filter('hadBiopsyWithAtypicalHyperplasia')(content.health_profile)
      content.diagnosed_with_lobular_carcinoma_in_situ = $filter('diagnosedWithLcis')(content.health_profile)

      content.is_somatic = $filter('isSomaticReport')(content)

      content.mutations = $filter('sortMutations')(content.mutations)

      content.risks = $filter('risksShaper')(content.risks, content.template.test_type, content.mutations)

      content.genes_with_pathogenic_mutations = $filter('genesWithPathogenicMutations')(content.mutations)

      content.is_positive = content.test_outcome == testOutcome.positive
      content.is_negative = content.test_outcome == testOutcome.negative
      content.is_non_reportable = content.test_outcome == testOutcome.non_reportable

      content.is_updated = report.revisions && report.revisions.length
      content.is_hh_provided = report.is_hh_provided
      content.is_hh_under_review = report.is_hh_under_review

      // For PDF signature
      if (content.reviewer) {
        content.reviewer.signature.svg = $sce.trustAsHtml(content.reviewer.signature.svg)
      }

      return content
    }
  })

  module.factory('reportGenericScreeningGuidelines', require('./services/report_generic_screening_guidelines'))
  module.factory('reportShareFamilyData', require('./services/report_share_family_data'))
}();
