/**
 * @prettier
 */

module.exports = function reportAdditionalFindings($filter, defaultLanguage) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/reportAdditionalFindings.html',
    scope: {
      reportContent: '=',
      language: '@?',
    },
    link: function(scope) {
      if (angular.isUndefined(scope.language)) {
        scope.language = defaultLanguage;
      }

      scope.additionalFindingMutations = $filter('additionalFindingMutations')(
        scope.reportContent.mutations
      );
      scope.showCard = scope.additionalFindingMutations.length;
      if (scope.additionalFindingMutations.length) {
        scope.cnvMutations = [];
        scope.nonCnvMutations = [];
        angular.forEach(scope.additionalFindingMutations, function(mutation) {
          if (mutation.effect == 'CNV') {
            scope.cnvMutations.push(mutation);
          } else {
            scope.nonCnvMutations.push(mutation);
          }
        });
      }
    },
  };
};
