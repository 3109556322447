module.exports = function placeSample(auth, ProviderPlatformModals, detailedCovidSampleTypes, isCovidSample, kioCountries, project) {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      order: '='
    },
    templateUrl: '/ordering_physicians/directives/place_sample.html',
    link: function(scope, element, attrs, formCtrl) {
      scope.form = formCtrl
      if (project.application != 'clinical') {
        scope.userProvider = auth.currentUser.provider_profile
        scope.settings = scope.userProvider.settings
      }
      scope.openBarcodeAccessionModal = ProviderPlatformModals.openBarcodeAccessionModal

      function formatDate(date) {
        return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
      }

      const newSalivaBarcodeMask = "*9999999"
      const bloodBarcodeMask = "*-9999999"
      const covidSwabBarcodeMask = "*-9999999999"
      const flexibleBarcodeMask = "*9-9999-9999-9999"
      const salivaBarcodeMask = "*9-9999-9999-9999"

      const newSalivaAccessionNumberMask = "C99999"
      const defaultAccessionNumberMask = "C-99999"

      scope.mask = flexibleBarcodeMask
      scope.useModelViewValue = true

      // Dynamic adjustment of barcode ui-mask such that blood format will be used
      // if first letter is character while saliva is used when first letter is number
      scope.$watch('order.sample.kit_barcode', function(newValue) {

        if (newValue && newValue.length) {
          if (isNaN(newValue[0])) {
            if (newValue[0] === 'L' || newValue[0] === 'l') {
              scope.mask = newSalivaBarcodeMask
              scope.accessionMask = newSalivaAccessionNumberMask
            } else if (newValue[0] === 'D' || newValue[0] === 'd') {
              scope.mask = covidSwabBarcodeMask
              scope.accessionMask = defaultAccessionNumberMask
            } else {
              scope.mask = bloodBarcodeMask
              scope.accessionMask = defaultAccessionNumberMask
            }
          } else {
            scope.mask = salivaBarcodeMask
            scope.accessionMask = defaultAccessionNumberMask
          }
          isCovidSample.update(newValue)
        }
        // Temporary hack to strip dashes. Submitting bug fix to this line in ui-mask library
        // https://github.com/angular-ui/ui-mask/blob/master/src/mask.js#L125
        // which currently results in useModelViewValue not being able to dynamically be updated
        // from true to false (but false to true works)
        if (newValue && newValue.length == 17) {
          scope.order.sample.kit_barcode = newValue.replace(/-/g, '')
        }
      })

      scope.postBlurCommitValue = function() {
        // Commit values on blur for additional validation from pasting events
        scope.order.sample.kit_barcode = scope.form.kitBarcode.$viewValue
      }

      scope.setCollectionDateToToday = function() {
        scope.order.sample.collection_date = formatDate(new Date())
      }

      scope.isCovidSample = isCovidSample
      scope.covidSampleTypes = Object.keys(detailedCovidSampleTypes).map(sampleType => {
        return {
          sampleType,
          externalName: detailedCovidSampleTypes[sampleType]
        }
      })

    }
  }
}
