'use strict'

module.exports = function icd10CmCodeSelector(cancerTypes) {
  'ngInject'

  const BILIARY_TRACT_OPTIONS = require('../../fixtures/icd_codes/biliary_tract.json')
  const BRAIN_OPTIONS = require('../../fixtures/icd_codes/brain.json')
  const BREAST_OPTIONS = require('../../fixtures/icd_codes/breast.json')
  const CERVICAL_OPTIONS = require('../../fixtures/icd_codes/cervical.json')
  const COLORECTAL_OPTIONS = require('../../fixtures/icd_codes/colorectal.json')
  const KERATOCANTHOMAS_OPTIONS = require('../../fixtures/icd_codes/keratocanthomas.json')
  const KIDNEY_OPTIONS = require('../../fixtures/icd_codes/kidney.json')
  const LEUKEMIA_OPTIONS = require('../../fixtures/icd_codes/leukemia.json')
  const LIVER_OPTIONS = require('../../fixtures/icd_codes/liver.json')
  const LUNG_OPTIONS = require('../../fixtures/icd_codes/lung.json')
  const MELANOMA_OPTIONS = require('../../fixtures/icd_codes/melanoma.json')
  const OSTEOSARCOMA_OPTIONS = require('../../fixtures/icd_codes/osteosarcoma.json')
  const OVARIAN_OPTIONS = require('../../fixtures/icd_codes/ovarian.json')
  const PANCREATIC_OPTIONS = require('../../fixtures/icd_codes/pancreatic.json')
  const PROSTATE_OPTIONS = require('../../fixtures/icd_codes/prostate.json')
  const RENAL_PELVIS_OPTIONS = require('../../fixtures/icd_codes/renal_pelvis.json')
  const SEBACEOUS_GLAND_ADENOMAS_OPTIONS = require('../../fixtures/icd_codes/sebaceous_gland_adenomas.json')
  const SMALL_BOWEL_OPTIONS = require('../../fixtures/icd_codes/small_bowel.json')
  const SMALL_INTESTINE_OPTIONS = require('../../fixtures/icd_codes/small_intestine.json')
  const SOFT_TISSUE_SARCOMA_OPTIONS = require('../../fixtures/icd_codes/soft_tissue_sarcoma.json')
  const STOMACH_OPTIONS = require('../../fixtures/icd_codes/stomach.json')
  const TESTICULAR_OPTIONS = require('../../fixtures/icd_codes/testicular.json')
  const THYROID_OPTIONS = require('../../fixtures/icd_codes/thyroid.json')
  const URETER_OPTIONS = require('../../fixtures/icd_codes/ureter.json')
  const UTERINE_OPTIONS = require('../../fixtures/icd_codes/uterine.json')
  const FAMILY_OPTIONS = require('../../fixtures/icd_codes/family.json')

  return {
    restrict: 'AE',
    templateUrl: '/ordering_physicians/directives/icd_10_cm_code_selector.html',
    scope: {
      diagnosis: '=',
      isPatient: '=',
      required: '=?'
    },
    require: '^form',
    link: function(scope, elem) {
      if (!scope.diagnosis.icd_10_cm_codes) {
        scope.diagnosis.icd_10_cm_codes = []
      }

      scope.cancerTypeToOptions = {
        biliary_tract_cancer: BILIARY_TRACT_OPTIONS,
        brain_cancer: BRAIN_OPTIONS,
        breast_cancer: BREAST_OPTIONS,
        cervical_cancer: CERVICAL_OPTIONS,
        colorectal_cancer: COLORECTAL_OPTIONS,
        kidney_cancer: KIDNEY_OPTIONS,
        leukemia: LEUKEMIA_OPTIONS,
        liver_cancer: LIVER_OPTIONS,
        lung_cancer: LUNG_OPTIONS,
        melanoma: MELANOMA_OPTIONS,
        osteosarcoma: OSTEOSARCOMA_OPTIONS,
        ovarian_cancer: OVARIAN_OPTIONS,
        pancreatic_cancer: PANCREATIC_OPTIONS,
        prostate_cancer: PROSTATE_OPTIONS,
        renal_pelvis_cancer: RENAL_PELVIS_OPTIONS,
        sebaceous_gland_adenomas: SEBACEOUS_GLAND_ADENOMAS_OPTIONS,
        small_bowel_cancer: SMALL_BOWEL_OPTIONS,
        small_intestine: SMALL_INTESTINE_OPTIONS,
        soft_tissue_sarcoma: SOFT_TISSUE_SARCOMA_OPTIONS,
        stomach_cancer: STOMACH_OPTIONS,
        testicular_cancer: TESTICULAR_OPTIONS,
        thyroid_cancer: THYROID_OPTIONS,
        ureter: URETER_OPTIONS,
        uterine_cancer: UTERINE_OPTIONS,
        unknown: [],
        undefined: []
      }

      scope.familyOptions = FAMILY_OPTIONS

      /**
       * Users are allowed to input any code they would like.
       */
      scope.createNewCode = function(code) {
        return {
          name: code,
          description: ''
        }
      }

      /**
       * ui-select does not have an explicit API for deselecting an option in
       * the choices directive, but thankfully it gives us enough primitives to
       * be able to construct this ourselves.
       *
       * We only need to have a handler for deselection, because the default
       * on-click behavior is selection.
       */
      scope.deselect = function($event, $selectMultiple, $select, option) {
        for (let i = 0; i < $select.selected.length; i++) {
          const selectedOption = $select.selected[i]
          if (option === selectedOption) {
            $selectMultiple.removeChoice(i)

            // NOTE: This is required so the click does not subsequently
            //       trigger a reselection of the same option.
            $event.stopPropagation()

            return
          }
        }
      }
    }
  }
}
