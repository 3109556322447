'use strict'

module.exports = function() {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/connect_with_others.html',
    replace: true
  }
}
