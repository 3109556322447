function ProviderReleaseResultsForPhysicianOrderModalController(
  $scope,
  $modalInstance,
  $http,
  $route,
  ngToast,
  requisition_number,
  testTypesWithReleasableReports,
) {
  $scope.testTypesWithReleasableReports = testTypesWithReleasableReports;
  $scope.releaseResult = function() {
    $scope.submitting = true
    $http.post('/api/v1/release_external_provider_results', {'requisition_number': requisition_number})
      .then(function() {
        $modalInstance.close()
        $route.reload()
        ngToast.create("Results have been released to patient")
      })
      .finally(function() { $scope.submitting = false })
  }
}

function EditTestRequestedController($scope, $translate, $modalInstance, $http, ngToast, order, newTestRequested) {
  $scope.submitting = false

  const oldTestRequested = order.test_requested
  $translate('TEST_TYPES.LONG.' + newTestRequested).then(function(translation) {
    $scope.newTestRequestedName = translation
  })
  $scope.submit = function() {
    $scope.submitting = true
    order.test_requested = newTestRequested
    order.$update()
      .then(function() {
        ngToast.create("Order has been successfully updated")
      })
      .catch(function() {
        order.test_requested = oldTestRequested
        ngToast.create({ className: 'error', content:'Update failed' })
      })
      .finally(function() {
        $scope.submitting = false
        $modalInstance.close()
      })
  }
}

module.exports = angular.module('orderingPhysicianModal', [])

  .factory('providerReleaseResultsForOrderModal', function($modal) {
    return {
      open: function(requisition_number, testTypesWithReleasableReports) {
        return $modal.open({
          templateUrl: "/ordering_physicians/_release_modal.html",
          controller: ProviderReleaseResultsForPhysicianOrderModalController,
          size: "md",
          resolve: {
            requisition_number: () => requisition_number,
            testTypesWithReleasableReports: () => testTypesWithReleasableReports,
          }
        })
      }
    }
  })

  .factory('kitOfficeModal', function($modal) {
    return {
      open: function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/_modal_kit_office.html',
          size: 'md'
        })
      }
    }
  })

  .controller('NoAttestationController', function($scope, $modalInstance, $cookies) {
    $scope.submit = function() {
      $cookies.put('seen_no_attestation', 'y')
      $modalInstance.close()
    }
  })

  .controller('SubmitFTPController', function ($scope, $http, $modalInstance, $route, auth, ngToast, order_number) {
    const enrollment = {
      provider_id: auth.currentUser.provider_profile.id,
      order_number: order_number
    }
    $scope.submit = function() {
      $scope.submitting = true
      $http.post('/api/v1/family_testing/request_discount', enrollment)
        .then(function() {
          $modalInstance.dismiss()
          $route.reload()
          ngToast.create("Patient succesfully enrolled.")
        })
        .catch(function() {
          ngToast.create({ className: 'error', content: 'There was an error with the enrollment' })
        })
        .finally(function() {
          $scope.submitting = false
        })
    }
  })

  .controller('DraftUpdatedModalController', function($scope, $window, DetailedPhysicianOrder, draftRequisitionNumber) {
    $scope.reloadDraft = function() {
      // If there is an actual order with that requisition number then take to that order
      // Otherwise force page refresh with same requisition number
      DetailedPhysicianOrder.get({requisition_number: draftRequisitionNumber}).$promise
        .then(function() {
          $window.location.href = '/providers/order/details/' + draftRequisitionNumber
        })
        .catch(function() {
          $window.location.href = '/providers/place-order?draft=' + draftRequisitionNumber
        })
    }
  })

  .controller('CancelOrderModalController', function ($scope, $modalInstance, DraftPhysicianOrder, draftRequisitionNumber) {
    $scope.cancelOrder = function() {
      if (draftRequisitionNumber) {
        new DraftPhysicianOrder().$delete({requisition_number: draftRequisitionNumber})
          .finally(function() {
            $modalInstance.close()
          })
      } else {
        $modalInstance.close()
      }
    }
  })

  .factory('ProviderPlatformModals', function($modal, textModal) {
    return new function() {
      this.openCancelOrderModal = function(draftRequisitionNumber) {
        return $modal.open({
          templateUrl: "/ordering_physicians/_cancel_order_modal.html",
          controller: 'CancelOrderModalController',
          resolve: {
            draftRequisitionNumber: function() { return draftRequisitionNumber },
          },
          size: "md"
        })
      }

      this.openWelcomeModal = function() {
        return $modal.open({
          templateUrl: "/ordering_physicians/_welcome_modal.html",
          size: "md"
        })
      }

      this.openDraftUpdatedModal = function(draftRequisitionNumber) {
        return $modal.open({
          templateUrl: "/ordering_physicians/modals/draft_updated.html",
          controller: 'DraftUpdatedModalController',
          backdrop: 'static',
          keyboard: false,
          size: "md",
          resolve: {
            draftRequisitionNumber: function() { return draftRequisitionNumber }
          }
        })
      }

      this.openBarcodeAccessionModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/_modal_barcode_accession.html',
          size: 'md'
        })
      }

      this.openPaymentErrorModal = function(reason) {
        return textModal.open(
          "We could not process your patient’s payment."
          , reason + " We were not able to process the payment information you provided for your patient. Please correct this information or try another payment method."
          , "Update Payment Info"
          , 'error'
          , true
        )
      }

      this.openNoAttestationModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/no_attestation_modal.html',
          controller: 'NoAttestationController',
          size: 'sm',
        })
      }

      this.openEditTestRequestedModal = function(order, newTestRequested) {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/edit_test_requested_modal.html',
          controller: EditTestRequestedController,
          size: 'md',
          resolve: {
            order: function() { return order },
            newTestRequested: function() { return newTestRequested }
          }
        })
      }

      this.openUSProvidersForUSPatientsModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/only_us_providers_for_us_patients.html',
          size: 'md'
        })
      }

      this.openNYBlockModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/ny_block_modal.html',
          size: 'md'
        })
      }

      this.openChooseTestTypeModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/choose_test_type_modal.html',
          size: 'md'
        })
      }

      this.openTakeHomeNotAvailableModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/take_home_not_available_modal.html',
          size: 'md'
        })
      }

      this.openKioOptionNotAvailableModal = function() {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/kio_not_available_modal.html',
          size: 'md'
        })
      }

      this.openFTPOneClickModal = function(order_number) {
        return $modal.open({
          templateUrl: '/ordering_physicians/modals/ftp_one_click_modal.html',
          controller: 'SubmitFTPController',
          size: 'md',
          resolve: {
            order_number: function() { return order_number },
          }
        })
      }
    }()
  })

  .factory('ProviderHomeInitialMessages', function($location, $cookies, ngToast, ProviderPlatformModals) {
    // Loads initial welcome modals/announcements to providers
    return new function() {
      this.run = function() {
        if ($location.search().welcome) {
          ProviderPlatformModals.openWelcomeModal()
          $cookies.put('insurance_modal', 'y')
        }
        if ($location.search().email_confirmed) {
          ngToast.create("We have confirmed your email address")
          $location.search('email_confirmed', null)
        }
      }
    }()
  })

  .factory('addPatientPaymentInfoModal', require('./modals/add_patient_payment_info'))
  .factory('reattemptInsuranceModal', require('./modals/reattempt_insurance'))
  .factory('overrideOopBlockModal', require('./modals/override_oop_block'))
