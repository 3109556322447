module.exports = function physicianOrderResults($routeParams, $rootScope, $location, $translate, $window,
  auth, modalShim, ProviderPlatformModals, DetailedPhysicianOrder, waffle, showMedicationPromo) {

  'ngInject'

  return {
    restrict: 'E',
    scope: {
    },
    templateUrl: '/ordering_physicians/order-results.html',
    link: function(scope) {

      scope.orderPromiseReturned = false

      scope.changeTest = function() {
        scope.$$postDigest(function () {
          $rootScope.$broadcast('pdf:reload');
        })
      }

      const defaultTestType = $location.search()['test_type']

      scope.ui = {}

      scope.getResultsUrl = (selectedKitOrderNumber) => {
        if (selectedKitOrderNumber) {
          return `/color_results/${selectedKitOrderNumber}`
        }
        return ''
      }

      DetailedPhysicianOrder.get({'requisition_number': $routeParams.requisition_number}).$promise
        .then(function(res) {
          scope.orderPromiseReturned = true
          scope.order = res
          $translate("PROVIDER_PORTAL.STATUSES." + scope.order.status.name + ".NAME")
            .then(function(translation) {scope.order.statusName = translation })

          if (scope.order.kit_orders.length > 0) {
            if (defaultTestType) {
              const searchKitOrder = scope.ui.selectedKitOrderNumber = scope.order.kit_orders.find(
                (ko) => ko.test_type === defaultTestType
              )
              if (searchKitOrder) {
                scope.ui.selectedKitOrderNumber = searchKitOrder.order_number
              }
            }

            if (!scope.ui.selectedKitOrderNumber) {
              scope.ui.selectedKitOrderNumber = scope.order.kit_orders[0].order_number
            }
          }
        })

      const isPgxReport = () => {
        if (scope.ui.selectedKitOrderNumber) {
          if (scope.order.kit_orders.find((ko) =>
            ko.order_number === scope.ui.selectedKitOrderNumber).test_type === 'pgx v1') {
            return true
          }
        }
        return false
      }

      scope.is_provider = !!auth.currentUser.provider_profile
      scope.openFTPModal = function() {
        ProviderPlatformModals.openFTPOneClickModal(scope.ui.selectedKitOrderNumber)
      }
    }
  }
}
