/**
 * @ngdoc module
 * @name counselingFilter
 *
 * @description
 * The `counselingFilter` provides filters for counseling.
 */
module.exports = angular.module('counselingFilter', [])

  /**
   * `appointmentTimeStr` is used in `pending_gc_session.jade`
   * @ngdoc filter
   * @name appointmentTimeStr
   * @memberof counselingFilter
   *
   * @description
   * Returns appiontment time in following format:
   * June 3rd at 9am PDT
   */
  .filter('appointmentTimeStr', function($filter) {
    return function(appointment) {
      const dateObj = new Date(appointment.start_time);
      const month = $filter('date')(appointment.start_time, 'MMMM');
      const date = $filter('ordinalSuffix')(dateObj.getDate());
      const hour = $filter('date')(appointment.start_time, 'h:mm a').toLowerCase();
      const timezone = getTimezoneAbbreviation(dateObj);
      let result = month + ' ' + date + ' at ' + hour

      if (timezone) {
        result = result + ' ' + timezone
      }
      return result
    }

    function getTimezoneAbbreviation(dateObj) {
      let splitted = dateObj.toString().split('(');
      let timezone = null
      // date string is in the following format:
      // Mon Jul 20 2015 13:00:00 GMT-0700 (PDT)
      if (splitted.length > 1) {
        splitted = splitted[1].split(')')
        if (splitted.length > 1) {
          timezone = splitted[0]
        }
      }
      return timezone
    }
  })
