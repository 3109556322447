/**
 * @prettier
 */

module.exports = function calledVariants($window, sampleRunVariants, sangerDataModal) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
    },
    templateUrl: '/results/called_variants.html',
    link: function(scope) {
      scope.showBenignVariants = true;
      scope.toggleAndReload = function() {
        // Invert the value of `showBenignVariants` and reload sampleRunVariants.
        scope.showBenignVariants = !scope.showBenignVariants;
        if (scope.report.sample_sequence_run) {
          scope.sampleRunVariants = sampleRunVariants(
            scope.report,
            scope.showBenignVariants ? null : 'True'
          );
          scope.sampleRunVariants.load();
        }
      };

      scope.openSangerPlots = function($event, confirmation) {
        // Each row in the table is itself a link to a variant classification page. When
        // openSangerPlots is called, we only want to open a modal, not follow the link to
        // the classification page
        $event.stopPropagation();
        sangerDataModal.open(confirmation);
      };

      scope.toggleAndReload();
      scope.viewClassification = function(classification) {
        $window.open('/variant_classifications/' + classification.id + '/show');
      };
    },
  };
};
