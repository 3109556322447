/**
 * @prettier
 */

module.exports = function reportAboutCancerRisks($filter, testTypes, namedMutation) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_about_cancer_risks.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      if (scope.reportContent.is_positive) {
        let surgeryGenes;
        if (scope.reportContent.gender == 'F') {
          surgeryGenes = ['BRCA1', 'BRCA2', 'PTEN', 'TP53', 'CDH1', 'PMS2', 'MSH2', 'MSH6', 'APC'];
          // TODO: Add these genes for the next BO19 render_version increment
          if (scope.reportContent.template.test_type == testTypes.hereditary30) {
            surgeryGenes.push('BRIP1', 'RAD51C', 'RAD51D', 'PALB2', 'MLH1', 'EPCAM');
          }
        } else {
          surgeryGenes = ['APC', 'CDH1'];
        }
        const apcMutations = $filter('filter')(scope.reportContent.mutations, { gene: 'APC' });
        if (apcMutations.length) {
          if ($filter('containsMutation')(apcMutations, namedMutation.apc_i1307k)) {
            surgeryGenes.splice(surgeryGenes.indexOf('APC'), 1);
          }
        }
        scope.surgeryGenes = scope.reportContent.genes_with_pathogenic_mutations.filter(function(
          gene
        ) {
          return $filter('containsAny')([gene], surgeryGenes);
        });
      }
    },
  };
};
