/**
 * @ngdoc service
 * @name benefitsEmployeePageOptions
 *
 * @description
 * Returns values for benefits employee page based on the company
 */

module.exports = (sku) => {
  'ngInject'

  return (companyName) => {
    const titles = {
      default: "Personalize your health",
      hereditary: "Get your Color Hereditary Cancer Test",
    }

    const defaultOptions = {
      routeTitle: 'Get Your Color Test - Color Benefits Program',
      routeDescription: 'Ready to personalize your health? Please enter your work email address to claim your Color benefit at the special price supported by your employer.',
      heroTitle: titles.default,
      heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit.',
      heroDescription2: '',
      isAcceptingEnrollments: true,
      buttonText: 'Get started',
      // Determines placeholder text of input element.  If more than one placeholder is added, multiple inputs will be shown.
      inputPlaceholders: ['Enter your work email'],
      img: 'img--box_sleeve',
      // Teamsters doesn't give their employees emails so we have to de-dupe on first_name,
      // last_initial, zip, and dob by combining them into the identifier
      // setting authFactors avoids a backend call to a whitelist_entry if all whitelists use the same auth factor.
      authFactors: null,
      // concatAuthFactors appends the auth factors into the identifier to keep it unique
      concatAuthFactors: false,
      showValuePropositions: true,
      showHealthAreas: true,
      showDiscovery: true,
      showFamily: true,
      showClientVideos: true,
      showHowItWorks: true,
      showPurchase: true,
      // Overwrites page props via Object.assign
      propsOverlay: {},
      // Some employers want to show test specific information.  Setting this shows a pop up video modal.
      sku: null
    }

    const getOptions = (companyName) => {
      const options = {
        aetna: {
          routeDescription: 'Aetna is offering you the Color Benefits Program.',
          heroTitle: titles.hereditary,
          heroDescription: 'Please enter your work email address to get your Color Test at the special price supported by The Aetna Harvest Program. When you create your Color account, you can use your personal email address.',
        },
        cadence: {
          routeDescription: 'UnitedHealthcare and Cadence are offering you the Color Benefits Program. Through the Program, UnitedHealthcare and Cadence are covering 100% of the cost of the Color Test.',
          heroDescription: 'This pilot is now closed, thank you for your interest.',
          isAcceptingEnrollments: false,
        },
        epicgames: {
          routeDescription: 'Epic Games is offering you the Color Benefits Program.',
          heroDescription: 'Please enter your work email address to redeem your complimentary Color Tests courtesy of Epic Games. This benefit is available to full-time employees in US, UK, Canada, Japan, Poland, Sweden, and Switzerland, and their spouses/partners. We recommend you provide your personal email address when creating your Color account.',
          organization: 'Epic Games',
        },
        hbc: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>Please enter your HBC email (\'<em>name@hbc.com</em>\' or \'<em>name@lordandtaylor.com</em>\') in the field below to claim your Color benefit. If you do not have an HBC email, please enter your associate ID.',
          inputPlaceholders: ['Enter your work email or associate ID'],
          organization_name: 'Hudson\'s Bay Company',
        },
        homedepot: {
          routeDescription: 'Home Depot is offering you the Color Benefits Program. Through the Program, Home Depot is covering 100% of the cost of the Color Test.',
          heroDescription: 'This program is free for associates working in the Atlanta Store Support Center or other Atlanta area supporting offices. To get started, enter your work email address to authenticate that you are eligible for this benefit. When you create your Color account, you can use your personal email address. Your results are confidential and will not be shared with The Home Depot.',
        },
        levistrauss: {
          routeDescription: 'LS&Co. is offering you the Color Benefits Program.',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications?<br><br>Please enter your work email address to claim your free Color benefit. This benefit only applies to full-time LS&Co. Home Office employees in the US. We recommend you use your personal email address when you create your Color account.',
          organization_name: 'Levi Strauss',
        },
        sap: {
          routeDescription: 'SAP is offering you the Color Benefits Program.',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit. <br><br>This benefit is available to US-based SAP, Qualtrics, and Concur employees on the SAP medical plan as well as their adult dependents (i.e. spouse/partner + children aged 18-26) on the SAP medical plan.',
          organization_name: 'SAP',
        },
        sapcanada: {
          routeDescription: 'SAP is offering you the Color Benefits Program.',
          heroDescription: 'Please enter your work email address to get your discounted Color test supported by SAP Canada. When you create a Color account, use your personal email address. To purchase additional kits for eligible dependents, simply visit this page again for each additional order. <br><br><em>Veuillez saisir votre adresse de courriel professionnelle pour obtenir votre test Color à prix réduit par SAP Canada. Lorsque vous créez un compte Color, utilisez votre adresse de courriel personnelle. Vous ne pouvez acheter qu’une seule trousse par ouverture de session. Pour acheter des trousses supplémentaires pour des personnes à charge admissibles, allez de nouveau sur cette page pour chaque commande additionnelle.</em>',
          organization_name: 'SAP Canada',
        },
        cdw: {
          heroDescription: 'Please enter your work email address if you wish to claim your Color benefit. By clicking the "I Consent" button, you understand and agree that your name will be shared with your employer because they are subsidizing the cost of the test and require this information for tax reporting purposes. However, your personal test results, and whether you choose to use your test kit, will <em>not</em> be shared with your employer.',
          buttonText: 'I consent',
          organization_name: 'CDW',
        },
        salesforce: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit.',
          img: 'img--salesforce_logo',
          isAcceptingEnrollments: true,
        },
        mubadala: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers to heart conditions? Please enter your work email address to claim your Color benefit. <br><br>Mubadala is generously covering 100% of the cost of a Color test for you and one family member.',
          organization_name: 'Mubadala',
        },
        uhc: {
          heroTitle: 'Personalize your health at no cost',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter the email address that received the offer to claim your Color kit. <br><br>This offer is available at no cost to those invited.',
          inputPlaceholders: ['Enter the email address that received the offer'],
        },
        jefferson: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>To redeem your free Color kit, please enter your campus key followed by <em>@jefferson.edu</em>. For example, if your campus key is 1234ABCD, please enter: <em>1234ABCD@jefferson.edu</em>',
          organization_name: 'Jefferson Health',
        },
        alaskarailroad: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>Please enter your Alaska Railroad Employee ID in the field below to claim the Color benefit for yourself.<br><br>To purchase a discounted Color test for your legal spouse with the <strong>AKRRCOLOR</strong> code, <strong><a href=\'/purchase/ordering-physician?sku=combo%203&code=AKRRCOLOR\'>click here</a></strong>.',
          inputPlaceholders: ['Enter your Employee ID'],
          organization_name: 'Alaska Railroad',
          showValuePropositions: false,
          showHealthAreas: false,
          showDiscovery: false,
          showFamily: false,
          showClientVideos: false,
          showHowItWorks: false,
          showPurchase: false,
        },
        sequoiaconsultinggroup: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>Please enter your work email address in the field below to claim the Color benefit for yourself.<br><br>To purchase a discounted Color test for your spouse/partner or adult dependent(s) with the <strong>SCGCOLOR</strong> code, <strong><a href=\'/purchase/ordering-physician?sku=combo%203&code=SCGCOLOR\'>click here</a></strong>.',
          organization_name: 'Sequoia Consulting Group',
        },
        ejgallo: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>Please enter your employer issued ID number in the field below to claim the Color benefit for you and your dependents.',
          inputPlaceholders: ['Enter your Employee ID'],
          organization_name: 'E. & J. Gallo Winery and G3'
        },
        shamrockfoods: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications?<br><br> Please enter your Associate ID in the field below to claim the Color benefit for yourself and eligible adult dependents.',
          inputPlaceholders: ['Enter your Associate ID'],
          organization_name: 'Shamrock Foods'
        },
        biltmore: {
          heroTitle: 'Get your free Color test and help advance science',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>To redeem your free Color kit, please enter your firstname and lastname in the field below, without capitalization or spaces. For example, if your name is Jane Doe, please enter \'janedoe\'.',
          heroDescription2: 'Color and the North Carolina Precision Health Collaborative (NCPHC) are conducting a research study to improve our understanding of the uptake and perception of genetic information. Look for an email with more information.',
          inputPlaceholders: ['Enter your name in lowercase, without spaces'],
          organization_name: 'Biltmore'
        },
        biltmorefarms: {
          heroTitle: 'Get your free Color test and help advance science',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? <br><br>To redeem your free Color kit, please enter your firstname and lastname in the field below, without capitalization or spaces. For example, if your name is Jane Doe, please enter \'janedoe\'.',
          heroDescription2: 'Color and the North Carolina Precision Health Collaborative (NCPHC) are conducting a research study to improve our understanding of the uptake and perception of genetic information. Look for an email with more information.',
          inputPlaceholders: ['Enter your name in lowercase, without spaces'],
          organization_name: 'Biltmore Farms'
        },
        accel: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit.<br><br>Please note that this benefit is available for Accel employees only.',
          organization_name: 'Accel'
        },
        qualtrics: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit.<br><br>Please use your @sap.com email address.',
          img: 'img--qualtrics_logo',
          inputPlaceholders: ['Enter your @sap.com email address'],
          organization_name: 'Qualtrics'
        },
        chicos: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color benefit. <br><br>By clicking the "I Consent" button, you have read and accept the <a href="https://cdn2.hubspot.net/hubfs/3989189/Chicos%20-%20Color%20Genomics%20Employee%20Benefit%20Consent%20and%20Release%20Agreement.pdf">Color Genomics Employee Benefit Consent and Release Agreement.</a>',
          organization_name: 'Chicos',
          buttonText: 'I Consent'
        },
        ncbiotech: {
          heroTitle: 'Get your free Color test and help advance science',
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your work email address to claim your Color.',
          heroDescription2: 'Color and the North Carolina Precision Health Collaborative (NCPHC) are conducting a research study to improve our understanding of the uptake and perception of genetic information. Look for an email with more information.',
        },
        daikin: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your employee ID to claim your Color benefit.',
          inputPlaceholders: ['Enter your Employee ID'],
          organization_name: 'Daikin'
        },
        arkema: {
          heroDescription: 'Ready to better understand how your genes impact your health, from your risk for common hereditary cancers and heart conditions to how your body might process certain medications? Please enter your A number to claim your Color benefit.',
          inputPlaceholders: ['A number'],
          organization_name: 'Arkema'
        },
        teamsters: {
          heroDescription: '<br>This page has moved. Please go to <a href="https://color.com/teamsters">https://color.com/teamsters</a>.',
          inputPlaceholders: ['', ''],
          heroTitle: 'This page has moved',
          img: 'img--teamsters_logo',
          buttonText: 'This page has moved. Follow the link above to get started.',
          showValuePropositions: false,
          showFamily: false,
          showHealthAreas: false,
          showDiscovery: false,
          showClientVideos: false,
          showHowItWorks: false,
          showPurchase: false,
        },
        apple: {
          heroTitle: 'AC Wellness Primary Care patients are eligible for Color',
          heroDescription: 'Color is a genetics-based service that you and your AC Wellness provider can use to inform your care plan. Provide your work email address to get started.',
          sku: sku['core 10'],
          propsOverlay: {
            sectionValuePropositions: {
              title: 'A personalized approach to prevention and health management',
              subtitle: 'Color’s confidential service helps you better understand your hereditary risks, helping you, and possibly your family, lead a healthier life.',
              items: [
                {
                  image: 'illo_NegativeResults',
                  heading: 'Actionable results',
                  paragraph: 'Your results can help you and your doctor create a personalized screening and health management plan.'
                },
                {
                  image: 'illo_GCTalk',
                  heading: 'Personal guidance',
                  paragraph: 'Get complimentary access to our board-certified genetic counselors to help you understand your results.'
                },
                {
                  image: 'illo_ScreenLock',
                  heading: 'Confidential information',
                  paragraph: 'We take privacy very seriously and your personally identifiable information will not be shared with your employer without your consent.'
                },
              ],
            },
            sectionHowItWorks: {
              title: 'Here’s how Color works',
              items: [
                {
                  heading: 'Claim your Color benefit online and create an account to request your kit.',
                },
                {
                  heading: 'Provide a saliva sample and drop your kit in the mail.',
                },
                {
                  heading: 'Get an email when your results are ready in 3-4 weeks.',
                },
                {
                  heading: 'Meet with our genetic counselors.',
                },
                {
                  heading: 'Create a screening and prevention plan with your healthcare provider.',
                },
              ],
            },
          }
        },
        projectbaseline: {
          routeDescription: 'Project Baseline is offering you the Color Benefits Program.',
          heroTitle: 'Thank you for contributing to the Project Baseline Health Study! ',
          heroDescription: 'To redeem your free Color kit, please enter your Project Baseline email (this is what you used to sign up for Project Baseline and where you receive email reminders from Project Baseline).',
          img: 'img--projectbaseline_logo',
          organization_name: 'Verily\'s Project Baseline',
          inputPlaceholders: ['Enter your email'],
        },
        local9fund: {
          heroDescription: 'Plumbers and Pipefitters of Local 9 Welfare Fund is offering eligible members and one (1) eligible adult dependent (max of 2) complimentary access to Color’s confidential health service to help you personalize your health with genetic insights. Send your test kit, including a saliva sample back to Color within 7 days of receiving your kit in the mail.',
          inputPlaceholders: ['First name', 'Last initial'],
          concatAuthFactors: true,
        },
        acandrefrigerationfund: {
          heroDescription: 'AC and Refrigeration Fund of New Jersey is offering eligible members and one (1) eligible adult dependent (max of 2) complimentary access to Color’s confidential health service to help you personalize your health with genetic insights. Send your test kit, including a saliva sample back to Color within 7 days of receiving your kit in the mail.',
          inputPlaceholders: ['First name', 'Last initial'],
          concatAuthFactors: true,
        },
        ocra: {
          routeTitle: 'Get Your OCRA Color Test',
          routeDescription: 'Ovarian Cancer Research Alliance (OCRA) is offering you free clinical hereditary cancer genetic testing.',
          heroTitle: '',
          heroDescription: 'Ovarian Cancer Research Alliance (OCRA) is partnering with Color Health to provide free clinical hereditary cancer genetic testing to those at increased risk who qualify.',
          heroDescription2: 'Ready to better understand how your genes impact your hereditary risk and management options for ovarian and other common hereditary cancers? To claim your genetic testing kit, please enter your email address (must be the same email address you used when you applied on the OCRA website). Learn more about the 29 genes included in Color\'s Hereditary Cancer Test <a href="https://www.color.com/learn/color-genes">here</a>.',
          inputPlaceholders: ['Enter your email'],
          img: 'img--ocra_logo',
          sku: sku['hereditary 30'],
          showHealthAreas: false,
          showDiscovery: false,
          showClientVideos: false,
          showHowItWorks: false,
          showPurchase: false,
          propsOverlay: {
            sectionValuePropositions: {
              title: 'A personalized approach to prevention and health management',
              subtitle: 'Color’s confidential service helps you better understand your hereditary risks, helping you, and possibly your family, lead a healthier life.',
              items: [
                {
                  image: 'illo_NegativeResults',
                  heading: 'Actionable results',
                  paragraph: 'Your results can help you and your doctor create a personalized screening and health management plan.'
                },
                {
                  image: 'illo_GCTalk',
                  heading: 'Personal guidance',
                  paragraph: 'Get complimentary access to our board-certified genetic counselors to help you understand your results.'
                },
                {
                  image: 'illo_ScreenLock',
                  heading: 'Confidential information',
                  paragraph: 'We take privacy very seriously and your personally identifiable information will not be shared with anyone without your consent.'
                },
              ],
            },
          }
        },
      }
      return options.hasOwnProperty(companyName) ? options[companyName] : {};
    }
    return angular.extend({}, defaultOptions, getOptions(companyName));
  }
}
