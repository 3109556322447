!function() {

  'use strict'

  angular.module('familyTestingDirectives', [])

    .directive('familyTestingBreadcrumb', function(FamilyTestingFlow) {
      'ngInject'
      return {
        restrict: 'E',
        scope: {
          step: '@'
        },
        templateUrl: '/family_testing/_breadcrumb.html',
        link: function(scope) {
          scope.flowState = FamilyTestingFlow.state
        }
      }
    })

    .directive('relativeTest', function() {
      return {
        restrict: 'E',
        scope: { relative: '=' },
        templateUrl: '/family_testing/_relative.html'
      }
    })

}();
