module.exports = function clrProviderInput(
  NotYetAvailableModal, orderingPhysicianRoles, npiOptionalRoles, externalProviderRoles
) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/clr_provider_input.html',
    scope: {
      defaultCountry: '=',
      isOrderingPhysician: '=',
      provider: '='
    },
    require: '^form',
    link: function(scope, elem, attr, ctrl) {
      scope.roles = scope.isOrderingPhysician ? orderingPhysicianRoles : externalProviderRoles
      scope.npiOptionalRoles = npiOptionalRoles.map(function(role) { return role[0] })
      scope.NotYetAvailableModal = NotYetAvailableModal
      scope.form = ctrl

      scope.$watch('provider.country', function (newValue) {
        scope.showNpi = newValue == 'US'
        if (scope.provider && newValue != 'US') {
          delete scope.provider['npi']
        }
      })

      if (!scope.provider) {
        scope.provider = {
          country: scope.defaultCountry
        }
      }
    }
  }
}
