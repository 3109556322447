module.exports = function placeTestingOptions($cookies, isCovidSample, ProviderPlatformModals, testTypes, project, ApplicationType) {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      userProvider: '=',
      order: '='
    },
    templateUrl: '/ordering_physicians/_place_release_options.html',
    link: function(scope, element, attrs, formCtrl) {
      if (!scope.order.test_requested) {
        scope.order.test_requested = testTypes.hereditary30
      }

      scope.form = formCtrl
      scope.testTypes = testTypes
      scope.isCovidSample = isCovidSample
      scope.isClinical = project.application === ApplicationType.CLINICAL

      scope.chooseTestTypeModal = function() {
        ProviderPlatformModals.openChooseTestTypeModal()
      }

      scope.settings = scope.userProvider ? scope.userProvider.settings : {}
    }
  }
}
