module.exports = function ProviderPlatformSubmitPaymentController(
  $scope,
  auth,
  project,
  promoCodeStore,
  testTypes,
  physicianOrderCheckout,
  DetailedPhysicianOrder,
  PhysicianOrderFormData,
  PhysicianOrderFormFlow,
  PhysicianOrderNavigationService
) {
  'ngInject'

  PhysicianOrderNavigationService.setState(PhysicianOrderNavigationService.STATES.PAYMENT)

  $scope.userProvider = auth.currentUser.provider_profile
  $scope.settings = $scope.userProvider ? $scope.userProvider.settings : {}

  $scope.order = PhysicianOrderFormData.getOrSetIfEmpty(
    DetailedPhysicianOrder.initializeWithSettings($scope.settings)
  )

  // Clear all promo-codes before the order
  promoCodeStore.clear()

  if ($scope.settings && $scope.settings.payment_code) {
    promoCodeStore.updateCode($scope.settings.payment_code, true)
  }
  if ($scope.order.promocode_for_payment) {
    promoCodeStore.updateCode($scope.order.promocode_for_payment, true)
  }

  $scope.checkout = new physicianOrderCheckout($scope.order, $scope.userProvider, $scope.order.test_requested)

  function moveToReview() {
    PhysicianOrderFormFlow.goTo(
      PhysicianOrderNavigationService.URLS.REVIEW,
      true
    )
  }

  $scope.store = promoCodeStore
  $scope.reviewOrder = function(order) {
    if (promoCodeStore.promotionalCode) {
      order.promocode_for_payment = promoCodeStore.promotionalCode.code
    }
    PhysicianOrderFormData.set(order)
    $scope.submitting = true
    if (!$scope.order.payment.meetsInsuranceCheck()) {
      $scope.checkout.reviewPayment()
        .then(moveToReview)
        .finally(function() {
          $scope.submitting = false
        })
    } else {
      moveToReview()
    }
  }
}
