!function() {

  'use strict'

  const module = angular.module('healthProfileDirectives', ['healthProfileFilter', 'pascalprecht.translate', 'healthProfileResource'])

  module.directive('clrAge', function() {
    return {
      scope: { age: '=' },
      replace: true,
      templateUrl: '/health_profiles/age.html'
    }
  })

  module.directive('clrAnswer', function() {
    return {
      transclude: true,
      scope: {
        isAnswered: '=',
        question: '@',
        isGridRow: '=?'
      },
      templateUrl: '/health_profiles/answer.html'
    }
  })

  module.directive('clrAgeError', function() {
    return {
      require: '^form',
      scope: {
        formInput: '=formInput'
      },
      templateUrl: '/health_profiles/age_error.html',
      link: function(scope, element, attrs, formCtrl) {
        scope.form = formCtrl
      }
    }
  })

  // A helper function for genetic testing health history
  // related directives that need to deal with different
  // field names depending on type of genetic testing
  // the directives  are dealing with. This function sets
  // the type as "Cancer" if not already set in a given scope.
  function addGeneticTestFieldName(scope) {
    // Default type is cancer
    if (!scope.type) {
      scope.type = 'Cancer'
    }

    // Set type specific params
    if (scope.type == 'Cancer') {
      scope.mutationsField = 'mutations'
      scope.hadMutationField = 'had_mutation'
      scope.companyField = 'company_performed_genetic_test'
      scope.hadGeneticTestField = 'had_genetic_test_for_hereditary_cancer_risk'
      scope.anyRelativeField = 'any_relative_had_genetic_test_for_hereditary_cancer_risk'
    } else if (scope.type == 'Cardio') {
      scope.mutationsField = 'cardio_mutations'
      scope.hadMutationField = 'had_cardio_mutation'
      scope.companyField = 'company_performed_cardio_test'
      scope.hadGeneticTestField = 'had_genetic_test_for_cardio'
      scope.anyRelativeField = 'any_relative_had_genetic_test_for_cardio'
    } else {
      throw new Error(scope.type + ' type is not supported.')
    }
  }

  module.directive('clrGeneticTestInput', function(interpretedGenes, testTypes, geneticLabsByCondition, fileUploader, FileUploader) {
    return {
      require: '^form',
      scope: {
        profile: '=',
        identifier: '@',
        withoutCompany: '=',
        type: '@?',
        ngRequired: '='
      },
      templateUrl: '/health_profiles/genetic_test_input.html',
      link: function(scope, elem, attrs, formCtrl) {
        if (angular.isUndefined(scope.identifier)) {
          scope.identifier = ''
        }
        scope.form = formCtrl

        addGeneticTestFieldName(scope)

        let genes;
        let labs
        if (scope.type == 'Cancer') {
          genes = angular.copy(interpretedGenes[testTypes.hereditary30])
          labs = angular.copy(geneticLabsByCondition[scope.type])
        } else if (scope.type == 'Fh') {
          genes = angular.copy(interpretedGenes[testTypes.fh3])
          labs = angular.copy(geneticLabsByCondition['Cardio'])
        } else if (scope.type == 'Cardio') {
          genes = angular.copy(interpretedGenes[testTypes.cardio30])
          labs = angular.copy(geneticLabsByCondition[scope.type])
          // LDLRAP1 is a FH gene we currently don't have but are actively looking to add
          genes.push('LDLRAP1')
          genes.sort()
        }

        genes.push('Other')
        scope.genes = genes
        scope.selected = {gene: ''}

        labs.push({ name: 'Unknown', value: 'Unknown' })
        scope.companyPerformedGeneticTestOptions = labs

        if (!scope.profile[scope.mutationsField]) {
          scope.profile[scope.mutationsField] = []
        }

        scope.resultsUploaders = []

        const createResultsUploader = function(mutation) {
          const uploader = fileUploader({
            url: '/api/v1/family_testing/upload',
            queueLimit: 1
          })

          // Display existing file if one has already been uploaded
          if (mutation.resultsDocumentName && mutation.resultsDocumentKey) {
            const file = new FileUploader.FileItem(uploader, { name: mutation.resultsDocumentName })
            file.s3_key = mutation.resultsDocumentKey
            file.isUploaded = true
            uploader.queue.push(file)
          }

          // If a file gets added, update file info
          uploader.onSuccessItem = function(item, response, status, headers) {
            mutation.resultsDocumentName = item.file.name
            mutation.resultsDocumentKey = response
          }

          // If a file gets removed, clear file info
          uploader.onRemoveFile = function(file) {
            delete mutation.resultsDocumentName
            delete mutation.resultsDocumentKey
          }

          return uploader
        }

        for (const mutation of scope.profile[scope.mutationsField]) {
          scope.resultsUploaders.push(createResultsUploader(mutation))
        }

        scope.$watch('profile.' + scope.hadMutationField, function(hadMutation) {
          if (hadMutation == 'N' || hadMutation == 'U') {
            scope.profile[scope.mutationsField] = []
            scope.resultsUploaders = []
          }
        })

        scope.addMutation = function() {
          const gene = scope.selected.gene == 'Other' ? '' : scope.selected.gene
          const mutation = { gene: gene, name: '' }
          scope.resultsUploaders.push(createResultsUploader(mutation))
          scope.profile[scope.mutationsField].push(mutation)
          scope.selected.gene = ""
        }

        scope.setGene = function(mutation, selectedGene) {
          if (selectedGene != 'Other') {
            mutation.gene = selectedGene
          }
        }

        scope.removeMutation = function(index) {
          scope.profile[scope.mutationsField].splice(index, 1)
          scope.resultsUploaders.splice(index, 1)
        }
      }
    }
  })

  module.directive('clrCheckboxWithAgeInput', function() {
    return {
      require: '^form',
      scope: {
        checkboxName: '@',
        ageInputName: '@',
        checkboxLabel: '@',
        model: '=',
        ageModel: '=',
        msgAgeQuestion: '@',
        ngRequired: '=',
        ageMax: '@?'
      },
      templateUrl: '/health_profiles/checkbox_with_age_input.html',
      link: function(scope, elem, attrs, formCtrl) {
        scope.form = formCtrl

        if (!scope.ageMax) {
          scope.ageMax = 130
        }
      }
    }
  })

  module.directive('clrRadioRowWithAgeInput', function() {
    return {
      require: '^form',
      scope: {
        radioRowName: '@',
        ageInputName: '@',
        rowTranslateText: '@',
        model: '=',
        ageModel: '=',
        msgAgeQuestion: '@',
        tooltipText: '@?',
        ngRequired: '='
      },
      templateUrl: '/health_profiles/directives/radio_row_with_age_input.html',
      link: function(scope, elem, attrs, formCtrl) {
        scope.form = formCtrl
      }
    }
  })

  module.directive('clrHealthHistory', function(HealthHistory, kidneyOrLiverProblemOptions) {
    return {
      scope: {
        healthHistory: '=?',
        userId: '@?'
      },
      templateUrl: '/health_profiles/health_history_show.html',
      link: function(scope) {
        scope.HealthHistory = HealthHistory // Used for relationship constants
        scope.relationships = [
          'daughter', 'son', 'full sister', 'full brother',
          'maternal half sister', 'maternal half brother',
          'paternal half sister', 'paternal half brother',
          'maternal aunt', 'maternal uncle',
          'paternal aunt', 'paternal uncle',
          'mother', 'father',
          'maternal grandmother', 'maternal grandfather',
          'paternal grandmother', 'paternal grandfather'
        ]
        if (scope.userId) {
          scope.healthHistory = new HealthHistory()
          HealthHistory.get({ user: scope.userId }).$promise
            .then(function(res) {
              if (res.results.length) {
                scope.healthHistory = new HealthHistory(res.results[0])
              }
            })
        }

        scope.kidneyOrLiverProblemOptions = kidneyOrLiverProblemOptions
      }
    }
  })

  function cleanUpCancerFields(profile, attrs) {
    // if had_cancer was answered, then convert null answer into false.
    if (profile && angular.isDefined(profile.had_cancer) && profile.had_cancer !== null) {
      for (let i = 0; i < attrs.length; i++) {
        if (!profile[attrs[i]]) {
          profile[attrs[i]] = false
        }
      }
    }
  }

  module.directive('clrCondensedRelativesDisplay', function(HealthHistory) {
    return {
      scope: {
        healthHistory: '=',
        known: '='
      },
      templateUrl: '/health_profiles/condensed_relatives_display.html',
      link: function(scope, elem, attrs) {
        scope.relationships = HealthHistory['R_' + attrs.relationships]
        scope.relatives = scope.healthHistory.getRelatives(scope.relationships)

        if (scope.known === 'U' || scope.known === null) {
          scope.hasRelatives = 'unknown'
        } else if (scope.known === 'N' || scope.known === false || scope.relatives.length == 0) {
          scope.hasRelatives = 'no'
        } else {
          scope.hasRelatives = 'yes'
        }
      }
    }
  })

  module.directive('clrCondensedRelativeDisplay', function() {
    return {
      scope: {
        relative: '='
      },
      templateUrl: '/health_profiles/condensed_relative_display.html',
      link: function(scope, elem, attrs) {
        scope.cancers = []
        scope.editedBy = []

        if (!scope.relative) {
          return
        }

        const healthProfile = scope.relative.female_relative_health_profile || scope.relative.male_relative_health_profile
        if (healthProfile) {
          scope.cancers = healthProfile.cancers
        }
      }
    }
  })

  module.directive('geneticTestShow', function() {
    return {
      scope: {
        healthHistory: '=',
        type: '@?'
      },
      templateUrl: '/health_profiles/genetic_test_show.html',
      link: function(scope, elem, attrs) {
        addGeneticTestFieldName(scope)
        if (scope.type == 'Cancer') {
          scope.hadGeneticTestMsg = 'HEALTH_HISTORY.PERSONAL.HAD_GENETIC_TEST_Q'
          scope.hadGeneticTestTooltipMsg = 'HEALTH_HISTORY.POPUPS.HAD_GENETIC_TEST'
        } else if (scope.type == 'Fh') {
          scope.hadGeneticTestMsg = 'HEALTH_HISTORY.PERSONAL.HAD_FH_GENETIC_TEST_Q'
        } else if (scope.type == 'Cardio') {
          scope.hadGeneticTestMsg = 'HEALTH_HISTORY.PERSONAL.HAD_CARDIO_GENETIC_TEST_Q'
        }
      }
    }
  })

  module.directive('boldOnSelectValue', function() {
    return {
      restrict: 'A',
      scope: {
        toggleValue: '='
      },
      link: function(scope, elem) {
        scope.$watch('toggleValue', function(newValue) {
          if (newValue === true || newValue == 'Y') {
            elem.css({'font-weight': 500})
          } else {
            elem.css({'font-weight': 400})
          }
        })
      }
    }
  })

  function getInitialHeightAndWeight(scope) {
    const heightInInches = scope.healthHistory.height_in_inches
    if (heightInInches) {
      scope.heightFeet = Math.floor(heightInInches / 12)
      scope.heightInches = heightInInches % 12
      scope.heightCentimeters = Math.round(heightInInches / 0.39370)
    }

    const weightInPounds = scope.healthHistory.weight_in_pounds
    if (weightInPounds) {
      scope.weightKilograms = Math.round(weightInPounds / 2.2046)
    }
    scope.healthHistory.weight_in_pounds = Math.round(scope.healthHistory.weight_in_pounds);
  }

  module.directive('generalWellnessShow', function() {
    return {
      restrict: 'E',
      scope: {
        healthHistory: '='
      },
      templateUrl: '/health_profiles/directives/general_wellness_show.html',
      link: function(scope) {
        getInitialHeightAndWeight(scope)
      }
    }
  })

  module.directive('gridRowShow', function() {
    return {
      restrict: 'E',
      scope: {
        field: '=',
        ageDiagnosed: '=',
        rowText: '@',
        tooltipText: '@?'
      },
      templateUrl: '/health_profiles/directives/grid_row_show.html'
    }
  })

  module.directive('subgridRowShow', function() {
    return {
      restrict: 'E',
      scope: {
        field: '=',
        ageDiagnosed: '=?',
        rowText: '@'
      },
      templateUrl: '/health_profiles/directives/subgrid_row_show.html'
    }
  })

  module.directive('relativeGridRowShow', function() {
    return {
      restrict: 'E',
      scope: {
        shouldDisplay: '=',
        ageDiagnosed: '=?',
        rowText: '@'
      },
      templateUrl: '/health_profiles/directives/relative_grid_row_show.html'
    }
  })

  module.directive('medicationHistory', function($filter) {
    return {
      restrict: 'E',
      require: '^form',
      scope: {
        healthHistory: '=',
        ngRequired: '='
      },
      templateUrl: '/health_profiles/directives/medication_history.html',
      link: function(scope, element, attrs, formCtrl) {
        scope.form = formCtrl
        if (!scope.healthHistory.hasOwnProperty('current_medications')) {
          scope.healthHistory.current_medications = []
        }

        scope.itemSelected = function(item) {
          const medication = item.originalObject
          if ($filter('filter')(scope.healthHistory.current_medications, {$: medication.drug_brand_name}).length) {
            return
          }
          const medicationItem = {
            brand: medication
          }
          scope.healthHistory.current_medications.push(medicationItem)
          scope.healthHistory.current_medications.sort((a, b) => a.brand.drug_brand_name.localeCompare(b.brand.drug_brand_name))
        }
      }
    }
  })

  module.directive('medicationList', function() {
    return {
      restrict: 'E',
      scope: {
        medicationList: '='
      },
      templateUrl: '/health_profiles/directives/medication_list.html',
      link: function(scope) {
        scope.removeItem = function(item) {
          const index = scope.medicationList.findIndex(m => m.id == item.id)
          scope.medicationList.splice(index, 1)
        }
      }
    }
  })

  module.directive('kidneyOrLiverProblem', function(kidneyOrLiverProblemOptions) {
    return {
      require: '^form',
      restrict: 'E',
      scope: {
        healthHistory: '=',
        ngRequired: '='
      },
      templateUrl: '/health_profiles/directives/kidney_or_liver_problem.html',
      link: function(scope, elem, attrs, formCtrl) {
        scope.form = formCtrl
        scope.options = kidneyOrLiverProblemOptions

        scope.atLeastOneOptionSelected = function() {
          return scope.options.some(function(option) { return scope.healthHistory[option.fieldName]})
        }
      }
    }
  })

  module.directive('genderIdentityInput', function(genderIdentities) {
    return {
      restrict: 'E',
      scope: {
        ngModel: "=",
        ngChange: "=",
      },
      templateUrl: '/health_profiles/directives/gender_identity_input.html',
      link: function(scope, elem, attrs, controller) {
        scope.form = controller
        scope.genderIdentityOptions = [
          { value: genderIdentities.FEMALE, label: "Female" },
          { value: genderIdentities.MALE, label: "Male" },
          { value: genderIdentities.NON_BINARY, label: "Non-Binary" },
          { value: genderIdentities.SELF_DESCRIBED, label: "Prefer to self-describe" },
          { value: genderIdentities.PREFER_NOT_TO_RESPOND, label: "Prefer not to say" }
        ]
      }
    }
  })
}();
