module.exports = function incorrectPatientInfoModal($modal) {
  'ngInject'
  return {
    open: function() {
      return $modal.open({
        templateUrl: '/kit_registrations/_modal-incorrect_patient_info.html',
        size: 'lg'
      })
    }
  }
}
