"use strict"

/**
 * Directs user to a path to avoid using an anchor tag (which causes weird styling sometimes).
 * Useful for enabling a click handler to a large element, like lists of image+text items
 */
module.exports = function goTo($location, $route, $window) {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      goTo: '@'
    },
    link: (scope, elem) => {
      const urlRegex = /^((https?|http):\/\/)(?:\S+(?::\S*)?)/

      elem.bind('click', () => {
        if (scope.goTo[0] == '/') { // This way of grabbing the first character is more performant than regex, .charAt(0), and .substring(0, 1)
          $location.path(scope.goTo)
          $route.reload() // Required for performance reasons, $location takes > 1s to process otherwise
        } else if (urlRegex.test(scope.goTo)) {
          $window.location = scope.goTo
        } else {
          console.info('goTo attribute is not a valid URL or path')
        }
      })
    }
  }
}
