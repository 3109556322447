'use strict'

module.exports = function($scope, $http, auth, ngToast, $modal) {
  'ngInject'

  $scope.storeSampleChanging = false
  $scope.form = {}
  $scope.storeSampleChanged = function(event) {
    event.preventDefault()
    event.stopPropagation()

    $scope.storeSampleChanging = true

    if (auth.currentUser.allowed_to_store) {
      const modalInstance = $modal.open({
        templateUrl: "/generic_modal.html",
        controller: function($scope, $modalInstance, options) {
          $scope.options = options
        },
        size: "sm",
        resolve: {
          options: function() {
            return {
              title: "Are you sure?",
              body: "If you choose not to store your sample, we will throw it " +
                          "away. This can not be undone. You will have to pay for another " +
                          "kit to utilize new genetic screens that are developed.",
              primaryActionText: "Throw my sample away",
              secondaryActionText: "Cancel change"
            }
          }
        }
      })
      modalInstance.result
        .then(function (scope) {
          $http.patch('/api/v1/accounts/' + auth.currentUser.id, { allowed_to_store: false })
            .then(function(res) {
              auth.currentUser.allowed_to_store = false
              ngToast.create('Your sample will be thrown away.')
            })
            .finally(function() {
              $scope.storeSampleChanging = false
            })
        })
        .catch(function() {
          $scope.storeSampleChanging = true
          $scope.form.storeSample = true
        })
    }
  }
}
