/**
 * @prettier
 */

module.exports = function reportAdditionalGenes(
  $filter,
  interpretedGenes,
  testTypes,
  defaultLanguage
) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_additional_genes.html',
    scope: {
      reportContent: '=',
      language: '@?',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      if (angular.isUndefined(scope.language)) {
        scope.language = defaultLanguage;
      }
      let nonPathogenicGenes = angular.copy(
        interpretedGenes[scope.reportContent.template.test_type]
      );
      // Remove pathogenic genes from whole gene list per test type
      angular.forEach(scope.reportContent.genes_with_pathogenic_mutations, function(gene) {
        // If gene is CDKN2A, remove both p14ARF and p16INK4a.
        if (gene == 'CDKN2A') {
          nonPathogenicGenes = nonPathogenicGenes.filter(function(g) {
            return g.indexOf('CDKN2A') != 0;
          });
        } else {
          nonPathogenicGenes.splice(nonPathogenicGenes.indexOf(gene), 1);
        }
      });
      // If testType is Hereditary 30, append asterisks on certain genes
      if (scope.reportContent.template.test_type == testTypes.hereditary30) {
        nonPathogenicGenes = nonPathogenicGenes.map(function(gene) {
          if (
            gene == 'CDK4' ||
            gene == 'EPCAM' ||
            gene == 'GREM1' ||
            gene == 'MITF' ||
            gene == 'POLD1' ||
            gene == 'POLE'
          ) {
            return gene + '*';
          } else if (gene == 'PMS2') {
            return gene + '**';
          } else {
            return gene;
          }
        });
      }
      scope.listOfGenes = $filter('orderBy')(nonPathogenicGenes).join(', ');

      if (scope.reportContent.template.test_type == testTypes.hereditary30) {
        scope.aboutGeneUrl =
          'https://www.color.com/learn/can-cancer-be-inherited-learn-about-hereditary-cancer#gene-table';
      } else if (scope.reportContent.template.test_type == testTypes.breastOvarian19) {
        scope.aboutGeneUrl =
          'https://www.color.com/learn/can-cancer-be-inherited-learn-about-hereditary-cancer#gene-table';
      } else if (scope.reportContent.template.test_type == testTypes.wisdom9) {
        scope.aboutGeneUrl =
          'https://www.color.com/learn/can-cancer-be-inherited-learn-about-hereditary-cancer#gene-table';
      } else if (scope.reportContent.template.test_type == testTypes.fh3) {
        scope.aboutGeneUrl =
          'https://www.color.com/learn/what-is-familial-hypercholesterolemia#genes';
      } else {
        throw scope.reportContent.template.test_type + ' is not supported.';
      }
    },
  };
};
