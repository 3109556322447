'use strict'

module.exports = function($filter, $translate, testTypes) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/results/directives/reportSurvivorStringAppend.html',
    scope: {
      reportContent: '=',
      genes: '=?',
      showSecondary: '=?',
      allelicity: '=?'
    },
    link: function(scope, elem) {
      scope.testTypes = testTypes
      scope.survivorStrings = []

      // Fallopian tube and PP, we state ovarian cancer as the affected to relate to shown chart
      const ovarianCancerGroup = ['ovarian cancer', 'fallopian tube cancer', 'primary peritoneal cancer']

      // Generate the primary phenotype that are part of this report
      let primaryPhenotypes = []
      if (scope.reportContent.is_negative) {
        primaryPhenotypes = ['breast cancer', 'ovarian cancer', 'fallopian tube cancer', 'primary peritoneal cancer', 'male breast cancer', 'colorectal cancer', 'pancreatic cancer', 'melanoma', 'prostate cancer', 'uterine cancer', 'stomach cancer']
      } else {
        const genes = scope.genes || scope.reportContent.genes_with_pathogenic_mutations
        genes.forEach(function(gene) {
          const customPrimaryPhenotypes = $filter('customPhenotypes')(scope.reportContent, gene, 'primary')
          if (angular.isDefined(customPrimaryPhenotypes)) {
            primaryPhenotypes = primaryPhenotypes.concat(customPrimaryPhenotypes)
          } else {
            primaryPhenotypes = primaryPhenotypes.concat($filter('getPrimaryPhenotypeOrder')([gene], scope.reportContent.gender, scope.reportContent.template.test_type, scope.allelicity))
          }
        })

        if (primaryPhenotypes.indexOf('ovarian cancer') > -1) {
          // This is survivor-specific logic, where `fallopian tube` and `primary peritoneal` cancer affects your ovarian risk, but they themselves are not phenotypes with risk numbers attached. In other words, ovarian risk includes fallopian tube and primary peritoneal.
          primaryPhenotypes = primaryPhenotypes.concat(['fallopian tube cancer', 'primary peritoneal cancer'])
        }
      }

      // Generate the secondary phenotype that are part of this report
      const risks = $filter('sortedRisks')(scope.reportContent, scope.reportContent.gender, true)
      const secondaryPhenotypes = risks.pathogenic.filter(function(risk) {
        return primaryPhenotypes.indexOf(risk.phenotype) == -1
      }).map(function(risk) {
        return risk.phenotype
      })

      // Generate all the survivor phenotypes associated with this report, with special conditions around breast + ovarian cancer
      const survivorPhenotypes = []

      /** This is supposed to handle these cases:
        * - client is breast + ovarian survivor, `breast` primary phenotype, sentence says `because of prior history of breast cancer`
        * - client is breast survivor, `male breast` primary phenotype, sentence says `because of prior
        * history of male breast cancer`
        * - client is breast + ovarian survivor, `ovarian` primary phenotype, sentence says `because of prior history of ovarian cancer`
        * - client is breast + ovarian survivor, `breast` and `ovarian` primary phenotypes, sentence says `because of prior history of breast and ovarian cancer`
      **/
      if (scope.reportContent.breast_cancer_survivor && scope.reportContent.gender == 'M') {
        survivorPhenotypes.push('male breast cancer')
      }
      if (scope.reportContent.breast_cancer_survivor && scope.reportContent.gender == 'F') {
        survivorPhenotypes.push('breast cancer')
      }
      if (scope.reportContent.ovarian_cancer_survivor) {
        survivorPhenotypes.push('ovarian cancer')
      }

      // Continue through all the other survivor scenarios
      if (scope.reportContent.colorectal_cancer_survivor) {
        survivorPhenotypes.push('colorectal cancer')
      }
      if (scope.reportContent.pancreatic_cancer_survivor) {
        survivorPhenotypes.push('pancreatic cancer')
      }
      if (scope.reportContent.melanoma_survivor) {
        survivorPhenotypes.push('melanoma')
      }
      if (scope.reportContent.prostate_cancer_survivor) {
        survivorPhenotypes.push('prostate cancer')
      }
      if (scope.reportContent.endometrial_cancer_survivor) {
        survivorPhenotypes.push('uterine cancer')
      }
      if (scope.reportContent.gastric_cancer_survivor) {
        survivorPhenotypes.push('stomach cancer') // We have risks for stomach in APC cause that's the colloquial name
        survivorPhenotypes.push('gastric cancer') // We have risks for gastric in CDH1 cause HDGC is the affected condition
      }
      if (scope.reportContent.fallopian_tube_cancer_survivor) {
        survivorPhenotypes.push('fallopian tube cancer')
      }
      if (scope.reportContent.primary_peritoneal_cancer_survivor) {
        survivorPhenotypes.push('primary peritoneal cancer')
      }

      // Generate the string parts that get ng-repeat'ed in the template, handles all variations
      function generateSurvivorString(survivorPhenotype) {
        const output = {}
        const isOvarianCancerGroup = ovarianCancerGroup.indexOf(survivorPhenotype) > -1
        $translate([
          'RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_1_STANDARD',
          'RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_1_RISK_MODEL',
          'RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_2',
          'RESULT.CHART.SUBTITLE.SURVIVOR_NEG_APPEND_3',
          'RESULT.CHART.SUBTITLE.SURVIVOR_POS_OVARIAN_GROUP_APPEND_3',
          'RESULT.CHART.SUBTITLE.SURVIVOR_POS_GENERAL_APPEND_3'
        ], {
          phenotype: survivorPhenotype,
          affectedPhenotype: isOvarianCancerGroup ? 'ovarian cancer' : survivorPhenotype
        }).then(function(translations) {
          if (scope.reportContent.template.risk_model) {
            output.s1 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_1_RISK_MODEL']
          } else {
            output.s1 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_1_STANDARD']
          }
          output.s2 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_APPEND_2']
          if (scope.reportContent.is_negative) {
            output.s3 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_NEG_APPEND_3']
          } else if (isOvarianCancerGroup && scope.reportContent.render_version < 8) {
            output.s3 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_POS_OVARIAN_GROUP_APPEND_3']
          } else {
            output.s3 = translations['RESULT.CHART.SUBTITLE.SURVIVOR_POS_GENERAL_APPEND_3']
          }
        })
        return output
      }

      const filteredPhenotypes = []
      // Loop through each valid survivor phenotype and generate the string
      angular.forEach(survivorPhenotypes, function(survivorPhenotype) {
        const isPrimary = primaryPhenotypes.indexOf(survivorPhenotype) > -1;
        const isSecondary = secondaryPhenotypes.indexOf(survivorPhenotype) > -1
        if (scope.showSecondary && isSecondary || !scope.showSecondary && isPrimary) {
          filteredPhenotypes.push(survivorPhenotype)
        }
      })

      const brCanIndex = filteredPhenotypes.indexOf('breast cancer')
      const ovCanIndex = filteredPhenotypes.indexOf('ovarian cancer')
      if (brCanIndex > -1 && ovCanIndex > -1) {
        filteredPhenotypes.splice(brCanIndex > ovCanIndex ? brCanIndex : ovCanIndex, 1)
        filteredPhenotypes.splice(brCanIndex > ovCanIndex ? ovCanIndex : brCanIndex, 1)
        filteredPhenotypes.push('breast and ovarian cancer')
      }
      scope.survivorStrings = filteredPhenotypes.map(function(phenotype) {
        return generateSurvivorString(phenotype)
      })

      // To handle the last sentence that appears in H30 charts, only for negatives
      function generateNegativeH30String(reportContent) {
        if (reportContent.gender == 'F') {
          if (reportContent.breast_cancer_survivor && !reportContent.colorectal_cancer_survivor) {
            return ' Colorectal cancer is highlighted because it is more common.'
          } else if (!reportContent.breast_cancer_survivor && reportContent.colorectal_cancer_survivor) {
            return ' Breast cancer is highlighted because it is more common.'
          } else if (reportContent.breast_cancer_survivor && reportContent.colorectal_cancer_survivor) {
            return ''
          } else {
            return ' Breast and colorectal cancers are highlighted because they are more common.'
          }
        } else {
          if (reportContent.prostate_cancer_survivor && !reportContent.colorectal_cancer_survivor) {
            return ' Colorectal cancer is highlighted because it is more common.'
          } else if (!reportContent.prostate_cancer_survivor && reportContent.colorectal_cancer_survivor) {
            return ' Prostate cancer is highlighted because it is more common.'
          } else if (reportContent.prostate_cancer_survivor && reportContent.colorectal_cancer_survivor) {
            return ''
          } else {
            return ' Prostate and colorectal cancers are highlighted because they are more common.'
          }
        }
      }

      //- Hereditary 30 Negative append, with primary cancer survivor cases handled
      if (scope.reportContent.template.test_type == testTypes.hereditary30 && scope.reportContent.is_negative) {
        scope.h30NegativeString = generateNegativeH30String(scope.reportContent)
      }
    }
  }
}
