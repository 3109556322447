'use strict'

module.exports = function (auth, device) {
  'ngInject'

  return {
    restrict: 'EA',
    link: function(scope, elem, attr) {
      // Safari doesn't support Clipboard.js.
      scope.showCopyLink = !device.isSafari
      scope.referralCode = auth.currentUser.referral_code
      if (!auth.currentUser.should_see_referral_card) {
        elem.remove()
      }
    }
  }
}
