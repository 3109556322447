'use strict'

module.exports = function(
  $filter, $scope, $anchorScroll, kitStatuses, PhysicianOrder,
  PhysicianOrderType, testTypes) {
  'ngInject'
  $anchorScroll()

  // Take the latest activated test type.
  const kitStatus = $filter('orderBy')(kitStatuses.results, '-sample.activated_at')[0]
  $scope.isWisdom = kitStatus.test_type == testTypes.wisdom9
  $scope.kitStatus = kitStatus
  $scope.atLab = kitStatus.fulfillment.state == 'completed'

  $scope.isSampleAtOffice = kitStatus.physician_order &&
    kitStatus.physician_order.type === PhysicianOrderType.SAMPLE_AT_OFFICE
}
