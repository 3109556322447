/**
 * @ngdoc filter
 * @name customPhenotypes
 *
 * @description
 * Returns custom phenotypes for a given gene and report types.
 * This is written so that this customization logic lives in one place.
 * TODO: This requires better design/logic so that we don't even need
 * to do the case by case customization.
 */

'use strict'

module.exports = function($filter, namedMutation, testTypes) {
  'ngInject'

  return function(reportContent, gene, tier) {
    const gender = reportContent.gender
    let primary
    let secondary
    if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA1', 'CHEK2'])) {
      if (gender == 'M' && reportContent.template.test_type == testTypes.breastOvarian19) {
        primary = []
        secondary = ['colorectal cancer', 'prostate cancer']
      }
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['CHEK2', 'MSH2'])) {
      if (gender == 'M') {
        primary = ['prostate cancer']
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['CHEK2', 'MITF'])) {
      if (gender == 'M') {
        primary = ['prostate cancer']
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA2', 'CHEK2', 'MUTYH'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer', 'colorectal cancer']
      }
    } else if (gene == 'RAD51C' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA1', 'RAD51C'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['ovarian cancer']
      }
    } else if (gene == 'ATM' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['ATM', 'BRIP1']) && reportContent.template.test_type == testTypes.breastOvarian19) {
      // CONSUMER-6244: "ovarian cancer" is in risks as "not elevated" but appears as a primary unless manually removed
      if (gender == 'F') {
        primary = ['breast cancer']
        secondary = ['pancreatic cancer']
      }
    } else if (gene == 'ATM' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['ATM', 'BRCA1'])) {
      if (gender == 'M') {
        if (reportContent.template.test_type == testTypes.breastOvarian19) {
          primary = []
          secondary = ['pancreatic cancer']
        } else {
          primary = ['pancreatic cancer']
          secondary = ['prostate cancer']
        }
      }
    } else if (gene == 'ATM' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['ATM', 'PALB2'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer', 'pancreatic cancer']
      }
    } else if (gene == 'ATM' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['ATM', 'CHEK2'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer', 'pancreatic cancer']
      }
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA2', 'CHEK2', 'APC'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer', 'colorectal cancer']
      }
    } else if (gene == 'PALB2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA2', 'PALB2'])) {
      primary = []
      if (gender == 'F') {
        secondary = ['breast cancer', 'ovarian cancer', 'pancreatic cancer']
      } else {
        secondary = ['male breast cancer', 'pancreatic cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'BARD1']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      if (gender == 'F') {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['ATM', 'APC']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'CHEK2']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      if (gender == 'F') {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['MITF', 'APC']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'MSH2']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      if (gender == 'F') {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'MSH6']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      if (gender == 'F') {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'MUTYH']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k) && reportContent.render_version >= 10) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'MUTYH']) && !$filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'EPCAM', 'MUTYH']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'EPCAM' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'EPCAM', 'MUTYH']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = ['ovarian cancer', 'colorectal cancer', 'uterine cancer']
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'BRCA2']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'PMS2']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      if (gender == 'F') {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'APC' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['APC', 'TP53']) && $filter('containsMutation')(reportContent.mutations, namedMutation.apc_i1307k)) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'BARD1' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BARD1', 'CHEK2'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer']
      }
    } else if (gene == 'BARD1' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BARD1', 'BRCA1'])) {
      if (gender == 'F') {
        primary = []
        secondary = ['breast cancer']
      }
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA2', 'MUTYH'])) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['MSH6', 'MUTYH'])) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MSH6' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['MSH6']) && reportContent.allelicity_by_gene['MSH6'] == 'biallelic' && reportContent.mutations.length == 1 && reportContent.mutations[0].zygosity == 'Homozygous') {
      // MSH6 Homozygous does not show any risks in the chart section or other cancers section
      primary = []
      secondary = []
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA1', 'MUTYH']) && reportContent.allelicity_by_gene['MUTYH'] != 'biallelic') {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['CHEK2', 'MUTYH'])) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['MLH1', 'MUTYH'])) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['MSH2', 'MUTYH'])) {
      if (reportContent.allelicity_by_gene['MUTYH'] == 'biallelic') {
        primary = ['colorectal cancer']
        secondary = ['small bowel (duodenal) cancer']
      } else {
        primary = []
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'MUTYH' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['PMS2', 'MUTYH'])) {
      primary = []
      secondary = ['colorectal cancer']
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BRCA2', 'CHEK2'])) {
      if (gender == 'M') {
        primary = []
        secondary = ['colorectal cancer', 'prostate cancer']
      }
    } else if (gene == 'CHEK2' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['BMPR1A', 'CHEK2'])) {
      if (gender == 'M') {
        primary = ['prostate cancer']
        secondary = ['colorectal cancer']
      }
    } else if (gene == 'STK11' && $filter('containsAll')(reportContent.genes_with_pathogenic_mutations, ['STK11']) && reportContent.template.test_type == testTypes.breastOvarian19) {
      if (gender == 'F') {
        primary = ['breast cancer', 'colorectal cancer', 'stomach cancer']
        secondary = ['cervical cancer', 'lung cancer', 'ovarian cancer', 'pancreatic cancer', 'small bowel cancer', 'uterine cancer']
      }
    }

    if (tier == 'primary' && angular.isDefined(primary)) {
      return primary
    }
    if (tier == 'secondary' && angular.isDefined(secondary)) {
      return secondary
    }
    return undefined
  }

}
