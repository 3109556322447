'use strict'

module.exports = function() {
  'ngInject'

  return function(render_version) {
    // render_version changes to content
    // <= 2: Original set
    // >= 3: Updated breast, colorectal, and prostate content and references
    const guidelines = [
      {
        phenotype: 'breast cancer',
        content: '* **Starting at age 25:** Breast awareness - Women should be familiar with their breasts and promptly report changes to their healthcare provider.\n\n* **Between ages 25-39:** Breast exam by your provider every 1-3 years.\n\n* **Starting at age 40:** Breast exam by your provider and mammogram every year.',
        references: [128],
        gender: 'F'
      },
      {
        phenotype: 'male breast cancer',
        content: '* There are no specific breast cancer screening guidelines recommended for men without genetic mutations associated with increased risk of male breast cancer. Please speak with your healthcare provider about other cancer screenings that are recommended for men your age based on your personal and family health history.',
        references: [],
        gender: 'M'
      },
      {
        phenotype: 'colorectal cancer',
        content: '* **Starting at age 50:** Colonoscopy every 10 years, or stool-based testing every year, or flexible sigmoidoscopy every 5 years which may include stool-based testing at year three.\n\n* These recommendations may change if you have polyps, colon cancer, inflammatory bowel disease (IBD), or family history of colorectal cancer.',
        references: [165]
      },
      {
        phenotype: 'melanoma',
        content: '* To reduce the chance of developing melanoma, the American Cancer Society recommends limiting exposure to UV light by avoiding excess sun exposure, wearing a hat, sunglasses and long protective clothing, applying sunscreen with SPF of 30 or higher and avoiding tanning beds and sun lamps.\n\n* Any new, unusual, or changing moles should be reported to your provider or dermatologist.',
        references: [63]
      },
      {
        phenotype: 'ovarian cancer',
        content: '* Currently, there are no standard screening guidelines for ovarian cancer. Please discuss any family history of ovarian cancer with your healthcare provider.',
        references: [54],
        gender: 'F'
      },
      {
        phenotype: 'pancreatic cancer',
        content: '* Currently, there are no standard screening guidelines for pancreatic cancer. Please discuss any family history of pancreatic cancer with your healthcare provider.',
        references: []
      },
      {
        phenotype: 'stomach cancer',
        content: '* Currently, there are no standard screening guidelines for stomach cancer. Please discuss any family history of stomach cancer with your healthcare provider.',
        references: []
      },
      {
        phenotype: 'uterine cancer',
        content: '* **At the time of menopause:** All women should be told about the risks and symptoms of endometrial cancer. Women should report any unexpected vaginal bleeding or spotting to their doctors.\n\n* Some women, because of their history, may need to consider having a yearly uterine biopsy. Speak with a healthcare provider about your history.',
        references: [41],
        gender: 'F'
      },
      {
        phenotype: 'prostate cancer',
        content: '* **Starting at age 45:** Begin discussion with your provider about the risks and benefits of screening for prostate cancer every 2-4 years, including a blood test called PSA and a digital rectal exam (DRE).\n\n* **After age 75, with caution and only in very healthy men with little or no additional health issues:** PSA and DRE every 1-2 years.',
        references: [181],
        gender: 'M'
      },
      {
        phenotype: 'general recommendations for all individuals',
        content: '* Avoid all forms of tobacco.\n\n* Get to and stay at a healthy weight.\n\n* Get moving with regular physical activity.\n\n* Eat healthy with plenty of fruits and vegetables.\n\n* Limit how much alcohol you drink (if you drink at all).\n\n* Protect your skin.\n\n* Know yourself, your family history, and your risks.\n\n* Get regular check-ups and cancer screening tests. A cancer-related check-up should include health counseling and, depending on a person’s age and gender, exams for cancers of the thyroid, oral cavity, skin, lymph nodes, testes, and ovaries, as well as for some other diseases besides cancer.',
        references: [41]
      }
    ]
    if (render_version >= 3) {
      // Breast: Content additions, 1.2016 NCCN citation
      guidelines[0].content = '* **Starting at age 25:** Breast awareness - Women should be familiar with their breasts and promptly report changes to their healthcare provider.\n\n* **Between ages 25-39:** Breast exam, risk assessment, and risk reduction counseling by your provider every 1-3 years.\n\n* **Starting at age 40:** Breast exam by your provider and mammogram every year. Your provider may discuss screening with tomosynthesis.'
      guidelines[0].references = [228]
      // Colorectal: Content additions, 2.2016 NCCN citation
      guidelines[2].content = '* **Between ages 50-75:**\n\n  * Colonoscopy every 10 years, or\n\n  * Stool-based testing (high-sensitivity, guaiac-based, or immunochemical-based) every year, or\n\n  * Stool-based DNA testing every 3 years, or\n\n  * Flexible sigmoidoscopy every 5 years which may include guaiac- or immunochemical-based testing at year three, or\n\n  * CT colonography every 5 years.\n\n* These recommendations may change if you have polyps, colon cancer, inflammatory bowel disease (IBD), or family history of colorectal cancer.'
      guidelines[2].references = [229]
      // Prostate: every 1-2 years -> every 1-4 years, 2.2016 NCCN citation
      guidelines[8].content = '* **Starting at age 45:** Begin discussion with your provider about the risks and benefits of screening for prostate cancer every 2-4 years, including a blood test called PSA and a digital rectal exam (DRE).\n\n* **After age 75, with caution and only in very healthy men with little or no additional health issues:** PSA and DRE every 1-4 years.'
      guidelines[8].references = [208]
    }
    return guidelines
  }
}
