const template = pug`
  clinical-form-modal(modal-instance="modalInstance", on-submit="withdrawReleaseGroup")
    modal-title Withdraw Release Group?
    modal-body
      span(clr-spinner-text, active="!isFinishedLoading")

      div(ng-show="isFinishedLoading")
        p This action cannot be undone. It will affect all reports in release group {{releaseGroupId}}.

        // Even if we failed to fetch the reports in the ReleaseGroup, we still want the
        // modal to be usable. So we simply hide the section where we'd normally list
        // out the reports.
        div(ng-show="reportsInReleaseGroup.length > 0")
          p
            div The following report(s) will be withdrawn:
            ul
              li(ng-repeat="report in reportsInReleaseGroup")
                a(ng-click="openReport(report.id)") Report {{report.id}}

    submit-button Withdraw Release Group
`;

function controller($scope, $http, $location, $modalInstance, releaseGroupId) {
  $scope.modalInstance = $modalInstance;
  $scope.releaseGroupId = releaseGroupId;
  $scope.formInput = { description: "" };

  // Fetch the reports in the ReleaseGroup so that we can display links to them
  $scope.reportsInReleaseGroup = [];
  $scope.isFinishedLoading = false;
  $http
    .get("/api/v1/color_hub/reports", {
      params: { release_group_id: releaseGroupId },
    })
    .then((response) => {
      $scope.reportsInReleaseGroup = response.data;
    })
    .finally(() => {
      $scope.isFinishedLoading = true;
    });

  $scope.openReport = (reportId) => {
    $modalInstance.dismiss();
    $location.path(`/reports/${reportId}`);
  };

  $scope.withdrawReleaseGroup = () =>
    $http.post(`/api/v1/color_hub/release_groups/${releaseGroupId}/withdraw_reports`, {
      release_group_id: releaseGroupId,
    });
}

module.exports = ($modal) => {
  "ngInject";

  return {
    open: (releaseGroupId) =>
      $modal.open({
        size: "md",
        template,
        resolve: {
          releaseGroupId: () => releaseGroupId,
        },
        controller,
      }),
  };
};
