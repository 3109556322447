'use strict'

module.exports = function() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      provider: "=",
      popup: "="
    },
    replace: true,
    templateUrl: '/kit_orders/healthcare_provider.html'
  }
}
