'use strict'

module.exports = function(stateNames) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      ngModel: '='
    },
    templateUrl: '/users/us_state_select.html',
    link: function(scope, element) {
      scope.stateNames = stateNames
    }
  }
}
