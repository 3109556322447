module.exports = function ProviderPlatformPlaceOrderController(
  $scope, $cookies, $filter, $location, project, auth, DetailedPhysicianOrder, ProviderPlatformModals,
  PhysicianOrderFormData, PhysicianOrderFormFlow, fileUploader,
  physicianOrderCheckout, NotYetAvailableModal, promoCodeStore, testTypesBlockedInNewYork,
  uploadFileTypes, PhysicianOrderNavigationService, draft, waffle, testTypes, enrollment,
  patientIsMinor, ngToast, PhysicianOrderType, testTypesAllowedInEU, euCountries, sku
) {
  'ngInject'

  const Navigation = PhysicianOrderNavigationService

  Navigation.setState(Navigation.STATES.DETAILS)

  $scope.modals = ProviderPlatformModals

  $scope.NotYetAvailableModal = NotYetAvailableModal

  $scope.settings = {}
  if (project.application != 'clinical') {
    $scope.userProvider = auth.currentUser.provider_profile
    $scope.settings = $scope.userProvider.settings
  } else {
    // loose requirement for patient form fields in support site
    $scope.patientFormLooseRequirement = true
    // Clear all promocodes when placing orders in clinical
    promoCodeStore.clear()
  }

  $scope.order = PhysicianOrderFormData.getOrSetIfEmpty(
    DetailedPhysicianOrder.initializeWithSettings($scope.settings)
  )

  $scope.checkout = new physicianOrderCheckout($scope.order, $scope.userProvider)

  if ($scope.userProvider) {
    $scope.defaultCountry = $scope.userProvider.country
  } else {
    $scope.defaultCountry = "US"
  }

  if (!$scope.order.patient_profile.country) {
    $scope.order.patient_profile.country = $scope.defaultCountry
  }

  $scope.uploader = fileUploader({
    formData:[{
      physician_order_requisition_number: $scope.order.requisition_number,
      file_type: uploadFileTypes.physicianOrderFiles
    }]
  })

  $scope.uploader.onSuccessItem = function(item, response, status, headers) {
    $scope.order.upload_files = $scope.order.upload_files || []
    $scope.order.upload_files.push({
      s3_key: response
    })
  }

  // Show a warning if a non-US physician tries to order for a US patient.
  $scope.showUSWarning = function () {
    const patientInUS = $scope.order.patient_profile && $scope.order.patient_profile.country == "US"
    return patientInUS && angular.isDefined($scope.order.ordering_physician_profile) && $scope.order.ordering_physician_profile.country != "US"
  }

  function isPatientInNY() {
    return $scope.order.patient_profile && $scope.order.patient_profile.country == "US" && $scope.order.patient_profile.state == "NY"
  }
  function isProviderInNy() {
    return $scope.order.ordering_physician_profile && $scope.order.ordering_physician_profile.state == 'NY'
  }
  // Show a focus ordering physician
  $scope.showNYBlock = function() {
    if ($scope.order.type && $scope.order.type != 'sample_ordered') {
      // For KIO orders, we expect the sample to provided outside of NY or the physician is in NY. CPP-1156
      return false
    }
    return isPatientInNY() && !isProviderInNy()
  }

  $scope.showTestSpecificNyBlock = function() {
    if (testTypesBlockedInNewYork.indexOf($scope.order.test_requested) < 0) {
      return false
    }
    // For Ship to home block if patient is in NY
    if ($scope.order.type && $scope.order.type == 'sample_ordered') {
      return isPatientInNY()
    } else {
      return isProviderInNy()
    }
  }

  $scope.showNotYetAvailableBlockPatient = () => {
    return euCountries.includes($scope.order.patient_profile.country) &&
      !testTypesAllowedInEU.includes($scope.order.test_requested) &&
      $scope.order.type == 'sample_ordered'
  }

  $scope.continue = function(order) {
    if (!validateOrder(order)) {
      return
    }

    if (order.type == 'sample_ordered' && !$scope.settings.allow_ship_to_home_payment) {
      order.skip_payment = true
    }

    PhysicianOrderFormData.set(order)

    PhysicianOrderFormFlow.goTo(
      Navigation.URLS[Navigation.STATES.PAYMENT],
      true
    )
  }

  function _checkMinorTestType(order) {
    //check whether trying to order a non-approved test for a minor
    const is_minor = patientIsMinor(new Date(Date.parse(order.patient_profile.birthday)))
    const is_kio = order.type === PhysicianOrderType.SAMPLE_AT_OFFICE
    const is_whitelisted_test = [testTypes.fh3, testTypes.cardio30].indexOf(order.test_requested) !== -1
    if (is_minor) {
      return is_kio && is_whitelisted_test
    } else {
      return true
    }
  }

  function validateOrder(order) {
    const testName = sku[order.test_requested].productName

    if (!_checkMinorTestType(order)) {
      // if the test_requested field was untouched, validity not yet have been checked
      return $scope.form.test_requested.checkMinor()
    }

    if ($scope.showUSWarning()) {
      ProviderPlatformModals.openUSProvidersForUSPatientsModal()
      return false
    }
    if ($scope.showNYBlock()) {
      ProviderPlatformModals.openNYBlockModal()
      return false
    }
    if ($scope.showTestSpecificNyBlock()) {
      NotYetAvailableModal.open('NY', testName)
      return false
    }
    if ($scope.showNotYetAvailableBlockPatient()) {
      const countryName = $filter('name')(order.patient_profile.country)
      NotYetAvailableModal.open(countryName, testName)
      return false
    }

    if (!$scope.form.validate()) {
      return false
    }
    if (!$scope.uploader.doneUploading(true)) {
      return false
    }
    return true
  }

  $scope.addAdditionalRecipient = function() {
    if ($scope.order.additional_recipients.length < 4) {
      $scope.order.additional_recipients.push({})
    }
  }

  $scope.removeAdditionalRecipient = function(index) {
    $scope.order.additional_recipients.splice(index, 1)
  }

  if (draft) {
    $scope.order = DetailedPhysicianOrder.loadFromDraft($scope.order, draft)
    PhysicianOrderFormData.set($scope.order)
  } else if (enrollment) {
    $scope.order = DetailedPhysicianOrder.loadFromEnrollment($scope.order, enrollment)
    PhysicianOrderFormData.set($scope.order)
  }

  const isSupportStaff = Boolean(auth.currentUser.is_support_staff)
  // As of Nov 1 2024, we are blocking CPP orders for all external users
  if (!isSupportStaff) {
    return $location.url('/providers/home')
  }
}
