const formatPhone = require('monoweb/django_apps/core/static/js/format_phone');

!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrFilter
   *
   * @description
   * The `clrFilter` is a collection of commonly used filters.
   */
  const module = angular.module('clrFilter', ['clrPhoneNumbers'])

  /**
   * @ngdoc filter
   * @name ordinalNumber
   * @memberof clrFilter
   *
   * @description
   * Converts integer into ordinal number string.
   * Supports up to Twentieth.
   */
  module.filter('ordinalNumber', function() {
    const suffixes = ['th', 'teenth'];
    const numStrs = ['', '', '', '', 'Four', 'Fif', 'Six', 'Seven', 'Eigh', 'Nine'];
    const specialCases = {
      1: 'First',
      2: 'Second',
      3: 'Third',
      10:'Tenth',
      11: 'Eleventh',
      12: 'Twelfth',
      13: 'Thirteenth',
      20: 'Twentieth'
    }

    return function (number) {
      if (number > 20) {
        return number
      }
      if (specialCases[number]) {
        return specialCases[number]
      }

      return numStrs[number % 10] + suffixes[Math.floor(number / 10)]
    }
  })

  /**
   * @ngdoc filter
   * @name pluralize
   * @memberof clrFilter
   *
   * @description
   * Appends "_PLURAL" to a given message bundle key
   * if a given count is more than 1.
   */
  module.filter('pluralize', function() {
    return function(msg, count) {
      if (count > 1) {
        msg += '_PLURAL'
      }
      return msg
    }
  })

  module.filter('genderNoun', function() {
    return function (gender, plural) {
      if (plural) {
        return gender == 'F' ? 'WOMEN' : 'MEN'
      } else {
        return gender == 'F' ? 'WOMAN' : 'MAN'
      }
    }
  })

  module.filter('genderPronoun', function() {
    return function (gender) {
      return gender == 'F' ? 'SHE' : 'HE'
    }
  })

  /**
   * @ngdoc filter
   * @name formatCentsSimple
   * @memberof clrFilter
   *
   * @description
   * Converts an integral number of cents into a simple currency string. If a
   * whole number of dollars, doesn't display a '.00', and adds commas every
   * three digits.
   */
  module.filter('formatCentsSimple', function(numberFilter) {
    return function(cents) {
      if (!angular.isNumber(cents)) {
        return cents
      } else if (cents % 100 == 0) {
        return numberFilter(cents / 100, 0)
      } else {
        return numberFilter(cents / 100, 2)
      }
    }
  })

  /**
   * @ngdoc filter
   * @name capitalize
   * @memberof clrFilter
   *
   * @description
   * Capitalizes first word, lowercases rest, if format is 'first',
   * capitalizes first word only if format is 'firstOnly',
   * capitalizes 2-letter words if format is 'team',
   * and capitalizes all words if format is undefined.
   */
  module.filter('capitalize', function () {
    return function (words, format) {
      if (!words) {
        return words
      }

      if (format == 'first') {
        // Capitalize the first letter of a sentence
        return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase()
      } if (format == 'firstOnly') {
        return words.charAt(0).toUpperCase() + words.slice(1)
      } else {
        words = words.split(' ');
        const capitalizedWords = [];

        words.forEach(function(word) {
          if (word.length == 2 && format == 'team') {
            // Uppercase team abbreviations like FC, CD, SD
            capitalizedWords.push(word.toUpperCase())
          } else {
            capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          }
        })
        return capitalizedWords.join(' ')
      }
    }
  })

  module.filter('joinEnglishList', function() {
    return function (words, oxComma) {
      if (words.length <= 2) {
        return words.join(' and ')
      } else {
        if (!oxComma) {
          return words.slice(0, words.length - 1).join(', ') + ' and ' + words[words.length - 1]
        } else {
          return words.slice(0, words.length - 1).join(', ') + ', and ' + words[words.length - 1]
        }
      }
    }
  })

  /**
   * @ngdoc filter
   * @name ordinalSuffix
   * @memberof clrFilter
   *
   * @description
   * Adds an ordinal suffix to a number.
   * Supports -st, -nd, -rd, -th.
   */
  module.filter('ordinalSuffix', function() {
    const defaultSuffix = "th";
    const specialCases = {
      1: 'st',
      2: 'nd',
      3: 'rd'
    }

    return function (number) {
      if (number > 3) {
        return number.toString() + defaultSuffix
      } else {
        return number.toString() + specialCases[number]
      }
    }
  })

  /**
   * @ngdoc filter
   * @name camel
   * @memberof clrFilter
   *
   * @description
   * Converts a string into camel case.
   */
  module.filter('camel', function() {
    return function(str) {
      return str.replace(/(\s[a-z])/g, function($1) {
        return $1.toUpperCase().replace(' ','')
      })
    }
  })

  /**
   * @ngdoc filter
   * @name replace
   * @memberof clrFilter
   *
   * @description
   * Replace all occurance of search strings.
   */
  module.filter('replace', function() {
    return function(str, search, replace) {
      return str.replace(new RegExp(search, 'g'), replace)
    }
  })

  /**
   * @ngdoc filter
   * @name barcode
   * @memberof clrFilter
   *
   * @description
   * Converts a 14 digit integer into a barcode string
   */
  module.filter('barcode', function() {
    return function(str) {
      if (angular.isDefined(str) && str.length == 14) {
        return str.substring(0,2) + "-" + str.substring(2,6) + "-" + str.substring(6,10) + "-" + str.substring(10,14)
      } else {
        return str
      }
    }
  })

  /**
   * @ngdoc filter
   * @name tel
   * @memberof clrFilter
   *
   * @description
   * Converts a phone number integer value into
   * (xxx) xxx-xxxx or +x (xxx) xxx-xxxx or +x-xxx-xxx-xxxx
   */
  module.filter('tel', function () {
    return formatPhone
  })

  /**
   * @ngdoc filter
   * @name html
   * @memberof clrFilter
   *
   * @description
   * Renders text as html
   */
  module.filter('html', function ($sce) {
    return function (str) {
      return $sce.trustAsHtml(str)
    }
  })

  /**
   * @ngdoc filter
   * @name nullBooleanString
   * @memberof clrFilter
   *
   * @description
   * Converts null boolean value into its string representation.
   */
  module.filter('nullBooleanString', function() {
    return function(value, placeholder) {
      if (value == true) {
        return 'Yes'
      } else if (value == false) {
        return 'No'
      } else {
        return placeholder || 'Unknown'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name unique
   * @memberof clrFilter
   *
   * @description
   * (from https://github.com/a8m/angular-filter/blob/master/src/_filter/collection/unique.js)
   * get collection and filter duplicate members
   * if uniqueFilter get a property(nested to) as argument it's
   * filter by this property as unique identifier
   */
  module.filter('unique', function ($parse) {
    return function (collection, property) {

      // from a8m/angular-filter
      function toArray(object) {
        return angular.isArray(object) ? object : Object.keys(object).map(function(key) {
          return object[key]
        })
      }

      if (angular.isUndefined(collection)) {
        return false
      }

      collection = angular.isObject(collection) ? toArray(collection) : collection

      if (!angular.isArray(collection)) {
        return collection
      }

      //store all unique identifiers
      const uniqueItems = [];
      const get = $parse(property)

      return angular.isUndefined(property)
      //if it's kind of primitive array
        ? collection.filter(function (elm, pos, self) {
          return self.indexOf(elm) === pos
        })
      //else compare with equals
        : collection.filter(function (elm) {
          const prop = get(elm)
          if (some(uniqueItems, prop)) {
            return false
          }
          uniqueItems.push(prop)
          return true
        })

      //checked if the unique identifier is already exist
      function some(array, member) {
        if (angular.isUndefined(member)) {
          return false
        }
        return array.some(function(el) {
          return angular.equals(el, member)
        })
      }
    }
  })

  /**
   * @ngdoc filter
   * @name containsAny
   * @memberof clrFilter
   *
   * @description
   * Returns true if array contains any value inside another array
   */
  module.filter('containsAny', function() {
    return function(arr1, arr2) {
      if (angular.isArray(arr1) && angular.isArray(arr2)) {
        return arr2.some(function (v) {
          return arr1.indexOf(v) >= 0
        })
      }
    }
  })

  /**
   * @ngdoc filter
   * @name containsNone
   * @memberof clrFilter
   *
   * @description
   * Returns true if array contains no value from another array
   */
  module.filter('containsNone', function() {
    return function(arr1, arr2) {
      if (angular.isArray(arr1) && angular.isArray(arr2)) {
        const result = arr1.filter(function(item){ return arr2.indexOf(item) > -1})
        return result.length == 0
      }
    }
  })

  /**
   * @ngdoc filter
   * @name containsAll
   * @memberof clrFilter
   *
   * @description
   * Returns true if array contains all values of another array
   */
  module.filter('containsAll', function() {
    return function(arr1, arr2) {
      if (angular.isArray(arr1) && angular.isArray(arr2)) {
        return !!arr2.every(function(val) { return arr1.indexOf(val) >= 0 })
      }
    }
  })

  /**
   * @ngdoc filter
   * @name urlEncode
   * @memberof clrFilter
   *
   * @description
   * Returns url-encoded text
   */
  module.filter('urlEncode', function($window) {
    return $window.encodeURIComponent
  })

  /**
   * @ngdoc filter
   * @name limitRepeatTo
   * @memberof clrFilter
   *
   * @description
   * Add third param to `limitTo` $filter to allow for an offset
   */
  module.filter('limitRepeatTo', function() {
    return function(input, limit, begin) {
      if (angular.isDefined(input)) {
        return input.slice(begin, begin + limit)
      } else {
        return input
      }
    }
  })

  module.filter('googleSearchLink', function($window) {
    return function(terms, resultsSinceDate) {
      let dateFilterString = ''
      if (resultsSinceDate) {
        const monthsSince = Math.ceil((new Date() - new Date(resultsSinceDate)) / (1000*60*60*24*30))
        // as_qdr filters to results indexed in the past specified time period.
        // Docs here: https://developers.google.com/custom-search/docs/xml_results
        dateFilterString = '&as_qdr=m' + monthsSince
      }
      return 'https://www.google.com/search?q=' + $window.encodeURIComponent(terms) + dateFilterString
    }
  })

  module.filter('scholarSearchLink', function($window, $filter) {
    const maxScholarQueryLen = 255
    return function(terms, resultsSinceDate) {
      if (angular.isDefined(terms) && terms.length > maxScholarQueryLen) {
        // Truncate, and drop the last (possibly truncated) term as well as its preceding 'OR', if needed
        terms = terms.substring(0, maxScholarQueryLen).replace(/( OR )?(\S+)$/, ')')
      }
      let dateFilterString = ''
      if (resultsSinceDate) {
        // as_ylo filters to results published in the given year or later
        dateFilterString = '&as_ylo=' + $filter('date')(resultsSinceDate, 'yyyy')
      }
      return 'https://scholar.google.com/scholar?q=' + $window.encodeURIComponent(terms) + dateFilterString
    }
  })

  module.filter('clinvarSearchLink', function($window) {
    return function(searchTerm) {
      return 'https://www.ncbi.nlm.nih.gov/clinvar/?term=' + $window.encodeURIComponent(searchTerm)
    }
  })

  module.filter('testTypeName', function($filter, testTypes) {
    return function(testType) {
      if (testType == testTypes.pgxv1) {
        return "PGx v1"
      } else if (testType == testTypes.brca12 || testType == testTypes.fh3) {
        return testType.toUpperCase()
      } else {
        return $filter('capitalize')(testType)
      }
    }
  })

  module.filter('testTypeNames', function($filter) {
    return function(testTypes) {
      const testTypeNames = testTypes.map((testType) => $filter('testTypeName')(testType))
      return testTypeNames.join(', ')
    }
  })

  /**
   * @ngdoc filter
   * @name withBaseUrl
   * @memberof clrFilter
   *
   * @description
   * Returns the given string with the current base host prepended.
   */
  module.filter('withBaseUrl', function(wwwBaseHostUrl) {
    return function(url) {
      return wwwBaseHostUrl + url
    }
  })

  /**
   * @ngdoc filter
   * @name withLoggedInBaseUrl
   * @memberof clrFilter
   *
   * @description
   * Returns the given string with the logged-in hostname prepended.
   */
  module.filter('withLoggedInBaseUrl', function(wwwLoggedInBaseHostUrl) {
    return function(url) {
      return wwwLoggedInBaseHostUrl + url
    }
  })

  /**
   * @ngdoc filter
   * @name getFileNameFromS3Key
   * @memberof clrFilter
   *
   * @description
   * Returns the filename of the given S3 key.
   */
  module.filter('getFileNameFromS3Key', function() {
    return function(s3Key) {
      // The keys are generated with the format:
      // 'documents/{user_id}/{date}_{filename}'
      return s3Key.slice(s3Key.indexOf('_') + 1)
    }
  })

  /**
   * @ngdoc filter
   * @name internalRoleToUserFacingRole
   * @memberof clrFilter
   *
   * @description
   * Returns the user facing role name from the internal role constant.
   */
  module.filter('internalRoleToUserFacingRole', function(internalProviderRoles) {
    const _map = internalProviderRoles
      .reduce(function(acc, curr) {
        const roleConstant = curr[0]
        const roleName = curr[1]

        acc[roleConstant] = roleName
        return acc
      }, {})

    return function(internalRole) {
      return _map[internalRole]
    }
  })

  /**
   * @ngdoc filter
   * @name phonePrefixToCountryCode
   * @memberof clrFilter
   *
   * @description
   * Returns a best guess at the country code given a phone prefix.
   */
  module.filter('phonePrefixToCountryCode', function(geographicalArea) {
    return function(phonePrefix) {
      if (phonePrefix[0] !== '+') {
        phonePrefix = '+' + phonePrefix
      }

      return Object.keys(geographicalArea).filter(function(countryCode) {
        const information = geographicalArea[countryCode]
        const currentPhonePrefix = information[1]
        return currentPhonePrefix === phonePrefix
      })[0]
    }
  })

  /**
   * @ngdoc filter
   * @name cleanCurrency
   * @memberof clrFilter
   *
   * @description
   * Returns a two-decimal formatted currency, except when the value
   * has no fractional component, in which case the trailing
   * zeroes are removed.
   */
  module.filter('cleanCurrency', function($filter) {
    return function(amount, symbol) {
      if (amount % 1 == 0) {
        return $filter('currency')(amount, symbol, 0)
      } else {
        return $filter('currency')(amount, symbol, 2)
      }
    }
  })

  /**
   * @ngdoc filter
   * @name commaSeparatedList
   * @memberof clrFilter
   *
   * @description
   * Returns the array as a string, with elements separated by commas.
   */
  module.filter('commaSeparatedList', function() {
    return function(arr) {
      return arr.join(', ')
    }
  })

  /**
   * @ngdoc filter
   * @name cancerTypeToDisplayName
   * @memberof clrFilter
   *
   * @description
   * Returns the given cancer type's display name.
   */
  module.filter('cancerTypeToDisplayName', function(cancerTypes) {
    const cancerTypeToDisplayNameMap = Object.keys(cancerTypes)
      .map(function(key) {
        return cancerTypes[key]
      })
      .reduce(function(map, tuple) {
        const displayName = tuple[0]
        const cancerType = tuple[1]
        map[cancerType] = displayName
        return map
      }, {})

    return function(cancerType) {
      return cancerTypeToDisplayNameMap[cancerType]
    }
  })

  /**
   * @ngdoc filter
   * @name clrDate
   * @memberof clrFilter
   *
   * @description
   * Returns a datestring formatted as MMM dd, yyyy (e.g. 'Sep 08, 2017')
   * with optional prepended string (e.g. 'on', 'at', etc.)
  */
  module.filter('clrDate', function($filter) {
    return function(date, prependString) {
      prependString = prependString ? prependString + ' ' : '';
      let dateString = '';
      if (typeof date !== 'undefined' && date !== null) {
        dateString = date ? $filter('date')(new Date(date), 'MMM dd, yyyy') : '';
      }
      return prependString + dateString;
    }
  })

  /**
   * @ngdoc filter
   * @name percentage
   * @memberof colorApp
   *
   * @description
   * Converts decimal fractions into a percentage representation.
   */
  module.filter('percentage', function () {
    return function (input, decimals) {
      if (angular.isUndefined(input) || input === null || input === '') {
        return ''
      }
      const inputInStr = (parseFloat(input) * 100).toString();
      const splittedInput = inputInStr.split('.');
      const wholeNumber = splittedInput[0]

      if (splittedInput.length == 1 || decimals == 0) {
        return wholeNumber + '%'
      } else {
        const fraction = splittedInput[1];
        const end = Math.min(fraction.length, decimals)
        return wholeNumber + '.' + fraction.substr(0, end) + '%'
      }
    }
  })

  /**
   * @ngdoc filter
   * @name eqSet
   * @memberof colorApp
   *
   * @description
   * Determines whether two sets are equal.
   *
   * Inspired from below:
   * https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
   */
  module.filter('eqSet', function () {
    return function(set1, set2) {
      if (set1.size !== set2.size) {
        return false;
      }

      for (const el1 of set1) {
        if (!set2.has(el1)) {
          return false;
        }
      }

      for (const el2 of set2) {
        if (!set1.has(el2)) {
          return false;
        }
      }

      return true;
    }
  })

  /**
   * @ngdoc filter
   * @name range
   * @memberof clrFilter
   *
   * @description
   * returns an array of values incremented by one up to and including total
   */
  module.filter('range', function() {
    return function(input, total) {
      for (let i = 0; i <= total; i++) {
        input.push(i)
      }
      return input
    }
  })

}();
