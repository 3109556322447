'use strict'

module.exports = function() {
  return function(name) {
    const simpleName = /^([\w\-]+)(?: ([\w\-]+\.?))? ([\w\-]+)$/.exec(name)
    if (simpleName) {
      return { firstName: simpleName[1], middleName: simpleName[2], lastName: simpleName[3] }
    } else {
      return null
    }
  }
}
