'use strict'

module.exports = function($scope, $modal, $location, sampleReuseFlow, waffle, sampleReuseService, testTypes) {
  'ngInject'

  const sku = sampleReuseFlow.checkout.sku
  if (!sampleReuseService.eligibleForReuse(sku.name)) {
    // If sample reuse is not possible, go back to purchase flow
    $location.path('/purchase').search({sku: sku.name})
  }

  $scope.checkout = sampleReuseFlow.checkout
  $scope.goToNext = function() {
    sampleReuseFlow.goTo('/sample-reuse/consent', true)
  }

  $scope.showOrderingPhysicianModal = function() {
    $modal.open({
      templateUrl: '/kit_orders/modals/ordering_physician.html',
      size: 'md'
    })
  }

  $scope.onChangeSku = function(physicianOrder) {
    $location.path('/purchase').search({'sku': $scope.checkout.sku.name})
  }
}
