'use strict'

module.exports = function ProviderPlatformResubmitOrderController(
  $scope,
  $location,
  $q,
  $routeParams,
  $timeout,
  auth,
  ngToast,
  physicianOrderCheckout,
  DetailedPhysicianOrder,
  PhysicianOrderPayment
) {
  'ngInject'

  $scope.submitting = false

  const goToOrderDetailsPage = () => $location.url('/providers/order/details/' + $scope.order.requisition_number)

  const requisitionNumber = $routeParams['requisition_number']
  DetailedPhysicianOrder.getFromRequisitionNumber(requisitionNumber)
    .then((order) => {
      $scope.order = order
      $scope.checkout = new physicianOrderCheckout(order, auth.currentUser.provider_profile, order.test_requested)

      // Cannot edit an order that has a payment already
      if (!order.skip_payment) {
        goToOrderDetailsPage()
      }
    })

  $scope.handleSubmit = () => {
    let promise
    if ($scope.order.payment.meetsInsuranceCheck()) {
      promise = $q.when()
    } else {
      // Handle switched-to-cash flow
      promise = $scope.checkout.reviewPayment()
    }

    return promise
      .then(() => DetailedPhysicianOrder.update($scope.order).$promise)
      .then(() => {
        goToOrderDetailsPage()
        $timeout(() => ngToast.create('Order has been successfully updated'), 1000)
      })
      .catch((response) => {
        const errorMessage = (response.data && response.data.non_field_errors) ?
          response.data.non_field_errors.join(', ') :
          'Update failed'

        ngToast.create({
          className: 'error',
          content: errorMessage
        })
      })
  }
}
