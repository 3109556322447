"use strict"

/**
 * File uploader.
 *
 * The uploader scope var must be a FileUploader.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/provider_uploader.html',
    scope: {
      uploader: '=',
      label: '@'
    },
    link: function(scope) {
      scope.removeFile = function(file) {
        file.cancel()
        file.remove()
        if (scope.uploader.onRemoveFile) {
          scope.uploader.onRemoveFile(file)
        }
      }

      // Set the uploaded file type to the currently selected fileType
      scope.uploader.onBeforeUploadItem = (fileItem) => {
        if (fileItem.formData.length == 0) {
          fileItem.formData = [{}]
        }
        fileItem.formData[0].file_type = scope.fileType
        fileItem.file_type = scope.fileType
      }

      scope.toggleDropdown = () => scope.dropdownOpened = !scope.dropdownOpened

      scope.triggerUploadDialog = (fileType) => {
        scope.dropdownOpened = false
        scope.fileType = fileType
      }
    }
  }
}
