module.exports = function physicianOrderLog($rootScope, $routeParams, DetailedPhysicianOrder) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/order-log.html',
    link: function(scope) {
      function updateLog() {
        return DetailedPhysicianOrder.get({'requisition_number': $routeParams.requisition_number}).$promise
          .then(function(res) {
            scope.order = res
          })
      }
      $rootScope.$on('physicianOrderLog:updated', function() {
        updateLog()
      })
    }
  }
}
