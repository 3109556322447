const videoIds = {
  // Precounseling videos before July 2017
  pretestBrca12: {
    vimeoId: 234543923
  },
  pretestBreastOvarian19: {
    vimeoId: 124322533
  },
  pretestHereditary30: {
    vimeoId: 163089926,
  },
  // New precounseling videos July 2017
  preCounselCancer: {
    vimeoId: 224364319
  },
  preCounselCombo: {
    // 8/25/17: Updated to V3, FIXIT-84
    vimeoId: 231118714
  },
  preCounselFh: {
    vimeoId: 227482214
  },
  preCounselCardio30: {
    vimeoId: 261163566
  },
  preCounselCombo2: {
    vimeoId: 261213066
  },
  preCounselCore10: {
    vimeoId: 271742191
  },
  sample: {
    vimeoId: 120631163,
  },
  storiesRobin: {
    vimeoId: 292217666
  },
  // Benefits-specific video variations (usually an end-card with "/benefits" text)
  benefitsNvidiaStory: {
    vimeoId: 292050989
  },
  benefitsMarissaStory: {
    vimeoId: 292050867
  },
  benefitsStoriesPam: {
    vimeoId: 229040189
  },
}

!function() {

  'use strict'

  const module = angular.module('clrVideo', [])

  module.factory('VideoService', function() {

    function vimeoEmbed(id) {
      return "<iframe src='https://player.vimeo.com/video/" + id + "?color=aae2e6' width='500' height='281' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>"
    }

    const videos = videoIds
    // Add embed IDs used on press page
    angular.forEach(videos, function(video) {
      if (video.vimeoId) {
        video.embed = vimeoEmbed(video.vimeoId)
      }
    })
    return {
      videos: videos
    }
  })

  /**
   * @ngdoc service
   * @name vimeoEmbedPlayer
   * @memberof clrVideo
   *
   * @description
   * A Vimeo video player directive
   *
   * To Use:
   * <div vimeo-embed-player='videoNameFromVideoService' autoplay='true' delay="250"></div>
   */
  module.directive('vimeoEmbedPlayer', function($sce, VideoService) {
    // Docs: https://github.com/vimeo/player.js
    return {
      restrict: 'A',
      // priority: 999, // must be higher than ng-repeat's priority of 1000
      link: function(scope, elem, attrs) {
        const videoName = attrs.vimeoEmbedPlayer
        const autoplay = attrs.autoplay ? attrs.autoplay : true
        const vimeo = VideoService.videos[videoName]
        if (vimeo) {
          const url = $sce.trustAsResourceUrl('https://player.vimeo.com/video/' + vimeo.vimeoId + (autoplay ? '?autoplay=1' : ''))
          elem.append('<iframe src="' + url + '" frameborder="0" allow="autoplay" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>')
        } else {
          console.info('No Vimeo video ID found for: ' + videoName)
        }
      }
    }
  })

  /**
   * @ngdoc service
   * @name vimeoModalPlayer
   * @memberof clrVideo
   *
   * @description
   * A Vimeo video player directive
   *
   * To Use:
   * <a vimeo-modal-player='videoNameFromVideoService' autoplay='true'>Play Video</a>
   */
  module.directive('vimeoModalPlayer', function(VideoService, vimeoModal) {
    // Docs: https://github.com/vimeo/player.js
    return {
      restrict: 'A',
      priority: 599, // must be lower than ng-repeat's priority of 1000
      link: function(scope, elem, attrs) {
        const videoName = attrs.vimeoModalPlayer
        const autoplay = attrs.autoplay ? attrs.autoplay : true
        const vimeo = VideoService.videos[videoName]
        if (angular.isDefined(vimeo)) {
          elem.on('click', function() {
            vimeoModal.open('https://player.vimeo.com/video/' + vimeo.vimeoId, autoplay)
          })
        } else {
          console.info('No Vimeo video ID found for: ' + videoName)
        }
      }
    }
  })

  function VimeoModalController($scope, $modalInstance, $sce, vimeoUrl) {
    $scope.vimeoUrl = $sce.trustAsResourceUrl(vimeoUrl)
    $scope.done = function() {
      $modalInstance.close()
    }
  }

  /**
   * @ngdoc service
   * @name vimeoModal
   * @memberof clrVideo
   *
   * @description
   * A Vimeo video modal.
   */
  module.factory('vimeoModal', function($modal) {
    return {
      open: function(vimeoUrl, autoplay) {
        return $modal.open({
          template: '<div class="modal-body video-modal vimeo-wrapper"><iframe ng-src="{{vimeoUrl}}" allow="autoplay" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
          controller: VimeoModalController,
          size: 'lg',
          resolve: {
            vimeoUrl: function() {
              return vimeoUrl + (autoplay ? '?autoplay=1' : '')
            }
          }
        })
      }
    }
  })

}();
