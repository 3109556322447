const SchedulizerServices = require('monoweb/django_apps/counseling/js/schedulizer/services')

module.exports = angular.module('schedulizer', [
  SchedulizerServices.name
])
  .config(function(colorRouteProvider) {
    const requireLoginAndConfirmation = {
      requireLogin: true,
      requireEmailConfirmation: true
    }

    colorRouteProvider
      .when('/counseling/schedulizer/iframe', {
        templateUrl: '/angular_js_templates/schedulizer/schedulizer_iframe.html',
        controller: require('monoweb/django_apps/counseling/js/schedulizer/controllers/schedulizer_iframe_controller'),
        auth: requireLoginAndConfirmation,
      })
  })
