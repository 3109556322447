module.exports = function eligibilitySubmissionCard() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      eligibility: '=',
    },
    templateUrl: '/ordering_physicians/directives/eligibility_submission_card.html',
  }
}
