module.exports = function ReportWrapperController($scope, report, isPDF, $translatePartialLoader) {
  'ngInject'
  $scope.report = report;
  $scope.isPDF = isPDF;
  $scope.isFH3 = false

  $translatePartialLoader.addPart('report')

  // in the near future, generated_content will be empty for v2 reports.
  if (!report.generated_content) {
    $scope.showV2 = true;
    $scope.showPDF = false;
  } else {
    $scope.showV2 = !!report.generated_content.show_v2;
    $scope.showPDF = report.generated_content.test_outcome == 'non-reportable';
    if (!$scope.showV2) {
      $scope.isFH3 = report.generated_content.template.test_type == 'fh 3';
    }
  }
}
