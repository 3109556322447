module.exports = function orderingPhysicianSelector(DetailedProviderProfiles) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/select_ordering_physician.html',
    scope: {
      userProvider: '=',
      order: '='
    },
    link: function(scope) {
      // Only default to last ordering physician if total number of providers
      // is less than MaxProviderCountForDefault
      const maxProvidersForDefaultSelected = 5
      if (!scope.userProvider.default_as_ordering_physician && !scope.physicianChoices) {
        // Get all unique physicians listed in previous orders user had made
        DetailedProviderProfiles.get({'ordering': 'last_name'}).$promise
          .then(function(res) {
            scope.physicianChoices = res.results
            scope.physicianChoicesCount = scope.physicianChoices.length
            if (scope.physicianChoicesCount && scope.physicianChoicesCount <= maxProvidersForDefaultSelected) {
              scope.order.ordering_physician_profile = scope.physicianChoices[0]
            }
          })
      }

      scope.isVantari = scope.userProvider.settings.distributor_name == 'vantari'
      scope.defaultCountry = "US"

      if (scope.userProvider.default_as_ordering_physician) {
        scope.order.ordering_physician_profile = scope.userProvider
      }

      scope.showSelectedProvider = function() {
        return scope.order.ordering_physician_profile && scope.order.ordering_physician_profile.id
      }
    }
  }
}
