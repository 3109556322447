module.exports = function ReportReviewListController(
  $scope, $window, $routeParams, $timeout
  , $filter, ngToast, auth
  , resourcePaginator, testTypes, Report
  , approveReleaseModal, releaseToPhysicianModal
  , reportNotReadyState, reportNewState, reportContentReviewedState
  , reportQueuedforBoardReviewState, reportPreparedForBoardReviewState
  , reportBoardReviewedState, reportSignOutApprovedState, reportSignedOffState
  , reportReleaseGatedState, reportReleaseApprovedState, reportSentToPhysicianState
  , reportReleaseReadyState, reportReleasedState, testOutcome) {
  'ngInject'

  const pageSize = 30
  const state = $routeParams.state;
  const pathologistReviewState = [reportContentReviewedState, reportBoardReviewedState].join(',');
  const isPreSignoff = [reportNotReadyState, reportNewState, reportQueuedforBoardReviewState, reportPreparedForBoardReviewState, pathologistReviewState, reportSignOutApprovedState].indexOf(state) != -1;
  const isPreReleaseApproval = isPreSignoff || state == reportSignedOffState;
  const isPreRelease = isPreReleaseApproval || [reportReleaseApprovedState, reportSentToPhysicianState, reportReleaseReadyState].indexOf(state) != -1

  $scope.states = [
    reportNotReadyState,
    reportNewState,
    reportQueuedforBoardReviewState,
    reportPreparedForBoardReviewState,
    pathologistReviewState,
    reportSignOutApprovedState,
    reportSignedOffState,
    reportReleaseGatedState,
    reportReleaseApprovedState,
    reportSentToPhysicianState,
    reportReleaseReadyState,
    reportReleasedState,
    'all'
  ]

  $scope.stateToTitle = {}
  $scope.stateToTitle[reportNotReadyState] = 'Not Ready'
  $scope.stateToTitle[reportNewState] = 'GC Review'
  $scope.stateToTitle[reportQueuedforBoardReviewState] = 'Prepare Genetics Board Review'
  $scope.stateToTitle[reportPreparedForBoardReviewState] = 'Genetics Board Review'
  $scope.stateToTitle[pathologistReviewState] = 'Medical Geneticist Review'
  $scope.stateToTitle[reportSignOutApprovedState] = 'Approved for Sign-Out'
  $scope.stateToTitle[reportSignedOffState] = 'Ordering Physician Review'
  $scope.stateToTitle[reportReleaseGatedState] = 'Release Gated Reports'
  $scope.stateToTitle[reportReleaseApprovedState] = 'Release Approved Reports'
  $scope.stateToTitle[reportSentToPhysicianState] = 'Reports Sent to Physician'
  $scope.stateToTitle[reportReleaseReadyState] = 'Reports Ready for Release'
  $scope.stateToTitle[reportReleasedState] = 'Released Reports'
  $scope.stateToTitle['all'] = 'All Reports'

  $scope.auth = auth
  $scope.state = state

  $scope.showApproveReleaseBtn = state == reportSignedOffState
  $scope.showReleaseToPhysician = state == pathologistReviewState || state == reportSignOutApprovedState
  $scope.showCheckboxColumn = state == reportSignedOffState
  $scope.showSampleColumn = isPreSignoff
  $scope.showInQueueColumn = isPreSignoff
  $scope.showPriorityColumn = isPreSignoff
  $scope.showCreatedColumn = isPreRelease
  $scope.showOrderingColumn = isPreSignoff
  $scope.showReportVersionColumn = state != reportNewState && state != reportNotReadyState
  $scope.showStatusColumn = state == 'all'
  $scope.showReleasedColumn = !isPreRelease && state != 'all'
  $scope.showSignificanceColumn = state != reportNotReadyState
  $scope.showClientColumn = !isPreSignoff
  $scope.showAgeColumn = !!isPreSignoff
  $scope.showHistoryReadyColumn = state == reportNewState || state == reportNotReadyState
  $scope.showVariantClassificationReadyColumn = state == reportNotReadyState
  $scope.showReviewReadyColumn = state == reportNotReadyState
  $scope.showConfirmationReadyColumn = state == reportNotReadyState

  $scope.showHealthReadyFilter = state == reportNewState
  $scope.showConfirmationReadyFilter = state == reportNotReadyState
  $scope.testOutcomeFilter = state == pathologistReviewState || state == reportSignOutApprovedState
  $scope.orderTypeFilter = state == pathologistReviewState || state == reportSignOutApprovedState

  $scope.getNextPage = (reports) => {
    if (reports) {
      reports.getNextPage()
        .then(function() {
        // Assignn significance value to each report so that reports can be searched by it.
          reports.items.filter(report => !report.significance).forEach((report) => {
            report.significance = $filter('resultSignificance')(report)
          })
          // After fetching the next page, not enough reports may be available
          // to fill the table after applying frontend angular filters. In such
          // cases, infinite scroll loading doesn't load next page so we load
          // them manually here.
          if (reports.hasNext && !reports.loadingNext) {
            const filteredItems = $filter('filter')(reports.items, $scope.searchPage)
            if (filteredItems.length < pageSize) {
              $scope.getNextPage(reports)
            }
          }
        })
    }
  }

  $scope.testTypes = testTypes
  $scope.reports = initializeReports()

  $scope.viewReport = function(report) {
    $window.open('/reports/' + report.id)
  }

  $scope.openApproveReleaseModal = function(reports) {
    openModalForApprovingRelease(
      approveReleaseModal
      , reports
      , 'Please select reports to approve for release.'
      , 'Selected reports have been approved for release.'
    )
  }

  const canSignOut = (report) => !report.sample.for_ny || auth.currentUser.is_ny_cq_holder

  $scope.releaseToPhysician = function(reports) {
    const reportsToSignOut = reports.filter((r) => canSignOut(r))
    const reportsToApprove = reports.filter((r) => !canSignOut(r))

    releaseToPhysicianModal.open(reportsToSignOut, reportsToApprove).result
      .then(function() {
        $scope.reports = initializeReports()
      })
  }

  $scope.canBatchRelease = function(report) {
    return (!report.revision || report.kit_order.test_type == testTypes.pgxv1) &&
      !report.has_incidental_findings &&
      !report.on_hold &&
      report.is_secondary_confirmation_completed &&
      report.has_classification_review_completed &&
      $filter('hasSampleTag')([report], 'pt').length == 0 &&
      (report.test_outcome == testOutcome.non_reportable ||
       report.test_outcome == testOutcome.negative && !report.has_vusp)
  }

  function openModalForApprovingRelease(modal, reports, errorMsg, successMsg) {
    const selectedReports = $filter('filter')(reports, { selected: true })

    if (!selectedReports.length) {
      ngToast.create({ className: 'error', content: errorMsg })
      return
    }

    modal.open(selectedReports).result
      .then(function() {
        ngToast.create(successMsg)
        $scope.reports = initializeReports()
      })
  }

  $scope.selectAllReports = function(reports, allSelected) {
    angular.forEach(reports, function(report) {
      report.selected = allSelected
    })
  }

  function initializeReports(search) {
    $scope.allSelected = false
    const queryParams = { page_size: pageSize, is_covid: false }

    if (state == reportNotReadyState) {
      queryParams.states = reportNewState
      queryParams.result_is_ready = false
    } else if (state == reportNewState) {
      queryParams.states = reportNewState
      queryParams.result_is_ready = true
    } else if (state == pathologistReviewState) {
      queryParams.states = pathologistReviewState
      queryParams.sign_out_approved = false
    } else if (state == reportSignOutApprovedState) {
      queryParams.states = pathologistReviewState
      queryParams.sign_out_approved = true
    } else if (state != 'all') {
      queryParams.states = state
    }

    if (search) {
      if (search.orderNumber) {
        queryParams.kit_order__order_number__icontains = search.orderNumber
      }
      if (search.sampleId) {
        queryParams.kit_order__samples__kit_barcode__iexact = search.sampleId
      }
      if (search.isHistoryReady) {
        queryParams.is_health_history_ready = search.isHistoryReady
      }
      if (search.testType) {
        queryParams.test_type__icontains = search.testType
      }
      if (search.testOutcome) {
        queryParams.test_outcome = search.testOutcome
      }
      if (search.orderType) {
        queryParams.order_type = search.orderType
      }
      if (search.highPriority) {
        queryParams.high_priority = search.highPriority
      }
    }
    const reports = resourcePaginator(new Report(), queryParams)
    $scope.getNextPage(reports)
    return reports
  }

  let searchPromise
  $scope.search = {}
  $scope.refresh = function(search) {
    $scope.reports = initializeReports(search)
  }
  $scope.searchPage = {}
  $scope.$watch('search', function(newValue, oldValue) {
    // Skip initial watch trigger.
    if (angular.isUndefined(oldValue) || newValue == oldValue) {
      return
    }
    if (searchPromise) {
      $timeout.cancel(searchPromise)
    }
    searchPromise = $timeout(function() {
      $scope.refresh(newValue)
      searchPromise = undefined
    }, 1000)
  }, true)

  // Infinite scroll does not load next page when angular filters
  // are applied and not enough reports are available to fill
  // the table. We load the nenxt page manually in such cases.
  $scope.$watch('searchPage', function() {
    if ($scope.reports.hasNext && !$scope.reports.loadingNext) {
      $scope.getNextPage($scope.reports)
    }
  }, true)
}
