module.exports = angular.module('orderingPhysiciansController', [])
  .controller('ProviderSignInController', require('./controllers/provider_sign_in_controller'))
  .controller('ProviderPlatformHomeController', require('./controllers/provider_platform_home_controller'))
  .controller('ProviderPlatformPlaceOrderController', require('./controllers/provider_platform_place_order_controller'))
  .controller('ProviderPlatformReviewOrderController', require('./controllers/provider_platform_review_order_controller'))
  .controller('ProviderPlatformSubmitPaymentController', require('./controllers/provider_platform_submit_payment_controller'))
  .controller('ProviderPlatformOrderController', require('./controllers/provider_platform_order_controller'))
  .controller('ProviderAccountCreationController', require('./controllers/provider_account_creation_controller'))
  .controller('ProviderExistingAccountController', require('./controllers/provider_existing_account_controller'))
  .controller('OrderPreferencesController', require('./controllers/order_preferences_controller'))
  .controller('ProviderPlatformDraftsController', require('./controllers/provider_platform_drafts_controller'))
  .controller('ProviderPlatformFamilyTestingController', require('./controllers/provider_platform_family_testing_controller'))
  .controller('ProviderPlatformFamilyTestingOrderController', require('./controllers/provider_platform_family_testing_order_controller'))
  .controller('ProviderPlatformFamilyTestingReviewingController', require('./controllers/provider_platform_family_testing_reviewing_controller'))
  .controller('ProviderPlatformSharedController', require('./controllers/provider_platform_shared_controller'))
  .controller('ProviderPlatformSharedOrderController', require('./controllers/provider_platform_shared_order_controller'))
