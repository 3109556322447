// The `clrTab` directive adds a tab only to `clrTabSet`, not used in `clrTabSimple`

"use strict"

module.exports = function($rootScope, $location, $timeout, locationSkipReload) {
  "ngInject"

  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    require: '^clrTabSet',
    scope: {
      path: '@',
      // Used on reports V1 to handle special URL conditions containing the test type
      pathLastSegmentOnly: '@',
      heroTemplateUrl: '@',
      contentTemplateUrl: '@',
      heroHeight: '@?',
      // Used to register $rootscope listeners, useful to change to this tab from another directive
      enableListener: '@?'
    },
    templateUrl: '/directives/clr_tab.html',
    link: function(scope, elem, attrs, tabCtrl) {
      // `tabCtrl` is `clrTabSet`'s controller
      tabCtrl.addTab(scope)

      let relativePath = scope.path

      // select method called by clicking on the tab
      scope.select = function () {
        scope.selected = true
        // lag transition slightly in minor chance width hasn't been set by browser yet
        $timeout(function() {
          tabCtrl.setUnderline(elem.width(), elem.position().left)
        }, 25)
        tabCtrl.select(scope)
        // when a tab is clicked vs going through the route

        if (scope.pathLastSegmentOnly) {
          relativePath = $location.path().split('/')
          relativePath.pop()
          relativePath.push(scope.path.split('/')[1])
          relativePath = relativePath.join('/')
        }
        if (relativePath != $location.path()) {
          tabCtrl.setScroll()
          locationSkipReload(relativePath)
        }
      }

      // select tab based on $location.path() when tabs are instantiated
      // making sure a defined path (`relativePath`) ends with the path as entered (`$location.path())
      const pathMatch = new RegExp(`${relativePath}$`)
      if (relativePath && pathMatch.test($location.path())) {
        scope.select()
      }

      // enables selecting a tab if called from call outside of this directive, e.g. `clrTabSelect`
      if (scope.enableListener) {
        $rootScope.$on('clrTab:selectTab', function(event, data) {
          if (scope.enableListener == data) {
            tabCtrl.setScroll(true)
            scope.select()
          }
        })
      }
    }
  }
}
