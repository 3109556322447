'use strict';

module.exports = function(cookiePersistedObject, $q, Claim, auth, requisitionStore, PhysicianOrder, sku) {
  'ngInject';

  const store = cookiePersistedObject('claimToken');

  store.clearClaim = () => {
    store.clear()
    store.claim = null
  }

  store.setClaim = function(claim) {
    if (!claim.is_redeemable) {
      store.clear();
      return;
    }

    this.set(claim.token);
    this.claim = claim;
  }

  /**
   * Extract claim from token via api. Should never access claim directly
   * args: claimToken: nullable string
   */
  store.getClaim = function(claimToken) {
    const token = claimToken || store.get();

    if (!token || !token.length) {
      return $q.when();
    }

    this.set(token)
    if (store.claim && store.claim.token == token) {
      return $q.when(store.claim)
    }

    return Claim.get({ token }).$promise.then(claim => {
      store.setClaim(claim)
      return claim;
    }).catch($q.reject);
  }

  store.getNonUpgradeSkuDiscount = function(givenSku) {
    if (!store.claim) {
      return
    }
    const claim = store.claim

    for (const skuDiscount of claim.sku_discounts) {
      if (skuDiscount.sku === givenSku.name) {
        return skuDiscount
      }
    }
  }

  return store;
}
