module.exports = angular.module('accounts', [
  require('monoweb/django_apps/users/js/accounts/controllers').name,
  require('monoweb/django_apps/users/js/accounts/services').name,
]).config(function(colorRouteProvider) {
  colorRouteProvider
    .when('/returned_data', {redirectTo: '/returned-data'})
    .when('/returned-data', {
      templateUrl: '/accounts/returned_data.html',
      controller: 'ReturnedDataController',
      auth: { loginPath: '/react/create-account' } //todo test
    })
})
