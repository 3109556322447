!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name reportModal
   *
   * @description
   * The `reportModal` provides result modals.
   */
  const module = angular.module('reportModal', ['ngToast', 'ui.bootstrap'])

  function EmailFamilyModal($scope, $modalInstance, sampleLetterModal, letter, reportId, testType) {
    $scope.letter = letter
    $scope.reportId = reportId
    $scope.testType = testType
    $scope.openSampleLetterModal = function() {
      sampleLetterModal.open(letter)
      $modalInstance.dismiss()
    }
  }
  module.factory('emailFamilyModal', function($modal) {
    return {
      open: function(letter, reportId, testType) {
        return $modal.open({
          templateUrl: '/results/_modal-email_family.html',
          controller: EmailFamilyModal,
          size: 'md',
          resolve: {
            letter: function() {
              return letter
            },
            reportId: function() {
              return reportId
            },
            testType: function() {
              return testType
            }
          }
        })
      }
    }
  })

  function SampleLetterModal($scope, $modalInstance, letter) {
    $scope.letter = letter
  }
  module.factory('sampleLetterModal', function($modal) {
    return {
      open: function(letter) {
        return $modal.open({
          templateUrl: '/results/_modal-sample_letter.html',
          controller: SampleLetterModal,
          size: 'md',
          resolve: {
            letter: function() {
              return letter
            }
          }
        })
      }
    }
  })

  function AddProviderModal($scope, $modalInstance, HealthcareProvider, healthcareProvider, orderNumber, ngToast) {
    if (healthcareProvider) {
      $scope.healthcareProvider = new HealthcareProvider(healthcareProvider)
      $scope.confirming = true
    } else {
      $scope.healthcareProvider = new HealthcareProvider()
      $scope.confirming = false
    }
    $scope.healthcareProvider.order_number = orderNumber
    $scope.confirmProvider = function() {
      $scope.confirming = true
    }
    $scope.addProvider = function() {
      $scope.submitting = true
      $scope.healthcareProvider.$save()
        .then(function(res) {
          $scope.submitting = false
          ngToast.create('Your results will be sent soon.')
          $modalInstance.close(res)
        })
        .finally(function(res) {
          $scope.submitting = false
        })
    }
  }
  module.factory('addProviderModal', function($modal) {
    return {
      open: function(healthcareProvider, orderNumber) {
        return $modal.open({
          templateUrl: '/results/_modal-add_provider.html',
          controller: AddProviderModal,
          size: 'md',
          resolve: {
            healthcareProvider: function() {
              return healthcareProvider
            },
            orderNumber: function() {
              return orderNumber
            }
          }
        })
      }
    }
  })

  function OrderingProviderNotesModal($scope, reportContent) {
    $scope.reportContent = reportContent
    $scope.testType = reportContent.kit_order.test_type
    $scope.relevantHistory = ''
    if ($scope.reportContent.relevant_history) {
      $scope.relevantHistory = '(' + $scope.reportContent.relevant_history + ') '
    }
  }

  module.factory('orderingProviderNotesModal', function($modal) {
    return {
      open: function(reportContent, report) {
        return $modal.open({
          templateUrl: '/results/_modal-ordering_provider_notes.html',
          controller: OrderingProviderNotesModal,
          size: 'md',
          resolve: {
            reportContent: function() {
              return reportContent
            }
          }
        })
      }
    }
  })

  function ReportOrderDetailsModal($scope, $location, $modalInstance, reportContent, report) {
    $scope.reportContent = reportContent
    $scope.report = report

    function checkIfDistinctProvider(field, compareTo) {
      return field && (!compareTo || JSON.stringify(field) != JSON.stringify(compareTo))
    }

    $scope.hasPrimaryContact = checkIfDistinctProvider(reportContent.primary_contact, reportContent.ordering_physician)
    $scope.hasOrderPlacedBy = checkIfDistinctProvider(reportContent.order_placed_by, reportContent.ordering_physician) &&
      checkIfDistinctProvider(reportContent.order_placed_by, reportContent.primary_contact)

  }

  module.factory('reportOrderDetailsModal', function($modal) {
    return {
      open: function(reportContent, report) {
        return $modal.open({
          templateUrl: '/results/_modal-order_details.html',
          controller: ReportOrderDetailsModal,
          size: 'lg',
          resolve: {
            reportContent: function() {
              return reportContent
            },
            report: function() {
              return report
            }
          }
        })
      }
    }
  })

  function ReportRevisionsModal($scope, revisions) {
    $scope.revisions = revisions
  }

  module.factory('reportRevisionsModal', function($modal) {
    return {
      open: function(revisions) {
        return $modal.open({
          templateUrl: '/results/_modal-revisions.html',
          controller: ReportRevisionsModal,
          size: 'md',
          resolve: {
            revisions: function() {
              return revisions
            }
          }
        })
      }
    }
  })

}();
