const createAccountRouteConfig = {
  templateUrl: '/ordering_physicians/provider_account_creation.html',
  auth: {
    requireLogin: false
  },
  seo: {
    title: 'Create a Provider Online Account | Color Providers',
    description: 'Easy online ordering for your patient is available through our Color Provider Platform. Register today.'
  },
  controller: 'ProviderAccountCreationController',
  navigation: {
    showLeft: false,
    showRight: false,
    showSignInAsButton: true,
    linkToProviderSignIn: true,
    showStore: false,
    layout: 'minimum'
  }
}

module.exports = angular.module('providerPlatform', [
  require('monoweb/django_apps/ordering_physicians/js/controllers').name,
  require('monoweb/django_apps/ordering_physicians/js/directives').name,
  require('monoweb/django_apps/ordering_physicians/js/services').name,
  require('monoweb/django_apps/ordering_physicians/js/modal').name,
  require('monoweb/django_apps/ordering_physicians/js/order_directives').name,
  require('monoweb/django_apps/ordering_physicians/js/place_order_directives').name,
  require('monoweb/django_apps/ordering_physicians/js/resource').name,
  require('monoweb/django_apps/ordering_physicians/js/tutorials').name
])
  .config(function(colorRouteProvider) {
    colorRouteProvider
      .when('/providers/sign-in', {
        templateUrl: '/ordering_physicians/signin.html',
        seo: {
          title: 'Sign in to Color - Color Providers',
          description: ''
        },
        controller: 'ProviderSignInController',
        auth: {
          requireLogin: false,
          requireAnonymous: true
        },
        navigation: {
          showLeft: false,
          showRight: false,
          showStore: false,
          layout: 'minimum'
        },
        forProviders: true
      })
      // [CONSUMER-2854] For marketing campaign to get GC's to open accounts.
      .when('/providers/create-account', createAccountRouteConfig)
      .when('/providers/verified-account/:token', createAccountRouteConfig)
      .when('/providers/existing-account', {
        templateUrl: '/ordering_physicians/existing_account.html',
        auth: {
          requireLogin: false
        },
        seo: {
          title: 'Create a Provider Online Account | Color Providers',
          description: 'Easy online ordering for your patient is available through our Color Provider Platform. Register today.'
        },
        controller: 'ProviderExistingAccountController',
        navigation: {
          showLeft: false,
          showRight: false,
          showSignInAsButton: true,
          showStore: false,
          layout: 'minimum'
        }
      })
      .when('/providers', { redirectTo: '/providers/home' })
      .when('/providers/home', {
        templateUrl: '/ordering_physicians/provider_platform_home.html',
        seo: {
          title: 'Color Provider Platform - Color',
          description: ''
        },
        auth: {
          requireProvider: true,
          requireEmailConfirmation: false
        },
        navigation: {
          active: 'orders',
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformHomeController'
      })
      .when('/providers/drafts', {
        templateUrl: '/ordering_physicians/provider_platform_drafts.html',
        seo: {
          title: 'Color Provider Platform - Color',
          description: ''
        },
        auth: {
          requireProvider: true,
          requireEmailConfirmation: false
        },
        navigation: {
          active: 'orders',
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformDraftsController'
      })
      .when('/providers/family-testing', {
        templateUrl: '/ordering_physicians/family_testing/home.html',
        controller: 'ProviderPlatformFamilyTestingController',
        seo: {
          title: 'Color Provider Platform - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
        }
      })
      .when('/providers/family-testing-enrolling', {
        templateUrl: '/ordering_physicians/family_testing/family_testing_form.html',
        controller: 'ProviderPlatformFamilyTestingOrderController',
        seo: {
          title: 'Color Provider Platform - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
        }
      })
      .when('/providers/family-testing-reviewing', {
        templateUrl: '/ordering_physicians/family_testing/reviewing.html',
        controller: 'ProviderPlatformFamilyTestingReviewingController',
        seo: {
          title: 'Color Provider Platform - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
        }
      })
      .when('/providers/place-order', {
        templateUrl: '/ordering_physicians/place_order.html',
        seo: {
          title: 'Place an order - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        resolve: {
          draft: function($route, DraftPhysicianOrder, PhysicianOrderFormData) {
            'ngInject'
            const draftRequisitionNumber = $route.current.params.draft
            if (draftRequisitionNumber) {
              return DraftPhysicianOrder.get({requisition_number: draftRequisitionNumber}).$promise
                .catch(() => undefined)
            }
          },
          enrollment: function($route, Enrollment) {
            'ngInject'
            const enrollmentId = $route.current.params.ftp
            if (enrollmentId) {
              return Enrollment.get({id:enrollmentId}).$promise
                .catch(() => undefined)
            }
          }
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
          showPhysicianOrderBreadcrumbs: true
        },
        controller: 'ProviderPlatformPlaceOrderController'

      })
      .when('/providers/submit-payment', {
        templateUrl: '/ordering_physicians/submit_payment.html',
        seo: {
          title: 'Provide payment - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
          showPhysicianOrderBreadcrumbs: true
        },
        controller: 'ProviderPlatformSubmitPaymentController'
      })
      .when('/providers/review-order', {
        templateUrl: '/ordering_physicians/review_order.html',
        seo: {
          title: 'Review your order - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          layout: 'provider',
          showLeft: false,
          showPhysicianOrderBreadcrumbs: true
        },
        controller: 'ProviderPlatformReviewOrderController'
      })
      // Provider physician order pages
      .when('/providers/order/results/:requisition_number', {
        templateUrl: '/ordering_physicians/order.html',
        seo: {
          title: 'Order details - Color',
          description: ''
        },
        auth: {
          requireProvider: true
        },
        navigation: {
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformOrderController'
      })
      .when('/providers/order/log/:requisition_number', {
        templateUrl: '/ordering_physicians/order.html',
        auth: {
          requireProvider: true
        },
        navigation: {
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformOrderController'
      })
      .when('/providers/order/details/:requisition_number', {
        templateUrl: '/ordering_physicians/order.html',
        auth: {
          requireProvider: true
        },
        navigation: {
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformOrderController'
      })

    // Provider shared order pages

      .when('/providers/shared_order/results/:order_number', {
        templateUrl: '/ordering_physicians/shared_order.html',
        auth: {
          requireProvider: true
        },
        navigation: {
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformSharedOrderController',
        controllerAs: '$ctrl'
      })
      .when('/providers/shared_order/details/:order_number', {
        templateUrl: '/ordering_physicians/shared_order.html',
        auth: {
          requireProvider: true
        },
        navigation: {
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformSharedOrderController',
        controllerAs: '$ctrl'
      })

      .when('/providers/forms', {
        templateUrl: '/ordering_physicians/forms.html',
        auth: {
          requireProvider: true,
          requireEmailConfirmation: false
        },
        seo: {
          title: 'Forms and information - Color',
          description: ''
        },
        controller: require('monoweb/django_apps/ordering_physicians/js/controllers/provider_forms_controller.js'),
        navigation: {
          showSearchBar: true,
          active: 'forms',
          layout: 'provider'
        }
      })
      .when('/providers/account-settings', {
        templateUrl: '/accounts/settings.html',
        seo: {
          title: 'Account Management - Color',
          description: ''
        },
        controller: require('monoweb/django_apps/users/js/accounts/controllers/account_settings_controller'),
        auth: { requireEmailConfirmation: false },
        navigation: {
          subnavActive: 'account',
          layout: 'provider'
        }
      })
      .when('/providers/order-preferences', {
        templateUrl: '/accounts/settings.html',
        seo: {
          title: 'Account Management - Color',
          description: ''
        },
        controller: require('monoweb/django_apps/users/js/accounts/controllers/account_settings_controller'),
        auth: { requireEmailConfirmation: false },
        navigation: {
          subnavActive: 'account',
          layout: 'provider'
        }
      })
      .when('/providers/resubmit-payment/:requisition_number', {
        templateUrl: '/ordering_physicians/resubmit_payment.html',
        controller: require('monoweb/django_apps/ordering_physicians/js/controllers/provider_platform_resubmit_payment_controller'),
        auth: {
          requireEmailConfirmation: true,
          requireSupportStaff: true
        },
        navigation: {
          layout: 'provider'
        }
      })
      .when('/providers/shared', {
        templateUrl: '/ordering_physicians/provider_platform_shared.html',
        seo: {
          title: 'Color Provider Platform - Color',
        },
        auth: {
          requireProvider: true,
          requireEmailConfirmation: false
        },
        navigation: {
          active: 'orders',
          showSearchBar: true,
          layout: 'provider'
        },
        controller: 'ProviderPlatformSharedController',
        controllerAs: '$ctrl'
      })
  })
