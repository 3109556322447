/**
 * @prettier
 */

module.exports = function reportRiskFactors(testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_risk_factors.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      // Only show if non-breast cancer survivor, is negative, is female, and has risk model, ADH, or LCIS
      scope.showCard =
        !scope.reportContent.breast_cancer_survivor &&
        scope.reportContent.is_negative &&
        scope.reportContent.gender == 'F' &&
        (scope.reportContent.template.risk_model ||
          scope.reportContent.had_breast_biopsy_with_atypical_hyperplasia ||
          scope.reportContent.diagnosed_with_lobular_carcinoma_in_situ);
    },
  };
};
