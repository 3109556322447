"use strict"

const BANNER_TYPE_ENTERPRISE = 'enterprise'
const BANNER_TYPE_REFERRAL = 'referral'

/**
 * Promo Banner
 */
module.exports = function(auth, promoCodeStore, claimStore, kitPriceService, sku, corporateLogos, PromoBanner, $location) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      bannerType: '=?',
      fullWidth: '=?',
      topOfPage: '=?',
    },
    templateUrl: '/directives/promo_banner.html',
    link: function(scope) {

      scope.auth = auth
      scope.promoCodeStore = promoCodeStore
      scope.corporateLogos = corporateLogos

      if (promoCodeStore.code || claimStore.get()) {
        PromoBanner.get(
          {
            code: promoCodeStore.code,
            token: claimStore.get(),
            sku: $location.search().sku
          }
        ).$promise.then(
          function(banner) {
            // Bind the banner to the scope only if it's not an empty object.
            if (banner.text) {
              scope.banner = banner
            }
          }
        );
      }

      scope.shouldShowGiftIcon = function () {
        return scope.banner && (scope.banner.banner_type == BANNER_TYPE_ENTERPRISE || scope.banner.banner_type == BANNER_TYPE_REFERRAL)
      }

      scope.getBannerClass = function() {
        const classes = [];
        if (!scope.fullWidth) {
          classes.push('banner--max-width');
        }
        if (scope.topOfPage) {
          classes.push('banner--top-of-page')
        }
        const bannerType = scope.banner ? scope.banner.banner_type : scope.getBannerType()
        if (bannerType == BANNER_TYPE_ENTERPRISE || bannerType == BANNER_TYPE_REFERRAL) {
          classes.push('banner--navy')
        } else {
          classes.push('banner--yellow')
        }
        return classes.join(' ')
      }

      /**
       * DEPRECATED - We're moving banner logic to a backend endpoint (See ENG-325)
       */
      scope.getBannerType = function() {
        return null
      }
    }
  }
}
