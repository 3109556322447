module.exports = (organizationRelationship) => {
  'ngInject'

  return (currentUser) => {
    const populationMembershipRelationships = currentUser.population_memberships.filter(membership => membership.population.name === currentUser.routing_organization).map(membership => membership.relationships).flat()
    return populationMembershipRelationships.some((relationship) => {
      return organizationRelationship.EMPLOYEE_RELATIONSHIPS.indexOf(relationship) > -1
    })
  }
}
