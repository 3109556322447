/**
 * @prettier
 */

module.exports = function reportProvideHealthHistoryBanner() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/banner_provide_health_history.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.healthHistoryStatus = 'completed';
      if (scope.reportContent.is_hh_under_review) {
        scope.healthHistoryStatus = 'updated';
      } else if (!scope.reportContent.is_hh_provided) {
        scope.healthHistoryStatus = 'not_provided';
      }
    },
  };
};
