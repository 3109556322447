'use strict'

module.exports = function($scope, $http, ngToast, Address) {
  'ngInject'

  $http.get('/api/v1/benefits/campaigns?page_size=1000')
    .then(function(res) {
      $scope.enterpriseV2Campaigns = res.data.results
    })
    .catch(function() {
      ngToast.create({ className: 'error', content: 'Unable to fetch campaign list' })
    })

  $scope.address = new Address()
  $scope.isOnsiteSetUp = false

  $scope.setUpOnsite = function() {
    $scope.submittingAddress = true

    const selectedCampaign = $scope.enterpriseV2Campaigns.find(campaign => campaign.name == $scope.selectedCampaignName)
    $scope.selectedCampaignName = selectedCampaign.name
    $scope.selectedCampaignSkus = selectedCampaign.sku_discounts.map(skuDiscount => skuDiscount.sku).join(', ')

    $scope.address.$save()
      .then(function() {
        $scope.isOnsiteSetUp = true
      })
      .catch(function() {
        ngToast.create({ className: 'error', content: 'Address creation failed' })
      })
      .finally(function() { $scope.submittingAddress = false })
  }

  $scope.values = {}
  $scope.checkEligibility = () => {
    const params = {
      identifier: $scope.values.identifier,
      campaign_name: $scope.selectedCampaignName
    }

    $http.put('api/v1/benefits/get_or_create_claim', params).then(response => {
      const claim = response.data
      $scope.claim = claim
      $scope.checkedEligibility = true

    }).catch(response => {
      ngToast.create({ className: 'error', content: response.data.detail })
    })
  }

  $scope.reset = () => {
    $scope.values = {}
    $scope.checkedEligibility = false
    $scope.claim = null
    $scope.enterpriseV1SubmissionForm.$setPristine()
    $scope.enterpriseV2EligibilityCheckForm.$setPristine()
    $scope.enterpriseV2SubmissionForm.$setPristine()
  }

  $scope.submit = function() {
    $scope.submitting = true
    const data = {
      identifier: $scope.values.identifier,
      // The tracking number is the last 22 digits of a USPS scan
      // Incident 3067 - we no longer want to truncate
      return_tracking_number: $scope.values.returnScan,
      delivery_address: $scope.address.id
    }

    const url = 'api/v1/onsite_benefits_enterprise_v2'
    data.campaign_name = $scope.selectedCampaignName

    $http.post(url, data)
      .then(function() {
        ngToast.create('Benefit claimed successfully. Instruct client to activate kit.')
        $scope.reset()
      })
      .catch(function(error) { ngToast.create({ className: 'error', content: 'Benefit claim failed: ' + error.data.detail }) })
      .finally(function() { $scope.submitting = false })
  }
}
