'use strict'

module.exports = function($translate, $cookies, waffle, resolvedPromise, ngToast, PromotionalCode, canAddDependents) {
  'ngInject'

  this.init = function(hideFailure) {
    if (!this._initializePromise) {
      if (!this.promotionalCode) {
        this.promotionalCode = new PromotionalCode()
      }
      this._initializePromise = resolvedPromise(this.promotionalCode)
      if ($cookies.get('code')) {
        this._initializePromise = this.updateCode($cookies.get('code'), true, !!hideFailure)
      }
    }
    return this._initializePromise
  }

  this.clear = function() {
    this.stopPersistingCode()
    this.promotionalCode = new PromotionalCode()
  }

  // bypass the cache to resync
  this.sync = function() {
    PromotionalCode.sync({ code: this.promotionalCode.code }, angular.bind(this, function(code) {
      this.promotionalCode = code
    }));
  }

  // re-fetches the existing code.
  this.updateCode = function(code, silentSuccess, silentFailure) {
    this.code = code
    this.promotionalCode = PromotionalCode.get(
      { code: code },
      this._onSuccessHandler(silentSuccess, silentFailure),
      this._onFailureHandler(silentFailure)
    )
    return this.promotionalCode.$promise
  }

  // Sets promotional code that's already fetched.
  this.setPromotionalCode = function(promoCode) {
    this.promotionalCode = new PromotionalCode(promoCode)
    this._onSuccessHandler(true)(promoCode)
  }

  this.canAddDependents = function() {
    return canAddDependents(this)
  }

  // Determine if the current promo code can be applied to a sku.
  // If no sku is specified, no sku-specific promo code is valid.
  this.promoAppliesToSku = function(sku) {
    const promoCode = this.promotionalCode
    if (!promoCode || !promoCode.can_redeem) {
      return false // promo code is not valid for any sku
    }
    if (!promoCode.sku_list || promoCode.sku_list.length < 1) {
      return true // promo code is valid for all skus
    }
    if (sku && promoCode.sku_list.indexOf(sku.name) >= 0) {
      return true // our sku matches sku-specific promo code
    }
    return false // promo code is sku-specific, but we have no matching sku
  }

  this._onSuccessHandler = function(silentSuccess, silentFailure) {
    return angular.bind(this, function(res) {
      if (!res.can_redeem) {
        this._onFailureHandler(silentFailure)(res)
        return
      }
      if (!silentSuccess) {
        $translate(['BILLING.TOASTS.PROMO_CODE_APPLIED'])
          .then(function(translations) {
            ngToast.create(translations['BILLING.TOASTS.PROMO_CODE_APPLIED'])
          })
      }
    })
  }

  this._onFailureHandler = function(silentFailure) {
    return angular.bind(this, function(res) {
      // reset the promoCodeStore
      this.clear()
      let msg
      // if status field is defined, it is http level error.
      if (res.status) {
        msg = 'BILLING.TOASTS.UNKNOWN_PROMO_CODE'
      } else if (res.remaining_redemptions_count === 0) {
        msg = 'BILLING.TOASTS.PROMO_CODE_ALREADY_REDEEM'
      } else {
        msg = 'BILLING.TOASTS.PROMO_CODE_UNUSABLE'
      }
      if (!silentFailure) {
        $translate([msg])
          .then(function(translations) {
            ngToast.create({ className: 'error', content: translations[msg] })
          })
      }
    })
  }

  this.persistCode = function(code) {
    $cookies.put('code', code)
  }

  this.stopPersistingCode = function() {
    // Do not clear the code from cookies even if it is unusable.
    // In ordering_physician_controller.js, we will place it in the query params
    // and then clear the code there instead, and let the React purchasing flow
    // handle the unusable code.
    const search = new URLSearchParams(window.location.search)
    const originIsFtpEmail = search.get('utm_content') === 'FTP_application_approved_(to_invitee)' && search.get('utm_source') === 'mailgun'
    if (originIsFtpEmail) {
      return
    }

    $cookies.remove('code')
  }

  this.isEligibleForPricingModifiers = function() {
    const promo = this.promotionalCode
    if (!promo) {
      return true
    }
    if (promo.is_family_testing_code) {
      return false
    }
    if (promo.campaign && promo.campaign.company_name) {
      return false
    }
    return true
  }
}
