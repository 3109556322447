/**
 * @prettier
 */

module.exports = function reportFamilyTestingProgram(clinicalDomains) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_family_testing_program.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.clinicalDomain = clinicalDomains[scope.reportContent.template.test_type];
    },
  };
};
