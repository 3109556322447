'use strict'

module.exports = function($scope, $http, $modal, auth, ngToast, VerifyIdentityModal, EmailUpdateConfirmationResend) {
  'ngInject'

  $scope.changingEmail = false
  $scope.verifyingIdentity = false
  $scope.pendingEmailChange = false

  if (!!auth.currentUser.unconfirmed_updated_email) {
    $scope.pendingEmailChange = true
    $scope.changingEmail = true
  }

  $scope.changeEmail = function() {
    $scope.verifyingIdentity = true
    $scope.instructions = "Verify your password to change your email address."
    const verifyIdentityModal = VerifyIdentityModal.open($scope)
    verifyIdentityModal.result.then(function(res) {
      $scope.changingEmail = true
      $scope.verifyingIdentity = false
      auth.currentUser.verification_password = res.password
    })
  }
  $scope.changeEmailSubmit = function(form) {
    $scope.changingEmail = true
    $scope.openAlmostDoneModal(form)
  }
  $scope.cancelEmailForm = function() {
    $scope.changingEmail = false
  }

  function AlmostDoneController($scope, options) {
    $scope.options = options
  }

  $scope.openAlmostDoneModal = function(form) {
    const modalInstance = $modal.open({
      templateUrl: "/generic_modal.html",
      controller: AlmostDoneController,
      size: "sm",
      resolve: {
        options: function() {
          return {
            title: "Almost done...",
            body: "To protect your privacy, we need to verify <strong>" + form.email.$modelValue + " </strong>" +
            "is your email address before your change takes effect. If you " +
            "don't see the email within 5 minutes, check your Spam folder.",
            primaryActionText: "Got it",
            secondaryActionText: "Cancel email address change"
          }
        }
      }
    })

    modalInstance.result
      .then(function(scope) {
        $scope.thisUser = auth.currentUser
        $scope.thisUser.$save()
          .then(function(res) {
            auth.currentUser.unconfirmed_updated_email = res.unconfirmed_updated_email
            $scope.pendingEmailChange = true
            $scope.changingEmail = true
          })
      })
      .catch(function() {
        $scope.cancelEmailChange()
      })
  }

  $scope.resendVerificationEmail = function() {
    $scope.resending = true
    $scope.EmailUpdateConfirmationResend = new EmailUpdateConfirmationResend()
    $scope.EmailUpdateConfirmationResend.$save()
      .then(function() {
        ngToast.create('We have resent the email.')
      })
      .catch(function(res) {
        ngToast.create({ className: 'error', content: 'Something went wrong.' })
      })
      .finally(function() {
        $scope.resending = false
      })
  }

  $scope.cancelEmailChange = function() {
    $http.patch('/api/v1/accounts/' + auth.currentUser.id, { unconfirmed_updated_email: "" })
      .then(function(res) {
        auth.currentUser.unconfirmed_updated_email = ""
        $scope.pendingEmailChange = false
        $scope.changingEmail = false
      })
      .catch(function(res) {
        ngToast.create({ className: 'error', content: 'Email address change cancelled.' })
      })
  }
}
