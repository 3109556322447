/**
 * @prettier
 */

module.exports = function highlightedHealthHistory($filter) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      healthHistory: '=',
      clinicalDomain: '=',
    },
    templateUrl: '/results/highlighted_health_history.html',
    link: function(scope, elem) {
      scope.personalHistory = [];

      if (scope.healthHistory.had_bone_marrow_transplant_or_blood_transfusion) {
        if (
          !scope.healthHistory.had_bone_marrow_transplant &&
          !scope.healthHistory.had_blood_transfusion
        ) {
          scope.personalHistory.push('Had a bone marrow transplant or blood transfusion');
        } else {
          if (scope.healthHistory.had_bone_marrow_transplant) {
            scope.personalHistory.push('Had a bone marrow transplant');
          }
          if (scope.healthHistory.had_blood_transfusion) {
            const parts = ['Had blood transfusion'];
            if (scope.healthHistory.had_blood_transfusion_in_the_week_before_providing_sample) {
              parts.push('in the week before providing the sample');
            }
            if (
              scope.healthHistory.had_blood_transfusion_in_the_week_before_providing_sample ===
              false
            ) {
              parts.push('more than a week before providing the sample');
            }
            scope.personalHistory.push(parts.join(' '));
          }
        }
      }

      if (scope.healthHistory.had_liver_transplant) {
        scope.personalHistory.push('Had liver transplant');
      }

      function appendAge(text, age) {
        if (age !== undefined && age !== null) {
          return `${text} at ${age} ${age > 1 ? 'years' : 'year'} old`;
        }
        return text;
      }

      function addMutations(mutations) {
        if (mutations) {
          mutations.forEach(m => {
            let text = `A mutation was identified in ${m.gene}`;
            if (m.name) {
              text = `${text} (${m.name})`;
            }
            scope.personalHistory.push(text);
          });
        }
      }

      if (scope.clinicalDomain == 'cancer') {
        scope.completed = scope.healthHistory.cancer_completed;
        const profile =
          scope.healthHistory.female_health_profile || scope.healthHistory.male_health_profile;
        if (profile) {
          if (profile.had_breast_biopsy_with_atypical_hyperplasia == 'Y') {
            scope.personalHistory.push('Had breast biopsies with atypical hyperplasia');
          }
          if (profile.diagnosed_with_lobular_carcinoma_in_situ == 'Y') {
            scope.personalHistory.push('Diagnosed with LCIS');
          }
          profile.cancers.forEach(c => {
            let text = $filter('capitalize')(c.name);
            text = appendAge(text, c.diagnosed_age);
            if (c.affected_both === true) {
              text = `${text}, affecting both breasts`;
            } else if (c.affected_both === false) {
              text = `${text}, affecting one breasts`;
            }
            scope.personalHistory.push(text);
          });
        }

        addMutations(scope.healthHistory.mutations);

        scope.familyNone =
          scope.healthHistory.id &&
          scope.healthHistory.family_relative_health_profiles.length &&
          !$filter('anyRelativeHadMutation')(
            scope.healthHistory.family_relative_health_profiles,
            scope.clinicalDomain
          ) &&
          !$filter('anyRelativeHadCancer')(scope.healthHistory.family_relative_health_profiles);
      } else if (scope.clinicalDomain == 'cardio') {
        scope.completed = scope.healthHistory.cardio_completed;
        if (scope.healthHistory.cardio_history) {
          scope.healthHistory.cardio_history.forEach(c => {
            let text = $filter('capitalize')(c.name);
            text = appendAge(text, c.age);
            scope.personalHistory.push(text);
          });
        }

        addMutations(scope.healthHistory.cardio_mutations);

        scope.familyNone =
          scope.healthHistory.id &&
          scope.healthHistory.family_relative_health_profiles.length &&
          !$filter('anyRelativeHadMutation')(
            scope.healthHistory.family_relative_health_profiles,
            scope.clinicalDomain
          ) &&
          !$filter('anyRelativeHadCardioHistory')(
            scope.healthHistory.family_relative_health_profiles
          );
      } else if (scope.clinicalDomain == 'medication') {
        scope.completed = scope.healthHistory.medication_completed;
        if (scope.healthHistory.medication_history) {
          scope.healthHistory.medication_history.forEach(m => {
            scope.personalHistory.push(m);
          });
        }
      }
    },
  };
};
