"use strict"

/**
 * Review if this is even used...
 */
/**
 * Creates a horizontal bar chart
 *
 * Valid chartStyles:
 * 'basic': used on /learn/why-get-tested/women and /learn/why-get-tested/men - both of which don't exist.
 * 'cancer': used on cancer pages, like /learn/cancer/breast-cancer-facts-and-causes, which is now https://www.color.com/learn/cancer/breast-cancer-facts-and-causes
 *
 */
module.exports = function($filter) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      chartName: '@'
    },
    templateUrl: '/directives/clrHozChart.html',
    controller: function($scope, $element) {
      "ngInject"

      const self = this

      self.shiftBaselineGuide = function(x) {
        if (x.indexOf('<') > -1) {
          x = parseFloat(x.replace('<', ''))
        }
        $element.find('.clrHozChart__baseline-guide').css( { 'left': x } )
      }

      self.setTitle = function(phenotype, risk, age, applyScope) {
        $scope.title = '<strong>Avg. risk of ' + phenotype + ' is ' + risk + '</strong> by age ' + age
        if (applyScope) {
          $scope.$apply()
        }
      }

    },
    link: function(scope, elem, attrs, ctrl) {

      // Private methods to shape scope.chartName content, only valid in certain scope.chartStyles
      function getRiskUpperBound(riskValue) {
        const value = riskValue.toLowerCase()
        return $filter('barChartHeight')(value) + '%'
      }
      function isElevated(riskValue) {
        return $filter('isElevated')(riskValue)
      }
      function getHoverTitle(phenotype, riskAge) {
        return $filter('capitalize')(phenotype, 'firstOnly') + ' risk to age ' + riskAge
      }
      function getBaselineAverage(gender, riskAge) {
        return '<strong>' + scope.baselineRisks[riskAge] + '</strong> for average US ' + (gender == 'F' ? 'woman' : 'man')
      }
      function getHoverRisk(riskValue, gene) {
        let result = '<strong>' + $filter('riskString')(riskValue) + '</strong>'
        if (isElevated(riskValue)) {
          result += '*'
        }
        result += ' with <em>' + gene + '</em> mutation'
        return result
      }

      // Standard elevated copy
      const elevatedHoverCopy = '* Risk is increased, but further research may clarify the exact risk figure.'

      // Default legend teal label
      scope.legendData = {
        bar: 'Risk with mutation'
      }
      scope.chartTicks = ['0%', '', '20%', '', '40%', '', '60%', '', '80%', '', '100%']

      // Pick chart data based on scope.chartName
      switch (scope.chartName) {
        case 'femaleRisk': // Learn -> Women risk chart
          scope.chartStyle = 'basic'
          scope.legendData.barHighlight = 'Avg. risk among US women'
          scope.chartData = [
            {
              rowTitle: 'Colorectal',
              barLabel: 'APC',
              barWidth: '100%',
              barHighlightWidth: '4%'
            },
            {
              rowTitle: 'Stomach',
              barLabel: 'CDH1',
              barWidth: '83%',
              barHighlightWidth: '1%'
            },
            {
              rowTitle: 'Breast',
              barLabel: 'BRCA1',
              barWidth: '81%',
              barHighlightWidth: '12%'
            },
            {
              rowTitle: 'Melanoma',
              barLabel: 'CDKN2A',
              barWidth: '68%',
              barHighlightWidth: '2%'
            },
            {
              rowTitle: 'Pancreatic',
              barLabel: 'CDKN2A',
              barWidth: '58%',
              barHighlightWidth: '2%'
            },
            {
              rowTitle: 'Uterine',
              barLabel: 'MLH1',
              barWidth: '54%',
              barHighlightWidth: '3%'
            },
            {
              rowTitle: 'Ovarian',
              barLabel: 'BRCA1',
              barWidth: '54%',
              barHighlightWidth: '2%'
            }
          ]
          break
        case 'maleRisk': // Learn -> Men risk chart
          scope.chartStyle = 'basic'
          scope.legendData.barHighlight = 'Avg. risk among US men'
          scope.chartData = [
            {
              rowTitle: 'Colorectal',
              barLabel: 'APC',
              barWidth: '100%',
              barHighlightWidth: '3.4%'
            },
            {
              rowTitle: 'Stomach',
              barLabel: 'CDH1',
              barWidth: '70%',
              barHighlightWidth: '1%'
            },
            {
              rowTitle: 'Melanoma',
              barLabel: 'CDKN2A',
              barWidth: '67%',
              barHighlightWidth: '2%'
            },
            {
              rowTitle: 'Pancreatic',
              barLabel: 'CDKN2A',
              barWidth: '58%',
              barHighlightWidth: '1%'
            },
            {
              rowTitle: 'Prostate',
              barLabel: 'BRCA2',
              barWidth: '16%',
              barHighlightWidth: '10%'
            },
            {
              rowTitle: 'Male breast',
              barLabel: 'BRCA2',
              barWidth: '12%',
              barHighlightWidth: '0.5%'
            }
          ]
          break
        case "breastCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'breast cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            70: '7.1%',
            80: '10%',
            95: '12%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'ATM',
              riskAge: 70,
              riskValue: 'elevated (16-26%)'
            },
            {
              gene: 'BARD1',
              riskAge: 80,
              riskValue: 'elevated [30%]'
            },
            {
              gene: 'BRIP1',
              riskAge: 80,
              riskValue: 'elevated (12-32%)'
            },
            {
              gene: 'NBN',
              riskAge: 95,
              riskValue: 'elevated (20-36%)'
            },
            {
              gene: 'CDH1',
              riskAge: 80,
              riskValue: '39-42%'
            },
            {
              gene: 'CHEK2',
              riskAge: 70,
              riskValue: '20-44%'
            },
            {
              gene: 'STK11',
              riskAge: 70,
              riskValue: '32-54%'
            },
            {
              gene: 'PALB2',
              riskAge: 70,
              riskValue: 'elevated (35-58%)'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: '74%'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [77%]'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '77-80%'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: '81%'
            }
          ]
          break
        case "breastCancerMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'male breast cancer'
          scope.gender = 'M'
          scope.baselineRisks = {
            70: '<0.1%',
            80: '<0.1%'
          }
          scope.defaultBaselineRiskAge = 80
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'PALB2',
              riskAge: 70,
              riskValue: 'elevated [1%]'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: '1.8%'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: '3-12%'
            }
          ]
          break
        case "colorectalCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'colorectal cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            70: '1.6%',
            80: '2.8%',
            95: '4.2%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.footnote = 'Risk information for someone with two mutations in this gene.'
          scope.chartData = [
            {
              gene: 'CHEK2',
              riskAge: 70,
              riskValue: 'elevated (2-5%)'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '9%'
            },
            {
              gene: 'MUTYH',
              riskAge: 80,
              riskValue: 'elevated [11%]',
              hoverRisk: '<strong>Elevated</strong>* with a single <em>MUTYH</em> mutation'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [12%]'
            },
            {
              gene: 'PMS2',
              riskAge: 70,
              riskValue: '11-15%'
            },
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: '10-18%'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: '39%'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: '39%'
            },
            {
              gene: 'STK11',
              riskAge: 70,
              riskValue: '39%'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '37-48%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '37-48%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '36-50%'
            },
            {
              gene: 'GREM1',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'POLD1',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'POLE',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'MUTYH*',
              riskAge: 80,
              riskValue: '86%',
              hoverRisk: '<strong>86%</strong> with <em>MUTYH</em> mutation in both copies'
            },
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: '70-100%',
              hoverRisk: '<strong>70-100%</strong> with <em>APC</em> mutation without surgical intervention'
            }
          ]
          break
        case "colorectalCancerMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'colorectal cancer'
          scope.gender = 'M'
          scope.baselineRisks = {
            70: '2%',
            80: '3.4%',
            95: '4.6%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.footnote = 'Risk information for someone with two mutations in this gene.'
          scope.chartData = [
            {
              gene: 'CHEK2',
              riskAge: 70,
              riskValue: 'elevated (3-6%)'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '9%'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [13%]'
            },
            {
              gene: 'MUTYH',
              riskAge: 80,
              riskValue: 'elevated [14%]',
              hoverRisk: '<strong>Elevated</strong>* with a single <em>MUTYH</em> mutation'
            },
            {
              gene: 'PMS2',
              riskAge: 70,
              riskValue: '20%'
            },
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: '12-22%'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: '39%'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: '39%'
            },
            {
              gene: 'STK11',
              riskAge: 70,
              riskValue: '39%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '34-41%'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '48%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '48%'
            },
            {
              gene: 'GREM1',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'POLD1',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'POLE',
              riskAge: 95,
              riskValue: 'elevated [70%]'
            },
            {
              gene: 'MUTYH*',
              riskAge: 80,
              riskValue: '88%',
              hoverRisk: '<strong>88%</strong> with <em>MUTYH</em> mutation in both copies'
            },
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: '70-100%',
              hoverRisk: '<strong>70-100%</strong> with <em>APC</em> mutation without surgical intervention'
            }
          ]
          break
        case "melanomaFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'melanoma'
          scope.gender = 'F'
          scope.baselineRisks = {
            70: '<1%',
            80: '1.3%',
            95: '1.6%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [2.2%]'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: 'elevated (1-5%)'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '6%'
            },
            {
              gene: 'MITF',
              riskAge: 95,
              riskValue: 'elevated [8%]'
            },
            {
              gene: 'BAP1',
              riskAge: 95,
              riskValue: 'elevated [44%]'
            },
            {
              gene: 'CDKN2A',
              riskAge: 80,
              riskValue: '28-67%'
            },
            {
              gene: 'CDK4',
              riskAge: 95,
              riskValue: 'elevated [74%]'
            }
          ]
          break
        case "melanomaMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'melanoma'
          scope.gender = 'M'
          scope.baselineRisks = {
            70: '1.2%',
            80: '1.9%',
            95: '2.6%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [4%]'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: 'elevated (2-6%)'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '6%'
            },
            {
              gene: 'MITF',
              riskAge: 95,
              riskValue: 'elevated [13%]'
            },
            {
              gene: 'BAP1',
              riskAge: 95,
              riskValue: 'elevated [44%]'
            },
            {
              gene: 'CDKN2A',
              riskAge: 80,
              riskValue: '28-67%'
            },
            {
              gene: 'CDK4',
              riskAge: 95,
              riskValue: 'elevated [74%]'
            }
          ]
          break
        case "ovarianCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'ovarian cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            65: '<1%',
            70: '<1%',
            80: '1%',
            95: '1.3%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: 'elevated [1.5%]'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'PALB2',
              riskAge: 70,
              riskValue: 'elevated [5%]'
            },
            {
              gene: 'RAD51C',
              riskAge: 80,
              riskValue: 'elevated (9%)'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '8-10%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '8-10%'
            },
            {
              gene: 'RAD51D',
              riskAge: 80,
              riskValue: 'elevated (10%)'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: '12%'
            },
            {
              gene: 'PMS2',
              riskAge: 70,
              riskValue: 'elevated [12%]'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '6-13%'
            },
            {
              gene: 'BRIP1',
              riskAge: 80,
              riskValue: 'elevated (5-14%)'
            },
            {
              gene: 'STK11',
              riskAge: 65,
              riskValue: '21%'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: '54%'
            }
          ]
          break
        case "pancreaticCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'pancreatic cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            70: '<1%',
            80: '<1%',
            95: '1.5%'
          }
          scope.defaultBaselineRiskAge = 80 // Using age 80 instead of 95 because ATM would be below the baseline guide otherwise
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'PALB2',
              riskAge: 70,
              riskValue: 'elevated'
            },
            {
              gene: 'ATM',
              riskAge: 70,
              riskValue: 'elevated [1.1%]'
            },
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: '1.7%'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: 'elevated (2-3%)'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: 'elevated (3-5%)'
            },
            {
              gene: 'STK11',
              riskAge: 70,
              riskValue: '11%'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [11%]'
            },
            {
              gene: 'CDKN2A',
              riskAge: 80,
              riskValue: '58%'
            }
          ]
          break
        case "pancreaticCancerMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'pancreatic cancer'
          scope.gender = 'M'
          scope.baselineRisks = {
            70: '<1%',
            80: '1.1%',
            95: '1.5%'
          }
          scope.defaultBaselineRiskAge = 80
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'PALB2',
              riskAge: 70,
              riskValue: 'elevated'
            },
            {
              gene: 'ATM',
              riskAge: 70,
              riskValue: 'elevated [1.4%]'
            },
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: '1.7%'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '4%'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: 'elevated (3-5%)'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: 'elevated (3-6%)'
            },
            {
              gene: 'STK11',
              riskAge: 70,
              riskValue: '11%'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [11%]'
            },
            {
              gene: 'CDKN2A',
              riskAge: 80,
              riskValue: '58%'
            }
          ]
          break
        case "prostateCancerMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'prostate cancer'
          scope.gender = 'M'
          scope.baselineRisks = {
            70: '7.2%',
            80: '12%',
            95: '14%'
          }
          scope.defaultBaselineRiskAge = 80 // Using age 80 instead of 95 because CHEK2 would be below the baseline guide otherwise
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'ATM',
              riskAge: 70,
              riskValue: 'elevated'
            },
            {
              gene: 'TP53',
              riskAge: 80,
              riskValue: 'elevated'
            },
            {
              gene: 'CHEK2',
              riskAge: 70,
              riskValue: 'elevated [13%]'
            },
            {
              gene: 'BRCA2',
              riskAge: 70,
              riskValue: 'elevated (7-16%)'
            },
            {
              gene: 'BRCA1',
              riskAge: 80,
              riskValue: 'elevated [28%]'
            },
            {
              gene: 'NBN',
              riskAge: 95,
              riskValue: 'elevated [38%]'
            }
          ]
          break
        case "stomachCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'stomach cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            65: '<1%',
            70: '<1%',
            80: '<1%',
            95: '<1%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: 'elevated [1%]'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [2%]'
            },
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '5-8%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '5-8%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '6-11%'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: 'elevated (21%)'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: 'elevated (21%)'
            },
            {
              gene: 'STK11',
              riskAge: 65,
              riskValue: '29%'
            },
            {
              gene: 'CDH1',
              riskAge: 80,
              riskValue: '56-83%'
            }
          ]
          break
        case "stomachCancerMale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'stomach cancer'
          scope.gender = 'M'
          scope.baselineRisks = {
            65: '<1%',
            70: '<1%',
            80: '<1%',
            95: '1%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'APC',
              riskAge: 80,
              riskValue: 'elevated [1%]'
            },
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [3%]'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '5-8%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '5-8%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '6-20%'
            },
            {
              gene: 'BMPR1A',
              riskAge: 80,
              riskValue: 'elevated (21%)'
            },
            {
              gene: 'SMAD4',
              riskAge: 80,
              riskValue: 'elevated (21%)'
            },
            {
              gene: 'STK11',
              riskAge: 65,
              riskValue: '29%'
            },
            {
              gene: 'CDH1',
              riskAge: 80,
              riskValue: '67-70%'
            }
          ]
          break
        case "uterineCancerFemale":
          scope.chartStyle = 'cancer'
          scope.phenotype = 'uterine cancer'
          scope.gender = 'F'
          scope.baselineRisks = {
            65: '<1%',
            70: '1.7%',
            95: '2.8%'
          }
          scope.defaultBaselineRiskAge = 95
          scope.defaultBaselineGuide = scope.baselineRisks[scope.defaultBaselineRiskAge]
          scope.chartData = [
            {
              gene: 'TP53',
              riskAge: 95,
              riskValue: 'elevated [6%]'
            },
            {
              gene: 'STK11',
              riskAge: 65,
              riskValue: '9%'
            },
            {
              gene: 'PMS2',
              riskAge: 70,
              riskValue: '12-15%'
            },
            {
              gene: 'MSH6',
              riskAge: 70,
              riskValue: '16-26%'
            },
            {
              gene: 'PTEN',
              riskAge: 70,
              riskValue: '28%'
            },
            {
              gene: 'EPCAM',
              riskAge: 70,
              riskValue: '21-30%'
            },
            {
              gene: 'MSH2',
              riskAge: 70,
              riskValue: '21-30%'
            },
            {
              gene: 'MLH1',
              riskAge: 70,
              riskValue: '18-54%'
            }
          ]
          break
        case "employerCost":
          scope.chartStyle = 'basic'
          scope.legendData = null
          scope.chartTicks = ['$0', '', '$1 million', '', '$2 million', '', '$3 million', '']
          scope.footnote = 'Reflects the combined spend for heart disease, high blood pressure, and high cholesterol.'
          scope.chartData = [
            {
              rowTitle: 'Heart disease*',
              barWidth: '100%',
            },
            {
              rowTitle: 'Cancer',
              barWidth: '60%',
            },
            {
              rowTitle: 'Back pain',
              barWidth: '31%',
            },
            {
              rowTitle: 'Diabetes',
              barWidth: '13%',
            },
            {
              rowTitle: 'Arthritis',
              barWidth: '11%',
            },
            {
              rowTitle: 'Depression',
              barWidth: '8%',
            }
          ]
        default:
          break
      }

      scope.chartTickWidth = Math.trunc(100.0 / scope.chartTicks.length) + '%'

      // Fill in extra data to active chart if they're of `cancer` chartStyle
      if (scope.chartStyle == 'cancer') {
        scope.italicTitle = true
        ctrl.setTitle(scope.phenotype, scope.defaultBaselineGuide, scope.defaultBaselineRiskAge)
      }
      angular.forEach(scope.chartData, function(data) {
        if (scope.chartStyle == 'cancer') {
          data.rowTitle = data.gene
          data.barWidth = getRiskUpperBound(data.riskValue)
          data.hoverTitle = getHoverTitle(scope.phenotype, data.riskAge)
          data.hoverAverage = getBaselineAverage(scope.gender, data.riskAge)
          if (!data.hoverRisk) {
            data.hoverRisk = getHoverRisk(data.riskValue, data.gene)
          }
          if (isElevated(data.riskValue)) {
            data.hoverFootnote = elevatedHoverCopy
          }
        }
      })
    }
  }
}
