'use strict'

module.exports = (genderIdentities) => {
  'ngInject'

  return function(gender, selfDescribedGender) {
    if (gender == genderIdentities.FEMALE) {
      return 'female'
    } else if (gender == genderIdentities.MALE) {
      return 'male'
    } else if (gender == genderIdentities.NON_BINARY) {
      return 'non-binary'
    } else if (gender == genderIdentities.SELF_DESCRIBED) {
      return 'self-described - ' + selfDescribedGender
    } else {
      return 'n/a'
    }
  }
}
