module.exports = function clrProviderProfile() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      provider: '='
    },
    templateUrl: '/ordering_physicians/directives/clr_provider_profile.html',
    link: function(scope, element) {
      scope.showPhoneNumber = false
      scope.showFaxNumber = false
      scope.$watch('provider', function(provider) {
        if (provider) {
          if (angular.isString(scope.provider.phone_number)) {
            scope.showPhoneNumber = scope.provider.phone_number
          } else {
            scope.showPhoneNumber = scope.provider.phone_number && scope.provider.phone_number.national_number
          }

          if (angular.isString(scope.provider.fax_number)) {
            scope.showFaxNumber = scope.provider.fax_number
          } else {
            scope.showFaxNumber = scope.provider.fax_number && scope.provider.fax_number.national_number
          }
        }
      })
    }
  }
}
