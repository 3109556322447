const getAbsolutePath = require('monoweb/django_apps/core/static/js/absolute_path');

!function() {

  'use strict'

  const colorApp = angular.module('colorApp')

  colorApp.controller('NavigationController', function(
    $scope,
    $window,
    $location,
    auth,
    navigation,
    redirect,
    permissionTypes,
    waffle,
  ) {

    // Check whether or not this webview is located inside a Clinical iframe, if so disable the nav from showing
    $scope.isInClinicalIframe = $window.frameElement && $window.frameElement.nodeName == "IFRAME" && $window.frameElement.getAttribute('class') == 'clinical-report-iframe';

    $scope.navigation = navigation
    $scope.auth = auth
    $scope.location = $location
    $scope.locationHost = escape($window.location.protocol + '//' + $window.location.host)

    $scope.$watchCollection('auth.currentUser', function() {
      if ($scope.auth.isLoggedIn) {
        $scope.name = $scope.auth.currentUser.first_name ? $scope.auth.currentUser.first_name + ' ' + $scope.auth.currentUser.last_name : $scope.auth.currentUser.email
      } else {
        $scope.name = ''
      }
    })

    $scope.showSubNav = false
    $scope.toggleSubNav = function(subNavName) {
      const isSubNavChanged = $scope.subNavName != subNavName
      $scope.subNavName = subNavName
      if (!$scope.showSubNav || !isSubNavChanged) {
        $scope.showSubNav = !$scope.showSubNav
      }
    }

    $scope.showCollapsedNav = false
    $scope.toggleCollapsedNav = function() {
      if ($scope.showCollapsedNav) {
        $scope.showSubnav = false
      }
      $scope.showCollapsedNav = !$scope.showCollapsedNav
    }

    $scope.closeAllSubNav = function() {
      $scope.showSubNav = false
    }

    let subNavElement
    angular.element($window).on('click', function(e) {
      subNavElement = angular.element('.sub-nav.js--active')
      if (subNavElement.length) {
        $scope.closeAllSubNav()
      }
    })

    // I can't $el.bind('click', ...) because navigation isn't a directive,
    // this mimics $(document).click() and $(.navbar).click() to close opened nav states
    $scope.collapseAllNav = function() {
      $scope.showSubNav = false
      $scope.showCollapsedNav = false
    }

    $scope.logout = function() {
      $window.$('.body-wrapper, .footer').addClass('u--force-hide')
      auth.logout()
        .then(function() {
          $window.location.href = '/react/sign-in'
        })
        .catch(function() {
          $window.$('.body-wrapper, .footer').removeClass('u--force-hide')
        })
    }

    $scope.openHomeDashboard = function() {
      $scope.collapseAllNav()
      redirect('/react/dashboard')
    }

    $scope.openProviderPortal = function() {
      $scope.collapseAllNav()
      redirect('/')
    }

    $scope.changePath = function(path) {
      $location.path(path)
      $scope.collapseAllNav()
    }

    $scope.goToInstitution = function() {
      $window.location.assign('/providers/institution')
    }

    $scope.goToConfigurator = function() {
      $window.location.assign('/configurator')
    }

    $scope.permissionTypes = permissionTypes

    // NOTE(color-thenriod): If you change this code, you probably need to change the same code in
    // monoweb/django_apps/results/static/js/results/controllers/covid_report_list_controller.js
    // ifchange
    $scope.covidReportRetractionPageEnabled = !!(waffle.SWITCHES && waffle.SWITCHES['clinical-covid-report-retraction-2022-08'])
    // Why not just use the `href` attribute of an `<a>` you ask? In short, because it won't work.
    // I don't have the full explanation, but if you are in Angular-land, and you use an <a> in the
    // normal way to link to a page in React-land, you will end up on a blank page in Angular land
    // and you will have to manually refresh the page (which will have the correct url in the url
    // bar - so that's nice I guess). Forcing the redirection with a click handler that forcibly
    // updates the window location will cause a page reload that will actually put you in
    // React-land.
    $scope.goToCovidReportRetractionPage = function() {
      $window.location.assign('/covid_reports/retract')
    }
    // endifchange

    // NOTE(anuliksh): If you change this code, you probably need to change the same code in
    // monoweb/django_apps/results/static/js/results/controllers/covid_report_list_controller.js
    // ifchange
    $scope.medicalGeneticistReviewPageEnabled = !!(waffle.SWITCHES && waffle.SWITCHES['clinical-mg-signout-2022-09'])
    // Why not just use the `href` attribute of an `<a>` you ask? In short, because it won't work.
    // I don't have the full explanation, but if you are in Angular-land, and you use an <a> in the
    // normal way to link to a page in React-land, you will end up on a blank page in Angular land
    // and you will have to manually refresh the page (which will have the correct url in the url
    // bar - so that's nice I guess). Forcing the redirection with a click handler that forcibly
    // updates the window location will cause a page reload that will actually put you in
    // React-land.
    $scope.goToMedicalGeneticistReviewPage = function() {
      $window.location.assign('/results/genetics/signout')
    }
    // endifchange
  })

  colorApp.controller('NavigationLayoutController', function($scope, navigation) {
    $scope.navigation = navigation
    $scope.getAbsolutePath = getAbsolutePath
  })

  colorApp.controller('Display500PageController', function($rootScope) {
    const serverErrorTemplate = $('#serverErrorTemplate')
    const contentTemplate = $('#content')
    $rootScope.$on('error:InternalServerError', function() {
      serverErrorTemplate.css('display', 'block')
      contentTemplate.css('display', 'none')
    })
    $rootScope.$on('$locationChangeStart', function(event, next, current) {
      serverErrorTemplate.css('display', 'none')
      contentTemplate.css('display', 'block')
    })
  })

  colorApp.controller('VideoScriptController', function($scope, $location, testTypes, sku) {

    $scope.testTypes = testTypes

    // Set SKU and video name
    const parts = $location.path().split('/')
    const skuName = parts[parts.length - 1]
    if (skuName == 'the-color-test') {
      $scope.sku = sku['combo 2']
    } else if (skuName == 'hereditary-high-cholesterol-test') {
      $scope.sku = sku[testTypes.fh3]
    } else if (skuName == 'hereditary-cancer-test') {
      $scope.sku = sku[testTypes.hereditary30]
    } else if (skuName == 'breast-ovarian-cancer-test') {
      $scope.sku = sku[testTypes.breastOvarian19]
    } else if (skuName == 'brca-test') {
      $scope.sku = sku[testTypes.brca12]
    } else if (skuName == 'hereditary-heart-health-test') {
      $scope.sku = sku[testTypes.cardio30]
    } else if (skuName == 'tier-1-hereditary-conditions-test') {
      $scope.sku = sku['core 10']
    }

    $scope.showTestType = function(testType) {
      if (!$scope.sku) {
        return false
      }
      return $scope.sku.testTypes.indexOf(testType) > -1
    }

    // Set references
    let references = []
    if ($scope.showTestType(testTypes.fh3)) {
      references = references.concat([292, 293, 294, 289, 287])
    }
    if ($scope.showTestType(testTypes.hereditary30)) {
      references = references.concat([27, 2 ,3, 4, 5, 162, 104, 105])
    }
    if ($scope.showTestType(testTypes.breastOvarian19)) {
      references = references.concat([27, 2 ,3, 4])
    }
    if ($scope.showTestType(testTypes.brca12)) {
      references = references.concat([27, 2 ,3, 4, 24, 5, 34])
    }
    if ($scope.showTestType(testTypes.cardio30)) {
      references = references.concat([327, 323, 324, 325])
    }
    if ($scope.showTestType(testTypes.hereditary7)) {
      references = references.concat([27, 2, 3, 4, 24, 5, 6, 104, 105])
    }
    references.push(283)
    $scope.references = references.join(',')

  })

}();
