module.exports = function criteriaSubmissionCard() {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      criteria: '=',
      eligibility: '='
    },
    templateUrl: '/ordering_physicians/directives/criteria_submission_card.html',
    link: function(scope) {
      scope.met_criteria_unknown_eligibility = scope.eligibility &&
        scope.eligibility.eligibility_state == 'unknown_pass' &&
        scope.criteria.met_criteria
    }
  }
}
