'use strict'

module.exports = function() {
  "ngInject"

  return {
    restrict: 'E',
    require: '^^form',
    scope: { ngModel: '=' },
    templateUrl: '/users/sex_input.html',
    link: function(scope, elem, attr, controller) {
      scope.form = controller
      scope.sexOptions = [
        { value: "F", label: "Female" },
        { value: "M", label: "Male" }
      ]
    }
  }
}
