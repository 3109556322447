!function() {

  'use strict'

  /**
   * @ngdoc module
   * @name clrModal
   *
   * @description
   * The `clrModal` provides common modals.
   */
  const module = angular.module('clrModal', ['ui.bootstrap'])

  function TextareaInputModalController($scope, $modalInstance, onSave, title, buttonText, placeholder, initValue) {
    $scope.title = title
    $scope.buttonText = buttonText
    $scope.placeholder = placeholder
    $scope.note = initValue
    $scope.save = function(note) {
      $scope.saving = true
      return onSave(note)
        .then(function() {
          $modalInstance.close()
        })
        .finally(function() {
          $scope.saving = false
        })
    }
  }

  /**
   * @ngdoc service
   * @name textareaInputModal
   * @memberof clrModal
   *
   * @description
   * A modal with textarea input.
   */
  module.factory('textareaInputModal', function($modal) {
    return {
      open: function(onSave, title, buttonText, placeholder, initValue, size) {
        return $modal.open({
          templateUrl: '/textarea_input_modal.html',
          controller: TextareaInputModalController,
          size: size || 'lg',
          resolve: {
            onSave: function() {
              return onSave
            },
            title: function() {
              return title
            },
            buttonText: function() {
              return buttonText
            },
            placeholder: function() {
              return placeholder
            },
            initValue: function() {
              return initValue
            }
          }
        })
      }
    }
  })

  module.factory('dismissableErrorToast', function(ngToast) {
    return function(msg) {
      ngToast.create({
        className: 'error',
        content: msg,
        timeout: 20000,
        dismissButton: true
      })
    }
  })

  function TextModalController($scope, $modalInstance, title, text, buttonText, state, isHtml, cancelButtonText, onSave, isMarkdown, showContactSupportText, showCloseButton) {
    $scope.title = title
    $scope.text = text
    $scope.buttonText = typeof buttonText !== 'undefined' ? buttonText : "Done";
    $scope.showContactSupportText = showContactSupportText
    $scope.state = typeof state !== 'undefined' ? state : "";
    $scope.isHtml = isHtml
    $scope.isMarkdown = isMarkdown
    $scope.cancelButtonText = cancelButtonText
    $scope.showCloseButton = typeof showCloseButton !== 'undefined' ? showCloseButton : true;

    $scope.save = function() {
      if (onSave) {
        $scope.saving = true
        return onSave()
          .then(function() {
            $modalInstance.close()
          })
          .finally(function() {
            $scope.saving = false
          })
      } else {
        $modalInstance.close()
      }
    }
  }

  /**
   * @ngdoc service
   * @name textModal
   * @memberof clrModal
   *
   * @description
   * A generic text modal.
   */
  module.factory('textModal', function($modal) {
    return {
      open: function(title, text, buttonText, state, isHtml, cancelButtonText, onSave, isMarkdown, showContactSupportText, showCloseButton) {
        return $modal.open({
          templateUrl: '/text_modal.html',
          controller: TextModalController,
          size: 'md',
          resolve: {
            title: function() {
              return title
            },
            text: function() {
              return text
            },
            buttonText: function() {
              return buttonText
            },
            showContactSupportText: function() {
              return showContactSupportText
            },
            state: function() {
              return state
            },
            isHtml: function() {
              return !!isHtml
            },
            cancelButtonText: function() {
              return cancelButtonText
            },
            onSave: function() {
              return onSave
            },
            isMarkdown: function() {
              return isMarkdown
            },
            showCloseButton: function() {
              return showCloseButton
            }
          }
        })
      }
    }
  })

  function TextSaveModalController($scope, $modalInstance, title, buttonText, placeholder, resource, fieldName) {
    $scope.title = title
    $scope.buttonText = buttonText
    $scope.placeholder = placeholder
    $scope.note = resource[fieldName]
    $scope.save = function(note) {
      $scope.saving = true
      resource[fieldName] = note
      resource.$save()
        .then(function() {
          $modalInstance.close()
        })
        .finally(function() {
          $scope.saving = false
        })
    }

    $scope.dismiss = function() {
      $modalInstance.dismiss()
    }
  }

  /**
   * @ngdoc service
   * @name textSaveModal
   * @memberof clrModal
   *
   * @description
   * A modal with textarea input that saves upon completion.
   */
  module.factory('textSaveModal', function($modal) {
    return {
      open: function(title, buttonText, placeholder, resource, fieldName) {
        return $modal.open({
          templateUrl: '/textarea_input_modal.html',
          controller: TextSaveModalController,
          size: 'lg',
          resolve: {
            title: function() {
              return title
            },
            buttonText: function() {
              return buttonText
            },
            placeholder: function() {
              return placeholder
            },
            resource: function() {
              return resource
            },
            fieldName: function() {
              return fieldName
            }
          }
        })
      }
    }
  })

  /**
   * @ngdoc service
   * @name modalShim
   * @memberof clrModal
   *
   * @description
   * An iframe shim that manually adds an iframe for Windows-specific issues around modal windows not appearing above PDF embeds
   */
  module.factory('modalShim', function(device) {
    const iframeShimId = "modalShim"
    const iframeShim = angular.element('<iframe></iframe>').addClass("modal-shim").attr("id", iframeShimId)
    return {
      add: function() {
        if (device.isWindows) {
          angular.element('body').append(iframeShim)
        }
      },
      remove: function() {
        angular.element("#" + iframeShimId).remove()
      }
    }
  })

  // TODO: Add support for multiple images
  function MultiPageModalController($scope, $modalInstance, titles, texts, buttonTexts, images) {
    $scope.page = 0
    $scope.maxPage = titles.length - 1

    $scope.titles = titles
    $scope.texts = texts
    $scope.buttonTexts = buttonTexts
    $scope.images = images

    $scope.continue = function() {
      if ($scope.page < $scope.maxPage) {
        $scope.next()
      } else {
        $modalInstance.close()
      }
    }

    $scope.next = function() {
      if ($scope.page < $scope.maxPage) {
        $scope.page += 1
      }
    }

    $scope.back = function() {
      if ($scope.page > 0) {
        $scope.page -= 1
      }
    }

    $scope.getDotClass = function(page) {
      if (page == $scope.maxPage) {
        return ''
      }
      return 'u--margin-right-10'
    }
  }

  /**
   * @ngdoc service
   * @name multiPageModal
   * @memberof clrModal
   *
   * @description
   * A generic text modal.
   */
  module.factory('multiPageModal', function($modal) {
    return {
      open: function(titles, texts, buttonTexts, images) {
        return $modal.open({
          templateUrl: '/multi_page_modal.html',
          controller: MultiPageModalController,
          size: 'md',
          resolve: {
            titles: function() {
              return titles
            },
            texts: function() {
              return texts
            },
            buttonTexts: function() {
              return buttonTexts
            },
            images: function() {
              return images
            },
          }
        })
      }
    }
  })

}();
