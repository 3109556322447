// Paraphrased from https://github.com/angular-ui/ui-select/issues/258#issuecomment-75227867

module.exports = function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.uiSelectRequired = function(modelValue, viewValue) {
        if (attrs.uiSelectRequired) {
          if (!scope.$eval(attrs.uiSelectRequired)) {
            return true
          }
        }

        let determineVal;
        if (angular.isArray(modelValue)) {
          determineVal = modelValue;
        } else if (angular.isArray(viewValue)) {
          determineVal = viewValue;
        } else {
          return false;
        }
        return determineVal.length > 0;
      }
    }
  }
}
