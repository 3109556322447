/**
 * @prettier
 */

module.exports = function reportInfo(
  $filter,
  testTypes,
  OrderLog,
  reportSignedOffState,
  HealthHistory,
  clinicalDomains
) {
  'ngInject';

  return {
    restrict: 'AE',
    scope: {
      report: '=',
      isAnonymized: '=',
    },
    templateUrl: '/results/report_info.html',
    link: function(scope) {
      const report = scope.report;
      const isCovidReport = report.kit_order.test_type == testTypes.covid19;
      const isInOrderingPhysicianReview = report.state == reportSignedOffState;
      scope.testTypes = testTypes;
      scope.sample = report.sample;
      scope.clinicalDomain = clinicalDomains[report.kit_order.test_type];
      scope.healthHistory = new HealthHistory(report.user.health_profile || {});
      if (report.user.health_profile) {
        scope.healthHistoryUrl =
          '/clinical/v2/support/generic-health-history/show/' +
          report.user.health_profile.id +
          '/summary';
      }

      // Key info tab
      scope.showKeyInfoTab = !isInOrderingPhysicianReview && !isCovidReport;
      // Health history tab
      scope.showHealthHistoryTab =
        !isInOrderingPhysicianReview && report.require_health_history_collection && !isCovidReport;
      // Known Variants tab
      scope.showKnownVariants =
        !scope.isAnonymized && !isInOrderingPhysicianReview && !isCovidReport;
      // QC tab
      scope.showQCTab = !scope.isAnonymized && !isInOrderingPhysicianReview && !isCovidReport;

      if (!scope.isAnonymized) {
        // Report tabs
        if (report.state == 'new') {
          // If it's in new state, show v2 if it qualifies.
          scope.showReportV1Tab = scope.report.result_is_ready && !report.ready_for_v2_prod;
          scope.showReportV2Tab = scope.report.result_is_ready && report.ready_for_v2_prod;
        } else {
          // If it's not in new state, show v2 if it has v2 contents.
          scope.showReportV1Tab = !report.has_v2;
          scope.showReportV2Tab = report.has_v2;
        }
      }

      // Log tab
      if (!scope.isAnonymized) {
        OrderLog.get({ kit_order__order_number: report.kit_order.order_number }).$promise.then(
          function(res) {
            scope.eventLogs = res.results;
          }
        );
      }
    },
  };
};
