'use strict'

module.exports = function($scope, $http, auth, ngToast, $modal) {
  'ngInject'

  $scope.shareDataChanging = false
  $scope.form = {}

  $scope.shareDataChanged = function(event) {
    event.preventDefault()
    event.stopPropagation()

    $scope.shareDataChanging = true

    if (auth.currentUser.can_use_for_research) {
      const modalInstance = $modal.open({
        templateUrl: "/generic_modal.html",
        controller: function($scope, $modalInstance, options) {
          $scope.options = options
        },
        size: "sm",
        resolve: {
          options: function() {
            return {
              title: "Are you sure?",
              body: "Your information is shared anonymously and it helps " +
                          "researchers and research companies outside of Color develop " +
                          "new consumer, medical and diagnostic products.",
              primaryActionText: "I don't want to share my data",
              secondaryActionText: "Cancel change"
            }
          }
        }
      })
      modalInstance.result
        .then(function (scope) {
          $http.patch('/api/v1/accounts/' + auth.currentUser.id, { can_use_for_research: false })
            .then(function(res) {
              auth.currentUser.can_use_for_research = false
              ngToast.create('Your data will no longer be shared with researchers.')
            })
            .finally(function() {
              $scope.shareDataChanging = false
            })
        })
        .catch(function() {
          $scope.shareDataChanging = true
          $scope.form.shareData = true
        })
    } else {
      $http.patch('/api/v1/accounts/' + auth.currentUser.id, { can_use_for_research: true })
        .then(function(res) {
          auth.currentUser.can_use_for_research = true
        })
        .finally(function() {
          $scope.shareDataChanging = false
        })
    }
  }
}
