module.exports = function ReportV2Controller($translate, $scope, $route, $sce, $http, $document, $window, $raven, marked, handleReportClick, reportRevisionsModal, orderingProviderNotesModal) {
  'ngInject'

  // Show revision modal if revised report is opened for the first item.
  const newRevisions = $scope.report.revisions.filter(function(rv) { return !rv.opened_at })
  if (newRevisions && newRevisions.length) {
    $translate(['RESULT.NEW_REVISION_MODAL.TITLE', 'RESULT.NEW_REVISION_MODAL.BUTTON'])
      .then(function(translations) {
        const title = translations['RESULT.NEW_REVISION_MODAL.TITLE'];
        const btnText = translations['RESULT.NEW_REVISION_MODAL.BUTTON']
        reportRevisionsModal.open(newRevisions)
      })
  }

  const iframe = angular.element('#reportFrame')[0]

  function getIframeElementOffset(selector) {
    const innerDocument = iframe.contentDocument
    const element = innerDocument.querySelector(selector)
    if (element) {
      return element.offsetTop
    }
  }

  // $scope.report is LatestReport, serialized by ReleasedReportSerializer
  const {
    id: reportID,
    kit_order: { test_type: testType },
    kit_order: { order_number: kitOrderNumber },
    is_hh_provided,
    is_hh_under_review
  } = $scope.report
  const reportShareFamilyData = {}

  // load data used by health history banner and top nav
  $http.get(`/report/${reportID}/data.json`)
    .then((res) => {
      const {
        components,
        report_info: {
          sample,
          test_type,
          physician_order: {
            show_ordering_provider_notes,
            relevant_history,
            additional_recipients,
            order_placed_by,
            ordering_physician,
            primary_contact
          }
        }
      } = res.data;
      $scope.reportContent = {
        kit_order: {
          order_number: kitOrderNumber,
          test_type
        },
        sample,
        additional_recipients,
        order_placed_by,
        ordering_physician,
        show_ordering_provider_notes,
        relevant_history,
        primary_contact,

        // for banner
        is_hh_under_review,
        is_hh_provided
      }
      reportShareFamilyData.msgMarkdownSampleLetter = ''
      if (!!components.share_family_letter) {
        components.share_family_letter.content.map((c) => {
          reportShareFamilyData.msgMarkdownSampleLetter += marked(c)
        })
      }
    })
    .then(function() {
      // Show ordering provider notes if it's the first time opening.
      if ($scope.reportContent.show_ordering_provider_notes && !$scope.report.opened_at) {
        orderingProviderNotesModal.open($scope.reportContent)
      }
    })

  // logic for syncing navbar to iframe
  let navTop = 0;
  let iframeNavSelector;
  $scope.selectedTabIndex = 0
  window.onNavigate = (tabIndex) => {
    $scope.$applyAsync((scope) => {
      scope.selectedTabIndex = tabIndex
    })
  }
  window.iframeRegisterNav = function(elementName, navLst) {
    iframeNavSelector = elementName
    navTop = getIframeElementOffset(elementName)
    $scope.$apply(() => { $scope.tabs = navLst })
  }

  // scrollPos is a helper function that is passed to report_navbar for calculating
  // offset from top
  $scope.scrollPos = () => 0
  if (!!iframe) {
    $scope.scrollPos = () => iframe.offsetTop + navTop
  }

  // reportURL is loaded in iframe#reportFrame
  $scope.reportURL = $sce.trustAsResourceUrl(`/report/${reportID}/web_report`)

  function handleSmoothScrollToTabRequest(event) {
    const reportFrame = $('#reportFrame');
    const anchorElement = $('#v2-colortabs', reportFrame.contents());
    const offset = $window.scrollY - 40 // extra since our anchor div is above the horizontal nav

    $document.scrollToElementAnimated(anchorElement, offset)
  }

  // iframe#reportFrame onload callback
  $scope.reportFrameOnload = function() {
    // trigger iframe resize
    window.iFrameResize({
      checkOrigin: false,
      heightCalculationMethod: 'documentElementOffset',
      resizedCallback: (event) => {
        navTop = getIframeElementOffset(iframeNavSelector)
      }
    }, '#reportFrame')

    iframe.contentWindow.addEventListener(
      'smoothScrollToTab', handleSmoothScrollToTabRequest, false
    );

    // event handler for clicks in the iframe
    iframe.contentWindow.addEventListener('click', function(e) {
      handleReportClick(
        e, reportShareFamilyData, testType,
        kitOrderNumber
      )
    })

    // Capture exceptions from inside the report iframe and passes them to Sentry
    // https://stackoverflow.com/questions/6327128/can-i-catch-exception-of-iframe-in-parent-window-of-iframe
    iframe.contentWindow.onerror = (e) => {
      $raven.captureException(e)
    }
  }
}
