module.exports = angular.module('gc_tools', [
  require('monoweb/django_apps/counseling/js/gc_tools/modal').name,
  require('monoweb/django_apps/counseling/js/gc_tools/filters').name
]).config(function(colorRouteProvider) {

  const resolveResults = {
    'report': ['$route', 'Report', function($route, Report) {
      return Report.get({ id: $route.current.params.id }).$promise
    }],
  }

  colorRouteProvider
    .when('/counseling', {
      redirectTo: '/counseling/results/upcoming'
    })
    .when('/counseling/results/:type', {
      templateUrl: '/angular_js_templates/gc_tools/results.html',
      controller: require('monoweb/django_apps/counseling/js/gc_tools/controllers/counseling_results_controller'),
      auth: {
        requireGeneticCounselor: true
      }
    })
    .when('/counseling/patient/:patient_id', {
      templateUrl: '/angular_js_templates/gc_tools/detail.html',
      controller: require('monoweb/django_apps/counseling/js/gc_tools/controllers/counseling_detail_controller'),
      auth: {
        requireGeneticCounselor: true
      },

      // normally the view is report-based, this forces using only patient ID
      resolve: {
        'report': ['$route', 'Report', function($route, Report) {
          return { patient_id: $route.current.params.patient_id }
        }],
      }
    })
    .when('/counseling/:id', {
      templateUrl: '/angular_js_templates/gc_tools/detail.html',
      controller: require('monoweb/django_apps/counseling/js/gc_tools/controllers/counseling_detail_controller'),
      auth: {
        requireGeneticCounselor: true
      },
      resolve: resolveResults
    })
    // Citation Cheatsheet
    .when('/citations', {
      templateUrl: '/angular_js_templates/gc_tools/citations.html',
      controller: require('monoweb/django_apps/counseling/js/gc_tools/controllers/citation_list_controller')
    })
})
