'use strict'

module.exports = function() {
  'ngInject'

  return {
    restrict: 'E',
    require: '^form',
    scope: {
      step: '@'
    },
    templateUrl: '/kit_orders/sample_reuse/breadcrumb.html',
  }
}
