'use strict'

module.exports = function($filter, $translate, emailFamilyModal, sampleLetterModal) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/share_family.html',
    replace: true,
    scope: {
      reportId: '=',
      reportContent: '=',
      isSecondary: '='
    },
    link: function(scope) {
      scope.letter = ''
      scope.openEmailFamilyModal = function() {
        emailFamilyModal.open(scope.letter, scope.reportId, scope.reportContent.kit_order.test_type)
      }
      scope.openSampleLetterModal = function() {
        sampleLetterModal.open(scope.letter)
      }
      scope.items = []
      let letter
      let letterValues
      if (scope.reportContent.is_negative) {
        scope.t1 = 'RESULT.SHARE_FAMILY.NEG_T1'
        scope.p1 = 'RESULT.SHARE_FAMILY.NEG_P1'
        scope.items.push('FH_REPORT.SHARE_FAMILY.NEGATIVE.L1')
        scope.items.push('FH_REPORT.SHARE_FAMILY.NEGATIVE.L2')
        scope.items.push('FH_REPORT.SHARE_FAMILY.NEGATIVE.L3')
        scope.items.push('FH_REPORT.SHARE_FAMILY.NEGATIVE.L4')
        scope.items.push('FH_REPORT.SHARE_FAMILY.NEGATIVE.L5')
        letter = 'FH_REPORT.SHARE_FAMILY.NEGATIVE.LETTER'
      } else if (scope.reportContent.is_positive) {
        const mutations = $filter('pathogenicMutations')(scope.reportContent.mutations)
        const genes = scope.reportContent.genes_with_pathogenic_mutations
        if (genes.length != 1) {
          throw new Error("Only single gene is currently supported.")
        }
        scope.gene = genes[0]
        if (mutations.length == 1) {
          const mutation = mutations[0]
          letterValues = {
            classification: mutation.classification.toLowerCase(),
            gene: scope.gene,
            nomenclature: $filter('nomenclature')(mutation, 'primary').replace(/</g, '&lt;').replace(/>/g, '&gt;')
          }
          if (mutation.zygosity == "Heterozygous") {
            scope.t1 = 'RESULT.SHARE_FAMILY.SINGLE_POSITIVE_T1'
            scope.p1 = 'RESULT.SHARE_FAMILY.POS_P1'
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HET.L1')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HET.L2')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HET.L3')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HET.L4')
            letter = 'FH_REPORT.SHARE_FAMILY.POSITIVE_HET.LETTER'
          } else { // Homozygous
            scope.t1 = 'RESULT.SHARE_FAMILY.2_POS_T1'
            scope.p1 = 'RESULT.SHARE_FAMILY.POS_P1'
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.L1')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.L2')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.L3')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.L4')
            scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.L5')
            letter = 'FH_REPORT.SHARE_FAMILY.POSITIVE_HOM.LETTER'
          }
        } else if (!scope.reportContent.allelicity_by_gene[scope.gene]) {
          const nomenclatures = mutations.map(function(mutation) {
            return $filter('nomenclature')(mutation, 'primary').replace(/</g, '&lt;').replace(/>/g, '&gt;')
          })
          letterValues = {
            gene: scope.gene,
            nomenclatures: $filter('joinEnglishList')(nomenclatures)
          }
          scope.t1 = 'RESULT.SHARE_FAMILY.MULTI_POS_T1'
          scope.p1 = 'RESULT.SHARE_FAMILY.POS_P1'
          scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_UNKNOWN_PHASE.L1')
          scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_UNKNOWN_PHASE.L2')
          scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_UNKNOWN_PHASE.L3')
          scope.items.push('FH_REPORT.SHARE_FAMILY.POSITIVE_UNKNOWN_PHASE.L4')
          letter = 'FH_REPORT.SHARE_FAMILY.POSITIVE_UNKNOWN_PHASE.LETTER'
        } else {
          throw new Error("Only single mutation or unknown phase is currently supported.")
        }
      }
      $translate(letter, letterValues)
        .then(function(translation) {
          scope.letter = translation
        })
    }
  }
}
