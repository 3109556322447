"use strict"

/**
 * CLR PDF Viewer - dynamically creates container for embedded PDFs if the browser supports
 */

module.exports = function($window, $rootScope, device) {
  "ngInject"

  return {
    restrict: 'E',
    scope: {
      pdfUrl: '@',
      pdfHeight: '@?'
    },
    link: function(scope, elem) {
      function embedPDF() {
        const options = {
          width: "100%",
          height: "100%",

          // This HTML is inserted if the user's browser doesn't support embedded pdfs
          fallbackLink: "<p>Your browser does not support inline PDFs. Please <a href='[url]' target='_blank' rel='noopener noreferrer'>download the PDF</a> to view it.",

          // Acrobat PDF param order matters, don't shift around: http://wwwimages.adobe.com/content/dam/Adobe/en/devnet/acrobat/pdfs/AcrobatDC_pdf_open_parameters.pdf
          pdfOpenParams: {
            zoom: 100,
            navpanes: 1,
            toolbar: 1,
            scrollbar: 1
          }
        }
        $window.PDFObject.embed(scope.pdfUrl, '#' + scope.pdfDomId, options)
      }

      // Give DOM node random ID in case this directive is used multiple times
      scope.pdfDomId = "pdf" + Math.floor(Math.random() * 100000 + 1)
      const pdfFrame = angular.element("<div></div>").attr("id", scope.pdfDomId).addClass("clr-pdf-viewer")
      if (scope.pdfHeight == 'tall') {
        pdfFrame.addClass("clr-pdf-viewer--tall")
      }
      elem.append(pdfFrame)

      embedPDF()

      // Update the displayed PDF if the url changes
      scope.$watch('pdfUrl', embedPDF)

      // Allow reloading PDF via $broadcast
      const deregister = $rootScope.$on('pdf:reload', embedPDF)
      scope.$on('$destroy', deregister)
    }
  }
}
