module.exports = function ReportController(
  $scope,
  $filter,
  $translate,
  $translatePartialLoader,
  reportRevisionsModal,
  testTypes,
  reportContent,
  reportGenericScreeningGuidelines,
  namedMutation,
  defaultLanguage
) {
  "ngInject";

  // from the parent
  const report = $scope.report;
  const isPDF = $scope.isPDF;

  $scope.reportContent = reportContent(report);
  $scope.testTypes = testTypes;

  $translatePartialLoader.addPart("report");
  // Translated report strings (only contains of subset of report components shared between normal and abbreviated)
  $translatePartialLoader.addPart("translated_report");

  // Translations for FH3 test type.
  if ($scope.reportContent.template.test_type == testTypes.fh3) {
    $translatePartialLoader.addPart("fh_report");
  }

  // Show revision modal if revised report is opened for the first item.
  const newRevisions = $filter("filter")(report.revisions, { opened_at: null });
  if (!isPDF && newRevisions && newRevisions.length) {
    $translate([
      "RESULT.NEW_REVISION_MODAL.TITLE",
      "RESULT.NEW_REVISION_MODAL.BUTTON"
    ]).then(function(translations) {
      const title = translations["RESULT.NEW_REVISION_MODAL.TITLE"];
      const btnText = translations["RESULT.NEW_REVISION_MODAL.BUTTON"];
      reportRevisionsModal.open(newRevisions);
    });
  }

  // If there is no risks to show for the report we will need to hide
  // risk section explicitly. We can't currently do this at directive
  // level since a card is responsible for showing multiple directives.
  $scope.showPdfSummaryRisks =
    isPDF &&
    ($scope.reportContent.is_negative ||
      $scope.reportContent.risks.filter(function(r) {
        const hasElevatedRisks = r.data.filter(function(d) {
          return d.risk != "not elevated";
        }).length;
        return (
          r.gender == $scope.reportContent.gender &&
          r.gene != "" &&
          hasElevatedRisks
        );
      }).length);

  // Process report references and what we anticipate the report to have
  let reportReferences = $filter("getReportReferences")(
    $scope.reportContent,
    "A"
  );

  function removeReference(referenceId) {
    const index = reportReferences.indexOf(referenceId);
    if (index > -1) {
      reportReferences.splice(index, 1);
    }
  }

  // TODO: Refactor the following references logic into getReportReferences $filter
  if ($scope.reportContent.is_positive) {
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BRCA2"]
      )
    ) {
      // Check nomenclature for T2626C, if so, add ref 318
      if (
        $filter("containsMutation")(
          $scope.reportContent.mutations,
          "chr13.GRCh37:g.32936732G>C"
        )
      ) {
        reportReferences.push(318);
      }
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["TP53"]
      )
    ) {
      // Questions: Family
      // Showing female breast cancer screening guideline referece.
      if (
        $scope.gender == "F" &&
        !$scope.reportContent.is_somatic &&
        $scope.reportContent.template.test_type != testTypes.wisdom9
      ) {
        reportReferences.push(59);
      }
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["PTEN"]
      )
    ) {
      // Questions: Family
      if (
        !$scope.reportContent.is_somatic &&
        $scope.reportContent.template.test_type != testTypes.wisdom9
      ) {
        if ($scope.reportContent.template.test_type == testTypes.hereditary30) {
          //- TODO: apply render_version upgrade next bump
          reportReferences.push(167);
        } else {
          reportReferences.push(59);
        }
      }
    }
    if (
      $scope.reportContent.template.test_type != testTypes.wisdom9 &&
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["STK11"]
      )
    ) {
      // Questions: Family
      reportReferences.push(304);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["RAD51C"]
      )
    ) {
      // About the gene
      reportReferences.push(81);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["RAD51D"]
      )
    ) {
      // About the gene
      reportReferences.push(119, 120);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["CHEK2"]
      )
    ) {
      // Questions: Family, About the Gene
      reportReferences.push(61);
      reportReferences.push(70);
      reportReferences.push(71);
      // Check nomenclature for I157T, if so, add ref 74
      if (
        $filter("containsMutation")(
          $scope.reportContent.mutations,
          "chr22.GRCh37:g.29121087A>G"
        )
      ) {
        reportReferences.push(74);
      }
      if (
        ($scope.reportContent.gender == "M" &&
          $scope.reportContent.genes_with_pathogenic_mutations.length < 2) ||
        $scope.reportContent.template.test_type == testTypes.hereditary30
      ) {
        // Remove non-elevated ovarian cancer risk citation, only for single mutation reports, and for all H30 reports
        removeReference(72);
      }
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BAP1"]
      )
    ) {
      if ($scope.reportContent.template.test_type == testTypes.hereditary30) {
        reportReferences.push(186, 187, 217);
      }
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BARD1"]
      )
    ) {
      if ($scope.reportContent.template.test_type == testTypes.hereditary30) {
        // Remove non-elevated breast cancer risk citation for all H30 reports
        removeReference(123);
      }
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BMPR1A"]
      )
    ) {
      reportReferences.push(235); // About the Gene
      reportReferences.push(304); // Questions - About Family
    }
    // On BO19 reports we surface breast cancer risks even if not elevated by
    // that gene so we use citation 92 to support not elevated except for single MSH2 M and single PMS2 M.
    if ($scope.reportContent.template.test_type == testTypes.breastOvarian19) {
      if (
        $filter("containsAny")(
          $scope.reportContent.genes_with_pathogenic_mutations,
          ["MSH2", "PMS2"]
        )
      ) {
        if (
          $scope.reportContent.gender == "M" &&
          $scope.reportContent.genes_with_pathogenic_mutations.length < 2
        ) {
          // Remove breast cancer risk citation, only for single mutation reports of MSH2 and PMS2
          removeReference(92);
        }
      }
    } else {
      removeReference(92);
    }
    if (
      $scope.reportContent.template.test_type != testTypes.wisdom9 &&
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["ATM"]
      )
    ) {
      // About the gene
      reportReferences.push(88, 89);
      // Check nomenclature for 7271T>G, if so, add ref 169, 170, 171
      if (
        $filter("containsMutation")(
          $scope.reportContent.mutations,
          "chr11.GRCh37:g.108199929T>G"
        )
      ) {
        reportReferences.push(169, 170, 171);
      }
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["TP53"]
      ) &&
      !$scope.reportContent.is_somatic
    ) {
      // About the gene
      reportReferences.push(103);
    }
    // Likely Pathogenic
    if ($filter("hasLikelyPathogenic")($scope.reportContent.mutations)) {
      reportReferences.push(77);
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["APC"]
      )
    ) {
      // APC I1307K
      // About the gene: Refs 218, 199 are added via risks

      // About the gene (for non-APC I1307K)
      if (
        !$filter("containsMutation")(
          $scope.reportContent.mutations,
          namedMutation.apc_i1307k
        )
      ) {
        reportReferences.push(147, 155);
      } else {
        reportReferences.push(218);
      }
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["MITF"]
      )
    ) {
      // About the gene
      reportReferences.push(186, 187);
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["MUTYH"]
      )
    ) {
      // About the gene, monoallelic
      reportReferences.push(194);
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["CDKN2A"]
      )
    ) {
      // About the gene
      reportReferences.push(186);
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["CDK4"]
      )
    ) {
      // About the gene
      reportReferences.push(262);
    }

    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["SMAD4"]
      )
    ) {
      // About the gene
      reportReferences.push(235);
      reportReferences.push(316);
    }
  }

  // Questions: About Cancer Survivors added references, only for BO19
  if ($scope.reportContent.template.test_type == testTypes.breastOvarian19) {
    // Survivor + F
    if ($scope.reportContent.gender == "F") {
      if ($scope.reportContent.breast_cancer_survivor) {
        reportReferences.push(55);
      }
      if ($scope.reportContent.ovarian_cancer_survivor) {
        reportReferences.push(56, 57);
      }
      if ($scope.reportContent.fallopian_tube_cancer_survivor) {
        reportReferences.push(56, 57);
      }
    }
    // Survivor + M
    if (
      $scope.reportContent.gender == "M" &&
      $scope.reportContent.breast_cancer_survivor
    ) {
      reportReferences.push(76);
    }
  }

  // VUS citations (also Wisdom9 never has VUS content)
  if (
    $filter("mutationsHaveVus")($scope.reportContent.mutations) &&
    $scope.reportContent.template.test_type != testTypes.wisdom9
  ) {
    reportReferences.push(7, 8, 14);
    // render_version changes to VUS citation
    // <= 2: Use NCCN Genetic/Familial High-Risk Assessment: Breast and Ovarian V 2.2015
    // >= 3: Use V 1.2017
    if ($scope.reportContent.render_version <= 2) {
      reportReferences.push(59);
    } else if ($scope.reportContent.render_version >= 3) {
      reportReferences.push(225);
    }
  }

  // Wisdom-specific "Increased risk for other cancers" summary extra reference additions
  if ($scope.reportContent.template.test_type == testTypes.wisdom9) {
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["ATM"]
      )
    ) {
      reportReferences.push(87);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BRCA1"]
      )
    ) {
      reportReferences.push(24, 38);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["BRCA2"]
      )
    ) {
      reportReferences.push(24, 38, 64);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["CDH1"]
      )
    ) {
      reportReferences.push(84, 85);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["CHEK2"]
      )
    ) {
      reportReferences.push(73);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["PALB2"]
      )
    ) {
      reportReferences.push(79, 82, 83, 123);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["PTEN"]
      )
    ) {
      reportReferences.push(116, 117);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["STK11"]
      )
    ) {
      reportReferences.push(124, 125, 126, 127);
    }
    if (
      $filter("containsAny")(
        $scope.reportContent.genes_with_pathogenic_mutations,
        ["TP53"]
      )
    ) {
      reportReferences.push(96, 97, 98, 99, 100, 101);
    }
  }

  // BO19 Test Methodologies Version 3 and 4 citation addition
  if (
    ($filter("testMethodologyVersion")($scope.reportContent) == 3 ||
      $filter("testMethodologyVersion")($scope.reportContent) == 4) &&
    $scope.reportContent.template.test_type == testTypes.breastOvarian19
  ) {
    reportReferences.push(27);
  }

  // H30 positive other screening guidelines citations, currently only for Female
  if (
    $scope.reportContent.template.test_type == testTypes.hereditary30 &&
    $scope.reportContent.is_positive
  ) {
    angular.forEach(
      reportGenericScreeningGuidelines($scope.reportContent.render_version),
      function(guideline) {
        if (
          guideline.gender
            ? $scope.reportContent.gender == guideline.gender
            : true
        ) {
          if (
            !$filter("screeningGuidelinesContainPhenotype")(
              $scope.reportContent.template.screening_guidelines,
              guideline.phenotype
            )
          ) {
            reportReferences = reportReferences.concat(guideline.references);
          }
        }
      }
    );
  }

  $scope.languages = [defaultLanguage];

  if ($scope.reportContent.template.test_type == testTypes.fh3) {
    // from risks
    reportReferences = $filter("uniqueReferences")($scope.reportContent.risks);
    // from screening guidelines
    $filter("fhGuidelines")($scope.reportContent).forEach(function(guideline) {
      reportReferences = reportReferences.concat(guideline.references);
    });
    // VUS
    if ($filter("mutationsHaveVus")($scope.reportContent.mutations)) {
      reportReferences.push(283);
    }
    // Positive
    if ($scope.reportContent.is_positive) {
      // We currently don't show baseline risk for FH positive report
      // so we want to remove the reference here.
      const index = reportReferences.indexOf(284);
      if (index > -1) {
        reportReferences.splice(index, 1);
      }
    }
  }

  $scope.reportReferences = reportReferences.join(",");
};
