/**
 * @prettier
 */

const E164Regex = require('monoweb/django_apps/core/static/js/e164_regex').E164Regex;
const phonePrefix = require('monoweb/django_apps/core/static/js/geo_constants_definitions').phonePrefix;

/**
 * Converts a phone number integer value into
 * (xxx) xxx-xxxx or +x (xxx) xxx-xxxx or +x-xxx-xxx-xxxx
 *
 * @param tel - The phone number to format
 * @param format - The format
 */
function formatPhone(tel, format) {
  if (!tel) {
    return '';
  }
  let country;
  let number;
  let extension;

  // Handle telephone objects, generated by clr-phone-input in some cases
  // when a field is represented with a PhoneNumberField on the back-end.
  // eslint-disable-next-line
  if (typeof tel === 'object') {
    const prefix = phonePrefix(tel.country);
    number = tel.national_number;
    extension = tel.extension;

    if (!number) {
      return '';
    }

    tel = prefix + number;
    if (extension) {
      tel = tel + ';ext=' + extension;
    }
  }

  // Handle E.164-formatted telephone numbers. PhoneNumberFields will
  // return their values in this (very constrained) format. We can match it
  // with a single regex and save a lot of guesswork.
  const e164Match = E164Regex.exec(tel);

  if (e164Match) {
    country = e164Match[1];
    number = e164Match[2];
    extension = e164Match[3];
    let formatted;

    if (country === '1' && number.length === 10) {
      // Format +12015555555 => (201) 555-5555
      formatted = '(' + number.slice(0, 3) + ') ' + number.slice(3, 6) + '-' + number.slice(6);
    } else {
      // Format +441212345678 => +44 1212345678
      formatted = '+' + country + ' ' + number;
    }

    if (extension) {
      formatted += ' ext. ' + extension;
    }

    return formatted;
  }

  // Clean +-() characters from the input
  const value = tel
    .toString()
    .trim()
    // eslint-disable-next-line no-useless-escape
    .replace(/[\+\-()\s]/g, '');

  // If there's anything other than numbers left in the input, return the
  // original value directly.
  if (value.match(/[^0-9]/)) {
    return tel;
  }

  let city;

  switch (value.length) {
    // Assumes US/Canada +1 telephone numbers
    // To handle more cases up to 15 digits, refer to https://en.wikipedia.org/wiki/List_of_country_calling_codes
    case 10: // PPP#######
      country = 1;
      city = value.slice(0, 3);
      number = value.slice(3);
      break;
    case 11: // CPPP#######
      country = value[0];
      city = value.slice(1, 4);
      number = value.slice(4);
      break;
    default:
      return tel;
  }

  number = number.slice(0, 3) + '-' + number.slice(3);

  let formatted;
  // For more format info, see https://en.wikipedia.org/wiki/Microsoft_telephone_number_format#Format_comparison_with_E.123
  if (format === 'international') {
    // infers E.123 telephone number, international notation
    // +C-PPP-###-####
    formatted = ('+' + country + '-' + city + '-' + number).trim();
  } else if (format === 'microsoft') {
    // infers Microsoft telephone number format
    // +C (PPP) ###-####
    formatted = ('+' + country + ' (' + city + ') ' + number).trim();
  } else {
    // infers E.123 telephone number, national notation
    // (PPP) ###-####
    formatted = ('(' + city + ') ' + number).trim();
  }

  if (extension) {
    return formatted + ' ext. ' + extension;
  } else {
    return formatted;
  }
}

module.exports = formatPhone;
