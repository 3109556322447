const resolvers = require('monoweb/django_apps/core/static/js/resolvers')

module.exports = angular.module('kitRegistrations', [
  require('monoweb/django_apps/kit_registrations/js/directives').name,
  require('monoweb/django_apps/kit_registrations/js/modals').name,
  require('monoweb/django_apps/kit_registrations/js/controllers').name
])
  .config(function(colorRouteProvider) {
    colorRouteProvider
    // Legacy genetics activation routes (/genetics/activation/*) are exclusively served by React now
  })
