'use strict'

module.exports = function($window, $q, $timeout) {
  'ngInject'
  let scriptLoaded = false

  function load_script(script_base_url) {
    const url = script_base_url
    const s = $window.document.createElement('script')
    s.src = url
    $window.document.head.appendChild(s)
    scriptLoaded = true
  }

  function lazyLoader(script_base_url, windowObject) {
    const deferred = $q.defer()
    if (!scriptLoaded) { // Not loaded yet
      $timeout(function() {
        load_script(script_base_url)
      }, 0, false)
    } else { // Already loaded
      deferred.resolve($window[windowObject])
    }
    return deferred.promise
  }

  return {
    get: function(script_base_url, windowObject) {
      return lazyLoader(script_base_url, windowObject)
    }
  }

}
