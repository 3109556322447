module.exports = {
  hero: {
    title: 'Color and Your Health',
    buttonText: 'Get Started'
  },
  sectionValuePropositions: {
    title: 'A personalized approach to prevention and health management',
    subtitle: 'Color’s confidential service helps you better understand your hereditary risks, helping you, and possibly your family, lead a healthier life.',
    items: [
      {
        image: 'illo_NegativeResults',
        heading: 'Actionable results',
        paragraph: 'Your results can help you and your doctor create a personalized screening and health management plan.'
      },
      {
        image: 'illo_GCTalk',
        heading: 'Personal guidance',
        paragraph: 'Get complimentary access to our board-certified genetic counselors to help you understand your results.'
      },
      {
        image: 'illo_ScreenLock',
        heading: 'Confidential information',
        paragraph: 'We take privacy very seriously and your personally identifiable information will not be shared with your employer without your consent.'
      },
    ],
  },
  sectionHealthAreas: {
    title: 'From a simple saliva sample, you can learn potentially life-changing information',
    subtitle: 'Clinical information to help make choices about your health with your doctor.',
    image: 'illo_CancerHeartMedication',
    showHereditary: true,
    showCardio: true,
  },

  sectionDiscovery: {
    title: 'Discover fun, genetic insights about yourself',
    subtitle: 'Take a glimpse into what your genes can reveal. Color Discovery is not yet available in countries outside the US.',
    items: [
      {
        heading: 'How well do you digest dairy products?',
        image: 'illo_Lactose',
      },
      {
        heading: 'Did you know that your genes determine earwax type?',
        image: 'illo_Earwax',
      },
      {
        heading: 'Are you likely to love or hate cilantro?',
        image: 'illo_Cilantro',
      },
    ]
  },
  sectionFamily: {
    reverse: true,
    title: 'When you learn, your family can benefit too',
    subtitle: 'Because genes are passed down through families, what you learn could also provide insights for your relatives.',
    backgroundImage: 'img_MomKidsTable',
  },
  sectionClientVideos: {
    title: '',
    quotes: [
      {
        image: 'img_Robin',
        kicker: 'Robin shares why he believes in the power of genetic testing for prostate cancer.',
        text: 'I want my whole family to be tested by Color, because I am interested in their futures and I want their futures to be bright.”',
        author: 'Robin Cole, Two-time Super Bowl champion, prostate cancer survivor',
        video: {
          cta: 'Hear Robin’s Story',
          name: 'storiesRobin',
        },
      },
      {
        image: 'img_Marissa',
        kicker: 'Marissa, who was adopted, put together missing pieces in her health history',
        text: 'The fact that my company offered this to employees is incredible. It really shows how much they care about employee well being.”',
        author: 'Marissa, employee, <em>BRCA2</em> positive',
        video: {
          cta: 'Hear Marissa’s Story',
          name: 'benefitsMarissaStory',
        },
      },
    ],
  },
  sectionHowItWorks: {
    title: 'Here’s how Color works',
    items: [
      {
        heading: 'Claim your Color benefit online and create an account to request your kit.',
      },
      {
        heading: 'Provide a saliva sample and drop your kit in the mail.',
      },
      {
        heading: 'Get an email when your results are ready in 3-4 weeks.',
      },
      {
        heading: 'Meet with our genetic counselors.',
      },
      {
        heading: 'Create a screening and prevention plan with your healthcare provider.',
      },
    ],
  },
  sectionPurchase: {
    title: 'Ready to get Color?',
    body: 'Get to know what your genes say about your risk for common hereditary cancers and heart conditions, plus how your body processes certain medications. Questions? Get in touch at <a href="mailto:support@color.com">support@color.com</a>.',
    cta: 'Get Started',
    ctaHref: '#email_action',
  },
}
