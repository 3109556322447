!function() {

  'use strict'

  const colorApp = angular.module('colorApp')

  colorApp.controller('SelectSexController', function($scope, $http, $translate, auth, ngToast, redirect, textModal, genderIdentities) {
    const defaultRedirectPath = '/health-history'

    $scope.showGenderIdentityOptions = false
    $scope.identity = {}
    $scope.genderIdentities = genderIdentities
    $scope.modalDisplayed = false

    if (auth.currentUser.gender) {
      redirect(defaultRedirectPath)
    }

    $scope.openGenderIdentityContent = () => {
      $scope.showGenderIdentityOptions = true
    }

    $scope.sexClicked = (sex) => {
      if (sex === genderIdentities.FEMALE) {
        $scope.identity.gender = genderIdentities.FEMALE
      } else {
        $scope.identity.gender = genderIdentities.MALE
      }
    }

    $scope.displayModal = () => {
      if ($scope.identity.gender_identity != null && $scope.identity.gender_identity != $scope.identity.gender && !$scope.modalDisplayed) {
        $scope.modalDisplayed = true
        $translate([
          "SELECT_SEX.GENDER_MODAL.TITLE",
          "SELECT_SEX.GENDER_MODAL.DESCRIPTION"
        ]).then((translations) => {
          textModal.open(translations["SELECT_SEX.GENDER_MODAL.TITLE"],
            translations["SELECT_SEX.GENDER_MODAL.DESCRIPTION"],
            'I understand',
            null,
            false,
            null,
            null,
            false,
            true,
            false).result
        })
      }
    }

    $scope.setSexAndGenderIdentity = () => {
      if ($scope.identity.gender_identity !== genderIdentities.SELF_DESCRIBED) {
        $scope.identity.self_described_gender_identity = null
      }
      $scope.submitting = true
      $http.patch('/api/v1/accounts/' + auth.currentUser.id, $scope.identity)
        .then(function() {
          redirect(defaultRedirectPath)
        }).catch(function() {
          ngToast.create({ className: 'error', content: 'There was an issue submitting this information, please try again.' })
        }).finally(function() {
          $scope.submitting = false
        })
    }
  })
}();
