'use strict'

module.exports = function($raven, ngToast, textModal) {
  'ngInject'

  return function(res, checkout, followup) {
    /*
        Stripe docs: https://stripe.com/docs/testing
        Possible error messages and card numbers to test with:
        4000000000000002 && 4100000000000019: "Your card was declined."
        4000000000000127: "Your card's security code is incorrect."
        4000000000000069: "Your card has expired."
        4000000000000119: "An error occurred while processing your card. Try again in a little bit."
      */
    if (!res) {
      $raven.capture('handlePaymentError caught unknown error')
      ngToast.create({ className: 'error', content: 'Unknown error' })
      return

    } else if (res.status == 402 || res.error) {
      // Iterate KitCheckout.paymentErrorsCount up per payment error
      checkout.paymentErrorsCount++
      let reason
      if (res.status == 402) {
        // Error coming from our API/Django, returns what Stripe returns in
        // `error.message`, always a single string from them. DRF returns it
        // as a list of `non_field_errors`
        reason = res.data.non_field_errors[0]
      } else if (res.error) {
        // Error returning from https://api.stripe.com/v1/tokens
        reason = res.error.message
      } else {
        reason = "Your payment authorization failed."
      }
      const modal = textModal.open(
        "We couldn't process your payment"
        , "<strong>" + reason + "</strong> Please verify your information and try again, or try another credit or debit card."
        , "Update Card"
        , 'error'
        , true
      )
      if (followup) {
        modal.result.then(followup)
      }
    }

    // Stripe card token error.
    else if (res.message) {
      ngToast.create({ className: 'error', content: res.message })
      return
    }
  }
}
