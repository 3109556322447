'use strict'

module.exports = function($filter) {
  'ngInject'
  return {
    restrict: 'E',
    templateUrl: '/results/fh/about_the_gene.html',
    replace: true,
    scope: {
      reportContent: '='
    }
  }
}
