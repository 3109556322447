"use strict"

module.exports = function($rootScope) {
  "ngInject"

  return {
    restrict: 'A',
    scope: {
      clrTabSelect: '@'
    },
    link: function(scope, elem) {
      elem.bind('click', function() {
        $rootScope.$broadcast('clrTab:selectTab', scope.clrTabSelect)
      })
    }
  }
}
