module.exports = function providerFormsController(
  $scope,
  kitPriceService,
  geneCount,
  sku,
  testTypes
) {
  'ngInject'

  $scope.geneCount = geneCount
  $scope.h30KitPriceDollars = kitPriceService.getAdvertisedKitPriceDollars(sku[testTypes.hereditary30])
  $scope.brcaKitPriceDollars = kitPriceService.getAdvertisedKitPriceDollars(sku[testTypes.brca12])
  $scope.fhKitPriceDollars = kitPriceService.getAdvertisedKitPriceDollars(sku[testTypes.fh3])
}
