module.exports = angular.module('providerPlatformPlaceOrderDirectives', [])
  .directive('additionalInformationForm', require('./place_order_directives/additional_information_form'))
  .directive('criteriaSubmissionCard', require('./place_order_directives/criteria_submission_card'))
  .directive('eligibilitySubmissionCard', require('./place_order_directives/eligibility_submission_card'))
  .directive('hbocInsuranceCriteriaForm', require('./place_order_directives/hboc_insurance_criteria_form'))
  .directive('healthHistoryDiagnosisForm', require('./place_order_directives/health_history_diagnosis_form'))
  .directive('healthHistoryGeneticTestResultForm', require('./place_order_directives/health_history_genetic_test_result_form'))
  .directive('icd10CmCodeSelector', require('./place_order_directives/icd_10_cm_code_selector'))
  .directive('orderingPhysicianSelector', require('./place_order_directives/ordering_physician_selector'))
  .directive('physicianOrderBreadcrumbs', require('./place_order_directives/physician_order_breadcrumbs'))
  .directive('placeCreditCard', require('./place_order_directives/place_credit_card'))
  .directive('placeInsuranceDetails', require('./place_order_directives/place_insurance_details'))
  .directive('placePhysicianOrderKit', require('./place_order_directives/place_physician_order_kit'))
  .directive('placePrimaryContact', require('./place_order_directives/place_primary_contact'))
  .directive('placeSample', require('./place_order_directives/place_sample'))
  .directive('placeTestingOptions', require('./place_order_directives/place_testing_options'))
  .directive('checkMinor', require('./place_order_directives/check_minor'))
  .directive('placeTestType', require('./place_order_directives/place_test_type'))
  .directive('saveDraft', require('./place_order_directives/save_draft'))
  .directive('searchProvider', require('./place_order_directives/search_provider'))
