/**
 * @prettier
 */

module.exports = function reportMutation(defaultLanguage) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_mutation.html',
    replace: true,
    scope: {
      mutation: '=',
      isSomatic: '=',
      hideZygosity: '=?',
      isVariant: '=?',
      language: '@?',
    },
    link: function(scope) {
      if (angular.isUndefined(scope.language)) {
        scope.language = defaultLanguage;
      }
    },
  };
};
