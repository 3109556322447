'use strict'

module.exports = function() {
  'ngInject'
  return function(birthdate) {
    const eighteenYearsAgo = new Date()
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
    return birthdate > eighteenYearsAgo
  }
}
