"use strict"

/**
 * Adds form error element for a given form input object.
 * It automatically show and hide error appropriately.
 * If you need to customize visibility of error message,
 * you can set showError [true|false] variable for the form input object.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'AE',
    require: '^form',
    scope: {
      formInput: '=formInput',
      name: '@',
      minlength: '@',
      maxlength: '@',
      min: '@',
      max: '@',
      msg: '@'
    },
    templateUrl: '/form_error.html',
    link: function(scope, element, attrs, formCtrl) {
      scope.form = formCtrl
      if (!attrs.name) {
        attrs.name = "This field"
      }
    }
  }
}
