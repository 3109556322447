'use strict'

module.exports = function(auth, waffle, testTypes, promoCodeStore) {
  'ngInject'

  this.eligibleForReuse = function(skuName) {
    return auth.currentUser.skus_qualified_for_data_reuse.indexOf(skuName) > -1
  }

  this.getResultsExpectedDate = function(purchasedAt) {
    // We expect results within a week of purchase
    const purchasedDate = new Date(purchasedAt)
    return purchasedDate.setDate(purchasedDate.getDate() + 7)
  }
}
