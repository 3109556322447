'use strict'

module.exports = function($location, $window, Stripe, handleIntlShipping) {
  'ngInject'

  const stripeButton = 'stripe-button'

  return {
    restrict: 'E',
    scope: {
      checkout: '=',
      text: '@'
    },
    templateUrl: '/kit_orders/directives/stripe_checkout_button.html',
    link: function(scope, elem) {
      const stripeHandler = $window.StripeCheckout.configure({
        key: Stripe.key,
        locale: 'auto',
        shippingAddress: true,
        billingAddress: true,
        token: function(token_response, address_args) {
          scope.checkout.placePurchaseWithStripeCheckout(token_response, address_args).then(function(res) {
            $location.path('/purchase/' + res.purchase_number + '/confirmation')
          }).catch(function(res) {
            handleIntlShipping(res, 'StripeCheckout')
          })
        }
      })

      scope.startStripe = function() {
        stripeHandler.open({
          name: "Color",
          description: "BRCA Test",
          image: "https://prod-static.color.com/images/site/box_sleeve_small.png",
          zipCode: false,
          amount: scope.checkout.getTotalPriceDollars() * 100 // stripe takes cents
        });
      }
    }
  }
}
