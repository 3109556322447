'use strict'

module.exports = function(auth, testTypes, waffle) {
  'ngInject'

  this.alreadyHavePhysicianOrder = function(skuName) {
    // nosemgrep:semgrep-rules.use-launchdarkly-react
    if (!waffle.switch_is_active('reuse_physician_order') || !auth.isLoggedIn || !auth.currentUser.id) {
      return false
    }
    return auth.currentUser.test_types_covered_with_prev_orders.indexOf(skuName) > -1
  }
}
