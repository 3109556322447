/**
 * @prettier
 */

module.exports = function reportGeneralQuestions($filter, testTypes) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_general_questions.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      scope.isLikelyPathogenic = $filter('isLikelyPathogenic')(scope.reportContent.mutations);
      scope.hasLikelyPathogenic = $filter('hasLikelyPathogenic')(scope.reportContent.mutations);
      scope.reportIsSingleHomozygous = $filter('isSingleHomozygousReport')(
        scope.reportContent.mutations
      );
      scope.reportIsPotentiallyCompoundHeterozygous = $filter(
        'isPotentiallyCompoundHeterozygousReport'
      )(scope.reportContent);

      // Simplified logic to replace first question with no effect text if H30 + M + BARD1/BRIP1/RAD51C/RAD51D
      const noEffectMaleGenes = ['BARD1', 'BRIP1', 'RAD51C', 'RAD51D'];
      scope.reportIsSingleGeneNoEffectPathogenic = false;
      if (
        scope.reportContent.template.test_type == testTypes.hereditary30 &&
        scope.reportContent.gender == 'M' &&
        scope.reportContent.genes_with_pathogenic_mutations.length == 1 &&
        noEffectMaleGenes.indexOf(scope.reportContent.genes_with_pathogenic_mutations[0]) > -1
      ) {
        scope.reportIsSingleGeneNoEffectPathogenic = true;
      }
    },
  };
};
