module.exports = angular.module('accountServices', ['ui.bootstrap', 'ngToast'])
  .factory('VerifyIdentityModal', function($modal, ngToast, IdentityVerification) {
    const VerifyIdentityModalController = function($scope, $modalInstance) {

      $scope.identityVerification = new IdentityVerification()
      $scope.verifyingIdentity = false

      $scope.verifyPassword = function(password) {
        $scope.verifyingIdentity = true
        $scope.identityVerification.password = password.password
        $scope.identityVerification.verification_password = password.password
        $scope.identityVerification.$save_with_ignore_auth_module()
          .then(function(res) {
            $modalInstance.close(res)
          })
          .catch(function(res) {
            ngToast.create({ className: "error", content: "Wrong password." })
          })
          .finally(function() {
            $scope.verifyingIdentity = false
          })
      }

      $scope.dismiss = function() {
        $modalInstance.dismiss()
      }
    }

    return {
      open: function(scope) {
        return $modal.open({
          templateUrl: '/accounts/verify_identity_modal.html',
          scope: scope,
          controller: VerifyIdentityModalController,
          size: 'sm',
          resolve: {
            instructions: function() {
              return scope.instructions
            }
          }
        })
      }
    }
  })
