'use strict'

module.exports = function(genderIdentities) {
  'ngInject'

  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '=',
    },
    templateUrl: '/users/gender_input.html',
    link: function(scope, elem, attr, controller) {
      scope.genderIdentities = genderIdentities
    }
  }
}
