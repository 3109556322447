'use strict'

module.exports = function healthHistoryDiagnosisForm(
  criteriaRelationshipTypes, cancerTypes, sideOfFamilyTypes
) {
  'ngInject'

  return {
    restrict: 'E',
    templateUrl: '/ordering_physicians/directives/health_history_diagnosis_form.html',
    scope: {
      canRemove: '=?',
      diagnosis: '=',
      icdCodesRequired: '=',
      isPatient: '=',
      removeDiagnosis: '=?'
    },
    link: function(scope) {

      // Transform queryable maps into arrays for ``ng-repeat``

      scope.criteriaRelationshipTypes = Object.keys(criteriaRelationshipTypes.ALL).map(function(key) {
        return criteriaRelationshipTypes.ALL[key]
      })

      scope.cancerTypes = Object.keys(cancerTypes).map(function(key) {
        return cancerTypes[key]
      })
      scope.sideOfFamilyTypes = Object.keys(sideOfFamilyTypes).map(function(key) {
        return sideOfFamilyTypes[key]
      })

      // Transform ``cancerTypes`` into format Object<string, string> for easy querying

      scope.CancerType = Object.keys(cancerTypes).reduce(function(acc, key) {
        acc[key] = cancerTypes[key][1]
        return acc
      }, {})

      scope.onRemove = function() {
        // TODO: Add confirmation logic here.
        scope.removeDiagnosis(scope.diagnosis)
      }
    }
  }
}
