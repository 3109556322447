module.exports = function($scope, $location, testTypes, waffle) {
  'ngInject'

  $scope.testTypes = testTypes
  // We want core 10 sku to appear as one test in informed consent
  // even though the sku is a combo sku
  $scope.core10Sku = 'core 10'

  const testTypeNames = $location.search().test_types
  if (testTypeNames) {
    $scope.testTypeNames = testTypeNames.split(',')
    if (testTypeNames.indexOf(testTypes.hereditary7) > -1 && testTypeNames.indexOf(testTypes.fh3) > -1) {
      $scope.testTypeNames = [$scope.core10Sku]
    }
  } else {
    // We have a link to this page from our website footer which does not have
    // context on which test types. In this case, we show all available ICs.
    $scope.testTypeNames = [
      testTypes.brca12,
      testTypes.hereditary30,
      testTypes.breastOvarian19,
      testTypes.fh3,
      testTypes.cardio30,
      $scope.core10Sku,
      testTypes.pgxv1
    ]
  }
}
