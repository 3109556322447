'use strict'

module.exports = function($filter, phoneCountries, E164Regex) {
  "ngInject"

  return {
    restrict: 'EA',
    require: '^form',
    scope: {
      phoneCountry: '=',
      phoneNumber: '=',
      addressCountry: '=?',
      phoneExtension: '=?',
      phoneFieldName: '@?',
      phoneFieldLabel: '@?',
      displayTooltip: '=?',
      required: '=?',
      limitCountry: '@?',
      flexStyle: '=?'
    },
    templateUrl: '/users/phone_input.html',
    link: function(scope, element, attrs, formCtrl) {
      scope.phoneCountries = phoneCountries
      scope.form = formCtrl

      if (!attrs.phoneFieldName) {
        scope.phoneFieldName = "phone"
      }

      if (!attrs.phoneFieldLabel) {
        scope.phoneFieldLabel = "Phone number"
      }

      if (!attrs.displayTooltip) {
        scope.displayTooltip = true
      }

      if (!attrs.required) {
        scope.required = true
      }

      // Toggle for new flex form styling, make this default once we switch over all form design
      if (angular.isUndefined(scope.flexStyle)) {
        scope.flexStyle = false
      }

      // Do not show country option and set it to the country value
      if (scope.limitCountry) {
        scope.addressCountry = scope.limitCountry
      }

      scope.hasExtension = !!attrs.phoneExtension

      // If phone number provided an is e164 format, set the input accordingly
      const e164Match = E164Regex.exec(scope.phoneNumber)
      if (e164Match) {
        const country = e164Match[1]
        const number = e164Match[2]
        const extension = e164Match[3]

        scope.phoneNumber = number
        scope.phoneExtension = extension
        if (!scope.phoneCountry && country) {
          scope.phoneCountry = $filter('phonePrefixToCountryCode')(country)
        }
      }

      // If the user hasn't entered a phone number, change the phone country
      // with the address country.
      scope.$watch('addressCountry', function(newValue) {
        if (!formCtrl[scope.phoneFieldName].$viewValue
            && !scope.phoneNumber
            && phoneCountries.indexOf(newValue) > -1) {
          scope.phoneCountry = newValue
        }
      })

      // If the user changes the phone country, empty the phone number and
      // extension.
      scope.$watch('phoneCountry', function(newValue, oldValue) {
        if (newValue != oldValue) {
          scope.phoneNumber = ''
          if (scope.hasExtension) {
            scope.phoneExtension = ''
          }
        }
      })
    }
  }
}
