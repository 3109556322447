"use strict"

/*
* Email domain spellcheck directive.
*/
module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    require: '^form',
    link: function(scope, elem, attrs, formCtrl) {
      scope.form = formCtrl
      const emailSuggestion = angular.element('<span class="form-hint" ng-show="emailSpelling"></span>')
      const emailBlurhandler = function() {
        Mailcheck.run({
          email: formCtrl.email.$viewValue,
          suggested: function(suggestion) {
            const suggestedEmailElement = angular.element('<a>' + '@' + suggestion.domain + '</a>')
            const suggestionClickhandler = function() {
              formCtrl.email.$setViewValue(suggestion.full)
              formCtrl.email.$render()
              emailSuggestion.empty()
              suggestedEmailElement.unbind('click', suggestionClickhandler)
            }
            suggestedEmailElement.bind('click', suggestionClickhandler)
            emailSuggestion.append("Did you mean ", suggestedEmailElement, "?")
          },
          empty: function() {
            emailSuggestion.empty()
          }
        })
      }
      const emailFocushandler = function() {
        emailSuggestion.empty()
      }
      elem.bind('blur', emailBlurhandler)
      elem.bind('focus', emailFocushandler)
      elem.after(emailSuggestion)
      scope.$on('$destroy', function() {
        elem.unbind('blur', emailBlurhandler)
        elem.unbind('focus', emailFocushandler)
      })
    }

  }
}
