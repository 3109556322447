"use strict"

/**
 * File uploader.
 *
 * The uploader scope var must be a FileUploader.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'E',
    require: '?^form',
    templateUrl: '/directives/uploader.html',
    scope: {
      uploader: '=',
      hideFiles: '=',
      label: '@',
      minFiles: '@'
    },
    link: function(scope, element, attrs, formController) {
      function validate() {
        if (formController && scope.minFiles) {
          const isValid = scope.uploader.queue.length >= scope.minFiles
          formController.$setValidity('minFiles', isValid)
        }
      }

      scope.removeFile = function(file) {
        file.cancel()
        file.remove()
        if (scope.uploader.onRemoveFile) {
          scope.uploader.onRemoveFile(file)
        }
        validate()
      }

      const originalOnSuccessItem = scope.uploader.onSuccessItem
      scope.uploader.onSuccessItem = function(item, response, status, headers) {
        originalOnSuccessItem(item, response, status, headers)
        validate()
      }

      // Remove the file if it fails to upload - otherwise users may not notice that their upload failed
      const originalOnErrorItem = scope.uploader.onErrorItem
      scope.uploader.onErrorItem = function(item, response, status, headers) {
        originalOnErrorItem(item, response, status, headers)
        scope.removeFile(item)
        validate()
      }

      validate()
    }
  }
}
