'use strict'

module.exports = angular.module('filesServices', ['clrConstant'])
  // Returns a map from upload file types to their human readable labels
  .service('uploadFileTypeLabels', function(uploadFileTypes) {
    const fileTypeLabels = {}
    fileTypeLabels[uploadFileTypes.geneticCounselingNote] = 'Genetic counseling summary'
    fileTypeLabels[uploadFileTypes.geneticTestReport] = 'Genetic test report'
    fileTypeLabels[uploadFileTypes.insuranceCard] = 'Insurance card'
    fileTypeLabels[uploadFileTypes.medicalRecords] = 'Medical records'
    fileTypeLabels[uploadFileTypes.orderFormOrInformedConsent] = 'Order form / Informed consent'
    fileTypeLabels[uploadFileTypes.otherPhysicianOrderFile] = 'Other'
    fileTypeLabels[uploadFileTypes.physicianOrderFiles] = ''
    return fileTypeLabels
  })
