/**
 * @prettier
 */

module.exports = function reportAboutTheGene($filter, $translate, testTypes, namedMutation) {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_about_the_gene.html',
    scope: {
      reportContent: '=',
    },
    link: function(scope) {
      scope.testTypes = testTypes;
      function aboutString(gene, paragraph) {
        // Conditionally apply `_SOMATIC` prefix since that content differs
        const isSomatic = scope.reportContent.is_somatic ? '_SOMATIC' : '';
        // render_version changes content, currently only 2 variations
        // 1: Initial launch version
        // < 7: Updated content, for BO19 and H30 reports
        // >= 7: Updated content, for ATM prostate cancer
        let contentVersion;
        if (scope.reportContent.render_version == 1) {
          contentVersion = 'V1.';
        } else if (scope.reportContent.render_version < 7) {
          contentVersion = 'V2.';
        } else {
          contentVersion = 'V3.';
        }
        return (
          'RESULT.ABOUT_THE_GENE.' +
          contentVersion +
          gene.replace(/\s/g, '_') +
          isSomatic +
          '_MD_' +
          paragraph
        );
      }
      if (scope.reportContent.genes_with_pathogenic_mutations.length) {
        scope.descriptions = [];
        angular.forEach($filter('genesWithMutationName')(scope.reportContent.mutations), function(
          gene
        ) {
          // For BRCA1 R1699Q mutation, we would like to show normal gene information.
          // We might be able to generalize this to say any spacial variants with lower risks
          // than normal.
          let mutationName = gene.mutationName;
          if (gene.gHGVS == namedMutation.brca1_r1699q) {
            mutationName = '';
          }
          const geneStringName = mutationName ? gene.name + '_' + gene.mutationName : gene.name;
          $translate([aboutString(geneStringName, 1), aboutString(geneStringName, 2)]).then(
            function(translations) {
              const description = {
                gene: gene.name,
                mutationName: mutationName,
                msg1: translations[aboutString(geneStringName, 1)],
                msg2: translations[aboutString(geneStringName, 2)],
              };
              scope.descriptions.push(description);
            }
          );
        });
      }
    },
  };
};
