function EmailAuthenticationController($scope, $http, $modalInstance, requestErrorToast, email, discountType, receivedPromoCode) {
  'ngInject'
  $scope.values = {}
  $scope.email = email
  $scope.discountType = discountType

  $scope.submitCode = () => {
    $scope.submitting = true
    receivedPromoCode($scope.values.code)
      .then(response => {
        $modalInstance.close()
      })
      .catch(response => {
        requestErrorToast(response)
      })
      .finally(() => {
        $scope.submitting = false
      })
  }

  $scope.resendEmail = () => {
    $http.post('api/v1/claim_benefits', { identifier: email }, { skipErrorToast: true })
      .then(response => {
        $scope.resentEmail = true
      })
      .catch(response => {
        requestErrorToast(response)
      })
  }
}

module.exports = ($modal) => {
  'ngInject'

  return {
    open: (email, discountType) => {
      return $modal.open({
        templateUrl: '/benefits/modals/email_authentication.html',
        controller: EmailAuthenticationController,
        size: 'md',
        resolve: {
          email: () => email,
          discountType: () => discountType
        }
      })
    }
  }
}
