"use strict"

/**
 * Validates the input value is equal to a given model.
 */
module.exports = function() {
  "ngInject"

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      confirmationFor: '='
    },
    link: function(scope, elem, attrs, ngModel) {
      // DOM to model validation
      ngModel.$parsers.unshift(function(value) {
        ngModel.$setValidity('confirmationFor', value == scope.confirmationFor)
        return value
      })
      // model to DOM validation
      ngModel.$formatters.unshift(function(value) {
        ngModel.$setValidity('confirmationFor', value == scope.confirmationFor)
        return value
      })
    }
  }
}
