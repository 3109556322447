/**
 * @prettier
 */

module.exports = function reportTags() {
  'ngInject';

  return {
    restrict: 'E',
    templateUrl: '/results/directives/report_tags.html',
    scope: {
      report: '=',
    },
    link: function(scope) {
      const tags = [];
      if (scope.report.on_hold) {
        tags.push({ label: 'on hold', cssClass: 'tag-warning' });
      }
      if (scope.report.revision) {
        tags.push({ label: 'rerelease', cssClass: 'tag-rerelease' });
      }
      if (scope.report.sample.for_ny) {
        tags.push({ label: 'NY', cssClass: 'tag-ny' });
      }
      if (scope.report.is_ftp) {
        tags.push({ label: 'FTP', cssClass: 'tag-primary' });
      }
      if (scope.report.reviewed_by_vcc) {
        tags.push({ label: 'VCC', cssClass: 'tag-primary' });
      }
      if (scope.report.partner_name) {
        tags.push({ label: scope.report.partner_name, cssClass: 'tag-primary' });
      }
      if (scope.report.user.is_minor) {
        tags.push({ label: 'minor', cssClass: 'tag-primary' });
      }
      scope.tags = tags;
      scope.report.reportTags =
        tags.map(function(t) {
          return t.label;
        }) + [''];
    },
  };
};
