'use strict'

module.exports = () => {
  "ngInject"

  return {
    require: 'ngModel',
    link: function(scope, elem, attr, ctrl) {
      ctrl.$validators.injection = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true
        }
        const blacklist = ['<', '>']
        for (let i = 0; i < blacklist.length; i++) {
          if (viewValue.indexOf(blacklist[i]) != -1) {
            return false
          }
        }
        return true
      }
    }
  };
}
