const resolvers = require('monoweb/django_apps/core/static/js/resolvers')

module.exports = angular.module('results', [
  require('monoweb/django_apps/results/static/js/results/controllers').name,
  require('monoweb/django_apps/results/static/js/results/directive').name,
  require('monoweb/django_apps/results/static/js/results/filter').name,
  require('monoweb/django_apps/results/static/js/results/modal').name,
]).config(function(colorRouteProvider) {
  const noLoginOrConfirmation = {
    requireLogin: false,
    requireEmailConfirmation: false
  }

  colorRouteProvider
    .when('/results', {
      templateUrl: '/results/pre-results/index.html',
      controller: 'PreResultsController',
      seo: {
        title: 'Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.kitStatus,
    })

    // Redirect logic for backward compatibility.
    .when('/results/reminder', { redirectTo: '/results/breast-ovarian-19/reminder' })
    .when('/results/report', { redirectTo: '/results/breast-ovarian-19/report' })
    .when('/results/report/next-steps', { redirectTo: '/results/breast-ovarian-19/report/next-steps' })
    .when('/results/report/details', { redirectTo: '/results/breast-ovarian-19/details' })
    .when('/results/report/health-history', { redirectTo: '/results/breast-ovarian-19/health-history' })
    .when('/results/report/family', { redirectTo: '/results/breast-ovarian-19/family' })
    .when('/results/report/questions', { redirectTo: '/results/breast-ovarian-19/questions' })

    .when('/results/:testType', {
      templateUrl: '/results/pre-results/index.html',
      controller: 'PreResultsController',
      seo: {
        title: 'Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.kitStatus,
    })
    .when('/results/:testType/reminder', {
      templateUrl: '/results/pre-results/results_ready.html',
      controller: 'PreResultsController',
      seo: {
        title: 'Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.kitStatus,
    })

    // all the report URLs
    // goToReport in pre results controller
    // .when('/results/:testType/report'
    // .when('/results/:testType/report/next-steps'
    // .when('/results/:testType/report/details'
    // .when('/results/:testType/report/health-history'
    // .when('/results/:testType/report/family'
    // .when('/results/:testType/report/questions'
    .when('/results/:testType/report', {
      redirectTo: function(routeParams, path, search) {
        const testTypeIsPgx = routeParams.testType === 'pgx' || routeParams.testType === 'pgx-v1'
        const resultIsCmsBased = search.cms_based || 'false'

        if (testTypeIsPgx && resultIsCmsBased.toLowerCase() !== 'true') {
          /**
           * If the result is a PGx test AND
           * the PGx result was NOT generated using the CMS-based system
           * (we determine this by the presence of the 'cms_based' search param, which is
           * applied in the PreResultsPage.tsx),
           *
           * we should render the legacy React based PGx web report in
           * home/frontend/src/ui/App/ColorPortal.
           *
           * Otherwise, if the PGx report is CMS-based, we should redirect the user to
           * the Angular-based reports wrapper at /report/next-steps
           * which will render the user's CMS-based PGx report.
           *
           * This will allow us to gradually migrate users to the new CMS-based PGx reports.
           */
          return '/react/legacy/results/' + routeParams.testType
        }

        return '/results/' + routeParams.testType + '/report/next-steps'
      }
    })
    .when('/results/:testType/report/next-steps', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Next Steps - Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.results,
    })
    .when('/results/:testType/report/details', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Details - Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.results,
    })
    .when('/results/:testType/report/health-history', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Health History - Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.results,
    })
    .when('/results/:testType/report/family', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Family - Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.results,
    })
    .when('/results/:testType/report/questions', {
      templateUrl: '/results/web_report.html',
      controller: 'ReportWrapperController',
      seo: {
        title: 'Questions - Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
      resolve: resolvers.results,
    })

    .when('/appointments/:order_number', {
      controller: 'AppointmentController',
      seo: {
        title: 'Your Results - Color',
        description: ''
      },
      navigation: { blue: true, active: 'results' },
    })

})
