'use strict'

// to be used with /results/directives/report_share_family.html
module.exports = function($filter, $translate, testTypes) {
  'ngInject'

  return function(reportContent, output) {
    output.testTypes = testTypes
    // VUSes don't factor into any of the logic in this component
    const pathogenicMutations = $filter('pathogenicMutations')(reportContent.mutations)
    output.pathogenicMutations = pathogenicMutations

    // Logic to figure out which content list to show
    if (reportContent.is_positive) {
      output.recommendChildrenTested = pathogenicMutations.some(function(mutation) {
        if (['SMAD4', 'STK11', 'TP53', 'PTEN', 'APC', 'BMPR1A'].indexOf(mutation.gene) > -1) {
          const mutationNames = $filter('genesWithMutationName')([mutation])
          const mutationName = mutationNames.length ? mutationNames[0].mutationName : ''
          if (reportContent.render_version < 9 || mutation.gene != 'APC' || mutationName != 'I1307K') {
            return true
          }
        }
        return false
      })

      if (reportContent.is_somatic) {
        output.showSomatic = true
      } else if (reportContent.genes_with_pathogenic_mutations.length == 1) {
        if ($filter('isBiallelic')(reportContent)) {
          output.showBiallelic = true
        } else if ($filter('isPotentiallyCompoundHeterozygousReport')(reportContent)) {
          output.showCompoundHeterozygous = true
          output.recommendChildrenTested = reportContent.allelicity_by_gene['BRCA2'] === null
        } else if ($filter('isInCis')(reportContent)) {
          output.showInCis = true
        } else {
          output.showSinglePositive = true
        }
      } else if (reportContent.genes_with_pathogenic_mutations.length == 2) {
        const homMutations = pathogenicMutations.filter(function(m) {
          return $filter('isHomozygous')(m)
        })
        const hetMutations = pathogenicMutations.filter(function(m) {
          return !$filter('isHomozygous')(m)
        })
        if (hetMutations.length && homMutations.length) {
          output.showHomozygousHeterozygous = true
          output.hetGene = hetMutations[0].gene
          output.homGene = homMutations[0].gene
        } else {
          output.showMultiplePositives = true
        }
      } else {
        output.showMultiplePositives = true
      }
    } else {
      if (reportContent.gender == 'F') {
        output.showNegativeFemale = true
      } else {
        output.showNegativeMale = true
      }
    }

    // TODO: Use $translate, temp workaround to avoid having to deal with $translate promises
    const MALE_LETTER_TRUE = "Men with this mutation are at an increased risk of developing "
    const MALE_LETTER_FALSE = "Men with this mutation are not known to be at an increased risk for developing cancer"

    function createGeneReport(report, mutation) {
      const geneReport = {}
      const mutationNames = $filter('genesWithMutationName')([mutation])
      geneReport.gene = mutation.gene
      geneReport.mutationName = mutationNames.length ? mutationNames[0].mutationName : ''
      geneReport.mutation = mutation
      geneReport.isLikelyPathogenic = $filter('isLikelyPathogenic')(mutation)
      geneReport.pathogenicNomenclature = $filter('nomenclature')(mutation, 'primary').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      // Include both monoallelic and biallelic risks if not monoallelic.
      if (report.allelicity_by_gene[geneReport.gene] == 'monoallelic') {
        geneReport.femaleRisks = $filter('sortedRisks')(report, 'F', true, null, mutation.gene, '1')
        geneReport.maleRisks = $filter('sortedRisks')(report, 'M', true, null, mutation.gene, '1')
      } else {
        geneReport.femaleRisks = $filter('sortedRisks')(report, 'F', true, null, mutation.gene)
        geneReport.maleRisks = $filter('sortedRisks')(report, 'M', true, null, mutation.gene)
      }

      // Set female cancers string
      if (mutation.gene == 'TP53') {
        geneReport.femaleCancersString = 'breast, ovarian, brain, sarcoma and other cancers'
      } else if (mutation.gene == 'MSH6') {
        geneReport.femaleCancersString = 'ovarian, colorectal, uterine and other cancers'
      } else if (mutation.gene == 'BRIP1') {
        geneReport.femaleCancersString = 'breast and ovarian cancer'
      } else {
        geneReport.femaleCancersString = $filter('cancerString')(geneReport.femaleRisks.pathogenic, '1')
      }

      // Set male cancers string
      if (geneReport.maleRisks.pathogenic.length) {
        geneReport.maleCancersString = ''
        if (!$filter('isPotentiallyCompoundHeterozygousReport')(reportContent)) {
          if (reportContent.genes_with_pathogenic_mutations.length == 1) {
            geneReport.maleCancersString = MALE_LETTER_TRUE
          } else {
            geneReport.maleCancersString = 'Men with <em>' + geneReport.gene + '</em> mutations are at an increased risk of developing '
          }
        }
        if (mutation.gene == 'TP53') {
          geneReport.maleCancersString += 'brain, sarcoma and other cancers'
        } else if (mutation.gene == 'MSH6') {
          geneReport.maleCancersString += 'colorectal and other cancers'
        } else {
          geneReport.maleCancersString += $filter('cancerString')(geneReport.maleRisks.pathogenic, '1')
        }
      } else {
        if (reportContent.genes_with_pathogenic_mutations.length == 1) {
          geneReport.maleCancersString = MALE_LETTER_FALSE
        } else {
          geneReport.maleCancersString = 'Men with <em>' + geneReport.gene + '</em> mutations are not known to be at an increased risk for developing cancer'
        }
      }
      return geneReport
    } // End of createGeneReport

    const geneReports = []
    if (reportContent.is_positive) {
      angular.forEach(pathogenicMutations, function(mutation) {
        geneReports.push(createGeneReport(reportContent, mutation))
      })
    }
    // TODO: Refactor how this letter is generated so it's less cumbersome and more programmatic
    // https://getcolor.atlassian.net/browse/ENG-25
    const sampleLetterTestType = reportContent.template.test_type == testTypes.breastOvarian19 ? '19 genes linked to breast and ovarian cancers' : '30 genes linked to the most common hereditary cancers'
    if (reportContent.is_negative && reportContent.gender == 'F') {
      // No wisdom9 because this directive isn't used there
      if (reportContent.template.test_type == testTypes.breastOvarian19) {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.F_BO19_MD').then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      } else if (reportContent.template.test_type == testTypes.hereditary30) {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.NEG_H30_MD').then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      }
    } else if (reportContent.is_negative && reportContent.gender == 'M') {
      if (reportContent.template.test_type == testTypes.breastOvarian19) {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.M_BO19_MD').then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      } else if (reportContent.template.test_type == testTypes.hereditary30) {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.NEG_H30_MD').then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      }
    } else if ($filter('isSingleHomozygousReport')(pathogenicMutations)) {
      // Single Homozygous letter, no condition letter: https://docs.google.com/document/d/1tJ-WbyEgc3HRXssPbCtZWfgRpvGBn3q6DwxN0egQnZk/edit
      // TODO: "with a condition" letter not implemented
      let singleMutationName = 'a'
      const phenotypeBiallelicCancerString = $filter('cancerString')(geneReports[0].femaleRisks.pathogenic, '2')
      let phenotypeBiallelicRisks
      if (phenotypeBiallelicCancerString) {
        // specific biallelic risks exist
        phenotypeBiallelicRisks = 'an increased risk to develop ' + phenotypeBiallelicCancerString
      } else if (geneReports[0].gene == 'CHEK2') {
        // no specific biallelic risks for CHEK2 exist yet
        phenotypeBiallelicRisks = 'a significantly increased risk to develop breast cancer and possibly other cancers'
      } else if (geneReports[0].gene == 'APC' && geneReports[0].mutationName == 'I1307K') {
        // no specific biallelic risks for APC I1307K exist yet
        // APC I1307K Homozygous
        phenotypeBiallelicRisks = 'an increased risk to develop colorectal cancer'
        singleMutationName = 'an ' + geneReports[0].mutationName
        geneReports[0].isSlightly = 'a slightly '
      } else if (geneReports[0].gene == 'MITF' || geneReports[0].gene == 'MSH6') {
        // no specific biallelic risks for MITF exist yet
        phenotypeBiallelicRisks = 'an increased risk to develop kidney cancer and melanoma'
      } else {
        throw new Error(geneReports[0].gene + " does not have specific biallelic risks and there're not overwrites provided.")
      }

      $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.HOMOZYGOUS_MD',
        {
          gene: geneReports[0].gene,
          testType: sampleLetterTestType,
          isSlightly: geneReports[0].isSlightly ? geneReports[0].isSlightly : '',
          singleMutationName: singleMutationName,
          phenotypeBiallelicRisks: phenotypeBiallelicRisks,
          pathogenicNomenclature: geneReports[0].pathogenicNomenclature,
          classification: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : '',
          femaleCancersString: geneReports[0].femaleCancersString,
          // Homozygous childhood sentence logic that does not apply to the only homozygous report we have currently: CHEK2, leaving here for later implementation when a homozygous gene does appear
          // https://docs.google.com/document/d/1tJ-WbyEgc3HRXssPbCtZWfgRpvGBn3q6DwxN0egQnZk/edit
          addChildhoodTestingSentence: ''
        }).then(function(translation) {
        output.msgMarkdownSampleLetter = translation
      })
    } else if ($filter('isPotentiallyCompoundHeterozygousReport')(reportContent) && reportContent.genes_with_pathogenic_mutations.length == 1) {
      // Compound heterozygous logic, no condition letter: https://docs.google.com/document/d/1VYcdQKwYCoszgll86qK1kHwF_eMfCPSXwCtjdmLwNc8/edit
      // TODO: "with a condition" letter not implemented

      if (geneReports[0].gene == 'BRCA2') {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.UNKNOWN_PHASE_CONDITION_MD',
          {
            gene: geneReports[0].gene,
            pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
            pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
            classification1: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic',
            classification2: geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic',
            geneCondition: 'Fanconi anemia',
            conditionPhenotypeRisks: 'bone marrow failure, physical disabilities, and childhood cancers',
            monoallelicPhenotypeRisksF: geneReports[0].femaleCancersString,
            monoallelicPhenotypeRisksM: geneReports[0].maleCancersString
          }).then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      } else if (geneReports[0].gene == 'CHEK2') {
        const noConditionPhenotypeRisks = 'a significantly increased risk to develop breast cancer and possibly other cancers'
        let translationKey = 'RESULT.SHARE_FAMILY.SAMPLE_LETTER.UNKNOWN_PHASE_NO_CONDITION_MD'
        if (reportContent.allelicity_by_gene['CHEK2'] == 'biallelic') {
          translationKey = 'RESULT.SHARE_FAMILY.SAMPLE_LETTER.COMPOUND_HETEROZYGOUS_NO_CONDITION_MD'
        }
        $translate(translationKey,
          {
            testType: sampleLetterTestType,
            gene: geneReports[0].gene,
            noConditionPhenotypeRisks: noConditionPhenotypeRisks,
            pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
            pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
            classification: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : '',
            femaleCancersString: geneReports[0].femaleCancersString,
            maleCancersString: geneReports[0].maleCancersString,
            // Compound heterozygous childhood sentence logic that does not apply to the only compound het report we have currently: CHEK2, leaving here for later implementation when it does apply
            // https://docs.google.com/document/d/1VYcdQKwYCoszgll86qK1kHwF_eMfCPSXwCtjdmLwNc8/edit
            addChildhoodTestingSentence: ''
          }).then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      } else if (geneReports[0].gene == 'MUTYH') {
        $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.UNKNOWN_PHASE_CONDITION_MD',
          {
            gene: geneReports[0].gene,
            pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
            pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
            classification1: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic',
            classification2: geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic',
            femaleCancersString: geneReports[0].femaleCancersString,
            maleCancersString: $filter('cancerString')(geneReports[0].maleRisks.pathogenic),
            geneCondition: 'MUTYH-Associated Polyposis (MAP)',
            conditionPhenotypeRisks: 'a significantly increased risk of colorectal and other cancers',
            monoallelicPhenotypeRisksF: 'colorectal cancer',
            monoallelicPhenotypeRisksM: 'colorectal cancer'
          }).then(function(translation) {
          output.msgMarkdownSampleLetter = translation
        })
      } else {
        throw new Error("Only BRCA2, CHEK2 and MUTYH are implemented for potentially compount het reports.")
      }
    } else if (reportContent.is_positive && geneReports.length == 1) {

      // Add childhood sentences for genes where children are impacted: https://docs.google.com/document/d/1e7ByoV4HprrMyFd1WV8jkJoWQIgnXtAXrW_W575KGPU/edit
      let addChildhoodTestingSentence = ''
      // MUTYH homozygous needs to be included
      const childhoodTestingGenes = ['APC', 'STK11', 'TP53', 'SMAD4', 'PTEN', 'BMPR1A']
      if ($filter('containsAny')([geneReports[0].gene], childhoodTestingGenes) && geneReports[0].mutationName != 'I1307K') {
        addChildhoodTestingSentence = ' Testing children for this mutation is recommended, as it impacts health and medical management in childhood. '
      }

      $translate(['RESULT.SHARE_FAMILY.SAMPLE_LETTER.1_POS_BO19_MD', 'RESULT.SHARE_FAMILY.SAMPLE_LETTER.1_POS_H30_MD'],
        {
          gene: geneReports[0].gene,
          pathogenicNomenclature: geneReports[0].pathogenicNomenclature,
          classification: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : '',
          addChildhoodTestingSentence: addChildhoodTestingSentence,
          femaleCancersString: geneReports[0].femaleCancersString,
          maleCancersString: geneReports[0].maleCancersString
        }).then(function(translations) {
        if (reportContent.template.test_type == testTypes.breastOvarian19) {
          output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.1_POS_BO19_MD']
        } else if (reportContent.template.test_type == testTypes.hereditary30) {
          output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.1_POS_H30_MD']
        }
      })
    } else if (reportContent.is_positive && geneReports.length == 2 && !$filter('isPotentiallyCompoundHeterozygousReport')(reportContent)) {
      if (output.showHomozygousHeterozygous) {
        const hetGeneReport = geneReports[0].gene == output.hetGene ? geneReports[0] : geneReports[1]
        const homGeneReport = geneReports[0].gene == output.homGene ? geneReports[0] : geneReports[1]
        $translate([
          'RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_HET_HOM_MD'
        ],
        {
          testType: sampleLetterTestType,
          hetGene: output.hetGene,
          homGene: output.homGene,
          hetClassification: hetGeneReport.isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          homClassification: homGeneReport.isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          hetNomenclature: hetGeneReport.pathogenicNomenclature,
          homNomenclature: homGeneReport.pathogenicNomenclature,
          hetFemaleCancersString: hetGeneReport.femaleCancersString,
          homFemaleCancersString: homGeneReport.femaleCancersString,
          hetMaleCancersString: hetGeneReport.maleCancersString
        }).then(function(translations) {
          output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_HET_HOM_MD']
        })
      } else if (geneReports[0].gene == geneReports[1].gene) { // in cis
        $translate([
          'RESULT.SHARE_FAMILY.SAMPLE_LETTER.IN_CIS_NO_CONDITION_MD',
          'RESULT.SHARE_FAMILY.SAMPLE_LETTER.IN_CIS_NO_CONDITION_MIXED_MD',
        ],
        {
          testType: sampleLetterTestType,
          gene: geneReports[0].gene,
          classification: geneReports[0].isLikelyPathogenic && geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : '',
          classification1: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          classification2: geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
          pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
          femaleCancersString: geneReports[0].femaleCancersString,
          maleCancersString: geneReports[0].maleCancersString,
        }).then(function(translations) {
          if (geneReports[0].isLikelyPathogenic != geneReports[1].isLikelyPathogenic) {
            output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.IN_CIS_NO_CONDITION_MIXED_MD']
          } else {
            output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.IN_CIS_NO_CONDITION_MD']
          }
        })
      } else {
        $translate([
          'RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_MD',
          'RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_MIXED_MD'
        ],
        {
          testType: sampleLetterTestType,
          gene1: geneReports[0].gene,
          gene2: geneReports[1].gene,
          classification: geneReports[0].isLikelyPathogenic && geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : '',
          classification1: geneReports[0].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          classification2: geneReports[1].isLikelyPathogenic ? 'likely pathogenic ' : 'pathogenic ',
          pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
          pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
          femaleCancers1String: geneReports[0].femaleCancersString,
          femaleCancers2String: geneReports[1].femaleCancersString,
          maleCancers1String: geneReports[0].maleCancersString,
          maleCancers2String: geneReports[1].maleCancersString
        }).then(function(translations) {
          if ($filter('hasLikelyPathogenic')(pathogenicMutations)) {
            output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_MIXED_MD']
          } else {
            output.msgMarkdownSampleLetter = translations['RESULT.SHARE_FAMILY.SAMPLE_LETTER.2_POS_MD']
          }
        })
      }
    } else if (reportContent.is_positive && (geneReports.length == 3 || (geneReports.length == 2 && $filter('isPotentiallyCompoundHeterozygousReport')(reportContent)))) {
      // Doesn't handle mixed P/LP right now

      // Construct the cancer risk string starting with female first.
      // TODO Refactor: https://getcolor.atlassian.net/browse/ENG-25
      let bothGenderCancersString = ""
      angular.forEach(geneReports, function(geneReport) {
        if ($filter('allRisksNotElevated')(geneReport.femaleRisks.pathogenic)) {
          bothGenderCancersString += "Women with <em>" + geneReport.gene + "</em> mutations are not known to be at an increased risk for developing cancer. "
        } else {
          bothGenderCancersString += "Women with <em>" + geneReport.gene + "</em> mutations are at an increased risk of developing " + geneReport.femaleCancersString + ". "
        }
      })
      angular.forEach(geneReports, function(geneReport) {
        bothGenderCancersString += geneReport.maleCancersString + ". "
      })
      $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.3_POS_MD',
        {
          testType: sampleLetterTestType,
          gene1: geneReports[0].gene,
          gene2: geneReports[1].gene,
          gene3: geneReports[2].gene,
          pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
          pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
          pathogenicNomenclature3: geneReports[2].pathogenicNomenclature,
          bothGenderCancersString: bothGenderCancersString
        }).then(function(translation) {
        output.msgMarkdownSampleLetter = translation
      })
    } else if (reportContent.is_positive && geneReports.length == 4) {
      let bothGenderCancersString = ""
      angular.forEach(geneReports, function(geneReport) {
        if ($filter('allRisksNotElevated')(geneReport.femaleRisks.pathogenic)) {
          bothGenderCancersString += "Women with <em>" + geneReport.gene + "</em> mutations are not known to be at an increased risk for developing cancer. "
        } else {
          bothGenderCancersString += "Women with <em>" + geneReport.gene + "</em> mutations are at an increased risk of developing " + geneReport.femaleCancersString + ". "
        }
      })
      angular.forEach(geneReports, function(geneReport) {
        bothGenderCancersString += geneReport.maleCancersString + ". "
      })
      $translate('RESULT.SHARE_FAMILY.SAMPLE_LETTER.4_POS_MD',
        {
          testType: sampleLetterTestType,
          gene1: geneReports[0].gene,
          gene2: geneReports[1].gene,
          gene3: geneReports[2].gene,
          gene4: geneReports[3].gene,
          pathogenicNomenclature1: geneReports[0].pathogenicNomenclature,
          pathogenicNomenclature2: geneReports[1].pathogenicNomenclature,
          pathogenicNomenclature3: geneReports[2].pathogenicNomenclature,
          pathogenicNomenclature4: geneReports[3].pathogenicNomenclature,
          bothGenderCancersString: bothGenderCancersString
        }).then(function(translation) {
        output.msgMarkdownSampleLetter = translation
      })
    }
  }
}
